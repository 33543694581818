import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import { FontAwesomeSvgIcon, RxForm } from "components/common";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions } from "store/patientDetail";
import { selectors } from "store/treatmentPlan";

export const RxModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const caseInfo = useAppSelector(selectors.caseInfoSelector);
  const openHandler = () => {
    setOpen(true);
    dispatch(actions.setCaseInfo(caseInfo!));
  };
  const closeHandler = () => {
    setOpen(false);
    dispatch(actions.clearState());
  };
  return (
    <>
      <Button
        endIcon={<FontAwesomeSvgIcon icon={faEye} />}
        fatButton
        variant="outlined"
        onClick={openHandler}
      >
        RX
      </Button>
      <Dialog open={open} maxWidth="lg" fullWidth>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={3}
          pt={3}
        >
          <Typography variant="h6">RX Form</Typography>
          <IconButton onClick={closeHandler} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          <RxForm />
        </DialogContent>
      </Dialog>
    </>
  );
};
