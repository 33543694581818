import { Nullable } from "lib/types";
import {
  IExtTreatmentPlanDto,
  IExtTreatmentPlanStepDto,
  ITreatmentPlanStepInstructionDto,
  ITreatmentPlanVerificationKeyDto,
  IExtToothNoteDto,
  IToothDto,
  IExtCaseWithCaseProgress,
} from "lib/dto";
import { NotesRecipient } from "lib/enum";

export enum TreatmentPlanActions {
  FETCH_CURRENT_VERSION = "treatmentPlan/version/fetch",
  TOGGLE_LEGEND = "treatmentPlan/legend/toggle",
  TOGGLE_PIN = "treatmentPlan/pin/toggle",
  TOGGLE_FBM = "treatmentPlan/fbm/toggle",
  TOGGLE_NOTES = "treatmentPlan/notes/toggle",
  TOGGLE_NOTES_LIST = "treatmentPlan/notes/list",
  FILTER_TP_NOTES = "treatmentPlan/notes/filter",
  SET_IPR_HOVER = "treatmentPlan/ipr/hover",
  OPEN_NOTES = "treatmentPlan/notes/open",
  SELECT_NOTE = "treatmentPlan/notes/select",
  DELETE_NOTE = "treatmentPlan/notes/delete",
  UPDATE_NOTE = "treatmentPlan/notes/update",
  ADD_NOTE = "treatmentPlan/notes/add",
  START_SELECTING = "treatmentPlan/cells/start",
  END_SELECTING = "treatmentPlan/cells/end",
  SET_SELECTED_CELLS = "treatmentPlan/cells/set",
  SET_SELECTED_CELL = "treatmentPlan/cell/set",
}

export type StepInstructions = Omit<
  ITreatmentPlanStepInstructionDto,
  "id" | "created" | "modified"
>;
export type TpSteps = {
  upperSteps: IExtTreatmentPlanStepDto[];
  lowerSteps: IExtTreatmentPlanStepDto[];
};

export type IprColumn = {
  toothNumber: number;
  distal: number | null;
  mesial: number | null;
};

export type VerificationKeyType = Record<
  number,
  {
    UPPER?: ITreatmentPlanVerificationKeyDto;
    LOWER?: ITreatmentPlanVerificationKeyDto;
  }
>;
export type NoteCellType = {
  bTop: boolean;
  bBottom: boolean;
  bLeft: boolean;
  bRight: boolean;
  isFirst: boolean;
  noteId: number;
};
export type NoteCells = Record<number, Record<number, NoteCellType[]>>;
export interface TreatmentPlanState {
  isLoading: boolean;
  showLegend: boolean;
  showFBM: boolean;
  showNotes: boolean;
  showPin: boolean;
  iprHoveredNum: IprColumn | null;
  treatmentPlan: Nullable<IExtTreatmentPlanDto>;
  verificationKeys: VerificationKeyType;
  lowerImpOrderIds: number[];
  upperImpOrderIds: number[];
  treatPlanExtraInst: {
    upperSteps: Record<number, IExtTreatmentPlanStepDto>;
    lowerSteps: Record<number, IExtTreatmentPlanStepDto>;
  };
  treatPlanAutoGenInstr: TpSteps[];
  treatmentPlanInstructions: TpSteps[];
  case: Nullable<IExtCaseWithCaseProgress>;
  startSelectingTooth: IToothDto | undefined;
  selectingToothKey: Record<string, boolean>;
  selectingToothDto: IToothDto[];
  tpNotes: {
    addNote: boolean;
    notes: IExtToothNoteDto[];
    noteCells: NoteCells;
    notesForShow?: IExtToothNoteDto[];
    previewFilter?: NotesRecipient;
    currentTooth: IToothDto[];
    anchor?: { top: number; left: number };
    selectedNote: number | null;
  };
}
