const toLocaleDateString = (date: Date) => {
  return date.toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
};

export const addDays = (date: string, days: number = 0) => {
  if (!date) return "";
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return toLocaleDateString(result);
};

export const convertDate = (date: string) => {
  if (!date) return "";
  const parsedDate = new Date(date);
  return `${
    parsedDate.getMonth() + 1
  }/${parsedDate.getDate()}/${parsedDate.getFullYear()} (${parsedDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${parsedDate.getMinutes().toString().padStart(2, "0")})`;
};

export const dateToString = (date?: string) => {
  if (!date) return "";

  const dateFormat = new Date(date);
  return toLocaleDateString(dateFormat);
};
export const dateTimeToString = (date?: string | null, options?: any) => {
  if (!date) return "";
  const dateFormat = new Date(date);
  if (dateFormat.toString() === "Invalid Date") return date;
  return dateFormat.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "numeric",
    ...(options || {}),
  });
};
export const timeToString = (date: string, options?: any) => {
  if (!date) return "";
  const dateFormat = new Date(date);
  if (dateFormat.toString() === "Invalid Date") return date;
  return dateFormat.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "numeric",
    ...(options || {}),
  });
};

export const getDateDiffInYears = (from: Date, to: Date) => {
  return from.getFullYear() - to.getFullYear();
};
