import React, { useState } from "react";
import { styled } from "@mui/material";
import Tab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";

export const StyledTab = styled(Tab)`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 8px;
  min-height: 32px;
  margin-right: 4px;
  text-transform: none;
  min-width: unset;
  color: var(--text-dark-color);
  &.Mui-selected {
    background-color: white;
    border-radius: 4px 4px 0 0;
    color: var(--text-dark-color);
  }
`;

export const StyledTabBar = styled(MuiTabs)`
  width: 100%;
  min-height: 32px;
  background-color: var(--tertiary-text-color);
  padding: 4px 4px 0;
  & .MuiTabs-indicator {
    display: none;
  }
`;

export const ContentWrapper = styled("div")`
  padding-top: 24px;
  min-height: 96px;
  box-sizing: border-box;
`;

interface Props {
  tabs: {
    tabId: string;
    label: string;
    content: React.ReactNode;
    tabAction?: React.ReactNode;
  }[];
  hideTab?: boolean;
}

export const Tabs: React.VFC<Props> = ({ tabs, hideTab = false }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const onChangeTab = (event: React.SyntheticEvent, value: number) => {
    setActiveTab(value);
  };
  if (!tabs.length) {
    return null;
  }
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <StyledTabBar
          value={activeTab}
          onChange={onChangeTab}
          aria-label="tabs"
        >
          {tabs.map((item, index) => (
            <StyledTab
              data-intercom-target={item.label}
              key={item.tabId}
              label={hideTab ? item.label : `${item.label} tab`}
              {...a11yProps(index)}
            />
          ))}
        </StyledTabBar>
        {tabs[activeTab].tabAction}
      </Stack>
      <ContentWrapper>{tabs[activeTab].content}</ContentWrapper>
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
