import React from "react";
import { styled, useTheme } from "@mui/material";

import { ITooth } from "lib/model";
import { Colors } from "consts/colors";

interface Props {
  teeth: ITooth[];
  width: number;
  height: number;
  viewBox: string;
  onClick?: (
    event: React.MouseEvent<SVGGElement> | null,
    toothNumber: string
  ) => void;
  onHover?: (
    event: React.MouseEvent | null,
    toothNumber: string | null
  ) => void;
}
const StyledPath = styled("path")`
  cursor: pointer;
  &:hover {
    stroke: ${Colors.SECONDARY};
  }
`;
export const Arch: React.VFC<Props> = (props) => {
  const theme = useTheme();
  const hoverHandler = (toothNumber: string) => (event: React.MouseEvent) => {
    if (props.onHover) {
      props.onHover(event, toothNumber);
    }
  };
  const leaveHandler = () => {
    if (props.onHover) {
      props.onHover(null, null);
    }
  };
  const clickHandler =
    (toothNumber: string) => (event: React.MouseEvent<SVGGElement>) => {
      if (props.onClick) {
        props.onClick(event, toothNumber);
      }
    };
  return (
    <svg viewBox={props.viewBox} width={props.width} height={props.height}>
      {props.teeth.map((tooth) => (
        <g
          key={tooth.id}
          onMouseEnter={hoverHandler(tooth.id)}
          onMouseLeave={leaveHandler}
          onClick={clickHandler(tooth.id)}
        >
          {props.onClick || props.onHover ? (
            <StyledPath
              d={tooth.path}
              fill={tooth.toothColor ?? Colors.CONTAINER_LIGHT_GREY}
            />
          ) : (
            <path
              d={tooth.path}
              fill={tooth.toothColor ?? Colors.CONTAINER_LIGHT_GREY}
            />
          )}
          <path
            d={tooth.numberPath}
            fill={
              tooth.toothColor
                ? theme.palette.getContrastText(tooth.toothColor)
                : Colors.SECONDARY
            }
          />
        </g>
      ))}
    </svg>
  );
};
