import React from "react";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material/Select";

import { Colors } from "consts/colors";
import { Select, TextField } from "components/ui";
import { selectors, actions } from "store/createPayment";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { InnerFieldContainer } from "./Elements";
import { OrganizationsFieldsEdit } from "./OrganizationsFieldsEdit";
import { OrganizationsFieldsRead } from "./OrganizationsFieldsRead";
import { PaymentsMethod } from "lib/enum";

export const OrganizationInfo = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const orgSelected = searchParams.has("org") && searchParams.has("office");
  const fields = useAppSelector(selectors.editableFields);

  const paymentTypes = [
    {
      label: "Promo",
      value: PaymentsMethod.PROMO,
    },
    {
      label: "Debit balance",
      value: PaymentsMethod.BALANCE,
    },
  ];

  const changeNoteHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.changeNote(event.target.value));
  };
  const changePaymentHandler = (event: SelectChangeEvent<string>) => {
    dispatch(actions.changePayment(event.target.value as PaymentsMethod));
  };
  return (
    <InnerFieldContainer>
      {orgSelected ? <OrganizationsFieldsRead /> : <OrganizationsFieldsEdit />}
      <Grid container spacing={3} mt={0}>
        <Grid item md={7}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={4}>
              <Stack spacing={0.5}>
                <Typography variant="body2" color={Colors.TEXT_PRIMARY}>
                  Payment type
                </Typography>
                <Select
                  placeholder="Select payment type"
                  value={fields.paymentMethod}
                  name="org"
                  onChange={changePaymentHandler}
                  data={paymentTypes}
                />
              </Stack>
            </Grid>
            <Grid item md={8}>
              <Stack spacing={0.5}>
                <Typography variant="body2" color={Colors.TEXT_PRIMARY}>
                  Notes
                </Typography>
                <TextField
                  disabled={fields.isSaving}
                  value={fields.notes}
                  onChange={changeNoteHandler}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </InnerFieldContainer>
  );
};
