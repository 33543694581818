import React from "react";
import clsx from "clsx";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Skeleton from "@mui/material/Skeleton";

import { IFile } from "lib/model";

import { getFilesType, getFileExtension } from "utils/fileHelpers";
import { FileNameTooltip } from "../FileNameTooltip";
import { PreviewIcon } from "./PreviewIcon";
import "./styles.css";

interface Props {
  file: IFile | File;
  disabled?: boolean;
  tooltip?: boolean;
  width?: number;
  height?: number;
  onDelete?: (file: IFile | File) => void;
}

export const FileCard = ({
  file,
  disabled,
  tooltip = true,
  width,
  height,
  onDelete,
}: Props) => {
  const fileExtension = getFileExtension(file);

  const deleteHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete(file);
    }
  };

  if (file instanceof Blob) {
    return (
      <FileNameTooltip fileName={file.name}>
        <div className="file-card" style={{ width, height }}>
          {!disabled && (
            <div
              className="file-card__close-button-container"
              onClick={deleteHandler}
            >
              <CancelRoundedIcon className="file-card__close-button" />
            </div>
          )}

          <span className="file-card__name">
            {fileExtension?.toUpperCase()}
          </span>
          <PreviewIcon
            fileType={getFilesType(file)}
            src={URL.createObjectURL(file)}
          />
        </div>
      </FileNameTooltip>
    );
  }
  if (
    !file.src &&
    (getFilesType(file) === "image" || getFilesType(file) === "pdf")
  ) {
    return (
      <div className="file-card" style={{ width, height }}>
        <span className="file-card__name">{fileExtension?.toUpperCase()}</span>
        <Skeleton
          className="file-card__preview"
          variant="rectangular"
          animation={"wave"}
          width={width ?? 64}
          height={height ?? 64}
        />
      </div>
    );
  }
  if (tooltip) {
    return (
      <FileNameTooltip fileName={file.name} createdDate={file.created}>
        <div
          style={{ width, height }}
          className={clsx("file-card", {
            hidden: file.delete,
          })}
        >
          {!disabled && !file.notDeletable && (
            <div
              className="file-card__close-button-container"
              onClick={deleteHandler}
            >
              <CancelRoundedIcon className="file-card__close-button" />
            </div>
          )}
          <span className="file-card__name">
            {fileExtension?.toUpperCase()}
          </span>
          <PreviewIcon fileType={getFilesType(file)} src={file.src} />
        </div>
      </FileNameTooltip>
    );
  }

  return (
    <div
      style={{ width, height }}
      className={clsx("file-card", {
        hidden: file.delete,
      })}
    >
      {!disabled && !file.notDeletable && (
        <div
          className="file-card__close-button-container"
          onClick={deleteHandler}
        >
          <CancelRoundedIcon className="file-card__close-button" />
        </div>
      )}
      <span className="file-card__name">{fileExtension?.toUpperCase()}</span>
      <PreviewIcon fileType={getFilesType(file)} src={file.src} />
    </div>
  );
};
