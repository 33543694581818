import { createSelector } from "@reduxjs/toolkit";
import { OrderRemakeType } from "lib/enum";

import { RootState } from "../store";
import { RequestRemakeState } from "./types";

const selectSelf = (state: RootState) => state.requestRemake;

export const getFilesSelector = createSelector(
  selectSelf,
  (state: RequestRemakeState) => ({
    clinicalPhotos: state.clinicalPhotos,
    commonFiles: state.commonFiles,
  })
);

export const disableSubmitButton = createSelector(
  selectSelf,
  (state: RequestRemakeState) => {
    if (state.remakeType === OrderRemakeType.REMAKE_ORDER) {
      if (
        state.needToSendFiles &&
        !state.clinicalPhotos.length &&
        !state.commonFiles.length
      ) {
        return true;
      }
      if (state.impressions > 0 && !state.shippingCreated) {
        return true;
      }
    }

    return !state.remakeType;
  }
);

export const getMainInfo = createSelector(
  selectSelf,
  (state: RequestRemakeState) => ({
    remakeType: state.remakeType,
    stepNum: state.stepNum,
    notes: state.notes,
    partialReplacement: state.partialReplacement,
    needToSendFiles: state.needToSendFiles,
    impressions: state.impressions,
  })
);
