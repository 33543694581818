export const upsServices = {
  "01": "Next Day Air",
  "02": "2nd Day Air",
  "03": "Ground",
  "65": "UPS Saver",
};

export const upsServiceForShippingLevel = {
  "01": "Next Day Air",
  "02": "2nd Day Air",
  "03": "Ground",
  "12": "3 Day Select",
  "13": "Next Day Air Saver",
  "14": "UPS Next Day Air Early",
  "59": "2nd Day Air A.M. Valid",
};

export const upsServiceWorld = {
  "07": "Worldwide Express",
  "08": "Worldwide Expedited",
  "11": "Standard",
  "65": "UPS Saver",
  "54": "Worldwide Express Plus",
  "96": "UPS Worldwide Express Freight",
};
