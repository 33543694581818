import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { DashboardState } from "./types";
const selectSelf = (state: RootState) => state.dashboard;

export const eventSelector = createSelector(
  selectSelf,
  (state: DashboardState) => state.events
);

export const loadingSelector = createSelector(
  selectSelf,
  (state: DashboardState) => state.isLoading
);
