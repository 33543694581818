import { IPaymentDto } from "lib/dto";
import { IBaseResponse, IBaseResponseList, ICreatePayment } from "lib/model";
import { getUrlSearchParamsString } from "utils/urlHelpers";
import api from "./baseService";

class PaymentService {
  private baseUrl: string = "payments";
  public async fetch(filter: any): Promise<IBaseResponseList<IPaymentDto>> {
    const { data } = await api.get<
      IBaseResponse<IBaseResponseList<IPaymentDto>>
    >(`${this.baseUrl}/filter?${getUrlSearchParamsString(filter)}`);
    return data.result;
  }
  public async getPaymentById(id: string | number): Promise<IPaymentDto> {
    const { data } = await api.get<IBaseResponse<IPaymentDto>>(
      `${this.baseUrl}/${id}`
    );
    return data.result;
  }
  public async calculateSum(units: number[]): Promise<{ sum: number }> {
    const { data } = await api.post<IBaseResponse<{ sum: number }>>(
      `calculate-sum`,
      {
        units,
      }
    );
    return data.result;
  }
  public async createPayment(params: ICreatePayment): Promise<void> {
    await api.post(this.baseUrl, params);
  }

  public async getUsingPaymentMethods(): Promise<IPaymentDto[]> {
    const { data } = await api.get<IBaseResponse<IPaymentDto[]>>(
      "payment-methods"
    );
    return data.result;
  }
}

export const paymentService = new PaymentService();
