import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";
import { ToothArches } from "components/common";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { selectors, actions } from "store/caseCreate";
import { ExtractionType } from "./ExtractionType";
import { ExtractionsArchContainer } from "containers";

export const ExtractionsArch = () => {
  const arches = useAppSelector(selectors.getArchesSelector);
  const extractions = useAppSelector(selectors.getExtractionsSelector);
  const dispatch = useAppDispatch();

  const toothClickHandler = (toothNumber: number, value: string) => {
    dispatch(
      actions.setExtractions({
        toothNumber,
        value,
      })
    );
  };

  const changeHandler = (toothNumber: number, value: string | null) => {
    dispatch(
      actions.setExtractions({
        toothNumber,
        value: value,
      })
    );
  };

  return (
    <>
      <ExtractionsArchContainer
        extractions={extractions}
        onChangeHandler={changeHandler}
        upperArch={arches.upper}
        lowerArch={arches.lower}
        toothClickHandler={toothClickHandler}
      />
    </>
  );
};
