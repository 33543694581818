import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";

import { MainContainer } from "../main";
import { StepperContainer } from "../stepper";

import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/caseCreate";
import { LoadingOverlay } from "components/common";

import { getStepsConf } from "./stepsConf";

const StepsContainer = styled(Stack)`
  height: 100%;
`;
const ContentContainer = styled("div")`
  height: calc(100vh - 238px);
`;

interface Props {
  preEval?: boolean;
  title?: string;
  retainer?: boolean;
}

export const CreateEvalCaseOrder: React.VFC<Props> = ({
  preEval,
  title,
  retainer = false,
}) => {
  const dispatch = useAppDispatch();
  const steps = useAppSelector(getStepsConf(retainer));
  const order = useAppSelector(selectors.getAllInfoSelector);

  useEffect(() => {
    dispatch(actions.setPreEval(Boolean(preEval)));
    dispatch(actions.setIsRetainer(Boolean(retainer)));
    dispatch(actions.fetchDoctorInfo());
    return () => {
      dispatch(actions.clearState());
    };
  }, [dispatch, preEval]);
  return (
    <MainContainer>
      <StepsContainer>
        <LoadingOverlay
          open={order.isLoading}
          text="Uploading files and placing your order. One moment, please."
        />
        <Box>
          <Typography variant="h1" color={Colors.PRIMARY} px={4} pt={8} pb={5}>
            {title ?? "New Patient Order"}
          </Typography>
        </Box>
        <ContentContainer>
          <StepperContainer steps={steps} />
        </ContentContainer>
      </StepsContainer>
    </MainContainer>
  );
};
