import React from "react";
import { FilterListProps, FilterType } from "./types";
import { FilterList } from "./FilterList";
import { FilterCheckboxList } from "./FilterCheckboxList";

interface Props {
  filter?: Omit<FilterListProps, "title"> & { type: FilterType };
  title: string;
}
export const FilterHead: React.VFC<Props> = ({ filter, title }) => {
  if (!filter) {
    return <span>{title}</span>;
  }
  switch (filter.type) {
    case "list":
      return (
        <FilterList
          title={title}
          data={filter?.data ?? []}
          icon={filter.icon}
          handleChange={filter.handleChange}
          defaultValue={filter.defaultValue}
        />
      );
    case "select":
      return (
        <FilterCheckboxList
          title={title}
          data={filter?.data ?? []}
          icon={filter.icon}
          filterValue={filter.filterValue}
          handleChange={filter.handleChange}
          defaultValue={filter.defaultValue}
        />
      );
    default:
      return <span>{title}</span>;
  }
};
