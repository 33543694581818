import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { TextField, FormLabel } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/caseCreate";
import { Colors } from "consts/colors";

import { CreateShippingButton } from "./CreateShippingButton";
import { InfoWithLabel } from "../InfoWithLabel";

export const ReviewStep = () => {
  const dispatch = useAppDispatch();
  const notes = useAppSelector(selectors.getNotesSelector);

  const { includes, excludes } = useAppSelector(selectors.getTagsSelector);
  const complicationsInfo = useAppSelector(selectors.getComplicationFullInfo);
  const objectives = includes
    .map(({ name }) => name)
    .join(". ")
    .trim();
  const doNotAddress = excludes
    .map(({ name }) => name)
    .join(". ")
    .trim();
  const notesChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setNotes(event.target.value));
  };
  return (
    <>
      <CreateShippingButton />
      <Typography mt={3} variant="h2" color={Colors.SECONDARY}>
        Review the information
      </Typography>
      <Stack spacing={1} mt={4}>
        {Boolean(objectives) && (
          <InfoWithLabel title="Objective" info={objectives} />
        )}
        {Boolean(doNotAddress) && (
          <InfoWithLabel title="Excluded from treatment" info={doNotAddress} />
        )}

        <InfoWithLabel title="Existing Complication" info={complicationsInfo} />
      </Stack>
      <Grid mt={4} container spacing={3}>
        <Grid item xs={6}>
          <FormLabel label="Any additional doctor preferences">
            <TextField
              fullWidth
              placeholder="Type notes here..."
              multiline
              rows={3}
              value={notes}
              onChange={notesChangeHandler}
            />
          </FormLabel>
        </Grid>
      </Grid>
    </>
  );
};
