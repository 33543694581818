import api from "./baseService";
import { IExtLabOfficeDto } from "lib/dto";
import { IBaseResponse } from "../lib/model";

class LabService {
  private baseUrl = "lab-offices";
  public async getLabOffices(): Promise<IExtLabOfficeDto[]> {
    const { data } = await api.get<IBaseResponse<IExtLabOfficeDto[]>>(
      this.baseUrl
    );
    return data.result;
  }
}

export const labService = new LabService();
