import React from "react";
import Box from "@mui/material/Box";

import {
  InstructionsText,
  ExtraInstructionContainer,
  ExtraInstructionOrder,
} from "./IndicatorElements";

interface Props {
  firstInstr: string[];
  mainInstr: string[];
  stepsExists: boolean;
  extraInstruction?: {
    types: string[];
    orderId: number | string;
  };
  lower?: boolean;
}
export const InstructionsIndicator: React.VFC<Props> = ({
  firstInstr,
  mainInstr,
  stepsExists,
  extraInstruction,
  lower,
}) => {
  if (extraInstruction) {
    return (
      <Box position="relative">
        {firstInstr.length > 0 ? (
          <ExtraInstructionContainer>
            <InstructionsText
              component="span"
              style={{
                top: -(firstInstr.length * 8),
              }}
            >
              {firstInstr.join(" ")}
            </InstructionsText>
          </ExtraInstructionContainer>
        ) : null}
        {stepsExists ? (
          <ExtraInstructionContainer>
            {mainInstr.length > 0 ? (
              <InstructionsText
                component="span"
                style={{
                  top: 40 - mainInstr.length * 8,
                }}
              >
                {mainInstr.join(" ")}
              </InstructionsText>
            ) : null}
          </ExtraInstructionContainer>
        ) : extraInstruction.types.length > 0 ? (
          <ExtraInstructionContainer>
            {mainInstr.length > 0 ? (
              <InstructionsText
                component="span"
                style={{
                  top: 40 - mainInstr.length * 8,
                }}
              >
                {extraInstruction.types
                  .map((item) => item.slice(0, 2).toUpperCase())
                  .join(" ")}
              </InstructionsText>
            ) : null}
          </ExtraInstructionContainer>
        ) : null}
        <ExtraInstructionOrder
          right={lower ? 0 : undefined}
          className="visible"
        >
          {extraInstruction.types.map((item) => (
            <span key={`${item}_${extraInstruction.orderId}`}>
              {item} for order {extraInstruction.orderId}
            </span>
          ))}
        </ExtraInstructionOrder>
      </Box>
    );
  }
  return (
    <div>
      {firstInstr.length > 0 ? (
        <Box component="span" position="relative">
          <InstructionsText
            component="span"
            style={{
              top: -(firstInstr.length * 8),
            }}
          >
            {firstInstr.join(" ")}
          </InstructionsText>
        </Box>
      ) : null}
      {stepsExists ? (
        <Box component="span" position="relative">
          {mainInstr.length > 0 ? (
            <InstructionsText
              component="span"
              style={{
                top: 40 - mainInstr.length * 8,
              }}
            >
              {mainInstr.join(" ")}
            </InstructionsText>
          ) : null}
        </Box>
      ) : null}
    </div>
  );
};
