import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

interface Props {
  progress?: number | null;
  children?: string | null;
  isLoading?: boolean;
}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#ABC1D9",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));
export const Progress: React.VFC<Props> = ({
  children,
  progress,
  isLoading,
}) => {
  const progressNumber = progress
    ? progress <= 1
      ? progress * 100
      : progress
    : 0;
  if (isLoading) {
    return (
      <Stack spacing={1} alignItems="flex-end">
        <Skeleton height={12} animation="wave" sx={{ alignSelf: "stretch" }} />
        <Skeleton height={16} width={16} variant="circular" animation="wave" />
      </Stack>
    );
  }
  if (progress === null) {
    return (
      <Typography variant="body1" fontSize={14} fontWeight={500}>
        N/A
      </Typography>
    );
  }
  return (
    <Stack spacing={1} alignItems="stretch">
      <BorderLinearProgress
        variant="determinate"
        value={progressNumber > 100 ? 100 : progressNumber}
      />
      {children ? (
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={500}
          textAlign="right"
        >
          {children}
        </Typography>
      ) : (
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={500}
          textAlign="right"
        >{`${progressNumber > 100 ? 100 : progressNumber}%`}</Typography>
      )}
    </Stack>
  );
};
