import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import printJS from "print-js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Button, IMenuItem, Menu } from "components/ui";
import { PromtWithDescription } from "components/common";
import { caseService } from "services";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { selectors, actions as caseActions } from "store/patientDetail";
import { selectors as userSelectors } from "store/currentUser";
import { CaseStatuses, OrderStatuses } from "lib/enum";

import { CalendlyWindow } from "./CalendlyWindow";

export const ActionsButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const permissions = useAppSelector(userSelectors.permissionsSelector);
  const { currentOrder } = useAppSelector(selectors.currentOrder);
  const { caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const { showErrorNotice } = useStyledSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [openCancel, setOpenCancel] = useState<boolean>(false);
  const [openCancelOrder, setOpenCancelOrder] = useState<boolean>(false);
  const [openCalendly, setOpenCalendly] = useState<boolean>(false);
  const actions: IMenuItem[] = [];

  if (permissions.includes("create:barcode-case-label") && currentOrder?.id) {
    actions.push({
      label: "Print Case label",
      handler: () => {
        setLoading(true);
        caseService
          .printCaseLabel(currentOrder?.id)
          .then((pdfFile: string) => {
            printJS({
              printable: pdfFile,
              type: "pdf",
              base64: true,
            });
            setLoading(false);
          })
          .catch((err: Error) => {
            setLoading(false);
            showErrorNotice(err.message);
          });
      },
    });
  }

  if (caseInfo?.status !== CaseStatuses.CANCELED) {
    actions.push({
      label: "Cancel the case",
      handler: () => {
        setOpenCancel(true);
      },
    });
  }

  if (currentOrder) {
    if (caseInfo?.treatPlanApprovedDate === null) {
      actions.push({
        label: "Edit case",
        handler: () => {
          navigate(`/cases/${currentOrder.caseId}/edit`);
        },
      });
    }

    if (
      [
        OrderStatuses.DRAFT,
        OrderStatuses.SUBMITTED_TO_LAB,
        OrderStatuses.RECEIVED_BY_LAB,
        OrderStatuses.PROCESSING_IN_LAB,
      ].includes(currentOrder.status)
    ) {
      actions.push({
        label: "Cancel the order",
        handler: () => {
          setOpenCancelOrder(true);
        },
      });
    }
    if (
      ![OrderStatuses.CANCELED, OrderStatuses.PROCESSING_IN_LAB].includes(
        currentOrder.status
      )
    ) {
      actions.push({
        label: "Order Next Aligners",
        handler: () => {
          navigate(`/orders/continuation/${currentOrder.caseId}`);
        },
      });
    }
  }
  actions.push({
    label: "Schedule consult",
    handler: () => setOpenCalendly(true),
  });

  const cancelOrderHandler = (description: string) =>
    dispatch(
      caseActions.cancelLastOrder({
        description,
      })
    ).unwrap();
  const cancelCaseHandler = (description: string) =>
    dispatch(caseActions.cancelCase(description)).unwrap();
  return (
    <>
      <CalendlyWindow
        open={openCalendly}
        onClose={() => setOpenCalendly(false)}
      />
      <PromtWithDescription
        open={openCancelOrder}
        onClose={() => setOpenCancelOrder(false)}
        title="Please enter the reason for canceling the order."
        onSubmit={cancelOrderHandler}
      />
      <PromtWithDescription
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        title="Please enter the reason for canceling the case."
        onSubmit={cancelCaseHandler}
      />
      <Menu items={actions}>
        <Button
          data-intercom-target="Case action buttons"
          loading={loading}
          fatButton
          endIcon={<ArrowDropDownIcon />}
          className="header__action-button"
          variant="contained"
        >
          Actions
        </Button>
      </Menu>
    </>
  );
};
