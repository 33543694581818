import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Colors } from "consts/colors";

interface Props {
  title: string;
  info: string;
}
export const InfoWithLabel: React.VFC<Props> = ({ title, info }) => (
  <Stack direction="row" spacing={0.5}>
    <Typography variant="body2" fontWeight="bold" color={Colors.SECONDARY}>
      {title}:
    </Typography>
    <Typography variant="body2" color={Colors.SECONDARY}>
      {info}
    </Typography>
  </Stack>
);
