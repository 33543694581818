import { styled } from "@mui/material";

export const PaperContainer = styled("div")`
  box-shadow: 0 4px 30px rgba(56, 71, 109, 0.1);
  border-radius: 8px;
  height: 100%;
  position: relative;
`;

export const TabsContainer = styled("div")`
  border-radius: 8px;
  overflow: hidden;
`;
