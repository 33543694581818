import api from "./baseService";
import { IPriceListDto } from "lib/dto";
import { IBaseResponse } from "../lib/model";

class PriceListService {
  private baseUrl = "price-lists";
  public async getPriceListEnabled(): Promise<IPriceListDto[]> {
    const { data } = await api.get<IBaseResponse<IPriceListDto[]>>(
      `${this.baseUrl}/enabled`
    );
    return data.result;
  }
  public async getPriceList(): Promise<IPriceListDto[]> {
    const { data } = await api.get<IBaseResponse<IPriceListDto[]>>(
      `${this.baseUrl}`
    );
    return data.result;
  }
}

export const priceListService = new PriceListService();
