import { MobileDatePicker } from "@mui/lab";
import { SelectChangeEvent, Stack } from "@mui/material";
import { Button, FormLabel, Select, TextField } from "components/ui";
import { PhoneField } from "components/ui/PhoneField";
import { genderDict } from "containers/createEvalCaseOrder/dicts";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { IPatientDto } from "lib/dto";
import { useState } from "react";
import { selectors, actions } from "store/patientDetail";
import { validateEmail } from "utils";

type FormState = Omit<IPatientDto, 'gender'> & { gender: string }
type Errors = Partial<Record<keyof FormState, string>>

interface Props {
  onClose: () => void;
}

export const PatientInfoForm: React.FC<Props> = ({ onClose }) => {
  const { caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState<FormState>({
    ...caseInfo?.patient!,
    gender: caseInfo?.patient.gender ? "1" : "0"
  })

  const [errors, setErrors] = useState<Errors>({})

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      [event.target.name]: undefined
    })
    setFormState({
      ...formState,
      [event.target.name]: event.target.value
    })
  }

  const phoneChangeHandler = (value: string) => {
    setFormState({
      ...formState,
      phone: value
    })
  }

  const dateChangeHandler = (date: string | null) => {
    setFormState({
      ...formState,
      birthDate: date
    })
  }

  const genderChangeHandler = (event: SelectChangeEvent<any>) => {
    setFormState({
      ...formState,
      gender: event.target.value
    })
  }

  const validate = () => {
    const errors: Errors = {}
    if (!formState.firstName) {
      errors.firstName = "Please fill this field"
    }
    if (!formState.lastName) {
      errors.lastName = "Please fill this field"
    }
    if (formState.email && !validateEmail(formState.email)) {
      errors.email = "Please enter valid email"
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const submitHandler = async () => {
    if (!validate()) {
      return
    }
    dispatch(actions.setCaseInfo({
      ...caseInfo!,
      patient: {
        ...formState,
        gender: formState.gender === "1"
      }
    }))
    try {
      await dispatch(actions.updatePatientInfo()).unwrap()
      onClose()
    }
    catch (e) {
      const error = e as any
      setErrors({
        ...errors,
        email: error.message,
        phone: error.message
      })
    }
  }

  const cancelHandler = () => {
    onClose()
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <FormLabel
          label="First Name"
          required
        >
          <TextField
            data-intercom-target="Patient first name"
            placeholder="First Name"
            name="firstName"
            value={formState.firstName}
            onChange={changeHandler}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </FormLabel>
        <FormLabel
          label="Last Name"
          required
        >
          <TextField
            data-intercom-target="Patient last name"
            placeholder="Last Name"
            name="lastName"
            value={formState.lastName}
            onChange={changeHandler}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </FormLabel>
        <FormLabel
          label="Email"
          hasError={true}
        >
          <TextField
            data-intercom-target="Patient email"
            placeholder="Email"
            name="email"
            value={formState.email}
            onChange={changeHandler}
            error={!!errors.email}
            helperText={errors.email}
          />
        </FormLabel>
        <FormLabel
          label="Phone number"
        >
          <PhoneField
            data-intercom-target="Patient phone"
            placeholder="Phone number"
            name="phone"
            value={formState.phone}
            onChange={phoneChangeHandler}
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </FormLabel>
        <FormLabel
          label="Birth Date"
        >
          <MobileDatePicker
            onChange={dateChangeHandler}
            inputFormat="MM/dd/yyyy"
            value={formState.birthDate}
            disableFuture
            renderInput={({
              placeholder,
              fullWidth,
              error,
              helperText,
              ...params
            }) => (
              <TextField
                data-intercom-target="Patient birth date"
                placeholder="Birth Date"
                fullWidth
                {...params}
              />
            )}
          />
        </FormLabel>
        <FormLabel label="Gender">
          <Select
            data-intercom-target="Patient gender"
            placeholder="Gender"
            name="gender"
            onChange={genderChangeHandler}
            value={formState.gender}
            fullWidth
            data={genderDict}
          />
        </FormLabel>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={submitHandler}>Save</Button>
        <Button onClick={cancelHandler}>Cancel</Button>
      </Stack>
    </Stack>
  )
}