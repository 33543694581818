import { RemakeFilesUpload } from "./RemakeFiles";
import Stack from "@mui/material/Stack";
import { FileSendQuestion } from "./FileSendQuestion";
import { ImpressionsField } from "./ImpressionsField";
import { useAppSelector } from "hooks/redux";

export const SecondStep = () => {
  const needToSend = useAppSelector(
    (state) => state.requestRemake.needToSendFiles
  );
  return (
    <Stack spacing={2}>
      <FileSendQuestion />
      {needToSend && (
        <>
          <RemakeFilesUpload />
          <ImpressionsField />
        </>
      )}
    </Stack>
  );
};
