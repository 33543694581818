import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import { Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions, selectors } from "store/patientDetail";

export const StartStopDmMonitoring = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showErrorNotice, showSuccessNotice } = useStyledSnackbar();
  const open = useAppSelector((state) => state.patientCard.showStartStopDM);
  const dispatch = useAppDispatch();
  const { caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const isOnMonitoring = caseInfo?.patient.onMonitoring;

  const dismissHandler = () => {
    dispatch(actions.hideStartStopDM());
  };

  const submitHandler = () => {
    setLoading(true);
    dispatch(actions.startStopMonitoringToDM())
      .unwrap()
      .then((approved) => {
        if (approved) {
          showSuccessNotice(`${isOnMonitoring ? 'Pause' : 'Start'} monitoring successfully approved.`);
        }
      })
      .catch((error) => {
        showErrorNotice(error.message);
      })
      .finally(() => {
        setLoading(false);
        dismissHandler();
      });
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          {isOnMonitoring ? "Pause" : "Start"} Monitoring
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please confirm that you wish to{" "}
            {isOnMonitoring ? "pause" : "start"} Dental
            Monitoring for this patient.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={dismissHandler} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            loading={loading}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
