import axios, { AxiosResponse } from "axios";
import { IAuthData, IBaseResponse } from "lib/model";
import { ApiStatusCodes } from "lib/enum";
import api from "./baseService";
import {
  REQUEST_TIMEOUT,
  AUTH_TOKEN_NAME,
  NEW_PORTAL_COOKIE,
} from "./apiConsts";

const redirectLink =
  document.cookie.indexOf(`${NEW_PORTAL_COOKIE}=true`) !== -1
    ? process.env.REACT_APP_REDIRECT_URI_OLD
    : process.env.REACT_APP_REDIRECT_URI;

export const renewToken = async (): Promise<boolean> => {
  const delay = 100;
  if (localStorage.getItem("isTokenRenewed")) {
    return await new Promise((resolve) => {
      setTimeout(function request() {
        if (!localStorage.getItem("isTokenRenewed")) {
          resolve(true);
        } else {
          setTimeout(request, delay);
        }
      }, delay);
    });
  } else {
    localStorage.setItem("isTokenRenewed", "true");
    const isLocalHost = process.env.REACT_APP_REDIRECT_URI
      ? `?isLocalHost=1&redirectUri=${redirectLink}`
      : "";
    const {
      data: {
        result: { accessToken },
      },
    } = await axios.post<IBaseResponse<IAuthData>>(
      `${process.env.REACT_APP_API_URL}renew-tokens${isLocalHost}`,
      {},
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_NAME)}`,
        },
        timeout: REQUEST_TIMEOUT,
      }
    );
    localStorage.setItem(AUTH_TOKEN_NAME, accessToken);
    localStorage.removeItem("isTokenRenewed");
    return true;
  }
};
export const responseInterceptor = async (
  response: AxiosResponse<IBaseResponse<any>>
) => {
  switch (response.data.code) {
    case ApiStatusCodes.TOKEN_RENEW_CODE:
      const tokenRenewed = await renewToken();
      if (tokenRenewed) {
        return await api.request(response.config);
      } else {
        throw new Error(response.data.desc);
      }
    case ApiStatusCodes.USER_UNAUTHORIZED_CODE:
      if (!response.data.result && response.data.desc) {
        throw new Error(response.data.desc);
      } else {
        if (response.data.result && response.data.result.startsWith("http")) {
          window.location.assign(response.data.result);
        } else {
          const redirectUrl = `?redirectUri=${redirectLink}`;
          const {
            data: { result },
          } = await axios.get<IBaseResponse<string>>(
            `${process.env.REACT_APP_API_URL}logout-uri${redirectUrl}`
          );
          localStorage.removeItem("auth0.access.token");
          document.cookie = `${NEW_PORTAL_COOKIE}=;Max-Age=0`;
          window.location.assign(result);
        }
      }
      break;
    case ApiStatusCodes.EXCEPTION_RESPONSE:
      throw new Error(response.data.desc);
    default:
      return response;
  }
};
