import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { fileService } from "services";
import { IFile } from "lib/model";
import { Colors } from "consts/colors";
import { fileDownload, getFilesType } from "utils";

import { Preview } from "./Preview";
import { Button } from "./Button";
import { FileDownloadButton } from "./FileDownloadButton";

interface Props {
  open: boolean;
  file?: IFile;
  fileDownloadHandler?: (
    fileId: number,
    controller: AbortController,
    contentType?: string
  ) => Promise<string>;
  onClose: () => void;
}

export const FilePreviewer: React.VFC<Props> = ({
  file,
  open,
  fileDownloadHandler,
  onClose,
}) => {
  const [path, setPath] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const size = isDesktop ? 640 : isMobile ? 250 : 400;
  const openInNewTabHandler = () => {
    if (file?.id) {
      window.open(
        `${window.location.origin}/${getFilesType(file)}/${file?.id}`
      );
    }
  };
  const canOpenInNewTab = Boolean(
    file &&
      file?.id &&
      (file?.src || path) &&
      ["video", "model"].includes(getFilesType(file))
  );
  useEffect(() => {
    const controller = new AbortController();
    if (file?.id && !file?.src) {
      setLoading(true);
      const filePromise = fileDownloadHandler
        ? fileDownloadHandler(file.id, controller, file?.contentType)
        : fileService.getFile(file?.id, file.contentType, controller.signal);
      filePromise.then(setPath).finally(() => setLoading(false));
    }
    return () => {
      controller.abort();
    };
  }, [file?.id, file?.contentType, file?.src, fileDownloadHandler]);
  if (!file) {
    return null;
  }
  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle>{file.name}</DialogTitle>
      <DialogContent>
        {loading || Boolean(!file?.src && !path) ? (
          <Stack
            direction="row"
            spacing={2}
            height={size}
            width={size}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress sx={{ color: Colors.PRIMARY }} size={24} />
            <Typography sx={{ color: Colors.PRIMARY }} variant="h2">
              Please wait a moment, file downloading...
            </Typography>
          </Stack>
        ) : (
          <Preview fileType={getFilesType(file)} fileSrc={file?.src ?? path} />
        )}
      </DialogContent>
      <DialogActions>
        {canOpenInNewTab && (
          <Button color="secondary" onClick={openInNewTabHandler}>
            Open in new tab
          </Button>
        )}
        {file.originalFileId ? (
          <FileDownloadButton
            fileId={file.originalFileId}
            contentType={file.contentType}
            name={file.originalFileName || file.name}
          />
        ) : Boolean(file && (file?.src || path)) ? (
          <Button
            color="secondary"
            onClick={() =>
              fileDownload(file.name, (file?.src || path) as string)
            }
          >
            Download
          </Button>
        ) : null}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
