export enum OrderItemType {
  INVALID = "INVALID",
  IMPRESSION = "IMPRESSION",
  BITE_REGISTRATION = "BITE_REGISTRATION",
  BITE = "BITE",
  MODEL = "MODEL",
  ARTICULATOR = "ARTICULATOR",
  PHOTOS = "PHOTOS",
  CASE = "CASE",
  CAD_CAM = "CAD_CAM",
}
