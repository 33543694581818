import React, { useEffect } from "react";
import { CreateEvalCaseOrder } from "containers";
import { analyticsMethod } from "plugins/analytics";
import { useParams } from "react-router-dom";

import { useStyledSnackbar } from "hooks/notification";
import { useAppDispatch } from "hooks/redux";
import { actions } from "store/caseCreate";
import { actions as stepperActions } from "store/createStepper";

import { checkRequiredFields } from "./helpers";

export const OrderAlignersCreatePage = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const { showErrorNotice } = useStyledSnackbar();

  const dispatch = useAppDispatch();
  analyticsMethod(
    "page",
    "Case",
    {
      title: "Create form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  useEffect(() => {
    if (caseId) {
      dispatch(actions.fetchOnlyCaseInfo(caseId))
        .unwrap()
        .then(({ caseInfo, clinicalPhotos }) => {
          if (checkRequiredFields(caseInfo, clinicalPhotos)) {
            dispatch(
              stepperActions.setTabStep({
                currentTab: 2,
                subStep: 0,
              })
            );
          } else {
            dispatch(
              stepperActions.setTabStep({
                currentTab: 1,
                subStep: 0,
              })
            );
          }
        })
        .catch((e) => {
          showErrorNotice(e.message);
        });
    }
  }, [caseId, dispatch, showErrorNotice]);
  return <CreateEvalCaseOrder title="Aligner order" />;
};
