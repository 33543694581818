import React, { useMemo } from "react";
import Box from "@mui/material/Box";

import { InfoWithLabel, ToothArches } from "components/common";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { Colors } from "consts/colors";

export const Extractions = () => {
  const { isLoading, caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const extractionTooth = useMemo(() => {
    if (!caseInfo?.additionalInfo.extractionTooth) {
      return [];
    }
    const parsedTeeth: string[] = JSON.parse(
      caseInfo?.additionalInfo.extractionTooth
    );
    const teeth = parsedTeeth.map((item) => item.toString());
    return Array(32)
      .fill(null)
      .map((_, index) => {
        if (teeth.includes(`${index.toString()}_p`)) {
          return Colors.TOOTH_WHITE;
        }
        return teeth.includes(index.toString()) ? Colors.DISABLED : null;
      });
  }, [caseInfo?.additionalInfo.extractionTooth]);
  if (
    !extractionTooth.length ||
    !extractionTooth.some((tooth) => Boolean(tooth))
  ) {
    return null;
  }
  return (
    <InfoWithLabel
      isLoading={isLoading}
      direction="column"
      label="PLANNED EXTRACTIONS"
    >
      <Box mt={2}>
        <ToothArches teeth={extractionTooth} width={168} height={256} />
      </Box>
    </InfoWithLabel>
  );
};
