import React from "react";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ConfirmProvider } from "components/common";
import { BrowserRouter } from "react-router-dom";
import RootRouter from "routes";
import { IdentifyUser } from "components/hoc";
import { SnackbarProvider } from "components/common/SnackbarProvider";


import { setupStore } from "store";
import { themeConfig } from "./themeConfig";
import "assets/styles/common.css";

export const store = setupStore();
function App() {
  const theme = createTheme(themeConfig);
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider>
                <IdentifyUser>
                  <BrowserRouter>
                    <RootRouter />
                  </BrowserRouter>
                </IdentifyUser>
              </SnackbarProvider>
            </LocalizationProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
