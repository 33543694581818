import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

const StyledContainer = styled(Stack)`
  border: 1px solid #fff;
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--primary-color);
`;
interface Props {
  text: string;
  open: boolean;
}
export const LoadingOverlay: React.VFC<Props> = ({ open, text }) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <StyledContainer spacing={2} direction="row" alignItems="center">
        <CircularProgress color="inherit" />
        <Typography variant="h6">{text}</Typography>
      </StyledContainer>
    </Backdrop>
  );
};
