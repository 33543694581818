import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { MainContainer, RequestRemakeContainer } from "containers";
import { selectors, actions } from "store/patientDetail";

import {
  Header,
  ActiveOrder,
  Orders,
  CaseInfoTabs,
  Messages,
  PromtEvents,
  CaseExtraActions,
  RatingQuestion,
} from "./components";

export const PatientsDetailsPage = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { caseEvents, requiredActions } = useAppSelector(
    selectors.caseNoticeSelector
  );
  const { showRating } = useAppSelector(selectors.allInfoSelector);
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const errors = useAppSelector(selectors.errors);

  useEffect(() => {
    if (patientId) {
      dispatch(actions.fetchAll(patientId));
    }
    return () => {
      dispatch(actions.clearState());
    };
  }, [patientId, dispatch]);
  useEffect(() => {
    if (errors) {
      showErrorNotice(errors);
    }
  }, [showErrorNotice, errors]);
  if (caseEvents.length > 0 || requiredActions.length > 0 || showRating) {
    return (
      <MainContainer>
        <Stack mx={3} mb={2} spacing={1}>
          <Header />
          <CaseExtraActions />
          <RequestRemakeContainer />
          <div>
            <Grid container spacing={1.5}>
              <Grid item md={8}>
                <Stack spacing={1.5}>
                  <CaseInfoTabs />
                  <ActiveOrder />
                  <Orders />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack spacing={1.5} justifyItems="stretch" height="100%">
                  <RatingQuestion />
                  <Box flexGrow={1}>
                    <PromtEvents />
                  </Box>
                  <Messages />
                </Stack>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </MainContainer>
    );
  }
  return (
    <MainContainer>
      <Stack mx={3} mb={2} spacing={1}>
        <Header />
        <CaseExtraActions />
        <RequestRemakeContainer />
        <div>
          <Grid container spacing={1.5} alignItems="stretch">
            <Grid item md={8}>
              <CaseInfoTabs />
            </Grid>
            <Grid item md={4}>
              <Messages />
            </Grid>
          </Grid>
        </div>
        <ActiveOrder />
        <Orders />
      </Stack>
    </MainContainer>
  );
};
