import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "serviceWorker";
import { autoRefreshPlugin } from "plugins";
import { StartPage } from "pages/start";
import { apiAlive } from "./services/apiServices";
import "normalize.css";

const rootElement = document.getElementById("root");

const initMaintenacePage = async () => {
  const { MaintenancePage } = await import("pages/maintenance");
  ReactDOM.render(<MaintenancePage />, rootElement);
};
const initMainApp = async () => {
  const { default: App } = await import("App");
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
  await autoRefreshPlugin();
};

const initApp = async () => {
  ReactDOM.render(<StartPage />, rootElement);
  try {
    const isAlive = await apiAlive();
    if (isAlive) {
      await initMainApp();
    } else {
      await initMaintenacePage();
    }
  } catch (e) {
    console.error(e);
    await initMaintenacePage();
  }
};
initApp().then();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.register();
