import React from "react";
import clsx from "clsx";
import Skeleton from "@mui/material/Skeleton";

import { ListTableColumn, ListTableRowProps } from "./types";
import { RowContainer, RowCell, NoData, RowContent } from "./Elements";
import Typography from "@mui/material/Typography";

export const Row = <T,>({
  columns,
  data,
  onRowClick,
  loading,
  itemsCount,
  emptyDataText,
}: ListTableRowProps<T>): JSX.Element => {
  return (
    <RowContainer className={"container"}>
      {loading ? (
        Array(itemsCount)
          .fill(null)
          .map((_, rowIndex) => {
            return (
              <RowContent key={`skeleton-row-${rowIndex}`}>
                {Array(columns.length)
                  .fill(null)
                  .map((_, cellIndex) => {
                    return (
                      <RowCell
                        key={`skeleton-row-${rowIndex}-cell-${cellIndex}`}
                      >
                        <Skeleton />
                      </RowCell>
                    );
                  })}
              </RowContent>
            );
          })
      ) : (
        <>
          {data?.length === 0 && (
            <RowContent>
              <NoData colSpan={columns.length}>
                <Typography variant="h2" fontWeight={500}>
                  {emptyDataText ?? "No data"}
                </Typography>
              </NoData>
            </RowContent>
          )}
          {data?.map((row: T, rowIndex: number) => {
            return (
              <React.Fragment key={`rf-table-row-${rowIndex}`}>
                <RowContent
                  key={`row-${rowIndex}`}
                  onClick={() => {
                    onRowClick && onRowClick(row);
                  }}
                  className={clsx({ cursor: typeof onRowClick === "function" })}
                >
                  {columns.map(
                    ({
                      width,
                      dataIndex,
                      render,
                      onClick,
                      style,
                      key,
                    }: ListTableColumn<T>) => {
                      const value = row[dataIndex];
                      return (
                        <RowCell
                          width={width}
                          style={{ ...style?.rowCell }}
                          key={`cell-${
                            key ?? dataIndex.toString()
                          }-${rowIndex}`}
                          onClick={() => {
                            onClick && onClick(value, row);
                          }}
                          className={clsx({
                            cursor: typeof onClick === "function",
                          })}
                        >
                          {render ? render(value, row, rowIndex) : value}
                        </RowCell>
                      );
                    }
                  )}
                </RowContent>
              </React.Fragment>
            );
          })}
        </>
      )}
    </RowContainer>
  );
};
