import api from "./baseService";

class FileService {
  private baseUrl: string = "download-file";

  public async getFileBlob(
    fileId: number | string,
    signal?: AbortSignal
  ): Promise<Blob> {
    const { data } = await api.get<BlobPart>(`${this.baseUrl}/${fileId}`, {
      responseType: "blob",
      signal,
    });
    return new Blob([data]);
  }

  public async getFile(
    fileId: number | string,
    contentType?: string,
    signal?: AbortSignal
  ): Promise<string> {
    const { data } = await api.get<BlobPart>(`${this.baseUrl}/${fileId}`, {
      responseType: "blob",
      signal,
    });
    return URL.createObjectURL(
      new Blob([data], { type: contentType ?? "application/octet-stream" })
    );
  }
}

export const fileService = new FileService();
