import React from "react";
import { PopupModal } from "react-calendly";
import { useAppSelector } from "hooks/redux";
import { selectors as userSelector } from "store/currentUser";
import { selectors as patientSelector } from "store/patientDetail";

interface Props {
  open: boolean;
  onClose: () => void;
}
export const CalendlyWindow: React.VFC<Props> = ({ open, onClose }) => {
  const { currentUser: doctorInfo } = useAppSelector(
    userSelector.getAllUserInfo
  );
  const { caseInfo } = useAppSelector(patientSelector.caseInfoSelector);
  const discussTP = useAppSelector(patientSelector.needDiscussTp);
  const rootElement = document.getElementById("root");
  if (!rootElement) {
    return null;
  }
  const prefill = {
    name: `${doctorInfo?.firstName || ""} ${doctorInfo?.lastName || ""}`,
    email: doctorInfo?.email || "",
    customAnswers: {
      a1: `${caseInfo?.patient.firstName || ""} ${
        caseInfo?.patient.lastName || ""
      }`,
      a2: discussTP ? "1" : "0",
      a3: `${process.env.REACT_APP_HOST!}cases/${caseInfo?.id || ""}`,
    },
  };
  return (
    <PopupModal
      url={process.env.REACT_APP_CALENDLY_LINK || ""}
      prefill={prefill}
      onModalClose={onClose}
      open={open}
      rootElement={document.getElementById("root")!}
    />
  );
};
