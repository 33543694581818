import { Grid, SelectChangeEvent } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { Select, FormLabel, TextField } from "components/ui";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { actions, selectors } from "store/caseCreate";
import { txPlanComplexitySelect, caseBudgetSelect } from "lib/dictionaries";
import { CaseBudgetEnum, TxPlanComplexityEnum } from "lib/enum";
import { SelectCaseBudgetContainer } from "containers";

export const CaseBudget: FC = () => {
  const { txPlanComplexity, caseBudget, caseBudgetCost } = useAppSelector(
    selectors.getCaseBudget
  );
  const dispatch = useAppDispatch();
  const changeHandler = (event: SelectChangeEvent<string>) => {
    dispatch(
      actions.setCaseBudget({
        name: event.target.name as "txPlanComplexity" | "caseBudget",
        value: event.target.value as TxPlanComplexityEnum | CaseBudgetEnum,
      })
    );

    if (
      event.target.name === "caseBudget" &&
      event.target.value !== CaseBudgetEnum.NO_MORE
    ) {
      dispatch(
        actions.setCaseBudget({
          name: "caseBudgetCost",
          value: "",
        })
      );
    }
  };

  const changeCostHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setCaseBudget({
        name: "caseBudgetCost",
        value: event.target.value as string,
      })
    );
  };
  return (
    <SelectCaseBudgetContainer
      value={{
        txPlanComplexity,
        caseBudget,
        caseBudgetCost,
      }}
      changeHandler={changeHandler}
      changeCostHandler={changeCostHandler}
    />
  );
};
