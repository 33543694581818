import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ICreateMessage } from "lib/model";
import { IExtOrderMessageDto } from "lib/dto";
import { ListTable } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { selectors, actions } from "store/patientDetail";
import { Colors } from "consts/colors";

import { columns } from "./tableColumns";
import { MessageDialog } from "../MessageDialog";

export const MessagesTable = () => {
  const orderMessages = useAppSelector(selectors.orderMessagesSelector);
  const loading = useAppSelector(selectors.isLoading);
  const { caseEvents, requiredActions } = useAppSelector(
    selectors.caseNoticeSelector
  );
  const { showErrorNotice } = useStyledSnackbar();
  const promtExists = caseEvents.length > 0 || requiredActions.length > 0;
  const ITEMS_PER_PAGE = promtExists ? 10 : 6;
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [message, setMessage] = useState<number | undefined>();
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const closeDialogHandler = () => {
    setMessage(undefined);
  };

  const messageOpenHandler = (row: IExtOrderMessageDto) => {
    setMessage(row.id);
    if (row.unreadMessageId) {
      dispatch(actions.readMessage(row.unreadMessageId))
        .unwrap()
        .catch((error) => {
          showErrorNotice(error.message);
        });
    }
  };

  const submitHandler = async (params: ICreateMessage) => {
    await dispatch(actions.createMessage(params));
  };

  if (!loading && !orderMessages.length) {
    return (
      <Box mt={2}>
        <Typography
          fontWeight="bold"
          variant="body1"
          textAlign="center"
          color={Colors.SECONDARY}
        >
          There are no messages yet.
        </Typography>
      </Box>
    );
  }
  const currentData: IExtOrderMessageDto[] = orderMessages.slice(
    startIndex,
    endIndex
  );
  return (
    <>
      <MessageDialog
        open={Boolean(message)}
        onClose={closeDialogHandler}
        onSubmit={submitHandler}
        messageId={message}
      />
      <ListTable
        sx={{
          borderRadius: 0,
          boxShadow: "none",
        }}
        loading={loading}
        maxHeight={promtExists ? undefined : 270}
        columns={columns}
        loadingRowsCount={5}
        onRowClick={messageOpenHandler}
        data={currentData}
        pagination={
          orderMessages.length > ITEMS_PER_PAGE
            ? {
                onChange: setPage,
                page,
                itemsCountParams: {
                  itemsCount: ITEMS_PER_PAGE,
                  onChange: () => {},
                },
                totalCount: orderMessages.length,
              }
            : undefined
        }
      />
    </>
  );
};
