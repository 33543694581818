import { Grid, styled } from "@mui/material";
import { Colors } from "consts/colors";
import { Button } from "components/ui";
export const CloseBtnContainer = styled("div")`
  margin-top: 20px;
  text-align: right;
`;

export const TotalContainer = styled("div")`
  margin-top: 32px;
`;

export const TotalLine = styled(Grid)`
  margin-top: 16px;
`;

export const Total = styled(Grid)`
  color: ${Colors.TEXT_GREY_DARK};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const SubTotal = styled(Total)`
  font-size: 20px;
`;

export const Wrapper = styled("div")`
  margin: 8px 40px;
`;

export const LabelContainer = styled(Grid)`
  padding: 20px 20px;
`;

export const LabelContainerBorder = styled(LabelContainer)`
  padding: 20px 20px;
  border-bottom: 1px solid ${Colors.CONTAINER_BORDER_GREY};
`;

export const StyledButton = styled(Button)`
  height: 48px;
  width: 88px;
`;
