import Stack from "@mui/material/Stack";
import {
  faCreditCard,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  BoxIcon,
  WalletIcon,
  PersonIcon,
  GearIcon,
  SettingIcon,
  HomeIcon,
} from "assets/vectorIcon";
import { usePermissionCheck } from "hooks/permissionCheck";
import { Colors } from "consts/colors";
import { NavLink } from "./NavLink";
import { LogoutButton } from "./LogoutButton";
import { NavMenuIcon } from "./NavMenuIcon";
import { NavContainer } from "./Elements";
import { useAppSelector } from "hooks/redux";

export const NavMenu = () => {
  const checkPermission = usePermissionCheck();

  const user = useAppSelector((state) => state.currentUser);

  return (
    <Stack
      sx={{
        backgroundColor: Colors.CONTAINER_GREY,
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <NavContainer>
        <Stack spacing={2} sx={{ flexGrow: 1 }} alignItems="flex-start">
          <NavLink to="/" icon={<HomeIcon />} title="Dashboard" />
          {checkPermission("read:case") && (
            <NavLink to="/cases" icon={<PersonIcon />} title="Patients" />
          )}
          <NavLink
            to={
              user.profile?.dmId
                ? "https://dental-monitoring.com/doctor/patients/all"
                : "https://help.orthosnap.com/en/articles/6475983-dental-monitoring-partnership-features-benefits"
            }
            icon={<NavMenuIcon icon={faArrowUpRightFromSquare} />}
            title="Dental Monitoring"
          />
          {checkPermission("read:shipping-order") && (
            <NavLink to="/shippings" icon={<BoxIcon />} title="Shipping" />
          )}
          {checkPermission("read:order-invoice") && (
            <NavLink to="/invoices" icon={<WalletIcon />} title="Invoices" />
          )}
          {/* Removed from view because of OST-1042 */}
          {/* {checkPermission("read:payment") && (
            <NavLink
              to="/payments"
              icon={<NavMenuIcon icon={faCreditCard} />}
              title="Payments"
            />
          )} */}
        </Stack>
        <Stack spacing={2} alignItems="flex-start">
          <NavLink
            to="http://help.orthosnap.com"
            icon={<NavMenuIcon icon={faArrowUpRightFromSquare} />}
            title="Help Center"
          />
          <NavLink
            to="/settings"
            icon={<GearIcon />}
            title="Account Settings"
          />
          <NavLink
            to="/preferences"
            icon={<SettingIcon />}
            title="Preferences"
          />
        </Stack>
      </NavContainer>
      <LogoutButton />
    </Stack>
  );
};
