import * as React from "react";
import Popper, { PopperProps } from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { Colors } from "consts/colors";

interface Props {
  open: boolean;
  anchorEl?: PopperProps["anchorEl"];
  placement: PopperProps["placement"];
  text: string;
}
export const ToothTooltips: React.VFC<Props> = ({
  open,
  anchorEl,
  placement,
  text,
}) => {
  if (!anchorEl) {
    return null;
  }
  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={{
              backgroundColor: Colors.PRIMARY,
              color: "white",
              marginInline: 1,
            }}
          >
            <Typography variant="body2" sx={{ p: 1 }}>
              {text}
            </Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
