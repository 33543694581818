import { CreateShippingState } from "./types";
import { ShippingTypes } from "lib/enum";

export const initState: CreateShippingState = {
  isLoading: true,
  office: null,
  lab: null,
  shippingType: ShippingTypes.UPS,
  shippingLevel: "cheapest",
  notes: "",
  cheapest: {
    packageType: "",
    upsService: "",
  },
  fastest: {
    packageType: "",
    upsService: "",
  },
  shippingInfo: {
    upsService: "03",
    packageType: "02",
    length: "7",
    height: "3",
    width: "7",
    weight: "1",
  },
};
