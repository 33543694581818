import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { MainContainer } from "containers";
import { fileService } from "services";
import { useStyledSnackbar } from "hooks/notification";

import {
  LoadingPreview,
  ModelPreview,
  ExceptionPreview,
  VideoPreview,
} from "./components";

interface Props {
  type: "video" | "model";
}
export const FilesPage: React.VFC<Props> = ({ type }) => {
  const { showErrorNotice } = useStyledSnackbar();
  const { fileId } = useParams<{ fileId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [source, setSource] = useState<string | undefined>();

  useEffect(() => {
    const controller = new AbortController();
    const init = async () => {
      try {
        setLoading(true);
        const fileSource = await fileService.getFileBlob(
          fileId!,
          controller.signal
        );
        setSource(URL.createObjectURL(fileSource));
      } catch (e: any) {
        showErrorNotice(e.message);
      } finally {
        setLoading(false);
      }
    };
    init().then();
    return () => {
      controller.abort();
    };
  }, [fileId, showErrorNotice]);

  if (loading) {
    return <LoadingPreview />;
  }
  if (source && type === "video") {
    return <VideoPreview source={source} />;
  }
  if (source && type === "model") {
    return <ModelPreview source={source} />;
  }
  return (
    <MainContainer>
      <ExceptionPreview />
    </MainContainer>
  );
};
