import { MainContainer, TabsContainer } from "containers";
import { Header } from "components/common";
import { shippingService } from "services";
import { TabsContainerProps } from "containers/tabs/types";
import { ListTableContainerProps } from "containers/listTable/types";
import { IShippingInfoForFilterViewDto } from "lib/dto";

import { columns } from "./columns";

const fetchToOrthosnap = async (filter: any) => {
  return await shippingService.fetch({
    ...filter,
    toOrthosnap: true,
  });
};

const fetchToOffice = async (filter: any) => {
  return await shippingService.fetch({
    ...filter,
    toOrthosnap: false,
  });
};

export const ShippingsPage = () => {
  const filters: ListTableContainerProps<IShippingInfoForFilterViewDto>["filterField"] =
    [
      { name: "search-for", initValue: "" },
      { name: "orderType", initValue: "DESC" },
      { name: "orderBy", initValue: "id" },
    ];
  const tabs: TabsContainerProps<IShippingInfoForFilterViewDto>["tabs"] = [
    {
      label: "To Orthosnap",
      list: {
        filterField: filters,
        columns,
        requests: {
          fetch: fetchToOrthosnap,
        },
        pagination: true,
      },
    },
    {
      label: "To Office",
      list: {
        filterField: filters,
        columns,
        requests: {
          fetch: fetchToOffice,
        },
        pagination: true,
      },
    },
  ];

  return (
    <MainContainer>
      <Header title={"Shipping"} />
      <TabsContainer tabs={tabs} />
    </MainContainer>
  );
};
