import { styled } from "@mui/material";
import { Colors } from "consts/colors";

export const StyledHeader = styled("header")({
  paddingBlock: 16,
  paddingInline: "24px 75px",
  backgroundColor: Colors.PRIMARY,
  height: "74px",
  boxSizing: "border-box",
  alignItems: "center",
  alignContent: "baseline",
  columnGap: 50,
});

export const StyledImg = styled("img")({
  width: "212px",
});

export const StyledContainer = styled("section")({
  width: "100%",
  flexGrow: 1,
});
