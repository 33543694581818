import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrderRemakeType } from "lib/enum";
import { IUploadedFile } from "lib/model";
import { generateUniqueID } from "utils";

import { initState } from "./state";
import { submitRemakeOrder } from "./actions";

export const requestRemakeSlice = createSlice({
  name: "requestRemake",
  initialState: initState,
  reducers: {
    openWindow(state) {
      state.open = true;
    },
    closeWindow() {
      return initState;
    },
    changeRemakeType(state, action: PayloadAction<OrderRemakeType>) {
      state.remakeType = action.payload;
      if (action.payload === OrderRemakeType.LOST_PACKAGE) {
        state.partialReplacement = false;
      }
    },
    changeStepNum(state, action: PayloadAction<number>) {
      state.stepNum = action.payload;
    },
    changePartReplace(state, action: PayloadAction<boolean>) {
      state.partialReplacement = action.payload;
    },
    changeNotes(state, action: PayloadAction<string>) {
      state.notes = action.payload;
    },
    setFilesNeed(state, action: PayloadAction<boolean>) {
      state.needToSendFiles = action.payload;
    },
    setImpressions(state, action: PayloadAction<number>) {
      state.impressions = action.payload;
    },
    setShippingCreate(state, action: PayloadAction<boolean>) {
      state.shippingCreated = action.payload;
    },
    setFiles(
      state,
      action: PayloadAction<{
        files: Omit<IUploadedFile, "id">[];
        type: "clinicalPhotos" | "commonFiles";
      }>
    ) {
      const fileNames = state[action.payload.type].map(({ name }) => name);
      state[action.payload.type] = [
        ...state[action.payload.type],
        ...action.payload.files
          .filter(({ name }) => !fileNames.includes(name))
          .map((file) => ({
            id: generateUniqueID(),
            ...file,
          })),
      ];
    },
    deleteFile(
      state,
      action: PayloadAction<{
        fileName: string;
        type: "clinicalPhotos" | "commonFiles";
      }>
    ) {
      state[action.payload.type] = state[action.payload.type].filter(
        ({ name }) => name !== action.payload.fileName
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitRemakeOrder.fulfilled, () => {
      return initState;
    });
  },
});
