import React, { useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { PreloadContainer } from "containers";
import { actions } from "store/currentUser";

export const LinkSMAccountPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.currentUser);
  const { showErrorNotice } = useStyledSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");
  const error = searchParams.get("error");

  useEffect(() => {
    if (error !== null) {
      showErrorNotice("Trouble when trying to link the account");
    }
    if (code !== null) {
      dispatch(actions.linkCurrentUser(code))
        .unwrap()
        .catch((error) => {
          navigate("/settings");
          showErrorNotice(error.message);
        });
    }
  }, [showErrorNotice, code, error, dispatch, navigate]);
  if (error || currentUser.isAuthorized) {
    return <Navigate to="/settings" replace />;
  }
  return <PreloadContainer />;
};
