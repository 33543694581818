import { IExtLabOfficeDto, IExtOfficeDto } from "lib/dto";
import { IShippingInfo } from "lib/model";
import { Nullable, ShippingLevel } from "lib/types";
import { ShippingTypes } from "lib/enum";
import { packageTypes, upsServiceForShippingLevel } from "lib/dictionaries";

type ShippingRate = {
  upsService: keyof typeof upsServiceForShippingLevel | "";
  packageType: keyof typeof packageTypes | "";
};

export interface CreateShippingState {
  isLoading: boolean;
  shippingLevel: ShippingLevel;
  shippingType: ShippingTypes;
  notes: string;
  lab: Nullable<IExtLabOfficeDto>;
  office: Nullable<IExtOfficeDto>;
  shippingInfo: IShippingInfo;
  fastest: ShippingRate;
  cheapest: ShippingRate;
}

export enum CreateShippingActions {
  SHIPPING_FETCH = "shipping/info/fetch",
  SHIPPING_SET_TYPE = "shipping/type/set",
  SHIPPING_SET_LEVEL = "shipping/level/set",
  SHIPPING_SET_NOTES = "shipping/notes/set",
  SHIPPING_PRINT_LABEL = "shipping/label/print",
}
