import React from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { Colors } from "consts/colors";
import { ReportFields } from "./ReportFields";
import { OrderIssue } from "./OrderIssue";

export const FirstStep = () => {
  return (
    <>
      <DialogContentText color={Colors.TEXT_PRIMARY}>
        If your order is missing please select “Report lost package” If your
        order has defects please select “Report order issue” and specify the
        issue (ex. issue with Upper arch aligner - missing mesial 0.25 IPR for
        tooth 2)
      </DialogContentText>
      <ReportFields />
      <OrderIssue />
    </>
  );
};
