import React from "react";
import { PreloadContainer } from "containers";
import { createTheme, ThemeProvider } from "@mui/material";
import { themeConfig } from "themeConfig";

export const StartPage = () => {
  const theme = createTheme(themeConfig);
  return (
    <ThemeProvider theme={theme}>
      <PreloadContainer />
    </ThemeProvider>
  );
};
