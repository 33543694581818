import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const QuestionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8" r="6" fill="#FFF"></circle>
      <path
        fill="#222222"
        d="M1.321 7.982c0-3.661 2.982-6.661 6.679-6.661 1.839 0 3.411.643 4.732 1.946 1.304 1.321 1.946 2.893 1.946 4.714 0 3.696-2.982 6.696-6.679 6.696-1.857 0-3.429-.661-4.714-1.982-1.304-1.286-1.964-2.857-1.964-4.714zm4.947-1.946l.179-.125c.018 0 .054-.036.143-.089.071-.054.125-.071.161-.089.214-.125.446-.196.714-.25.161-.018.268-.036.321-.036.589 0 .768.214.768.643 0 .304-.143.554-.429.786l-.446.411a3.07 3.07 0 0 0-.464.536c-.214.286-.393.804-.393 1.393v.179c0 .089 0 .143.018.179l.018.179h1.536v-.179c0-.429.161-.821.482-1.161l.232-.196a1.67 1.67 0 0 1 .232-.196 2.38 2.38 0 0 0 .5-.464c.304-.375.554-.893.554-1.661 0-1.232-.911-2.018-2.357-2.018-.875 0-1.661.232-2.357.696l-.125.089zm.661 5.946c.196.196.429.286.732.286.661 0 1.089-.393 1.089-1.036 0-.625-.393-1-1.089-1-.625 0-1.036.375-1.036 1 0 .304.107.554.304.75z"
      ></path>
    </SvgIcon>
  );
};
