import React, { useState, useRef, WheelEventHandler } from "react";
import AvatarEditor from "react-avatar-editor";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import { Button } from "components/ui";
import { FileUploadArea } from "components/common";
import { useAppDispatch } from "hooks/redux";
import { actions } from "store/accountSettings";

interface Props {
  officeId: number;
}
export const OfficeUploadLogo: React.FC<Props> = ({ officeId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [scale, setScale] = useState<number>(1);
  const dispatch = useAppDispatch();
  const editor = useRef<any>(null);
  const editorOpen = Boolean(file);

  const cancelEditing = () => {
    setFile(null);
  };
  const uploadFileHandler = (files: File[]) => {
    if (files) {
      setFile(files[0]);
    }
  };

  const onWheelHandler: WheelEventHandler<HTMLDivElement> = (event) => {
    setScale((oldScale) => {
      if (event.deltaY < 0) {
        return oldScale + 0.2 > 10 ? 10 : oldScale + 0.2;
      } else {
        return oldScale - 0.2 < 1 ? 1 : oldScale - 0.2;
      }
    });
  };

  const submitHandler = async () => {
    if (editor.current && file) {
      const dataUrl = editor.current!.getImageScaledToCanvas().toDataURL();
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      setLoading(true);
      dispatch(
        actions.uploadFile({
          fileName: file?.name,
          officeId,
          contentType: file?.type,
          src: blob,
        })
      )
        .then(() => {
          setFile(null);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      <FileUploadArea onFilesUpload={uploadFileHandler} />
      <Dialog maxWidth="sm" fullWidth open={editorOpen}>
        <DialogTitle>Edit office logo</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }} onWheel={onWheelHandler}>
          {Boolean(file) && (
            <AvatarEditor
              border={1}
              ref={editor}
              image={file!}
              width={500}
              height={400}
              scale={scale}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEditing}>Cancel</Button>
          <Button
            loading={loading}
            disabled={loading}
            color="primary"
            onClick={submitHandler}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
