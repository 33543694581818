import { SearchState } from "./types";

export const initState: SearchState = {
  isLoading: false,
  searchValue: "",
  open: false,
  searchData: {
    invoices: {
      data: [],
      totalCount: 0,
    },
    patients: {
      data: [],
      totalCount: 0,
    },
  },
};
