import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import logo from "../assets/images/orthosnap-logo-web.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "consts/colors";
import Typography from "@mui/material/Typography";
import { MainContainer } from "./main";
import React from "react";
import { styled } from "@mui/material";

const StyledContainer = styled(Container)({
  display: "flex",
  alignItems: "center",
  height: "100%",
});
export const PagePreloadContainer = () => {
  return (
    <MainContainer>
      <StyledContainer maxWidth="xs">
        <Stack spacing={4}>
          <img style={{ maxWidth: "100%" }} src={logo} alt="Orthosnap" />
          <Stack direction="row" spacing={2}>
            <CircularProgress sx={{ color: Colors.PRIMARY }} size={24} />
            <Typography sx={{ color: Colors.PRIMARY }} variant="h2">
              Please wait a moment...
            </Typography>
          </Stack>
        </Stack>
      </StyledContainer>
    </MainContainer>
  );
};
