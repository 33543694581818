import React from 'react';

const IPR = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none">
			<circle cx="16" cy="16" r="16" fill="white" />
			<path
				d="M14.4212 5L16.5771 11.0871L18.7214 5H20.9926V13.3929H19.2575V11.0987L19.4304 7.13857L17.1651 13.3929H15.9776L13.718 7.14433L13.8909 11.0987V13.3929H12.1616V5H14.4212Z"
				fill="#2C2E30"
			/>
			<path
				d="M5 26.0654C5 25.8003 5.08839 25.5851 5.26516 25.4198C5.44578 25.2546 5.67058 25.172 5.93959 25.172C6.21243 25.172 6.43724 25.2546 6.61401 25.4198C6.79463 25.5851 6.88494 25.8003 6.88494 26.0654C6.88494 26.3268 6.79655 26.54 6.61978 26.7053C6.44301 26.8667 6.21627 26.9474 5.93959 26.9474C5.66674 26.9474 5.44193 26.8667 5.26516 26.7053C5.08839 26.54 5 26.3268 5 26.0654Z"
				fill="#2C2E30"
			/>
			<path
				d="M12.3265 26.884H10.6606V20.4625L8.67188 21.0793V19.7247L12.1478 18.4796H12.3265V26.884Z"
				fill="#2C2E30"
			/>
			<path
				d="M20.8116 26.884H15.0588V25.7426L17.7738 22.8489C18.1465 22.4416 18.4213 22.0861 18.5981 21.7825C18.7787 21.479 18.869 21.1907 18.869 20.9179C18.869 20.5451 18.7748 20.2531 18.5865 20.0417C18.3982 19.8265 18.1292 19.7189 17.7795 19.7189C17.4029 19.7189 17.1051 19.8496 16.8861 20.1109C16.6709 20.3684 16.5633 20.7085 16.5633 21.1312H14.8916C14.8916 20.6201 15.0127 20.1532 15.2548 19.7304C15.5007 19.3077 15.8466 18.9772 16.2923 18.739C16.7381 18.4969 17.2435 18.3758 17.8084 18.3758C18.673 18.3758 19.3436 18.5833 19.8201 18.9984C20.3005 19.4134 20.5407 19.9994 20.5407 20.7565C20.5407 21.1715 20.433 21.5942 20.2178 22.0246C20.0026 22.4551 19.6337 22.9565 19.1111 23.5291L17.2031 25.5409H20.8116V26.884Z"
				fill="#2C2E30"
			/>
			<path
				d="M22.0855 22.7567L22.5697 18.4911H27.2734V19.8803H23.9358L23.7283 21.6846C24.1241 21.4732 24.5449 21.3675 24.9907 21.3675C25.79 21.3675 26.4164 21.6154 26.8699 22.1111C27.3234 22.6068 27.5501 23.3005 27.5501 24.192C27.5501 24.7339 27.4348 25.22 27.2042 25.6504C26.9775 26.077 26.6508 26.4094 26.2243 26.6476C25.7977 26.8821 25.2943 26.9993 24.714 26.9993C24.2068 26.9993 23.736 26.8974 23.3018 26.6938C22.8675 26.4862 22.5236 26.1961 22.27 25.8233C22.0202 25.4506 21.8876 25.0259 21.8722 24.5494H23.5208C23.5554 24.8991 23.6764 25.172 23.884 25.368C24.0953 25.5601 24.3701 25.6562 24.7083 25.6562C25.0849 25.6562 25.375 25.5217 25.5787 25.2527C25.7824 24.9798 25.8842 24.5955 25.8842 24.0998C25.8842 23.6233 25.767 23.2582 25.5326 23.0046C25.2981 22.751 24.9657 22.6241 24.5353 22.6241C24.1395 22.6241 23.8186 22.7279 23.5727 22.9354L23.4113 23.0853L22.0855 22.7567Z"
				fill="#2C2E30"
			/>
		</svg>
	);
};
export default IPR;
