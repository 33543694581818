import React from "react";
import { types, selectors, helpers, actions } from "store/treatmentPlan";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import clsx from "clsx";

import { NotesCell } from "./NotesCell";
import "./styles.css";

interface Props {
  stepId: number;
  stepNumber: number;
  bordered: boolean;
  type: "uppers" | "lowers";
}
const getPropValue = (
  noteCells: types.NoteCells,
  stepNumber: number,
  toothNumber: number,
  property: keyof types.NoteCellType
): boolean => {
  if (!noteCells[stepNumber]) {
    return false;
  }

  if (!noteCells[stepNumber][toothNumber]) {
    return false;
  }
  return noteCells[stepNumber][toothNumber].some((item) => item[property]);
};
export const NotesRow: React.FC<Props> = (props) => {
  const tpNotes = useAppSelector(selectors.notesSelector);
  const dispatch = useAppDispatch();
  const iconClickHandler =
    (noteIds: number[]) => (event: React.MouseEvent<SVGSVGElement>) => {
      event.preventDefault();
      const toothDTOs = tpNotes.notes
        .filter(({ noteDto: { id } }) => noteIds.includes(id))
        .map(({ toothDtoList }) => toothDtoList);
      const teeth = toothDTOs.pop();
      const { width, left, top } = event.currentTarget.getBoundingClientRect();
      dispatch(
        actions.openToShow({
          noteIds,
          anchor: {
            top,
            left: left + width,
          },
          currentTooth: teeth!.map(({ toothNumber, stepNumber }) => ({
            toothNumber,
            stepNumber,
          })),
          addNote: true,
        })
      );
    };
  const getRowCells = (increment?: number) =>
    Array(8)
      .fill(null)
      .map((_, index) => {
        const toothIdx = helpers.getToothNumber(props.type, index, increment);
        const noteIds: number[] = [];
        if (
          tpNotes.noteCells[props.stepNumber] &&
          tpNotes.noteCells[props.stepNumber][toothIdx]
        ) {
          noteIds.push(
            ...tpNotes.noteCells[props.stepNumber][toothIdx].map(
              ({ noteId }) => noteId
            )
          );
        }
        const selected = tpNotes.selectedNote
          ? noteIds.includes(tpNotes.selectedNote)
          : false;
        return (
          <NotesCell
            onIconClick={iconClickHandler(noteIds)}
            key={`${toothIdx}_${props.stepId}`}
            selected={selected}
            bTop={getPropValue(
              tpNotes.noteCells,
              props.stepNumber,
              toothIdx,
              "bTop"
            )}
            bBottom={getPropValue(
              tpNotes.noteCells,
              props.stepNumber,
              toothIdx,
              "bBottom"
            )}
            bLeft={getPropValue(
              tpNotes.noteCells,
              props.stepNumber,
              toothIdx,
              "bLeft"
            )}
            bRight={getPropValue(
              tpNotes.noteCells,
              props.stepNumber,
              toothIdx,
              "bRight"
            )}
            isFirst={getPropValue(
              tpNotes.noteCells,
              props.stepNumber,
              toothIdx,
              "isFirst"
            )}
          />
        );
      });
  return (
    <div className="notes-row__cell-wrapper">
      <div
        className={clsx("notes-row", {
          "notes-row_bordered": props.bordered,
        })}
      >
        {getRowCells()}
      </div>
      <div
        className={clsx("notes-row", {
          "notes-row_bordered": props.bordered,
        })}
      >
        {getRowCells(8)}
      </div>
    </div>
  );
};
