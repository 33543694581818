import React from "react";
import { styled } from "@mui/material";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";

import { PaperContainer } from "../Elements";
import { EventItem } from "./EventItem";
import { ActionsButton } from "./ActionsButton";

const SubHeadTypography = styled(Typography)`
  font-size: 14px;
`;
const AttentionText = styled("span")`
  color: var(--error-color);
  font-weight: bold;
`;
export const PromtEvents = () => {
  const { caseEvents, requiredActions } = useAppSelector(
    selectors.caseNoticeSelector
  );
  if (!requiredActions.length && !caseEvents.length) {
    return null;
  }
  return (
    <PaperContainer data-intercom-target="Case promt events">
      <Stack px={2.5} pt={2.5} pb={5} data-intercom-target="Required actions">
        {requiredActions.length > 0 && (
          <Stack alignItems="flex-start" spacing={2} mb={4}>
            <SubHeadTypography fontWeight={600} color={Colors.PRIMARY}>
              Required actions
            </SubHeadTypography>
            <Typography variant="body2" color={Colors.TEXT_SECONDARY}>
              You have{" "}
              <AttentionText>{requiredActions.length} required</AttentionText>{" "}
              action item
            </Typography>
            {requiredActions.map(({ targetId, type }) => (
              <ActionsButton key={type} actionType={type} targetId={targetId} />
            ))}
          </Stack>
        )}
        {caseEvents.length > 0 && (
          <Stack spacing={2.5}>
            <SubHeadTypography fontWeight={600} color={Colors.PRIMARY}>
              Upcoming
            </SubHeadTypography>
            {caseEvents.map(({ id, message, operation }) => (
              <EventItem key={id} type={operation} text={message} />
            ))}
          </Stack>
        )}
      </Stack>
    </PaperContainer>
  );
};
