import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Nullable } from "lib/types";
import { IExtLabOfficeDto, IExtOfficeDto } from "lib/dto";
import { Colors } from "consts/colors";

import { InfoItem } from "./InfoItem";

interface Props {
  info: Nullable<IExtLabOfficeDto> | Nullable<IExtOfficeDto>;
  title: string;
  isLoading: boolean;
}
export const ShipInfo: React.VFC<Props> = (props) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h2" color={Colors.TEXT_PRIMARY}>
        {props.title}
      </Typography>
      <div>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <InfoItem
              isLoading={props.isLoading}
              label="Name"
              text={props.info?.name}
            />
          </Grid>
          <Grid item md={2}>
            <InfoItem
              isLoading={props.isLoading}
              label="Phone"
              text={props.info?.phone}
            />
          </Grid>
          <Grid item md={3}>
            <InfoItem
              isLoading={props.isLoading}
              label="Street"
              text={props.info?.address?.address}
            />
          </Grid>
          <Grid item md={3}>
            <InfoItem
              isLoading={props.isLoading}
              label="City, State, Zip"
              text={
                props.info
                  ? `${props.info?.address?.city}, ${props.info?.address?.state}, ${props.info?.address?.zipCode}`
                  : null
              }
            />
          </Grid>
        </Grid>
      </div>
    </Stack>
  );
};
