import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IShippingsRatingResult } from "lib/model";
import { IExtLabOfficeDto, IExtOfficeDto } from "lib/dto";
import {
  orderService,
  labService,
  shippingService,
  userSettingsService,
} from "services";
import { Nullable, ShippingLevel } from "lib/types";
import { ShippingTypes } from "lib/enum";

import { CreateShippingActions } from "./types";
import { RootState } from "../store";

export const fetchShippingInfo = createAsyncThunk<
  {
    shippingRate: Nullable<IShippingsRatingResult>;
    levelPriority: Nullable<string>;
    lab: Nullable<IExtLabOfficeDto>;
    office: Nullable<IExtOfficeDto>;
  },
  number
>(CreateShippingActions.SHIPPING_FETCH, async (orderId: number, thunkApi) => {
  const labs = await labService.getLabOffices();
  const offices = await orderService.getOfficesByOrderId(orderId);
  const state: RootState = thunkApi.getState() as RootState;
  const preferences = await userSettingsService.getPreferencesByUser(
    state.currentUser.currentUser!.userId
  );
  try {
    const shippingRate = await shippingService.getShippingsRate();
    return {
      shippingRate,
      levelPriority: preferences.SHIPPING_LEVEL?.value || null,
      lab: labs.length > 0 ? labs[0] : null,
      office: offices.length > 0 ? offices[0] : null,
    };
  } catch (e) {
    return {
      shippingRate: null,
      levelPriority: preferences.SHIPPING_LEVEL?.value || null,
      lab: labs.length > 0 ? labs[0] : null,
      office: offices.length > 0 ? offices[0] : null,
    };
  }
});

export const printShippingLabel = createAsyncThunk<string, number>(
  CreateShippingActions.SHIPPING_PRINT_LABEL,
  async (orderId, thunkApi) => {
    const { shipping }: RootState = thunkApi.getState() as RootState;
    const { shippingLabelBase64 } = await shippingService.getShippingLabel({
      packageType: shipping.shippingInfo.packageType,
      upsService: shipping.shippingInfo.upsService,
      description: shipping.notes,
      orders: [
        {
          orderId,
          weight: Number(shipping.shippingInfo.weight),
          height: Number(shipping.shippingInfo.height),
          length: Number(shipping.shippingInfo.length),
          width: Number(shipping.shippingInfo.width),
        },
      ],
      shippingType: shipping.shippingType,
    });
    return shippingLabelBase64;
  }
);

export const setShippingType = createAction<ShippingTypes>(
  CreateShippingActions.SHIPPING_SET_TYPE
);

export const setShippingLevel = createAction<ShippingLevel>(
  CreateShippingActions.SHIPPING_SET_LEVEL
);

export const setShippingNotes = createAction<string>(
  CreateShippingActions.SHIPPING_SET_NOTES
);
