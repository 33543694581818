import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { Select, TextField, FormLabel } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { requiredStepsNum } from "lib/dictionaries";
import { selectors, actions } from "store/continuationOrderCreate";

const StyledSelect = styled(Select)`
  max-width: 322px;
`;
export const SelectSteps = () => {
  const { stepsType, customStepsNum } = useAppSelector(
    selectors.getStepsInfoSelector
  );
  const dispatch = useAppDispatch();

  const stepsTypeChangeHandler = (
    event: SelectChangeEvent<string | number>
  ) => {
    dispatch(actions.setStepsType(event.target.value as string));
  };
  const customStepsChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(actions.setCustomSteps(event.target.value));
  };
  return (
    <Box>
      <FormLabel label="Number of aligners">
        <Stack spacing={3} direction="row">
          <StyledSelect
            placeholder="Select one"
            fullWidth
            value={stepsType}
            onChange={stepsTypeChangeHandler}
            data={requiredStepsNum}
          />
          {stepsType === "custom" && (
            <TextField
              type="number"
              value={customStepsNum}
              onChange={customStepsChangeHandler}
              sx={{ maxWidth: 123 }}
            />
          )}
        </Stack>
      </FormLabel>
    </Box>
  );
};
