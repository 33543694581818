import { TuneIcon } from "assets/vectorIcon";

import {
  ListTableColumn,
  TableFilterListOption,
} from "components/ui/listTable";
import { IExtFileDto } from "lib/dto";
import { FileFromType, FileType } from "lib/enum";
import { fileTypes, fileFromTypes } from "lib/dictionaries";
import { dateToString } from "utils";
import { Colors } from "consts/colors";
import { UploadedFileCard } from "./UploadedFileCard";
import { State } from "./utils";

const headerCellStyle = {
  color: Colors.TEXT_SECONDARY,
  backgroundColor: "white",
  paddingBottom: 16,
  borderBottom: "none",
  fontSize: 12,
  paddingLeft: 16,
  paddingRight: 0,
  lineHeight: 1,
};
const rowCellStyle = {
  fontSize: 14,
  paddingBlock: 8,
  paddingLeft: 16,
  paddingRight: 0,
  borderBottom: "none",
};
export const columns = (
  orderItems: TableFilterListOption[],
  dateItems: TableFilterListOption[],
  filterState: State,
  filterChangeHandle: (filterName: string, value: string) => void
): ListTableColumn<IExtFileDto & { src?: string }>[] => [
  {
    title: "FILE",
    dataIndex: "id",
    width: "68px",
    render: (_, file) => <UploadedFileCard file={file} />,
    style: {
      headerCell: { ...headerCellStyle, paddingInlineEnd: 0 },
      rowCell: {
        borderBottom: "none",
        padding: "8px 0 8px 16px",
      },
    },
  },
  {
    title: "NAME",
    dataIndex: "name",
    width: "200px",
    style: {
      headerCell: headerCellStyle,
      rowCell: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: 200,
        ...rowCellStyle,
      },
    },
  },
  {
    title: "TYPE",
    dataIndex: "fileType",
    render: (fileType: FileType) => fileTypes[fileType],
    style: {
      headerCell: headerCellStyle,
      rowCell: rowCellStyle,
    },
    filter: {
      type: "select",
      filterValue: filterState.typeFilter.split(","),
      icon: (
        <TuneIcon sx={{ cursor: "pointer", fontSize: 14, marginTop: "2px" }} />
      ),
      handleChange: (_: any, option: TableFilterListOption | null) => {
        filterChangeHandle("typeFilter", option?.value || "");
      },
      data: Object.entries(fileTypes)
        .filter(([key]) => key !== FileType.INVALID)
        .map(([key, value]) => ({
          title: value,
          value: key,
        })),
    },
  },
  {
    title: "DATE",
    dataIndex: "created",
    width: "80px",
    render: (created) => dateToString(created),
    style: {
      headerCell: headerCellStyle,
      rowCell: rowCellStyle,
    },
    filter: {
      type: "select",
      filterValue: filterState.dateFilter.split(","),
      icon: (
        <TuneIcon sx={{ cursor: "pointer", fontSize: 14, marginTop: "2px" }} />
      ),
      handleChange: (_: any, option: TableFilterListOption | null) => {
        filterChangeHandle("dateFilter", option?.value || "");
      },
      data: dateItems,
    },
  },
  {
    title: "UPLOADED BY",
    dataIndex: "fromType",
    width: "150px",
    render: (fileFromType: FileFromType) => fileFromTypes[fileFromType],
    style: {
      headerCell: headerCellStyle,
      rowCell: rowCellStyle,
    },
    filter: {
      type: "select",
      filterValue: filterState.personFilter.split(","),
      icon: (
        <TuneIcon sx={{ cursor: "pointer", fontSize: 14, marginTop: "2px" }} />
      ),
      handleChange: (_: any, option: TableFilterListOption | null) => {
        filterChangeHandle("personFilter", option?.value || "");
      },
      data: [
        {
          title: "Labstaff",
          value: FileFromType.FROM_LAB,
        },
        {
          title: "Doctor",
          value: FileFromType.FROM_DOCTOR,
        },
      ],
    },
  },
  {
    title: "ORDER #",
    dataIndex: "orderId",
    width: "120px",
    style: {
      headerCell: { ...headerCellStyle, whiteSpace: "nowrap" },
      rowCell: {
        color: Colors.PRIMARY,
        ...rowCellStyle,
      },
    },
    filter: {
      type: "select",
      filterValue: filterState.orderFilter.split(","),
      icon: (
        <TuneIcon sx={{ cursor: "pointer", fontSize: 14, marginTop: "2px" }} />
      ),
      handleChange: (_: any, option: TableFilterListOption | null) => {
        filterChangeHandle("orderFilter", option?.value || "");
      },
      data: orderItems,
    },
  },
];
