import { FC, useEffect, useState } from "react";

import { Box, Grid, Stack } from "@mui/material";
import { FileUploadArea, UploadedFileCard } from "components/common";
import { FormLabel, SelectTagFields } from "components/ui";
import { ITreatmentCondition, IUploadedFile } from "lib/model";
import { OptionItem } from "components/ui/SelectTagFields/types";
import { caseService } from "services";
import { Tag } from "store/caseCreate/types";

interface Props {
  clinicalPhotos: IUploadedFile[];
  filesUploadHandler: (files: File[]) => void;
  uploadFiles: (params: {
    fileId: string;
    controller: AbortController;
    contentType: string;
  }) => void;
  filesDeleteHandler: (name: string) => void;
  helperText?: {
    fileUpload?: string;
    includes?: string;
  };
  error?: {
    fileUpload?: boolean;
    includes?: boolean;
  };
  required?: { include?: boolean };
  changeIncludesHandler: (options: OptionItem[]) => void;
  changeExcludesHandler: (options: OptionItem[]) => void;
  includes: Tag[];
  excludes: Tag[];
  useMeditLink?: boolean;
}
export const PhotoIncludeExcludeContainer: FC<Props> = ({
  clinicalPhotos,
  filesUploadHandler,
  uploadFiles,
  filesDeleteHandler,
  required,
  helperText,
  includes,
  excludes,
  error,
  changeIncludesHandler,
  changeExcludesHandler,
  useMeditLink,
}) => {
  const [complicationTags, setComplicationTags] = useState<
    ITreatmentCondition[]
  >([]);

  const fetch = async () => {
    const complicationTags = await caseService.getCaseTreatmentCondition();
    setComplicationTags(complicationTags);
  };

  useEffect(() => {
    fetch();
  }, []);

  const includeTags = complicationTags.flatMap((item) => {
    if (item.secondary) {
      return item.secondary.map((name) => ({
        id: item.id,
        name: `${item.name} (${name})`,
      }));
    }
    return item;
  });

  return (
    <Grid container spacing={3}>
      <Grid item md={4}>
        <FormLabel label="Clinical photos" required={!useMeditLink}>
          <FileUploadArea
            error={error?.fileUpload}
            helperText={helperText?.fileUpload}
            onFilesUpload={filesUploadHandler}
          />
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {clinicalPhotos.map((file: any) => (
              <UploadedFileCard
                key={file.id}
                file={file}
                onUpload={uploadFiles}
                onDelete={filesDeleteHandler}
              />
            ))}
          </Stack>
        </FormLabel>
      </Grid>
      <Grid item md={4}>
        <FormLabel label="Include Objectives" required={required?.include}>
          <SelectTagFields
            error={error?.includes}
            helperText={helperText?.includes}
            value={includes}
            options={includeTags}
            onChange={changeIncludesHandler}
            placeholder="Type or select..."
          />
        </FormLabel>
      </Grid>
      <Grid item md={4}>
        <FormLabel label="Exclude (Optional)">
          <SelectTagFields
            value={excludes}
            options={complicationTags}
            onChange={changeExcludesHandler}
            placeholder="Type or select..."
          />
        </FormLabel>
      </Grid>
    </Grid>
  );
};
