import React from "react";

import { Tabs } from "components/ui";
import { RxForm } from "components/common";
import { PaperContainer, TabsContainer } from "./Elements";
import { TreatmentPlan } from "./TreatmentPlan";
import { UploadedFiles } from "./UploadedFiles";

export const CaseInfoTabs = () => {
  return (
    <PaperContainer>
      <TabsContainer>
        <Tabs
          hideTab
          tabs={[
            {
              label: "Treatment Plan",
              tabId: "treatPlan",
              content: <TreatmentPlan />,
            },
            {
              label: "Uploaded files",
              tabId: "files",
              content: <UploadedFiles />,
            },
            {
              label: "RX Form",
              tabId: "rxForm",
              content: <RxForm />,
            },
          ]}
        />
      </TabsContainer>
    </PaperContainer>
  );
};
