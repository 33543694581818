import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import clsx from "clsx";

import "./styles.css";

interface Props {
  value?: any;
  onChange?: (value: number) => void;
  label?: string;
  inputClassName?: string;
  className?: string;
  minValue?: number;
  maxValue?: number;
  disable?: boolean;
  withOutAdornment?: boolean;
}

export const NumberField = ({
  value,
  label,
  className,
  inputClassName,
  onChange,
  minValue,
  maxValue,
  withOutAdornment = false,
}: Props) => {
  const [number, setNumber] = useState(value || 0);
  const parseValue = useCallback(
    (val: any) => {
      let parsedValue = parseInt(val);
      if (isNaN(parsedValue)) {
        return 0;
      }
      if (minValue !== undefined && parsedValue < minValue) {
        return minValue;
      }
      if (maxValue !== undefined && parsedValue > maxValue) {
        return maxValue;
      }
      return parsedValue;
    },
    [maxValue, minValue]
  );
  const changeValue = (type: boolean) => () => {
    const newValue = parseValue(type ? number + 1 : number - 1);
    if (onChange) {
      onChange(newValue);
    }
  };
  useEffect(() => {
    setNumber(value);
  }, [value]);
  const increment = (
    <Stack direction="row" alignItems="center">
      <Button
        disabled={maxValue !== undefined && parseInt(number) === maxValue}
        onClick={changeValue(true)}
        color="primary"
        size="small"
        variant="contained"
        className="number-field__button"
      >
        <AddIcon className="icon" fontSize="small" sx={{ fontSize: 14 }} />
      </Button>
    </Stack>
  );
  const decrement = (
    <Stack direction="row" alignItems="center">
      <Button
        disabled={minValue !== undefined && parseInt(number) === minValue}
        onClick={changeValue(false)}
        color="primary"
        size="small"
        variant="contained"
        className="number-field__button"
      >
        <RemoveIcon className="number-field__icon" sx={{ fontSize: 14 }} />
      </Button>
    </Stack>
  );
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseValue(event.target.value);
    setNumber(parsedValue);
    if (onChange) {
      onChange(parsedValue);
    }
  };
  return (
    <span className={clsx("number-field__container", className)}>
      {label && (
        <Typography variant="subtitle1" className="number-field__label">
          {label}
        </Typography>
      )}
      <Stack direction="row" spacing={0.25} alignItems="center">
        {!withOutAdornment && decrement}
        <TextField
          value={number}
          className={clsx("number-field__input", inputClassName)}
          onChange={changeHandler}
          margin="dense"
          variant="outlined"
          sx={{ p: 0 }}
        />
        {!withOutAdornment && increment}
      </Stack>
    </span>
  );
};
