import React, { MouseEventHandler } from "react";
import Stack from "@mui/material/Stack";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";

import { FontAwesomeSvgIcon } from "components/common";
import { Colors } from "consts/colors";

import { Step } from "../types";
import { TabSubTitle, TabTitle, StyledTab, EmptyBlock } from "./Elements";

interface Props extends Omit<Step, "stepContent" | "id"> {
  index: number;
  isActive: boolean;
  onClick: MouseEventHandler;
  disabled: boolean;
}

export const StepTab: React.VFC<Props> = (props) => {
  const requiredStepsText =
    typeof props.requiredStepFields === "boolean"
      ? "Response required"
      : props.requiredStepFields
      ? `${props.requiredStepFieldsDone || 0} of ${
          props.requiredStepFields
        } Required fields`
      : "No required fields";
  const titleColor = props.stepDone
    ? "white"
    : props.isActive
    ? Colors.PRIMARY
    : Colors.LIGHT_GREY;
  const subTitleColor = props.stepDone ? "white" : Colors.LIGHT_GREY;
  return (
    <StyledTab
      sx={{
        backgroundColor: props.stepDone ? Colors.SUCCESS : "white",
      }}
      disabled={props.disabled}
      onClick={props.onClick}
      label={
        <Stack direction="row" alignItems="center" spacing={3}>
          <Stack spacing={0.5}>
            <TabTitle variant="body1" color={titleColor}>
              {props.label}
            </TabTitle>
            <TabSubTitle variant="body2" color={subTitleColor}>
              {requiredStepsText}
            </TabSubTitle>
          </Stack>
          {props.stepDone ? (
            <FontAwesomeSvgIcon icon={faCheckCircle} sx={{ color: "white" }} />
          ) : (
            <EmptyBlock />
          )}
        </Stack>
      }
      {...a11yProps(props.index)}
    />
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
