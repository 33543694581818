import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer as currentUser } from "./currentUser";
import { reducer as newCase } from "./caseCreate";
import { reducer as invoicesCard } from "./invoicesCard";
import { reducer as dashboard } from "./dashboard";
import { reducer as shipping } from "./shipping";
import { reducer as createPayment } from "./createPayment";
import { reducer as patientCard } from "./patientDetail";
import { reducer as newOrder } from "./continuationOrderCreate";
import { reducer as search } from "./search";
import { reducer as accountSettings } from "./accountSettings";
import { reducer as userPreferences } from "./userPreferences";
import { reducer as createStepper } from "./createStepper";
import { reducer as treatmentPlan } from "./treatmentPlan";
import { reducer as requestRemake } from "./requestRemake";

const rootReducer = combineReducers({
  currentUser,
  invoicesCard,
  patientCard,
  dashboard,
  shipping,
  createPayment,
  newCase,
  newOrder,
  search,
  accountSettings,
  userPreferences,
  createStepper,
  treatmentPlan,
  requestRemake,
});

//TODO: добавить очищение сторов при размонтировании
export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === "development",
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
