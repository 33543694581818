import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import { FileUploadArea, UploadedFileCard } from "components/common";
import { FormLabel } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/caseCreate";

import { createFileForStore } from "utils/fileHelpers";

export const OtherFiles = () => {
  const otherFiles = useAppSelector(selectors.getOtherFilesSelector);
  const order = useAppSelector(selectors.getAllInfoSelector);
  const dispatch = useAppDispatch();
  const filesUploadHandler = (files: File[]) => {
    dispatch(actions.setOtherFiles(files.map(createFileForStore)));
  };
  const filesDeleteHandler = (name: string) => {
    dispatch(actions.deleteOtherFile(name));
  };
  const uploadFiles = (params: {
    fileId: string;
    controller: AbortController;
    contentType: string;
  }) => {
    dispatch(
      actions.downloadFile({
        ...params,
        fileType: "otherFiles",
      })
    );
  };
  return (
    <Grid container mt={3}>
      <Grid item md={6}>
        <FormLabel
          label="Upload your scans (STL format)"
          required={!order.preEval}
        >
          <FileUploadArea
            error={order.requiredFields.includes("otherFiles")}
            onFilesUpload={filesUploadHandler}
          />
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {otherFiles.map((file) => (
              <UploadedFileCard
                key={file.id}
                file={file}
                onUpload={uploadFiles}
                onDelete={filesDeleteHandler}
              />
            ))}
          </Stack>
        </FormLabel>
      </Grid>
    </Grid>
  );
};
