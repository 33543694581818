import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { PreviewIcon } from "components/common/FileCard/PreviewIcon";
import { getFileExtension, getFilesType } from "utils";
import React from "react";
import { styled } from "@mui/material";
import { FileNameTooltip } from "../common";
import Skeleton from "@mui/material/Skeleton";

interface Props {
  src: string;
  name: string;
  contentType: string;
  onDelete?: (name: string) => void;
}

const CardContainer = styled("div")`
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 4px;
`;

const CloseButtonContainer = styled("div")`
  position: absolute;
  cursor: pointer;
  z-index: 1000;
  right: 0;
  margin-top: -6px;
  margin-right: -6px;
`;

const CloseButton = styled(CancelRoundedIcon)`
  color: white;
  background-color: var(--disabled-color);
  border-radius: 50%;
  font-size: 16px;
  transition: 0.4s;
`;

const NameContainer = styled("span")`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 15px;
  width: 100%;
  color: white;
  font-size: 10px;
  line-height: 15px;
  padding-inline: 4px;
  background: var(--primary-color);
  text-align: center;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  font-weight: 600;
`;

export const PreviewUploadFile: React.VFC<Props> = ({
  src,
  name,
  contentType,
  onDelete,
}) => {
  const fileExtension = getFileExtension({ name, contentType });

  const deleteHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete(name);
    }
  };
  if (!src) {
    return (
      <CardContainer>
        <NameContainer>{fileExtension?.toUpperCase()}</NameContainer>
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          width={64}
          height={64}
        />
      </CardContainer>
    );
  }
  return (
    <FileNameTooltip fileName={name}>
      <CardContainer>
        {Boolean(onDelete) && (
          <CloseButtonContainer onClick={deleteHandler}>
            <CloseButton />
          </CloseButtonContainer>
        )}
        <NameContainer>{fileExtension?.toUpperCase()}</NameContainer>
        <PreviewIcon fileType={getFilesType({ contentType })} src={src} />
      </CardContainer>
    </FileNameTooltip>
  );
};
