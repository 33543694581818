import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ShippingTypes } from "lib/enum";
import { ShippingLevel } from "lib/types";
import { Select } from "components/ui";
import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/shipping";
import { SelectChangeEvent } from "@mui/material/Select";

export const InfoEditFields = () => {
  const shippingType = useAppSelector(selectors.shippingTypeSelector);
  const shippingLevel = useAppSelector(selectors.shippingLevelSelector);
  const showShipLvlSelect = useAppSelector(selectors.showShippingLevel);
  const dispatch = useAppDispatch();
  const upsDict = [
    { label: "UPS", value: ShippingTypes.UPS },
    { label: "Other", value: ShippingTypes.OTHER },
  ];
  const labelDict = [
    { label: "Cheapest", value: "cheapest" as ShippingLevel },
    { label: "Fastest", value: "fastest" as ShippingLevel },
  ];

  const changeTypeHandler = (event: SelectChangeEvent<typeof shippingType>) => {
    dispatch(actions.setShippingType(event.target.value as ShippingTypes));
  };
  const changeLevelHandler = (
    event: SelectChangeEvent<typeof shippingLevel>
  ) => {
    dispatch(actions.setShippingLevel(event.target.value as ShippingLevel));
  };
  return (
    <Box sx={{ marginInline: 5, mb: 4 }}>
      <Stack direction="row" spacing={3}>
        <Box maxWidth={168}>
          <Stack spacing={0.5}>
            <Typography variant="body2" color={Colors.TEXT_PRIMARY}>
              Type of shipping
            </Typography>
            <Select
              onChange={changeTypeHandler}
              value={shippingType}
              data={upsDict}
            />
          </Stack>
        </Box>
        {shippingType === ShippingTypes.UPS && showShipLvlSelect && (
          <Box maxWidth={168}>
            <Stack spacing={0.5}>
              <Typography variant="body2" color={Colors.TEXT_PRIMARY}>
                Shipping level
              </Typography>
              <Select
                onChange={changeLevelHandler}
                value={shippingLevel}
                data={labelDict}
              />
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
