import { useConfirm } from "hooks/confirm";
import { Colors } from "consts/colors";
export const usePatientConfirmation = () => {
  const confirm = useConfirm();
  return () =>
    confirm({
      title:
        "Are you sure you want to create a New Case and Rx for an existing patient?",
      description:
        "If you meant to place an additional order, please click “Create Next Order” from your active cases.",
      cancellationText: "Yes",
      confirmationText: "Patients list",
      clickableContent: true,
      dialogProps: {
        maxWidth: "sm",
        disableEscapeKeyDown: true,
      },
      actionProps: {
        sx: {
          justifyContent: "space-between",
          padding: 3,
        },
      },
      titleProps: {
        sx: { color: Colors.PRIMARY, textAlign: "center" },
      },
      cancellationButtonProps: {
        variant: "contained",
        color: "primary",
        size: "large",
        sx: { textTransform: "none" },
      },
      confirmationButtonProps: {
        variant: "contained",
        color: "primary",
        size: "large",
        sx: { textTransform: "none" },
      },
      allowClose: false,
    });
};
