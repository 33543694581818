import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { analyticsMethod } from "plugins/analytics";

import { useStyledSnackbar } from "hooks/notification";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/caseCreate";
// import { selectors } from "store/patientDetail";

import { CreateEvalCaseOrder } from "containers";

export const EditCasePage = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const navigate = useNavigate();
  const { caseInfo } = useAppSelector(selectors.getAllInfoSelector);
  const { showErrorNotice } = useStyledSnackbar();
  const dispatch = useAppDispatch();
  analyticsMethod(
    "page",
    "Case",
    {
      title: "Edit case form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );

  useEffect(() => {
    if (caseId) {
      dispatch(actions.setEditing(true));
      dispatch(actions.fetchContinueOrderInfo(caseId))
        .unwrap()
        .catch((e) => {
          showErrorNotice(e.message);
        });
    }
  }, [caseId, dispatch, showErrorNotice]);

  useEffect(() => {
    if (caseInfo && caseInfo.treatPlanApprovedDate !== null) {
      navigate(`/cases/${caseId}`);
    }
  }, [caseInfo]);

  return <CreateEvalCaseOrder title={`Edit case #${caseId}`} />;
};
