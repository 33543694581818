import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Radio, RadioGroup, styled } from "@mui/material";

import { Colors } from "consts/colors";
import { InformBlock } from "components/common";
import { Button } from "components/ui";

import { ContainerProps } from "./types";

import { MainContainer } from "../main";

const StyledButton = styled(Button)`
  height: 48px;
  padding-inline: 20px;
`;

export const QuestionContainer: React.VFC<ContainerProps> = ({
  title,
  subTitle,
  questions,
  cancelHandler,
}) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };

  const goForwardHandler = () => {
    navigate(questions[selectedOption!].to);
  };

  const selectOptionHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(Number(e.target.value));
  };

  return (
    <MainContainer>
      <Stack
        px={4}
        pt={8}
        pb={5}
        sx={{ height: "100%", boxSizing: "border-box" }}
      >
        <Typography variant="h1" color={Colors.PRIMARY}>
          {title}
        </Typography>
        <Grid container spacing={3} mt={8}>
          <Grid item md={7}>
            <Stack alignItems="flex-start" spacing={4}>
              <Typography variant="h2" color={Colors.SECONDARY}>
                {subTitle}
              </Typography>
              <Stack spacing={3} alignItems="flex-start">
                <RadioGroup
                  value={selectedOption}
                  onChange={selectOptionHandler}
                >
                  {questions.map(({ label }, index) => {
                    return (
                      <FormControlLabel
                        key={`radio-${index}`}
                        value={index}
                        control={<Radio />}
                        label={label}
                      />
                    );
                  })}
                </RadioGroup>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={5}>
            {Boolean(
              selectedOption !== null && questions[selectedOption]?.notice
            ) && (
              <InformBlock
                title={questions[selectedOption!]?.noticeTitle ?? "Notice"}
                text={questions[selectedOption!].notice!}
              />
            )}
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Stack direction="row" spacing={3}>
            <StyledButton
              data-intercom-target="Cancel question button"
              onClick={cancelHandler}
              variant="text"
              sx={{ color: Colors.DISABLED }}
            >
              Cancel
            </StyledButton>
          </Stack>
          <Stack spacing={3} direction="row">
            <StyledButton
              data-intercom-target="Back question button"
              onClick={goBackHandler}
              variant="outlined"
              color="secondary"
            >
              Back
            </StyledButton>
            <StyledButton
              data-intercom-target="Next question button"
              disabled={selectedOption === null}
              onClick={goForwardHandler}
              variant="contained"
              color="primary"
            >
              Next
            </StyledButton>
          </Stack>
        </Stack>
      </Stack>
    </MainContainer>
  );
};
