import React from "react";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { faMapPin } from "@fortawesome/free-solid-svg-icons/faMapPin";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import Stack from "@mui/material/Stack";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";

import { useStyledSnackbar } from "hooks/notification";
import { IExtEventDashboardDto } from "lib/dto";
import { useAppDispatch } from "hooks/redux";
import { Colors } from "consts/colors";
import { FontAwesomeSvgIcon } from "components/common";
import { Button, Menu } from "components/ui";
import { actions } from "store/dashboard";

import { useButtonActions } from "./helpers";
import "./styles.css";

interface Props {
  icon: React.ReactElement<SvgIconProps>;
  pinned: boolean;
  event: IExtEventDashboardDto;
}
export const EventItem: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const { label, clickHandler } = useButtonActions(
    props.event.operation,
    props.event.targetId,
    props.event.caseId
  );
  const itemActions = [
    {
      label: "Remind me later",
      icon: (
        <FontAwesomeSvgIcon icon={faClock} fontSize="inherit" color="primary" />
      ),
      subItems: [
        {
          label: "1 day later",
          handler: () => {
            dispatch(
              actions.remindLater({
                dashboardEventId: props.event.id,
                dayCount: 1,
              })
            );
          },
        },
        {
          label: "3 days later",
          handler: () => {
            dispatch(
              actions.remindLater({
                dashboardEventId: props.event.id,
                dayCount: 3,
              })
            );
          },
        },
        {
          label: "7 days later",
          handler: () => {
            dispatch(
              actions.remindLater({
                dashboardEventId: props.event.id,
                dayCount: 7,
              })
            );
          },
        },
      ],
    },
  ];
  const dismissHandler = () => {
    dispatch(actions.dismissEvent(props.event.id)).catch((error) => {
      showErrorNotice(error.message);
    });
  };
  return (
    <div className="task-item">
      <span className="task-item__icon">
        {React.cloneElement(props.icon, {
          color: "inherit",
          fontSize: "small",
        })}
      </span>
      <div>{props.children}</div>
      <Stack alignItems="center">
        {props.pinned && (
          <FontAwesomeSvgIcon
            icon={faMapPin}
            sx={{ p: 0.5, fontSize: "16px" }}
            fontSize="inherit"
            color="primary"
          />
        )}
      </Stack>
      <div>
        <Menu items={itemActions}>
          <IconButton sx={{ p: 0.5 }}>
            <MoreHorizIcon sx={{ color: Colors.TEXT_GREY }} />
          </IconButton>
        </Menu>
      </div>
      <Button variant="contained" onClick={clickHandler}>
        {label}
      </Button>
      <Tooltip arrow placement="top" title="Dismiss">
        <IconButton
          sx={{ p: 0.5, borderRadius: 0.5, width: 32 }}
          onClick={dismissHandler}
        >
          <DeleteOutlineOutlinedIcon sx={{ color: Colors.TEXT_GREY }} />
        </IconButton>
      </Tooltip>
    </div>
  );
};
