export enum EventDashboardOperation {
  INVALID = "INVALID",
  INVOICING = "INVOICING",
  START_WEARING = "START_WEARING",
  SHEDULING = "SHEDULING",
  TIME_FOR_NEXT_ORDER = "TIME_FOR_NEXT_ORDER",
  TIME_FOR_RETAINER_ORDER = "TIME_FOR_RETAINER_ORDER",
  CREATE_ALIGNER_ORDER = "CREATE_ALIGNER_ORDER",
  SEND_IMPRESSIONS = "SEND_IMPRESSIONS",
  VERIFICATION_KEY = "VERIFICATION_KEY",
}
