import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";

import { InfoWithLabel, ToothArches } from "components/common";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { Compliactions } from "./types";
import { ToothTooltips } from "./ToothTooltips";
import { PopperProps } from "@mui/material/Popper";
import { complications } from "lib/dictionaries";

const getPlacement = (toothNum: number): PopperProps["placement"] => {
  if (toothNum < 8) {
    return "left-start";
  }
  if (toothNum > 7 && toothNum < 16) {
    return "right-start";
  }

  if (toothNum > 23 && toothNum < 32) {
    return "left-end";
  }

  return "right-end";
};
export const Complications = () => {
  const { isLoading, caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const [state, setState] = useState<{
    anchorEl?: PopperProps["anchorEl"];
    placement: PopperProps["placement"];
    text: string;
  }>({
    text: "",
    placement: "auto",
  });
  const complicationTooth = useMemo(() => {
    if (!caseInfo?.additionalInfo.complicationsTooth) {
      return [];
    }
    const teeth: Compliactions = JSON.parse(
      caseInfo?.additionalInfo.complicationsTooth
    );
    return Object.values(teeth).map((item) => item.fill);
  }, [caseInfo?.additionalInfo.complicationsTooth]);
  const hoverHandler = (
    event: React.MouseEvent | null,
    toothNumber: string | null
  ) => {
    if (!toothNumber) {
      setState({
        anchorEl: null,
        placement: "auto",
        text: "",
      });
      return;
    }
    const toothId = toothNumber.split("-")[1];
    const toothIdx = Number(toothId) - 1;
    if (complicationTooth[toothIdx]) {
      setState({
        anchorEl: event!.target as any,
        placement: getPlacement(toothIdx),
        text: complications[complicationTooth[toothIdx]!],
      });
    }
  };
  if (
    !complicationTooth.length ||
    !complicationTooth.some((tooth) => Boolean(tooth))
  ) {
    return null;
  }
  return (
    <InfoWithLabel
      isLoading={isLoading}
      direction="column"
      label="EXISTING COMPLICATIONS"
    >
      <Box mt={2}>
        <ToothTooltips
          anchorEl={state.anchorEl}
          text={state.text}
          placement={state.placement}
          open={Boolean(state.anchorEl)}
        />
        <ToothArches
          onHover={hoverHandler}
          teeth={complicationTooth}
          width={168}
          height={256}
        />
      </Box>
    </InfoWithLabel>
  );
};
