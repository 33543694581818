import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "components/common";
import {
  PatientsDetailsPage,
  PatientsPage,
  PatientsSearchPage,
  EditCasePage,
} from "pages";

export const PatientsRoute = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <PatientsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/search"
        element={
          <ProtectedRoute>
            <PatientsSearchPage />
          </ProtectedRoute>
        }
      />
      <Route
        path=":patientId"
        element={
          <ProtectedRoute>
            <PatientsDetailsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path=":caseId/edit"
        element={
          <ProtectedRoute>
            <EditCasePage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
