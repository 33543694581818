import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "components/common";
import { ShippingsPage, ShippingCreatePage } from "pages";

export const ShippingsRoute = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <ShippingsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="new/:orderId"
        element={
          <ProtectedRoute>
            <ShippingCreatePage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
