import { IExtEventDashboardDto } from "lib/dto";

export interface DashboardState {
  isLoading: boolean;
  events: IExtEventDashboardDto[];
}

export enum DashboardActions {
  DASHBOARD_EVENTS_FETCHED = "dashboard/events/fetch",
  DASHBOARD_EVENT_PINNED = "dashboard/event/pin",
  DASHBOARD_EVENT_REMIND = "dashboard/event/remind",
  DASHBOARD_EVENT_DISMISS = "dashboard/event/dismiss",
}
