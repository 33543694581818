import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/patientDetail";
import { Button, TextField } from "components/ui";

import { PaperContainer } from "./Elements";
import { CaseFinishRate } from "lib/enum";
import { InfoWithLabel } from "components/common";

const COMMENT_MAX_LENGTH = 10000;

export const RatingQuestion = () => {
  const dispatch = useAppDispatch();
  const { showRating, ratingCompleted, raiting, ratingComment } =
    useAppSelector(selectors.allInfoSelector);
  const isRatingCommentLong = ratingComment.length > COMMENT_MAX_LENGTH;

  const changeRatingHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setRating(event.target.value as CaseFinishRate));
  };

  const changeRatingComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setRatingComment(event.target.value));
  };

  const submitRatingHandler = () => {
    dispatch(actions.submitRating());
  };

  if (ratingCompleted) {
    return (
      <Box flexGrow={1}>
        <PaperContainer>
          <Stack
            px={2.5}
            py={2.5}
            alignItems="center"
            justifyContent="center"
            minHeight={172}
          >
            <Typography fontSize={14} fontWeight={600} color={Colors.PRIMARY}>
              Thank you for feedback!
            </Typography>
          </Stack>
        </PaperContainer>
      </Box>
    );
  }

  if (!showRating) {
    return null;
  }

  return (
    <Box flexGrow={1}>
      <PaperContainer>
        <Stack px={2.5} py={2.5} spacing={1}>
          <Box>
            <Typography fontSize={14} fontWeight={600} color={Colors.PRIMARY}>
              Case completed.
            </Typography>
            <Typography
              variant="body2"
              fontWeight={600}
              color={Colors.TEXT_SECONDARY}
              mt={1}
            >
              Please, rate the result vs objective
            </Typography>
          </Box>

          <RadioGroup onChange={changeRatingHandler}>
            <FormControlLabel
              value={CaseFinishRate.EXCELLENT}
              control={<Radio size="small" />}
              label="Excellent"
              componentsProps={{
                typography: {
                  fontSize: 12,
                  color: Colors.TEXT_PRIMARY,
                },
              }}
            />
            <FormControlLabel
              value={CaseFinishRate.VERY_GOOD}
              control={<Radio size="small" />}
              label="Very good"
              componentsProps={{
                typography: {
                  fontSize: 12,
                  color: Colors.TEXT_PRIMARY,
                },
              }}
            />
            <FormControlLabel
              value={CaseFinishRate.GOOD}
              control={<Radio size="small" />}
              label="Good"
              componentsProps={{
                typography: {
                  fontSize: 12,
                  color: Colors.TEXT_PRIMARY,
                },
              }}
            />
          </RadioGroup>
          {raiting && raiting !== CaseFinishRate.EXCELLENT && (
            <Box>
              <InfoWithLabel
                labelStyleSx={{ color: Colors.TEXT_SECONDARY }}
                direction="column"
                label="What could have been done better?"
              >
                <TextField
                  value={ratingComment}
                  onChange={changeRatingComment}
                  label="Comment"
                  margin="dense"
                  variant="outlined"
                  multiline
                  error={isRatingCommentLong}
                  helperText={
                    isRatingCommentLong
                      ? "You can enter  10000 symbols only "
                      : ""
                  }
                  fullWidth
                />
              </InfoWithLabel>
            </Box>
          )}
          <Box>
            <Button
              onClick={submitRatingHandler}
              disabled={!raiting || isRatingCommentLong}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </PaperContainer>
    </Box>
  );
};
