import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const ResulContainer = styled(Box)`
  padding: 18px;
  max-height: 50vh;
  overflow: auto;
`;

export const ExpansionPanel = styled(MuiAccordion)`
  box-shadow: none;
`;
export const ExpansionPanelSummary = styled(MuiAccordionSummary)`
  padding: 0 24px;
  min-height: 48px !important;
`;
export const ExpansionPanelDetails = styled(MuiAccordionDetails)`
  flex-direction: column;
  padding: 8px 16px;
`;
