import { styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { Colors } from "consts/colors";

export const SearchContainer = styled("div")`
  position: relative;
  font-size: 14px;
  color: ${Colors.LABEL_GREY};
`;

export const SearchIconWrapper = styled("div")`
  padding: 0 16px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledInputBase = styled(InputBase)(() => ({
  color: "inherit",
  borderRadius: "4px",
  border: `1px solid ${Colors.LABEL_GREY}`,
  "& .MuiInputBase-input": {
    padding: "8px 8px 8px 48px",
  },
}));
