import { styled, css, Typography } from "@mui/material";
import { Colors } from "consts/colors";

const CommonCss = css`
  display: inline-flex;
  align-items: center;
`;
const Wrapper = styled("header")`
  display: flex;
  justify-content: space-between;
  padding-inline: 44px 50px;
  padding-block: 25px 10px;
`;
const Left = styled("div")`
  display: flex;
  align-items: start;
  flex-direction: column;
  color: ${Colors.PRIMARY};
`;
const Right = styled("div")`
  ${CommonCss}
`;

type HeaderProps = {
  title: string | JSX.Element;
  subtitle?: JSX.Element;
  right?: JSX.Element;
};
export const Header = ({ title, right, subtitle }: HeaderProps) => {
  return (
    <Wrapper>
      <Left>
        <Typography variant="h1">{title}</Typography>
        {subtitle && <div>{subtitle}</div>}
      </Left>
      <Right>{right}</Right>
    </Wrapper>
  );
};
