import React, { useEffect, useMemo, useReducer, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";

import { useStyledSnackbar } from "hooks/notification";
import { PaymentMethodType } from "lib/enum";
import { useAppSelector } from "hooks/redux";
import { Button, Menu, Select } from "components/ui";
import { selectors } from "store/currentUser";
import { INFO_TEXT, INFO_TITLE } from "./consts";
import {
  closeModal,
  initState,
  reducer,
  showModal,
  submitAction,
  getGrantAction,
  changeAction,
  checkedChangeAction,
  fetchInfoAction,
  setDefaultAction,
} from "./state";

interface Props {
  officeId?: number | string;
}
export const PayAllButton: React.FC<Props> = ({ officeId }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isSuccess = searchParams.has("isSuccess")
    ? searchParams.get("isSuccess") === "true"
    : undefined;
  const paymentOfficeLink = useRef<object>({});
  const { offices } = useAppSelector(selectors.getAllUserInfo);
  const currentOffice = officeId
    ? offices?.find(({ id }) => id === Number(officeId))
    : undefined;
  const { showErrorNotice, showSuccessNotice } = useStyledSnackbar();

  searchParams.delete("isSuccess");
  const [state, dispatch] = useReducer(reducer, {
    ...initState,
    stripeSourceId: currentOffice?.stripeDefaultSourceId || "",
    defaultCard: Boolean(currentOffice?.stripeDefaultSourceId),
    open: searchParams.has("payAll"),
  });
  const cardDictionary = useMemo(() => {
    return state.paymentsMethods?.map(
      ({
        stripeSourceId,
        last4,
        expMonth,
        expYear,
        paymentMethodType,
        bankName,
      }) => {
        return {
          label:
            paymentMethodType === PaymentMethodType.CARD
              ? `${last4} (${expMonth.toString().padStart(2, "0")}/${expYear
                  .toString()
                  .slice(-2)})`
              : `ACH Debit ${bankName} ${last4}`,
          value: stripeSourceId,
        };
      }
    );
  }, [state.paymentsMethods]);
  const isMethodsExists = Boolean(state.paymentsMethods?.length > 0);
  const redirectHandler = () => {
    const orgId: number | undefined = currentOffice
      ? currentOffice.orgId
      : undefined;
    if (orgId) {
      navigate(`/payments/new?org=${orgId}&office=${officeId}&all=true`);
    }
  };
  const payActions: any[] = [
    {
      label: `Pay by CC or Bank account`,
      handler: () => {
        showModal(dispatch)();
      },
    },
  ];
  const outstandingBalanceCount = Object.values(
    state.outstandingBalance
  ).reduce((result: number, itemBalance: number) => result + itemBalance, 0);
  if (officeId && currentOffice?.balance! >= outstandingBalanceCount) {
    payActions.push({
      label: `Pay All by office's balance`,
      handler: redirectHandler,
    });
  }

  const changeHandler = changeAction(dispatch, currentOffice);
  const checkedChangeHandler = checkedChangeAction(dispatch);
  const getGrantHandler = () => {
    getGrantAction(dispatch)().catch((error) => {
      showErrorNotice(error.message);
    });
  };
  const submitHandler = () => {
    const paymentType = state.paymentsMethods.find(
      ({ stripeSourceId }) => stripeSourceId === state.stripeSourceId
    );
    submitAction(dispatch)(paymentOfficeLink, state, currentOffice!, () => {
      showSuccessNotice(
        paymentType?.paymentMethodType === "us_bank_account"
          ? `Payment has been initiated for amount $ ${outstandingBalanceCount.toFixed(
              2
            )}`
          : `Payment has been performed for amount $ ${outstandingBalanceCount.toFixed(
              2
            )}`
      );
    }).catch((error) => {
      showErrorNotice(error.message);
    });
  };
  useEffect(() => {
    if (isSuccess !== undefined) {
      setSearchParams("");
    }
    if (isSuccess) {
      showSuccessNotice("Payment method successfully added");
    }
    if (isSuccess === false) {
      showErrorNotice("Error when adding a payment method.");
    }
  }, [isSuccess, showSuccessNotice, showErrorNotice, setSearchParams]);
  useEffect(() => {
    if (currentOffice && currentOffice?.stripeDefaultSourceId) {
      dispatch(setDefaultAction(currentOffice?.stripeDefaultSourceId));
    }
  }, [currentOffice]);

  useEffect(() => {
    if (currentOffice) {
      fetchInfoAction(dispatch, currentOffice, paymentOfficeLink).catch(
        (error) => showErrorNotice(error.message)
      );
    }
  }, [currentOffice, showErrorNotice]);

  return (
    <>
      <Menu items={payActions}>
        <Button
          disabled={!officeId || !outstandingBalanceCount}
          variant="contained"
        >
          Pay all
        </Button>
      </Menu>
      <Dialog open={state.open} maxWidth="xs" fullWidth>
        <DialogTitle>
          {isMethodsExists ? "Choose card to pay" : INFO_TITLE}
        </DialogTitle>
        <DialogContent>
          {isMethodsExists ? (
            <>
              <div>
                <Select
                  fullWidth
                  value={state.stripeSourceId}
                  data={cardDictionary}
                  onChange={changeHandler}
                  margin="dense"
                  sx={{ mt: 1 }}
                />
                <Button
                  onClick={getGrantHandler}
                  startIcon={<AddIcon />}
                  color="primary"
                >
                  Add new payment method
                </Button>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="defaultCard"
                    checked={state.defaultCard}
                    onChange={checkedChangeHandler}
                  />
                }
                label="Default payment method."
              />
            </>
          ) : (
            <DialogContentText>{INFO_TEXT}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal(dispatch)}>Cancel</Button>
          <Button
            loading={state.loading}
            disabled={!state.stripeSourceId && isMethodsExists}
            onClick={isMethodsExists ? submitHandler : getGrantHandler}
            color="primary"
            variant="contained"
          >
            {isMethodsExists ? "Pay" : "Permit"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
