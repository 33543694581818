import {
  AnyAction,
  AsyncThunk,
  createReducer,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { generateUniqueID } from "utils/idHelper";
import { Colors } from "consts/colors";
import { IExtCaseDto, IExtOfficeStaffDto, IOfficeDto } from "lib/dto";
import { IUploadedFile } from "lib/model";

import { OrderCreateState } from "./types";
import { initState } from "./state";
import * as actions from "./actions";
import { Questions } from "../../lib/enum";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return (
    action.type.startsWith("case") && action.type.endsWith("/send/rejected")
  );
}

function isPendingAction(action: AnyAction): action is PendingAction {
  return (
    action.type.startsWith("case") && action.type.endsWith("/send/pending")
  );
}

function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return (
    action.type.startsWith("case") && action.type.endsWith("/send/fulfilled")
  );
}

const fetchStateSetter = (
  state: Draft<OrderCreateState>,
  payload: {
    caseInfo: IExtCaseDto;
    clinicalPhotos: IUploadedFile[];
    otherFiles: IUploadedFile[];
    selectedOffice: IOfficeDto | null;
  }
) => {
  state.caseInfo = payload.caseInfo;
  state.preEval = payload.caseInfo.needPreEvaluation;

  state.selectedOffice = payload.selectedOffice;

  if (payload.caseInfo.additionalInfo.wearSchedule) {
    state.wearSchedule = payload.caseInfo.additionalInfo.wearSchedule;
  }

  if (payload.caseInfo.additionalInfo.caseBudget) {
    state.caseBudget = payload.caseInfo.additionalInfo.caseBudget;
  }

  if (payload.caseInfo.additionalInfo.caseBudgetCost) {
    state.caseBudgetCost = payload.caseInfo.additionalInfo.caseBudgetCost;
  }

  if (payload.caseInfo.additionalInfo.txPlanComplexity) {
    state.txPlanComplexity = payload.caseInfo.additionalInfo.txPlanComplexity;
  }

  if (payload.caseInfo.additionalInfo.attachPrefer) {
    state.attachments = payload.caseInfo.additionalInfo.attachPrefer;
  }

  if (payload.caseInfo.additionalInfo.include) {
    state.includes = payload.caseInfo.additionalInfo.include
      ? JSON.parse(payload.caseInfo.additionalInfo.include)
      : [];
  }

  if (payload.caseInfo.additionalInfo.extractionIPR) {
    state.ipr = payload.caseInfo.additionalInfo.extractionIPR;
  }

  state.caseId = payload.caseInfo.id;

  state.lowerArch = payload.caseInfo.lowerArch;
  state.upperArch = payload.caseInfo.upperArch;

  const complications: any = payload.caseInfo.additionalInfo.complicationsTooth
    ? JSON.parse(payload.caseInfo.additionalInfo.complicationsTooth)
    : "";
  if (complications) {
    state.complications = Object.values(complications).map(
      ({ fill }: any) => fill
    );
  }

  const extractions = payload.caseInfo.additionalInfo.extractionTooth
    ? JSON.parse(payload.caseInfo.additionalInfo.extractionTooth)
    : "";
  if (extractions) {
    state.extractions = initState.extractions.map((tooth, index) => {
      if (extractions.includes(`${index}_p`)) {
        return Colors.TOOTH_WHITE;
      }
      return extractions.includes(index) ? Colors.DISABLED : null;
    });
  }

  state.excludes = payload.caseInfo.additionalInfo.exclude
    ? JSON.parse(payload.caseInfo.additionalInfo.exclude)
    : [];
  state.notes = payload.caseInfo.additionalInfo.other;
  state.clinicalPhotos = payload.clinicalPhotos;
  state.otherFiles = payload.otherFiles;
  state.patient = {
    lastName: payload.caseInfo.patient.lastName || "",
    firstName: payload.caseInfo.patient.firstName || "",
    email: payload.caseInfo.patient.email || "",
    gender:
      payload.caseInfo.patient.gender === null
        ? ""
        : payload.caseInfo.patient.gender
        ? "1"
        : "0",
    birthDate: payload.caseInfo.patient.birthDate,
    phone: payload.caseInfo.patient.phone || "",
  };
  if (payload.caseInfo?.externalCases?.DENTAL_MONITORING?.enabled) {
    state.dmEnabled = "1";
    state.showDm = true;
    state.dmNumber =
      payload.caseInfo?.externalCases?.DENTAL_MONITORING?.externalId;
  }
};

export const reducer = createReducer<OrderCreateState>(initState, (builder) => {
  builder
    .addCase(actions.setEditing, (state, { payload }) => {
      state.isEditing = payload;
    })
    .addCase(actions.setIsRetainer, (state, { payload }) => {
      state.isRetainer = payload;
    })
    .addCase(actions.setSendScansViaMeditLink, (state, { payload }) => {
      state.useMeditLink = payload;
    })
    .addCase(actions.setCaseBudget, (state, { payload }) => {
      state[payload.name] = payload.value as any;
    })
    .addCase(actions.setPatientFields, (state, action) => {
      state.patient = {
        ...state.patient,
        ...action.payload,
      };
      const filledFields = Object.entries(action.payload).reduce(
        (previousValue, currentValue) => {
          if (currentValue[1]) {
            previousValue.push(`patient.${currentValue[0]}`);
          }
          return previousValue;
        },
        [] as string[]
      );
      state.requiredFields = state.requiredFields.filter(
        (item) => !filledFields.includes(item)
      );
    })
    .addCase(actions.setIncludeTags, (state, action) => {
      state.includes = action.payload;
      if (action.payload.length) {
        state.requiredFields = state.requiredFields.filter(
          (item) => item !== "includes"
        );
      }
    })
    .addCase(actions.setPreEval, (state, action) => {
      state.preEval = action.payload;
    })
    .addCase(actions.downloadFile.fulfilled, (state, action) => {
      const fileIdx = state[action.payload.fileType].findIndex(
        ({ id }) => id === action.payload.fileId
      );
      if (fileIdx !== -1) {
        state[action.payload.fileType][fileIdx].src = action.payload.src;
      }
    })
    .addCase(actions.fetchDoctorInfo.fulfilled, (state, action) => {
      if (action.payload.preferences.ATTACHMENTS_PREFERENCES) {
        state.attachments =
          action.payload.preferences.ATTACHMENTS_PREFERENCES.value;
      }
      if (action.payload.preferences.IPR_PREFERENCES) {
        state.ipr = action.payload.preferences.IPR_PREFERENCES.value;
      }
      if (action.payload.preferences.ADDITIONAL_INFO) {
        state.notes = action.payload.preferences.ADDITIONAL_INFO.value;
      }
      if (action.payload.preferences.PLANNED_WEAR_SCHEDULE) {
        state.wearSchedule =
          action.payload.preferences.PLANNED_WEAR_SCHEDULE.value;
      }

      if (action.payload.preferences.STEPS_NUMBER) {
        state.stepsType = !["3", "5", "10"].includes(
          action.payload.preferences.STEPS_NUMBER.value
        )
          ? "custom"
          : action.payload.preferences.STEPS_NUMBER.value;
        state.customStepsNum = Number(
          action.payload.preferences.STEPS_NUMBER.value
        );
      }
      state.showDm = action.payload.showDM;
    })
    .addCase(actions.searchPatients.fulfilled, (state, action) => {
      state.patients = action.payload.slice();
    })
    .addCase(actions.clearPatients, (state) => {
      state.patients = [];
    })
    .addCase(actions.setExcludeTags, (state, action) => {
      state.excludes = action.payload;
    })
    .addCase(actions.setPreference, (state, action) => {
      state[action.payload.name] = action.payload.value;
      state.requiredFields = state.requiredFields.filter(
        (item) => item !== action.payload.name
      );
    })
    .addCase(actions.toggleArch, (state, action) => {
      if (!state[action.payload]) {
        state.requiredFields = state.requiredFields.filter(
          (item) => item !== "arches"
        );
      }
      state[action.payload] = !state[action.payload];
    })
    .addCase(actions.setWearSchedule, (state, action) => {
      if (action.payload) {
        state.requiredFields = state.requiredFields.filter(
          (item) => item !== "wearSchedule"
        );
      }
      state.wearSchedule = action.payload;
    })
    .addCase(actions.setRemoveAttachment, (state, action) => {
      state.removeAttachment = action.payload;
    })
    .addCase(actions.setNotes, (state, action) => {
      state.notes = action.payload;
    })
    .addCase(actions.setDmEnabled, (state, action) => {
      state.dmEnabled = action.payload;
      if (state.dmEnabled === Questions.NO) {
        state.requiredFields = state.requiredFields.filter(
          (item) =>
            !["patient.phone", "patient.email", "patient.birthDate"].includes(
              item
            )
        );
      }
    })
    .addCase(actions.setDmNumber, (state, action) => {
      state.dmNumber = action.payload;
      if (state.dmNumber) {
        state.requiredFields = state.requiredFields.filter(
          (item) =>
            !["patient.phone", "patient.email", "patient.birthDate"].includes(
              item
            )
        );
      }
    })
    .addCase(actions.setOfficeId, (state, action) => {
      state.officeStaffId = action.payload.officeId;
      state.showDm = action.payload.showDM;
      state.dmEnabled = action.payload.dmEnabled ? Questions.YES : Questions.NO;
    })
    .addCase(actions.setDoctorId, (state, action) => {
      state.doctorId = action.payload;
    })
    .addCase(actions.setProductTypeId, (state, action) => {
      state.productTypeId = action.payload;
    })
    .addCase(actions.setRequiredState, (state, action) => {
      if (!state.requiredFields.includes(action.payload)) {
        state.requiredFields = [...state.requiredFields, action.payload];
      }
    })
    .addCase(actions.setOrderItem, (state, action) => {
      state.orderItems = {
        ...state.orderItems,
        ...action.payload,
      };
    })
    .addCase(actions.setStepsType, (state, action) => {
      state.stepsType = action.payload;
      state.customStepsNum = isNaN(Number(action.payload))
        ? 1
        : Number(action.payload);
    })
    .addCase(actions.setCustomSteps, (state, action) => {
      state.customStepsNum =
        !isNaN(Number(action.payload)) && Number(action.payload) < 1
          ? 1
          : Number(action.payload);
    })
    .addCase(actions.setComplications, (state, action) => {
      state.complications[action.payload.toothNumber] = action.payload.value;
    })
    .addCase(actions.setExtractions, (state, action) => {
      state.extractions[action.payload.toothNumber] = action.payload.value;
    })
    .addCase(actions.setClinicalPhotos, (state, action) => {
      const clinicalPhotoNames = state.clinicalPhotos.map(({ name }) => name);
      state.clinicalPhotos = [
        ...state.clinicalPhotos,
        ...action.payload
          .filter(({ name }) => !clinicalPhotoNames.includes(name))
          .map((file) => ({
            id: generateUniqueID(),
            ...file,
          })),
      ];
      state.requiredFields = state.requiredFields.filter(
        (item) => item !== "clinicalPhotos"
      );
    })
    .addCase(actions.setOtherFiles, (state, action) => {
      const otherFilesName = state.otherFiles.map(({ name }) => name);
      state.otherFiles = [
        ...state.otherFiles,
        ...action.payload
          .filter(({ name }) => !otherFilesName.includes(name))
          .map((file) => ({
            id: generateUniqueID(),
            ...file,
          })),
      ];
      state.requiredFields = state.requiredFields.filter(
        (item) => item !== "otherFiles"
      );
    })
    .addCase(actions.deleteClinicalPhoto, (state, action) => {
      state.clinicalPhotos = state.clinicalPhotos.filter(
        ({ name }) => name !== action.payload
      );
    })
    .addCase(actions.deleteOtherFile, (state, action) => {
      state.otherFiles = state.otherFiles.filter(
        ({ name }) => name !== action.payload
      );
    })
    .addCase(actions.updateCaseOrder.fulfilled, (state, action) => {
      state.orderId = action.payload.orderInfo.id;
      state.caseId = action.payload.caseInfo.id;
    })
    .addCase(actions.createShippingForOrder.fulfilled, (state, action) => {
      state.shippingLabel = action.payload;
      state.isLoading = false;
    })
    .addCase(actions.createOrder.fulfilled, (state, action) => {
      state.orderId = action.payload.orderId;
      state.caseId = action.payload.caseId;
      state.otherFiles = state.otherFiles.map((file) => ({
        ...file,
        unDeletable: true,
      }));
      state.clinicalPhotos = state.clinicalPhotos.map((file) => ({
        ...file,
        unDeletable: true,
      }));
      state.isLoading = false;
    })
    .addCase(actions.fetchContinueOrderInfo.fulfilled, (state, action) => {
      state.orderId = action.payload.orderInfo.id;
      state.customStepsNum = action.payload.orderInfo.alignersMaxQty!;
      state.stepsType = [3, 5, 10].includes(
        action.payload.orderInfo.alignersMaxQty!
      )
        ? action.payload.orderInfo.alignersMaxQty!.toString()
        : "custom";
      fetchStateSetter(state, action.payload);
    })
    .addCase(actions.fetchOnlyCaseInfo.fulfilled, (state, action) => {
      state.createOnlyOrder = true;
      fetchStateSetter(state, action.payload);
    })
    .addCase(actions.clearState, () => {
      return initState;
    })
    .addCase(actions.getStaffOfficeByUserId.fulfilled, (state, action) => {
      state.selectedOffice = action.payload;
    })
    .addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    })
    .addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    })
    .addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
});
