export enum TxPlanComplexityEnum {
  SIMPLE = "0",
  MODERATE = "1",
  COMPLEX = "2",
}

export const TxPlanComplexity: Record<TxPlanComplexityEnum, string> = {
  [TxPlanComplexityEnum.SIMPLE]: "Simple (Cosmetic)",
  [TxPlanComplexityEnum.MODERATE]: "Moderate (Cosmetic + Mild Occlusion)",
  [TxPlanComplexityEnum.COMPLEX]: "Complex (Comprehensive Orthodontic Care)",
};
