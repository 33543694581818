import React from "react";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery, styled } from "@mui/material";
import { StlViewer } from "react-stl-viewer";
import { FileTypes } from "lib/types";
import { VideoViewer } from "./VideoViewer";

interface Props {
  fileType: FileTypes;
  fileSrc: any;
}

const ImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  minWidth: 400,
  height: 400,
  maxWidth: "100%",
  backgroundColor: "white",
  [theme.breakpoints.up("xl")]: {
    minWidth: 600,
    height: 600,
  },
  [theme.breakpoints.down("xs")]: {
    minWidth: 250,
    height: 250,
  },
}));

const Image = styled("img")`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
`;

export const Preview: React.VFC<Props> = ({ fileSrc, fileType }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  switch (fileType) {
    case "model":
      return (
        <StlViewer
          url={fileSrc}
          style={{
            width: isDesktop ? 1200 : isMobile ? 250 : 400,
            height: isDesktop ? 640 : isMobile ? 250 : 400,
          }}
          modelProps={{
            color: theme.palette.primary.main,
            scale: 1.8,
            positionX: 18,
          }}
          orbitControls
        />
      );
    case "video":
      return (
        <ImageBox>
          <VideoViewer src={fileSrc} />
        </ImageBox>
      );
    case "image":
      return (
        <ImageBox>
          <Image src={fileSrc} alt="" />
        </ImageBox>
      );
  }
  return null;
};
