import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";

import { FontAwesomeSvgIcon } from "components/common";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Colors } from "consts/colors";
import { selectors, actions } from "store/treatmentPlan";
import { Button } from "components/ui";
import { InfoWithLabel } from "components/common";
import { treatPlanStatus } from "lib/dictionaries";
import { toLocalCurrency } from "utils/helpers";
import { dateToString } from "utils/dateHelpers";

import { StyledLink } from "./Elements";
import { RxModal } from "./RxModal";
import { ApproveTpBlock } from "./ApproveTpBlock";
import {
  attachValue,
  iprValues,
} from "../../../components/common/RxForm/consts";

export const Header = () => {
  const dispatch = useAppDispatch();
  const caseInfo = useAppSelector(selectors.caseInfoSelector);
  const showLegend = useAppSelector(selectors.showLegend);
  const showPin = useAppSelector(selectors.showPin);
  const showNotes = useAppSelector(selectors.showNotes);
  const activeOrderId = useAppSelector(selectors.getActiveOrder);
  const treatmentPlan = useAppSelector(selectors.treatPlanSelector);
  const isLoading = useAppSelector(selectors.isLoading);

  const showPinHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.togglePin(event.target.checked));
  };
  const showLegendHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.toggleLegend(event.target.checked));
  };
  const showNotesHandler = () => {
    dispatch(actions.toggleNotes(!showNotes));
  };

  return (
    <Box px={5} pt={4} pb={2}>
      <Grid container>
        <Grid item md={6}>
          <Stack direction="row" alignItems="baseline" spacing={3}>
            <Typography fontWeight={600} variant="h5" color={Colors.PRIMARY}>
              Treatment plan
            </Typography>
            {caseInfo?.needApproveTreatPlan && <ApproveTpBlock />}
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Stack mr={2}>
              <FormControlLabel
                labelPlacement="start"
                componentsProps={{
                  typography: {
                    color: Colors.TEXT_SECONDARY,
                    variant: "body2",
                  },
                }}
                control={
                  <Switch
                    size="small"
                    checked={showPin}
                    onChange={showPinHandler}
                    name="showPin"
                  />
                }
                label="SHOW PIN"
              />
              <FormControlLabel
                labelPlacement="start"
                componentsProps={{
                  typography: {
                    color: Colors.TEXT_SECONDARY,
                    variant: "body2",
                  },
                }}
                control={
                  <Switch
                    size="small"
                    checked={showLegend}
                    onChange={showLegendHandler}
                    name="showLegend"
                  />
                }
                label="SHOW LEGEND"
              />
            </Stack>
            <RxModal />
            <Button
              endIcon={<FontAwesomeSvgIcon icon={faPencilAlt} />}
              fatButton
              variant={showNotes ? "contained" : "outlined"}
              onClick={showNotesHandler}
            >
              Notes
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={4} mt={3} mb={2}>
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500 }}
          textStyleSx={{ fontSize: 12, lineHeight: "24px", fontWeight: 500 }}
          label="ORDER"
        >
          <StyledLink to={`/orders/${activeOrderId}`}>
            {activeOrderId}
          </StyledLink>
        </InfoWithLabel>
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500 }}
          textStyleSx={{ fontSize: 12, fontWeight: 500, width: "max-content" }}
          label="PATIENT"
          text={`${caseInfo?.patient.firstName} ${caseInfo?.patient.lastName}`}
        />
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500 }}
          textStyleSx={{ fontSize: 12, fontWeight: 500 }}
          label="STATUS"
          text={treatPlanStatus[treatmentPlan?.status!]}
        />
        {caseInfo?.typeOfPrice &&
          caseInfo?.typeOfPrice?.id !== 0 &&
          caseInfo?.packageType && (
            <InfoWithLabel
              direction="column"
              isLoading={isLoading}
              labelStyleSx={{ fontWeight: 500 }}
              textStyleSx={{ fontSize: 12, fontWeight: 500 }}
              label="Package type"
              text={caseInfo.packageType.name}
            />
          )}
        {Boolean(caseInfo?.additionalInfo?.extractionIPR) && (
          <InfoWithLabel
            direction="column"
            isLoading={isLoading}
            labelStyleSx={{ fontWeight: 500 }}
            textStyleSx={{
              fontSize: 12,
              fontWeight: 500,
              width: "max-content",
            }}
            label="IPR"
            text={iprValues[caseInfo?.additionalInfo?.extractionIPR!]}
          />
        )}
        {Boolean(caseInfo?.additionalInfo?.attachPrefer) && (
          <InfoWithLabel
            direction="column"
            isLoading={isLoading}
            labelStyleSx={{ fontWeight: 500 }}
            textStyleSx={{ fontSize: 12, fontWeight: 500 }}
            label="ATTACHMENTS"
            text={attachValue[caseInfo?.additionalInfo?.attachPrefer!]}
          />
        )}
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500 }}
          textStyleSx={{ fontSize: 12, fontWeight: 500 }}
          label="VERSION"
          text={treatmentPlan?.version.toString() || "0"}
        />

        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500 }}
          textStyleSx={{ fontSize: 12, fontWeight: 500 }}
          label="Months"
          text={treatmentPlan?.estimatedCompletionMonths.toString() || "0"}
        />

        {(caseInfo?.typeOfPrice === null ||
          caseInfo?.typeOfPrice?.id === 0) && (
          <InfoWithLabel
            direction="column"
            isLoading={isLoading}
            labelStyleSx={{ fontWeight: 500 }}
            textStyleSx={{ fontSize: 12, fontWeight: 500 }}
            label="Est.cost"
            text={toLocalCurrency(
              (treatmentPlan?.lowerEstCost || 0) +
                (treatmentPlan?.upperEstCost || 0)
            )}
          />
        )}

        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500, width: "max-content" }}
          textStyleSx={{ fontSize: 12, fontWeight: 500 }}
          label="Upper Est.completion"
          text={dateToString(treatmentPlan?.estimatedUpperCompletionDate)}
        />
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500 }}
          textStyleSx={{ fontSize: 12, fontWeight: 500 }}
          label="Upper Est.steps"
          text={treatmentPlan?.upperEstimatedSteps?.toString() || "0"}
        />
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500, width: "max-content" }}
          textStyleSx={{ fontSize: 12, fontWeight: 500 }}
          label="Lower Est.completion"
          text={dateToString(treatmentPlan?.estimatedLowerCompletionDate)}
        />
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          labelStyleSx={{ fontWeight: 500 }}
          textStyleSx={{ fontSize: 12, fontWeight: 500 }}
          label="Lower Est.steps"
          text={treatmentPlan?.lowerEstimatedSteps?.toString() || "0"}
        />
      </Stack>
      <Divider />
    </Box>
  );
};
