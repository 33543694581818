import { PreferencesType } from "lib/types";
import { IBaseResponse } from "lib/model";
import api from "./baseService";
import { IUserSettingsWriteModel } from "../lib/model/IUserSettingsWriteModel";

class UserSettingsService {
  private baseUrl: string = "user-settings";

  public async getPreferencesByUser(id: number): Promise<PreferencesType> {
    const { data } = await api.get<IBaseResponse<PreferencesType>>(
      `${this.baseUrl}/users/${id}`
    );
    return data.result;
  }

  public async saveUserPreferences(
    userSettings: IUserSettingsWriteModel,
    update?: boolean
  ): Promise<void> {
    if (update) {
      await api.put(this.baseUrl, userSettings);
    } else {
      await api.post(this.baseUrl, userSettings);
    }
  }
}

export const userSettingsService = new UserSettingsService();
