import { RotationType } from "lib/enum";
import React from "react";
interface RotationProps {
  className?: string;
  style?: any;
  side: "left" | "right";
}
interface Props extends RotationProps {
  type: "uppers" | "lowers";
  side: "left" | "right";
  value: RotationType | null;
}

export const RotationMesial = (props: Props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={props.className}
      style={props.style}
    >
      {props.side === "left" ? (
        <>
          <path
            d="M21.8374 5.04688L23.8924 10.8491L25.9363 5.04688H28.1012V13.0469H26.4473V10.8601L26.6122 7.08534L24.4528 13.0469H23.321L21.1671 7.09083L21.3319 10.8601V13.0469H19.6836V5.04688H21.8374Z"
            fill="#F759AB"
          />
          <path
            d="M32.5023 10.1183H31.1891V13.0469H29.5407V5.04688H32.5133C33.4583 5.04688 34.1873 5.2575 34.7001 5.67874C35.2129 6.09999 35.4693 6.69523 35.4693 7.46446C35.4693 8.01025 35.3503 8.46629 35.1122 8.83259C34.8777 9.19523 34.5206 9.4846 34.0407 9.70072L35.7715 12.97V13.0469H34.0023L32.5023 10.1183ZM31.1891 8.78314H32.5188C32.9327 8.78314 33.2532 8.67874 33.4803 8.46995C33.7074 8.2575 33.821 7.96629 33.821 7.59633C33.821 7.21904 33.7129 6.92233 33.4968 6.70622C33.2843 6.4901 32.9565 6.38204 32.5133 6.38204H31.1891V8.78314Z"
            fill="#F759AB"
          />
          <path
            d="M2.78343 16.2745L2.80981 16.7706C2.81546 17.449 3.00952 18.0642 3.20169 18.6848C3.29967 18.9933 3.45793 19.2513 3.58981 19.5345C3.73112 19.8088 3.86489 20.0992 4.04199 20.3247C4.37924 20.8046 4.71837 21.299 5.10837 21.6814L5.67924 22.2894L6.27838 22.8162C7.88737 24.1982 9.57927 25.1742 11.2033 25.9969C12.8349 26.7745 14.4062 27.4402 15.8739 27.9345C16.6068 28.2034 17.3134 28.4253 17.9879 28.6291C18.6605 28.8456 19.3029 29.026 19.9096 29.183C21.1079 29.5222 22.2082 29.7567 23.1031 29.9642C23.3876 30.0273 23.6344 30.0796 23.868 30.1284L23.5609 34L37 27.8173L24.6876 19.7619L24.3862 23.574C24.3127 23.5668 24.2449 23.5595 24.1695 23.5505C23.3876 23.4531 22.4399 23.3629 21.3227 23.1735C20.765 23.0941 20.1753 22.9966 19.5629 22.8704C18.945 22.7567 18.2969 22.6286 17.6355 22.4644C16.2922 22.174 14.8961 21.7482 13.451 21.2521C12.0361 20.7253 10.5929 20.074 9.36072 19.2387C8.14172 18.4088 7.14693 17.3804 6.99621 16.3881L6.94722 16.1969L6.93215 16.1482C6.92273 16.4062 6.93027 16.2077 6.92838 16.2582V16.2366V16.1933L6.93027 16.0201L6.93403 15.6755C6.94534 15.634 6.95287 15.5925 6.95664 15.5492C6.94534 15.4825 6.99432 15.3237 7.02824 15.183C7.04708 15.0387 7.13751 14.8943 7.18273 14.7464C7.4352 14.1528 7.89302 13.534 8.46766 12.9603C9.62072 11.8039 11.1675 10.8224 12.769 9.99252C13.988 9.37371 15.2541 8.83428 16.5258 8.35979V6C14.8942 6.38428 13.2306 6.85696 11.5613 7.48479C9.69043 8.21185 7.79882 9.09587 6.06171 10.4652C5.20069 11.1544 4.3717 11.9807 3.73489 13.0469C3.42025 13.6062 3.16024 14.1131 2.98126 14.8564L2.86068 15.4103L2.81546 15.7567L2.79285 15.9281L2.7872 15.9714L2.78531 15.993C2.78343 16.0598 2.7872 15.8758 2.77778 16.1464L2.78343 16.2745Z"
            fill="#F759AB"
          />
        </>
      ) : (
        <>
          <path
            d="M5.04252 5.04688L7.09746 10.8491L9.14142 5.04688H11.3063V13.0469H9.65241V10.8601L9.81724 7.08534L7.6579 13.0469H6.52603L4.37219 7.09083L4.53702 10.8601V13.0469H2.88867V5.04688H5.04252Z"
            fill="#F759AB"
          />
          <path
            d="M15.7074 10.1183H14.3942V13.0469H12.7458V5.04688H15.7183C16.6634 5.04688 17.3923 5.2575 17.9052 5.67874C18.418 6.09999 18.6744 6.69523 18.6744 7.46446C18.6744 8.01025 18.5553 8.46629 18.3172 8.83259C18.0828 9.19523 17.7257 9.4846 17.2458 9.70072L18.9766 12.97V13.0469H17.2074L15.7074 10.1183ZM14.3942 8.78314H15.7238C16.1378 8.78314 16.4583 8.67874 16.6854 8.46995C16.9125 8.2575 17.026 7.96629 17.026 7.59633C17.026 7.21904 16.918 6.92233 16.7019 6.70622C16.4894 6.4901 16.1616 6.38204 15.7183 6.38204H14.3942V8.78314Z"
            fill="#F759AB"
          />
          <path
            d="M37.2166 16.2745L37.1902 16.7706C37.1845 17.449 36.9905 18.0642 36.7983 18.6848C36.7003 18.9933 36.5421 19.2513 36.4102 19.5345C36.2689 19.8088 36.1351 20.0992 35.958 20.3247C35.6208 20.8046 35.2816 21.299 34.8916 21.6814L34.3208 22.2894L33.7216 22.8162C32.1126 24.1982 30.4207 25.1742 28.7967 25.9969C27.1651 26.7745 25.5938 27.4402 24.1261 27.9345C23.3932 28.2034 22.6866 28.4253 22.0121 28.6291C21.3395 28.8456 20.6971 29.026 20.0904 29.183C18.8921 29.5222 17.7918 29.7567 16.8969 29.9642C16.6124 30.0273 16.3656 30.0796 16.132 30.1284L16.4391 34L3 27.8173L15.3124 19.7619L15.6138 23.574C15.6873 23.5668 15.7551 23.5595 15.8305 23.5505C16.6124 23.4531 17.5601 23.3629 18.6773 23.1735C19.235 23.0941 19.8247 22.9966 20.4371 22.8704C21.055 22.7567 21.7031 22.6286 22.3645 22.4644C23.7078 22.174 25.1039 21.7482 26.549 21.2521C27.9639 20.7253 29.4071 20.074 30.6393 19.2387C31.8583 18.4088 32.8531 17.3804 33.0038 16.3881L33.0528 16.1969L33.0679 16.1482C33.0773 16.4062 33.0697 16.2077 33.0716 16.2582V16.2366V16.1933L33.0697 16.0201L33.066 15.6755C33.0547 15.634 33.0471 15.5925 33.0434 15.5492C33.0547 15.4825 33.0057 15.3237 32.9718 15.183C32.9529 15.0387 32.8625 14.8943 32.8173 14.7464C32.5648 14.1528 32.107 13.534 31.5323 12.9603C30.3793 11.8039 28.8325 10.8224 27.231 9.99252C26.012 9.37371 24.7459 8.83428 23.4742 8.35979V6C25.1058 6.38428 26.7694 6.85696 28.4387 7.48479C30.3096 8.21185 32.2012 9.09587 33.9383 10.4652C34.7993 11.1544 35.6283 11.9807 36.2651 13.0469C36.5798 13.6062 36.8398 14.1131 37.0187 14.8564L37.1393 15.4103L37.1845 15.7567L37.2071 15.9281L37.2128 15.9714L37.2147 15.993C37.2166 16.0598 37.2128 15.8758 37.2222 16.1464L37.2166 16.2745Z"
            fill="#F759AB"
          />
        </>
      )}
    </svg>
  );
};

export const RotationDistal = (props: Props) => {
  if (props.type === "uppers") {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        className={props.className}
        style={props.style}
      >
        {props.side === "left" ? (
          <>
            <path
              d="M4 33V25H6.72506C7.50365 25 8.19911 25.1593 8.81144 25.478C9.42782 25.793 9.90835 26.2436 10.253 26.8297C10.5977 27.4121 10.7701 28.0751 10.7701 28.8187V29.1868C10.7701 29.9304 10.5998 30.5916 10.2591 31.1703C9.92255 31.7491 9.44607 32.1978 8.82968 32.5165C8.2133 32.8352 7.51784 32.9963 6.74331 33H4ZM5.82482 26.3352V31.6758H6.70681C7.42052 31.6758 7.96594 31.4652 8.34307 31.044C8.72019 30.6227 8.91281 30.0201 8.92092 29.2363V28.8132C8.92092 28 8.73439 27.3846 8.36131 26.967C7.98824 26.5458 7.44282 26.3352 6.72506 26.3352H5.82482Z"
              fill="#2C2E30"
            />
            <path
              d="M15.3808 30.0714H13.927V33H12.1022V25H15.3929C16.4392 25 17.2461 25.2106 17.8139 25.6319C18.3816 26.0531 18.6655 26.6484 18.6655 27.4176C18.6655 27.9634 18.5337 28.4194 18.2701 28.7857C18.0105 29.1484 17.6152 29.4377 17.0839 29.6538L19 32.9231V33H17.0414L15.3808 30.0714ZM13.927 28.7363H15.399C15.8573 28.7363 16.2121 28.6319 16.4635 28.4231C16.7149 28.2106 16.8406 27.9194 16.8406 27.5495C16.8406 27.1722 16.721 26.8755 16.4818 26.6593C16.2466 26.4432 15.8836 26.3352 15.3929 26.3352H13.927V28.7363Z"
              fill="#2C2E30"
            />
            <path
              d="M21.474 8C17.602 8 13.9621 9.88081 11.6096 12.9981L8.00769 10.8379L8 23.2035L18.3223 17.0275L15.0048 15.0351C16.6345 13.1407 18.9795 12 21.474 12C26.2559 12 30.1459 16.0371 30.1459 21.0001C30.1459 25.9629 26.2558 31 21.474 31V33C28.3805 33 34 28.1684 34 21.0001C34 13.8321 28.3807 8 21.474 8Z"
              fill="#E7D74E"
            />
          </>
        ) : (
          <>
            <path
              d="M23 33V25H25.7251C26.5036 25 27.1991 25.1593 27.8114 25.478C28.4278 25.793 28.9084 26.2436 29.253 26.8297C29.5977 27.4121 29.7701 28.0751 29.7701 28.8187V29.1868C29.7701 29.9304 29.5998 30.5916 29.2591 31.1703C28.9225 31.7491 28.4461 32.1978 27.8297 32.5165C27.2133 32.8352 26.5178 32.9963 25.7433 33H23ZM24.8248 26.3352V31.6758H25.7068C26.4205 31.6758 26.9659 31.4652 27.3431 31.044C27.7202 30.6227 27.9128 30.0201 27.9209 29.2363V28.8132C27.9209 28 27.7344 27.3846 27.3613 26.967C26.9882 26.5458 26.4428 26.3352 25.7251 26.3352H24.8248Z"
              fill="#2C2E30"
            />
            <path
              d="M34.3808 30.0714H32.927V33H31.1022V25H34.3929C35.4392 25 36.2461 25.2106 36.8139 25.6319C37.3816 26.0531 37.6655 26.6484 37.6655 27.4176C37.6655 27.9634 37.5337 28.4194 37.2701 28.7857C37.0105 29.1484 36.6152 29.4377 36.0839 29.6538L38 32.9231V33H36.0414L34.3808 30.0714ZM32.927 28.7363H34.399C34.8573 28.7363 35.2121 28.6319 35.4635 28.4231C35.7149 28.2106 35.8406 27.9194 35.8406 27.5495C35.8406 27.1722 35.721 26.8755 35.4818 26.6593C35.2466 26.4432 34.8836 26.3352 34.3929 26.3352H32.927V28.7363Z"
              fill="#2C2E30"
            />
            <path
              d="M20.526 8C24.398 8 28.0379 9.88081 30.3904 12.9981L33.9923 10.8379L34 23.2035L23.6777 17.0275L26.9952 15.0351C25.3655 13.1407 23.0205 12 20.526 12C15.7441 12 11.8541 16.0371 11.8541 21.0001C11.8541 25.9629 15.7442 31 20.526 31V33C13.6195 33 8 28.1684 8 21.0001C8 13.8321 13.6193 8 20.526 8Z"
              fill="#E7D74E"
            />
          </>
        )}
      </svg>
    );
  }
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={props.className}
      style={props.style}
    >
      {props.side === "left" ? (
        <>
          <path
            d="M4 16V8H6.72506C7.50365 8 8.19911 8.15934 8.81144 8.47802C9.42782 8.79304 9.90835 9.24359 10.253 9.82967C10.5977 10.4121 10.7701 11.0751 10.7701 11.8187V12.1868C10.7701 12.9304 10.5998 13.5916 10.2591 14.1703C9.92255 14.7491 9.44607 15.1978 8.82968 15.5165C8.2133 15.8352 7.51784 15.9963 6.74331 16H4ZM5.82482 9.33516V14.6758H6.70681C7.42052 14.6758 7.96594 14.4652 8.34307 14.044C8.72019 13.6227 8.91281 13.0201 8.92092 12.2363V11.8132C8.92092 11 8.73439 10.3846 8.36131 9.96703C7.98824 9.54579 7.44282 9.33516 6.72506 9.33516H5.82482Z"
            fill="#2C2E30"
          />
          <path
            d="M15.3808 13.0714H13.927V16H12.1022V8H15.3929C16.4392 8 17.2461 8.21062 17.8139 8.63187C18.3816 9.05311 18.6655 9.64835 18.6655 10.4176C18.6655 10.9634 18.5337 11.4194 18.2701 11.7857C18.0105 12.1484 17.6152 12.4377 17.0839 12.6538L19 15.9231V16H17.0414L15.3808 13.0714ZM13.927 11.7363H15.399C15.8573 11.7363 16.2121 11.6319 16.4635 11.4231C16.7149 11.2106 16.8406 10.9194 16.8406 10.5495C16.8406 10.1722 16.721 9.87546 16.4818 9.65934C16.2466 9.44322 15.8836 9.33516 15.3929 9.33516H13.927V11.7363Z"
            fill="#2C2E30"
          />
          <path
            d="M21.474 33C17.602 33 13.9621 31.1192 11.6096 28.0019L8.00769 30.1621L8 17.7965L18.3223 23.9725L15.0048 25.9649C16.6345 27.8593 18.9795 29 21.474 29C26.2559 29 30.1459 24.9629 30.1459 19.9999C30.1459 15.0371 26.2558 10 21.474 10V8C28.3805 8 34 12.8316 34 19.9999C34 27.1679 28.3807 33 21.474 33Z"
            fill="#E7D74E"
          />
        </>
      ) : (
        <>
          <path
            d="M22 16V8H24.7251C25.5036 8 26.1991 8.15934 26.8114 8.47802C27.4278 8.79304 27.9084 9.24359 28.253 9.82967C28.5977 10.4121 28.7701 11.0751 28.7701 11.8187V12.1868C28.7701 12.9304 28.5998 13.5916 28.2591 14.1703C27.9225 14.7491 27.4461 15.1978 26.8297 15.5165C26.2133 15.8352 25.5178 15.9963 24.7433 16H22ZM23.8248 9.33516V14.6758H24.7068C25.4205 14.6758 25.9659 14.4652 26.3431 14.044C26.7202 13.6227 26.9128 13.0201 26.9209 12.2363V11.8132C26.9209 11 26.7344 10.3846 26.3613 9.96703C25.9882 9.54579 25.4428 9.33516 24.7251 9.33516H23.8248Z"
            fill="#2C2E30"
          />
          <path
            d="M33.3808 13.0714H31.927V16H30.1022V8H33.3929C34.4392 8 35.2461 8.21062 35.8139 8.63187C36.3816 9.05311 36.6655 9.64835 36.6655 10.4176C36.6655 10.9634 36.5337 11.4194 36.2701 11.7857C36.0105 12.1484 35.6152 12.4377 35.0839 12.6538L37 15.9231V16H35.0414L33.3808 13.0714ZM31.927 11.7363H33.399C33.8573 11.7363 34.2121 11.6319 34.4635 11.4231C34.7149 11.2106 34.8406 10.9194 34.8406 10.5495C34.8406 10.1722 34.721 9.87546 34.4818 9.65934C34.2466 9.44322 33.8836 9.33516 33.3929 9.33516H31.927V11.7363Z"
            fill="#2C2E30"
          />
          <path
            d="M20.526 33C24.398 33 28.0379 31.1192 30.3904 28.0019L33.9923 30.1621L34 17.7965L23.6777 23.9725L26.9952 25.9649C25.3655 27.8593 23.0205 29 20.526 29C15.7441 29 11.8541 24.9629 11.8541 19.9999C11.8541 15.0371 15.7442 10 20.526 10V8C13.6195 8 8 12.8316 8 19.9999C8 27.1679 13.6193 33 20.526 33Z"
            fill="#E7D74E"
          />
        </>
      )}
    </svg>
  );
};

const Rotation = (props: Props) => {
  switch (props.value) {
    case RotationType.DISTAL:
      return <RotationDistal {...props} />;
    case RotationType.MESIAL:
      return <RotationMesial {...props} />;
  }
  return null;
};
export default Rotation;
