import React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { OfficeSettingType } from "store/accountSettings/types";
import { Balance } from "components/common";

import { OfficeSettingsItem } from "./OfficeSettingsItem";
import { OfficeSettingsLogo } from "./OfficeSettingsLogo";

interface Props {
  officeSettings: OfficeSettingType;
}
export const OfficeItemCard: React.FC<Props> = ({ officeSettings }) => {
  return (
    <>
      <Stack alignItems="flex-start" mb={2}>
        <Balance officeId={officeSettings.officeId} />
      </Stack>
      <Divider />
      <OfficeSettingsItem officeSettings={officeSettings} />
      <Divider />
      <OfficeSettingsLogo officeId={officeSettings.officeId} />
    </>
  );
};
