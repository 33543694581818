import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";
import { MainContainer } from "containers";

export const LoadingPreview = () => {
  return (
    <MainContainer>
      <Stack
        direction="row"
        spacing={2}
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress sx={{ color: Colors.PRIMARY }} size={24} />
        <Typography sx={{ color: Colors.PRIMARY }} variant="h2">
          Please wait a moment, file downloading...
        </Typography>
      </Stack>
    </MainContainer>
  );
};
