import React from "react";

import { MessageDialogProps } from "./types";
import { ReplyMessageDialog } from "./ReplyMessageDialog";
import { NewMessageDialog } from "./NewMessageDialog";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { IExtOrderMessageDto } from "lib/dto";

export const MessageDialog: React.VFC<
  Omit<MessageDialogProps, "message"> & { messageId?: number }
> = ({ messageId, ...props }) => {
  const messages = useAppSelector(selectors.orderMessagesSelector);
  const message: IExtOrderMessageDto | undefined = messages.find(
    ({ id }) => id === messageId
  );
  if (!message) {
    return <NewMessageDialog {...props} />;
  }
  return <ReplyMessageDialog message={message} {...props} />;
};
