export const Colors = {
  PRIMARY: "#266FBE",
  PRIMARY_DARK: "#356DA9",
  SECONDARY: "#464E5F",
  DISABLED: "#B1B1B1",
  TERTIARY: "#DCEDFF",
  TERTIARY_TEXT: "#D7D7D7",
  SUCCESS: "#5CBD7B",
  NOTICE_SUCCESS: "#7FC586",
  BACKGROUND_GREEN: "#C9E9D3",
  ERROR: "#E44D4D",
  NOTICE_ERROR: "#E97070",
  BADGE: "#F45A5A",
  CONTAINER_GREY: "#F7F8F9",
  CONTAINER_BORDER_GREY: "#F1F1F1",
  CONTAINER_LIGHT_GREY: "#F2F2F2",
  LIGHT_GREY: "#C1C1C1",
  TEXT_GREY: "#464E5F",
  TEXT_GREY_LIGHT: "#727272",
  TEXT_PRIMARY: "#303344",
  TEXT_SECONDARY: "#7B808C",
  LABEL_GREY: "#B1B1B1",
  TEXT_GREY_DARK: "#303344",
  TEXT_DARK: "#2D323D",
  TOOTH_WHITE: "#F9F9F9",
};
