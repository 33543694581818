import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { dateTimeToString } from "utils/dateHelpers";
import { PLAN_COLORS } from "consts/tpColors";
import { Button } from "components/ui";
import { INoteDto } from "lib/dto";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions } from "store/treatmentPlan";
import { selectors } from "store/currentUser";
import { useStyledSnackbar } from "hooks/notification";

import {
  StyledNoteInput,
  NotesToContainer,
  StyledNotesItem,
} from "./NoteElements";
import Box from "@mui/material/Box";

interface Props {
  to: string;
  note: INoteDto;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onDelete?: React.MouseEventHandler<HTMLElement>;
}

export const NotesItem: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(selectors.getAllUserInfo);
  const { showErrorNotice } = useStyledSnackbar();
  const [editMessage, setEditMessage] = useState<string | undefined>();
  const edit = editMessage !== undefined;
  const editHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    setEditMessage(props.note.text);
  };
  const closeHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    setEditMessage(undefined);
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditMessage(event.target.value);
  };
  const submitNote = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(
      actions.updateNote({
        noteId: props.note.id,
        note: editMessage!,
        forDoctor: props.to === "Doctor",
      })
    )
      .unwrap()
      .then(() => {
        setEditMessage(undefined);
      })
      .catch((err) => {
        showErrorNotice(err.message);
      });
  };
  return (
    <StyledNotesItem
      isEdit={!edit}
      isSelected={props.selected}
      onClick={props.onClick}
    >
      <Stack direction="row" alignItems="center" marginBottom={2}>
        <NotesToContainer direction="row" alignItems="center">
          <Typography
            fontSize={14}
            color={PLAN_COLORS.grey.GREY_9}
            variant="body2"
          >
            to:
          </Typography>
          <Typography
            fontSize={14}
            color={PLAN_COLORS.grey.GREY_9}
            variant="body2"
          >
            {props.to}
          </Typography>
        </NotesToContainer>
        <Box component="span" flexGrow={1}>
          <Typography color={PLAN_COLORS.grey.GREY_7} variant="body2">
            {dateTimeToString(props.note.created)}
          </Typography>
        </Box>
        {!edit && props.note.createdBy === currentUser?.userId && (
          <>
            <IconButton
              size="small"
              onClick={props.onDelete}
              sx={{ marginLeft: 1 }}
            >
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={editHandler}
              sx={{ marginLeft: 1 }}
            >
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </Stack>
      {edit ? (
        <>
          <StyledNoteInput
            margin="dense"
            placeholder="Add note"
            multiline
            rows={2}
            value={editMessage}
            onChange={changeHandler}
            onKeyDown={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            py={2}
          >
            <Button onClick={closeHandler} disableElevation variant="outlined">
              Cancel
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={submitNote}
            >
              Save
            </Button>
          </Stack>
        </>
      ) : (
        <Typography variant="body2">{props.note.text}</Typography>
      )}
    </StyledNotesItem>
  );
};
