import React from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";

import { ScrollContainer } from "components/common";
import { useAppSelector } from "hooks/redux";
import { eventSelector } from "store/dashboard/selectors";

import { EventItem } from "./EventItem";
import { ItemText } from "./ItemText";
import { EventIcon } from "./EventIcon";
import { EmptyBlock } from "./EmptyBlock";

const StyledContent = styled("div")`
  height: calc(100% - 53px);
`;

export const EventsList = () => {
  const events = useAppSelector(eventSelector);
  if (!events?.length) {
    return (
      <div>
        <EmptyBlock />
      </div>
    );
  }
  return (
    <StyledContent>
      <ScrollContainer>
        <Stack spacing={2}>
          {events.map((event) => (
            <EventItem
              event={event}
              pinned={event.pinned}
              key={event.id}
              icon={<EventIcon eventType={event.operation} />}
            >
              <ItemText>{event.message}</ItemText>
            </EventItem>
          ))}
        </Stack>
      </ScrollContainer>
    </StyledContent>
  );
};
