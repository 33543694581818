import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Button, TextField } from "components/ui";
import { Colors } from "consts/colors";

export const ImgContainer = styled("span")({
  marginRight: 20,
  height: 20,
  width: 20,
});

export const StyledImg = styled("img")({
  width: "212px",
});

export const StyledButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  height: 54,
  paddingLeft: 62,
  paddingBlock: "20px 16px",
  boxSizing: "border-box",
  borderRadius: "unset",
  textTransform: "none",
}));

export const NavContainer = styled(Stack)({
  width: "290px",
  paddingBlock: "85px 29px",
  paddingInline: "27px",
  flexGrow: 1,
  boxSizing: "border-box",
});
export const StyledNavLinkButton = styled(Button)({
  height: 48,
  textTransform: "unset",
  fontWeight: "normal",
  paddingBlock: 16,
  paddingInline: 12,
  fontSize: 16,
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: 5.65,
});

export const StyledIconButton = styled(Button)({
  backgroundColor: "white",
  textTransform: "none",
  minWidth: 24,
  padding: "5px 6px",
  borderRadius: 2,
  "&:hover": {
    backgroundColor: "white",
  },
});

export const StyledHeader = styled("header")({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 1.5rem",
  backgroundColor: Colors.PRIMARY,
  height: "74px",
  alignItems: "center",
  columnGap: 50,
});

export const Container = styled("div")({
  height: "100vh",
});

export const ContentContainer = styled(Stack)({
  height: "calc(100% - 74px)",
});

export const Content = styled("div")({
  flex: 1,
  maxWidth: "calc(100% - 290px)",
  maxHeight: "calc(100vh - 74px)",
  overflow: "auto",
});

export const StyledSearchField = styled(TextField)({
  flexGrow: 1,
  backgroundColor: "#356DA9",

  borderRadius: 9.56,
  "& .MuiOutlinedInput-root": {
    borderRadius: 9.56,
    color: Colors.DISABLED,
  },
  "& fieldset": {
    borderRadius: 9.56,
    borderColor: "#356DA9",
  },
  "& .MuiOutlinedInput-root:hover fieldset": {
    borderRadius: 9.56,
    borderColor: Colors.DISABLED,
  },
});
