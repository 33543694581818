import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const ContentContainer = styled(Stack)`
  padding: 32px;
`;

export const HeaderContainer = styled(Typography)`
  color: var(--primary-color);
  margin-block: 12px 24px;
  font-weight: bold;
`;

export const BackButton = styled(Stack)`
  cursor: pointer;
  color: var(--text-primary-color);
  font-size: 12px;
`;

export const BackText = styled(Typography)`
  text-transform: uppercase;
  color: inherit;
`;

export const Subtitle = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  color: var(--text-grey-color);
`;

export const NameText = styled(Typography)`
  color: var(--primary-color);
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
`;
