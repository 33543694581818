import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAppSelector } from "hooks/redux";
import { Button } from "components/ui";

import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledTextArea,
  StyledDialogActions,
} from "./Elements";
import { MessageDialogProps } from "./types";
import Stack from "@mui/material/Stack";
import { MessageFilesAddButton } from "./MessageFilesAddButton";

export const NewMessageDialog: React.VFC<MessageDialogProps> = ({
  open,
  orderId,
  onClose,
  onSubmit,
}) => {
  const userId = useAppSelector(
    (state) => state.currentUser.currentUser!.userId
  );
  const [message, setMessage] = useState<{
    subject: string;
    text: string;
    files: File[];
  }>({
    subject: "",
    text: "",
    files: [],
  });
  const [loading, setLoading] = useState<boolean>(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage((oldMessage) => ({
      ...oldMessage,
      [event.target.name]: event.target.value,
    }));
  };
  const closeHandler = () => {
    onClose();
    setMessage({
      subject: "",
      text: "",
      files: [],
    });
  };
  const changeFilesHandler = (files: File[]) => {
    setMessage((oldStateMessage) => ({
      ...oldStateMessage,
      files,
    }));
  };
  const submitHandler = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    onSubmit({
      orderId: orderId as number,
      text: message.text,
      files: message.files.length > 0 ? message.files : undefined,
      isFilesAttached: message.files.length > 0,
      replyFor: null,
      subject: message.subject,
      authorId: userId,
    })
      .then(() => {
        setLoading(false);
        closeHandler();
      })
      .catch(() => setLoading(false));
  };
  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <StyledDialogTitle>
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Stack spacing={3}>
          <StyledTextArea
            name="subject"
            placeholder="Subject"
            onChange={changeHandler}
            value={message.subject}
            variant="filled"
            fullWidth
          />
          <StyledTextArea
            name="text"
            placeholder="Message text"
            value={message.text}
            onChange={changeHandler}
            multiline
            variant="filled"
            fullWidth
            rows={6}
          />
        </Stack>
      </StyledDialogContent>
      <StyledDialogActions>
        <MessageFilesAddButton
          onUpload={changeFilesHandler}
          files={message.files}
        />
        <Button
          onClick={submitHandler}
          disabled={!message.text || !message.subject}
          variant="contained"
          fatButton
          loading={loading}
        >
          Publish
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};
