import { CreatePaymentState } from "./types";

export const initState: CreatePaymentState = {
  org: "",
  office: "",
  invoiceAmount: 0,
  outstandingBalance: 0,
  orgs: [],
  offices: [],
  invoices: [],
  selectedInvoices: [],
  notes: "",
  paymentMethod: "",
  isLoading: false,
  isSaving: false,
  errors: null,
};
