import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Colors } from "consts/colors";
import { FormLabel, TextField } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/accountSettings";

import { PaperContainer } from "./Elements";
import Skeleton from "@mui/material/Skeleton";
import { LinkSMButtons } from "./LinkSMButtons";

export const ProfileSettingsCard = () => {
  const userInfo = useAppSelector(selectors.getUserInfoSelector);
  const dispatch = useAppDispatch();
  const { password, isLoading, isSaving } = useAppSelector(
    selectors.getAllInfoSelector
  );
  const fieldsChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setUserFields({
        name: event.target.name as any,
        value: event.target.value,
      })
    );
  };
  const passwordChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(actions.setPassword(event.target.value));
  };
  return (
    <PaperContainer>
      <Typography fontWeight={600} variant="h2" color={Colors.TEXT_PRIMARY}>
        Profile settings
      </Typography>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <FormLabel label="First Name">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <TextField
                  disabled={isSaving}
                  placeholder="First Name"
                  name="firstName"
                  value={userInfo.firstName}
                  fullWidth
                  onChange={fieldsChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
          <Grid item md={3}>
            <FormLabel label="Last Name">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <TextField
                  disabled={isSaving}
                  placeholder="Last Name"
                  name="lastName"
                  value={userInfo.lastName}
                  fullWidth
                  onChange={fieldsChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
          <Grid item md={3}>
            <FormLabel label="Phone">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <TextField
                  disabled={isSaving}
                  placeholder="Phone"
                  name="phone"
                  value={userInfo.phone}
                  fullWidth
                  onChange={fieldsChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
          <Grid item md={3}>
            <FormLabel label="Password">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <TextField
                  disabled={isSaving}
                  placeholder="Password"
                  type="password"
                  name="password"
                  fullWidth
                  value={password}
                  onChange={passwordChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
        </Grid>
      </Box>
      <LinkSMButtons />
    </PaperContainer>
  );
};
