import Stack from "@mui/material/Stack";
import { InfoWithLabel } from "components/common";
import Grid from "@mui/material/Grid";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";

import { attachValue, wearScheduleValues, iprValues } from "./consts";
import Box from "@mui/material/Box";

export const InfoBlock = () => {
  const { isLoading, caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const include = caseInfo?.additionalInfo.include
    ? JSON.parse(caseInfo?.additionalInfo.include)
    : ["-"];
  const textStyle = { fontSize: 14, mb: 2 };
  const archesText = `${caseInfo?.upperArch ? "Upper" : ""}${
    caseInfo?.lowerArch && caseInfo?.upperArch ? ", " : ""
  }${caseInfo?.lowerArch ? "Lower" : ""}`.trim();
  return (
    <Stack spacing={3} flexGrow={1} marginRight={1}>
      <InfoWithLabel
        direction="column"
        label="OBJECTIVE(S)"
        text={include
          .map(({ name, selectedName }: any) => selectedName || name)
          .join(". ")
          .trim()}
        textStyleSx={{ fontSize: 14 }}
      />
      <Box>
        <Grid container spacing={4}>
          <Grid item md={6} xl={3}>
            <InfoWithLabel
              direction="column"
              label="ATTACHMENTS PREF."
              isLoading={isLoading}
              labelStyleSx={{ whiteSpace: "nowrap" }}
              text={
                caseInfo?.additionalInfo?.attachPrefer
                  ? attachValue[caseInfo?.additionalInfo?.attachPrefer]
                  : "-"
              }
              textStyleSx={textStyle}
            />
          </Grid>
          <Grid item md={6} xl={3}>
            <InfoWithLabel
              direction="column"
              label="IPR"
              isLoading={isLoading}
              textStyleSx={textStyle}
              text={
                caseInfo?.additionalInfo?.extractionIPR
                  ? iprValues[caseInfo?.additionalInfo?.extractionIPR]
                  : "-"
              }
            />
          </Grid>
          <Grid item md={6} xl={3}>
            <InfoWithLabel
              direction="column"
              label="WEARING SCHEDULE"
              labelStyleSx={{ whiteSpace: "nowrap" }}
              isLoading={isLoading}
              text={
                caseInfo?.additionalInfo?.wearSchedule
                  ? wearScheduleValues[caseInfo?.additionalInfo?.wearSchedule]
                  : "-"
              }
              textStyleSx={textStyle}
            />
          </Grid>
          <Grid item md={6} xl={3}>
            <InfoWithLabel
              direction="column"
              label="ARCHES"
              text={archesText}
              labelStyleSx={{ whiteSpace: "nowrap" }}
              textStyleSx={textStyle}
            />
          </Grid>
        </Grid>
      </Box>
      <InfoWithLabel
        direction="column"
        label="ADDITIONAL DOCTOR PREFERENCES"
        text={caseInfo?.additionalInfo.other || "-"}
        textStyleSx={textStyle}
      />
    </Stack>
  );
};
