import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import Link from "@mui/material/Link";
import { OrderActions } from "lib/enum";
import { Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  actions,
  selectors as patientSelector,
  selectors,
} from "store/patientDetail";
import { actions as remakeActions } from "store/requestRemake";
import { CalendlyFrame, RejectTPButton } from "components/common";
import { Colors } from "consts/colors";
import { useStyledSnackbar } from "hooks/notification";

interface Props {
  actionType: OrderActions;
  targetId: number;
}

const AttentionText = styled("span")`
  color: var(--error-color);
`;
export const ActionsButton: React.VFC<Props> = ({ actionType, targetId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { showSuccessNotice } = useStyledSnackbar();
  const { caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const { dmDoctor, dmProfileId } = useAppSelector((state) => ({
    dmDoctor: state.patientCard.officeStaff?.dmDoctor,
    // not null if have account DM
    dmProfileId: state.patientCard.caseInfo?.dmProfileId,
  }));
  const discussTP = useAppSelector(patientSelector.needDiscussTp);

  const submitToLabHandler = () => {
    navigate(`/orders/case/continuation/${targetId}`);
  };
  const openHandler = () => {
    dispatch(remakeActions.openWindow());
  };
  const rejectHandler = (description: string) =>
    dispatch(
      actions.rejectTp({
        taskId: targetId,
        description,
      })
    ).unwrap();

  const approveTPHandler = () => {
    if (dmDoctor && !dmProfileId) {
      dispatch(actions.openDM());
    } else {
      setLoading(true);
      dispatch(actions.approveTp(targetId))
        .unwrap()
        .then(() => {
          showSuccessNotice("Treatment plan successfully approved.");
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const payInvoiceHandler = () => {
    navigate(`/invoices/${targetId}`);
  };

  const completeContinuation = () => {
    navigate(`/orders/continuation/complete/${targetId}`);
  };

  const submitEvaluation = () => {
    navigate(`/orders/aligners/new/${targetId}`);
  };

  switch (actionType) {
    case OrderActions.CREATE_CONTINUATION:
      return (
        <Button
          data-intercom-target="Submit Draft button"
          variant="contained"
          onClick={submitToLabHandler}
        >
          Submit Draft
        </Button>
      );
    case OrderActions.SUBMIT_TO_LAB:
      return (
        <Button
          data-intercom-target="Submit Draft button"
          variant="contained"
          onClick={submitToLabHandler}
        >
          Submit Draft
        </Button>
      );
    case OrderActions.COMPLETE_CONTINUE_ORDER:
      return (
        <Button
          data-intercom-target="Submit Draft button"
          variant="contained"
          onClick={completeContinuation}
        >
          Submit Draft
        </Button>
      );
    case OrderActions.APPROVE_TP:
      return (
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <Button
              loading={loading}
              data-intercom-target="Approve TP button"
              variant="contained"
              onClick={approveTPHandler}
            >
              Approve TP
            </Button>
            <RejectTPButton onTpReject={rejectHandler} />
          </Stack>
          <CalendlyFrame
            patient={`${caseInfo?.patient.firstName || ""} ${
              caseInfo?.patient.lastName || ""
            }`}
            discussTP={discussTP}
            caseId={caseInfo?.id!}
          >
            <Link
              data-intercom-target="Schedule consult button"
              sx={{ cursor: "pointer" }}
              variant="body2"
            >
              Or schedule consult if you need to discuss TP
            </Link>
          </CalendlyFrame>
        </Stack>
      );
    case OrderActions.SUBMIT_CONTINUATION:
      return (
        <Stack spacing={2} alignItems="flex-start">
          {/* TODO: OSBA-3716 hidden until needed */}
          {/* <Stack direction="row" spacing={0.5}>
            <Typography variant="body2" color={Colors.PRIMARY}>
              Have an issue with your order?
            </Typography>
            <Link
              data-intercom-target="Request remake"
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              variant="body2"
              onClick={openHandler}
            >
              Request remake
            </Link>
          </Stack> */}
        </Stack>
      );
    case OrderActions.SUBMIT_PHOTO_EVALUATIONS:
      return (
        <Button
          data-intercom-target="Order Aligners button"
          variant="contained"
          onClick={submitEvaluation}
        >
          Order Aligners
        </Button>
      );
    case OrderActions.SCHEDULE_CONSULT:
      return (
        <Stack spacing={1}>
          <CalendlyFrame
            patient={`${caseInfo?.patient.firstName || ""} ${
              caseInfo?.patient.lastName || ""
            }`}
            discussTP={discussTP}
            caseId={caseInfo?.id!}
          >
            <Button
              data-intercom-target="Schedule consult button"
              variant="contained"
            >
              Schedule consult
            </Button>
          </CalendlyFrame>
          <Typography variant="body2" color={Colors.TEXT_GREY}>
            Order is on Hold, please contact us.
          </Typography>
        </Stack>
      );
    case OrderActions.SUBMIT_ALIGNER_ORDER:
      return (
        <Button
          data-intercom-target="Submit aligner order button"
          variant="contained"
        >
          Submit aligner order
        </Button>
      );
    case OrderActions.PAY_INVOICE:
      return (
        <Button
          data-intercom-target="Pay invoices button"
          variant="contained"
          onClick={payInvoiceHandler}
        >
          Pay invoices
        </Button>
      );
    case OrderActions.DM_MONITORING:
      return (
        <Typography variant="body2" color={Colors.TEXT_GREY}>
          <AttentionText>Activate Dental Monitoring</AttentionText> on the day
          you provide your patient with the scan box hardware and take their
          first scans.
        </Typography>
      );
  }
  return null;
};
