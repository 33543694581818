import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAuthorizedUserData, IAuthData } from "lib/model";
import { IExtUserDtoForProfileView, IGroupedUnreadMessageDto } from "lib/dto";
import { authService, orderMessageService, userService } from "services";
import { CurrentUserActions } from "./types";
import { withDoctorModeProcessing } from "./utils";

export const loginCurrentUser = createAsyncThunk<
  IAuthorizedUserData & { unreadMessages: IGroupedUnreadMessageDto[] },
  string
>(CurrentUserActions.CURRENT_USER_LOG_IN, async (code) => {
  const authData: IAuthData = await withDoctorModeProcessing(authService.loginUser, [code]);
  const userProfileData: IExtUserDtoForProfileView =
    await userService.getUserProfile(authData.currentUser.userId);
  const unreadMessages = await orderMessageService.getUnreadMessagesCount();
  return {
    ...authData,
    offices: userProfileData.offices,
    doctorInfo: userProfileData.doctorInfo,
    roles: userProfileData.auth0Roles,
    profile: userProfileData,
    unreadMessages,
  };
});

export const linkCurrentUser = createAsyncThunk<
  IAuthorizedUserData & { unreadMessages: IGroupedUnreadMessageDto[] },
  string
>(CurrentUserActions.CURRENT_USER_LINK, async (code) => {
  await userService.linkIdentity(code);
  const authData: IAuthData = await withDoctorModeProcessing(authService.getUserData);
  const userProfileData: IExtUserDtoForProfileView =
    await userService.getUserProfile(authData.currentUser.userId);
  const unreadMessages = await orderMessageService.getUnreadMessagesCount();
  return {
    ...authData,
    offices: userProfileData.offices,
    doctorInfo: userProfileData.doctorInfo,
    roles: userProfileData.auth0Roles,
    profile: userProfileData,
    unreadMessages,
  };
});

export const fetchCurrentUser = createAsyncThunk<
  IAuthorizedUserData & { unreadMessages: IGroupedUnreadMessageDto[] }
>(CurrentUserActions.CURRENT_USER_FETCHED, async (_) => {
  const authData: IAuthData = await withDoctorModeProcessing(authService.getUserData);
  const userProfileData: IExtUserDtoForProfileView =
    await userService.getUserProfile(authData.currentUser.userId);
  const unreadMessages = await orderMessageService.getUnreadMessagesCount();
  return {
    ...authData,
    offices: userProfileData.offices,
    doctorInfo: userProfileData.doctorInfo,
    roles: userProfileData.auth0Roles,
    profile: userProfileData,
    unreadMessages,
  };
});

export const refreshMessageCount = createAsyncThunk<IGroupedUnreadMessageDto[]>(
  CurrentUserActions.CURRENT_USER_SET_MESSAGE_COUNT,
  async () => {
    return await orderMessageService.getUnreadMessagesCount();
  }
);
