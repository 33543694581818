import { createReducer } from "@reduxjs/toolkit";

import { initState } from "./state";
import { UserPreferencesState } from "./types";
import * as actions from "./actions";

export const reducer = createReducer<UserPreferencesState>(
  initState,
  (builder) => {
    builder
      .addCase(actions.setField, (state, action) => {
        state[action.payload.fieldName] = action.payload.value;
      })
      .addCase(actions.fetchInfo.fulfilled, (state, action) => {
        const rate = action.payload.shippingRate;
        state.isLoading = false;
        state.wearPreference = action.payload.wearPreference;
        state.iprPreference = action.payload.iprPreference;
        state.attachPreference = action.payload.attachPreference;
        state.additional = action.payload.additional;
        state.shippingLevel = action.payload.shippingLevel;
        state.customStep = action.payload.customStep;
        state.existsPreference = Object.values(
          action.payload.existsPreference || {}
        ).some((value) => !!value)
          ? action.payload.existsPreference
          : undefined;
        state.shippingRate = {
          cheapest: {
            packageType: rate ? rate.cheapestPackagingCode : "",
            serviceCode: rate ? rate.cheapestServiceCode : "",
          },
          fastest: {
            packageType: rate ? rate.fastestPackagingCode : "",
            serviceCode: rate ? rate.fastestServiceCode : "",
          },
        };
      })
      .addCase(actions.fetchInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.fetchInfo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(actions.saveHandler.fulfilled, (state) => {
        state.isSaving = false;
      })
      .addCase(actions.saveHandler.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(actions.saveHandler.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(actions.clearState, () => {
        return initState;
      });
  }
);
