import { ListTableContainerProps } from "containers/listTable/types";
import { ICaseTabDto } from "lib/dto";
import { dateToString } from "utils";
import { caseService } from "services";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { Colors } from "consts/colors";
import { StatusColumn } from "../components/StatusColumn";
import { CaseProgressColumn } from "../components/CaseProgressColumn";
import { Nullable } from "lib/types";
import { QuestionIcon } from "assets/vectorIcon";
import Stack from "@mui/material/Stack";


async function fetch(filter: any) {
  return await caseService.getCasesAtLab(filter);
}
const columns: ListTableContainerProps<ICaseTabDto>["columns"] = [
  {
    title: "Patient",
    targetLabel: "Patient",
    style: {
      headerCell: { justifyContent: "center" },
    },
    dataIndex: "patientFirstName",
    sort: true,
    onClick: () => { },
    render: (_: string, { patientFirstName, patientLastName }: ICaseTabDto) => {
      return (
        <Typography
          variant="body2"
          fontWeight={500}
          color={Colors.PRIMARY}
        >{`${patientFirstName} ${patientLastName}`}</Typography>
      );
    },
  },
  {
    title: "Lab status",
    targetLabel: "Lab status",
    dataIndex: "labStatus",
    render: (status: number, { needPreEvaluation }) => (
      <CaseProgressColumn isPreEval={needPreEvaluation} currentState={status} />
    ),
  },
  {
    title: "Order date",
    targetLabel: "Order date",
    dataIndex: "orderDate",
    width: "150px",
    render: (date: string) => (date ? dateToString(date) : "N/A"),
    sort: true,
  },
  {
    title: "Est. Ship Date",
    targetLabel: "Est. Ship Date",
    dataIndex: "deliveryEst",
    width: "150px",
    render: (date: Nullable<string>) => (
      date
        ? dateToString(date)
        : (
          <Tooltip
            arrow
            placement="top"
            title="The date will be available after approving treatment plan"
          >
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" fontWeight={500}>
                --/--/--
              </Typography>
              <QuestionIcon sx={{ width: "16px", height: "16px" }} />
            </Stack>
          </Tooltip>
        )
    ),
    sort: true,
  },
  {
    title: "Type",
    targetLabel: "Type",
    dataIndex: "lastActivity",
    width: "150px",
    render: (lastActivity: string, { needPreEvaluation }: ICaseTabDto) => {
      return (
        <Typography variant="body2" fontWeight={500} color={Colors.PRIMARY}>
          {needPreEvaluation ? "Pre-Evaluation" : lastActivity}
        </Typography>
      );
    },
  },
  {
    title: "Status",
    targetLabel: "Status",
    width: "250px",
    dataIndex: "caseTabAction",
    render: (_, info) => <StatusColumn caseInfo={info} />,
    style: {
      rowCell: {
        padding: "12px 24px",
      },
    },
    sort: true,
  },
];

export const atLabListConf: ListTableContainerProps<ICaseTabDto> = {
  columns,
  emptyDataText: "No patient records found <At Lab>",
  requests: { fetch },
  pagination: true,
};
