import { useMemo, useState } from "react";
import { State } from "./utils";
import { dateToString } from "utils";
import { IExtFileDto } from "lib/dto";

export const useFilesState = (files: IExtFileDto[]) => {
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<State>({
    dateFilter: "",
    orderFilter: "",
    personFilter: "",
    typeFilter: "",
  });

  const filtersChangeHandler = (filterName: string, filterValue: string) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      [filterName]: filterValue,
    }));
    setPage(1);
  };
  const clearFilter = () => {
    setFilters({
      dateFilter: "",
      orderFilter: "",
      personFilter: "",
      typeFilter: "",
    });
    setPage(1);
  };

  const calculatedFilters = useMemo(() => {
    const orderItems = new Set<string>();
    const dateItems = new Set<string>();
    files.forEach(({ orderId, created }) => {
      if (orderId) {
        orderItems.add(orderId.toString());
      }
      dateItems.add(dateToString(created));
    });
    return {
      orderFilter: Array.from(orderItems.values()).map((item) => ({
        title: item,
        value: item,
      })),
      dateFilter: Array.from(dateItems.values()).map((item: string) => ({
        title: dateToString(item),
        value: item,
      })),
    };
  }, [files]);

  return {
    page,
    filters,
    calculatedFilters,
    setPage,
    filtersChangeHandler,
    clearFilter,
  };
};
