import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";

import { FontAwesomeSvgIcon } from "components/common";

import { HeaderContainer, BackButton, BackText } from "./Elements";
import { useNavigate, useParams } from "react-router-dom";

export const Header = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate(`/cases/${caseId}`, { replace: true });
  };
  return (
    <Box>
      <BackButton
        onClick={goBackHandler}
        direction="row"
        spacing={3}
        alignItems="center"
      >
        <FontAwesomeSvgIcon fontSize="small" icon={faArrowLeft} />
        <BackText fontSize="inherit">return to patient detail page</BackText>
      </BackButton>
      <HeaderContainer variant="h1">Continuing Order</HeaderContainer>
    </Box>
  );
};
