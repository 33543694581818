import {
  styled,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ArrowIcon, CloseIcon, FilterIcon } from "assets/vectorIcon";
import { Colors } from "consts/colors";
import TableRow from "@mui/material/TableRow";

export const PaginationItemsCountContainer = styled("div")`
  display: flex;
  align-items: center;
  color: ${Colors.TEXT_GREY};
  font-size: 12px;
  margin-right: 12px;
  cursor: pointer;
  & .text {
    color: ${Colors.LABEL_GREY};
    padding: 0 0 0 5px;
  }
`;
export const HeaderContainer = styled(TableHead)`
  padding: 0 12px;
  border-radius: 8px 8px 0 0;
`;
export const HeaderCell = styled(TableCell)`
  padding: 12px 24px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: white;
  box-sizing: border-box;
  background-color: var(--primary-color);
  &.active {
    font-weight: 600;
  }
  & .showHover {
    visibility: hidden;
  }
  &:hover .showHover {
    visibility: visible;
    transition: 0.1s;
  }
`;

export const HeaderCloseIcon = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  position: relative;
  top: 3px;
`;

export const HeaderFilterIcon = styled(FilterIcon)`
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
`;
export const Input = styled("input")`
  flex: auto;
  width: 100%;
  border: none;
  line-height: 18px;
  font-weight: 500;
  padding-right: 6px;
  background: transparent;
  color: inherit;
  &::placeholder {
    color: inherit;
  }
  &:active {
    border: none;
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
`;

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Listbox = styled("ul")`
  width: auto;
  margin: 0;
  padding: 0;
  background: white;
  border-radius: 4px;
  border: 1px solid ${Colors.LABEL_GREY};
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.TEXT_GREY};
  max-height: 294px;
  overflow: auto;
  list-style: none;
`;

export const ListItem = styled("li")`
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background: ${Colors.TERTIARY};
  }
`;

export const HeaderArrowIcon = styled(ArrowIcon)`
  position: relative;
  top: 2px;
  left: 7px;
  width: 14px;
  height: 14px;
  &.asc {
    top: 0;
    left: 0;
    transform: rotate(180deg);
  }
`;
export const List = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;
export const TotalCount = styled("span")`
  color: ${Colors.DISABLED};
`;
export const LiCount = styled("li")`
  font-size: 12px;
`;
export const NavigatePreviousIcon = styled(NavigateNextIcon)`
  transform: rotate(180deg);
`;
export const PaginationContainer = styled(Grid)`
  justify-content: flex-end;
  margin-top: 16px;
`;
export const Container = styled(TableContainer)`
  box-shadow: 0 4px 30px rgba(56, 71, 109, 0.1);
  border-radius: 8px;
`;
export const RowContainer = styled(TableBody)`
  position: relative;
  top: -1px;
  background: white;
  padding: 0 12px;
  border-radius: 8px;
  overflow: auto;
`;

export const RowContent = styled(TableRow)`
  &:last-of-type {
    border-bottom: none;
  }
`;
export const RowCell = styled(TableCell)`
  padding: 24px;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.TEXT_GREY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`;

export const NoData = styled(TableCell)`
  text-align: center;
  padding: 32px;
`;
