import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@mui/material";

import { QuestionIcon } from "assets/vectorIcon";
import { caseService } from "services";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { Button } from "components/ui";
import { InfoWithLabel } from "components/common";
import { dateToString } from "utils";

import { CaseProgressInfo } from "./CaseProgressInfo";
import { CaseStepInfo } from "./CaseStepInfo";
import { ContentContainer } from "./Elements";
import { ViewTpVideoButton } from "./ViewTpVideoButton";

export const TreatmentPlan = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { caseInfo, productType, priceList, isLoading } = useAppSelector(
    selectors.caseInfoSelector
  );
  const showTpButton = Boolean(
    (caseInfo?.treatPlanLink &&
      caseInfo?.treatPlanLink?.startsWith("http") &&
      !caseInfo?.newTpMode) ||
      caseInfo?.treatPlanExists
  );
  const redirectHandler = () => {
    if (loading) {
      return;
    }
    if (
      Boolean(caseInfo?.treatPlanLink) &&
      caseInfo?.treatPlanLink?.startsWith("http") &&
      !caseInfo?.newTpMode
    ) {
      setLoading(true);
      caseService
        .getTreatmentPlanPdf(caseInfo?.id!)
        .then((tpPdf) => window.open(tpPdf, "_blank"))
        .catch()
        .finally(() => setLoading(false));
    } else {
      window.open(`${window.location.origin}/treatment-plan/${caseInfo?.id}`);
    }
  };
  const openHandler = () => {
    window.open(caseInfo?.tpAnimation);
  };
  const arches = `${caseInfo?.upperArch ? "Upper" : ""}${
    caseInfo?.upperArch && caseInfo?.lowerArch ? ", " : ""
  }${caseInfo?.upperArch ? "Lower" : ""}`.trim();

  return (
    <Box mb={0.5} minHeight={200} data-intercom-target="Treatment plan info">
      <Stack spacing={3}>
        <ContentContainer>
          <Box minWidth={180}>
            <InfoWithLabel
              isLoading={isLoading}
              textStyleSx={{ fontSize: 14 }}
              label="ARCHES"
              text={arches}
              direction="column"
            />
          </Box>
          <CaseStepInfo />
          <CaseProgressInfo />
        </ContentContainer>
        <ContentContainer>
          <Box minWidth={180}>
            <InfoWithLabel
              isLoading={isLoading}
              textStyleSx={{ fontSize: 14 }}
              label="TREATMENT START DATE"
              text={dateToString(caseInfo?.created || "")}
              direction="column"
            />
          </Box>
          {productType && (
            <Box minWidth={180}>
              <InfoWithLabel
                isLoading={isLoading}
                textStyleSx={{ fontSize: 14 }}
                label="Package"
                text={
                  <>
                    <Tooltip
                      arrow
                      placement="right"
                      title={priceList?.description ?? "-"}
                    >
                      <span>
                        {productType?.packageType?.name || "N/A"}
                        <QuestionIcon sx={{ width: "16px", height: "16px" }} />
                      </span>
                    </Tooltip>
                  </>
                }
                direction="column"
              />
            </Box>
          )}
        </ContentContainer>
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        px={10.5}
        mt={5}
        mb={2.5}
        justifyContent="center"
      >
        <ViewTpVideoButton />
        {Boolean(caseInfo?.tpAnimation) && (
          <Button
            data-intercom-target="TP animation view button"
            variant="outlined"
            onClick={openHandler}
          >
            View 3D Animation
          </Button>
        )}
        {showTpButton && (
          <Button
            loading={loading}
            variant="outlined"
            onClick={redirectHandler}
          >
            View treatment plan
          </Button>
        )}
      </Stack>
    </Box>
  );
};
