import { IPatient, IUploadedFile } from "lib/model";
import { CaseBudgetEnum, OrderItemType, TxPlanComplexityEnum } from "lib/enum";
import {
  IExtCaseDto,
  IExtOfficeStaffDto,
  IOfficeDto,
  IPatientDto,
} from "lib/dto";

export interface Tag {
  id: number;
  name: string;
}

export type RequiredFields =
  | keyof Omit<OrderCreateState, "requiredFields">
  | "arches"
  | "patient.firstName"
  | "patient.lastName"
  | "patient.email"
  | "patient.phone"
  | "patient.birthDate";

export enum OrderType {
  PRE_EVAL = 1,
  NEW_PATIENT,
  RETAINER = 5,
}

export interface OrderCreateState {
  caseInfo: IExtCaseDto | null;
  txPlanComplexity?: TxPlanComplexityEnum | string;
  caseBudget?: CaseBudgetEnum | string;
  caseBudgetCost?: string;
  isLoading: boolean;
  isRetainer: boolean;
  isEditing: boolean;
  caseId?: number;
  orderId?: number;
  shippingLabel?: string;
  lowerArch: boolean;
  upperArch: boolean;
  patient: IPatient;
  includes: Tag[];
  excludes: Tag[];
  doctorId?: string;
  officeStaffId?: string;
  wearSchedule: string;
  stepsType: string;
  ipr: string;
  dmEnabled: string;
  dmNumber: string;
  showDm: boolean;
  attachments: string;
  notes: string;
  customStepsNum: number;
  patients: IPatientDto[];
  preEval: boolean;
  clinicalPhotos: IUploadedFile[];
  otherFiles: IUploadedFile[];
  orderItems: Partial<Record<OrderItemType, number>>;
  complications: (string | null)[];
  extractions: (string | null)[];
  createOnlyOrder?: boolean;
  requiredFields: RequiredFields[];
  useMeditLink: boolean;
  removeAttachment: boolean;
  selectedOffice: IOfficeDto | null;
  productTypeId?: string | null;
}
