import React from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Stack from "@mui/material/Stack";
import { Colors } from "consts/colors";
import { FontAwesomeSvgIcon } from "components/common";
import { styled } from "@mui/material";

interface Props {
  complete: boolean;
  inProgress: boolean;
  pending: boolean;
  icon: React.ReactElement<SvgIconProps>;
  isFirstPoint?: boolean;
}

const LinkLine = styled("span")`
  width: 10px;
  height: 3px;
  background: linear-gradient(0deg, #464e5f, #464e5f), #d1d1d1;
  border-radius: 46px;
`;

const LinkLineDisabled = styled("span")`
  width: 10px;
  height: 3px;
  background: linear-gradient(0deg, #d1d1d1, #d1d1d1), #d1d1d1;
  border-radius: 46px;
`;

const CaseProgressIcon = (props: Exclude<Props, "isLastPoint">) => {
  if (props.complete) {
    return (
      <Stack
        sx={{
          width: 16,
          height: 16,
          borderRadius: "50%",
          backgroundColor: Colors.SECONDARY,
          color: "white",
          fontSize: 12,
        }}
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeSvgIcon icon={faCheck} fontSize="inherit" />
      </Stack>
    );
  }
  if (props.inProgress) {
    return React.cloneElement(props.icon, {
      fontSize: "medium",
      color: "secondary",
    });
  }
  return React.cloneElement(props.icon, {
    fontSize: "small",
    color: "disabled",
  });
};

export const CaseProgressItem: React.FC<Props> = (props) => {
  const line = props.pending ? <LinkLineDisabled /> : <LinkLine />;
  return (
    <Stack direction="row" alignItems="center" spacing={0.25}>
      {!props.isFirstPoint && line}
      <CaseProgressIcon
        complete={props.complete}
        inProgress={props.inProgress}
        pending={props.pending}
        icon={props.icon}
      />
    </Stack>
  );
};
