import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { MainContainer } from "containers";
import { Header } from "components/common";
import { useStyledSnackbar } from "hooks/notification";
import {
  CloseBtnContainer,
  StyledButton,
  Wrapper,
} from "./components/Elements";
import { IMenuItem, MenuButton } from "components/ui";
import { InvoiceStatuses, PaymentStatuses } from "lib/enum";
import { invoiceStatuses } from "lib/dictionaries";
import { InfoInvoice } from "./components/InfoInvoice";
import { ItemsTableInvoice } from "./components/ItemsTableInvoice";
import { AmountsInvoice } from "./components/AmountsInvoice";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { actions } from "store/invoicesCard";

export const InvoicesCardPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { invoiceId } = params;
  const dispatch = useAppDispatch();
  const invoice = useAppSelector((state) => state.invoicesCard.invoice);
  const order = useAppSelector((state) => state.invoicesCard.order);
  const payment = useAppSelector((state) => state.invoicesCard.payment);
  const { showErrorNotice } = useStyledSnackbar();

  const payActions: IMenuItem[] = [];
  if (
    invoice?.stripeUrl &&
    (invoice?.status === InvoiceStatuses.OPEN ||
      invoice?.status === InvoiceStatuses.UNPAID)
  ) {
    payActions.push({
      label: "Pay online by CC",
      handler: () => {
        invoice?.stripeUrl && window.open(invoice?.stripeUrl, "_blank");
      },
    });
  }

  if (
    invoice?.status === InvoiceStatuses.OPEN &&
    order?.office.balance &&
    order.office.balance > 0
  ) {
    payActions.push({
      label: "Pay by office's balance",
      handler: () => {
        navigate(
          `/payments/new?org=${order?.org?.id}&office=${order?.office?.id}`
        );
      },
    });
  }

  useEffect(() => {
    dispatch(actions.getInvoiceInfo(Number(invoiceId)))
      .unwrap()
      .catch((error) => {
        showErrorNotice(error.message);
      });
  }, [invoiceId, dispatch, showErrorNotice]);
  const invoiceStatus =
    invoice?.status === InvoiceStatuses.OPEN &&
    payment?.status === PaymentStatuses.IN_PROCESS
      ? " (Pending)"
      : invoice?.status
      ? ` (${invoiceStatuses[invoice?.status]})`
      : null;
  return (
    <MainContainer>
      <Header
        title={
          <>
            Read invoice
            {invoiceStatus}
          </>
        }
        right={
          payActions.length ? (
            <MenuButton title="Pay" variant="contained" items={payActions} />
          ) : undefined
        }
      />
      <InfoInvoice />
      <ItemsTableInvoice />
      <AmountsInvoice />
      <Wrapper>
        <CloseBtnContainer>
          <StyledButton variant="contained" onClick={() => navigate(-1)}>
            Close
          </StyledButton>
        </CloseBtnContainer>
      </Wrapper>
    </MainContainer>
  );
};
