import Stack from "@mui/material/Stack";
import { StyledButton } from "./Elements";
import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors, extActions } from "store/requestRemake";
import { OrderRemakeType } from "lib/enum";
import { useStyledSnackbar } from "hooks/notification";
import { useState } from "react";
import printJS from "print-js";

export const Footer = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotice, showSuccessNotice } = useStyledSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { remakeType, stepNum } = useAppSelector(selectors.getMainInfo);
  const disableSubmit = useAppSelector(selectors.disableSubmitButton);

  const nextStepHandler = () => {
    dispatch(actions.changeStepNum(1));
  };
  const prevStepHandler = () => {
    dispatch(actions.changeStepNum(0));
  };
  const closeHandler = () => {
    dispatch(actions.closeWindow());
  };
  const submitHandler = () => {
    const message =
      remakeType === OrderRemakeType.REMAKE_ORDER
        ? "Order issue reported"
        : "Lost package reported";
    setLoading(true);
    dispatch(extActions.submitRemakeOrder())
      .unwrap()
      .then(({ shippingLabel }) => {
        if (shippingLabel) {
          printJS({
            printable: `data:image/jpeg;base64,${shippingLabel}`,
            type: "image",
          });
        }
        showSuccessNotice(message);
      })
      .catch((e) => {
        showErrorNotice(e.message);
        setLoading(false);
      });
  };

  if (remakeType === OrderRemakeType.REMAKE_ORDER && !stepNum) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        flexGrow={1}
        px={2}
        pb={1}
        color={Colors.DISABLED}
      >
        <StyledButton color="inherit" variant="text" onClick={closeHandler}>
          Cancel
        </StyledButton>
        <Stack direction="row" spacing={2}>
          <StyledButton variant="contained" onClick={nextStepHandler}>
            Next
          </StyledButton>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexGrow={1}
      px={2}
      pb={1}
      color={Colors.DISABLED}
    >
      <StyledButton color="inherit" variant="text" onClick={closeHandler}>
        Cancel
      </StyledButton>

      <Stack direction="row" spacing={2}>
        {remakeType === OrderRemakeType.REMAKE_ORDER && stepNum && (
          <StyledButton variant="outlined" onClick={prevStepHandler}>
            Back
          </StyledButton>
        )}
        <StyledButton
          loading={loading}
          disabled={disableSubmit}
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </StyledButton>
      </Stack>
    </Stack>
  );
};
