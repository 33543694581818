import React from "react";
import MUITextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material";
import { Colors } from "consts/colors";

const StyledTextField = styled(MUITextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.SECONDARY};
  }

  & .MuiFormHelperText-root {
    font-size: 12px;
    margin: 0;
  }
`;

export const TextField = ({
  variant = "outlined",
  margin = "none",
  size = "small",
  ...props
}: TextFieldProps) => {
  return (
    <StyledTextField margin={margin} variant={variant} size={size} {...props} />
  );
};
