import React from "react";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";

import { TextField } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { OrderRemakeType } from "lib/enum";
import { actions } from "store/requestRemake";

export const OrderIssue = () => {
  const dispatch = useAppDispatch();
  const partialReplacement = useAppSelector(
    (state) => state.requestRemake.partialReplacement
  );
  const remakeType = useAppSelector((state) => state.requestRemake.remakeType);
  const notes = useAppSelector((state) => state.requestRemake.notes);

  const notesChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.changeNotes(event.target.value));
  };
  const partialReplacementChange = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    dispatch(actions.changePartReplace(checked));
  };

  return (
    <Stack>
      {remakeType === OrderRemakeType.REMAKE_ORDER && (
        <FormControlLabel
          componentsProps={{
            typography: { variant: "body2" },
          }}
          onChange={partialReplacementChange}
          value={partialReplacement}
          control={<Switch size="small" />}
          label="Partial replacement"
        />
      )}
      <Stack mt={2} spacing={0.5}>
        <Typography variant="body2">
          {remakeType === OrderRemakeType.REMAKE_ORDER
            ? "Please specify order issue"
            : "Notes"}
        </Typography>
        <TextField
          onChange={notesChangeHandler}
          value={notes}
          multiline
          rows={4}
          fullWidth
        />
      </Stack>
    </Stack>
  );
};
