import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { CreateShippingState } from "./types";
import { ShippingTypes } from "lib/enum";

const selectSelf = (state: RootState) => state.shipping;

export const shippingInfoSelector = createSelector(
  selectSelf,
  (state: CreateShippingState) => ({
    office: state.office,
    lab: state.lab,
  })
);

export const packageInfoSelector = createSelector(
  selectSelf,
  (state: CreateShippingState) => state.shippingInfo
);

export const disableButtonSelector = createSelector(
  selectSelf,
  (state: CreateShippingState) => {
    return Boolean(state.shippingType === ShippingTypes.OTHER && !state.notes);
  }
);

export const loadingSelector = createSelector(
  selectSelf,
  (state: CreateShippingState) => state.isLoading
);

export const shippingTypeSelector = createSelector(
  selectSelf,
  (state: CreateShippingState) => state.shippingType
);
export const shippingLevelSelector = createSelector(
  selectSelf,
  (state: CreateShippingState) => state.shippingLevel
);

export const noteSelector = createSelector(
  selectSelf,
  (state: CreateShippingState) => state.notes
);

export const showShippingLevel = createSelector(
  selectSelf,
  (state: CreateShippingState) => {
    return Boolean(
      (state.cheapest.upsService || state.cheapest.packageType) &&
        (state.fastest.upsService || state.fastest.packageType)
    );
  }
);
