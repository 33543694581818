export const packageTypes = {
  "01": "UPS Letter",
  "02": "Customer Supplied Package",
  "03": "Tube",
  "04": "PAK",
  "21": "UPS Express Box",
  "2a": "Small Express Box",
  "2b": "Medium Express Box",
  "2c": "Large Express Box 56 = Flats",
};
