import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { Button } from "components/ui";

import { NotesItem } from "./NotesItem";
import { selectors, actions } from "store/treatmentPlan";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { NoteListDot } from "./NoteElements";
import { useStyledSnackbar } from "hooks/notification";
import { NoteAddForm } from "./NoteAddForm";

export const NotesList: React.FC = () => {
  const tpNotes = useAppSelector(selectors.notesSelector);
  const { showErrorNotice } = useStyledSnackbar();
  const dispatch = useAppDispatch();
  const [currentAnchor, setCurrentAnchor] = useState<
    { top: number; left: number } | undefined
  >();
  const [move, setMove] = useState<boolean>(false);
  const moveStartHandler = () => {
    setMove(true);
  };
  const closeHandler = () =>
    dispatch(actions.openToShow({ noteIds: [], addNote: false }));
  const moveUpdateHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (move) {
      setCurrentAnchor({
        top: event.clientY,
        left: event.clientX,
      });
    }
  };
  const moveEndHandler = () => {
    setMove(false);
  };
  const selectNoteHandler = (id: number) => () => {
    dispatch(actions.selectNote(id === tpNotes.selectedNote ? null : id));
  };
  const deleteNoteHandler = (id: number) => () => {
    dispatch(actions.deleteNote(id))
      .unwrap()
      .catch((err) => {
        showErrorNotice(err.message);
      });
  };
  useEffect(() => {
    setCurrentAnchor(tpNotes.anchor);
  }, [tpNotes.anchor]);
  const notesForShow = tpNotes.notesForShow ?? [];
  return (
    <Popover
      open={Boolean(tpNotes.anchor)}
      anchorPosition={currentAnchor}
      anchorReference="anchorPosition"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ borderRadius: 8 }}
      onMouseMove={moveUpdateHandler}
      onMouseUp={moveEndHandler}
    >
      <div>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="center"
          height={16}
          paddingBottom={0.25}
          onMouseDown={moveStartHandler}
          sx={{ cursor: "grabbing" }}
        >
          <NoteListDot />
          <NoteListDot />
          <NoteListDot />
        </Stack>
        <Box maxHeight={565} overflow="auto">
          {notesForShow.map(({ forDoctor, noteDto }) => (
            <NotesItem
              to={forDoctor ? "Doctor" : "Technician"}
              key={noteDto.id}
              note={noteDto}
              selected={noteDto.id === tpNotes.selectedNote}
              onDelete={deleteNoteHandler(noteDto.id)}
              onClick={selectNoteHandler(noteDto.id)}
            />
          ))}
        </Box>
        {tpNotes.addNote ? (
          <Box>
            <NoteAddForm />
          </Box>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            py={2}
          >
            <Button disableElevation onClick={closeHandler} variant="outlined">
              Close
            </Button>
          </Stack>
        )}
      </div>
    </Popover>
  );
};
