import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const HomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path
        d="M8.34709 0.128396C8.14736 -0.0427988 7.85264 -0.0427988 7.65291 0.128396L0 6.68804V14.4C0 15.2837 0.716344 16 1.6 16H5.86667C6.16122 16 6.4 15.7612 6.4 15.4667V12.2667C6.4 11.383 7.11634 10.6667 8 10.6667C8.88366 10.6667 9.6 11.383 9.6 12.2667V15.4667C9.6 15.7612 9.83878 16 10.1333 16H14.4C15.2837 16 16 15.2837 16 14.4V6.68804L8.34709 0.128396Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
