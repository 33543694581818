export const toLocalCurrency = (currency: string | number) => {
  return currency.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const someInclude = <T>(arr: T[], arr2: T[]) => {
  return arr.some((value) => arr2.includes(value));
};
