import React, { useEffect } from "react";
import { useAppDispatch } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { fetchDashboardEvents } from "store/dashboard/actions";
import { MainContainer } from "containers";

import { Content } from "./components";
import "./styles.css";

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  useEffect(() => {
    dispatch(fetchDashboardEvents())
      .unwrap()
      .catch((error) => showErrorNotice(error.message));
  }, [dispatch, showErrorNotice]);
  return (
    <MainContainer>
      <Content />
    </MainContainer>
  );
};
