import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import printJS from "print-js";

import { Colors } from "consts/colors";
import { useStyledSnackbar } from "hooks/notification";
import { shippingService } from "services";

interface Props {
  shippingId: number;
}

export const PrintShippingLabelButton: React.VFC<Props> = ({ shippingId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showErrorNotice } = useStyledSnackbar();
  const printShippingHandler = () => {
    setLoading(true);
    shippingService
      .getShippingLabelById(shippingId)
      .then(({ shippingLabelBase64 }) => {
        setLoading(false);
        printJS({
          printable: `data:image/jpeg;base64,${shippingLabelBase64}`,
          type: "image",
        });
      })
      .catch((error) => {
        showErrorNotice(error.message);
        setLoading(false);
      });
  };
  if (loading) {
    return (
      <Box color={Colors.TEXT_GREY}>
        <CircularProgress size={28} color="inherit" />
      </Box>
    );
  }
  return (
    <IconButton aria-label="print" size="small" onClick={printShippingHandler}>
      <PrintIcon fontSize="inherit" />
    </IconButton>
  );
};
