import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import { Button } from "components/ui";
import { CalendlyFrame, RejectTPButton } from "components/common";
import { useStyledSnackbar } from "hooks/notification";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/treatmentPlan";

export const ApproveTpBlock = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showSuccessNotice, showErrorNotice } = useStyledSnackbar();
  const dispatch = useAppDispatch();
  const caseInfo = useAppSelector(selectors.caseInfoSelector);

  const rejectHandler = async (description: string) =>
    dispatch(
      actions.rejectTp({
        description,
      })
    ).unwrap();
  const approveTPHandler = () => {
    setLoading(true);
    dispatch(actions.approveTp())
      .unwrap()
      .then(() => showSuccessNotice("Treatment plan successfully approved."))
      .catch((error) => {
        showErrorNotice(error.message);
        setLoading(false);
      });
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2}>
        <Button
          data-intercom-target="Approve TP button"
          loading={loading}
          variant="contained"
          onClick={approveTPHandler}
        >
          Approve TP
        </Button>
        <RejectTPButton onTpReject={rejectHandler} />
      </Stack>
      <CalendlyFrame
        patient={`${caseInfo?.patient.firstName || ""} ${
          caseInfo?.patient.lastName || ""
        }`}
        discussTP
        caseId={caseInfo?.id!}
      >
        <Link
          data-intercom-target="Schedule consult button"
          sx={{ cursor: "pointer" }}
          variant="body2"
        >
          Or schedule consult if you need to discuss TP
        </Link>
      </CalendlyFrame>
    </Stack>
  );
};
