import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ContinuationOrderCreateContainer } from "containers";
import { analyticsMethod } from "plugins/analytics";

import { useAppDispatch } from "hooks/redux";
import { actions } from "store/continuationOrderCreate";

export const CompleteContinuationOrderPage = () => {
  analyticsMethod(
    "page",
    "Orders",
    {
      title: "Create form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  const dispatch = useAppDispatch();
  const { caseId } = useParams<{ caseId: string }>();
  useEffect(() => {
    if (caseId) {
      dispatch(actions.fetchOrderInfo(caseId));
    }
    return () => {
      dispatch(actions.clearState());
    };
  }, [dispatch, caseId]);
  return <ContinuationOrderCreateContainer />;
};
