import React, { useCallback, useEffect, useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { userService, orgService } from "services";
import { selectors, actions } from "store/caseCreate";
import { selectors as userSelectors } from "store/currentUser";
import { Select, FormLabel } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { sendSegment } from "utils/analyticsMethod";

export const DoctorSelector = () => {
  const [staffs, setStaffs] = useState<
    { label: string; value: string; showDM: boolean; dmEnabled: boolean }[]
  >([]);
  const [doctors, setDoctors] = useState<{ label: string; value: string }[]>(
    []
  );
  const caseInfo = useAppSelector(selectors.getAllInfoSelector);
  const userOffices = useAppSelector(userSelectors.selectUserOffices);
  const userOfficeIds = useMemo(
    () => userOffices.map(({ id }) => id),
    [userOffices]
  );
  const { showErrorNotice } = useStyledSnackbar();
  const dispatch = useAppDispatch();
  const changeDoctorHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      dispatch(actions.setDoctorId(event.target.value));
    },
    [dispatch]
  );
  const changeOfficeHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      const candidate = staffs.find(
        ({ value }) => value === event.target.value
      );
      let office = {
        officeId: "",
        showDM: false,
        dmEnabled: false,
      };

      if (candidate) {
        office = {
          officeId: candidate.value,
          showDM: candidate.showDM,
          dmEnabled: candidate.dmEnabled,
        };
        sendSegment("Office Selected", office);
        dispatch(actions.setOfficeId(office));
      } else {
        sendSegment("Office Selected", office);
        dispatch(actions.setOfficeId(office));
      }
    },
    [dispatch, staffs]
  );

  useEffect(() => {
    userService
      .getAllDoctors()
      .then((allDoctors) => {
        setDoctors(
          allDoctors.map(({ firstName, lastName, id }) => ({
            label: `${firstName} ${lastName}`,
            value: id.toString(),
          }))
        );
        if (allDoctors.length === 1) {
          dispatch(actions.setDoctorId(allDoctors[0].id.toString()));
        }
      })
      .catch((e) => showErrorNotice(e.message));
  }, [dispatch, caseInfo.doctorId, showErrorNotice, userOfficeIds]);

  useEffect(() => {
    if (caseInfo.doctorId) {
      orgService
        .getStaffByUserId(caseInfo.doctorId)
        .then((officeStaffs) => {
          const userStaffs = officeStaffs.filter(({ officeId }) =>
            userOfficeIds.includes(officeId)
          );
          const defOffice = officeStaffs.find(
            ({ isDefaultOffice }) => isDefaultOffice
          );
          if (userStaffs.length) {
            setStaffs(
              userStaffs.map(({ officeName, officeStaffId, dmEnabled }) => ({
                label: officeName,
                value: officeStaffId.toString(),
                showDM: dmEnabled,
                dmEnabled,
              }))
            );
            if (userStaffs.length === 1) {
              dispatch(
                actions.setOfficeId({
                  officeId: userStaffs[0].officeStaffId.toString(),
                  showDM: userStaffs[0].dmEnabled,
                  dmEnabled: userStaffs[0].dmEnabled,
                })
              );
            }
          } else {
            setStaffs(
              officeStaffs.map(({ officeName, officeStaffId, dmEnabled }) => ({
                label: officeName,
                value: officeStaffId.toString(),
                showDM: dmEnabled,
                dmEnabled,
              }))
            );
            dispatch(
              actions.setOfficeId({
                officeId: defOffice
                  ? defOffice.officeStaffId.toString()
                  : officeStaffs[0].officeStaffId.toString(),
                showDM: defOffice
                  ? defOffice.dmEnabled
                  : officeStaffs[0].dmEnabled,
                dmEnabled: defOffice
                  ? defOffice.dmEnabled
                  : officeStaffs[0].dmEnabled,
              })
            );
          }
        })
        .catch((e) => showErrorNotice(e.message));
    }
  }, [dispatch, caseInfo.doctorId, showErrorNotice, userOfficeIds]);

  return (
    <Stack direction="row" spacing={2} mt={2}>
      <FormLabel label="Doctor">
        <Select
          data-intercom-target="Doctor selector"
          sx={{ minWidth: 200 }}
          placeholder="Select one"
          data={doctors}
          onChange={changeDoctorHandler}
          value={caseInfo.doctorId || ""}
        />
      </FormLabel>
      {staffs.length > 1 && (
        <FormLabel label="Office">
          <Select
            data-intercom-target="Office selector"
            sx={{ minWidth: 200 }}
            value={caseInfo.officeStaffId || ""}
            onChange={changeOfficeHandler}
            placeholder="Select one"
            data={staffs}
          />
        </FormLabel>
      )}
    </Stack>
  );
};
