import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { NumberField } from "components/ui";
import { Colors } from "consts/colors";
import { OrderItemType } from "lib/enum";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/continuationOrderCreate";

export const ImpressionsSelect = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectors.getAllInfoSelector);
  const changeHandler = (name: OrderItemType) => (value: number) => {
    dispatch(
      actions.setOrderItem({
        [name]: value,
      })
    );
  };
  return (
    <Stack spacing={2} data-intercom-target="Order impressions">
      <Typography variant="body2" fontSize={12} color={Colors.TEXT_GREY_DARK}>
        You must specify impressions if shipping physical sample
      </Typography>
      <Stack direction="row" spacing={6}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2" color={Colors.TEXT_GREY_DARK}>
            Bite registration
          </Typography>
          <NumberField
            minValue={0}
            maxValue={1}
            value={order.orderItems.BITE_REGISTRATION || 0}
            onChange={changeHandler(OrderItemType.BITE_REGISTRATION)}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2" color={Colors.TEXT_GREY_DARK}>
            Impression
          </Typography>
          <NumberField
            minValue={0}
            maxValue={2}
            value={order.orderItems.IMPRESSION || 0}
            onChange={changeHandler(OrderItemType.IMPRESSION)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
