import React from "react";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { PLAN_COLORS } from "consts/tpColors";
import { NotesRecipient } from "lib/enum";
import { selectors, actions } from "store/treatmentPlan";
import { selectors as userSelectors } from "store/currentUser";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import {
  StyledNotesButton,
  StyledCloseButton,
  NotesHeadContainer,
  NoteFilterChip,
} from "./NoteElements";

export const NotesHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const tpNotes = useAppSelector(selectors.notesSelector);
  const { currentUser } = useAppSelector(userSelectors.getAllUserInfo);
  const treatmentPlanState = useAppSelector(selectors.getAllInfo);
  const changeSelectedNotes = (value?: NotesRecipient) => {
    dispatch(actions.setTpNotesFilter(value));
  };
  const closeNotesHandler = () => {
    dispatch(actions.toggleNotes(false));
  };
  const showAllNotesHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { width, height, left, top } =
      event.currentTarget.getBoundingClientRect();
    dispatch(
      actions.toggleNoteList({
        top: top + height + 8,
        left: left + width,
      })
    );
  };
  return (
    <Box>
      <Collapse in={treatmentPlanState.showNotes} timeout="auto" unmountOnExit>
        <NotesHeadContainer>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle1" fontSize={18} fontWeight="bold">
              Notes view
            </Typography>
            {tpNotes.notes.length > 0 && (
              <StyledNotesButton
                variant="contained"
                color="primary"
                onClick={showAllNotesHandle}
              >
                Show all notes
              </StyledNotesButton>
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            {currentUser?.isAdmin && (
              <>
                <Typography
                  variant="body2"
                  fontSize={14}
                  color={PLAN_COLORS.grey.GREY_8}
                >
                  To:
                </Typography>
                <NoteFilterChip
                  selected={!tpNotes.previewFilter}
                  variant="outlined"
                  size="small"
                  label="All"
                  onClick={() => changeSelectedNotes()}
                />
                <NoteFilterChip
                  selected={tpNotes.previewFilter === NotesRecipient.TECHNICIAN}
                  variant="outlined"
                  size="small"
                  label="Technician"
                  onClick={() => changeSelectedNotes(NotesRecipient.TECHNICIAN)}
                />
                <NoteFilterChip
                  selected={tpNotes.previewFilter === NotesRecipient.DOCTOR}
                  variant="outlined"
                  size="small"
                  label="Doctor"
                  onClick={() => changeSelectedNotes(NotesRecipient.DOCTOR)}
                />
              </>
            )}
          </Stack>
          <Box justifySelf="flex-end">
            <StyledCloseButton onClick={closeNotesHandler}>
              <Close color="disabled" fontSize="inherit" />
            </StyledCloseButton>
          </Box>
        </NotesHeadContainer>
      </Collapse>
    </Box>
  );
};
