import { PatientDetailsState } from "./types";
export const initState: PatientDetailsState = {
  isLoading: false,
  isOrderUpdating: false,
  files: [],
  currentOrder: null,
  caseInfo: null,
  productType: null,
  priceList: null,
  previousOrders: [],
  currentOrderShippings: [],
  currentOrderProducedSteps: null,
  showRating: false,
  showDM: false,
  showStartStopDM: false,
  officeStaff: null,
  orderTasks: [],
  dmFields: {
    birthDate: "",
    phoneNumber: "",
    email: "",
    notify: true,
  },
  searchTabCaseId: { data: [], totalCount: 0 },
  ratingComment: "",
  ratingCompleted: false,
  orderMessages: [],
  invoices: [],
  caseEvents: [],
  openScheduling: false,
  openVerification: false,
  errors: "",
};
