import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material";

import { analytics } from "plugins";
import { useConsentWindow } from "hooks/consentWindow";
import { Button } from "components/ui";
import { authService } from "services";
import logo from "assets/images/orthosnap-logo-web.png";
import { Colors } from "consts/colors";
import { useStyledSnackbar } from "hooks/notification";

const StyledContainer = styled(Container)({
  height: "100vh",
  display: "flex",
  alignItems: "center",
});
const StyledImage = styled("img")({
  maxWidth: "100%",
});
export const LoginPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const consentConfirm = useConsentWindow();
  const { showErrorNotice } = useStyledSnackbar();
  const loginHandler = () => {
    setLoading(true);
    authService
      .getLoginLink()
      .then((loginLink) => {
        window.location.assign(loginLink);
      })
      .catch((error) => {
        showErrorNotice(error.message);
        setLoading(false);
      });
  };
  useEffect(() => {}, []);
  useEffect(() => {
    setTimeout(() => {
      analytics.smartlookSetConsent(consentConfirm);
    }, 500);
  }, [consentConfirm]);
  return (
    <StyledContainer maxWidth="xs">
      <Stack spacing={6}>
        <StyledImage src={logo} alt="Orthosnap" />
        <Button
          startIcon={
            loading ? (
              <CircularProgress sx={{ color: Colors.TERTIARY }} size={14} />
            ) : undefined
          }
          onClick={loginHandler}
          variant="contained"
        >
          Sign in
        </Button>
      </Stack>
    </StyledContainer>
  );
};
