import { styled } from "@mui/material";

export const InfoContainer = styled("section")`
  background: white;
  border: 1px solid var(--container-border-grey-color);
  box-shadow: 0 0 7px rgba(56, 71, 109, 0.1);
  padding-inline: 24px;
  padding-block: 32px 40px;
  border-radius: 4px;
  margin-inline: 16px;
  width: calc(100% - 32px);
  box-sizing: border-box;
`;
