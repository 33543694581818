import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import { IFile } from "lib/model";
import { ListTable, FilePreviewer } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/patientDetail";
import { Colors } from "consts/colors";

import { columns } from "./consts";
import { filterFiles, openPdfInNewTab } from "./utils";
import { useFilesState } from "./hooks";
import { ClearButton } from "./Elements";
import { IExtFileDto } from "lib/dto";
import { fileDownload, getFilesType } from "utils";
import { UploadFilesButton } from "../UploadFilesButton";

interface Props {
  loading: boolean;
}

const ITEMS_PER_PAGE = 4;

export const UploadedFilesTable: React.VFC<Props> = ({ loading }) => {
  const { files } = useAppSelector(selectors.filesSelector);

  const caseInfo = useAppSelector(selectors.caseInfoSelector);

  const dispatch = useAppDispatch();
  const [file, setFile] = useState<IFile | undefined>();
  const {
    page,
    filters,
    clearFilter,
    filtersChangeHandler,
    setPage,
    calculatedFilters,
  } = useFilesState(files);

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const downloadFileHandler = useCallback(
    async (
      fileId: number,
      controller: AbortController,
      contentType?: string
    ) => {
      const { src } = await dispatch(
        actions.downloadFile({ fileId, contentType, controller })
      ).unwrap();
      return src;
    },
    [dispatch]
  );
  const rowClickHandler = (row: IExtFileDto & { src?: string }) => {
    if (getFilesType(row) === "application" || getFilesType(row) === "text") {
      dispatch(
        actions.downloadFile({ fileId: row.id, contentType: row.contentType })
      )
        .unwrap()
        .then(({ src }) => {
          fileDownload(row.name, src);
        });
      return;
    }

    if (getFilesType(row) === "pdf") {
      openPdfInNewTab(row!.src as string);
    } else {
      setFile({
        id: row.id,
        contentType: row.contentType,
        created: row.created,
        name: row.name,
        originalFileId: row.originalFileId,
        originalFileName: row.originalFileName,
        notDeletable: true,
        src: row.src,
      });
    }
  };

  const previewCloseHandler = () => {
    setFile(undefined);
  };

  if (!loading && !files.length) {
    return (
      <Box py={2}>
        {caseInfo.caseInfo?.useMeditLink && (
          <Box pl={2} >
            <Typography variant="caption">
              Submissions scan via MeditLink: Yes
            </Typography>
          </Box>
        )}
        <Typography
          fontWeight="bold"
          variant="body1"
          textAlign="center"
          color={Colors.SECONDARY}
        >
          No files.
        </Typography>
      </Box>
    );
  }
  const filteredFiles = filterFiles(files, filters);
  return (
    <Box px={2.5} data-intercom-target="Uploaded files table">
      <FilePreviewer
        open={Boolean(file)}
        file={file}
        onClose={previewCloseHandler}
        fileDownloadHandler={downloadFileHandler}
      />
      {caseInfo.caseInfo?.useMeditLink && (
        <Box pl={2} pb={2}>
          <Typography variant="caption">
            Submissions scan via MeditLink: Yes
          </Typography>
        </Box>
      )}
      <Stack direction="row" justifyContent="space-between">
        <div>
          {Object.values(filters).some((filter) => Boolean(filter)) ? (
            <ClearButton
              data-intercom-target="Filter clear button"
              size="small"
              startIcon={<CloseIcon fontSize="inherit" />}
              variant="contained"
              onClick={clearFilter}
            >
              CLEAR ALL
            </ClearButton>
          ) : null}
        </div>
        <UploadFilesButton />
      </Stack>

      <ListTable
        key="table"
        sx={{
          borderRadius: 0,
          borderBottom: "none",
          boxShadow: "none",
        }}
        loading={loading}
        maxHeight={296}
        loadingRowsCount={3}
        onRowClick={rowClickHandler}
        columns={columns(
          calculatedFilters.orderFilter,
          calculatedFilters.dateFilter,
          filters,
          filtersChangeHandler
        )}
        data={filteredFiles.slice(startIndex, endIndex)}
        pagination={
          filteredFiles.length > 4
            ? {
              onChange: setPage,
              page,
              itemsCountParams: {
                itemsCount: ITEMS_PER_PAGE,
                initItemsCount: [ITEMS_PER_PAGE],
                onChange: () => { },
              },
              totalCount: filteredFiles.length,
            }
            : undefined
        }
      />
    </Box>
  );
};
