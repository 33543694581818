import React, { useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/caseCreate";
import { Colors } from "consts/colors";
import { createFileForStore } from "utils/fileHelpers";

import { OptionItem } from "components/ui/SelectTagFields/types";
import { ArchesStep } from "./ArchesStep";
import { ComplicationsArch } from "./ComplicationsArch";
import { ExtractionsArch } from "./ExtractionsArch";
import { PreferenceSelectors } from "./PreferenceSelectors";
import { CaseBudget } from "./CaseBubget";
import { PhotoIncludeExcludeContainer } from "containers";
import { PlainCheckbox } from "components/ui";

export const RXStep = () => {
  const clinicalPhotos = useAppSelector(selectors.getClinicalPhotosSelector);
  const arches = useAppSelector(selectors.getArchesSelector);
  const order = useAppSelector(selectors.getAllInfoSelector);
  const selectedOffice = useAppSelector(selectors.getStaffsOfficeSelector);
  const { includes, excludes } = useAppSelector(selectors.getTagsSelector);
  const photoBlock = useRef<HTMLElement | null>(null);
  const selectBlock = useRef<HTMLElement | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actions.getStaffOfficeByUserId());
  }, []);

  const handleSendScansViaMeditLink = (value: boolean) => {
    dispatch(actions.setSendScansViaMeditLink(value));
  };

  const filesUploadHandler = (files: File[]) => {
    dispatch(actions.setClinicalPhotos(files.map(createFileForStore)));
  };
  const filesDeleteHandler = (name: string) => {
    dispatch(actions.deleteClinicalPhoto(name));
  };
  const changeIncludesHandler = (options: OptionItem[]) => {
    dispatch(actions.setIncludeTags(options));
  };
  const changeExcludesHandler = (options: OptionItem[]) => {
    dispatch(actions.setExcludeTags(options));
  };

  const uploadFiles =
    (fileType: "clinicalPhotos" | "otherFiles") =>
    (params: {
      fileId: string;
      controller: AbortController;
      contentType: string;
    }) => {
      dispatch(
        actions.downloadFile({
          ...params,
          fileType,
        })
      );
    };

  useEffect(() => {
    if (
      order.requiredFields.includes("includes") ||
      order.requiredFields.includes("clinicalPhotos")
    ) {
      photoBlock.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (
      order.requiredFields.includes("ipr") ||
      order.requiredFields.includes("attachments")
    ) {
      selectBlock.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [order.requiredFields]);

  return (
    <>
      <ArchesStep />
      <Typography variant="h2" mt={5} color={Colors.SECONDARY}>
        Provide RX information
      </Typography>
      <Box mt={2} ref={photoBlock}>
        {selectedOffice?.useMeditLink && (
          <Box pl={1.2} pb={1}>
            <PlainCheckbox
              value={order.useMeditLink}
              onChange={handleSendScansViaMeditLink}
              label="Would you like to submit scans via MeditLink?"
            />
          </Box>
        )}
        <PhotoIncludeExcludeContainer
          useMeditLink={order.useMeditLink}
          clinicalPhotos={clinicalPhotos}
          filesUploadHandler={filesUploadHandler}
          uploadFiles={uploadFiles("clinicalPhotos")}
          filesDeleteHandler={filesDeleteHandler}
          error={{
            fileUpload: order.requiredFields.includes("clinicalPhotos"),
            includes: order.requiredFields.includes("includes"),
          }}
          helperText={{
            fileUpload: order.requiredFields.includes("clinicalPhotos")
              ? "Please fill this field"
              : undefined,
            includes: order.requiredFields.includes("includes")
              ? "Please fill this field"
              : undefined,
          }}
          includes={includes}
          excludes={excludes}
          required={{ include: !order.preEval }}
          changeIncludesHandler={changeIncludesHandler}
          changeExcludesHandler={changeExcludesHandler}
        />
      </Box>
      <Box mt={2} ref={selectBlock}>
        <PreferenceSelectors />
      </Box>
      {selectedOffice?.typeOfPriceId === 0 && (
        <Box mt={2}>
          <CaseBudget />
        </Box>
      )}

      {(arches.upper || arches.lower) && (
        <Stack
          direction="row"
          spacing={13}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ mt: 10 }}
        >
          <ComplicationsArch />
          <ExtractionsArch />
        </Stack>
      )}
    </>
  );
};
