import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/** 
 * @warn It should be used in SnackbarProvider's and RootRouter's context
 * 
 * Needs to:
 * 1. Clear notifications on route change
 * 2. ....
*/
export const useSnackbarHelper = () => {
  const { closeSnackbar } = useSnackbar();
  const location = useLocation();

  useEffect(() => {
    closeSnackbar()
  }, [closeSnackbar, location])
}