import { Grid } from "@mui/material";
import MuiTable from "@mui/material/Table";

import { Header } from "./Header";
import { Row } from "./Row";
import { ListTableProps } from "./types";
import { Pagination } from "./Pagination";
import { PaginationContainer, Container } from "./Elements";

export const Table = <T,>({
  columns,
  data,
  onRowClick,
  pagination,
  maxHeight,
  loadingRowsCount,
  sx,
  loading = false,
  emptyDataText,
}: ListTableProps<T>): JSX.Element => {
  return (
    <Grid container>
      <Container sx={{ ...sx, maxHeight }}>
        <MuiTable stickyHeader>
          <Header columns={columns} />
          <Row
            emptyDataText={emptyDataText}
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            loading={loading}
            itemsCount={
              loadingRowsCount || pagination?.itemsCountParams?.itemsCount || 8
            }
          />
        </MuiTable>
      </Container>
      {pagination && data?.length !== 0 && (
        <PaginationContainer container>
          <Pagination {...pagination} />
        </PaginationContainer>
      )}
    </Grid>
  );
};
