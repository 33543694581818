import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { useAppSelector } from "hooks/redux";
import { selectors } from "store/search";

import { ResulContainer } from "./Elements";
import { EntityGroup } from "./EntityGroup";
import { ParsedObject } from "./types";

const ResultPanel = () => {
  const pathMatching = window.location.pathname.split("/");
  const parsedObject: ParsedObject = useAppSelector(
    selectors.parsedObjectSelect
  );
  const clickStopPropogation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  return (
    <ResulContainer onClick={clickStopPropogation}>
      <Typography variant="subtitle2" color="textSecondary" mb={2} ml={3}>
        Search result
      </Typography>
      <div>
        {Object.entries(parsedObject)
          .filter(([_, value]) => value.totalCount)
          .map(([key, value], index, arr) => (
            <React.Fragment key={key}>
              <EntityGroup
                expanded={pathMatching.includes(key)}
                data={value.data}
                entityName={key as any}
              />
              {index !== arr.length - 1 && <Divider />}
            </React.Fragment>
          ))}
      </div>
    </ResulContainer>
  );
};

export default ResultPanel;
