import { IBaseResponse, ISearchAllResponseModel } from "lib/model";
import api from "./baseService";

class CommonService {
  public async getSearchResults(
    searchValue: string
  ): Promise<ISearchAllResponseModel> {
    const { data } = await api.get<IBaseResponse<ISearchAllResponseModel>>(
      `search?pageNumber=0&itemsCount=10000&search-for=${searchValue}`
    );
    return data.result;
  }
}

export const commonService = new CommonService();
