import React from "react";
import Close from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { Button } from "components/ui";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/treatmentPlan";

import LegendItem from "./LegendItem";
import Rotation from "./Rotation";
import Torque from "./Torque";
import Extrusion from "./Extrusion";
import Intrusion from "./Intrusion";
import IPR from "./IPR";
import FBM from "./FBM";
import Attachment from "./Attachment";

import clsx from "clsx";
import "./styles.css";

export const Legend: React.VFC = () => {
  const dispatch = useAppDispatch();
  const showLegend = useAppSelector(selectors.showLegend);
  const closeHandler = () => dispatch(actions.toggleLegend(false));
  return (
    <div
      className={clsx("legend__expand", showLegend && "legend__expand_opened")}
    >
      <Collapse in={showLegend} timeout="auto" unmountOnExit>
        <div className="legend">
          <LegendItem title="Rotation" subTitles={["M-mesial", "D-distal"]}>
            <Rotation />
          </LegendItem>
          <LegendItem
            title="Torque"
            subTitles={["M-mesial", "D-distal", "B-bucctal", "P-palatal"]}
          >
            <Torque />
          </LegendItem>
          <LegendItem title="Extrusion">
            <Extrusion />
          </LegendItem>
          <LegendItem title="Intrusion">
            <Intrusion />
          </LegendItem>
          <LegendItem title="FBM">
            <FBM />
          </LegendItem>
          <LegendItem title="IPR" subTitles={["M-mesial", "D-distal"]}>
            <IPR />
          </LegendItem>
          <LegendItem
            title="Attachment"
            subTitles={["STD-standard", "APC-apical"]}
          >
            <Attachment />
          </LegendItem>
          <Button className="legend__close-button" onClick={closeHandler}>
            <Close color="disabled" fontSize="inherit" />
          </Button>
        </div>
      </Collapse>
    </div>
  );
};
