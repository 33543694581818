import { AnyAction, AsyncThunk, createReducer } from "@reduxjs/toolkit";
import { generateUniqueID } from "utils/idHelper";
import { CreateOrderState, ProductionType } from "./types";
import { initState } from "./state";
import * as actions from "./actions";
import {
  ArchCompleteStatus,
  CaseStatuses,
  OrderItemType,
} from "../../lib/enum";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return (
    action.type.startsWith("order") && action.type.endsWith("/fetch/rejected")
  );
}

function isPendingAction(action: AnyAction): action is PendingAction {
  return (
    action.type.startsWith("order") && action.type.endsWith("/fetch/pending")
  );
}

function isRejectedActionSend(action: AnyAction): action is RejectedAction {
  return (
    action.type.startsWith("order") && action.type.endsWith("/send/rejected")
  );
}

function isPendingActionSend(action: AnyAction): action is PendingAction {
  return (
    action.type.startsWith("order") && action.type.endsWith("/send/pending")
  );
}

export const reducer = createReducer<CreateOrderState>(initState, (builder) => {
  builder
    .addCase(actions.setFiles, (state, action) => {
      const clinicalPhotoNames = state[action.payload.type].map(
        ({ name }) => name
      );
      state[action.payload.type] = [
        ...state[action.payload.type],
        ...action.payload.files
          .filter(({ name }) => !clinicalPhotoNames.includes(name))
          .map((file) => ({
            id: generateUniqueID(),
            ...file,
          })),
      ];
    })
    .addCase(actions.setSendScansViaMeditLink, (state, action) => {
      state.useMeditLink = action.payload;
    })
    .addCase(actions.setRemoveAttachment, (state, action) => {
      state.removeAttachment = action.payload;
    })
    .addCase(actions.deleteFile, (state, action) => {
      state[action.payload.type] = state[action.payload.type].filter(
        ({ name }) => name !== action.payload.fileName
      );
    })
    .addCase(actions.toggleArch, (state, action) => {
      if (!state[action.payload]) {
        state.requiredFields = state.requiredFields.filter(
          (item) => item !== "arch"
        );
      }
      state[action.payload] = !state[action.payload];
    })
    .addCase(actions.setNotes, (state, action) => {
      state.orderNote = action.payload;
    })
    .addCase(actions.setRetainers, (state, action) => {
      state.productionType = action.payload;
    })
    .addCase(actions.setShowVerification, (state, action) => {
      state.showVerification = action.payload;
    })
    .addCase(actions.setOrderItem, (state, action) => {
      state.orderItems = {
        ...state.orderItems,
        ...action.payload,
      };
    })
    .addCase(actions.setStepsType, (state, action) => {
      state.stepsType = action.payload;
      state.customStepsNum = isNaN(Number(action.payload))
        ? 1
        : Number(action.payload);
    })
    .addCase(actions.setCustomSteps, (state, action) => {
      state.customStepsNum =
        !isNaN(Number(action.payload)) && Number(action.payload) < 1
          ? 1
          : Number(action.payload);
    })
    .addCase(actions.setRequestedRetainers, (state, action) => {
      state.requestedRetainers =
        !isNaN(Number(action.payload)) && Number(action.payload) < 1
          ? 0
          : Number(action.payload);
      state.requiredFields = state.requiredFields.filter(
        (item) => item !== "requestedRetainers"
      );
    })
    .addCase(actions.setRequired, (state) => {
      const reqFields = [...state.requiredFields];
      if (!state.upperArch && !state.lowerArch && !reqFields.includes("arch")) {
        reqFields.push("arch");
      }
      if (
        state.productionType === ProductionType.RETAINER &&
        !state.requestedRetainers &&
        !reqFields.includes("requestedRetainers")
      ) {
        reqFields.push("requestedRetainers");
      }
      state.requiredFields = [...reqFields];
    })
    .addCase(actions.setReason, (state, action) => {
      state.reason = action.payload;
    })
    .addCase(actions.clearState, () => {
      return initState;
    })
    .addCase(actions.downloadFile.fulfilled, (state, action) => {
      const fileIdx = state[action.payload.fileType].findIndex(
        ({ id }) => id === action.payload.fileId
      );
      if (fileIdx !== -1) {
        state[action.payload.fileType][fileIdx].src = action.payload.src;
      }
    })
    .addCase(actions.fetchOrderInfo.fulfilled, (state, action) => {
      state.previousOrder = action.payload.orderInfo;
      state.caseInfo = action.payload.caseInfo;
      state.lowerArch = action.payload.caseInfo.lowerArch;
      state.upperArch = action.payload.caseInfo.upperArch;
      state.orderId = action.payload.orderInfo.id;
      state.orderNote = action.payload.orderInfo.note!;

      state.productionType =
        action.payload.orderInfo.alignersMaxQty &&
        action.payload.orderInfo.requestedRetainers
          ? ProductionType.BOTH
          : action.payload.orderInfo.alignersMaxQty
          ? ProductionType.ALIGNER
          : ProductionType.RETAINER;

      state.requestedRetainers = action.payload.orderInfo.requestedRetainers;
      state.orderItems = action.payload.orderInfo.orderItems.reduce(
        (previousValue, currentValue) => {
          previousValue[currentValue.itemType] = currentValue.qty;
          return previousValue;
        },
        {} as Record<OrderItemType, number>
      );
      state.stepsType = !["3", "5", "10"].includes(
        action.payload.orderInfo.alignersMaxQty!.toString()
      )
        ? "custom"
        : action.payload.orderInfo.alignersMaxQty!.toString();
      state.customStepsNum = Number(action.payload.orderInfo.alignersMaxQty);
      state.commonFiles = action.payload.commonFiles;
      state.verificationKeys = action.payload.verificationKeys;
      state.clinicalPhotos = action.payload.clinicalPhotos;
      state.isLoading = false;
    })
    .addCase(actions.fetchCaseOrderInfo.fulfilled, (state, action) => {
      state.previousOrder = action.payload.orderInfo;
      state.caseInfo = action.payload.caseInfo;
      state.lowerArch =
        action.payload.caseInfo.archCompleteStatus ===
        ArchCompleteStatus.LOWER_COMPLETED
          ? false
          : action.payload.caseInfo.lowerArch;
      state.upperArch =
        action.payload.caseInfo.archCompleteStatus ===
        ArchCompleteStatus.UPPER_COMPLETED
          ? false
          : action.payload.caseInfo.upperArch;
      state.showVerification = Boolean(
        (action.payload.orderInfo.lowerVerificationKeyQty ||
          action.payload.orderInfo.upperVerificationKeyQty) &&
          !action.payload.orderInfo?.verified
      );

      if (action.payload.caseInfo.status === CaseStatuses.COMPLETED) {
        state.productionType = ProductionType.RETAINER;
      }

      if (action.payload.preferences.ADDITIONAL_INFO) {
        state.orderNote = action.payload.preferences.ADDITIONAL_INFO.value;
      }
      if (
        action.payload.preferences.STEPS_NUMBER &&
        action.payload.preferences.STEPS_NUMBER.value
      ) {
        state.stepsType = !["3", "5", "10"].includes(
          action.payload.preferences.STEPS_NUMBER.value
        )
          ? "custom"
          : action.payload.preferences.STEPS_NUMBER.value;
        state.customStepsNum = Number(
          action.payload.preferences.STEPS_NUMBER.value
        );
      }

      state.isLoading = false;
    })
    .addCase(actions.createOrder.fulfilled, (state, action) => {
      state.isSaving = false;
      state.orderId = action.payload.orderId;
      state.clinicalPhotos = state.clinicalPhotos.map((file) => ({
        ...file,
        unDeletable: true,
      }));
      state.commonFiles = state.commonFiles.map((file) => ({
        ...file,
        unDeletable: true,
      }));
      state.verificationKeys = state.verificationKeys.map((file) => ({
        ...file,
        unDeletable: true,
      }));
    })
    .addCase(actions.updateOrder.fulfilled, (state) => {
      state.isSaving = false;

      state.commonFiles = state.commonFiles.map((file) => ({
        ...file,
        unDeletable: true,
      }));
      state.clinicalPhotos = state.clinicalPhotos.map((file) => ({
        ...file,
        unDeletable: true,
      }));
      state.verificationKeys = state.verificationKeys.map((file) => ({
        ...file,
        unDeletable: true,
      }));
    })
    .addCase(actions.createShippingForOrder.fulfilled, (state, action) => {
      state.isSaving = false;
      state.shippingLabel = action.payload;
    })
    .addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    })
    .addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    })
    .addMatcher(isRejectedActionSend, (state) => {
      state.isSaving = false;
    })
    .addMatcher(isPendingActionSend, (state) => {
      state.isSaving = true;
    });
});
