import React, { useEffect } from "react";
import { MainContainer } from "containers";
import { Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/accountSettings";
import { useStyledSnackbar } from "hooks/notification";
import { analyticsMethod } from "plugins/analytics";

import {
  OfficeSettingsCard,
  ProfileSettingsCard,
  ButtonsContainer,
  CardsContainer,
  MainInfo,
} from "./components";
import { useNavigate } from "react-router-dom";

const AccountSettingsPage = () => {
  analyticsMethod(
    "page",
    "Account settings",
    {
      title: "Edit form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showErrorNotice } = useStyledSnackbar();
  const { isSaving } = useAppSelector(selectors.getAllInfoSelector);
  const closeHandler = () => {
    navigate(-1);
  };
  const submitHandler = () => {
    dispatch(actions.saveChanges())
      .unwrap()
      .catch((e) => showErrorNotice(e.message));
  };
  useEffect(() => {
    dispatch(actions.fetchAccountSettings())
      .unwrap()
      .catch((e) => {
        showErrorNotice(e.message);
      });
  }, [dispatch, showErrorNotice]);
  return (
    <MainContainer>
      <MainInfo />
      <CardsContainer spacing={3}>
        <ProfileSettingsCard />
        <OfficeSettingsCard />
      </CardsContainer>
      <ButtonsContainer direction="row" justifyContent="space-between">
        <Button onClick={closeHandler} fatButton variant="outlined">
          Close
        </Button>
        <Button
          onClick={submitHandler}
          loading={isSaving}
          fatButton
          variant="contained"
        >
          Save
        </Button>
      </ButtonsContainer>
    </MainContainer>
  );
};

export default AccountSettingsPage;
