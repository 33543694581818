import { createReducer } from "@reduxjs/toolkit";
import { ShippingLevel } from "lib/types";

import { CreateShippingState } from "./types";
import { initState } from "./initState";
import * as actions from "./actions";

export const reducer = createReducer<CreateShippingState>(
  initState,
  (builder) => {
    builder
      .addCase(actions.fetchShippingInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.lab) {
          state.lab = action.payload.lab;
        }
        if (action.payload.office) {
          state.office = action.payload.office;
        }
        state.fastest = {
          upsService: action.payload.shippingRate?.fastestServiceCode || "",
          packageType: action.payload.shippingRate?.fastestPackagingCode || "",
        };
        state.cheapest = {
          upsService: action.payload.shippingRate?.cheapestServiceCode || "",
          packageType: action.payload.shippingRate?.cheapestPackagingCode || "",
        };
        if (action.payload.levelPriority) {
          state.shippingInfo = {
            ...state.shippingInfo,
            ...state[action.payload.levelPriority as ShippingLevel],
          };
        }
      })
      .addCase(actions.printShippingLabel.fulfilled, () => {})
      .addCase(actions.fetchShippingInfo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(actions.setShippingType, (state, action) => {
        state.shippingType = action.payload;
      })
      .addCase(actions.setShippingLevel, (state, action) => {
        state.shippingLevel = action.payload;
        state.shippingInfo.upsService = state[action.payload].upsService;
        state.shippingInfo.packageType = state[action.payload].packageType;
      })
      .addCase(actions.setShippingNotes, (state, action) => {
        state.notes = action.payload;
      });
  }
);
