import React, { useEffect } from "react";
import { useAppSelector } from "hooks/redux";
import { useConsentWindow } from "hooks/consentWindow";

import { analytics } from "plugins";

export const IdentifyUser: React.FC = ({ children }) => {
  const currentUser = useAppSelector((state) => state.currentUser.currentUser);
  const consentConfirm = useConsentWindow();
  analytics.analytics();
  useEffect(() => {
    if (currentUser) {
      analytics.analyticsMethod(
        "identify",
        analytics.getAnalyticsUserId() || currentUser.userId,
        {
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          email: currentUser.email,
          phone: currentUser.phone,
        }
      );
      setTimeout(() => {
        analytics.smartlookSetConsent(consentConfirm);
      }, 500);
    }
  }, [currentUser, consentConfirm]);
  return <>{children}</>;
};
