import MuiPhoneNumber, {
  type MuiPhoneNumberProps,
} from 'material-ui-phone-number';
import { styled } from "@mui/material";
import { Colors } from 'consts/colors';

type PhoneFieldProps = Omit<MuiPhoneNumberProps, 'onChange'> & {
  onChange: (value: string) => void;
}

const StyledMuiPhoneNumber = styled(MuiPhoneNumber)`
  & .MuiInputBase-input {
    padding: 8.5px 14px;
    border-left: 1px ${Colors.DISABLED} solid;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.SECONDARY};
  }
  & .MuiFormHelperText-root {
    font-size: 12px;
    margin: 0;
  }
`


export const PhoneField: React.FC<PhoneFieldProps> = ({
  disableAreaCodes = true,
  defaultCountry = 'us',
  variant = "outlined",
  onChange,
  ...props
}) => {
  return (
    <StyledMuiPhoneNumber
      onChange={(value) => onChange(value as string)}
      variant={variant}
      disableAreaCodes={disableAreaCodes}
      defaultCountry={defaultCountry}
      {...props}
    />
  )
}