import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAppSelector } from "hooks/redux";
import { Colors } from "consts/colors";
import { Button } from "components/ui";
import { dateToString } from "utils";

import { MessageInfoItem } from "./MessageInfoItem";
import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledTextArea,
  StyledDialogActions,
} from "./Elements";
import { MessageDialogProps } from "./types";
import { MessageFilesAddButton } from "./MessageFilesAddButton";
import { MessagesFileCard } from "./MessagesFileCard";
import { TextWrapper } from "./TextWrapper";

export const ReplyMessageDialog: React.VFC<MessageDialogProps> = ({
  open,
  message,
  onClose,
  onSubmit,
}) => {
  const userId = useAppSelector(
    (state) => state.currentUser.currentUser!.userId
  );
  const [newMessage, setNewMessage] = useState<{
    text: string;
    files: File[];
  }>({
    text: "",
    files: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const assignee = message
    ? `${message.author.firstName} ${message.author.lastName}`
    : "";

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage((oldStateMessge) => ({
      ...oldStateMessge,
      text: event.target.value,
    }));
  };
  const changeFilesHandler = (messageFiles: File[]) => {
    setNewMessage(({ text }) => ({
      text,
      files: messageFiles,
    }));
  };
  const closeHandler = () => {
    onClose();
    setNewMessage({
      text: "",
      files: [],
    });
  };
  const submitHandler = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    onSubmit({
      orderId: message!.orderId,
      text: newMessage.text,
      files: newMessage.files.length > 0 ? newMessage.files : undefined,
      isFilesAttached: newMessage.files.length > 0,
      replyFor: message!.id,
      subject: `Reply: ${message!.subject || ""}`,
      authorId: userId,
    })
      .then(() => {
        setLoading(false);
        closeHandler();
      })
      .catch(() => setLoading(false));
  };
  return (
    <Dialog
      PaperProps={{ sx: { height: "calc(100% - 188px)" } }}
      fullWidth
      maxWidth="md"
      open={open}
    >
      <StyledDialogTitle>
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Box mb={4}>
          <Typography fontWeight={500} variant="body1" color={Colors.PRIMARY}>
            {message?.subject || ""}
          </Typography>
        </Box>
        <Stack spacing={2}>
          <MessageInfoItem title="Author" text={assignee} />
          <MessageInfoItem
            title="Date"
            text={message?.created ? dateToString(message?.created) : ""}
          />
          <MessageInfoItem
            title="Order ID"
            text={message?.orderId.toString() || ""}
          />
        </Stack>
        <Stack mt={2} direction="row" spacing={1}>
          {message?.files.map((file) => (
            <MessagesFileCard
              key={file.id}
              messageId={message?.id}
              file={{
                ...file,
                orderId: message!.orderId,
                caseId: null,
                orderMessageId: message?.id,
              }}
            />
          ))}
        </Stack>
        <Box mt={3} flexGrow={1}>
          <Typography variant="body2" whiteSpace="pre-wrap">
            <TextWrapper>{message?.text || ""}</TextWrapper>
          </Typography>
        </Box>
        <StyledTextArea
          onChange={changeHandler}
          multiline
          variant="filled"
          fullWidth
          rows={6}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <MessageFilesAddButton
          onUpload={changeFilesHandler}
          files={newMessage.files}
        />
        <Button
          onClick={submitHandler}
          disabled={!newMessage.text || !message}
          variant="contained"
          fatButton
          loading={loading}
        >
          Publish
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};
