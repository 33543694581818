export enum CaseTabAction {
  PLACE_A_CONTINUATION_ORDER = "PLACE_A_CONTINUATION_ORDER",
  SEND_IMPRESSIONS = "SEND_IMPRESSIONS",
  SUBMIT_PHOTO_EVALUATIONS = "SUBMIT_PHOTO_EVALUATIONS",
  IN_TREATMENT = "IN_TREATMENT",
  APPROVE_TREATMENT_PLAN = "APPROVE_TREATMENT_PLAN",
  NOTHING_REQUIRED = "NOTHING_REQUIRED",
  SUBMIT_TO_LAB = "SUBMIT_TO_LAB",
  SCHEDULE_CONSULT = "SCHEDULE_CONSULT",
  COMPLETE_ORDER = "COMPLETE_ORDER",
  COMPLETE_A_CONTINUATION_ORDER = "COMPLETE_A_CONTINUATION_ORDER",
}
