import { FileTypes } from "lib/types";
import { IUploadedFile } from "../lib/model";
import { URLStored } from "./URLStored";

export const getFileExtension = (file: {
  name: string;
  contentType?: string;
}): string => {
  if (file.contentType && file?.contentType.split("/")[1].length < 5) {
    return file?.contentType.split("/")[1];
  }
  const exts = file.name?.split(".");
  if (exts && exts.length > 1) {
    return exts[exts.length - 1];
  }
  return "";
};

export const fileDownload = (name: string, url: string) => {
  let a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
  a.remove();
};

export const getFilesType = (file: any): FileTypes => {
  if (
    (file.file && file.file.type.indexOf("image") !== -1) ||
    (file.type && file.type.indexOf("image") !== -1) ||
    (file.contentType && file.contentType.indexOf("image") !== -1)
  ) {
    return "image";
  }
  if (
    (file.file && file.file.type.indexOf("video") !== -1) ||
    (file.type && file.type.indexOf("video") !== -1) ||
    (file.contentType && file.contentType.indexOf("video") !== -1) ||
    (file.contentType && file.contentType.indexOf("video") !== -1)
  ) {
    return "video";
  }
  if (
    (file.file && file.file.type.indexOf("model") !== -1) ||
    (file.type && file.type.indexOf("model") !== -1) ||
    (file.contentType && file.contentType.indexOf("model") !== -1) ||
    (file.name && file.name.indexOf(".stl") !== -1)
  ) {
    return "model";
  }
  if (
    (file.file && file.file.type.indexOf("pdf") !== -1) ||
    (file.type && file.type.indexOf("pdf") !== -1) ||
    (file.contentType && file.contentType.indexOf("pdf") !== -1)
  ) {
    return "pdf";
  }
  if (
    (file.file && file.file.type.indexOf("application") !== -1) ||
    (file.type && file.type.indexOf("application") !== -1) ||
    (file.contentType && file.contentType.indexOf("application") !== -1)
  ) {
    return "application";
  }
  if (
    (file.file && file.file.type.indexOf("text") !== -1) ||
    (file.type && file.type.indexOf("text") !== -1) ||
    (file.contentType && file.contentType.indexOf("text") !== -1)
  ) {
    return "text";
  }
  return "other";
};

export const createFileForStore = (file: File): Omit<IUploadedFile, "id"> => ({
  name: file.name,
  contentType: file.type,
  src: URLStored.createObjectURL(file),
});
