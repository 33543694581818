import React from "react";
import { ISelect } from "./types";
import { AutocompleteSelect } from "./AutocompleteSelect";
import { DefaultSelect } from "./DefaultSelect";

export const Select = <T extends number | string>({
  data,
  ...props
}: ISelect<T>) => {
  if (data.length > 10) {
    return <AutocompleteSelect data={data} {...props} />;
  }

  return <DefaultSelect data={data} {...props} />;
};
