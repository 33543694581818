import React from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";

import { Header, Balance } from "components/common";
import { OfficeSelector } from "./OfficeSelector";
import { OutstandingBalance } from "./OutstandingBalance";

interface Props {
  onChangeOffice: (officeId: string) => void;
  office: string;
}

const Container = styled(Stack)`
  padding-inline: 44px 44px;
  margin-bottom: 32px;
`;
export const HeaderBlock: React.FC<Props> = ({ office, onChangeOffice }) => {
  return (
    <Stack>
      <Header title="Invoices" />
      <Container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={4}
      >
        <OfficeSelector office={office} onChangeOffice={onChangeOffice} />
        <Balance officeId={office} />
        <OutstandingBalance officeId={office} />
      </Container>
    </Stack>
  );
};
