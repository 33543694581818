export enum TpInstructions {
  rotation = "rotation",
  distal = "distal",
  mesial = "mesial",
  torque = "torque",
  fullBodyMovement = "fullBodyMovement",
  attachment = "attachment",
  intrusion = "intrusion",
  extrusion = "extrusion",
}
