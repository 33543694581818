import { PlainCheckbox } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/continuationOrderCreate";

export const MeditLink = () => {
  const order = useAppSelector(selectors.getAllInfoSelector);
  const dispatch = useAppDispatch();

  const handleSendScansViaMeditLink = (value: boolean) => {
    dispatch(actions.setSendScansViaMeditLink(value));
  }

  return (
    <PlainCheckbox
      value={order.useMeditLink}
      onChange={handleSendScansViaMeditLink}
      label="Would you like to submit scans via MeditLink?"
    />
  )
}