import { useState, useEffect } from "react";
import clsx from "clsx";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import {
  ListTableProps,
  ListTableColumn,
  HeaderSorting,
  SortingType,
} from "./types";
import { HeaderContainer, HeaderCell, HeaderArrowIcon } from "./Elements";
import { FilterHead } from "./FilterHead";

export const Header = <T,>({
  columns,
}: Pick<ListTableProps<T>, "columns">): JSX.Element => {
  const [sorting, setSorting] = useState<HeaderSorting<T>>({});
  useEffect(() => {
    setSorting(getSort());
  }, [columns]);
  const getSort = (reset: boolean = false) => {
    let localSorting = {};
    columns.forEach((column) => {
      if (typeof column?.sort === "function") {
        const key = column.dataIndex;
        localSorting = {
          ...localSorting,
          [key]: reset ? null : sorting[key] ?? null,
        };
      }
    });
    return localSorting;
  };
  const handleSort = (key: keyof T, sort: Function) => {
    let res: SortingType = null;
    switch (sorting[key]) {
      case "ASC":
        res = "DESC";
        break;
      case "DESC":
        res = null;
        break;
      default:
        res = "ASC";
    }
    setSorting({
      ...getSort(true),
      [key]: res,
    });
    sort(res);
  };
  return (
    <HeaderContainer>
      <TableRow>
        {columns.map(
          ({
            width,
            style,
            title = "",
            dataIndex,
            sort,
            filter,
            ...props
          }: ListTableColumn<T>) => {
            const key = dataIndex;
            const isSort = typeof sort === "function";
            return (
              <HeaderCell
                width={width}
                data-intercom-target={props.targetLabel}
                key={props.key ?? key.toString()}
                style={{ ...style?.headerCell }}
                className={clsx({
                  cursor: isSort,
                  active: typeof sorting[key] === "string",
                })}
                onClick={isSort ? () => handleSort(key, sort) : undefined}
              >
                <Stack direction="row" alignItems="center">
                  <FilterHead title={title} filter={filter} />
                  {isSort && (
                    <span>
                      <HeaderArrowIcon
                        className={clsx({
                          asc: sorting[key] === "ASC",
                          showHover: sorting[key] === null,
                        })}
                        viewBox="0 0 14 14"
                      />
                    </span>
                  )}
                </Stack>
              </HeaderCell>
            );
          }
        )}
      </TableRow>
    </HeaderContainer>
  );
};
