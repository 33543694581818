import React, { Component, ReactNode } from "react";
import { ErrorIndicator } from "../common";

interface IErrorBoundry {
  children: ReactNode;
}
export class ErrorBoundry extends Component<IErrorBoundry> {
  state = {
    hasError: false,
  };

  componentDidCatch = (error: Error) => {
    this.setState({
      hasError: true,
    });
  };

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorIndicator />;
    }
    return this.props.children;
  }
}
