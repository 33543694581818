import { AttachmentType } from "../enum";

export const attachmentType: Record<AttachmentType, string> = {
  [AttachmentType.APICAL_EMBED]: "APC-E",
  [AttachmentType.APICAL_PRODUCE]: "APC-P",
  [AttachmentType.STANDARD_EMBED]: "STD-E",
  [AttachmentType.STANDARD_PRODUCE]: "STD-P",
  [AttachmentType.UE_EMBED]: "UE-E",
  [AttachmentType.UE_PRODUCE]: "UE-P",
  [AttachmentType.APICAL]: "APC",
  [AttachmentType.STANDARD]: "STD",
  [AttachmentType.UE]: "UE",
};
