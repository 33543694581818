import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { InfoWithLabel } from "components/common";
import { toLocalCurrency } from "utils";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/currentUser";
import { IExtOfficeDtoWithOrg } from "lib/dto";
interface Props {
  officeId?: string | number;
}
export const Balance: React.FC<Props> = ({ officeId }) => {
  const officesBalance = useAppSelector(selectors.getUserBalances);
  const offices = useAppSelector(selectors.selectUserOffices);

  const currentOffice: IExtOfficeDtoWithOrg | undefined = offices.find(
    ({ id }) => id.toString() === officeId
  );

  return (
    <Box flexGrow={1}>
      <Stack direction="row" spacing={4}>
        <InfoWithLabel
          direction="column"
          label="CURRENT BALANCE"
          text={toLocalCurrency(
            currentOffice
              ? Number(currentOffice.balance)
              : officesBalance.currentBalance
          )}
        />

        <InfoWithLabel
          direction="column"
          label="DEBIT BALANCE"
          text={toLocalCurrency(
            currentOffice
              ? Number(currentOffice.debitBalance)
              : officesBalance.debitBalance
          )}
        />

        <InfoWithLabel
          direction="column"
          label="PROMO BALANCE"
          text={toLocalCurrency(
            currentOffice
              ? Number(currentOffice.promo)
              : officesBalance.promoBalance
          )}
        />
      </Stack>
    </Box>
  );
};
