import React from "react";
import { IMarkOutToothDto } from "lib/dto";
import FbmCell from "./FbmCell";

export const fbmCellsCreate =
  (markOutToothList?: IMarkOutToothDto[]) =>
  (incrementIndex: number): React.ReactElement[] => {
    return Array(8)
      .fill("")
      .map((_, index) => {
        const calculatedToothNumber = Math.abs(index + incrementIndex);
        const toothRotation: IMarkOutToothDto | undefined =
          markOutToothList?.find(
            ({ toothNumber }) => toothNumber === calculatedToothNumber + 1
          );
        return (
          <FbmCell
            toothRotation={toothRotation}
            key={`toothFBM_${calculatedToothNumber}`}
          />
        );
      });
  };
