import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const LowerComplete = ({ sx, ...props }: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 19" fill="none" {...props} sx={{ fill: "none" }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3333 10.3333C12.0733 10.3333 12.6667 9.74001 12.6667 9.00001C13.4067 9.00001 14 8.40668 14 7.66668V5.66668C14 4.92668 13.4067 4.33334 12.6667 4.33334H3.33333C2.59333 4.33334 2 4.92668 2 5.66668L2.00667 7.66668C2.00667 8.40668 2.59333 9.00001 3.33333 9.00001H3.34C3.34 9.74001 3.92667 10.3333 4.66667 10.3333H11.3333ZM4.66667 8.33334C4.66667 8.66668 4.96667 9.00001 5.33333 9.00001H10.6667C11.0333 9.00001 11.3333 8.66668 11.3333 8.33334C11.3333 8.00001 11.6667 7.66668 12 7.66668C12.3333 7.66668 12.6667 7.36668 12.6667 7.00001V6.33334C12.6667 5.96668 12.3667 5.66668 12 5.66668H4C3.63333 5.66668 3.33333 5.96668 3.33333 6.33334V7.00001C3.33333 7.36668 3.66667 7.66668 4 7.66668C4.33333 7.66668 4.66667 8.00001 4.66667 8.33334ZM14 16.3333C14 17.0733 13.4067 17.6667 12.6667 17.6667H3.33333C2.59333 17.6667 2.00667 17.0733 2.00667 16.3333L2 14.3333C2 13.5933 2.59333 13 3.33333 13C3.33333 12.26 3.92667 11.6667 4.66667 11.6667H11.3333C12.0733 11.6667 12.6667 12.26 12.6667 13C13.4067 13 14 13.5933 14 14.3333V16.3333Z"
        fill="#888888"
      />
      <circle cx="13" cy="5" r="4.5" fill="#5CBD7B" stroke="white" />
      <path
        d="M16.33 3L12.6656 6.67L11 5.00182"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
