import {
  IExtTreatmentPlanStepDto,
  ITreatmentPlanVerificationKeyDto,
} from "lib/dto";
import { Nullable } from "lib/types";

const check = (value: any) => {
  return (
    value === null || value === false || value === undefined || value === 0
  );
};

export const getExtraStepObject = (
  step: IExtTreatmentPlanStepDto
): { types: string[]; orderId: number | string } => {
  const joinedTypes: string[] = [];
  if (step.matrix) {
    joinedTypes.push("Matrix");
  }
  if (step.origin) {
    joinedTypes.push("Origin Aligner");
  }
  if (step.retainerQty) {
    joinedTypes.push("Retainer");
  }
  return {
    types: joinedTypes,
    orderId: step.orderId,
  };
};

export const getFirstStepInstruction = (
  step: Nullable<IExtTreatmentPlanStepDto>,
  needImpressions: boolean,
  index: number
) => {
  const instr: string[] = [];
  if (needImpressions) {
    instr.push("NI");
  }

  if (!check(step?.origin) && index === 0) {
    instr.push("OA");
  }
  if (!check(step?.matrix) && index === 0) {
    instr.push("MX");
  }
  return instr;
};

export const getStepInstruction = (
  planSteps: Nullable<IExtTreatmentPlanStepDto>[],
  extraSteps: Record<number, IExtTreatmentPlanStepDto>,
  step: Nullable<IExtTreatmentPlanStepDto>,
  rowNumber: number,
  index: number,
  verificationKey?: {
    UPPER?: ITreatmentPlanVerificationKeyDto;
    LOWER?: ITreatmentPlanVerificationKeyDto;
  } | null
) => {
  const instr: string[] = [];

  if (!check(planSteps[index + 1]?.origin) || extraSteps[rowNumber]?.origin) {
    instr.push("OA");
  }
  if (!check(planSteps[index + 1]?.matrix) || extraSteps[rowNumber]?.matrix) {
    instr.push("MX");
  }
  if (verificationKey?.UPPER) {
    instr.push("VK");
  }
  if (!check(step?.retainerQty) || extraSteps[rowNumber]?.retainerQty) {
    instr.push("RT");
  }
  return instr;
};
