import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

import { Colors } from "consts/colors";
import { UploadedFileCard } from "components/common";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/accountSettings";

import { OfficeUploadLogo } from "./OfficeUploadLogo";

interface Props {
  officeId: number;
}

export const OfficeSettingsLogo: React.FC<Props> = ({ officeId }) => {
  const dispatch = useAppDispatch();
  const officeLogo = useAppSelector(selectors.getOfficeLogos);
  const logoFiles = officeLogo[officeId.toString()];

  const uploadLogo = (params: {
    fileId: string;
    controller: AbortController;
    contentType: string;
  }) => {
    dispatch(
      actions.downloadFile({
        ...params,
        officeId,
      })
    );
  };

  const deleteLogo = (fileId: string) => (name: string) => {
    dispatch(
      actions.deletedFile({
        fileId,
        name,
        officeId,
      })
    );
  };

  useEffect(() => {
    if (officeId) {
      dispatch(actions.fetchFiles(officeId));
    }
  }, [officeId, dispatch]);

  return (
    <>
      <Stack mt={2} spacing={1}>
        <Typography
          fontWeight={600}
          color={Colors.TEXT_PRIMARY}
          variant="body1"
        >
          Office logo settings
        </Typography>
        <Typography variant="body2" color={Colors.TEXT_PRIMARY}>
          {`If you would like to brand patient facing materials using your logo,
        please upload logo file. It must be either svg or png format with min
        resolution <512x512>p and max resolution <1512x1512>p`}
        </Typography>

        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          <OfficeUploadLogo officeId={officeId} />
          {logoFiles?.map((file) => (
            <UploadedFileCard
              key={file.id}
              file={file}
              onUpload={uploadLogo}
              onDelete={deleteLogo(file.id)}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
};
