import React, { Dispatch, ReducerAction } from "react";
import { invoiceService, orgService } from "services";
import { IExtOfficeDtoWithOrg } from "lib/dto";
import { IPaymentMethodModel } from "lib/model";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

interface State {
  loading: boolean;
  outstandingBalance: {
    [k: number]: number;
  };
  stripeSourceId: string;
  defaultCard: boolean;
  open: boolean;
  showMessage: boolean;
  paymentsMethods: IPaymentMethodModel[];
}
interface Action {
  type: string;
  payload?: Partial<State>;
}
type Reducer<S, A> = (prevState: S, action: A) => S;

const SET_STATE = "set_state";

export const initState: State = {
  loading: false,
  outstandingBalance: {},
  stripeSourceId: "",
  defaultCard: false,
  open: false,
  showMessage: false,
  paymentsMethods: [],
};

export const reducer = (state: State, { type, payload }: Action) => {
  switch (type) {
    case SET_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
type DispatchAction = Dispatch<ReducerAction<Reducer<State, Action>>>;

// actions
export const setLoading = (dispatch: DispatchAction) => (loading: boolean) => {
  dispatch({
    type: SET_STATE,
    payload: {
      loading,
    },
  });
};
export const showModal = (dispatch: DispatchAction) => () => {
  dispatch({
    type: SET_STATE,
    payload: {
      open: true,
    },
  });
};
export const closeModal = (dispatch: DispatchAction) => () => {
  dispatch({
    type: SET_STATE,
    payload: {
      open: false,
    },
  });
};
export const setDefaultAction = (value: string) => ({
  type: SET_STATE,
  payload: {
    stripeSourceId: value,
    defaultCard: true,
  },
});

export const changeAction =
  (dispatch: DispatchAction, defaultCardOffice: any) =>
  (event: SelectChangeEvent<string>) => {
    dispatch({
      type: SET_STATE,
      payload: {
        stripeSourceId: event.target.value,
        defaultCard:
          defaultCardOffice?.stripeDefaultSourceId === event.target.value,
      },
    });
  };

export const checkedChangeAction =
  (dispatch: DispatchAction) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: SET_STATE,
      payload: {
        defaultCard: event.target.checked,
      },
    });
  };

export const getGrantAction = (dispatch: DispatchAction) => async () => {
  setLoading(dispatch)(true);
  try {
    const { redirectUrl } = await invoiceService.getPaymentSession();
    window.location.replace(redirectUrl);
  } catch (error) {
    setLoading(dispatch)(true);
    throw error;
  }
};
export const fetchInfoAction = async (
  dispatch: DispatchAction,
  office: IExtOfficeDtoWithOrg,
  paymentOfficeLink: React.MutableRefObject<object>
) => {
  try {
    const [balance, payments] = await Promise.all([
      invoiceService.getOutstanding(office.id),
      orgService.getPayments(office.id),
    ]);
    dispatch({
      type: SET_STATE,
      payload: {
        outstandingBalance: {
          [office.id]: balance.outstandingBalance || 0,
        },
        paymentsMethods: payments,
      },
    });
    paymentOfficeLink.current = Object.fromEntries(
      payments.map(({ stripeSourceId }) => [stripeSourceId, office.id])
    );
  } catch (error) {
    throw error;
  }
};

export const submitAction =
  (dispatch: DispatchAction) =>
  async (
    paymentOfficeLink: React.MutableRefObject<object>,
    state: State,
    userOffice: IExtOfficeDtoWithOrg,
    callback: (invoiceCount: number) => void
  ) => {
    setLoading(dispatch)(true);
    const selectedOfficeId = (paymentOfficeLink.current as any)[
      state.stripeSourceId
    ];

    try {
      await invoiceService.payAllInvoices(
        state.outstandingBalance[selectedOfficeId],
        state.stripeSourceId,
        selectedOfficeId as number
      );
      if (state.defaultCard && userOffice) {
        const { billingAddress, address, org, ...officeParams } = userOffice;
        await orgService.updateOffice({
          ...officeParams,
          stripeDefaultSourceId: state.stripeSourceId,
        });
      }
      if (!state.defaultCard) {
        dispatch({
          type: SET_STATE,
          payload: {
            stripeSourceId: "",
            defaultCard: false,
          },
        });
      }
      const timeStart = Date.now();
      let timeOutId: NodeJS.Timeout;

      const refreshHandler = async () => {
        const { invoice, outstandingBalance: outstandingBalanceOffice } =
          await invoiceService.getOutstanding(userOffice.id);
        if (!invoice?.length) {
          dispatch({
            type: SET_STATE,
            payload: {
              open: false,
              loading: false,
              outstandingBalance: {
                [userOffice.id]: outstandingBalanceOffice,
              },
            },
          });
          callback(invoice?.length || 0);
        } else {
          if (Date.now() - timeStart < 10000) {
            timeOutId = setTimeout(refreshHandler, 2000);
          } else {
            clearTimeout(timeOutId);
            dispatch({
              type: SET_STATE,
              payload: {
                open: false,
                loading: false,
                outstandingBalance: {
                  [userOffice.id as number]: outstandingBalanceOffice,
                },
                showMessage: true,
              },
            });
            callback(invoice?.length || 0);
          }
        }
        return outstandingBalanceOffice;
      };
      return await refreshHandler();
    } catch (error) {
      setLoading(dispatch)(false);
      throw error;
    }
  };
