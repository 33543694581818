import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { InfoBlock } from "./InfoBlock";
import { Complications } from "./Complications";
import { Extractions } from "./Extractions";

export const RxForm = () => {
  return (
    <Box pb={5} px={2.5} data-intercom-target="RX info">
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <InfoBlock />
        <Stack direction="row" spacing={3}>
          <Extractions />
          <Complications />
        </Stack>
      </Stack>
    </Box>
  );
};
