import React from "react";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popper from "@mui/material/Popper";

import { useAppSelector } from "hooks/redux";
import { selectors } from "store/search";

import ResultPanel from "./ResultPanel";

interface Props {
  anchorEl?: null | HTMLDivElement;
}
export const ResultPopper: React.VFC<Props> = ({ anchorEl }) => {
  const { open } = useAppSelector(selectors.allInfoSelector);
  const resultIsEmpty = useAppSelector(selectors.isEmptyResult);
  return (
    <Popper
      style={{ zIndex: 10 }}
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 32],
          },
        },
      ]}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <div
              style={{
                width: anchorEl ? anchorEl.offsetWidth : 0,
              }}
            >
              {resultIsEmpty ? (
                <Typography
                  style={{ padding: 18 }}
                  variant="h6"
                  color="textSecondary"
                >
                  No matches found.
                </Typography>
              ) : (
                <ResultPanel />
              )}
            </div>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
