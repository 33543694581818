import React from "react";
import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import { SvgIconProps } from "@mui/material/SvgIcon";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/styles";

import { Colors } from "consts/colors";
import { NestedMenuItem } from "./NestedMenuItem";

export type IMenuItem = {
  label: string;
  icon?: React.ReactElement<SvgIconProps>;
  handler?: () => void;
  subItems?: Omit<IMenuItem, "subItems">[];
};
interface Props {
  id?: string;
  items: IMenuItem[];
  children: React.ReactElement;
}

const StyledListItemText = styled(Typography)({
  color: Colors.TEXT_GREY_LIGHT,
});
const StyledMenuItem = styled(MenuItem)({
  padding: 16,
});
export const Menu: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleWrapper = (handler?: () => void) => {
    return () => {
      if (handler) {
        handleClose();
        handler();
      }
    };
  };
  return (
    <>
      {React.cloneElement(props.children, {
        onClick: handleClick,
      })}
      <MuiMenu
        id={props?.id ?? "basic-menu"}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.items.map((item) => {
          if (item.subItems) {
            return (
              <NestedMenuItem
                key={item.label}
                label={item.label}
                parentMenuOpen={Boolean(anchorEl)}
                leftIcon={item.icon}
                rightIcon={<ChevronRight fontSize="small" color="disabled" />}
              >
                {item.subItems.map((subItem) => (
                  <StyledMenuItem
                    key={subItem.label}
                    onClick={handleWrapper(subItem.handler)}
                  >
                    {subItem.icon && (
                      <ListItemIcon>{subItem.icon}</ListItemIcon>
                    )}
                    <StyledListItemText variant="body2">
                      {subItem.label}
                    </StyledListItemText>
                  </StyledMenuItem>
                ))}
              </NestedMenuItem>
            );
          }
          return (
            <StyledMenuItem
              key={item.label}
              onClick={handleWrapper(item.handler)}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <StyledListItemText variant="body2">
                {item.label}
              </StyledListItemText>
            </StyledMenuItem>
          );
        })}
      </MuiMenu>
    </>
  );
};
