import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { FileUploadArea, UploadedFileCard } from "components/common";
import { FormLabel } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { createFileForStore } from "utils/fileHelpers";
import {
  selectors,
  actions,
  OrderFileType,
} from "store/continuationOrderCreate";

export const FilesUpload = () => {
  const dispatch = useAppDispatch();
  const { verificationKeys, commonFiles, clinicalPhotos } = useAppSelector(
    selectors.getFilesSelector
  );

  const filesUploadHandler = (type: OrderFileType) => (files: File[]) => {
    dispatch(actions.setFiles({ files: files.map(createFileForStore), type }));
  };
  const filesDeleteHandler = (type: OrderFileType) => (name: string) => {
    dispatch(
      actions.deleteFile({
        fileName: name,
        type,
      })
    );
  };
  const uploadFiles =
    (fileType: "clinicalPhotos" | "commonFiles" | "verificationKeys") =>
    (params: {
      fileId: string;
      controller: AbortController;
      contentType: string;
    }) => {
      dispatch(
        actions.downloadFile({
          ...params,
          fileType,
        })
      );
    };

  return (
    <Grid container spacing={4} mt={6}>
      <Grid item md={4}>
        <FormLabel
          label="Upload your scans (STL format)"
          targetLabel="STL files"
        >
          <FileUploadArea onFilesUpload={filesUploadHandler("commonFiles")} />
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {commonFiles.map((file) => (
              <UploadedFileCard
                key={file.id}
                file={file}
                onUpload={uploadFiles("commonFiles")}
                onDelete={filesDeleteHandler("commonFiles")}
              />
            ))}
          </Stack>
        </FormLabel>
      </Grid>
      <Grid item md={4}>
        <FormLabel
          label="Upload your Clinical Photos"
          targetLabel="Clinical photos"
        >
          <FileUploadArea
            onFilesUpload={filesUploadHandler("clinicalPhotos")}
          />
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {clinicalPhotos.map((file) => (
              <UploadedFileCard
                key={file.id}
                file={file}
                onUpload={uploadFiles("clinicalPhotos")}
                onDelete={filesDeleteHandler("clinicalPhotos")}
              />
            ))}
          </Stack>
        </FormLabel>
      </Grid>
      <Grid item md={4}>
        <FormLabel label="Verification Key">
          <FileUploadArea
            onFilesUpload={filesUploadHandler("verificationKeys")}
          />
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {verificationKeys.map((file) => (
              <UploadedFileCard
                key={file.id}
                file={file}
                onUpload={uploadFiles("verificationKeys")}
                onDelete={filesDeleteHandler("verificationKeys")}
              />
            ))}
          </Stack>
        </FormLabel>
      </Grid>
    </Grid>
  );
};
