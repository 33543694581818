import React, { useRef } from "react";

import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import clsx from "clsx";
import {
  HeaderCloseIcon,
  HeaderFilterIcon,
  Input,
  InputContainer,
  Listbox,
  ListItem,
} from "./Elements";
import { FilterListProps, TableFilterListOption } from "./types";
import { Popper } from "@mui/material";
import Typography from "@mui/material/Typography";

export const FilterList = ({
  title,
  data,
  handleChange,
  defaultValue,
  icon,
}: FilterListProps) => {
  const {
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    getPopupIndicatorProps,
    groupedOptions,
    value,
  } = useAutocomplete({
    id: "use-autocomplete-filter",
    options: data,
    getOptionLabel: (option: TableFilterListOption) => option.title,
    onChange: (event, value) => handleChange(event, value),
    defaultValue,
  });
  const input = useRef<HTMLInputElement>(null);
  if (!data?.length) {
    return (
      <Typography color="inherit" fontSize="inherit" fontWeight="inherit">
        {title}
      </Typography>
    );
  }
  return (
    <>
      <div
        ref={input}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <InputContainer>
          <Input {...getInputProps()} placeholder={title} />
          {value && (
            <span onClick={getClearProps()?.onClick}>
              <HeaderCloseIcon viewBox="0 0 14 14" />
            </span>
          )}
          <span onClick={getPopupIndicatorProps().onClick}>
            {icon ? (
              React.cloneElement(icon, {
                className: clsx({ active: value }),
              })
            ) : (
              <HeaderFilterIcon
                className={clsx({ active: value })}
                viewBox="0 0 14 14"
              />
            )}
          </span>
        </InputContainer>
      </div>
      <Popper
        open={groupedOptions.length > 0}
        anchorEl={input.current}
        sx={{ zIndex: 300 }}
        placement="bottom-start"
      >
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof data).map(
            (option: TableFilterListOption, index: number) => (
              <ListItem
                {...getOptionProps({ option, index })}
                value={String(option.value)}
              >
                {option.title}
              </ListItem>
            )
          )}
        </Listbox>
      </Popper>
    </>
  );
};
