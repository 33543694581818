import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { styled } from "@mui/material";

import { Colors } from "consts/colors";

import logo from "assets/images/orthosnap-logo-web.png";

const StyledContainer = styled(Container)({
  height: "100vh",
  display: "flex",
  alignItems: "center",
});
const StyledImage = styled("img")({
  maxWidth: "100%",
});

export const PreloadContainer = () => {
  return (
    <StyledContainer maxWidth="xs">
      <Stack spacing={4}>
        <StyledImage src={logo} alt="Orthosnap" />
        <Stack direction="row" spacing={2}>
          <CircularProgress sx={{ color: Colors.PRIMARY }} size={24} />
          <Typography sx={{ color: Colors.PRIMARY }} variant="h2">
            Please wait a moment...
          </Typography>
        </Stack>
      </Stack>
    </StyledContainer>
  );
};
