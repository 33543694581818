import { PaperContainer } from "./Elements";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { Tabs } from "components/ui";
import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/accountSettings";

import { OfficeItemCard } from "./OfficeItemCard";

import React from "react";
import Skeleton from "@mui/material/Skeleton";

export const OfficeSettingsCard = () => {
  const { isLoading, officeSettings } = useAppSelector(
    selectors.getAllInfoSelector
  );

  const tabs = officeSettings.map((officeSettings) => ({
    label: officeSettings.officeName,
    tabId: officeSettings.officeId.toString(),
    content: <OfficeItemCard officeSettings={officeSettings} />,
  }));
  return (
    <PaperContainer>
      <Typography fontWeight={600} variant="h2" color={Colors.TEXT_PRIMARY}>
        Office settings
      </Typography>
      {isLoading ? (
        <Skeleton animation="wave" height={200} />
      ) : (
        <Box mt={3}>
          <Tabs tabs={tabs} />
        </Box>
      )}
    </PaperContainer>
  );
};
