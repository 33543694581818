import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Colors } from "consts/colors";

interface Props {
  value: boolean;
  onChange: (event: React.SyntheticEvent, checked: boolean) => void;
}

export const ShowCompletedCheckbox: React.FC<Props> = ({ value, onChange }) => {
  return (
    <FormControlLabel
      sx={{
        marginLeft: 1,
      }}
      componentsProps={{
        typography: {
          variant: "body2",
          fontWeight: 600,
          color: Colors.TEXT_DARK,
        },
      }}
      checked={value}
      onChange={onChange}
      control={<Checkbox sx={{ padding: 1 }} />}
      label="Completed"
    />
  );
};
