import React from "react";
import { CreateEvalCaseOrder } from "containers";
import { analyticsMethod } from "plugins/analytics";

export const NewPatientPreEvalOrderPage = () => {
  analyticsMethod(
    "page",
    "Orders",
    {
      title: "Create form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  return <CreateEvalCaseOrder preEval title="Pre-Evaluation order" />;
};
