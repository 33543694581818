import { AnyAction, AsyncThunk, createReducer } from "@reduxjs/toolkit";
import { initState } from "./state";
import { PatientDetailsState } from "./types";
import * as actions from "./actions";
import { CaseStatuses } from "lib/enum";
import { IExtOrderTaskDto } from "../../lib/dto";
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.startsWith("patient") && action.type.endsWith("/rejected");
}

export const reducer = createReducer<PatientDetailsState>(
  initState,
  (builder) => {
    builder
      .addCase(actions.clearState, () => initState)
      .addCase(actions.setOpenScheduling, (state, action) => {
        state.openScheduling = action.payload;
      })
      .addCase(actions.openDM, (state) => {
        state.showDM = true;
      })
      .addCase(actions.showStartStopDM, (state) => {
        state.showStartStopDM = true;
      })
      .addCase(actions.hideStartStopDM, (state) => {
        state.showStartStopDM = false;
      })
      .addCase(actions.dismissDM, (state) => {
        state.showDM = false;
        state.dmFields.birthDate = "";
        state.dmFields.phoneNumber = "";
        state.dmFields.email = "";
      })
      .addCase(actions.setOpenVerification, (state, action) => {
        state.openVerification = action.payload;
      })
      .addCase(actions.setRating, (state, action) => {
        state.raiting = action.payload;
      })
      .addCase(actions.setRatingComment, (state, action) => {
        state.ratingComment = action.payload;
      })
      .addCase(actions.setDMEmail, (state, action) => {
        state.dmFields.email = action.payload;
      })
      .addCase(actions.setDMPhone, (state, action) => {
        state.dmFields.phoneNumber = action.payload;
      })
      .addCase(actions.setDMNotify, (state, action) => {
        state.dmFields.notify = action.payload;
      })
      .addCase(actions.setDMBirth, (state, action) => {
        state.dmFields.birthDate = action.payload;
      })
      .addCase(actions.setCaseInfo, (state, action) => {
        state.caseInfo = action.payload;
      })
      .addCase(actions.fetchAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.submitPatientToDM.fulfilled, (state, action) => {
        state.showDM = false;
        state.dmFields.birthDate = "";
        state.dmFields.phoneNumber = "";
        state.dmFields.email = "";
      })
      .addCase(actions.fetchAll.fulfilled, (state, action) => {
        return {
          ...initState,
          searchTabCaseId: action.payload!.searchTabCaseId,
          currentOrder: action.payload.currentOrder,
          previousOrders: action.payload.previousOrders,
          caseInfo: action.payload.caseInfo,
          productType: action.payload.productType,
          priceList: action.payload.priceList,
          currentOrderShippings: action.payload.shippings,
          orderMessages: action.payload.orderMessages,
          currentOrderProducedSteps: action.payload.treatPlanInstructions,
          files: action.payload.files,
          invoices: action.payload.invoices,
          caseEvents: action.payload.caseEvents,
          openScheduling: state.openScheduling,
          openVerification: state.openVerification,
          officeStaff: action.payload.officeStaff,
          orderTasks: action.payload.currentOrder?.orderTasks || [],
          showRating:
            action.payload.caseInfo?.status === CaseStatuses.COMPLETED &&
            !action.payload.caseInfo?.finishRate,
        };
      })
      .addCase(actions.downloadFile.fulfilled, (state, action) => {
        const fileIdx = state.files.findIndex(
          ({ id }) => id === action.payload.fileId
        );
        if (fileIdx !== -1) {
          state.files[fileIdx].src = action.payload.src;
        }
      })
      .addCase(actions.removeMessageFile.fulfilled, (state, action) => {
        const messageIdx = state.orderMessages.findIndex(
          ({ id }) => id === action.payload.messageId
        );
        if (messageIdx !== -1) {
          state.orderMessages[messageIdx].files = state.orderMessages[
            messageIdx
          ].files.filter(({ id }) => id !== action.payload.fileId);
        }
      })
      .addCase(actions.uploadCaseFiles.fulfilled, (state, action) => {
        state.files.push(...action.payload);
      })
      .addCase(actions.submitRating.fulfilled, (state) => {
        state.showRating = false;
        state.ratingCompleted = true;
      })
      .addCase(
        actions.createShippingForCurrentOrder.fulfilled,
        (state, action) => {
          state.currentOrderShippings = action.payload.shippings;
        }
      )
      .addCase(actions.updateWearingStart.fulfilled, (state, action) => {
        state.currentOrder = action.payload;
        state.openScheduling = false;
      })
      .addCase(actions.reloadOrder.fulfilled, (state, action) => {
        state.currentOrder = action.payload.currentOrder;
        state.previousOrders = action.payload.previousOrders;
      })
      .addCase(actions.startStopMonitoringToDM.fulfilled, (state, action) => {
        if (state.caseInfo?.patient && action.payload) {
          state.caseInfo.patient.onMonitoring =
            !state.caseInfo.patient.onMonitoring;
        }
      })
      .addCase(actions.createMessage.fulfilled, (state, action) => {
        state.orderMessages = [action.payload, ...state.orderMessages];
      })
      .addCase(actions.readMessage.fulfilled, (state, action) => {
        const messageIndex = state.orderMessages.findIndex(
          ({ unreadMessageId }) => action.payload === unreadMessageId
        );
        state.orderMessages[messageIndex].unreadMessageId = null;
      })
      .addCase(actions.submitToLab.fulfilled, (state, action) => {
        state.currentOrder = action.payload;
      })
      .addCase(actions.cancelCase.fulfilled, (state, action) => {
        state.caseInfo = action.payload.case;
        state.currentOrder = action.payload.order;
      })
      .addCase(actions.cancelLastOrder.fulfilled, (state, action) => {
        state.currentOrder = action.payload;
      })
      .addCase(actions.approveTp.fulfilled, (state, action) => {
        const orderTasks = action.payload?.map(
          ({ notes, ...task }) => task
        ) as IExtOrderTaskDto[];
        state.orderTasks = orderTasks;
        if (state.currentOrder?.orderTasks) {
          state.currentOrder.orderTasks = orderTasks;
        }
      })
      .addCase(actions.rejectTp.fulfilled, (state, action) => {
        state.currentOrder = action.payload;
      })
      .addCase(actions.updatePatientInfo.fulfilled, (state, _) => {})
      .addMatcher(isRejectedAction, (state, action) => {
        if ((action.error as any)?.message !== "canceled") {
          state.errors =
            (action.error as any)?.message ?? (action.error as any).toString();
        }
        state.isLoading = false;
        state.isOrderUpdating = false;
      });
  }
);
