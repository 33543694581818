import { ChangeEvent, FC } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { Select, FormLabel, TextField } from "components/ui";

import { txPlanComplexitySelect, caseBudgetSelect } from "lib/dictionaries";
import { CaseBudgetEnum } from "lib/enum";

interface Props {
  value: {
    txPlanComplexity?: string;
    caseBudget?: string;
    caseBudgetCost?: string;
  };
  changeHandler: (event: SelectChangeEvent<string>) => void;
  changeCostHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}
export const SelectCaseBudgetContainer: FC<Props> = ({
  value,
  changeHandler,
  changeCostHandler,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <FormLabel label="Tx Plan Complexity">
          <Select
            value={value.txPlanComplexity ?? ""}
            fullWidth
            data={txPlanComplexitySelect}
            placeholder="Select one"
            name="txPlanComplexity"
            onChange={changeHandler}
          />
        </FormLabel>
      </Grid>
      <Grid item xs={4}>
        <FormLabel label="Case Budget">
          <Select
            value={value.caseBudget ?? ""}
            fullWidth
            data={caseBudgetSelect}
            placeholder="Select one"
            name="caseBudget"
            onChange={changeHandler}
          />
        </FormLabel>
      </Grid>
      <Grid item xs={4}>
        {value.caseBudget === CaseBudgetEnum.NO_MORE && (
          <FormLabel label="Case budget amount">
            <TextField
              value={value.caseBudgetCost ?? ""}
              type="number"
              fullWidth
              name="caseBudgetCost"
              placeholder="Please enter the case budget amount in dollars"
              onChange={changeCostHandler}
            />
          </FormLabel>
        )}
      </Grid>
    </Grid>
  );
};
