import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PatientDetailsAction } from "./types";
import { Nullable } from "lib/types";
import {
  IEventDashboardDto,
  IExtCaseWithCaseProgress,
  IExtFileDto,
  IExtOrderForCaseViewDto,
  IExtOrderMessageDto,
  IExtOrderWithItemsAndTasksDto,
  IExtShippingInfoDto,
  IOrderInvoiceDto,
  ITreatmentPlanProductionInstructions,
  IExtOfficeStaffDto,
  IExtOrderTaskWithNotesDto,
  ICaseTabDto,
  IProductTypesDto,
  IPriceListDto,
} from "lib/dto";
import { IBaseResponseList, ICreateMessage } from "lib/model";
import {
  CaseFinishRate,
  FileFromType,
  FileType,
  OrderStatuses,
  OrderTaskStatuses,
  TaskType,
} from "lib/enum";
import {
  caseService,
  fileService,
  invoiceService,
  orderMessageService,
  orderService,
  orderTaskService,
  orgService,
  priceListService,
  productTypesService,
  shippingService,
  treatmentPlanService,
} from "services";
import { RootState } from "../store";
import { generateNewMessage } from "./utils";
import { generateUniqueNumberID } from "utils";
import { refreshMessageCount } from "../currentUser/actions";
import { actions } from "../shipping";

export const setOpenScheduling = createAction<boolean>(
  PatientDetailsAction.SCHEDULING_SET
);
export const setDMPhone = createAction<string>(
  PatientDetailsAction.SET_DM_PHONE
);
export const setDMBirth = createAction<string>(
  PatientDetailsAction.SET_DM_BIRTH
);
export const setDMEmail = createAction<string>(
  PatientDetailsAction.SET_DM_EMAIL
);
export const setDMNotify = createAction<boolean>(
  PatientDetailsAction.SET_DM_NOTIFY
);
export const openDM = createAction(PatientDetailsAction.OPEN_DM);
export const dismissDM = createAction(PatientDetailsAction.DISMISS_DM);
export const showStartStopDM = createAction(
  PatientDetailsAction.SHOW_START_STOP_MD
);
export const hideStartStopDM = createAction(
  PatientDetailsAction.HIDE_START_STOP_MD
);
export const setOpenVerification = createAction<boolean>(
  PatientDetailsAction.VERIFICATION_SET
);
export const clearState = createAction(PatientDetailsAction.CLEAR_STATE);
export const setCaseInfo = createAction<IExtCaseWithCaseProgress>(
  PatientDetailsAction.SET_CASE_INFO
);
export const fetchAll = createAsyncThunk<
  {
    searchTabCaseId: IBaseResponseList<ICaseTabDto>;
    caseInfo: Nullable<IExtCaseWithCaseProgress>;
    productType: Nullable<IProductTypesDto>;
    priceList: Nullable<IPriceListDto>;
    currentOrder: Nullable<IExtOrderWithItemsAndTasksDto>;
    previousOrders: IExtOrderForCaseViewDto[];
    orderMessages: IExtOrderMessageDto[];
    shippings: IExtShippingInfoDto[];
    caseEvents: IEventDashboardDto[];
    invoices: IOrderInvoiceDto[];
    treatPlanInstructions: Nullable<ITreatmentPlanProductionInstructions>;
    files: IExtFileDto[];
    officeStaff: IExtOfficeStaffDto;
  },
  string
>(PatientDetailsAction.FETCH_INFO, async (caseId) => {
  const [caseInfo, caseOrders, { files }, caseEvents, searchTabCaseId] =
    await Promise.all([
      caseService.getCaseWithOfficeAndProgressById(caseId),
      caseService.getOrdersByCaseId(caseId),
      caseService.getAllCaseAndOrderFiles(caseId),
      caseService.getCaseEvents(caseId),
      caseService.getCasesSearch({ "search-for": caseId }),
    ]);
  let productType = null;
  let priceList = null;
  if (caseInfo?.productTypeId) {
    productType = await productTypesService.get(caseInfo.productTypeId);
    const priceLists = await priceListService.getPriceListEnabled();
    priceList =
      priceLists.find(
        ({ productTypeId }) => productTypeId === caseInfo.productTypeId
      ) ?? null;
  }

  const officeStaff = await orgService.getOfficeStaffById(
    caseInfo?.officeStaffId!
  );

  if (caseOrders && caseOrders.length) {
    caseOrders.sort(({ id: aId }, { id: bId }) => bId - aId);
    const currentOrder = caseOrders.shift();

    const [
      orderInfo,
      orderMessages,
      invoices,
      shippings,
      treatPlanInstructions,
    ] = await Promise.all([
      orderService.getOrderWithItemsAndTasksById(currentOrder!.id),
      caseService.getCaseOrderMessagesById(caseId),
      invoiceService.getInvoicesByOrderId(currentOrder!.id),
      shippingService.getShippingsByOrderId(currentOrder!.id),
      treatmentPlanService.getProductionInstructions(caseId, currentOrder!.id),
    ]);
    console.log(invoices);
    return {
      caseInfo,
      productType,
      priceList,
      currentOrder: { ...orderInfo, type: currentOrder!.type },
      orderMessages,
      officeStaff,
      shippings,
      files: Object.values(files || {}).flatMap((filesGroup) => filesGroup),
      previousOrders: caseOrders,
      invoices: invoices || [],
      caseEvents: caseEvents || [],
      treatPlanInstructions,
      searchTabCaseId,
    };
  }

  return {
    caseInfo,
    productType,
    priceList,
    officeStaff,
    files: Object.values(files || {}).flatMap((filesGroup) => filesGroup),
    shippings: [],
    currentOrder: null,
    previousOrders: [],
    orderMessages: [],
    invoices: [],
    caseEvents: caseEvents || [],
    treatPlanInstructions: null,
    searchTabCaseId: searchTabCaseId,
  };
});

export const downloadFile = createAsyncThunk<
  {
    src: string;
    fileId: number;
  },
  { fileId: number; controller?: AbortController; contentType?: string }
>(
  PatientDetailsAction.DOWNLOAD_FILE,
  async ({ fileId, controller, contentType }) => {
    const src = await fileService.getFile(
      fileId,
      contentType,
      controller?.signal
    );
    return {
      src,
      fileId,
    };
  }
);

export const submitToLab = createAsyncThunk<
  Nullable<IExtOrderWithItemsAndTasksDto>,
  number | string
>(PatientDetailsAction.SUBMIT_ORDER, async (orderId) => {
  await orderService.submitOrderToLab(orderId);
  return await orderService.getOrderWithItemsAndTasksById(orderId);
});

export const approveTp = createAsyncThunk<
  Nullable<IExtOrderTaskWithNotesDto[]>,
  number | string
>(PatientDetailsAction.APPROVE_PLAN, async (taskId, thunkApi) => {
  const {
    patientCard: { currentOrder },
  } = thunkApi.getState() as RootState;
  if (currentOrder?.status === OrderStatuses.HOLD) {
    await orderService.unholdOrder(currentOrder!.id);
  }
  await orderTaskService.moveTaskToDone(taskId);
  return await orderService.getOrderTaskByOrderId(currentOrder!.id);
});

export const submitPatientToDM = createAsyncThunk(
  PatientDetailsAction.SUBMIT_DM_PATIENT,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { patientCard } = state;
    const approveTreatPlanTask = patientCard.currentOrder?.orderTasks.find(
      ({ task, status }) =>
        task.type === TaskType.APPROVE_TREAT_PLAN &&
        status === OrderTaskStatuses.IN_PROGRESS
    );
    await caseService.submitPatientToDM(patientCard.caseInfo?.id!, {
      email: patientCard.caseInfo?.patient.email || patientCard.dmFields.email,
      notifyPatient: patientCard.dmFields.notify,

      dateOfBirth:
        patientCard.caseInfo?.patient.birthDate ||
        patientCard.dmFields.birthDate,
      practiceId: patientCard.caseInfo?.office.practiceId!,
      doctorId: patientCard.officeStaff?.dmDoctor!,
      phone:
        patientCard.caseInfo?.patient.phone || patientCard.dmFields.phoneNumber,
    });

    const caseInfo = await caseService.getCaseWithOfficeAndProgressById(
      patientCard.caseInfo!.id
    );
    thunkAPI.dispatch(setCaseInfo(caseInfo));

    if (approveTreatPlanTask) {
      await thunkAPI.dispatch(approveTp(approveTreatPlanTask.id));
      return true;
    }
    return false;
  }
);

export const startStopMonitoringToDM = createAsyncThunk(
  PatientDetailsAction.SUBMIT_START_STOP_MONITORING_DM,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { patientCard } = state;
    if (patientCard.caseInfo && patientCard?.caseInfo?.patient) {
      if (patientCard?.caseInfo?.patient.onMonitoring) {
        await caseService.stopDmMonitoring(patientCard.caseInfo.id);
      } else {
        await caseService.startDmMonitoring(patientCard.caseInfo.id);
      }
      return true;
    }
    return false;
  }
);

export const rejectTp = createAsyncThunk<
  Nullable<IExtOrderWithItemsAndTasksDto>,
  {
    taskId: number | string;
    description: string;
  }
>(
  PatientDetailsAction.REJECT_PLAN,
  async ({ taskId, description }, thunkApi) => {
    const {
      patientCard: { currentOrder },
    } = thunkApi.getState() as RootState;
    if (currentOrder?.status === OrderStatuses.HOLD) {
      await orderService.unholdOrder(currentOrder!.id);
    }
    await orderTaskService.moveTaskToCancel(taskId, description);
    return await orderService.getOrderWithItemsAndTasksById(currentOrder!.id);
  }
);

export const setRating = createAction<CaseFinishRate>(
  PatientDetailsAction.SET_RATING
);
export const setRatingComment = createAction<string>(
  PatientDetailsAction.SET_RATING_COMMENT
);

export const submitRating = createAsyncThunk<void>(
  PatientDetailsAction.SUBMIT_RATING,
  async (rate, thunkApi) => {
    const {
      patientCard: { caseInfo, raiting, ratingComment },
    } = thunkApi.getState() as RootState;
    await caseService.finishRate(caseInfo?.id!, raiting!, ratingComment);
  }
);

export const updateWearingStart = createAsyncThunk<
  Nullable<IExtOrderWithItemsAndTasksDto>,
  string
>(
  PatientDetailsAction.SEND_START_WEARING,
  async (startWearingDate, thunkApi) => {
    const {
      patientCard: { currentOrder },
    } = thunkApi.getState() as RootState;
    await orderService.setStartWearingDate(currentOrder!.id, startWearingDate);
    return await orderService.getOrderWithItemsAndTasksById(currentOrder!.id);
  }
);

export const reloadOrder = createAsyncThunk<
  {
    currentOrder: Nullable<IExtOrderWithItemsAndTasksDto>;
    previousOrders: IExtOrderForCaseViewDto[];
  },
  void
>(PatientDetailsAction.RELOAD_ORDER, async (_, thunkApi) => {
  const {
    patientCard: { caseInfo },
  } = thunkApi.getState() as RootState;
  const orders = await caseService.getOrdersByCaseId(caseInfo?.id!);

  orders!.sort(({ id: aId }, { id: bId }) => bId - aId);
  const currentOrder = orders!.shift();

  const orderInfo: Nullable<IExtOrderWithItemsAndTasksDto> =
    await orderService.getOrderWithItemsAndTasksById(currentOrder!.id);

  return {
    currentOrder: { ...orderInfo, type: currentOrder!.type },
    previousOrders: orders!,
  };
});

export const readMessage = createAsyncThunk<number, number>(
  PatientDetailsAction.READ_MESSAGE,
  async (messageId, thunkAPI) => {
    await orderMessageService.readMessage([messageId]);
    await thunkAPI.dispatch(refreshMessageCount()).unwrap();
    return messageId;
  }
);

export const createMessage = createAsyncThunk<
  IExtOrderMessageDto,
  ICreateMessage
>(
  PatientDetailsAction.CREATE_MESSAGE,
  async ({ files, ...message }: ICreateMessage, thunkApi) => {
    try {
      const {
        currentUser: { currentUser },
      } = thunkApi.getState() as RootState;
      const { id } = await orderMessageService.sendMessage(message);
      if (files) {
        await orderMessageService.uploadFilesMessage(id, files);
        const newFiles = await orderMessageService.getFilesByMessageId(id);
        return generateNewMessage(message, id, currentUser!, newFiles);
      }
      return generateNewMessage(message, id, currentUser!);
    } catch {
      return thunkApi.rejectWithValue("Message sending error");
    }
  }
);

export const cancelCase = createAsyncThunk<
  {
    case: IExtCaseWithCaseProgress;
    order: IExtOrderWithItemsAndTasksDto;
  },
  string
>(PatientDetailsAction.CANCEL_CASE, async (description, thunkAPI) => {
  const { patientCard } = thunkAPI.getState() as RootState;
  await caseService.cancelTheCase(patientCard.caseInfo!.id, description);
  const [caseResult, orderResult] = await Promise.all([
    caseService.getCaseWithOfficeAndProgressById(patientCard.caseInfo!.id!),
    orderService.getOrderWithItemsAndTasksById(patientCard.currentOrder!.id),
  ]);

  return {
    case: caseResult,
    order: orderResult,
  };
});

export const cancelLastOrder = createAsyncThunk<
  IExtOrderWithItemsAndTasksDto,
  {
    description: string;
  }
>(PatientDetailsAction.CANCEL_LAST_ORDER, async (params, thunkAPI) => {
  const { patientCard } = thunkAPI.getState() as RootState;
  await orderService.cancelOrder(
    patientCard.currentOrder!.id,
    params.description
  );
  return await orderService.getOrderWithItemsAndTasksById(
    patientCard.currentOrder!.id
  );
});

type ActionParams = {
  fileId: number;
  messageId: number;
};
export const removeMessageFile = createAsyncThunk<ActionParams, ActionParams>(
  PatientDetailsAction.DELETE_MESSAGE_FILE,
  async (params) => {
    await orderMessageService.removeOrderMessageFile([params.fileId]);
    return params;
  }
);

export const uploadCaseFiles = createAsyncThunk<
  (IExtFileDto & { src?: string })[],
  {
    files: File[];
    filesType: FileType;
  }
>(PatientDetailsAction.UPLOAD_FILE_CASES, async (params, thunkAPI) => {
  const { patientCard } = thunkAPI.getState() as RootState;
  await caseService.uploadCaseFiles(
    patientCard.caseInfo!.id,
    params.files.map((file) => ({
      blob: file,
      name: file.name,
    })),
    params.filesType
  );
  const currentDate = new Date().toISOString();
  return params.files.map((file) => ({
    id: generateUniqueNumberID(),
    name: file.name,
    contentType: file.type,
    src: URL.createObjectURL(file),
    fileType: params.filesType,
    fromType: FileFromType.FROM_DOCTOR,
    path: "",
    caseId: patientCard.caseInfo!.id,
    orderId: null,
    originalFileId: null,
    created: currentDate,
    modified: currentDate,
  })) as (IExtFileDto & { src?: string })[];
});

export const createShippingForCurrentOrder = createAsyncThunk<{
  shippings: IExtShippingInfoDto[];
  shippingLabel: string;
}>(PatientDetailsAction.CREATE_SHIPPING, async (_, thunkAPI) => {
  const { patientCard } = thunkAPI.getState() as RootState;
  const shippingLabel = await thunkAPI
    .dispatch(actions.printShippingLabel(patientCard.currentOrder?.id!))
    .unwrap();

  const shippings = await shippingService.getShippingsByOrderId(
    patientCard.currentOrder?.id!
  );
  return {
    shippings,
    shippingLabel,
  };
});

export const updatePatientInfo = createAsyncThunk(
  PatientDetailsAction.UPDATE_PATIENT,
  async (_, thunkAPI) => {
    const patient = (thunkAPI.getState() as RootState).patientCard.caseInfo
      ?.patient!;
    return await caseService.updatePatientInfo(patient);
  }
);
