import React from "react";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";

import Degrees from "../Cell/Degrees";
import { IMarkOutToothDto } from "lib/dto";
import { Colors } from "consts/colors";

interface Props {
  toothRotation?: IMarkOutToothDto;
}

const FbmCell: React.FunctionComponent<Props> = ({ toothRotation }) => {
  if (toothRotation && typeof toothRotation.toothMovement === "number") {
    return (
      <Tooltip title={`${toothRotation.toothMovement}°`} placement="top" arrow>
        <span
          className={clsx("fbmCell", !!toothRotation && "fbmCell__itemInfo")}
        >
          <Degrees
            value={toothRotation.toothMovement}
            color={Colors.PRIMARY}
            className="fbmCell__rotationInfo"
          />
        </span>
      </Tooltip>
    );
  }
  return (
    <span className={clsx("fbmCell", !!toothRotation && "fbmCell__itemInfo")}>
      <span className="fbmCell__selector" />
    </span>
  );
};

export default FbmCell;
