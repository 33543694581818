export enum TorqueType {
  MESIAL = "MESIAL",
  DISTAL = "DISTAL",
  BUCCTAL = "BUCCTAL",
  LINGUAL = "LINGUAL",
  PALATAL = "PALATAL",
  MESIAL_BUCCTAL = "MESIAL_BUCCTAL",
  MESIAL_LINGUAL = "MESIAL_LINGUAL",
  MESIAL_PALATAL = "MESIAL_PALATAL",
  DISTAL_BUCCTAL = "DISTAL_BUCCTAL",
  DISTAL_LINGUAL = "DISTAL_LINGUAL",
  DISTAL_PALATAL = "DISTAL_PALATAL",
}
