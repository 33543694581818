import React from "react";
import ChatIcon from "@mui/icons-material/Chat";
import clsx from "clsx";

interface NoteCellProps {
  selected: boolean;
  bTop: boolean;
  bBottom: boolean;
  bLeft: boolean;
  bRight: boolean;
  isFirst: boolean;
  onIconClick: React.MouseEventHandler;
}
export const NotesCell = (props: NoteCellProps) => {
  return (
    <div
      className={clsx("notes-cell", {
        "notes-cell_selected": props.selected,
        "notes-cell_selected-top": props.bTop,
        "notes-cell_selected-bottom": props.bBottom,
        "notes-cell_selected-left": props.bLeft,
        "notes-cell_selected-right": props.bRight,
      })}
    >
      {props.isFirst && (
        <ChatIcon
          onContextMenu={props.onIconClick}
          fontSize="small"
          className="notes-cell__icon"
        />
      )}
    </div>
  );
};
