import React, { useMemo } from "react";
import { IMarkOutToothDto } from "lib/dto";

import { useAppSelector } from "hooks/redux";
import { selectors } from "store/treatmentPlan";

import { FbmContainer } from "./FbmElements";
import Arch from "./Arch";

const FbmArch = () => {
  const treatPlan = useAppSelector(selectors.treatPlanSelector);
  const markOutMap = useMemo<Map<number, IMarkOutToothDto>>(() => {
    if (treatPlan?.markOutToothList) {
      return new Map(
        treatPlan?.markOutToothList.map((item) => [item.toothNumber, item])
      );
    }
    return new Map();
  }, [treatPlan?.markOutToothList]);

  return (
    <FbmContainer>
      <div>
        <Arch toothMap={markOutMap} type="upper" />
      </div>
      <div>
        <Arch toothMap={markOutMap} type="lower" />
      </div>
    </FbmContainer>
  );
};
export default FbmArch;
