import React from 'react';

const Extrusion = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none">
			<circle cx="16" cy="16" r="16" fill="white" />
			<path
				d="M10.0165 16.6662H6.06044V19.3448H10.7033V21H4V11H10.6896V12.669H6.06044V15.0522H10.0165V16.6662Z"
				fill="#38B781"
			/>
			<path
				d="M15.4904 14.4478L17.3654 11H19.7349L16.8228 15.9588L19.8104 21H17.4135L15.4904 17.4973L13.5673 21H11.1703L14.158 15.9588L11.2459 11H13.6154L15.4904 14.4478Z"
				fill="#38B781"
			/>
			<path
				d="M28.3819 12.669H25.3187V21H23.2582V12.669H20.2363V11H28.3819V12.669Z"
				fill="#38B781"
			/>
		</svg>
	);
};
export default Extrusion;
