import { AnyAction, AsyncThunk, createReducer } from "@reduxjs/toolkit";
import { CurrentUserState } from "./types";
import { initState } from "./state";
import { IAuthorizedUserData } from "lib/model";
import { IGroupedUnreadMessageDto } from "lib/dto";
import { refreshMessageCount } from "./actions";

type GenericAsyncThunk = AsyncThunk<
  IAuthorizedUserData & { unreadMessages: IGroupedUnreadMessageDto[] },
  string,
  any
>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;

function isFulfilleddAction(action: AnyAction): action is FulfilledAction {
  return (
    action.type.startsWith("currentUser") &&
    action.type.endsWith("get/fulfilled")
  );
}

export const reducer = createReducer<CurrentUserState>(initState, (builder) => {
  builder
    .addCase(refreshMessageCount.fulfilled, (state, action) => {
      state.unreadMessage = action.payload;
    })
    .addMatcher(isFulfilleddAction, (state, action) => {
      return {
        ...state,
        currentUser: action.payload.currentUser,
        roles: action.payload.roles,
        offices: action.payload.offices,
        doctorInfo: action.payload.doctorInfo,
        isAuthorized: true,
        isLoading: false,
        unreadMessage: action.payload.unreadMessages,
        profile: action.payload.profile,
      };
    });
});
