import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons/faArrowRightFromBracket";

import { FontAwesomeSvgIcon } from "components/common";
import { authService } from "services";
import { AUTH_TOKEN_NAME, NEW_PORTAL_COOKIE } from "services/apiConsts";
import { useStyledSnackbar } from "hooks/notification";
import { ImgContainer, StyledButton } from "./Elements";

export const LogoutButton = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showErrorNotice } = useStyledSnackbar();
  const logoutHandler = () => {
    setLoading(true);
    authService
      .getLogoutLink()
      .then((logoutLink) => {
        localStorage.removeItem(AUTH_TOKEN_NAME);
        document.cookie = `${NEW_PORTAL_COOKIE}=;Max-Age=0`;
        window.location.assign(logoutLink);
      })
      .catch((error) => {
        showErrorNotice(error.message);
        setLoading(false);
      });
  };
  return (
    <StyledButton
      data-intercom-target="Logout button"
      variant="contained"
      onClick={logoutHandler}
    >
      <ImgContainer>
        {loading ? (
          <CircularProgress sx={{ color: "white" }} size={20} />
        ) : (
          <FontAwesomeSvgIcon
            icon={faArrowRightFromBracket}
            sx={{ color: "white" }}
            fontSize="small"
          />
        )}
      </ImgContainer>
      <Typography variant="caption" color="white">
        Logout
      </Typography>
    </StyledButton>
  );
};
