import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IUploadedFile } from "lib/model";
import { OrderFileType, OrderType, ProductionType } from "./types";
import { FileType, OrderItemType } from "lib/enum";
import { PreferencesType } from "lib/types";
import {
  IExtCaseWithCaseProgress,
  IExtOrderWithItemsAndTasksDto,
} from "lib/dto";
import {
  caseService,
  fileService,
  orderService,
  userSettingsService,
} from "services";
import { URLStored } from "utils/URLStored";

import { RootState } from "../store";
import { actions } from "../shipping";

const FILES_SAVE_ERROR =
  "Order was saved, but files could not be uploaded, pls try to upload them later or contact us";

export const toggleArch = createAction<"lowerArch" | "upperArch">(
  "order/set/arch"
);

export const setFiles = createAction<{
  files: Omit<IUploadedFile, "id">[];
  type: OrderFileType;
}>("order/files/set");
export const deleteFile = createAction<{
  fileName: string;
  type: OrderFileType;
}>("order/file/delete");

export const setStepsType = createAction<string>("order/stepsType/set");
export const setCustomSteps = createAction<string>("order/customSteps/set");
export const setShowVerification = createAction<boolean>(
  "order/showVerification/set"
);
export const setRequestedRetainers = createAction<string>(
  "order/requestedSteps/set"
);
export const setNotes = createAction<string>("order/notes/set");
export const setRetainers = createAction<string>("order/retainers/set");
export const setRequired = createAction("order/required/set");
export const setOrderItem = createAction<
  Partial<Record<OrderItemType, number>>
>("order/orderItem/set");
export const setReason = createAction<string>("order/reason/set");
export const clearState = createAction("order/clear");
export const setSendScansViaMeditLink = createAction<boolean>(
  "order/isSendScansViaMeditLink/set"
);
export const setRemoveAttachment = createAction<boolean>(
  "order/removeAttachment/set"
);

export const downloadFile = createAsyncThunk<
  {
    src: string;
    fileId: string;
    fileType: "clinicalPhotos" | "commonFiles" | "verificationKeys";
  },
  {
    fileId: string;
    controller: AbortController;
    contentType: string;
    fileType: "clinicalPhotos" | "commonFiles" | "verificationKeys";
  }
>(
  "order/file/download",
  async ({ fileType, fileId, controller, contentType }) => {
    const src = await fileService.getFile(
      fileId,
      contentType,
      controller.signal
    );
    return {
      src,
      fileId,
      fileType,
    };
  }
);

export const fetchOrderInfo = createAsyncThunk<
  {
    orderInfo: IExtOrderWithItemsAndTasksDto;
    caseInfo: IExtCaseWithCaseProgress;
    clinicalPhotos: IUploadedFile[];
    commonFiles: IUploadedFile[];
    verificationKeys: IUploadedFile[];
  },
  string
>("order/current/fetch", async (caseId) => {
  const caseInfo = await caseService.getCaseWithOfficeAndProgressById(caseId);
  const orderInfo = await orderService.getOrderWithItemsAndTasksById(
    caseInfo.currentOrderId!
  );
  const files = await orderService.getFilesByOrderId(caseInfo.currentOrderId!);
  const groupedFiles = files.reduce(
    (prevFiles, item) => {
      if (
        item.fileType === FileType.CLINICAL_PHOTO ||
        item.fileType === FileType.COMMON ||
        item.fileType === FileType.VERIFICATION_KEY
      ) {
        prevFiles[item.fileType].push({
          id: item.id.toString(),
          name: item.name,
          contentType: item.contentType!,
          unDeletable: true,
          src: "",
        });
      }

      return prevFiles;
    },
    {
      [FileType.CLINICAL_PHOTO]: [] as IUploadedFile[],
      [FileType.COMMON]: [] as IUploadedFile[],
      [FileType.VERIFICATION_KEY]: [] as IUploadedFile[],
    }
  );
  return {
    orderInfo,
    caseInfo,
    commonFiles: groupedFiles[FileType.COMMON],
    verificationKeys: groupedFiles[FileType.VERIFICATION_KEY],
    clinicalPhotos: groupedFiles[FileType.CLINICAL_PHOTO],
  };
});

export const fetchCaseOrderInfo = createAsyncThunk<
  {
    orderInfo: IExtOrderWithItemsAndTasksDto;
    caseInfo: IExtCaseWithCaseProgress;
    preferences: PreferencesType;
  },
  string
>("order/info/fetch", async (caseId, thunkAPI) => {
  const { currentUser } = thunkAPI.getState() as RootState;
  const preferences = await userSettingsService.getPreferencesByUser(
    currentUser.currentUser!.userId
  );
  const caseInfo = await caseService.getCaseWithOfficeAndProgressById(caseId);
  const orderInfo = await orderService.getOrderWithItemsAndTasksById(
    caseInfo.currentOrderId!
  );
  return {
    preferences,
    orderInfo,
    caseInfo,
  };
});

export const createOrder = createAsyncThunk<{
  orderId: number;
  filesSaveError?: string;
}>("order/create/send", async (_, thunkAPI) => {
  const { newOrder } = thunkAPI.getState() as RootState;

  const { id } = await orderService.createOrder({
    removeAttachment: newOrder.removeAttachment,
    useMeditLink: newOrder.useMeditLink,
    caseId: newOrder.caseInfo!.id,
    threeDVisualization: false,
    lowerArch: newOrder.lowerArch,
    upperArch: newOrder.upperArch,
    note: "",
    specialInstruction: newOrder.orderNote,
    requestedRetainers:
      newOrder.productionType === ProductionType.RETAINER ||
      newOrder.productionType === ProductionType.BOTH
        ? newOrder.requestedRetainers
        : 0,
    orderTypeId: newOrder.orderItems.IMPRESSION
      ? OrderType.CONTINUATION_NEW_IMPR
      : OrderType.CONTINUATION_EXIST_IMPR,
    productionType:
      newOrder.productionType === ProductionType.ALIGNER ||
      newOrder.productionType === ProductionType.BOTH,
    orderItems: Object.entries(newOrder.orderItems).map(([key, value]) => ({
      itemType: key as OrderItemType,
      qty: value,
    })),
    alignersMaxQty:
      newOrder.productionType === ProductionType.ALIGNER ||
      newOrder.productionType === ProductionType.BOTH
        ? newOrder.customStepsNum
        : 0,
  });
  if (id && newOrder.reason) {
    await orderService.addNoteToOrder(id, newOrder.reason);
  }
  try {
    if (newOrder.clinicalPhotos.length > 0) {
      await orderService.uploadOrderFiles(
        id,
        newOrder.clinicalPhotos.map(({ src, name }) => ({
          blob: URLStored.getBlobByObjectURL(src),
          name,
        })),
        FileType.CLINICAL_PHOTO
      );
    }

    if (newOrder.verificationKeys.length > 0) {
      await orderService.uploadOrderFiles(
        id,
        newOrder.verificationKeys.map(({ src, name }) => ({
          blob: URLStored.getBlobByObjectURL(src),
          name,
        })),
        FileType.VERIFICATION_KEY
      );
    }

    if (newOrder.commonFiles.length > 0) {
      await orderService.uploadOrderFiles(
        id,
        newOrder.commonFiles.map(({ src, name }) => ({
          blob: URLStored.getBlobByObjectURL(src),
          name,
        })),
        FileType.COMMON
      );
    }
  } catch (e: any) {
    return {
      orderId: id,
      filesSaveError: FILES_SAVE_ERROR,
    };
  }
  return {
    orderId: id,
  };
});

export const updateOrder = createAsyncThunk<{
  orderId: number;
  filesSaveError?: string;
}>("order/update/send", async (_, thunkAPI) => {
  const { newOrder } = thunkAPI.getState() as RootState;
  const orderDto = await orderService.getOrderById(newOrder.orderId!);
  await orderService.updateOrder({
    ...orderDto,
    lowerArch: newOrder.lowerArch,
    upperArch: newOrder.upperArch,
    note: newOrder.orderNote,
    requestedRetainers:
      newOrder.productionType === ProductionType.RETAINER ||
      newOrder.productionType === ProductionType.BOTH
        ? newOrder.requestedRetainers
        : 0,
    orderTypeId: newOrder.orderItems.IMPRESSION
      ? OrderType.CONTINUATION_NEW_IMPR
      : OrderType.CONTINUATION_EXIST_IMPR,
    productionType: newOrder.productionType === ProductionType.ALIGNER,
    orderItems: Object.entries(newOrder.orderItems).map(([key, value]) => ({
      itemType: key as OrderItemType,
      qty: value,
    })),
    alignersMaxQty:
      newOrder.productionType === ProductionType.ALIGNER ||
      newOrder.productionType === ProductionType.BOTH
        ? newOrder.customStepsNum
        : 0,
  });

  if (newOrder.orderId && newOrder.reason) {
    await orderService.addNoteToOrder(newOrder.orderId, newOrder.reason);
  }

  try {
    if (newOrder.clinicalPhotos.length > 0) {
      await orderService.uploadOrderFiles(
        newOrder.orderId!,
        newOrder.clinicalPhotos
          .filter(({ unDeletable }) => !unDeletable)
          .map(({ src, name }) => ({
            blob: URLStored.getBlobByObjectURL(src),
            name,
          })),
        FileType.CLINICAL_PHOTO
      );
    }

    if (newOrder.verificationKeys.length > 0) {
      await orderService.uploadOrderFiles(
        newOrder.orderId!,
        newOrder.verificationKeys
          .filter(({ unDeletable }) => !unDeletable)
          .map(({ src, name }) => ({
            blob: URLStored.getBlobByObjectURL(src),
            name,
          })),
        FileType.VERIFICATION_KEY
      );
    }

    if (newOrder.commonFiles.length > 0) {
      await orderService.uploadOrderFiles(
        newOrder.orderId!,
        newOrder.commonFiles
          .filter(({ unDeletable }) => !unDeletable)
          .map(({ src, name }) => ({
            blob: URLStored.getBlobByObjectURL(src),
            name,
          })),
        FileType.COMMON
      );
    }
  } catch (e: any) {
    return {
      orderId: newOrder.orderId!,
      filesSaveError: FILES_SAVE_ERROR,
    };
  }
  return {
    orderId: newOrder.orderId!,
  };
});

export const submitOrder = createAsyncThunk<{
  orderId: number;
  filesSaveError?: string;
}>("order/submit/send", async (_, thunkAPI) => {
  const { newOrder } = thunkAPI.getState() as RootState;
  if (!newOrder.orderId) {
    const { orderId, filesSaveError } = await thunkAPI
      .dispatch(createOrder())
      .unwrap();

    if (!filesSaveError) {
      await orderService.submitOrderToLab(orderId);
    }

    return { orderId, filesSaveError };
  }
  const { filesSaveError } = await thunkAPI.dispatch(updateOrder()).unwrap();
  if (!filesSaveError) {
    await orderService.submitOrderToLab(newOrder.orderId);
  }

  return {
    orderId: newOrder.orderId!,
    filesSaveError,
  };
});

export const createShippingForOrder = createAsyncThunk<string>(
  "order/shipping/create",
  async (_, thunkAPI) => {
    const { newOrder } = thunkAPI.getState() as RootState;
    return await thunkAPI
      .dispatch(actions.printShippingLabel(newOrder.orderId!))
      .unwrap();
  }
);
