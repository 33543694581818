import { UserPreferencesState } from "./types";

export const initState: UserPreferencesState = {
  isLoading: false,
  isSaving: false,
  additional: "",
  attachPreference: "",
  customStep: "",
  iprPreference: "",
  wearPreference: "",
  shippingLevel: "",
  shippingRate: null,
};
