import React, { ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import { Colors } from "consts/colors";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles/createTheme";

interface Props {
  title: string;
  text: ReactNode;
  sx?: SxProps<Theme>;
}

const StyledContainer = styled(Stack)`
  background-color: #eef9fe;
  padding-block: 20px;
  padding-inline: 24px;
  min-height: 263px;
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
`;
export const InformBlock: React.VFC<Props> = ({ title, text, sx }) => {
  return (
    <StyledContainer sx={sx} spacing={1}>
      <Typography variant="h2" fontWeight="bold" color={Colors.SECONDARY}>
        {title}
      </Typography>
      <Typography variant="body1" color={Colors.SECONDARY}>
        {text}
      </Typography>
    </StyledContainer>
  );
};
