import React from "react";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/treatmentPlan";
import { StepsArea } from "./StepsArea";

export const StepsTableAreas = () => {
  const treatmentPlanInstructions = useAppSelector(
    selectors.planInstructionsSelector
  );
  const rows = useAppSelector(selectors.tableRowsSelector);
  return (
    <>
      {treatmentPlanInstructions.map((plan: any, index: number, arr: any[]) => {
        const lastItem = arr[arr.length - 1]?.id ?? arr[arr.length - 2]?.id;
        return (
          <StepsArea
            isLastOrder={plan.id === lastItem}
            collapse={plan.autoGenerated}
            key={plan.id || `last_area_${index}`}
            itemIndex={index}
            plan={plan}
            row={rows}
          />
        );
      })}
    </>
  );
};
