import { IconButton } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";


export const CloseNoticeButton: React.FC<{ snackbarKey: SnackbarKey }> = ({
  snackbarKey,
}) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => closeSnackbar(snackbarKey)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}
