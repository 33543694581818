import React from "react";
import { TableHeader } from "./TableHeader";
import { Legend } from "../Legend";
import { StepsTableAreas } from "./StepsTableAreas";
import { NotesList, NotesHeader } from "../Notes";
import {
  PlanTableScrolledContainer,
  PlanTableContainer,
} from "./TableElements";
import GeneralFBMLine from "../GeneralFBMLine";
import { GeneralParams } from "./GeneralParams";

export const StepsTable: React.VFC = () => {
  return (
    <PlanTableContainer>
      <PlanTableScrolledContainer>
        <TableHeader />
        <Legend />
        <NotesHeader />
        <GeneralParams />
        <GeneralFBMLine />
        <NotesList />
        <StepsTableAreas />
      </PlanTableScrolledContainer>
    </PlanTableContainer>
  );
};
