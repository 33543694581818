import { styled } from "@mui/material/styles";
import { Colors } from "consts/colors";

export const UploadContainer = styled("div")`
  display: flex;
  height: 110px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${Colors.SECONDARY};
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
`;

export const UploadLayout = styled("div")`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  &.canDrop {
    background-color: ${Colors.NOTICE_SUCCESS};
    opacity: 0.5;
  }
`;
