import React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ConfirmOptions } from "./types";
import { IconButton, Stack } from "@mui/material";
import Close from "@mui/icons-material/Close";


interface ConfirmDialogProps {
  open: boolean;
  options: ConfirmOptions;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: DialogProps["onClose"];
}
const ConfirmationDialog: React.VFC<ConfirmDialogProps> = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    actionProps: proxyActionProps,
    clickableContent,
  } = options;

  const actionChildren = proxyActionProps?.children ?? null;
  const actionProps = proxyActionProps
    ? { ...proxyActionProps, children: undefined }
    : undefined;
  return (
    <Dialog
      fullWidth
      {...dialogProps}
      open={open}
      onClose={allowClose ? onClose : undefined}
    >
      {title && (
        <DialogTitle {...titleProps}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {title}
            {allowClose && (
              <IconButton
                onClick={(event) => {
                  onClose && onClose(event, "backdropClick")
                }}>
                <Close />
              </IconButton>
            )}
          </Stack>
        </DialogTitle>
      )}
      {content ? (
        <DialogContent
          onClick={clickableContent ? onConfirm : undefined}
          {...contentProps}
        >
          {content}
        </DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )
      )}
      <DialogActions {...actionProps}>
        {actionChildren}
        <Button {...cancellationButtonProps} onClick={onCancel}>
          {cancellationText}
        </Button>
        <Button
          color="primary"
          {...confirmationButtonProps}
          onClick={onConfirm}
        >
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
