import React, { useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

import { ITreatmentPlanStepInstructionDto } from "lib/dto";
import { TpInstructions } from "lib/enum";
import "./styles.css";

import { getIcons } from "./helpers";
import { ICellIcon } from "./types";
import { TooltipCell } from "./TooltipCell";
import { CellContainer } from "./CellContainer";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/treatmentPlan";

interface Props {
  cells: ITreatmentPlanStepInstructionDto;
  type: "uppers" | "lowers";
  side: "left" | "right";
  disabled: boolean;
  planned: boolean;
  showPin: boolean;
  hovered?: boolean;
  rowNum: number;
  cellNum: number;
}
const Cells = React.memo((props: Props) => {
  const cellRef = useRef<any>(null);
  const { selectingToothKey } = useAppSelector(selectors.getAllInfo);
  if (!props.cells) {
    return null;
  }

  const icons: ICellIcon[] = getIcons(props.cells, props.type, props.side);
  const fbmIcons = icons.find(
    ({ field }) => field === TpInstructions.fullBodyMovement
  );
  return (
    <CellContainer
      cellSelected={selectingToothKey[`${props.cellNum}_${props.rowNum}`]}
      disabled={props.disabled}
      planned={props.planned}
      cellNum={props.cellNum}
      rowNum={props.rowNum}
      treatmentPlanStepId={props.cells.treatmentPlanStepId}
    >
      {props.showPin && props.cells.pinNumber !== null ? (
        icons.length ? (
          <Tooltip
            title={
              <>
                <TooltipCell icons={icons} />
              </>
            }
            placement="top"
          >
            <div className="cell__sub cell__sub-pin">
              <div className="cell_flexible">
                <div
                  className={
                    Boolean(fbmIcons)
                      ? "cell__pin-number-with-icon"
                      : "cell__pin-number"
                  }
                >
                  {props.cells.pinNumber}
                </div>
                {fbmIcons ? (
                  <fbmIcons.componentParams.Component
                    {...fbmIcons.componentParams.props}
                    className="cell__pin-fbm"
                    classNameContainer="cell__pin-fbm-container"
                  />
                ) : null}
                {props.cells.reversed && (
                  <span className="cell__pin-rContainer">
                    <Typography
                      fontSize={10}
                      lineHeight={1}
                      className="cell__pin_reversed"
                    >
                      R
                    </Typography>
                  </span>
                )}
              </div>
            </div>
          </Tooltip>
        ) : (
          <div className="cell__sub cell__sub-pin">
            <div className="cell_flexible">
              <div className="cell__pin-number">{props.cells.pinNumber}</div>
              {props.cells.reversed && (
                <span className="cell__pin-rContainer">
                  <Typography
                    fontSize={10}
                    lineHeight={1}
                    className="cell__pin_reversed"
                  >
                    R
                  </Typography>
                </span>
              )}
            </div>
          </div>
        )
      ) : icons.length ? (
        <Tooltip
          title={
            <>
              <TooltipCell icons={icons} />
            </>
          }
          placement="top"
        >
          <div
            ref={cellRef}
            className={clsx("cell__sub", {
              cell__sub_hovered: props.hovered,
            })}
          >
            <div className="cell_flexible" style={{ flexWrap: "wrap" }}>
              {icons
                .filter(
                  ({ field }) => field !== TpInstructions.fullBodyMovement
                )
                .slice(0, 2)
                .map(
                  (
                    { field, componentParams: { Component, props } },
                    index,
                    arr
                  ) => {
                    return (
                      <Box
                        key={`${index}_${field}`}
                        alignSelf={
                          index === 1 && arr.length > 1
                            ? "flex-start"
                            : arr.length === 1
                            ? "center"
                            : "flex-end"
                        }
                        height={arr.length > 1 ? "50%" : undefined}
                        width={arr.length > 1 ? "50%" : undefined}
                      >
                        <Component {...props} />
                      </Box>
                    );
                  }
                )}
              {icons
                .filter(
                  ({ field }) => field === TpInstructions.fullBodyMovement
                )
                .map(
                  ({ field, componentParams: { Component, props } }, index) => {
                    return (
                      <div className="cell__fbm" key={`${index}_${field}`}>
                        <Component {...props} />
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </Tooltip>
      ) : (
        <div
          ref={cellRef}
          className={clsx("cell__sub", {
            cell__sub_hovered: props.hovered,
          })}
        />
      )}
    </CellContainer>
  );
});

export default React.memo(Cells);
