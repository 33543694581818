export enum AttachmentType {
  STANDARD = "Standard",
  APICAL = "Apical",
  UE = "UE",
  STANDARD_PRODUCE = "Standard - produce",
  APICAL_PRODUCE = "Apical - produce",
  UE_PRODUCE = "UE - produce",
  STANDARD_EMBED = "Standard - embed",
  APICAL_EMBED = "Apical - embed",
  UE_EMBED = "UE - embed",
}
