import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";

import { OfficeSettingType } from "store/accountSettings/types";
import { actions, selectors } from "store/accountSettings";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { OfficeSettings } from "lib/enum";
import { Colors } from "consts/colors";
import { TextField } from "components/ui";

import { StyledFormLabel } from "./Elements";

interface Props {
  officeSettings: OfficeSettingType;
}
export const OfficeSettingsItem: React.VFC<Props> = ({ officeSettings }) => {
  const dispatch = useAppDispatch();
  const { isSaving } = useAppSelector(selectors.getAllInfoSelector);
  const notifyPerInvoice = officeSettings[OfficeSettings.NOTIFY_PER_INVOICE];
  const notifyOutstandingInvoice =
    officeSettings[OfficeSettings.NOTIFY_OUTSTANDING_INVOICE];
  const notifyType = React.useMemo(() => {
    if (notifyPerInvoice && notifyPerInvoice !== "false") {
      return OfficeSettings.NOTIFY_PER_INVOICE;
    }
    if (
      typeof notifyOutstandingInvoice === "string" &&
      notifyOutstandingInvoice !== "false"
    ) {
      return OfficeSettings.NOTIFY_OUTSTANDING_INVOICE;
    }
    return "";
  }, [notifyPerInvoice, notifyOutstandingInvoice]);

  const treshold = React.useMemo(() => {
    if (notifyOutstandingInvoice !== "false") {
      return notifyOutstandingInvoice || "";
    }
    return "";
  }, [notifyOutstandingInvoice]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setOfficeSetting({
        officeId: officeSettings.officeId,
        value: event.target.value as any,
      })
    );
  };

  const changeTreshold = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setOfficeTreshold({
        officeId: officeSettings.officeId,
        value: event.target.value as any,
      })
    );
  };
  return (
    <FormControl sx={{ marginTop: 5, marginBottom: 3 }}>
      <FormLabel
        sx={{ fontWeight: 600, color: Colors.TEXT_PRIMARY, marginBottom: 2 }}
      >
        Notification settings
      </FormLabel>
      <RadioGroup value={notifyType} onChange={changeHandler}>
        <StyledFormLabel
          disabled={isSaving}
          value={OfficeSettings.NOTIFY_PER_INVOICE}
          control={<Radio />}
          componentsProps={{ typography: { fontSize: 12 } }}
          label="Notify per invoice"
        />
        <Stack direction="row" spacing={6}>
          <StyledFormLabel
            disabled={isSaving}
            value={OfficeSettings.NOTIFY_OUTSTANDING_INVOICE}
            control={<Radio />}
            componentsProps={{ typography: { fontSize: 12 } }}
            label="Notify if outstanding invoices exceed"
          />
          <TextField
            type="number"
            sx={{ width: 110 }}
            value={treshold}
            onChange={changeTreshold}
            error={
              notifyType === OfficeSettings.NOTIFY_OUTSTANDING_INVOICE &&
              !treshold
            }
            disabled={
              notifyType !== OfficeSettings.NOTIFY_OUTSTANDING_INVOICE ||
              isSaving
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};
