import React, { useState } from "react";
import { OverlayContainer, PopoverContainer, StyledButton } from "./Elements";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAppSelector } from "hooks/redux";
import { ToothArches } from "components/common";
import { Progress } from "components/ui";
import { selectors } from "store/patientDetail";
import { ArchCompleteStatus } from "lib/enum";

export const BreakdownWindow = () => {
  const { caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const [open, setOpen] = useState<boolean>(false);

  const upperStep =
    caseInfo?.lastUpperEstimatedSteps || caseInfo?.upperEstimatedSteps;
  const lowerStep =
    caseInfo?.lastLowerEstimatedSteps || caseInfo?.lowerEstimatedSteps;

  const openHandler = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };
  return (
    <>
      <OverlayContainer display={open ? "flex" : "none"}>
        <PopoverContainer>
          <Box display="flex" justifyContent="flex-end">
            <IconButton size="small" onClick={closeHandler}>
              <CloseIcon sx={{ fontSize: 16 }} fontSize="small" />
            </IconButton>
          </Box>
          <Stack spacing={3} px={8} pb={1}>
            <Stack direction="row" spacing={3} alignItems="flex-start">
              <Stack spacing={1}>
                <Typography variant="body2" fontWeight={500}>
                  Upper progress (Est. {upperStep ?? "N/A"})
                </Typography>
                <Progress progress={caseInfo?.upperCaseProgress}>
                  {caseInfo?.archCompleteStatus ===
                  ArchCompleteStatus.UPPER_COMPLETED
                    ? "Complete"
                    : null}
                </Progress>
              </Stack>
              <ToothArches width={140} height={216} lowerArch={false} />
            </Stack>
            <Stack direction="row" spacing={3}>
              <Stack spacing={1}>
                <Typography variant="body2" fontWeight={500}>
                  Lower progress (Est. {lowerStep ?? "N/A"})
                </Typography>
                <Progress progress={caseInfo?.lowerCaseProgress}>
                  {caseInfo?.archCompleteStatus ===
                  ArchCompleteStatus.LOWER_COMPLETED
                    ? "Complete"
                    : null}
                </Progress>
              </Stack>
              <ToothArches width={140} height={216} upperArch={false} />
            </Stack>
          </Stack>
        </PopoverContainer>
      </OverlayContainer>
      <StyledButton
        data-intercom-target="Breakdown view button"
        onClick={openHandler}
        underline="none"
      >
        See breakdown
      </StyledButton>
    </>
  );
};
