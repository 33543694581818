import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

interface Props {
  collapse?: boolean;
}
export const CollapseBlock: React.FC<Props> = ({ children, collapse }) => {
  const [open, setOpen] = useState<boolean>(true);
  const toggleOpen = () => setOpen((oldOpen) => !oldOpen);
  if (collapse) {
    return (
      <>
        <Box px={1}>
          <IconButton size="small" color="primary" onClick={toggleOpen}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </>
    );
  }

  return <>{children}</>;
};
