import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const BoxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path
        d="M10.0152 3.70832L5.01515 6.9015L10.0152 10.0947L5.01515 13.2879L0 10.0644L5.01136 6.8712L0 3.70832L5.01136 0.515137L10.0152 3.70832ZM4.98485 14.2917L9.98485 11.0985L14.9848 14.2917L9.98485 17.4848L4.98485 14.2917V14.2917ZM10.0152 10.0644L15.0152 6.8712L10.0152 3.70453L14.9886 0.515137L20 3.70832L14.9886 6.9015L20 10.0909L14.9886 13.2841L10.0152 10.0644Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
