import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";

import { useStyledSnackbar } from "hooks/notification";
import { Button, Menu } from "components/ui";
import { useAppDispatch } from "hooks/redux";
import { actions } from "store/treatmentPlan";

import {
  StyledNoteInput,
  StyledNotesItem,
  NotesToSelector,
} from "./NoteElements";

export const NoteAddForm = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const [message, setMessage] = useState<string>("");
  const [forDoctor, setForDoctor] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const items = [
    {
      label: "Technician",
      handler: () => setForDoctor(false),
    },
    {
      label: "Doctor",
      handler: () => setForDoctor(true),
    },
  ];

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };
  const closeHandler = () =>
    dispatch(actions.openToShow({ noteIds: [], addNote: false }));
  const submitHandler = () => {
    setLoading(true);
    dispatch(
      actions.createNote({
        note: message,
        forDoctor,
      })
    )
      .unwrap()
      .catch((e) => {
        showErrorNotice(e.message);
        setLoading(false);
      });
  };
  return (
    <>
      <Divider />
      <StyledNotesItem>
        <Stack
          justifyContent="space-between"
          alignItems="baseline"
          direction="row"
        >
          <Typography variant="body2">To:</Typography>

          <Menu items={items}>
            <NotesToSelector size="small" endIcon={<ExpandMoreIcon />}>
              {forDoctor ? "Doctor" : "Technician"}
            </NotesToSelector>
          </Menu>
        </Stack>
        <Box>
          <StyledNoteInput
            margin="dense"
            placeholder="Add note"
            multiline
            rows={2}
            value={message}
            onChange={changeHandler}
          />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          py={2}
        >
          <Button onClick={closeHandler} disableElevation variant="outlined">
            Cancel
          </Button>
          <Button
            loading={loading}
            disableElevation
            variant="contained"
            color="primary"
            onClick={submitHandler}
          >
            Save
          </Button>
        </Stack>
      </StyledNotesItem>
    </>
  );
};
