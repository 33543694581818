import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const GoogleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="25" viewBox="0 0 24 25" fill="none">
      <rect
        width="24"
        height="24"
        transform="translate(0 0.537109)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.04 12.7984C23.04 11.983 22.9668 11.1989 22.8309 10.4462H12V14.8946H18.1891C17.9225 16.3321 17.1123 17.55 15.8943 18.3655V21.2509H19.6109C21.7855 19.2489 23.04 16.3007 23.04 12.7984Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 24.0369C15.1049 24.0369 17.7081 23.0072 19.6108 21.2508L15.8942 18.3653C14.8644 19.0553 13.5472 19.4631 11.9999 19.4631C9.00467 19.4631 6.46945 17.4401 5.56513 14.7219H1.72308V17.7015C3.61536 21.4599 7.50445 24.0369 11.9999 24.0369Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56523 14.7222C5.33523 14.0322 5.20455 13.2952 5.20455 12.5372C5.20455 11.7793 5.33523 11.0422 5.56523 10.3522V7.37268H1.72318C0.944318 8.92518 0.5 10.6815 0.5 12.5372C0.5 14.3929 0.944318 16.1493 1.72318 17.7018L5.56523 14.7222Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 5.61097C13.6883 5.61097 15.2042 6.1912 16.396 7.33075L19.6944 4.03234C17.7029 2.17665 15.0997 1.03711 11.9999 1.03711C7.50445 1.03711 3.61536 3.61415 1.72308 7.37256L5.56513 10.3521C6.46945 7.63393 9.00468 5.61097 11.9999 5.61097Z"
        fill="#EA4335"
      />
    </SvgIcon>
  );
};
