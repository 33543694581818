import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PaginationItemsCountType } from "./types";
import { PaginationItemsCountContainer } from "./Elements";
import { Menu } from "components/ui";

const DEFAULT_ITEMS_COUNT = [8, 10, 25, 100];
export const PaginationItemsCount = ({
  onChange,
  initItemsCount,
  itemsCount,
}: PaginationItemsCountType): JSX.Element => {
  const items = (initItemsCount ?? DEFAULT_ITEMS_COUNT).map(
    (itemsCount: number) => {
      return {
        label: String(itemsCount),
        handler: () => {
          onChange(itemsCount);
        },
      };
    }
  );
  return (
    <Menu items={items}>
      <PaginationItemsCountContainer>
        <span>{itemsCount}</span>
        <span className="text">rows</span>
        <ArrowDropDownIcon />
      </PaginationItemsCountContainer>
    </Menu>
  );
};
