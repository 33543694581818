import { createAsyncThunk } from "@reduxjs/toolkit";
import { IExtEventDashboardDto } from "lib/dto";
import { DashboardActions } from "./types";
import { dashboardService } from "services";

export const fetchDashboardEvents = createAsyncThunk<IExtEventDashboardDto[]>(
  DashboardActions.DASHBOARD_EVENTS_FETCHED,
  async () => {
    return await dashboardService.fetch();
  }
);

export const pinDashboardEvent = createAsyncThunk<number, number>(
  DashboardActions.DASHBOARD_EVENT_PINNED,
  async (eventId: number) => {
    await dashboardService.pinEvent(eventId);
    return eventId;
  }
);
export const remindLater = createAsyncThunk<
  number,
  {
    dashboardEventId: number;
    dayCount: number;
  }
>(
  DashboardActions.DASHBOARD_EVENT_REMIND,
  async ({ dashboardEventId, dayCount }) => {
    await dashboardService.remindLaterEvent(dashboardEventId, dayCount);
    return dashboardEventId;
  }
);

export const dismissEvent = createAsyncThunk<number, number>(
  DashboardActions.DASHBOARD_EVENT_DISMISS,
  async (eventId: number) => {
    await dashboardService.doneEvent(eventId);
    return eventId;
  }
);
