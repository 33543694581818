import React from "react";
import Box from "@mui/material/Box";

import { useAppSelector, useAppDispatch } from "hooks/redux";
import { actions } from "store/requestRemake";

import { StyledButton } from "./Elements";
import { ShippingInfo } from "./ShippingInfo";

export const ShippingButton = () => {
  const dispatch = useAppDispatch();
  const toggleOpen = () => {
    dispatch(actions.setShippingCreate(true));
  };
  const impressions = useAppSelector(
    (state) => state.requestRemake.impressions
  );
  const shippingCreated = useAppSelector(
    (state) => state.requestRemake.shippingCreated
  );

  if (!impressions) return null;
  return (
    <>
      {!shippingCreated && (
        <Box alignSelf="flex-start" px={3} mt={1.5}>
          <StyledButton onClick={toggleOpen} variant="outlined">
            Create Shipment
          </StyledButton>
        </Box>
      )}
      {shippingCreated && <ShippingInfo />}
    </>
  );
};
