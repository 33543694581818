import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ListTableContainerProps } from "containers/listTable/types";
import { ArchCompleteStatus } from "lib/enum";
import { ICaseTabDto } from "lib/dto";
import { dateToString } from "utils";
import { caseService } from "services";
import { Progress } from "components/ui";
import { UpperComplete, LowerComplete } from "assets/vectorIcon";
import { Colors } from "consts/colors";

import { StatusColumn } from "../components/StatusColumn";

async function fetch(filter: any) {
  return await caseService.getActiveCases(filter);
}
const columns: ListTableContainerProps<ICaseTabDto>["columns"] = [
  {
    title: "Patient",
    targetLabel: "Patient",
    style: {
      headerCell: { justifyContent: "center" },
    },
    dataIndex: "patientFirstName",
    sort: true,
    onClick: () => {},
    render: (_: string, { patientFirstName, patientLastName }: ICaseTabDto) => {
      return (
        <Typography
          variant="body2"
          fontWeight={500}
          color={Colors.PRIMARY}
        >{`${patientFirstName} ${patientLastName}`}</Typography>
      );
    },
  },
  {
    title: "Last Activity",
    targetLabel: "Last Activity",
    dataIndex: "lastActivity",
    render: (lastActivity: string, { needPreEvaluation }: ICaseTabDto) => {
      return (
        <Typography variant="body2" fontWeight={500} color={Colors.PRIMARY}>
          {needPreEvaluation ? "Pre-Evaluation" : lastActivity}
        </Typography>
      );
    },
  },
  {
    title: "Last Activity Date",
    targetLabel: "Last Activity Date",
    dataIndex: "lastActivityDate",
    render: (date: string) => (date ? dateToString(date) : "N/A"),
    sort: true,
  },
  {
    title: "Case progress",
    targetLabel: "Case progress",
    dataIndex: "caseProgress",
    width: "170px",
    render: (progress: number, { archCompleteStatus }) => (
      <Stack spacing={1}>
        <Progress progress={progress} />
        {archCompleteStatus === ArchCompleteStatus.UPPER_COMPLETED && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <UpperComplete />
            <Typography
              variant="body2"
              color={Colors.SECONDARY}
              fontWeight={600}
            >
              Upper complete
            </Typography>
          </Stack>
        )}
        {archCompleteStatus === ArchCompleteStatus.LOWER_COMPLETED && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <LowerComplete />
            <Typography
              variant="body2"
              color={Colors.SECONDARY}
              fontWeight={600}
            >
              Lower complete
            </Typography>
          </Stack>
        )}
      </Stack>
    ),
    style: {
      rowCell: {
        padding: "12px 24px",
      },
    },
    sort: true,
  },
  {
    title: "Status",
    targetLabel: "Status",
    dataIndex: "caseTabAction",
    width: "250px",
    render: (_, info) => <StatusColumn caseInfo={info} />,
    style: {
      rowCell: {
        padding: "12px 24px",
      },
    },
    sort: true,
  },
];

export const activeListConf: ListTableContainerProps<ICaseTabDto> = {
  columns,
  emptyDataText: "No patient records found <At Office>",
  requests: { fetch },
  pagination: true,
};
