import React, { useMemo, useState } from "react";
import MailIcon from "@mui/icons-material/Mail";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";

import logo from "assets/images/orthosnap-menu-logo.png";
import { Button } from "components/ui";

import { StyledHeader, StyledImg, StyledIconButton } from "./Elements";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/currentUser";
import SearchBar from "./SearchBar";
import { UnreadMessagesList } from "./UnreadMessagesList";
import { DoctorModeButton } from "./DoctorModeButton";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { currentUser, unreadMessage } = useAppSelector(
    selectors.getAllUserInfo
  );

  const openHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.target as HTMLElement);
  };

  const closeHandler = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UnreadMessagesList anchorEl={anchorEl} onClose={closeHandler} />
      <StyledHeader>
        <Stack justifyContent="center">
          <StyledImg src={logo} alt="Orthosnap" />
        </Stack>
        <SearchBar />
        {currentUser?.doctorMode && <DoctorModeButton />}
        {unreadMessage.length > 0 ? (
          <Badge badgeContent={unreadMessage.length} color="error">
            <StyledIconButton
              data-intercom-target="Message notice button"
              onClick={openHandler}
            >
              <MailIcon sx={{ fontSize: 14 }} />
            </StyledIconButton>
          </Badge>
        ) : null}
        <Button
          sx={{
            color: "white",
            textTransform: "none",
            fontSize: "20px",
            flexShrink: 0,
          }}
        >
          {`Dr. ${currentUser?.lastName}`}
          <PersonIcon sx={{ marginInlineStart: 1 }} />
        </Button>
      </StyledHeader>
    </>
  );
};
