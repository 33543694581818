import { FC, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { caseService } from "services";
import { IInvoiceItemDto } from "lib/dto";
import { dateToString, toLocalCurrency } from "utils";
import { InfoWithLabel, LoadingOverlay } from "components/common";
import { InvoiceStatuses } from "lib/enum";
import { renderPaymentLink, renderStatus } from "pages/invoices/columns";
import BigNumber from "bignumber.js";
import { Checkbox, FormControlLabel, Stack, TableFooter } from "@mui/material";

export const Invoices: FC = () => {
  const { currentOrder } = useAppSelector(selectors.currentOrder);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IInvoiceItemDto[]>([]);
  const [sum, setSum] = useState({ total: 0, open: 0, paid: 0 });
  const [hideZeroAmount, setHide] = useState<boolean>(true);

  const fetch = async () => {
    setLoading(true);
    const data = await caseService.getCaseOrderInvoices(currentOrder!.caseId);
    const newData = data.filter(
      ({ status }) =>
        ![InvoiceStatuses.DRAFT, InvoiceStatuses.VOID].includes(status as any)
    );

    let total = new BigNumber(0);
    let paid = new BigNumber(0);
    let open = new BigNumber(0);

    newData.forEach(({ totalAmount, status }) => {
      total = total.plus(totalAmount);
      if (status === InvoiceStatuses.PAID) {
        paid = paid.plus(totalAmount);
      }
      if (status === InvoiceStatuses.OPEN) {
        open = open.plus(totalAmount);
      }
    });

    setSum({
      total: total.toNumber(),
      paid: paid.toNumber(),
      open: open.toNumber(),
    });

    setData(newData);
    setLoading(false);
  };

  const handleChange = (_: any, checked: boolean) => {
    setHide(checked);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                <FormControlLabel
                  onChange={handleChange}
                  control={<Checkbox />}
                  label="Hide $0 Invoices"
                  checked={hideZeroAmount}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <LoadingOverlay text="Please, wait load invoices" open={loading} />
            {data
              .filter(({ totalAmount }) =>
                hideZeroAmount ? totalAmount > 0 : true
              )
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.orderId}
                  </TableCell>
                  <TableCell>{dateToString(row.created)}</TableCell>
                  <TableCell>
                    {renderStatus(row.status as InvoiceStatuses, row)}
                  </TableCell>
                  <TableCell>{toLocalCurrency(row.totalAmount)}</TableCell>
                  <TableCell>
                    {renderPaymentLink(row.stripeUrl!, row as any)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <Stack direction="row" gap={1} p={2}>
              <InfoWithLabel
                label={"Total:"}
                text={`\$${sum.total.toFixed(2).toLocaleString()}`}
              />
              <InfoWithLabel
                label={"Open:"}
                text={`\$${sum.open.toFixed(2).toLocaleString()}`}
              />
              <InfoWithLabel
                label={"Paid:"}
                text={`\$${sum.paid.toFixed(2).toLocaleString()}`}
              />
            </Stack>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
