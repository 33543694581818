import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { InfoWithLabel } from "components/common";
import { useAppSelector } from "hooks/redux";
import { Colors } from "consts/colors";
import { selectors } from "store/accountSettings";

export const MainInfo = () => {
  const { userInfo, isLoading } = useAppSelector(selectors.getAllInfoSelector);
  return (
    <>
      <Typography
        ml={5}
        mt={4}
        variant="h1"
        color={Colors.PRIMARY}
        fontWeight={600}
      >
        Account Settings
      </Typography>
      <Stack mt={4} px={5} mb={6} direction="row" spacing={6}>
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          label="EMAIL"
          text={userInfo?.email || ""}
        />
        <InfoWithLabel
          direction="column"
          isLoading={isLoading}
          label="ROLE"
          text={userInfo?.role || ""}
        />
      </Stack>
    </>
  );
};
