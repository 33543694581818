import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";

import { useAppDispatch } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions } from "store/shipping";

import { ShippingMainInfo, InfoEditFields } from "./components";

interface Props {
  orderId: number | string;
}
export const ShippingCreateContainer: React.VFC<Props> = ({ orderId }) => {
  const { showErrorNotice } = useStyledSnackbar();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.fetchShippingInfo(Number(orderId)))
      .unwrap()
      .catch((e) => showErrorNotice(e.message));
  }, [dispatch, orderId, showErrorNotice]);

  return (
    <Stack>
      <InfoEditFields />
      <ShippingMainInfo />
    </Stack>
  );
};
