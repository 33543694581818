import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { MainContainer, TabsContainer } from "containers";

import { useStyledSnackbar } from "hooks/notification";
import { invoiceService } from "services";
import {
  IExtOrderInvoiceDtoForFilterView,
  IOrderInvoiceCountDto,
} from "lib/dto";
import { InvoiceStatuses } from "lib/enum";

import { columnsWithPayColumn } from "./columns";
import { TabsContainerProps } from "containers/tabs/types";
import { HeaderBlock } from "./components/Header";

const filters = [
  "officeId",
  "dueDateTo",
  { name: "orderType", initValue: "DESC" },
  { name: "orderBy", initValue: "dueDate" },
];

export const InvoicesPage = () => {
  const { showErrorNotice } = useStyledSnackbar();
  const [searchParams] = useSearchParams();
  const [office, setOffice] = useState<string>(
    searchParams.get("officeId") || ""
  );
  const [counts, setCounts] = useState<IOrderInvoiceCountDto>({
    allCount: 0,
    paidCount: 0,
    overdueCount: 0,
  });

  useEffect(() => {
    invoiceService
      .getTabsCount()
      .then(setCounts)
      .catch((error) => showErrorNotice(error.message));
  }, [showErrorNotice]);

  const fetchAll = useCallback(
    async (filter: any) => {
      return await invoiceService.fetch({
        ...filter,
        officeId: office,
      });
    },
    [office]
  );

  const fetchOpen = useCallback(
    async (filter: any) => {
      return await invoiceService.fetch({
        ...filter,
        status: InvoiceStatuses.OPEN,
        officeId: office,
      });
    },
    [office]
  );
  const fetchPaid = useCallback(
    async (filter: any) => {
      return await invoiceService.fetch({
        ...filter,
        status: InvoiceStatuses.PAID,
        officeId: office,
      });
    },
    [office]
  );

  const tabs: TabsContainerProps<IExtOrderInvoiceDtoForFilterView>["tabs"] = [
    {
      label: "All",
      badge: counts.allCount,
      list: {
        filterField: filters,
        columns: columnsWithPayColumn,
        requests: {
          fetch: fetchAll,
        },
        pagination: true,
      },
    },
    {
      label: "Open",
      badge: counts.overdueCount,
      list: {
        filterField: filters,
        columns: columnsWithPayColumn,
        requests: {
          fetch: fetchOpen,
        },
        pagination: true,
      },
    },
    {
      label: "Paid",
      badge: counts.paidCount,
      list: {
        filterField: filters,
        columns: columnsWithPayColumn,
        requests: {
          fetch: fetchPaid,
        },
        pagination: true,
      },
    },
  ];

  return (
    <MainContainer>
      <HeaderBlock office={office} onChangeOffice={setOffice} />
      <TabsContainer tabs={tabs} />
    </MainContainer>
  );
};
