import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CreateOrderState, ProductionType } from "./types";
import { ICategoryDto } from "lib/dto";
import { ArchCompleteStatus, CaseStatuses, CategoryType } from "lib/enum";

const selectSelf = (state: RootState) => state.newOrder;

export const getAllInfoSelector = createSelector(
  selectSelf,
  (state: CreateOrderState) => state
);

export const getFilesSelector = createSelector(
  selectSelf,
  (state: CreateOrderState) => ({
    clinicalPhotos: state.clinicalPhotos,
    commonFiles: state.commonFiles,
    verificationKeys: state.verificationKeys,
  })
);

export const getArchesSelector = createSelector(
  selectSelf,
  (state: CreateOrderState) => ({
    lower: state.lowerArch,
    upper: state.upperArch,
  })
);

export const requiredFields = createSelector(
  selectSelf,
  (state) => state.requiredFields
);

export const requiredFieldsFilled = createSelector(
  selectSelf,
  (state: CreateOrderState) => {
    if (!state.lowerArch && !state.upperArch) {
      return false;
    }

    return (
      state.productionType === ProductionType.ALIGNER ||
      Boolean(state.requestedRetainers)
    );
  }
);

export const needVKFilesSelector = createSelector(
  selectSelf,
  (state: CreateOrderState) => {
    return (
      state.previousOrder?.categories?.some(
        ({ type }: ICategoryDto) => type === CategoryType.NON_COMPLIANCE
      ) ||
      state.caseInfo?.categories?.some(
        ({ type }: ICategoryDto) => type === CategoryType.NON_COMPLIANCE
      )
    );
  }
);

export const showCompleteArchWarning = createSelector(
  selectSelf,
  (state: CreateOrderState) => {
    if (
      state.caseInfo?.archCompleteStatus ===
        ArchCompleteStatus.UPPER_COMPLETED &&
      state.upperArch
    ) {
      return true;
    }

    return (
      state.caseInfo?.archCompleteStatus ===
        ArchCompleteStatus.LOWER_COMPLETED && state.lowerArch
    );
  }
);

export const showCompleteCaseWarning = createSelector(
  selectSelf,
  (state: CreateOrderState) => {
    return (
      state.caseInfo?.status === CaseStatuses.COMPLETED &&
      (state.productionType === ProductionType.BOTH ||
        state.productionType === ProductionType.ALIGNER)
    );
  }
);

export const archWarningType = createSelector(
  selectSelf,
  (state: CreateOrderState) => {
    if (
      state.caseInfo?.archCompleteStatus ===
        ArchCompleteStatus.UPPER_COMPLETED &&
      state.upperArch
    ) {
      return "Upper";
    }

    if (
      state.caseInfo?.archCompleteStatus ===
        ArchCompleteStatus.LOWER_COMPLETED &&
      state.lowerArch
    ) {
      return "Lower";
    }

    return "";
  }
);

export const getStepsInfoSelector = createSelector(
  selectSelf,
  (state: CreateOrderState) => ({
    stepsType: state.stepsType,
    customStepsNum: state.customStepsNum,
  })
);
