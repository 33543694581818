import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { IFile } from "lib/model";
import { Button } from "components/ui";
import { FileCard, FileUploadArea } from "components/common";
import { Colors } from "consts/colors";

import { StyledTextSeparator, StyledUploadContainer } from "./Elements";

interface Props {
  onChange: (files: File[]) => void;
  files: File[];
}
export const UploadArea: React.VFC<Props> = ({ onChange, files }) => {
  const changeFiles = (newFiles: File[]) => {
    const filesName = files.map(({ name }) => name);

    if (newFiles) {
      const filteredFiles = newFiles.filter(
        ({ name }) => !filesName.includes(name)
      );
      onChange([...files, ...filteredFiles]);
    }
  };
  const fileDeleteHandler = (file: File | IFile) => {
    onChange(files.filter(({ name }) => name !== file.name));
  };

  return (
    <>
      <FileUploadArea
        sx={{
          border: "none",
          backgroundColor: Colors.CONTAINER_LIGHT_GREY,
          height: 190,
        }}
        onFilesUpload={changeFiles}
      >
        <StyledUploadContainer
          justifyContent="center"
          data-intercom-target="Upload files area"
        >
          <Stack spacing={1.25} alignItems="center">
            <Typography
              fontWeight={400}
              textAlign="center"
              fontSize={11.5}
              variant="body2"
            >
              Drag your documents, photos here to start uploading
            </Typography>
            <StyledTextSeparator variant="body2">OR</StyledTextSeparator>
            <Button variant="outlined">Browse Files</Button>
          </Stack>
        </StyledUploadContainer>
      </FileUploadArea>
      {files.length > 0 && (
        <Stack spacing={1.5} direction="row" justifyContent="flex-start">
          {files.map((file) => (
            <FileCard
              onDelete={fileDeleteHandler}
              key={file.name}
              file={file}
            />
          ))}
        </Stack>
      )}
    </>
  );
};
