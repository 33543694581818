import React from "react";
import Box from "@mui/material/Box";
import { StyledSvg } from "./CellElements";
import clsx from "clsx";

interface IconProps {
  className?: string;
  style?: any;
  value: number | null;
  classNameIcon?: string;
  classNameContainer?: string;
  classNameText?: string;
  color?: string;
}
interface Props extends IconProps {
  value: number | null;
  color?: string;
}

const FBM = (props: Props) => {
  if (props.value || props.value === 0) {
    return (
      <Box
        position="relative"
        height="100%"
        className={props.classNameContainer}
      >
        <StyledSvg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          className={clsx(props.className, props.classNameIcon)}
          style={{
            ...props.style,
            transform: `rotate(${props.value || 0}deg)`,
          }}
        >
          <path
            opacity="0.5"
            d="M20.9985 0.330081L9.45153 20.3301H32.5455L20.9985 0.330081ZM22.9985 40.3301V18.3301H18.9985V40.3301H22.9985Z"
            fill="#2196F3"
          />
        </StyledSvg>
      </Box>
    );
  }

  return null;
};

export default FBM;
