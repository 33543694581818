import { Step } from "containers/stepper/types";
import { RootState } from "store";
import { selectors, actions } from "store/caseCreate";
import { store } from "App";

import * as components from "./components";
import { Questions } from "lib/enum";
import { validateEmail } from "utils";

const getRequiredFieldsAmount = (state: RootState, retainer: boolean) => {
  if (retainer) {
    return 1;
  }

  if (state.newCase.preEval) {
    if (state.newCase.useMeditLink) return 1;
    return 2;
  }

  if (state.newCase.useMeditLink) return 4;

  const showSelectOne = getShowSelectOne(state);
  if (showSelectOne) return 7;

  return 5;
};

const getIsStepDone = (state: RootState, retainer: boolean) => {
  return (
    selectors.secondStepsRequiredFieldsComplete(state) >=
    getRequiredFieldsAmount(state, retainer)
  );
};

const getShowSelectOne = (state: RootState) => {
  let showSelectOne = false;
  [
    "/orders/case/continuation/",
    "/orders/aligners/new/",
    "/orders/new/eval",
  ].forEach((path) => {
    const idx = window.location.pathname.indexOf(path);
    if (idx !== -1) {
      showSelectOne = true;
    }
  });
  if (showSelectOne) {
    const typeOfPriceId =
      state.newCase?.caseInfo?.typeOfPrice?.id ??
      state.newCase.selectedOffice?.typeOfPriceId ??
      (state.newCase.selectedOffice as any)?.office?.typeOfPriceId;

    showSelectOne = typeOfPriceId && typeOfPriceId !== 0;
  }
  return showSelectOne;
};

export const getStepsConf =
  (retainer: boolean = false) =>
  (state: RootState): Step[] => {
    const showSelectOne = getShowSelectOne(state);

    const getRxStepContent = () => {
      const res = [
        {
          title: "What arches need attention?*",
          component: retainer ? (
            <components.ArchesStep isRetainer />
          ) : (
            <components.RXStep />
          ),
          nextStepCheckHandler: () => {
            if (
              showSelectOne &&
              selectors.secondStepsRequiredFieldsComplete(state) >=
                getRequiredFieldsAmount(state, retainer) - 2
            ) {
              return true;
            }

            if (
              selectors.secondStepsRequiredFieldsComplete(state) >=
              getRequiredFieldsAmount(state, retainer)
            ) {
              return true;
            }

            if (!state.newCase.lowerArch && !state.newCase.upperArch) {
              store.dispatch(actions.setRequiredState("arches"));
            }
            if (!state.newCase.clinicalPhotos.length) {
              if (!state.newCase.useMeditLink) {
                store.dispatch(actions.setRequiredState("clinicalPhotos"));
              }
            }
            if (!state.newCase.ipr && !state.newCase.preEval) {
              store.dispatch(actions.setRequiredState("ipr"));
            }
            if (!state.newCase.attachments && !state.newCase.preEval) {
              store.dispatch(actions.setRequiredState("attachments"));
            }
            if (!state.newCase.includes.length && !state.newCase.preEval) {
              store.dispatch(actions.setRequiredState("includes"));
            }
            return false;
          },
        },
        {
          title: "Just a little bit more information...",
          component: <components.AdditionalInfoStep />,
          nextStepCheckHandler: () => {
            if (state.newCase.preEval) {
              return true;
            }

            if (!state.newCase.wearSchedule) {
              store.dispatch(actions.setRequiredState("wearSchedule"));
              return false;
            }
            return true;
          },
        },
      ];
      if (showSelectOne && !retainer && !state.newCase.preEval) {
        res.splice(1, 0, {
          title: "Select one",
          component: <components.SelectPrice />,
          nextStepCheckHandler: () => {
            if (state.newCase.productTypeId) {
              return true;
            }
            return false;
          },
        });
      }
      return res;
    };

    const rxInformation = {
      label: retainer ? "Information" : "RX Information",
      requiredStepFields: getRequiredFieldsAmount(state, retainer),
      id: "rxStep",
      stepDone: getIsStepDone(state, retainer),
      requiredStepFieldsDone: getIsStepDone(state, retainer)
        ? getRequiredFieldsAmount(state, retainer)
        : selectors.secondStepsRequiredFieldsComplete(state),
      stepLeftAction: <components.SaveButton />,
      stepContent: getRxStepContent(),
    };

    const patientInfo = {
      label: "Patient Info",
      requiredStepFields: 2,
      id: "patientStep",
      stepDone: selectors.firstStepsRequiredFieldsComplete(state) === 2,
      requiredStepFieldsDone: selectors.firstStepsRequiredFieldsComplete(state),
      stepContent: [
        {
          title: "Enter patient information",
          component: <components.PatientStep />,
          nextStepCheckHandler: () => {
            if (
              state.newCase.patient.email !== "" &&
              !validateEmail(state.newCase.patient.email)
            ) {
              store.dispatch(actions.setRequiredState("patient.email"));
              return false;
            }

            if (
              selectors.firstStepsRequiredFieldsComplete(state) === 2 &&
              state.newCase.dmEnabled !== Questions.YES
            ) {
              return true;
            }

            if (
              state.newCase.dmEnabled === Questions.YES &&
              selectors.firstStepsRequiredFieldsComplete(state) === 2 &&
              (state.newCase.dmNumber ||
                (state.newCase.patient.birthDate &&
                  state.newCase.patient.phone &&
                  state.newCase.patient.email))
            ) {
              return true;
            }

            if (!state.newCase.patient.firstName) {
              store.dispatch(actions.setRequiredState("patient.firstName"));
            }
            if (!state.newCase.patient.lastName) {
              store.dispatch(actions.setRequiredState("patient.lastName"));
            }

            if (
              !state.newCase.patient.email &&
              !state.newCase.dmNumber &&
              state.newCase.dmEnabled === Questions.YES
            ) {
              store.dispatch(actions.setRequiredState("patient.email"));
            }
            if (
              !state.newCase.patient.phone &&
              !state.newCase.dmNumber &&
              state.newCase.dmEnabled === Questions.YES
            ) {
              store.dispatch(actions.setRequiredState("patient.phone"));
            }
            if (
              !state.newCase.patient.birthDate &&
              !state.newCase.dmNumber &&
              state.newCase.dmEnabled === Questions.YES
            ) {
              store.dispatch(actions.setRequiredState("patient.birthDate"));
            }

            return false;
          },
        },
      ],
    };

    const review = {
      label: state.newCase.isEditing ? "Review" : "Review & Submit Order",
      id: "review",
      stepDone: false,
      stepRightAction: state.newCase.isEditing ? null : (
        <components.SubmitOrderButton />
      ),
      stepLeftAction: <components.SaveButton />,
      stepContent: [
        {
          component: <components.ReviewStep />,
        },
      ],
    };

    return state.newCase.isEditing
      ? [rxInformation, review]
      : [patientInfo, rxInformation, review];
  };
