import React from 'react';

interface IprProps {
	className?: string;
	style?: any;
	type: 'mesial' | 'distal';
	value: number;
}

const NumIcon = ({ num }: { num: number }) => {
	const numPrettiedArr = num.toString().split('.');
	if (numPrettiedArr[1]) {
		return (
			<text
				x="8.76562"
				y="32.0293"
				fill="#2C2E30"
				fontWeight="bold"
				fontSize="13"
			>
				.{numPrettiedArr[1]}
			</text>
		);
	}
	return null;
};
const IPR = (props: IprProps) => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			className={props.className}
			style={props.style}
		>
			{props.type === 'mesial' ? (
				<path
					d="M17.4365 7.04688L19.9932 14.2656L22.5361 7.04688H25.2295V17H23.1719V14.2793L23.377 9.58301L20.6904 17H19.2822L16.6025 9.58984L16.8076 14.2793V17H14.7568V7.04688H17.4365Z"
					fill="#2C2E30"
				/>
			) : (
				<path
					d="M16.3359 17V7.04688H19.3984C20.2734 7.04688 21.055 7.24512 21.7432 7.6416C22.4359 8.03353 22.9759 8.59408 23.3633 9.32324C23.7507 10.0479 23.9443 10.8727 23.9443 11.7979V12.2559C23.9443 13.181 23.7529 14.0036 23.3701 14.7236C22.9919 15.4437 22.4564 16.002 21.7637 16.3984C21.071 16.7949 20.2894 16.9954 19.4189 17H16.3359ZM18.3867 8.70801V15.3525H19.3779C20.18 15.3525 20.793 15.0905 21.2168 14.5664C21.6406 14.0423 21.8571 13.2926 21.8662 12.3174V11.791C21.8662 10.7793 21.6566 10.0137 21.2373 9.49414C20.818 8.97005 20.2051 8.70801 19.3984 8.70801H18.3867Z"
					fill="#2C2E30"
				/>
			)}
			<NumIcon num={props.value} />
		</svg>
	);
};

export default IPR;
