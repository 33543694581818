import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

import { PLAN_COLORS } from "consts/tpColors";

interface StyledBox extends BoxProps {
  isDone?: boolean;
  isBordered?: boolean;
}

export const CellsContainer = styled(Box)<StyledBox>(
  ({ isDone, isBordered }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    position: "relative",
    ...(isBordered && {
      borderTop: `1px solid ${PLAN_COLORS.grey.GREY_12}`,
    }),
    ...(isDone && {
      "&::before": {
        content: "''",
        display: "block",
        width: "calc(100% - 2px)",
        position: "absolute",
        height: 39,
        border: `1px solid ${PLAN_COLORS.green.GREEN_2}`,
        left: 1,
        borderRadius: 4,
        background: "rgba(0, 150, 136, 0.05)",
      },
    }),
  })
);

export const CellsColumnContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "24px 1fr 2px 1fr 24px",
  columnGap: 5,
  flexGrow: 1,
});

interface StyledColumnBox extends BoxProps {
  borderedTop?: boolean;
  borderedBottom?: boolean;
  needImpression?: boolean;
}
export const RowColumns = styled(Box)<StyledColumnBox>(
  ({ borderedTop, borderedBottom, needImpression }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: 8,
    position: "relative",
    ...(borderedTop && {
      borderTop: `1px dashed ${
        needImpression ? PLAN_COLORS.orange.ORANGE_2 : PLAN_COLORS.blue.BLUE3
      }`,
    }),
    ...(borderedBottom && {
      borderBottom: `1px dashed ${PLAN_COLORS.blue.BLUE3}`,
    }),
  })
);

interface StyledRowNumberProps extends BoxProps {
  isBordered?: boolean;
}

export const RowNumber = styled(Box)<StyledRowNumberProps>(
  ({ isBordered }) => ({
    padding: "1px 4px 0px 4px",
    cursor: "pointer",
    ...(isBordered && {
      border: `1px solid ${PLAN_COLORS.grey.GREY_6}`,
      borderRadius: "50%",
    }),
    userSelect: "none",
  })
);
