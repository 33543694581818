import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";

import { fileDownload, getFilesType } from "utils";
import { Preview } from "../Preview";
import { Button } from "../Button";
import { fileService } from "services";

import { IFile } from "lib/model";
import { FileDownloadButton } from "../FileDownloadButton";

interface Props {
  open: boolean;
  files: IFile[];
  fileDownloadHandler?: (
    fileId: number,
    controller: AbortController,
    contentType?: string
  ) => Promise<string>;
  onClose: () => void;
}

export const FilesGallery: React.VFC<Props> = ({
  files,
  open,
  fileDownloadHandler,
  onClose,
}) => {
  const [filePage, setFilePage] = useState<number>(0);
  const [path, setPath] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const size = isDesktop ? 640 : isMobile ? 250 : 400;
  const file = files[filePage];

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    event.stopPropagation();
    setFilePage(value - 1);
  };
  useEffect(() => {
    const controller = new AbortController();

    if (file?.id && !file?.src && open) {
      setLoading(true);
      const filePromise = fileDownloadHandler
        ? fileDownloadHandler(file.id, controller, file?.contentType)
        : fileService.getFile(file?.id, file.contentType, controller.signal);
      filePromise.then(setPath).finally(() => setLoading(false));
    }
    return () => {
      controller.abort();
    };
  }, [open, file?.id, file?.contentType, file?.src, fileDownloadHandler]);
  if (!file) {
    return null;
  }
  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle>{file.name}</DialogTitle>
      <DialogContent>
        {loading || !Boolean(file?.src || path) ? (
          <Skeleton width={size} height={size} />
        ) : (
          <Preview fileType={getFilesType(file)} fileSrc={file?.src ?? path} />
        )}
        {files.length > 1 && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              page={filePage + 1}
              count={files.length}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={handleChange}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {file.originalFileId ? (
          <FileDownloadButton
            fileId={file.originalFileId}
            contentType={file.contentType}
            name={file.originalFileName || file.name}
          />
        ) : Boolean(file && (file?.src || path)) ? (
          <Button
            color="secondary"
            onClick={() =>
              fileDownload(file.name, (file?.src || path) as string)
            }
          >
            Download
          </Button>
        ) : null}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
