import { createAction } from "@reduxjs/toolkit";

export const incrementTab = createAction("steps/tabs/increment");
export const decrementTab = createAction("steps/tabs/decrement");
export const incrementSubStep = createAction("steps/subStep/increment");
export const decrementSubStep = createAction("steps/subStep/decrement");
export const setTab = createAction<number>("steps/tab/set");
export const setTabStep = createAction<{ currentTab: number; subStep: number }>(
  "steps/set"
);
export const clearState = createAction("steps/clear");
