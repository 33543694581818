import { IExtCaseDto } from "lib/dto";
import { IUploadedFile } from "lib/model";

export const checkRequiredFields = (
  caseInfo: IExtCaseDto,
  clinicalPhotos: IUploadedFile[]
): boolean => {
  return Boolean(
    clinicalPhotos.length > 0 &&
      (caseInfo.upperArch || caseInfo.lowerArch) &&
      caseInfo.additionalInfo.include?.length > 3 &&
      caseInfo.additionalInfo.extractionIPR &&
      caseInfo.additionalInfo.attachPrefer &&
      caseInfo.additionalInfo.wearSchedule
  );
};
