import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IExtCaseWithCaseProgress,
  IExtOrderWithTreatmentPlanStepsDto,
  IExtToothNoteDto,
  IExtTreatmentPlanDto,
  IToothDto,
  ITreatmentPlanVerificationKeyDto,
} from "lib/dto";
import { Nullable } from "lib/types";
import {
  ArchImpressionType,
  NotesRecipient,
  OrderItemType,
  OrderStatuses,
  TaskType,
} from "lib/enum";
import { IUpdateTreatmentPlanNote } from "lib/model";
import {
  caseService,
  orderService,
  orderTaskService,
  treatmentPlanService,
} from "services";

import {
  IprColumn,
  TpSteps,
  TreatmentPlanActions,
  TreatmentPlanState,
} from "./types";
import { calculateStepsHandler } from "./helpers";

import { RootState } from "../store";
import { PatientDetailsAction } from "../patientDetail/types";
import { getActiveOrder } from "./selectors";

export const setIPRHover = createAction<Nullable<IprColumn>>(
  TreatmentPlanActions.SET_IPR_HOVER
);

type VerificationKeyType = Record<
  number,
  {
    UPPER?: ITreatmentPlanVerificationKeyDto;
    LOWER?: ITreatmentPlanVerificationKeyDto;
  }
>;

export const setSelectingStart = createAction<IToothDto>(
  TreatmentPlanActions.START_SELECTING
);

export const setSelectingEnd = createAction<void>(
  TreatmentPlanActions.END_SELECTING
);

export const setSelectedCell = createAction<IToothDto>(
  TreatmentPlanActions.SET_SELECTED_CELL
);

export const setSelectedCells = createAction<{
  selectingToothKey: Record<string, boolean>;
  selectingToothDto: IToothDto[];
}>(TreatmentPlanActions.SET_SELECTED_CELLS);

export const openToShow = createAction<{
  noteIds: number[];
  currentTooth?: IToothDto[];
  anchor?: { top: number; left: number };
  addNote?: boolean;
}>(TreatmentPlanActions.OPEN_NOTES);

export const deleteNote = createAsyncThunk<number, number>(
  TreatmentPlanActions.DELETE_NOTE,
  async (id) => {
    await treatmentPlanService.deleteNote(id);
    return id;
  }
);

export const updateNote = createAsyncThunk<
  IUpdateTreatmentPlanNote,
  IUpdateTreatmentPlanNote
>(TreatmentPlanActions.UPDATE_NOTE, async (editedNote) => {
  await treatmentPlanService.updateNote(editedNote);
  return editedNote;
});

export const createNote = createAsyncThunk<
  IExtToothNoteDto,
  {
    forDoctor: boolean;
    note: string;
  }
>(TreatmentPlanActions.ADD_NOTE, async (newNote, thunkAPI) => {
  const { treatmentPlan } = thunkAPI.getState() as RootState;
  return await treatmentPlanService.createNote({
    treatmentPlanId: treatmentPlan.treatmentPlan?.id!,
    note: newNote.note,
    forDoctor: newNote.forDoctor,
    teeth: treatmentPlan.tpNotes.currentTooth,
  });
});

const getApproveTpTaskId = async (
  rootState: RootState
): Promise<{
  taskId: number;
  currentOrder: IExtOrderWithTreatmentPlanStepsDto;
}> => {
  const currentOrderId = getActiveOrder(rootState);
  const currentOrder = rootState.treatmentPlan.treatmentPlan?.orders.find(
    ({ id }) => id === currentOrderId
  );
  const { orderTasks } = await orderService.getOrderWithItemsAndTasksById(
    currentOrder!.id
  );
  const task = orderTasks.find(
    ({ task }) => task.type === TaskType.APPROVE_TREAT_PLAN
  );
  return {
    taskId: task!.id,
    currentOrder: currentOrder!,
  };
};

export const approveTp = createAsyncThunk<void, void>(
  PatientDetailsAction.APPROVE_PLAN,
  async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const { currentOrder, taskId } = await getApproveTpTaskId(rootState);

    if (currentOrder?.status === OrderStatuses.HOLD) {
      await orderService.unholdOrder(currentOrder!.id);
    }
    await orderTaskService.moveTaskToDone(taskId);
  }
);

export const rejectTp = createAsyncThunk<
  void,
  {
    description: string;
  }
>(PatientDetailsAction.REJECT_PLAN, async ({ description }, thunkApi) => {
  const rootState = thunkApi.getState() as RootState;
  const { currentOrder, taskId } = await getApproveTpTaskId(rootState);
  if (currentOrder?.status === OrderStatuses.HOLD) {
    await orderService.unholdOrder(currentOrder!.id);
  }
  await orderTaskService.moveTaskToCancel(taskId, description);
});

export const fetchCurrentVersion = createAsyncThunk<
  {
    treatmentPlan: IExtTreatmentPlanDto;
    treatPlanExtraInst: TreatmentPlanState["treatPlanExtraInst"];
    treatmentPlanInstructions: TpSteps[];
    tpNotes: IExtToothNoteDto[];
    caseInfo: IExtCaseWithCaseProgress;
    verificationKeys: VerificationKeyType;
    needLowerIds: number[];
    needUpperIds: number[];
  },
  number
>(TreatmentPlanActions.FETCH_CURRENT_VERSION, async (caseId) => {
  const [treatmentPlan, caseInfo] = await Promise.all([
    treatmentPlanService.getCurrentVersion(caseId),
    caseService.getCaseWithOfficeAndProgressById(caseId),
  ]);
  const tpNotes = await treatmentPlanService.getAllNotes(treatmentPlan.id);
  const { orders, verificationKeys } = treatmentPlan;

  const tpOrdersIds = orders.map(({ id }) => id);
  const needLowerIds: number[] = [];
  const needUpperIds: number[] = [];

  const orderWithItems = await Promise.all(
    tpOrdersIds.map((id) => orderService.getOrderWithItemsById(id))
  );
  orderWithItems.forEach(({ id, orderItems }) => {
    const impression = orderItems.find(
      ({ itemType }) => itemType === OrderItemType.IMPRESSION
    );
    if (impression?.archImpression === ArchImpressionType.UPPER) {
      needUpperIds.push(id);
    }
    if (impression?.archImpression === ArchImpressionType.LOWER) {
      needLowerIds.push(id);
    }
  });

  const verificationKeysObj = verificationKeys.reduce(
    (
      result: VerificationKeyType,
      verificationKey: ITreatmentPlanVerificationKeyDto
    ) => {
      if (result[verificationKey.step]) {
        result[verificationKey.step][verificationKey.type] = verificationKey;
      } else {
        result[verificationKey.step] = {
          [verificationKey.type]: verificationKey,
        };
      }
      return result;
    },
    {} as VerificationKeyType
  );

  const extraStepsInstructions = orders
    .filter((order: any) =>
      Boolean(order.upperSteps?.length || order.lowerSteps?.length)
    )
    .reduce(
      (prevState: any, current: any) => {
        current.lowerSteps.forEach((item: any) => {
          if (!item.aligner) {
            prevState.lowerSteps[item.number] = item;
          }
        });
        current.upperSteps.forEach((item: any) => {
          if (!item.aligner) {
            prevState.upperSteps[item.number] = item;
          }
        });
        return prevState;
      },
      {
        upperSteps: {},
        lowerSteps: {},
      }
    );
  return {
    treatmentPlan,
    caseInfo,
    treatPlanExtraInst: extraStepsInstructions,
    verificationKeys: verificationKeysObj,
    needLowerIds,
    needUpperIds,
    tpNotes: tpNotes.filter(({ forDoctor }) => forDoctor),
    treatmentPlanInstructions: calculateStepsHandler(treatmentPlan),
  };
});

export const setTpNotesFilter = createAction<NotesRecipient | undefined>(
  TreatmentPlanActions.FILTER_TP_NOTES
);

export const toggleLegend = createAction<boolean>(
  TreatmentPlanActions.TOGGLE_LEGEND
);
export const selectNote = createAction<number | null>(
  TreatmentPlanActions.SELECT_NOTE
);
export const togglePin = createAction<boolean>(TreatmentPlanActions.TOGGLE_PIN);

export const toggleFBM = createAction<boolean>(TreatmentPlanActions.TOGGLE_FBM);
export const toggleNotes = createAction<boolean>(
  TreatmentPlanActions.TOGGLE_NOTES
);
export const toggleNoteList = createAction<{ top: number; left: number }>(
  TreatmentPlanActions.TOGGLE_NOTES_LIST
);
