import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { FatButton, FormLabel } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/continuationOrderCreate";
import { Colors } from "consts/colors";

export const SelectArches = () => {
  const arches = useAppSelector(selectors.getArchesSelector);
  const requiredFields = useAppSelector(selectors.requiredFields);
  const dispatch = useAppDispatch();
  const toggleArches = (archType: "upperArch" | "lowerArch") => () => {
    dispatch(actions.toggleArch(archType));
  };
  const requiredStyle = {
    border: `1px solid ${Colors.ERROR}`,
    textTransform: "none",
    height: 48,
    px: 2.5,
    py: 1.5,
  };
  const defStyle = {
    textTransform: "none",
    height: 48,
    px: 2.5,
    py: 1.5,
  };
  const upperRequired = requiredFields.includes("arch") && !arches.upper;
  const lowerRequired = requiredFields.includes("arch") && !arches.lower;

  return (
    <Box>
      <FormLabel label="What arches need attention*?">
        <Stack spacing={3} direction="row" sx={{ mt: 3 }}>
          <FatButton
            onClick={toggleArches("upperArch")}
            variant={arches.upper ? "contained" : "outlined"}
            sx={upperRequired ? requiredStyle : defStyle}
          >
            Upper Arches
          </FatButton>
          <FatButton
            onClick={toggleArches("lowerArch")}
            variant={arches.lower ? "contained" : "outlined"}
            sx={lowerRequired ? requiredStyle : defStyle}
          >
            Lower Arches
          </FatButton>
        </Stack>
      </FormLabel>
    </Box>
  );
};
