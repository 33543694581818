import React, { useEffect } from "react";
import { FileCard } from "components/common";
import { IExtFileDto } from "lib/dto";
import { getFilesType } from "utils";
import { useAppDispatch } from "hooks/redux";
import { actions } from "store/patientDetail";

interface Props {
  file: IExtFileDto & { src?: Blob | string };
}
export const UploadedFileCard: React.VFC<Props> = ({ file }) => {
  const fileType = getFilesType(file);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const controller = new AbortController();
    if ((fileType === "image" || fileType === "pdf") && !file?.src) {
      dispatch(
        actions.downloadFile({
          fileId: file.id,
          controller,
          contentType: file.contentType,
        })
      );
    }
    return () => {
      controller.abort();
    };
  }, [file.id, file?.src, file.contentType, fileType, dispatch]);
  const path = {
    id: file.id,
    contentType: file.contentType,
    created: file.created,
    name: file.name,
    originalFileId: file.originalFileId,
    originalFileName: file.originalFileName,
    notDeletable: true,
    src: file.src,
  };
  return <FileCard tooltip={false} file={path} width={45} height={45} />;
};
