import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Progress } from "components/ui";
import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";

import { BreakdownWindow } from "./BreakdownWindow";
import { ArchCompleteStatus } from "lib/enum";

export const CaseProgressInfo = () => {
  const { isLoading, caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const status =
    caseInfo?.archCompleteStatus === ArchCompleteStatus.UPPER_COMPLETED
      ? "Upper Complete"
      : caseInfo?.archCompleteStatus === ArchCompleteStatus.LOWER_COMPLETED
      ? "Lower Complete"
      : null;
  return (
    <Stack spacing={1} minWidth={100} color={Colors.TEXT_GREY}>
      <Typography fontWeight={600} variant="body2" color={Colors.LABEL_GREY}>
        CASE PROGRESS
      </Typography>
      <Progress progress={caseInfo?.caseProgress} isLoading={isLoading}>
        {status}
      </Progress>
      <BreakdownWindow />
    </Stack>
  );
};
