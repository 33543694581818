import React from "react";
import { Tabs } from "components/ui";
import { ShippingPreferences } from "./ShippingPreferences";
import { TreatmentPreferences } from "./TreatementPreferences";

export const PreferencesTabs = () => {
  return (
    <Tabs
      tabs={[
        {
          label: "Treatment preferences",
          tabId: "treatPreference",
          content: <TreatmentPreferences />,
        },
        {
          label: "Shipping preferences",
          tabId: "shipReference",
          content: <ShippingPreferences />,
        },
      ]}
    />
  );
};
