import { OrderItemType } from "lib/enum";
import { OrderCreateState } from "./types";

export const initState: OrderCreateState = {
  isLoading: false,
  isRetainer: false,
  isEditing: false,
  caseInfo: null,
  patient: {
    lastName: "",
    firstName: "",
    gender: "",
    birthDate: null,
    email: "",
    phone: "",
  },
  ipr: "",
  dmEnabled: "0",
  dmNumber: "",
  showDm: false,
  attachments: "",
  wearSchedule: "7",
  stepsType: "5",
  customStepsNum: 5,
  lowerArch: false,
  upperArch: false,
  clinicalPhotos: [],
  otherFiles: [],
  includes: [],
  excludes: [],
  patients: [],
  complications: Array(32).fill(null),
  extractions: Array(32).fill(null),
  notes: "",
  preEval: false,
  requiredFields: [],
  orderItems: {
    [OrderItemType.IMPRESSION]: 0,
  },
  useMeditLink: false,
  removeAttachment: false,
  selectedOffice: null,

  productTypeId: null,
};
