import React from 'react';

const FBM = () => {
	return (
		<svg width="32" height="32" viewBox="0 -6 32 32" fill="none">
			<path
				opacity="0.5"
				d="M32 12L12 0.452995V23.547L32 12ZM0 14H14V10H0L0 14Z"
				fill="#2196F3"
			/>
		</svg>
	);
};
export default FBM;
