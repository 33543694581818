import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" {...props}  fill="none">
      <path
        d="M8.21132 5.99996L11.528 2.68325C11.935 2.27624 11.935 1.61634 11.528 1.209L10.7909 0.471882C10.3839 0.0648741 9.72401 0.0648741 9.31667 0.471882L5.99996 3.7886L2.68325 0.471882C2.27624 0.0648741 1.61634 0.0648741 1.209 0.471882L0.471882 1.209C0.0648741 1.61601 0.0648741 2.27591 0.471882 2.68325L3.7886 5.99996L0.471882 9.31667C0.0648741 9.72368 0.0648741 10.3836 0.471882 10.7909L1.209 11.528C1.61601 11.935 2.27624 11.935 2.68325 11.528L5.99996 8.21132L9.31667 11.528C9.72368 11.935 10.3839 11.935 10.7909 11.528L11.528 10.7909C11.935 10.3839 11.935 9.72401 11.528 9.31667L8.21132 5.99996Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
