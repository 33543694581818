import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import { Colors } from "consts/colors";
import { Button, TextField } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/continuationOrderCreate";

export const CompleteCaseWarning = () => {
  const dispatch = useAppDispatch();
  const showWarning = useAppSelector(selectors.showCompleteCaseWarning);
  const { reason } = useAppSelector(selectors.getAllInfoSelector);
  const [open, setOpen] = useState<boolean>(false);

  const closeHandler = () => {
    setOpen(false);
    dispatch(actions.setReason(""));
    dispatch(actions.setRetainers("1"));
  };
  const applyHandler = () => {
    setOpen(false);
  };
  const changeReasonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setReason(event.target.value));
  };

  useEffect(() => {
    if (showWarning) {
      dispatch(actions.setReason(""));
      setOpen(true);
    }
  }, [dispatch, showWarning]);

  return (
    <Dialog fullWidth open={open} maxWidth="md">
      <DialogTitle color={Colors.PRIMARY}>
        Case supposed to be completed
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure the Patient needs new aligners?
        </DialogContentText>
        <TextField
          placeholder="Please, provide the reason why completed case requires another aligners."
          fullWidth
          variant="outlined"
          margin="dense"
          multiline
          rows={4}
          value={reason}
          onChange={changeReasonHandler}
        />
      </DialogContent>
      <DialogActions sx={{ paddingInline: 5, paddingBottom: 3 }}>
        <Button fatButton variant="outlined" onClick={closeHandler}>
          Only Retainers
        </Button>
        <Button fatButton variant="contained" onClick={applyHandler}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
