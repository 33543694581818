import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { InfoWithLabel } from "components/common";
import { IExtShippingInfoDto } from "lib/dto";
import { shippingInfoStatus } from "lib/dictionaries";
import { Colors } from "consts/colors";
import { dateToString } from "utils";

import { ShippingItemContainer } from "./Elements";
import { PrintShippingLabelButton } from "./PrintShippngLabelButton";

interface Props {
  shipping: IExtShippingInfoDto;
}
export const ShippingItem: React.VFC<Props> = (props: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const toggleMoreInfo = () => {
    setOpen((oldOpen) => !oldOpen);
  };
  return (
    <ShippingItemContainer spacing={3}>
      <Stack direction="column" spacing={1}>
        <InfoWithLabel labelStyleSx={{ fontSize: "0.75em" }} label="SHIP FROM:">
          <>
            <Typography
              variant="body1"
              fontWeight={500}
              fontSize="1em"
              color={Colors.TEXT_GREY}
            >{`${props.shipping.user?.firstName || ""} ${
              props.shipping.user?.lastName || ""
            },`}</Typography>
            <Typography
              variant="body1"
              fontSize="1em"
              fontWeight={500}
              color={Colors.TEXT_GREY}
            >{`${props.shipping.senderAddress.country}, ${props.shipping.senderAddress.state}, ${props.shipping.senderAddress.city}, ${props.shipping.senderAddress.address},`}</Typography>
          </>
        </InfoWithLabel>
        <InfoWithLabel
          labelStyleSx={{ fontSize: "0.75em" }}
          textStyleSx={{ fontSize: "1em" }}
          label="SHIP TO:"
        >
          <>
            <Typography
              variant="body1"
              fontWeight={500}
              fontSize="1em"
              color={Colors.TEXT_GREY}
            >{`${props.shipping.shipToName},`}</Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              fontSize="1em"
              color={Colors.TEXT_GREY}
            >{`${props.shipping.recipientAddress.country}, ${props.shipping.recipientAddress.state}, ${props.shipping.recipientAddress.city}, ${props.shipping.recipientAddress.address},`}</Typography>
          </>
        </InfoWithLabel>
        <InfoWithLabel
          label="DATE:"
          labelStyleSx={{ fontSize: "0.75em" }}
          textStyleSx={{ fontSize: "1em" }}
          text={dateToString(props.shipping.created)}
        />
        {props.shipping.shipmentId ? (
          <Stack direction="row" spacing={2} alignItems="center">
            <InfoWithLabel
              label="TRACKING NUMBER:"
              labelStyleSx={{ fontSize: "0.75em" }}
              textStyleSx={{ fontSize: "1em" }}
              text={
                <Link
                  data-intercom-target="Direct shipping link"
                  target="_blank"
                  href={`https://www.ups.com/track?tracknum=${props.shipping.shipmentId}`}
                  underline="none"
                  rel="noreferrer"
                >
                  {props.shipping.shipmentId}
                </Link>
              }
            />
            <PrintShippingLabelButton
              data-intercom-target="Print shipping label"
              shippingId={props.shipping.id}
            />
          </Stack>
        ) : null}
      </Stack>
      <div>
        <Stack direction="row" spacing={0.5} onClick={toggleMoreInfo}>
          <Typography
            variant="body2"
            color={Colors.PRIMARY}
            fontSize="0.75em"
            fontWeight={500}
            sx={{ cursor: "pointer", fontSize: 14, lineHeight: "20px" }}
          >
            tracking details
          </Typography>
          {open ? (
            <ExpandLessIcon fontSize="small" color="primary" />
          ) : (
            <ExpandMoreIcon fontSize="small" color="primary" />
          )}
        </Stack>
        <Collapse in={open}>
          <Stack direction="row" spacing={9} sx={{ marginTop: 2 }}>
            <InfoWithLabel
              label="STATUS"
              labelStyleSx={{ fontSize: "0.75em" }}
              textStyleSx={{ fontSize: "1em" }}
              text={shippingInfoStatus[props.shipping.status]}
              direction="column"
            />
            {!!props.shipping.currentLocation && (
              <InfoWithLabel
                labelStyleSx={{ fontSize: "0.75em" }}
                textStyleSx={{ fontSize: "1em" }}
                label="LOCATION"
                text={props.shipping.currentLocation}
                direction="column"
              />
            )}
          </Stack>
        </Collapse>
      </div>
    </ShippingItemContainer>
  );
};
