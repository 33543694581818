import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const PersonIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1488 4.42857C10.1488 6.32228 8.61296 7.85714 6.71801 7.85714C4.82306 7.85714 3.2872 6.32228 3.2872 4.42857C3.2872 2.53486 4.82306 1 6.71801 1C8.61296 1 10.1488 2.53486 10.1488 4.42857Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.436 17H1C1 16.188 1 15.4155 1 14.7159C1 12.8212 2.53602 11.2857 4.43081 11.2857H9.00521C10.9 11.2857 12.436 12.8212 12.436 14.7159C12.436 15.4155 12.436 16.188 12.436 17Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};
