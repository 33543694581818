import React, { useCallback, useEffect, useRef, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import clsx from "clsx";
import {
  HeaderFilterIcon,
  InputContainer,
  Listbox,
  ListItem,
} from "./Elements";
import { FilterListProps } from "./types";
import { Popper } from "@mui/material";
import Stack from "@mui/material/Stack";

export const FilterCheckboxList = ({
  title,
  data,
  handleChange,
  filterValue,
  icon,
}: FilterListProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState<string[]>([]);
  const input = useRef<HTMLInputElement>(null);
  const toggleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen((oldOpen) => !oldOpen);
  };
  const filterChange = (name: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    if (filterValue && Array.isArray(filterValue)) {
      if (filterValue.includes(name)) {
        const newValues = filterValue.filter((item) => item !== name);
        handleChange(event, {
          title,
          value: newValues.join(","),
        });
        return newValues;
      }
      const newValues = [...filterValue, name];
      handleChange(event, {
        title,
        value: newValues.join(","),
      });
    } else {
      setValues((oldValues) => {
        if (oldValues.includes(name)) {
          const newValues = oldValues.filter((item) => item !== name);
          handleChange(event, {
            title,
            value: newValues.join(","),
          });
          return newValues;
        }
        const newValues = [...oldValues, name];
        handleChange(event, {
          title,
          value: newValues.join(","),
        });
        return newValues;
      });
    }
  };
  const backdropClickHandler = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if ((!target.id || target.id !== "filterPopper") && open) {
        setOpen(false);
      }
    },
    [open]
  );
  const filterValues: string[] =
    filterValue && Array.isArray(filterValue) ? filterValue : values;
  useEffect(() => {
    document.addEventListener("click", backdropClickHandler);
    return () => {
      document.removeEventListener("click", backdropClickHandler);
    };
  }, [backdropClickHandler]);
  if (!data?.length) {
    return (
      <Typography color="inherit" fontSize="inherit" fontWeight="inherit">
        {title}
      </Typography>
    );
  }
  return (
    <>
      <div ref={input} onClick={toggleOpen}>
        <InputContainer>
          <Typography
            sx={{ marginRight: 2, cursor: "pointer" }}
            color="inherit"
            fontSize="inherit"
            fontWeight="inherit"
          >
            {title}
          </Typography>
          <span>
            {icon ? (
              React.cloneElement(icon, {
                className: clsx({ active: Boolean(values.length) }),
              })
            ) : (
              <HeaderFilterIcon
                className={clsx({ active: Boolean(values.length) })}
                viewBox="0 0 14 14"
              />
            )}
          </span>
        </InputContainer>
      </div>
      <Popper
        id="filterPopper"
        open={open}
        anchorEl={input.current}
        placement="bottom-start"
        sx={{ zIndex: 300 }}
      >
        <Listbox>
          {data.map(({ title, value }) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem key={value} onClick={filterChange(value)}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    sx={{ p: 0 }}
                    size="small"
                    edge="start"
                    checked={filterValues.includes(value)}
                    disableRipple
                    name={value}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                  <Typography
                    color="inherit"
                    fontSize="inherit"
                    fontWeight="inherit"
                  >
                    {title}
                  </Typography>
                </Stack>
              </ListItem>
            );
          })}
        </Listbox>
      </Popper>
    </>
  );
};
