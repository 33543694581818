import React, { useRef } from "react";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { FileCard } from "components/common";
import { IFile } from "lib/model";

interface Props {
  onUpload: (files: File[]) => void;
  files: File[];
}
export const MessageFilesAddButton = ({ files, onUpload }: Props) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesName = files.map(({ name }) => name);

    if (event.target.files) {
      const filteredFiles = Array.from(event.target.files).filter(
        ({ name }) => !filesName.includes(name)
      );
      onUpload([...files, ...filteredFiles]);
    }
  };
  const clickHandler = () => {
    if (inputEl.current) {
      inputEl.current.value = "";
      inputEl.current.click();
    }
  };

  const fileDeleteHandler = (file: File | IFile) => {
    onUpload(files.filter(({ name }) => name !== file.name));
  };

  return (
    <>
      <input
        multiple
        ref={inputEl}
        id="message_files_upload"
        onChange={handleCapture}
        type="file"
        hidden
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        flexGrow={1}
        justifyContent="flex-end"
      >
        {files.length > 0 && (
          <Stack spacing={1.5} direction="row" justifyContent="flex-end">
            {files.map((file) => (
              <FileCard
                onDelete={fileDeleteHandler}
                key={file.name}
                file={file}
              />
            ))}
          </Stack>
        )}
        <IconButton color="secondary" component="span" onClick={clickHandler}>
          <AttachFileIcon />
        </IconButton>
      </Stack>
    </>
  );
};
