import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { FormLabel, RadioSelect, TextField } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  selectors,
  actions,
  ProductionType,
} from "store/continuationOrderCreate";
import { SelectSteps } from "./SelectSteps";

const retainerOptions = [
  {
    label: "Aligners",
    value: "0",
  },
  {
    label: "Retainers",
    value: "1",
  },
  {
    label: "Both",
    value: "2",
  },
];


const yesNoOptions = [
  {
    label: "Yes",
    value: "true"
  },
  {
    label: "No",
    value: "false"
  }
]


export const SelectProduct = () => {
  const { productionType, removeAttachment } = useAppSelector(selectors.getAllInfoSelector);
  const requiredFields = useAppSelector(selectors.requiredFields);
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectors.getAllInfoSelector);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setRetainers(event.target.value));
  };
  const requestedRetainersChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(actions.setRequestedRetainers(event.target.value));
  };

  const removeAttachmentChangeHandler = (value: string) => {
    const booleanValue = value === "true";
    dispatch(actions.setRemoveAttachment(booleanValue));
  }

  const reqField = requiredFields.includes("requestedRetainers");
  return (
    <Stack direction="row" spacing={8} alignItems="flex-start" minHeight={86}>
      <FormLabel label="What product you creating order for?" required>
        <RadioSelect
          value={productionType}
          onChange={changeHandler}
          options={retainerOptions}
        />
      </FormLabel>
      <Box>
        <Stack direction="row" spacing={3} alignItems="center">
          {(order.productionType === ProductionType.ALIGNER ||
            order.productionType === ProductionType.BOTH) && <SelectSteps />}
          {(order.productionType === ProductionType.RETAINER ||
            order.productionType === ProductionType.BOTH) && (
              <>
                <FormLabel label="Retainers count" required>
                  <TextField
                    error={reqField}
                    helperText={reqField ? "Please fill this field" : undefined}
                    value={order.requestedRetainers}
                    type="number"
                    sx={{ maxWidth: 123 }}
                    onChange={requestedRetainersChangeHandler}
                  />
                </FormLabel>
                <FormLabel
                  label="Do we need to remove the attachment?"
                >
                  <RadioSelect
                    value={removeAttachment ? "true" : "false"}
                    options={yesNoOptions}
                    onChange={(_, value) => removeAttachmentChangeHandler(value)}
                  />
                </FormLabel>
              </>
            )}
        </Stack>
      </Box>
    </Stack>
  );
};
