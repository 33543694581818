import React from "react";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faTooth } from "@fortawesome/free-solid-svg-icons/faTooth";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons/faFileInvoiceDollar";

import { FontAwesomeSvgIcon } from "components/common";
import { EventDashboardOperation } from "lib/enum";

interface Props {
  eventType: EventDashboardOperation;
}
export const EventIcon: React.VFC<Props> = ({ eventType }) => {
  switch (eventType) {
    case EventDashboardOperation.INVOICING:
      return <FontAwesomeSvgIcon icon={faFileInvoiceDollar} />;
    case EventDashboardOperation.CREATE_ALIGNER_ORDER:
    case EventDashboardOperation.TIME_FOR_NEXT_ORDER:
    case EventDashboardOperation.TIME_FOR_RETAINER_ORDER:
      return <FontAwesomeSvgIcon icon={faClipboard} />;
    case EventDashboardOperation.START_WEARING:
    case EventDashboardOperation.SHEDULING:
      return <FontAwesomeSvgIcon icon={faTooth} />;
    default:
      return <FontAwesomeSvgIcon icon={faBell} />;
  }
};
