import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { IExtTreatmentPlanStepDto } from "lib/dto";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/treatmentPlan";
import { TreatmentPlanStepStatus } from "lib/enum";
import { PLAN_COLORS } from "consts/tpColors";

import { InstructionsIndicator } from "./InstructionsIndicator";
import { NotesRow } from "../Notes";
import { StepsRowProps } from "./types";
import {
  getExtraStepObject,
  getFirstStepInstruction,
  getStepInstruction,
} from "./helpers";
import {
  CellsContainer,
  CellsColumnContainer,
  RowColumns,
  RowNumber,
} from "./RowElements";
import { Cells } from "./Cells";

const check = (value: any) => {
  return (
    value === null || value === false || value === undefined || value === 0
  );
};

export const StepsRow = (props: StepsRowProps) => {
  const { upper, lower, rowNumber } = props;
  const state = useAppSelector(selectors.getAllInfo);

  const verificationKey = state.verificationKeys[rowNumber] ?? null;
  const firstStepUpperInstr = useMemo(
    () =>
      getFirstStepInstruction(
        upper,
        props.needUpperImpressions,
        props.upperIndex
      ),
    [upper, props.needUpperImpressions, props.upperIndex]
  );
  const firstStepLowerInstr = useMemo(
    () =>
      getFirstStepInstruction(
        lower,
        props.needLowerImpressions,
        props.lowerIndex
      ),
    [lower, props.needLowerImpressions, props.lowerIndex]
  );

  const stepInstructionsUpper = useMemo(
    () =>
      getStepInstruction(
        props.plan.upperSteps,
        state.treatPlanExtraInst.upperSteps,
        upper,
        rowNumber,
        props.upperIndex,

        verificationKey
      ),
    [
      upper,
      props.upperIndex,
      props.plan,
      rowNumber,
      state.treatPlanExtraInst.upperSteps,
      verificationKey,
    ]
  );
  const stepInstructionsLower = useMemo(
    () =>
      getStepInstruction(
        props.plan.lowerSteps,
        state.treatPlanExtraInst.lowerSteps,
        lower,
        rowNumber,
        props.lowerIndex,
        verificationKey
      ),
    [
      lower,
      props.lowerIndex,
      props.plan,
      rowNumber,
      state.treatPlanExtraInst.lowerSteps,
      verificationKey,
    ]
  );

  const emptyStep = (step: IExtTreatmentPlanStepDto) => {
    return check(step.retainerQty) && check(step.matrix) && check(step.origin);
  };
  if (!upper && !lower) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="center">
      <Box width={20} height="100%" mr={1} textAlign="end">
        {Boolean(upper) && (
          <Typography
            fontWeight="bold"
            color={PLAN_COLORS.grey.GREY_8}
            variant="body2"
          >
            <RowNumber
              component="span"
              isBordered={Boolean(upper && !emptyStep(upper))}
            >
              {rowNumber}
            </RowNumber>
          </Typography>
        )}
      </Box>
      <CellsColumnContainer>
        <InstructionsIndicator
          extraInstruction={
            state.treatPlanExtraInst.upperSteps[rowNumber]
              ? getExtraStepObject(
                  state.treatPlanExtraInst.upperSteps[rowNumber]
                )
              : undefined
          }
          firstInstr={firstStepUpperInstr}
          mainInstr={stepInstructionsUpper}
          stepsExists={Boolean(upper)}
        />
        <RowColumns
          needImpression={props.needUpperImpressions}
          borderedTop={firstStepUpperInstr.length > 0}
          borderedBottom={Boolean(
            upper &&
              (stepInstructionsUpper.length > 0 ||
                state.treatPlanExtraInst.upperSteps[rowNumber])
          )}
        >
          {upper && (
            <>
              {state.showNotes && (
                <NotesRow
                  stepId={upper.id}
                  stepNumber={rowNumber}
                  bordered={!props.upperIndex}
                  type="uppers"
                />
              )}
              <CellsContainer
                isBordered={!props.upperIndex}
                isDone={
                  upper.status === TreatmentPlanStepStatus.INSTRUCTION_DONE
                }
              >
                <Cells
                  type="uppers"
                  indexPlus={0}
                  upperIndex={props.upperIndex}
                  lowerIndex={props.lowerIndex}
                  rowNumber={props.rowNumber}
                  itemIndex={props.itemIndex}
                />
              </CellsContainer>
              <CellsContainer
                isBordered={!props.upperIndex}
                isDone={
                  upper.status === TreatmentPlanStepStatus.INSTRUCTION_DONE
                }
              >
                <Cells
                  type="uppers"
                  indexPlus={8}
                  upperIndex={props.upperIndex}
                  lowerIndex={props.lowerIndex}
                  rowNumber={props.rowNumber}
                  itemIndex={props.itemIndex}
                />
              </CellsContainer>
            </>
          )}
        </RowColumns>
        <div />
        <RowColumns
          needImpression={props.needLowerImpressions}
          borderedTop={firstStepLowerInstr.length > 0}
          borderedBottom={Boolean(
            lower &&
              (stepInstructionsLower.length > 0 ||
                state.treatPlanExtraInst.lowerSteps[rowNumber])
          )}
        >
          {lower && (
            <>
              {state.showNotes && (
                <NotesRow
                  stepNumber={rowNumber}
                  stepId={lower.id}
                  bordered={!props.lowerIndex}
                  type="lowers"
                />
              )}
              <CellsContainer
                isBordered={!props.lowerIndex}
                isDone={
                  lower.status === TreatmentPlanStepStatus.INSTRUCTION_DONE
                }
              >
                <Cells
                  type="lowers"
                  indexPlus={0}
                  upperIndex={props.upperIndex}
                  lowerIndex={props.lowerIndex}
                  rowNumber={props.rowNumber}
                  itemIndex={props.itemIndex}
                />
              </CellsContainer>
              <CellsContainer
                isBordered={!props.lowerIndex}
                isDone={
                  lower.status === TreatmentPlanStepStatus.INSTRUCTION_DONE
                }
              >
                <Cells
                  type="lowers"
                  indexPlus={8}
                  upperIndex={props.upperIndex}
                  lowerIndex={props.lowerIndex}
                  rowNumber={props.rowNumber}
                  itemIndex={props.itemIndex}
                />
              </CellsContainer>
            </>
          )}
        </RowColumns>
        <InstructionsIndicator
          lower
          extraInstruction={
            state.treatPlanExtraInst.lowerSteps[rowNumber]
              ? getExtraStepObject(
                  state.treatPlanExtraInst.lowerSteps[rowNumber]
                )
              : undefined
          }
          firstInstr={firstStepLowerInstr}
          mainInstr={stepInstructionsLower}
          stepsExists={Boolean(lower)}
        />
      </CellsColumnContainer>
      <Box width={20} height="100%" mr={1} textAlign="start">
        {Boolean(lower) && (
          <Typography
            fontWeight="bold"
            color={PLAN_COLORS.grey.GREY_8}
            variant="body2"
          >
            <RowNumber
              component="span"
              isBordered={Boolean(lower && !emptyStep(lower))}
            >
              {rowNumber}
            </RowNumber>
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
