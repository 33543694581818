import { Grid } from "@mui/material";
import { useAppSelector } from "hooks/redux";
import { InfoWithLabel } from "components/common";
import { Colors } from "consts/colors";
import { dateToString } from "utils/dateHelpers";
import { LabelContainer, LabelContainerBorder, Wrapper } from "./Elements";

export const InfoInvoice = () => {
  const invoice = useAppSelector((state) => state.invoicesCard.invoice);
  const order = useAppSelector((state) => state.invoicesCard.order);
  const loading = useAppSelector((state) => state.invoicesCard.isLoading);
  const labelStyleSx = {
    color: Colors.TEXT_SECONDARY,
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: 12,
  };
  const textStyleSx = {
    fontSize: 14,
    textTransform: "uppercase",
  };
  return (
    <Wrapper>
      <LabelContainerBorder container spacing={2}>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Patient"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={`${order?.case?.patient.firstName ?? ""} ${
              order?.case?.patient.lastName ?? ""
            }`}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Doctor"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={`${invoice?.user?.firstName ?? ""} ${
              invoice?.user?.lastName ?? ""
            }`}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Order"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={<span className="link">{invoice?.orderId}</span>}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Order due date"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={order?.dueDate ? dateToString(order.dueDate) : "-"}
          />
        </Grid>
        <Grid item xs={4}>
          <InfoWithLabel
            label="Invoice Number"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={invoice?.stripeInvoiceNumber ?? ""}
          />
        </Grid>
      </LabelContainerBorder>
      <LabelContainerBorder container spacing={2}>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Open Date"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={invoice?.openDate ? dateToString(invoice.openDate) : "-"}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Create Date"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={invoice?.created ? dateToString(invoice.created) : "-"}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Pay Date"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={invoice?.paidDate ? dateToString(invoice.paidDate) : "-"}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Due Date"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={invoice?.dueDate ? dateToString(invoice?.dueDate) : "-"}
          />
        </Grid>
      </LabelContainerBorder>
      <LabelContainer container spacing={2}>
        <Grid item xs={2}>
          <InfoWithLabel
            label="Note"
            labelStyleSx={labelStyleSx}
            textStyleSx={textStyleSx}
            textColor={Colors.TEXT_GREY_DARK}
            isLoading={loading}
            text={invoice?.note ?? "-"}
          />
        </Grid>
      </LabelContainer>
    </Wrapper>
  );
};
