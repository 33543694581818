import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer, TabsContainer } from "containers";
import { Header } from "components/common";
import { Button } from "components/ui";
import { ICaseTabDto, ICaseTabCountDto } from "lib/dto";
import { CaseStatuses } from "lib/enum";
import { TabsContainerProps } from "containers/tabs/types";
import { activeListConf } from "./tabs/activeConf";
import { atLabListConf } from "./tabs/atLabConf";
import { arhivedListConf } from "./tabs/archivedConf";
import { caseService } from "services";
import { ShowCompletedCheckbox } from "./components/ShowCompletedCheckbox";

const filterField = [
  { name: "orderType", initValue: "ASC" },
  { name: "orderBy", initValue: "caseTabAction" },
];
export const PatientsPage = () => {
  const navigate = useNavigate();
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [tabsCount, setTabsCount] = useState<ICaseTabCountDto>({
    activeCount: 0,
    archivedCount: 0,
    atLabCount: 0,
  });
  const atLabFetch = useCallback(
    async (filter: any) =>
      await atLabListConf.requests!.fetch!(
        showComplete
          ? { ...filter, caseStatus: CaseStatuses.COMPLETED }
          : filter
      ),
    [showComplete]
  );
  const activeFetch = useCallback(
    async (filter: any) =>
      await activeListConf.requests!.fetch!(
        showComplete
          ? { ...filter, caseStatus: CaseStatuses.COMPLETED }
          : filter
      ),
    [showComplete]
  );
  const archiveFetch = useCallback(
    async (filter: any) =>
      await arhivedListConf.requests!.fetch!(
        showComplete
          ? { ...filter, caseStatus: CaseStatuses.COMPLETED }
          : filter
      ),
    [showComplete]
  );
  const tabs: TabsContainerProps<any>["tabs"] = [
    {
      badge: tabsCount.atLabCount,
      label: "At Lab",
      list: {
        filterField,
        ...atLabListConf,
        requests: {
          fetch: atLabFetch,
        },
        onRowClick,
      },
    },
    {
      badge: tabsCount.activeCount,
      label: "At Office",
      list: {
        filterField,
        ...activeListConf,
        requests: {
          fetch: activeFetch,
        },
        onRowClick,
      },
    },
    {
      badge: tabsCount.archivedCount,
      label: "Archived",
      list: {
        filterField,
        ...arhivedListConf,
        requests: {
          fetch: archiveFetch,
        },
        onRowClick,
      },
    },
  ];
  const navigateHandler = () => {
    navigate("/orders");
  };
  const changeCompleteHandler = (
    event: React.SyntheticEvent,
    checked: boolean
  ) => {
    setShowComplete(checked);
  };
  function handleEnter(search: string) {
    navigate(`/cases/search?search-for=${search}`);
  }
  function onRowClick({ id }: ICaseTabDto) {
    navigate(`/cases/${id}`);
  }
  useEffect(() => {
    caseService
      .getCaseTabsCount()
      .then(setTabsCount)
      .catch(() => {});
  }, []);
  return (
    <MainContainer>
      <Header
        title="Patients"
        right={
          <Button
            data-intercom-target="Create order button"
            fatButton
            onClick={navigateHandler}
            variant="contained"
            color="primary"
          >
            + Create order
          </Button>
        }
      />
      <TabsContainer
        tabs={tabs}
        searchProps={{
          placeholder: "Search patients...",
          disableOnSearch: true,
          handleEnter: handleEnter,
        }}
      >
        {
          <ShowCompletedCheckbox
            value={showComplete}
            onChange={changeCompleteHandler}
          />
        }
      </TabsContainer>
    </MainContainer>
  );
};
