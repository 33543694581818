import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import { Button } from "../../ui";

const StyledContainer = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ErrorIndicator: React.VFC = () => {
  const clickHandler = () => {
    window.location.reload();
  };
  return (
    <StyledContainer>
      <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
        <div>
          <WarningIcon
            sx={{ fontSize: 180 }}
            fontSize="large"
            color="warning"
          />
        </div>
        <Stack spacing={1}>
          <Typography variant="h1">We got a problem!</Typography>
          <Typography variant="body1">
            Try to reload the page or come back later.
          </Typography>
          <Button variant="outlined" color="warning" onClick={clickHandler}>
            Reload page
          </Button>
        </Stack>
      </Stack>
    </StyledContainer>
  );
};

export default ErrorIndicator;
