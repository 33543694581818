import React from "react";
import { Colors } from "consts/colors";

interface Props {
  children: string;
}

const LINK_REGEX =
  /(<a href=")?((?!<=("))(https?:\/\/)?([\w\-@]{1,32}\.[\w-]{1,32})[^\s@^)^>^"]*)("\>[a-zA-Z0-9\s]+\<\/a\>)?/gim;
const URL_EXCLUDE_REGEX = /.*@.*/;
const TAGS_REGEX = /(<[^>]+>)([^<]*)(<\/[^>]+>)/gm;

const replaceLinkHelper = (link: string): string => {
  const replacedText = link.replace(LINK_REGEX, (matchedText, ...params) => {
    if (URL_EXCLUDE_REGEX.test(matchedText)) {
      return matchedText;
    }
    return `<a rel="noreferrer" href="${params[1]}" target="_blank" style="color: ${Colors.PRIMARY}">${params[1]}</a>`;
  });
  return replacedText || link;
};

export const TextWrapper: React.FC<Props> = ({ children }) => {
  let parsedText = "";

  if (children.startsWith("<")) {
    parsedText = children.replace(
      TAGS_REGEX,
      (matchedText, tagStart, content, tagEnd) => {
        if (!matchedText.startsWith("<a") && !matchedText.startsWith("<img")) {
          return `${tagStart}${replaceLinkHelper(content)}${tagEnd}`;
        }
        return matchedText;
      }
    );
  } else {
    parsedText = children.replace(LINK_REGEX, (matchedText, ...params) => {
      if (URL_EXCLUDE_REGEX.test(matchedText)) {
        return matchedText;
      }
      return `<a rel="noreferrer" href="${params[1]}" target="_blank" style="color: ${Colors.PRIMARY}">${params[1]}</a>`;
    });
  }
  if (!parsedText) return <>{children}</>;
  return (
    <span
      style={{ whiteSpace: "pre-wrap" }}
      dangerouslySetInnerHTML={{ __html: parsedText }}
    />
  );
};
