import React, { useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useBackdrop } from "hooks/backdropClick";
import { selectors, actions } from "store/search";

import { StyledSearchField } from "../Elements";
import { ResultPopper } from "./ResultPopper";

const SearchBar = () => {
  const searchInfo = useAppSelector(selectors.allInfoSelector);
  const dispatch = useAppDispatch();
  const searchInput = React.useRef<HTMLDivElement | null>(null);
  const icon = searchInfo.isLoading ? (
    <CircularProgress color="inherit" size="1.5em" />
  ) : (
    <IconButton size="small" sx={{ color: Colors.DISABLED, padding: 0 }}>
      <SearchIcon fontSize="small" />
    </IconButton>
  );

  const searchResult = (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    if (!searchInfo.searchValue) return;
    dispatch(actions.getSearchResults());
  };
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setSearchValue(event.target.value));
  };
  const keyPressHandler = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      searchResult();
    }
    if (event.key === "Escape") {
      dispatch(actions.clearState());
    }
  };
  const bodyClickHandler = useCallback(
    (event: MouseEvent) => {
      if (event.target) {
        const searchContainer = (event.target as HTMLBaseElement).closest(
          "#searchContainer"
        );
        if (searchContainer) return;
      }
      dispatch(actions.clearState());
    },
    [dispatch]
  );
  useBackdrop(bodyClickHandler);
  return (
    <>
      <Box id="searchContainer" onKeyUpCapture={keyPressHandler} width="100%">
        <div ref={searchInput}>
          <StyledSearchField
            type="search"
            placeholder="Search by Patient Name or Case #"
            fullWidth
            value={searchInfo.searchValue}
            onChange={onValueChange}
            InputProps={{
              endAdornment: icon,
            }}
          />
        </div>
      </Box>
      <ResultPopper anchorEl={searchInput.current} />
    </>
  );
};

export default SearchBar;
