import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { TreatmentPlanState } from "./types";

const selectSelf = (state: RootState) => state.treatmentPlan;

export const getAllInfo = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state
);

export const caseInfoSelector = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state.case
);
export const treatPlanSelector = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state.treatmentPlan
);

export const notesSelector = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state.tpNotes
);

export const generalIPRSelector = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => {
    return state.treatmentPlan?.iprToothList || [];
  }
);

export const getActiveOrder = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => {
    const ordersLength = state.treatmentPlan?.orders.length;
    if (ordersLength) {
      return state.treatmentPlan?.orders[ordersLength - 1].id;
    }

    return null;
  }
);

export const showPin = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state.showPin
);

export const showLegend = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state.showLegend
);
export const showNotes = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state.showNotes
);

export const isLoading = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state.isLoading
);
export const planInstructionsSelector = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => state.treatmentPlanInstructions
);
export const tableRowsSelector = createSelector(
  selectSelf,
  (state: TreatmentPlanState) => {
    return state.treatmentPlanInstructions.map((plan: any) => {
      const isAutogenerated =
        plan.upperSteps[0]?.autogenerated || plan.lowerSteps[0]?.autogenerated;
      const minStepNum = Math.min(
        plan.upperSteps[0]?.number || Infinity,
        plan.lowerSteps[0]?.number || Infinity
      );
      const maxStepNum = Math.max(
        plan.upperSteps[plan.upperSteps.length - 1]?.number || -Infinity,
        plan.lowerSteps[plan.lowerSteps.length - 1]?.number || -Infinity
      );
      const count = Math.abs(maxStepNum - minStepNum);
      const autogeneratedCount = Math.max(
        plan.upperSteps.length,
        plan.lowerSteps.length
      );
      return {
        count: isAutogenerated
          ? autogeneratedCount
          : isFinite(count)
          ? count + 1
          : 0,
      };
    });
  }
);
