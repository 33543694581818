import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { AccountSettingsState } from "./types";

const selectSelf = (state: RootState) => state.accountSettings;

export const getAllInfoSelector = createSelector(
  selectSelf,
  (state: AccountSettingsState) => state
);

export const getOfficeLogos = createSelector(
  selectSelf,
  (state: AccountSettingsState) => state.officesLogo
);

export const getUserInfoSelector = createSelector(
  selectSelf,
  (state: AccountSettingsState) => {
    if (state.userInfo) {
      return state.userInfo;
    }
    return {
      lastName: "",
      firstName: "",
      email: "",
      userId: "",
      role: "",
      phone: "",
    };
  }
);
