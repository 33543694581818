import { SnackbarProvider as NtsSnackbarProvider } from "notistack";
import { CloseNoticeButton } from "./CloseSnackbarButton";


interface SnackbarProviderProps {
  children: React.ReactNode
}


export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children
}) => {
  return (
    <NtsSnackbarProvider
      action={(key) => <CloseNoticeButton snackbarKey={key} />}
      classes={{
        variantSuccess: "success",
        variantError: "error",
        variantInfo: "notice",
        variantWarning: "notice",
      }}
      hideIconVariant
      maxSnack={3}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {children}
    </NtsSnackbarProvider>
  )
}