import React, { ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { Colors } from "consts/colors";
import { SxProps, Theme } from "@mui/material";

interface Props {
  label: string | ReactNode;
  text?: string | JSX.Element;
  direction?: "column" | "row";
  textColor?: string;
  isLoading?: boolean;
  labelStyleSx?: SxProps<Theme>;
  textStyleSx?: SxProps<Theme>;
}
export const InfoWithLabel: React.FC<Props> = ({
  labelStyleSx,
  textStyleSx,
  textColor = Colors.TEXT_GREY,
  direction = "row",
  ...props
}) => {
  return (
    <Stack
      spacing={direction === "row" ? 1 : 0}
      direction={direction}
      alignItems="baseline"
    >
      <Typography
        variant="body2"
        sx={{ color: Colors.LABEL_GREY, ...labelStyleSx }}
      >
        {props.label}
      </Typography>
      {!props.children ? (
        <Typography
          sx={{ fontWeight: 500, ...textStyleSx }}
          variant="body1"
          color={textColor}
        >
          {props.isLoading ? (
            <Skeleton animation="wave" width={120} />
          ) : (
            props.text
          )}
        </Typography>
      ) : props.isLoading ? (
        <Typography
          sx={{ fontWeight: 500, ...textStyleSx }}
          variant="body1"
          color={textColor}
        >
          <Skeleton animation="wave" width={120} />
        </Typography>
      ) : (
        props.children
      )}
    </Stack>
  );
};
