import { VerificationFilesModal } from "./VerificationFilesModal";
import { WearSchedulingModal } from "./WearSchedulingModal";

export const CaseExtraActions = () => {
  return (
    <>
      <VerificationFilesModal />
      <WearSchedulingModal />
    </>
  );
};
