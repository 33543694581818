import { orderStatuses, invoiceStatuses } from "lib/dictionaries";
import { Params } from "./types";

export const getRowLabel = ({ entity, dataItem }: Params) => {
  switch (entity) {
    case "patients":
      return `${dataItem.firstName} ${dataItem.lastName} ${
        dataItem.lastOrderStatus
          ? "(" + orderStatuses[dataItem.lastOrderStatus] + ")"
          : ""
      }`;
    case "invoices":
      return `${dataItem.patientFirstName} ${dataItem.patientLastName}, ${
        dataItem.status ? invoiceStatuses[dataItem.status] : ""
      } ${
        dataItem.totalAmount ? "(" + dataItem.totalAmount.toFixed(2) + "$)" : ""
      }`;
    default:
      return "";
  }
};
