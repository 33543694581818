import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IExtOrderInvoiceDtoWithItems,
  IExtOrderWithOrgAndOfficeDto,
  IPaymentDto,
} from "lib/dto";
import { invoiceService, orderService, paymentService } from "services";
import { InvoicesCardActions } from "./types";
import { Nullable } from "lib/types";

export const getInvoiceInfo = createAsyncThunk<
  {
    invoice: IExtOrderInvoiceDtoWithItems;
    order: IExtOrderWithOrgAndOfficeDto;
    payment: Nullable<IPaymentDto>;
  },
  number
>(InvoicesCardActions.INVOICE_CARD_GET, async (id: number) => {
  const invoice = await invoiceService.get(id);
  const order = await orderService.getOrderWithOrgAndOffice(invoice.orderId);
  let payment = null;
  if (invoice.paymentId) {
    payment = await paymentService.getPaymentById(invoice.paymentId);
  }
  return { invoice, order, payment };
});
