import { OrderItemType } from "../enum";

export const orderItems: {
  [key in OrderItemType]: string;
} = {
  INVALID: "Invalid",
  BITE_REGISTRATION: "Bite registration",
  IMPRESSION: "Impression",
  MODEL: "Model",
  ARTICULATOR: "Articulator",
  CASE: "Case",
  CAD_CAM: "Cad cam",
  BITE: "Bite",
  PHOTOS: "Photos",
};
