import React from 'react';

const Intrusion = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none">
			<circle cx="16" cy="16" r="16" fill="white" />
			<path d="M8.06044 21H6V11H8.06044V21Z" fill="#38B781" />
			<path
				d="M18.1085 21H16.0481L12.0371 14.4203V21H9.97665V11H12.0371L16.0549 17.5934V11H18.1085V21Z"
				fill="#38B781"
			/>
			<path
				d="M27.2294 12.669H24.1662V21H22.1058V12.669H19.0838V11H27.2294V12.669Z"
				fill="#38B781"
			/>
		</svg>
	);
};
export default Intrusion;
