import { CurrentUserState } from "./types";

export const initState: CurrentUserState = {
  currentUser: null,
  accessToken: "",
  invoices: 0,
  isAuthorized: false,
  isLoading: false,
  unreadMessage: [],
  roles: [],
  doctorInfo: null,
  offices: [],
  profile: null,
};
