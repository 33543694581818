import React, { useEffect } from "react";
import { PreviewUploadFile } from "components/ui";
import { getFilesType } from "utils";
import { IUploadedFile } from "lib/model";

interface Props {
  file: IUploadedFile;
  onDelete?: (name: string) => void;
  onUpload: (params: {
    fileId: string;
    controller: AbortController;
    contentType: string;
  }) => void;
}
export const UploadedFileCard: React.VFC<Props> = ({
  file,
  onDelete,
  onUpload,
}) => {
  const type = getFilesType(file);
  useEffect(() => {
    const controller = new AbortController();
    if ((type === "image" || type === "pdf") && !file.src) {
      onUpload({
        fileId: file.id,
        controller,
        contentType: file.contentType,
      });
    }
    return () => {
      controller.abort();
    };
  }, [file.id, file.src, file.contentType, type]);
  return (
    <PreviewUploadFile
      name={file.name}
      contentType={file.contentType}
      src={file.src}
      onDelete={file.unDeletable ? undefined : onDelete}
    />
  );
};
