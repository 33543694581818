import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, styled } from "@mui/material";
import { IMenuItem, Menu } from "./Menu";

interface Props {
  title: string;
  items: IMenuItem[];
  variant?: "contained" | "outlined";
}
const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  margin-left: 30px;
  vertical-align: text-bottom;
`;
export const MenuButton = ({ title, items, variant = "outlined" }: Props) => {
  const titleElem = (
    <div>
      {title}
      <StyledExpandMoreIcon />
    </div>
  );
  if (items.length === 0) return null;
  return (
    <Menu items={items}>
      <Button sx={{ height: 48 }} variant={variant}>
        {titleElem}
      </Button>
    </Menu>
  );
};
