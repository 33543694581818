import { createReducer } from "@reduxjs/toolkit";
import { CreateStepperState } from "./types";
import { initState } from "./state";
import * as actions from "./actions";

export const reducer = createReducer<CreateStepperState>(
  initState,
  (builder) => {
    builder
      .addCase(actions.incrementTab, (state) => {
        state.currentTab++;
        state.subStep = 0;
      })
      .addCase(actions.decrementTab, (state) => {
        state.currentTab--;
        state.subStep = 0;
      })
      .addCase(actions.incrementSubStep, (state) => {
        state.subStep++;
      })
      .addCase(actions.decrementSubStep, (state) => {
        state.subStep--;
      })
      .addCase(actions.setTab, (state, action) => {
        state.currentTab = action.payload;
        state.subStep = 0;
      })
      .addCase(actions.clearState, () => initState)
      .addCase(actions.setTabStep, (state, action) => {
        state.currentTab = action.payload.currentTab;
        state.subStep = action.payload.subStep;
      });
  }
);
