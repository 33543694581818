import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { actions } from "store/requestRemake";
import { Colors } from "consts/colors";
import { Button } from "components/ui";
import { OrderStatuses } from "lib/enum";

import { ProgressInfo } from "./ProgressInfo";
import { GeneralInfo } from "./GeneralInfo";
import "./styles.css";
import { AdditionalInfo } from "../AdditionalInfo";
import { PaperContainer } from "../Elements";

export const ActiveOrder = () => {
  const { currentOrder, isLoading } = useAppSelector(selectors.currentOrder);
  const orderRemaked = useAppSelector(selectors.remakedOrder);
  const dispatch = useAppDispatch();

  const openHandler = () => {
    dispatch(actions.openWindow());
  };

  const orderTitle = useMemo(() => {
    switch (currentOrder?.status) {
      case OrderStatuses.SUBMITTED_TO_DOCTOR:
        return "Last completed order";
      case OrderStatuses.CANCELED:
        return "Last order(Cancelled)";
      default:
        return "Active orders";
    }
  }, [currentOrder?.status])


  if (!currentOrder && !isLoading) {
    return null;
  }

  return (
    <PaperContainer data-intercom-target="Active order info">
      <Box pt={1.5} px={1.75}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            sx={{ fontWeight: 500 }}
            variant="body1"
            color={Colors.PRIMARY}
          >
            {orderTitle}
          </Typography>
          {/* TODO: OSBA-3716 hidden until needed  */}
          {/* {(currentOrder?.status === OrderStatuses.SUBMITTED_TO_DOCTOR ||
            currentOrder?.status === OrderStatuses.RECEIVED_BY_DOCTOR) && (
            <Button
              sx={{ lineHeight: "1.2", textTransform: "none" }}
              size="small"
              variant="contained"
              onClick={openHandler}
            >
              Request Remake
            </Button>
          )} */}
          {orderRemaked && (
            <Typography variant="body2" color={Colors.PRIMARY}>
              (Remake of previous order)
            </Typography>
          )}
        </Stack>
        <Stack spacing={5} ml={3} mt={6}>
          <GeneralInfo />
          <ProgressInfo />
        </Stack>
      </Box>
      <AdditionalInfo />
    </PaperContainer>
  );
};
