import { createReducer } from "@reduxjs/toolkit";
import { DashboardState } from "./types";
import * as actions from "./actions";
import { initState } from "./state";

export const reducer = createReducer<DashboardState>(initState, (builder) => {
  builder
    .addCase(actions.fetchDashboardEvents.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(actions.fetchDashboardEvents.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(actions.fetchDashboardEvents.fulfilled, (state, action) => {
      return {
        events: action.payload,
        isLoading: false,
      };
    })
    .addCase(actions.remindLater.fulfilled, (state, action) => {
      state.events = state.events.filter(({ id }) => id !== action.payload);
    })
    .addCase(actions.dismissEvent.fulfilled, (state, action) => {
      state.events = state.events.filter(({ id }) => id !== action.payload);
    })
    .addCase(actions.pinDashboardEvent.fulfilled, (state, action) => {
      const eventIndex = state.events.findIndex(
        ({ id }) => action.payload === id
      );
      state.events[eventIndex] = {
        ...state.events[eventIndex],
        pinned: true,
      };
    });
});
