import React from "react";
import { QuestionContainer } from "containers";
import { useNavigate } from "react-router-dom";
import { analyticsMethod } from "plugins/analytics";

export const NewPatientOrderPage = () => {
  analyticsMethod(
    "page",
    "Orders",
    {
      title: "Create form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  const navigate = useNavigate();
  const questions = [
    {
      label: "I’m ready to submit (I have photos, impressions, & prescription)",
      to: "/orders/new/eval",
      noticeTitle: "Notice",
      notice:
        "Click here to order your completed treatment plan immediately\nRequired from you: Photos and Rx Information + Impressions or Scans\nWhat you get: 3D smile animation and a detailed treatment plans",
    },
    {
      label: "I want a preliminary estimate (I have photos only)",
      to: "/orders/new/pre-eval",
      noticeTitle: "Notice",
      notice:
        "Click here if you’d like a preliminary evaluation based on photos\nRequired from you: Photos and Rx Information \nWhat you get: Initial plan assessment with estimated # of steps to help with case selection",
    },
  ];
  const cancelHandler = () => {
    navigate("/orders");
  };
  return (
    <QuestionContainer
      questions={questions}
      title="New Patient Order"
      subTitle="Select one:"
      cancelHandler={cancelHandler}
    />
  );
};
