import { FC, useState } from "react";
import {
  Popover,
  Box,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { CloseIcon } from "assets/vectorIcon";
import { ToothArches } from "components/common";
import { Button } from "components/ui";
import { Colors } from "consts/colors";

import { complications } from "../createEvalCaseOrder/dicts";

const StyledButton = styled(Button)`
  text-transform: none;
  max-width: 150px;
  position: relative;
  &:hover::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
  }
`;

interface Props {
  initComplications: (string | null)[];
  onClick: (toothNumber: number, value: string | null) => void;
  lowerArch: boolean;
  upperArch: boolean;
}
export const ComplicationsArchContainer: FC<Props> = ({
  initComplications,
  onClick,
  upperArch,
  lowerArch,
}) => {
  const [complication, setComplication] = useState<{
    anchorEl: any;
    toothNumber: number;
  } | null>(null);

  const closeHandler = () => setComplication(null);

  const toothClickHandler = (
    event: React.MouseEvent | null,
    toothNumber: string
  ) => {
    setComplication({
      anchorEl: event?.target,
      toothNumber: Number(toothNumber.split("-")[1]) - 1,
    });
  };

  const buttonClickHandler =
    (value: string | null) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (complication) {
        onClick(complication.toothNumber, value);
        setComplication(null);
      }
    };

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={complication?.anchorEl}
        open={Boolean(complication)}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton size="small" onClick={closeHandler}>
            <CloseIcon sx={{ fontSize: 14 }} />
          </IconButton>
        </Box>
        <Stack spacing={1} px={2} pb={2}>
          {complications.map(({ text, fill }: any) => (
            <StyledButton
              key={text}
              sx={{
                backgroundColor: `${fill}!important`,
              }}
              size="small"
              variant="contained"
              onClick={buttonClickHandler(fill)}
            >
              {text}
            </StyledButton>
          ))}
          <Button
            onClick={buttonClickHandler(null)}
            size="small"
            variant="outlined"
          >
            Unselect
          </Button>
        </Stack>
      </Popover>
      <Stack spacing={5} data-intercom-target="Specify objectives">
        <Typography variant="h2" color={Colors.SECONDARY}>
          Specify Implants, Crowns etc.
        </Typography>
        <ToothArches
          onClick={toothClickHandler}
          teeth={initComplications}
          lowerArch={lowerArch}
          upperArch={upperArch}
        />
      </Stack>
    </>
  );
};
