import React from "react";
import { PaperContainer } from "./Elements";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { FormLabel, Select, TextField } from "components/ui";
import {
  iprPreference,
  attachPreference,
  wearSchedule,
} from "lib/dictionaries";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/userPreferences";

export const TreatmentPreferences = () => {
  const {
    isSaving,
    isLoading,
    iprPreference: iprPreferenceField,
    attachPreference: attachPreferenceField,
    additional,
    wearPreference,
    customStep,
  } = useAppSelector(selectors.getAllSelector);
  const dispatch = useAppDispatch();
  const selectChangeHandler = (event: SelectChangeEvent<string>) => {
    dispatch(
      actions.setField({
        fieldName: event.target.name as any,
        value: event.target.value,
      })
    );
  };
  const fieldsChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setField({
        fieldName: event.target.name as any,
        value: event.target.value,
      })
    );
  };
  return (
    <PaperContainer>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <FormLabel label="IPR preferences">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <Select
                  disabled={isSaving}
                  fullWidth
                  data={iprPreference}
                  value={iprPreferenceField}
                  placeholder="Select preference"
                  name="iprPreference"
                  onChange={selectChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
          <Grid item md={3}>
            <FormLabel label="Attachment preferences">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <Select
                  disabled={isSaving}
                  fullWidth
                  data={attachPreference}
                  value={attachPreferenceField}
                  placeholder="Select preference"
                  name="attachPreference"
                  onChange={selectChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
          <Grid item md={3}>
            <FormLabel label="Planned wear schedule">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <Select
                  disabled={isSaving}
                  fullWidth
                  data={wearSchedule}
                  placeholder="Select preference"
                  value={wearPreference}
                  name="wearPreference"
                  onChange={selectChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
          <Grid item md={3}>
            <FormLabel label="Number of steps for continuation">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <TextField
                  disabled={isSaving}
                  placeholder="Enter number"
                  name="customStep"
                  type="number"
                  value={customStep}
                  fullWidth
                  onChange={fieldsChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
          <Grid item md={7}>
            <FormLabel label="Additional preferences">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <TextField
                  multiline
                  disabled={isSaving}
                  rows={3}
                  placeholder="Enter preference"
                  name="additional"
                  value={additional}
                  fullWidth
                  onChange={fieldsChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
        </Grid>
      </Box>
    </PaperContainer>
  );
};
