import { OrderStatuses } from "../enum";

export const orderStatuses: Record<OrderStatuses, string> = {
  DRAFT: "Draft",
  SUBMITTED_TO_LAB: "Submitted to lab",
  RECEIVED_BY_LAB: "Received by lab",
  SUBMITTED_TO_DOCTOR: "Submitted to doctor",
  RECEIVED_BY_DOCTOR: "Received by doctor",
  PROCESSING_IN_LAB: "Processing in lab",
  READY_FOR_SHIPPING: "Ready for shipping",
  HOLD: "Hold",
  INVALID_ORDER_STATUS: "Invalid",
  CANCELED: "Canceled",
};
