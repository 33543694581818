import React from "react";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { StyledSvg } from "./CellElements";

interface IconProps {
  className?: string;
  style?: any;
  value: number | null;
  classNameIcon?: string;
  classNameContainer?: string;
  classNameText?: string;
  color?: string;
}
interface Props extends IconProps {
  value: number | null;
  color?: string;
}

const Degrees = (props: Props) => {
  if (props.value || props.value === 0) {
    return (
      <Box
        position="relative"
        height="100%"
        className={props.classNameContainer}
      >
        <StyledSvg
          width="6"
          height="16"
          viewBox="0 0 6 16"
          fill="none"
          className={clsx(props.className, props.classNameIcon)}
          style={{
            ...props.style,
            transform: `rotate(${props.value || 0}deg)`,
          }}
        >
          <path
            d="M3 -3.52859e-05L0.113249 4.99996H5.88675L3 -3.52859e-05ZM3.5 15.9171L3.5 4.49996H2.5L2.5 15.9171H3.5Z"
            fill={props.color ?? "#2196F3"}
          />
        </StyledSvg>
      </Box>
    );
  }

  return null;
};

export default Degrees;
