import React, { useEffect } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useConfirm } from "hooks/confirm";

import { actions } from "store/currentUser";
import { AUTH_TOKEN_NAME, NEW_PORTAL_COOKIE } from "services/apiConsts";
import { authService } from "services";
import { PreloadContainer } from "containers";

export const CodeInitPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const currentUser = useAppSelector((state) => state.currentUser);
  const searchParams = new URLSearchParams(window.location.search);
  let pathFromStore = "";
  let searchFromStore = "";
  if (localStorage.getItem("redirectPath")) {
    pathFromStore = `${localStorage.getItem("redirectPath") || ""}`;
  }
  if (localStorage.getItem("searchPath")) {
    searchFromStore = localStorage.getItem("searchPath") || "";
  }

  // any used because hook useLocation return unknown type
  const from = (location.state as any)?.from?.pathname || pathFromStore || "/";
  const search = (location.state as any)?.from?.search || searchFromStore || "";
  const code = searchParams.get("code");
  useEffect(() => {
    if (code !== null) {
      dispatch(actions.loginCurrentUser(code))
        .unwrap()
        .then(() => {
          if (localStorage.getItem("redirectPath")) {
            localStorage.removeItem("redirectPath");
          }
          if (localStorage.getItem("searchPath")) {
            localStorage.removeItem("searchPath");
          }
          if (
            document.cookie.indexOf(`${NEW_PORTAL_COOKIE}=false`) !== -1 &&
            `${window.location.origin}/` ===
              process.env.REACT_APP_REDIRECT_URI_OLD
          ) {
            window.location.href = `${from}${search}`;
          }
        })
        .catch((error) => {
          confirm({
            title: "Authorization error",
            description: error.message,
            cancellationButtonProps: {
              sx: {
                display: "none",
              },
            },
          }).then(() => {
            authService.getLogoutLink().then((logOut) => {
              window.location.assign(logOut);
            });
          });
        });
    } else {
      dispatch(actions.fetchCurrentUser())
        .unwrap()
        .catch((error) => {
          confirm({
            title: "Authorization error",
            description: error.message,
            cancellationButtonProps: {
              sx: {
                display: "none",
              },
            },
          }).then(() => {
            localStorage.removeItem(AUTH_TOKEN_NAME);
            authService.getLogoutLink().then((logOut) => {
              window.location.assign(logOut);
            });
          });
        });
    }
  }, [code, dispatch, navigate, from, search]);
  if (currentUser.isAuthorized) {
    return <Navigate to={`${from}${search}`} replace />;
  }
  return <PreloadContainer />;
};
