import api from "./baseService";
import {
  ITreatmentPlanProductionInstructions,
  IExtTreatmentPlanDto,
  IExtToothNoteDto,
} from "lib/dto";
import {
  IBaseResponse,
  IUpdateTreatmentPlanNote,
  ICreateNote,
} from "lib/model";

class TreatmentPlanService {
  private baseUrl = "treatment-plans";
  public async getProductionInstructions(
    caseId: number | string,
    orderId: number | string
  ): Promise<ITreatmentPlanProductionInstructions> {
    const { data } = await api.get<
      IBaseResponse<ITreatmentPlanProductionInstructions>
    >(`${this.baseUrl}/case/${caseId}/${orderId}/production-instructions`);
    return data.result;
  }
  public async getCurrentVersion(
    caseId: number
  ): Promise<IExtTreatmentPlanDto> {
    const { data } = await api.get<IBaseResponse<IExtTreatmentPlanDto>>(
      `${this.baseUrl}/case/${caseId}/current-version`
    );
    return data.result;
  }
  public async getAllNotes(
    treatmentPlanId: number
  ): Promise<IExtToothNoteDto[]> {
    const { data } = await api.get<IBaseResponse<IExtToothNoteDto[]>>(
      `${this.baseUrl}/notes?treatmentPlanId=${treatmentPlanId}`
    );
    return data.result;
  }

  public async createNote(newNote: ICreateNote): Promise<IExtToothNoteDto> {
    const { data } = await api.post<IBaseResponse<IExtToothNoteDto>>(
      `${this.baseUrl}/notes`,
      newNote
    );
    return data.result;
  }

  public async updateNote(note: IUpdateTreatmentPlanNote): Promise<void> {
    await api.put<void>(`${this.baseUrl}/notes`, note);
  }
  public async deleteNote(id: number): Promise<void> {
    await api.delete<void>(`${this.baseUrl}/notes`, {
      data: {
        id,
      },
    });
  }
}

export const treatmentPlanService = new TreatmentPlanService();
