import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";

export const InnerFieldContainer = styled("div")`
  padding-inline: 40px;
  padding-block: 30px 40px;
`;

export const InvoiceListContainer = styled("div")`
  margin-inline: 16px 24px;
  margin-block: 24px 40px;
`;

export const InvoiceInfoContainer = styled(Stack)`
  border: 1px solid var(--container-border-grey-color);
  box-shadow: 0 0 7px rgba(56, 71, 109, 0.1);
  border-radius: 4px;
  padding: 24px;
  margin-inline: 16px 24px;
`;
