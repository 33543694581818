import React from "react";
import Typography from "@mui/material/Typography";

interface ItemProps {
  title: string;
  subTitles?: string[];
}
const LegendItem: React.FC<ItemProps> = ({ title, subTitles, children }) => {
  return (
    <div className="legend__item">
      <span className="legend__item-icon">{children}</span>
      <div>
        <Typography variant="body1" className="legend__item-title">
          {title}
        </Typography>
        {Boolean(subTitles) && (
          <div>
            {subTitles?.map((item) => (
              <span key={item} className="legend__item-subtitle">
                {item}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default LegendItem;
