import { styled } from "@mui/material";
import Box from "@mui/material/Box";

export const SubtitleColumn = styled(Box)`
  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
`;

export const GridColumn = styled(Box)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
`;
