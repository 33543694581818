import { AccountSettingsState } from "./types";

export const intiState: AccountSettingsState = {
  userInfo: null,
  password: "",
  loginDirectLink: "",
  officeSettings: [],
  isLoading: false,
  isSaving: false,
  identities: [],
  officesLogo: {},
};
