import React from "react";
import Stack from "@mui/material/Stack";
import { StyledHeader, StyledImg, StyledContainer } from "./Elements";

import logo from "assets/images/orthosnap-menu-logo.png";

export const EmptyContainer: React.FC = ({ children }) => {
  return (
    <Stack sx={{ height: "100vh" }}>
      <StyledHeader>
        <Stack justifyContent="center">
          <StyledImg src={logo} alt="Orthosnap" />
        </Stack>
      </StyledHeader>
      <StyledContainer>{children}</StyledContainer>
    </Stack>
  );
};
