import { CaseBudgetEnum, CaseBudget } from "lib/enum";

export const caseBudgetSelect = [
  {
    label: CaseBudget[CaseBudgetEnum.LOWER_PRICE],
    value: CaseBudgetEnum.LOWER_PRICE,
  },
  {
    label: CaseBudget[CaseBudgetEnum.BEST_RESULT],
    value: CaseBudgetEnum.BEST_RESULT,
  },
  {
    label: CaseBudget[CaseBudgetEnum.UNKNOW],
    value: CaseBudgetEnum.UNKNOW,
  },
  {
    label: CaseBudget[CaseBudgetEnum.NO_MORE],
    value: CaseBudgetEnum.NO_MORE,
  },
];
