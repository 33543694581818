import React from "react";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/treatmentPlan";

interface Props {
  disabled: boolean;
  planned: boolean;
  cellNum: number;
  rowNum: number;
  cellSelected: boolean;
  treatmentPlanStepId: number;
  children: React.ReactNode;
}
export const CellContainer: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const tpNotes = useAppSelector(selectors.notesSelector);
  const { selectingToothDto } = useAppSelector(selectors.getAllInfo);
  const startSelectHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.shiftKey) {
      dispatch(
        actions.setSelectingStart({
          toothNumber: props.cellNum,
          stepNumber: props.rowNum,
        })
      );
    } else {
      if (!props.cellSelected) {
        dispatch(
          actions.setSelectedCells({
            selectingToothDto: [],
            selectingToothKey: {},
          })
        );
      }
    }
  };
  const selectHandler = () => {
    dispatch(
      actions.setSelectedCell({
        toothNumber: props.cellNum,
        stepNumber: props.rowNum,
      })
    );
  };
  const endSelect = () => {
    dispatch(actions.setSelectingEnd());
  };
  const noteListOpenHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const noteIds: number[] = [];
    if (
      tpNotes.noteCells[props.rowNum] &&
      tpNotes.noteCells[props.rowNum][props.cellNum]
    ) {
      noteIds.push(
        ...tpNotes.noteCells[props.rowNum][props.cellNum].map(
          ({ noteId }) => noteId
        )
      );
    }
    const { width, left, top } = event.currentTarget.getBoundingClientRect();
    dispatch(
      actions.openToShow({
        noteIds,
        anchor: {
          top,
          left: left + width,
        },
        currentTooth: props.cellSelected
          ? selectingToothDto
          : [
              {
                toothNumber: props.cellNum,
                stepNumber: props.rowNum,
              },
            ],
        addNote: true,
      })
    );
  };
  return (
    <Tooltip
      title={
        props.disabled ? "extracted" : props.planned ? "planned extraction" : ""
      }
      placement="top"
    >
      <div
        className={clsx("cell", {
          cell_disabled: props.disabled,
          cell_extracted: props.planned,
          cell__selected: props.cellSelected,
        })}
        onMouseDown={startSelectHandler}
        onMouseMove={selectHandler}
        onMouseUp={endSelect}
        onContextMenu={noteListOpenHandler}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};
