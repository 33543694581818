import { LoadingOverlay } from "components/common";
import { useStyledSnackbar } from "hooks/notification";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { userService } from "services";
import { fetchCurrentUser } from "store/currentUser/actions";

type Params = {
  id: string;
};

export const AuthAsDoctor = () => {
  const params = useParams() as Params;
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.currentUser.currentUser);

  const handleAuthAsDoctor = async () => {
    if (!currentUser?.isAdmin) {
      navigate("/");
      return;
    }
    try {
      await userService.turnOnDoctorMode(Number(params.id));
      dispatch(fetchCurrentUser());
      navigate("/");
    } catch (error) {
      console.error(error)
      showErrorNotice((error as Error).message);
    }
  };

  useEffect(() => {
    handleAuthAsDoctor();
  }, []);

  return <LoadingOverlay text="Authenticating as Doctor" open={true} />;
};
