import React from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";

import { MainContainer, ShippingCreateContainer } from "containers";
import { Header } from "components/common";

import { Footer } from "./components";

export const ShippingCreatePage: React.VFC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  return (
    <MainContainer>
      <Stack sx={{ display: "flex", height: "100%" }}>
        <Header title="Create Shipping Label" />
        <ShippingCreateContainer orderId={orderId!} />
        <Footer />
      </Stack>
    </MainContainer>
  );
};
