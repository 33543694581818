import React from "react";
import Box from "@mui/material/Box";
import { FormLabel, TextField } from "components/ui";
import { selectors, actions } from "store/continuationOrderCreate";
import { useAppDispatch, useAppSelector } from "hooks/redux";

export const OrderNotes = () => {
  const { orderNote } = useAppSelector(selectors.getAllInfoSelector);
  const dispatch = useAppDispatch();
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setNotes(event.target.value));
  };
  return (
    <Box>
      <FormLabel label="If you have any additional treatment preferences, please provide them here:">
        <TextField
          value={orderNote}
          onChange={changeHandler}
          fullWidth
          placeholder="Type notes here..."
          sx={{ maxWidth: 635 }}
        />
      </FormLabel>
    </Box>
  );
};
