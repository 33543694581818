type Tooth = {
	toothPath: string;
	numberPath: string;
	cx?: number;
	cy?: number;
};

export const lowerArchTooth: Tooth[] = [
	{
		toothPath:
			'M42.2785 11.5108C41.7614 20.0715 30.1258 24.7409 19.5245 20.7849C12.8664 18.2556 14.2885 4.37692 19.072 2.10704C29.6087 -3.01639 42.6664 4.83089 42.2785 11.5108Z',
		numberPath: '5.55957px, 15px',
	},
	{
		toothPath:
			'M44.7348 39.2051C46.868 45.431 36.59 54.7051 26.1826 54.7051C19.072 54.7051 17.9084 43.2908 20.4941 40.3076C26.4411 33.4331 42.9895 34.2114 44.7348 39.2051Z',
		numberPath: '6.5791px, 53.1699px',
	},
	{
		toothPath:
			'M50.2291 73.1048C52.5562 79.2659 43.377 88.0859 32.9697 88.0859C25.859 88.0859 23.6612 78.2282 26.2469 75.2449C32.194 68.3705 47.9666 67.1382 50.2291 73.1048Z',
		numberPath: '12.626px, 87.4141px',
	},
	{
		toothPath:
			'M55.8495 106.874C58.1766 113.036 49.9024 120.559 41.305 120.559C35.0347 120.559 31.9965 110.701 34.5822 107.718C39.1072 102.4 53.587 100.908 55.8495 106.874Z',
		numberPath: '19.3418px, 124.455px',
	},
	{
		toothPath:
			'M65.8686 138.828C67.8079 145.119 56.1723 151.474 51.1949 151.15C44.9246 150.761 41.8864 141.811 44.6014 138.828C49.3203 133.769 64.3819 134.029 65.8686 138.828Z',
		numberPath: '31.4082px, 154.576px',
	},
	{
		toothPath:
			'M74.5922 165.725C75.9497 167.022 75.4972 169.357 74.4629 171.043C72.0711 175.064 67.6755 179.863 64.7019 180.187C60.0477 180.706 53.4542 173.831 55.0056 170.264C56.557 166.827 71.554 162.871 74.5922 165.725Z',
		numberPath: '42.4609px, 189.691px',
	},
	{
		toothPath:
			'M89.801 188.461C91.4817 189.499 92.1927 191.379 91.9988 193.325C91.6109 197.995 89.6717 204.999 87.1506 205.842C83.9185 206.879 75.5797 202.599 75.0626 199.227C74.6747 196.568 80.4279 191.055 84.5003 188.72C86.181 187.748 88.1849 187.488 89.801 188.461Z',
		numberPath: '70.5469px, 221.756px',
	},
	{
		toothPath:
			'M112.95 201.96C110.817 201.96 109.007 203.322 108.102 205.268C105.775 209.872 103.577 217.59 105.516 219.86C107.908 222.713 118.057 222.713 120.513 219.86C122.453 217.59 120.384 209.808 117.928 205.268C116.764 203.322 115.083 201.96 112.95 201.96Z',
		numberPath: '112.653px, 240.789px',
	},
	{
		toothPath:
			'M145.05 201.96C147.183 201.96 148.993 203.322 149.898 205.268C152.225 209.872 154.423 217.59 152.484 219.86C150.092 222.713 139.943 222.713 137.487 219.86C135.547 217.59 137.616 209.808 140.072 205.268C141.236 203.322 142.917 201.96 145.05 201.96Z',
		numberPath: '146.249px, 240.887px',
	},
	{
		toothPath:
			'M168.199 188.461C166.518 189.499 165.807 191.379 166.001 193.325C166.389 197.995 168.328 204.999 170.849 205.842C174.081 206.879 182.42 202.599 182.937 199.227C183.325 196.568 177.572 191.055 173.5 188.72C171.819 187.748 169.815 187.488 168.199 188.461Z',
		numberPath: '193.355px, 223.854px',
	},
	{
		toothPath:
			'M183.408 165.725C182.05 167.022 182.503 169.357 183.537 171.043C185.929 175.064 190.325 179.863 193.298 180.187C197.952 180.706 204.546 173.831 202.994 170.264C201.443 166.827 186.446 162.871 183.408 165.725Z',
		numberPath: '217.441px, 187.789px',
	},
	{
		toothPath:
			'M192.131 138.828C190.192 145.119 201.828 151.474 206.805 151.15C213.075 150.761 216.114 141.811 213.399 138.828C208.68 133.769 193.618 134.029 192.131 138.828Z',
		numberPath: '229.494px, 152.674px',
	},
	{
		toothPath:
			'M202.151 106.874C199.823 113.036 208.098 120.559 216.695 120.559C222.965 120.559 226.003 110.701 223.418 107.718C218.893 102.4 204.413 100.908 202.151 106.874Z',
		numberPath: '238.561px, 120.553px',
	},
	{
		toothPath:
			'M207.771 73.1048C205.444 79.2659 214.623 88.0859 225.03 88.0859C232.141 88.0859 234.339 78.2282 231.753 75.2449C225.806 68.3705 210.033 67.1382 207.771 73.1048Z',
		numberPath: '244.904px, 87.0059px',
	},
	{
		toothPath:
			'M213.265 39.2051C211.132 45.431 221.41 54.7051 231.817 54.7051C238.928 54.7051 240.092 43.2908 237.506 40.3076C231.559 33.4331 215.01 34.2114 213.265 39.2051Z',
		numberPath: '251.289px, 51.2734px',
	},
	{
		toothPath:
			'M215.721 11.5108C216.239 20.0715 227.874 24.7409 238.475 20.7849C245.134 18.2556 243.711 4.37692 238.928 2.10704C228.391 -3.01639 215.334 4.83089 215.721 11.5108Z',
		numberPath: '254.309px, 14.1035px',
	},
];
export const upperArchTooth: Tooth[] = [
	{
		toothPath:
			'M41.2785 233.489C40.7614 224.929 29.1258 220.259 18.5245 224.215C11.8664 226.744 13.2885 240.623 18.072 242.893C28.6087 248.016 41.6664 240.169 41.2785 233.489Z',
		numberPath: '2.55957px, 237.789px',
	},
	{
		toothPath:
			'M43.7348 205.795C45.868 199.569 35.5899 190.295 25.1826 190.295C18.072 190.295 16.9084 201.709 19.4941 204.692C25.4411 211.567 41.9895 210.789 43.7348 205.795Z',
		numberPath: '4.26855px, 196.619px',
	},
	{
		toothPath:
			'M49.2291 171.895C51.5562 165.734 42.377 156.914 31.9697 156.914C24.859 156.914 22.6612 166.772 25.2469 169.755C31.194 176.63 46.9666 177.862 49.2291 171.895Z',

		numberPath: '9.9707px, 162.479px',
	},
	{
		toothPath:
			'M54.8495 138.126C57.1766 131.964 48.9024 124.441 40.305 124.441C34.0347 124.441 30.9965 134.299 33.5822 137.282C38.1072 142.6 52.587 144.092 54.8495 138.126Z',
		numberPath: '17.4971px, 128.9463px',
	},
	{
		toothPath:
			'M64.8686 106.172C66.8079 99.8812 55.1723 93.5255 50.1949 93.8498C43.9246 94.2389 40.8864 103.189 43.6014 106.172C48.3203 111.231 63.3819 110.971 64.8686 106.172Z',

		numberPath: '28.165px, 97.6484px',
	},
	{
		toothPath:
			'M73.5922 79.2753C74.9497 77.9782 74.4972 75.6435 73.4629 73.9573C71.0711 69.9364 66.6755 65.1372 63.7019 64.8129C59.0477 64.2941 52.4542 71.1686 54.0056 74.7355C55.557 78.1728 70.554 82.1288 73.5922 79.2753Z',
		numberPath: '40.3477px, 63.9834px',
	},
	{
		toothPath:
			'M88.801 56.5389C90.4817 55.5013 91.1927 53.6205 90.9988 51.6749C90.6109 47.0055 88.6717 40.0013 86.1506 39.1582C82.9185 38.1205 74.5797 42.4009 74.0626 45.7733C73.6747 48.4323 79.4279 53.9448 83.5003 56.2795C85.181 57.2523 87.1849 57.5117 88.801 56.5389Z',
		numberPath: '72.4785px, 31.4316px',
	},
	{
		toothPath:
			'M111.95 43.0397C109.817 43.0397 108.007 41.6778 107.102 39.7322C104.775 35.1276 102.577 27.41 104.516 25.1402C106.908 22.2866 117.057 22.2866 119.513 25.1402C121.453 27.41 119.384 35.1925 116.928 39.7322C115.764 41.6778 114.083 43.0397 111.95 43.0397Z',

		numberPath: '112.3066px, 12.74609px',
	},
	{
		toothPath:
			'M144.05 43.0397C146.183 43.0397 147.993 41.6778 148.898 39.7322C151.225 35.1276 153.423 27.41 151.484 25.1402C149.092 22.2866 138.943 22.2866 136.487 25.1402C134.547 27.41 136.616 35.1925 139.072 39.7322C140.236 41.6778 141.917 43.0397 144.05 43.0397Z',
		numberPath: '146.053px, 12.875px',
	},
	{
		toothPath:
			'M167.199 56.5389C165.518 55.5013 164.807 53.6205 165.001 51.6749C165.389 47.0055 167.328 40.0013 169.849 39.1582C173.081 38.1205 181.42 42.4009 181.937 45.7733C182.325 48.4323 176.572 53.9448 172.5 56.2795C170.819 57.2523 168.815 57.5117 167.199 56.5389Z',
		numberPath: '182.666px, 32.0332px',
	},
	{
		toothPath:
			'M182.408 79.2753C181.05 77.9782 181.503 75.6435 182.537 73.9573C184.929 69.9364 189.325 65.1372 192.298 64.8129C196.952 64.2941 203.546 71.1686 201.994 74.7355C200.443 78.1728 185.446 82.1288 182.408 79.2753Z',

		numberPath: '215.752px, 64.0977px',
	},
	{
		toothPath:
			'M191.131 106.172C189.192 99.8812 200.828 93.5255 205.805 93.8498C212.075 94.2389 215.114 103.189 212.399 106.172C207.68 111.231 192.618 110.971 191.131 106.172Z',
		numberPath: '229.666px, 95.0332px',
	},
	{
		toothPath:
			'M201.151 138.126C198.823 131.964 207.098 124.441 215.695 124.441C221.965 124.441 225.003 134.299 222.418 137.282C217.893 142.6 203.413 144.092 201.151 138.126Z',
		numberPath: '237.752px, 130.0977px',
	},
	{
		toothPath:
			'M206.771 171.895C204.444 165.734 213.623 156.914 224.03 156.914C231.141 156.914 233.339 166.772 230.753 169.755C224.806 176.63 209.033 177.862 206.771 171.895Z',

		numberPath: '243.897px, 163.473px',
	},
	{
		toothPath:
			'M212.265 205.795C210.132 199.569 220.41 190.295 230.817 190.295C237.928 190.295 239.092 201.709 236.506 204.692C230.559 211.567 214.01 210.789 212.265 205.795Z',

		numberPath: '247.944px, 196.619px',
	},
	{
		toothPath:
			'M214.721 233.489C215.239 224.929 226.874 220.259 237.475 224.215C244.134 226.744 242.711 240.623 237.928 242.893C227.391 248.016 214.334 240.169 214.721 233.489Z',

		numberPath: '253.964px, 236.789px',
	},
];
