import * as React from "react";
import { InfoContainer } from "./Elements";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Colors } from "consts/colors";

export const SpecialInstructions = () => {
  const { currentOrder } = useAppSelector(selectors.currentOrder);

  if (!currentOrder?.specialInstruction) {
    return (
      <Stack direction="row" justifyContent="center">
        <Typography
          variant="body1"
          fontWeight="bold"
          color={Colors.TEXT_PRIMARY}
        >
          There are no special instructions for this order.
        </Typography>
      </Stack>
    );
  }
  return (
    <InfoContainer>
      <Typography variant="body2">
        {currentOrder?.specialInstruction}
      </Typography>
    </InfoContainer>
  );
};
