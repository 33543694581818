import React from 'react';

const Rotation = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none">
			<circle cx="16" cy="16" r="16" fill="white" />
			<path
				d="M8.20505 10.6667L10.945 18.4029L13.6702 10.6667H16.5567V21.3333H14.3516V18.4176L14.5713 13.3846L11.6922 21.3333H10.1831L7.31127 13.3919L7.53105 18.4176V21.3333H5.33325V10.6667H8.20505Z"
				fill="#F759AB"
			/>
			<path
				d="M22.4248 17.4286H20.6739V21.3333H18.4761V10.6667H22.4395C23.6996 10.6667 24.6715 10.9475 25.3552 11.5091C26.039 12.0708 26.3809 12.8645 26.3809 13.8901C26.3809 14.6178 26.2221 15.2259 25.9047 15.7143C25.5921 16.1978 25.1159 16.5836 24.4761 16.8718L26.7838 21.2308V21.3333H24.4248L22.4248 17.4286ZM20.6739 15.6483H22.4468C22.9987 15.6483 23.426 15.5091 23.7289 15.2308C24.0317 14.9475 24.1831 14.5592 24.1831 14.0659C24.1831 13.5629 24.039 13.1673 23.7508 12.8791C23.4676 12.591 23.0304 12.4469 22.4395 12.4469H20.6739V15.6483Z"
				fill="#F759AB"
			/>
		</svg>
	);
};
export default Rotation;
