import React, { useCallback, useState } from "react";

import { FilesGallery, Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/patientDetail";

export const ViewTpVideoButton = () => {
  const dispatch = useAppDispatch();
  const tpVideoFiles = useAppSelector(selectors.tpVideoFiles);
  const [open, setOpen] = useState<boolean>(false);
  const openHandler = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };
  const downloadFileHandler = useCallback(
    async (
      fileId: number,
      controller: AbortController,
      contentType?: string
    ) => {
      const { src } = await dispatch(
        actions.downloadFile({ fileId, contentType, controller })
      ).unwrap();
      return src;
    },
    [dispatch]
  );
  if (!tpVideoFiles.length) {
    return null;
  }
  return (
    <>
      <FilesGallery
        fileDownloadHandler={downloadFileHandler}
        open={open}
        files={tpVideoFiles}
        onClose={closeHandler}
      />
      <Button
        variant="outlined"
        data-intercom-target="TP videos view button"
        onClick={openHandler}
      >
        Pre-evaluation video(s)
      </Button>
    </>
  );
};
