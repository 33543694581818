import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { FilesGallery } from "components/ui";
import { selectors, actions } from "store/patientDetail";

export const VerificationFilesModal = () => {
  const dispatch = useAppDispatch();
  const { openVerification, caseInfo } = useAppSelector(
    selectors.allInfoSelector
  );
  const preEvalFiles = useAppSelector(selectors.preEvaluationFiles);
  const closeHandler = () => {
    dispatch(actions.setOpenVerification(false));
  };
  const downloadFileHandler = useCallback(
    async (
      fileId: number,
      controller: AbortController,
      contentType?: string
    ) => {
      const { src } = await dispatch(
        actions.downloadFile({ fileId, contentType, controller })
      ).unwrap();
      return src;
    },
    [dispatch]
  );
  if (!preEvalFiles.length) {
    return null;
  }
  return (
    <>
      <FilesGallery
        fileDownloadHandler={downloadFileHandler}
        open={openVerification && Boolean(caseInfo && preEvalFiles.length)}
        files={preEvalFiles}
        onClose={closeHandler}
      />
    </>
  );
};
