export enum ToothsComplication {
  IMPLANTS = "implants",
  ANKYLOSED = "ankylosed",
  IMPACTED = "impacted",
  UNERRUPTED = "unerrupted",
  DO_NOT_MOVE = "doNotMove",
  VENEERS = "veneers",
  BRIDGE = "bridge",
  UNSELECT = "unselect",
}
