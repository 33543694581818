import React, { useState } from "react";
import { PromtWithDescription } from "components/common";
import { Button } from "components/ui";

interface Props {
  onTpReject: (description: string) => Promise<any>;
}

export const RejectTPButton: React.VFC<Props> = ({ onTpReject }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <PromtWithDescription
        open={open}
        onClose={() => setOpen(false)}
        title="Confirm rejecting the treatment plan? Please ensure you send a message with requested changes so we can prepare a new one."
        onSubmit={onTpReject}
        confirmationButtonText="Reject"
      />
      <Button
        data-intercom-target="Reject TP button"
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        Reject TP
      </Button>
    </>
  );
};
