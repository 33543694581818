import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import { TextField, Button } from "components/ui";
import { useStyledSnackbar } from "hooks/notification";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (description: string) => Promise<unknown>;
  title: string;
  confirmationButtonText?: string;
  cancelationButtonText?: string;
}
export const PromtWithDescription: React.VFC<Props> = ({
  open,
  onClose,
  onSubmit,
  title,
  confirmationButtonText,
  cancelationButtonText,
}) => {
  const { showErrorNotice } = useStyledSnackbar();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const reasonChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
    if (hasError) {
      setHasError(false);
    }
  };
  const execHandler = () => {
    if (!reason) {
      setHasError(true);
      return;
    }
    setLoading(true);
    onSubmit(reason)
      .then(() => {
        setReason("");
        setLoading(false);
        onClose();
      })
      .catch((err: Error) => {
        setLoading(false);
        showErrorNotice(err.message);
      });
  };
  const closeHandler = () => {
    setReason("");
    if (hasError) {
      setHasError(false);
    }
    onClose();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ paddingTop: "8px!important" }}>
        <TextField
          fullWidth
          placeholder="Type notes here..."
          multiline
          rows={3}
          value={reason}
          onChange={reasonChangeHandler}
          error={hasError}
          helperText={hasError ? "Please enter reason" : undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={closeHandler}>
          {cancelationButtonText ?? "Cancel"}
        </Button>
        <Button
          loading={loading}
          variant="contained"
          color="primary"
          onClick={execHandler}
        >
          {confirmationButtonText ?? "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
