import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { IIprToothDto, IAttachmentToothDto } from "lib/dto";

import { actions, selectors } from "store/treatmentPlan";
import { attachmentType } from "lib/dictionaries";
import { AttachmentType } from "lib/enum";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import {
  GeneralAttachContainer,
  GeneralGridColumns,
  GeneralIprContainer,
  GeneralHeadItem,
  GeneralEmptyValue,
  GeneralSubTitles,
  GeneralSubtitlesColumns,
} from "./TableElements";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export const GeneralParams = () => {
  const dispatch = useAppDispatch();
  const treatmentPlanInstructions = useAppSelector(
    selectors.planInstructionsSelector
  );
  const treatmentPlan = useAppSelector(selectors.treatPlanSelector);
  const upperSteps = treatmentPlanInstructions.flatMap(
    ({ upperSteps }: any) => upperSteps
  );
  const lowerSteps = treatmentPlanInstructions.flatMap(
    ({ lowerSteps }: any) => lowerSteps
  );

  const attachmentCells = (steps: any, incrementIndex: number) => {
    return (
      <GeneralGridColumns>
        {Array(8)
          .fill("")
          .map((_, index) => {
            const calculatedToothNum = Math.abs(index + incrementIndex);
            const toothAttachment: IAttachmentToothDto | undefined =
              treatmentPlan?.attachmentToothList?.find(
                ({ toothNumber }: IAttachmentToothDto) =>
                  toothNumber === calculatedToothNum + 1
              );
            if (toothAttachment && toothAttachment.attachmentType) {
              return (
                <GeneralAttachContainer
                  key={`toothAttach_${calculatedToothNum}`}
                >
                  {toothAttachment.attachmentType && (
                    <Typography variant="body2" fontSize={10} fontWeight={500}>
                      {
                        attachmentType[
                          toothAttachment.attachmentType as AttachmentType
                        ]
                      }
                    </Typography>
                  )}
                </GeneralAttachContainer>
              );
            }
            return (
              <GeneralHeadItem key={`toothAttach_${calculatedToothNum}`}>
                <GeneralEmptyValue />
              </GeneralHeadItem>
            );
          })}
      </GeneralGridColumns>
    );
  };
  const iprCells = (steps: any, incrementIndex: number) => {
    return (
      <GeneralGridColumns>
        {Array(8)
          .fill("")
          .map((_, index) => {
            const calculatedToothNum = Math.abs(index + incrementIndex);
            const toothIPR: IIprToothDto | undefined =
              treatmentPlan?.iprToothList?.find(
                ({ toothNumber }: IIprToothDto) =>
                  toothNumber === calculatedToothNum + 1
              );
            if (toothIPR && (toothIPR.mesial || toothIPR.distal)) {
              return (
                <GeneralIprContainer
                  key={`toothIpr_${calculatedToothNum}`}
                  onMouseEnter={() =>
                    dispatch(
                      actions.setIPRHover({
                        toothNumber: calculatedToothNum,
                        mesial: toothIPR?.mesial || null,
                        distal: toothIPR?.distal || null,
                      })
                    )
                  }
                  onMouseLeave={() => dispatch(actions.setIPRHover(null))}
                >
                  {toothIPR.mesial && (
                    <Typography
                      variant="body2"
                      fontSize={10}
                      fontWeight={500}
                    >{`m ${toothIPR.mesial}`}</Typography>
                  )}
                  {toothIPR.distal && (
                    <Typography
                      variant="body2"
                      fontSize={10}
                      fontWeight={500}
                    >{`d ${toothIPR.distal}`}</Typography>
                  )}
                </GeneralIprContainer>
              );
            }
            return (
              <GeneralHeadItem key={`toothIpr_${calculatedToothNum}`}>
                <GeneralEmptyValue />
              </GeneralHeadItem>
            );
          })}
      </GeneralGridColumns>
    );
  };
  return (
    <>
      <Box mt={1}>
        <Stack direction="row" alignItems="center">
          <Box width={93} textAlign="right" pr={0.5}>
            <Typography
              fontSize={10}
              variant="body2"
              color="primary"
              fontWeight="bold"
            >
              Attachment
            </Typography>
          </Box>
          <GeneralSubTitles>
            <GeneralSubtitlesColumns>
              {attachmentCells(upperSteps, 0)}
              {attachmentCells(upperSteps, 8)}
            </GeneralSubtitlesColumns>
            <GeneralSubtitlesColumns>
              {attachmentCells(lowerSteps, -31)}
              {attachmentCells(lowerSteps, -23)}
            </GeneralSubtitlesColumns>
          </GeneralSubTitles>
        </Stack>
        <Divider sx={{ marginInline: "93px" }} />
        <Stack direction="row" alignItems="center">
          <Box width={93} textAlign="right" pr={0.5}>
            <Typography
              fontSize={10}
              variant="body2"
              color="primary"
              fontWeight="bold"
            >
              IPR
            </Typography>
          </Box>
          <GeneralSubTitles>
            <GeneralSubtitlesColumns>
              {iprCells(upperSteps, 0)}
              {iprCells(upperSteps, 8)}
            </GeneralSubtitlesColumns>
            <GeneralSubtitlesColumns>
              {iprCells(lowerSteps, -31)}
              {iprCells(lowerSteps, -23)}
            </GeneralSubtitlesColumns>
          </GeneralSubTitles>
        </Stack>
      </Box>
    </>
  );
};
