import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  authService,
  fileService,
  officeSettingsService,
  userService,
} from "services";
import { OfficeSettingType } from "./types";
import { IUser } from "lib/model";
import { LoginProviders, OfficeSettings } from "lib/enum";
import { IRoleDto, IOfficeSetting, IIdentity, IFileDto } from "lib/dto";

export const setPassword = createAction<string>("setting/password/set");
export const clearState = createAction("settings/clear");
export const setUserFields = createAction<{
  value: string;
  name: "firstName" | "lastName" | "email";
}>("settings/user/set");

export const setOfficeSetting = createAction<{
  officeId: number;
  value:
    | OfficeSettings.NOTIFY_PER_INVOICE
    | OfficeSettings.NOTIFY_OUTSTANDING_INVOICE;
}>("settings/office/set");

export const setOfficeTreshold = createAction<{
  officeId: number;
  value: string;
}>("settings/treshold/set");

export const unlinkSocialMediaAccount = createAsyncThunk<
  string,
  {
    accountUserId: string;
    provider: LoginProviders;
  }
>("settings/smAccount/unlink", async (params) => {
  await userService.unlinkIdentity(params.accountUserId, params.provider);
  return params.accountUserId;
});

export const fetchFiles = createAsyncThunk<
  {
    files: IFileDto[];
    officeId: number;
  },
  number
>("settings/officeLogo/fetch", async (officeId) => {
  const files = await officeSettingsService.getOfficeImageLogo(officeId);
  return {
    files,
    officeId,
  };
});

export const downloadFile = createAsyncThunk<
  {
    src: string;
    fileId: string;
    officeId: string;
  },
  {
    fileId: string;
    controller: AbortController;
    contentType: string;
    officeId: number;
  }
>(
  "settings/officeLogo/download",
  async ({ contentType, fileId, controller, officeId }) => {
    const src = await fileService.getFile(
      fileId,
      contentType,
      controller.signal
    );
    return {
      src,
      fileId,
      officeId: officeId.toString(),
    };
  }
);

export const uploadFile = createAsyncThunk<
  {
    src: string;
    id: string;
    name: string;
    officeId: string;
    contentType: string;
  },
  {
    src: Blob;
    fileName: string;
    officeId: number;
    contentType: string;
  }
>("settings/logo/upload", async ({ officeId, fileName, src, contentType }) => {
  await officeSettingsService.addOfficeImages(
    officeId,
    new File([src], fileName, { type: contentType })
  );
  return {
    officeId: officeId.toString(),
    name: fileName,
    id: "",
    src: URL.createObjectURL(src),
    contentType,
  };
});

export const deletedFile = createAsyncThunk<
  {
    officeId: string;
    fileId: string;
    name: string;
  },
  {
    fileId: string;
    officeId: number;
    name: string;
  }
>("settings/logo/delete", async ({ officeId, fileId, name }) => {
  if (fileId) {
    await officeSettingsService.deleteOfficeImageLogo(officeId, [
      Number(fileId),
    ]);
  }

  return {
    fileId,
    officeId: officeId.toString(),
    name,
  };
});

export const fetchAccountSettings = createAsyncThunk<{
  officeSettings: OfficeSettingType[];
  userInfo: IUser;
  roles: IRoleDto[];
  loginDirectLink: string;
  identities: IIdentity[];
}>("settings/fetch", async (_, thunkApi) => {
  const { currentUser } = thunkApi.getState() as RootState;
  const loginDirectLink = await authService.getLoginLink(
    `${window.origin}/link-account`
  );
  const identities = await userService.getUserIdentities();

  if (!currentUser.offices || !currentUser.offices.length) {
    return {
      officeSettings: [],
      loginDirectLink,
      identities,
      userInfo: currentUser.currentUser!,
      roles: currentUser.roles!,
    };
  }
  const officesSettings = await Promise.all(
    currentUser.offices!.map(({ id }) =>
      officeSettingsService.getOfficeSettingsByOfficeId(id)
    )
  );

  return {
    userInfo: currentUser.currentUser!,
    roles: currentUser.roles!,
    loginDirectLink,
    identities,
    officeSettings: officesSettings.map(
      ({ NOTIFY_OUTSTANDING_INVOICE, NOTIFY_PER_INVOICE }, index) => ({
        officeId: currentUser.offices![index].id,
        officeName: currentUser.offices![index].name,
        noticePerInvoiceId: NOTIFY_PER_INVOICE?.id,
        noticeOutInvoiceId: NOTIFY_OUTSTANDING_INVOICE?.id,
        [OfficeSettings.NOTIFY_PER_INVOICE]: NOTIFY_PER_INVOICE?.value || null,
        [OfficeSettings.NOTIFY_OUTSTANDING_INVOICE]:
          NOTIFY_OUTSTANDING_INVOICE?.value || null,
      })
    ),
  };
});

export const saveChanges = createAsyncThunk(
  "settings/office/save",
  async (_, thunkAPI) => {
    const { accountSettings } = thunkAPI.getState() as RootState;
    await Promise.all(
      accountSettings.officeSettings.map((setting) => {
        const officeSetting: IOfficeSetting[] = [];
        if (setting[OfficeSettings.NOTIFY_PER_INVOICE]) {
          officeSetting.push({
            id: setting.noticePerInvoiceId,
            value: setting[OfficeSettings.NOTIFY_PER_INVOICE] as any,
            key: OfficeSettings.NOTIFY_PER_INVOICE,
            officeId: setting.officeId,
          });
        }
        if (setting[OfficeSettings.NOTIFY_OUTSTANDING_INVOICE]) {
          officeSetting.push({
            id: setting.noticeOutInvoiceId,
            value: setting[OfficeSettings.NOTIFY_OUTSTANDING_INVOICE] as any,
            key: OfficeSettings.NOTIFY_OUTSTANDING_INVOICE,
            officeId: setting.officeId,
          });
        }
        return officeSettingsService.createAccountSetting(
          setting.officeId,
          officeSetting
        );
      })
    );

    await userService.updateUser({
      password: accountSettings.password ? accountSettings.password : undefined,
      lastName: accountSettings.userInfo?.lastName || "",
      firstName: accountSettings.userInfo?.firstName || "",
      email: accountSettings.userInfo?.email || "",
      phone: accountSettings.userInfo?.phone || "",
      id: accountSettings.userInfo?.userId!,
    });
  }
);
