import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { upsServiceForShippingLevel, packageTypes } from "lib/dictionaries";

import { UserPreferencesState } from "./types";

const selectSelf = (state: RootState) => state.userPreferences;

export const getAllSelector = createSelector(
  selectSelf,
  (state: UserPreferencesState) => state
);

export const getShippingPreferences = createSelector(
  selectSelf,
  (state: UserPreferencesState) => {
    if (!state.shippingRate || !state.shippingLevel) {
      return {
        upsService: "",
        packageType: "",
      };
    }
    return {
      upsService: (upsServiceForShippingLevel as any)[
        (state.shippingRate as any)[state.shippingLevel].serviceCode
      ],
      packageType: (packageTypes as any)[
        (state.shippingRate as any)[state.shippingLevel].packageType
      ],
    };
  }
);

export const shippingPrefISSame = createSelector(
  selectSelf,
  (state: UserPreferencesState) => {
    return state.shippingRate
      ? state.shippingRate.fastest.serviceCode ===
          state.shippingRate.cheapest.serviceCode &&
          state.shippingRate.fastest.packageType ===
            state.shippingRate.cheapest.packageType
      : false;
  }
);
