import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
} from "./Elements";
import { Row } from "./Row";
import { ICaseSearchDto, IPatientSearchDto } from "lib/dto";

interface Props {
  entityName: "invoices" | "patients";
  data: {
    entityId: string;
    entityName: "patients" | "invoices";
    entityData: IPatientSearchDto | ICaseSearchDto;
  }[];
  expanded?: boolean;
}
const capitalize = (str: string) => {
  return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
};
export const EntityGroup = ({ entityName, data, expanded }: Props) => {
  return (
    <ExpansionPanel defaultExpanded={expanded}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{capitalize(entityName)}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {data.map((item) => (
          <Row
            key={`${item.entityName}_${item.entityId}`}
            entityId={item.entityId}
            entityInfo={
              {
                entity: item.entityName,
                dataItem: item.entityData,
              } as any
            }
          />
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default EntityGroup;
