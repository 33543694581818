import { styled } from "@mui/material";
import Box from "@mui/material/Box";

export const FbmContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  column-gap: 12px;
`;
