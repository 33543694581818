import { createReducer, AnyAction, AsyncThunk } from "@reduxjs/toolkit";
import { CreatePaymentState } from "./types";
import { initState } from "./initState";
import * as actions from "./actions";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.startsWith("payment") && action.type.endsWith("/rejected");
}
export const reducer = createReducer<CreatePaymentState>(
  initState,
  (builder) => {
    builder
      .addCase(actions.selectInvoice, (state, action) => {
        if (state.selectedInvoices.includes(action.payload)) {
          state.selectedInvoices = state.selectedInvoices.filter(
            (item) => item !== action.payload
          );
        } else {
          state.selectedInvoices = [...state.selectedInvoices, action.payload];
        }
      })
      .addCase(actions.changeOrg, (state, action) => {
        state.org = action.payload;
        state.office = "";
        state.offices = [];
        state.invoices = [];
        state.outstandingBalance = 0;
        state.invoiceAmount = 0;
        state.errors = "";
      })
      .addCase(actions.changeOffice, (state, action) => {
        state.office = action.payload;
        if (action.payload) {
          const office = state.offices.find(
            ({ id }) => id === Number(action.payload)
          );
          state.outstandingBalance = office!.balance || 0;
        }
        state.invoices = [];
      })
      .addCase(actions.changeNote, (state, action) => {
        state.notes = action.payload;
      })
      .addCase(actions.changePayment, (state, action) => {
        state.paymentMethod = action.payload;
      })
      .addCase(actions.createPayment.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(actions.calculateInvoiceInfo.fulfilled, (state, action) => {
        state.outstandingBalance = action.payload.outstandingBalance;
        state.invoiceAmount = action.payload.invoiceAmount;
      })
      .addCase(actions.getOrgs.fulfilled, (state, action) => {
        state.orgs = action.payload;
        if (action.payload.length === 1) {
          state.org = action.payload[0].id.toString();
        }
      })
      .addCase(actions.getOffices.fulfilled, (state, action) => {
        state.offices = action.payload;
        if (action.payload.length === 1) {
          state.office = action.payload[0].id.toString();
        }
      })
      .addCase(actions.getInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.getAllInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.getInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload || [];
        state.isLoading = false;
      })
      .addCase(actions.getAllInfo.fulfilled, (state, action) => {
        state.org = action.payload.org.id.toString();
        state.office = action.payload.office.id.toString();
        state.offices = [action.payload.office];
        state.orgs = [action.payload.org];
        state.invoices = action.payload.invoices || [];
        state.outstandingBalance = action.payload.office.balance || 0;
        state.invoiceAmount = 0;
        state.isLoading = false;
        state.errors = "";
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.errors =
          (action.error as any)?.message ?? (action.error as any).toString();
        state.isLoading = false;
        state.isSaving = false;
      });
  }
);
