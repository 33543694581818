import { TorqueType } from "lib/enum";
import React from "react";
interface IconProps {
  className?: string;
  style?: any;
}
interface Props extends IconProps {
  side: "left" | "right";
  value: TorqueType | null;
}

export const TorqueMesial = (props: Omit<Props, "value">) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={props.className}
      style={props.style}
    >
      <path
        d="M14.6792 26L16.7342 31.8022L18.7781 26H20.943V34H19.2891V31.8132L19.454 28.0385L17.2946 34H16.1628L14.0089 28.044L14.1737 31.8132V34H12.5254V26H14.6792Z"
        fill="#9254DE"
      />
      <path
        d="M28.2397 27.3352H25.7891V34H24.1408V27.3352H21.7232V26H28.2397V27.3352Z"
        fill="#9254DE"
      />

      {props.side === "left" ? (
        <path
          d="M24.0694 18.0997L26.6888 15.143C23.2721 12.6949 13.25 9.5312 3 18.0997C10.5166 4.6349 24.0694 5.8419 30.3902 10.7014L32.611 7L37.0526 19.3238L24.0694 18.0997Z"
          fill="#9254DE"
        />
      ) : (
        <path
          d="M15.9306 19.0997L13.3112 16.143C16.7279 13.6949 26.75 10.5312 37 19.0997C29.4834 5.6349 15.9306 6.8419 9.60983 11.7014L7.38901 8L2.94736 20.3238L15.9306 19.0997Z"
          fill="#9254DE"
        />
      )}
    </svg>
  );
};

export const TorqueDistal = (props: Omit<Props, "value">) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={props.className}
      style={props.style}
    >
      <path
        d="M14.2959 34V26H16.7574C17.4607 26 18.0889 26.1593 18.6421 26.478C19.1988 26.793 19.6329 27.2436 19.9442 27.8297C20.2556 28.4121 20.4113 29.0751 20.4113 29.8187V30.1868C20.4113 30.9304 20.2574 31.5916 19.9497 32.1703C19.6457 32.7491 19.2153 33.1978 18.6585 33.5165C18.1018 33.8352 17.4736 33.9963 16.7739 34H14.2959ZM15.9442 27.3352V32.6758H16.741C17.3856 32.6758 17.8783 32.4652 18.219 32.044C18.5596 31.6227 18.7336 31.0201 18.741 30.2363V29.8132C18.741 29 18.5725 28.3846 18.2355 27.967C17.8985 27.5458 17.4058 27.3352 16.7574 27.3352H15.9442Z"
        fill="#9254DE"
      />
      <path
        d="M27.164 27.3352H24.7135V34H23.0651V27.3352H20.6475V26H27.164V27.3352Z"
        fill="#9254DE"
      />
      {props.side === "left" ? (
        <path
          d="M15.9306 18.0997L13.3112 15.143C16.7279 12.6949 26.75 9.5312 37 18.0997C29.4834 4.6349 15.9306 5.8419 9.60983 10.7014L7.38901 7L2.94736 19.3238L15.9306 18.0997Z"
          fill="#9254DE"
        />
      ) : (
        <path
          d="M24.0694 19.0997L26.6888 16.143C23.2721 13.6949 13.25 10.5312 3 19.0997C10.5166 5.6349 24.0694 6.8419 30.3902 11.7014L32.611 8L37.0526 20.3238L24.0694 19.0997Z"
          fill="#9254DE"
        />
      )}
    </svg>
  );
};

export const TorquePalatal = (props: IconProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M15.6845 31.1813V34H14.0361V26H17.157C17.7577 26 18.2852 26.1099 18.7394 26.3297C19.1973 26.5495 19.549 26.8626 19.7944 27.2692C20.0398 27.6722 20.1625 28.1319 20.1625 28.6484C20.1625 29.4322 19.8933 30.0513 19.3548 30.5055C18.82 30.956 18.0783 31.1813 17.1295 31.1813H15.6845ZM15.6845 29.8462H17.157C17.5929 29.8462 17.9244 29.7436 18.1515 29.5385C18.3823 29.3333 18.4977 29.0403 18.4977 28.6593C18.4977 28.2674 18.3823 27.9505 18.1515 27.7088C17.9207 27.467 17.6021 27.3425 17.1955 27.3352H15.6845V29.8462Z"
        fill="#9254DE"
      />
      <path
        d="M27.3218 27.3352H24.8713V34H23.2229V27.3352H20.8054V26H27.3218V27.3352Z"
        fill="#9254DE"
      />
      <path
        d="M26.3529 13.1176L20.1765 2L14 13.1176H17.7059L20.1765 23L22.6471 13.1176H26.3529Z"
        fill="#9254DE"
      />
    </svg>
  );
};

export const TorqueLingual = (props: IconProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M26.3529 13.1176L20.1765 2L14 13.1176H17.7059L20.1765 23L22.6471 13.1176H26.3529Z"
        fill="#9254DE"
      />
      <path
        d="M14.4004 34V26H16.0918V32.6055H19.5215V34H14.4004ZM19.3379 27.3945V26H25.9082V27.3945H23.459V34H21.7871V27.3945H19.3379Z"
        fill="#9254DE"
      />
    </svg>
  );
};

export const TorqueBucctal = (props: IconProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M14.1797 34V26H16.9819C17.9526 26 18.6888 26.1868 19.1907 26.5604C19.6925 26.9304 19.9434 27.4744 19.9434 28.1923C19.9434 28.5842 19.8427 28.9304 19.6412 29.2308C19.4398 29.5275 19.1595 29.7454 18.8006 29.8846C19.2108 29.9872 19.5332 30.1941 19.7676 30.5055C20.0057 30.8168 20.1247 31.1978 20.1247 31.6484C20.1247 32.4176 19.8793 33 19.3885 33.3956C18.8976 33.7912 18.198 33.9927 17.2896 34H14.1797ZM15.828 30.5165V32.6758H17.2401C17.6284 32.6758 17.9306 32.5842 18.1467 32.4011C18.3665 32.2143 18.4764 31.9579 18.4764 31.6319C18.4764 30.8993 18.0973 30.5275 17.339 30.5165H15.828ZM15.828 29.3516H17.0478C17.8793 29.337 18.2951 29.0055 18.2951 28.3571C18.2951 27.9945 18.1888 27.7344 17.9764 27.5769C17.7676 27.4158 17.4361 27.3352 16.9819 27.3352H15.828V29.3516Z"
        fill="#9254DE"
      />
      <path
        d="M27.2346 27.3352H24.7841V34H23.1357V27.3352H20.7181V26H27.2346V27.3352Z"
        fill="#9254DE"
      />
      <path
        d="M26.3529 11.8824L20.1765 23L14 11.8824H17.7059L20.1765 2L22.6471 11.8824H26.3529Z"
        fill="#9254DE"
      />
    </svg>
  );
};

export const TorqueMesialPalatal = (props: Omit<Props, "value">) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M31.5165 28.3352H29.066V35H27.4176V28.3352H25V27H31.5165V28.3352Z"
        fill="#9254DE"
      />
      <path
        d="M10.1538 27L12.2088 32.8022L14.2527 27H16.4176V35H14.7637V32.8132L14.9286 29.0385L12.7692 35H11.6374L9.4835 29.044L9.6483 32.8132V35H8V27H10.1538Z"
        fill="#9254DE"
      />
      <path
        d="M19.6484 32.1813V35H18V27H21.1209C21.7216 27 22.2491 27.1099 22.7033 27.3297C23.1612 27.5495 23.5129 27.8626 23.7583 28.2692C24.0037 28.6722 24.1264 29.1319 24.1264 29.6484C24.1264 30.4322 23.8572 31.0513 23.3187 31.5055C22.7839 31.956 22.0422 32.1813 21.0934 32.1813H19.6484ZM19.6484 30.8462H21.1209C21.5568 30.8462 21.8883 30.7436 22.1154 30.5385C22.3462 30.3333 22.4616 30.0403 22.4616 29.6593C22.4616 29.2674 22.3462 28.9505 22.1154 28.7088C21.8846 28.467 21.566 28.3425 21.1594 28.3352H19.6484V30.8462Z"
        fill="#9254DE"
      />
      {props.side === "left" ? (
        <path
          d="M26.7227 16.5961L30.2167 4.36745L17.9879 7.86133L20.6084 10.4818L15.3674 19.2167L24.1023 13.9758L26.7227 16.5961Z"
          fill="#9254DE"
        />
      ) : (
        <path
          d="M21.5961 7.86133L9.36745 4.36737L12.8613 16.5961L15.4818 13.9757L24.2167 19.2166L18.9758 10.4817L21.5961 7.86133Z"
          fill="#9254DE"
        />
      )}
    </svg>
  );
};

export const TorqueMesialBucctal = (props: Omit<Props, "value">) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M31.5165 28.3352H29.066V35H27.4176V28.3352H25V27H31.5165V28.3352Z"
        fill="#9254DE"
      />
      <path
        d="M10.1538 27L12.2088 32.8022L14.2527 27H16.4176V35H14.7637V32.8132L14.9286 29.0385L12.7692 35H11.6374L9.4835 29.044L9.6483 32.8132V35H8V27H10.1538Z"
        fill="#9254DE"
      />

      <path
        d="M18 35V27H20.8022C21.7729 27 22.5091 27.1868 23.011 27.5604C23.5128 27.9304 23.7637 28.4744 23.7637 29.1923C23.7637 29.5842 23.663 29.9304 23.4615 30.2308C23.2601 30.5275 22.9798 30.7454 22.6209 30.8846C23.0311 30.9872 23.3535 31.1941 23.5879 31.5055C23.826 31.8168 23.945 32.1978 23.945 32.6484C23.945 33.4176 23.6996 34 23.2088 34.3956C22.7179 34.7912 22.0183 34.9927 21.1099 35H18ZM19.6483 31.5165V33.6758H21.0604C21.4487 33.6758 21.7509 33.5842 21.967 33.4011C22.1868 33.2143 22.2967 32.9579 22.2967 32.6319C22.2967 31.8993 21.9176 31.5275 21.1593 31.5165H19.6483ZM19.6483 30.3516H20.8681C21.6996 30.337 22.1154 30.0055 22.1154 29.3571C22.1154 28.9945 22.0091 28.7344 21.7967 28.5769C21.5879 28.4158 21.2564 28.3352 20.8022 28.3352H19.6483V30.3516Z"
        fill="#9254DE"
      />

      {props.side === "left" ? (
        <path
          d="M16.9879 18.7227L29.2166 22.2167L25.7227 9.98791L23.1023 12.6084L14.3674 7.36745L19.6083 16.1023L16.9879 18.7227Z"
          fill="#9254DE"
        />
      ) : (
        <path
          d="M17.289 9.60845L13.795 21.8372L26.0238 18.3433L23.4034 15.7228L28.6443 6.98791L19.9094 12.2288L17.289 9.60845Z"
          fill="#9254DE"
        />
      )}
    </svg>
  );
};

export const TorqueMesialLingual = (props: Omit<Props, "value">) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M10.1538 27L12.2088 32.8022L14.2527 27H16.4176V35H14.7637V32.8132L14.9286 29.0385L12.7692 35H11.6374L9.4835 29.044L9.6483 32.8132V35H8V27H10.1538Z"
        fill="#9254DE"
      />
      <path
        d="M19 35V27H20.6914V33.6055H24.1211V35H19ZM23.9375 28.3945V27H30.5078V28.3945H28.0586V35H26.3867V28.3945H23.9375Z"
        fill="#9254DE"
      />

      {props.side === "left" ? (
        <path
          d="M26.7227 16.5961L30.2167 4.36745L17.9879 7.86133L20.6084 10.4818L15.3674 19.2167L24.1023 13.9758L26.7227 16.5961Z"
          fill="#9254DE"
        />
      ) : (
        <path
          d="M21.5961 7.86133L9.36745 4.36737L12.8613 16.5961L15.4818 13.9757L24.2167 19.2166L18.9758 10.4817L21.5961 7.86133Z"
          fill="#9254DE"
        />
      )}
    </svg>
  );
};

export const TorqueDistalPalatal = (props: Omit<Props, "value">) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M31.5165 28.3352H29.066V35H27.4176V28.3352H25V27H31.5165V28.3352Z"
        fill="#9254DE"
      />
      <path
        d="M19.6484 32.1813V35H18V27H21.1209C21.7216 27 22.2491 27.1099 22.7033 27.3297C23.1612 27.5495 23.5129 27.8626 23.7583 28.2692C24.0037 28.6722 24.1264 29.1319 24.1264 29.6484C24.1264 30.4322 23.8572 31.0513 23.3187 31.5055C22.7839 31.956 22.0422 32.1813 21.0934 32.1813H19.6484ZM19.6484 30.8462H21.1209C21.5568 30.8462 21.8883 30.7436 22.1154 30.5385C22.3462 30.3333 22.4616 30.0403 22.4616 29.6593C22.4616 29.2674 22.3462 28.9505 22.1154 28.7088C21.8846 28.467 21.566 28.3425 21.1594 28.3352H19.6484V30.8462Z"
        fill="#9254DE"
      />
      <path
        d="M10 35V27H12.4615C13.1648 27 13.793 27.1593 14.3462 27.478C14.9029 27.793 15.337 28.2436 15.6483 28.8297C15.9597 29.4121 16.1154 30.0751 16.1154 30.8187V31.1868C16.1154 31.9304 15.9615 32.5916 15.6538 33.1703C15.3498 33.7491 14.9194 34.1978 14.3626 34.5165C13.8059 34.8352 13.1777 34.9963 12.478 35H10ZM11.6483 28.3352V33.6758H12.4451C13.0897 33.6758 13.5824 33.4652 13.9231 33.044C14.2637 32.6227 14.4377 32.0201 14.4451 31.2363V30.8132C14.4451 30 14.2766 29.3846 13.9396 28.967C13.6026 28.5458 13.1099 28.3352 12.4615 28.3352H11.6483Z"
        fill="#9254DE"
      />

      {props.side === "left" ? (
        <path
          d="M22.5961 7.86133L10.3674 4.36737L13.8613 16.5961L16.4818 13.9757L25.2167 19.2166L19.9758 10.4817L22.5961 7.86133Z"
          fill="#9254DE"
        />
      ) : (
        <path
          d="M27.7227 16.5961L31.2167 4.36745L18.9879 7.86133L21.6084 10.4818L16.3674 19.2167L25.1023 13.9758L27.7227 16.5961Z"
          fill="#9254DE"
        />
      )}
    </svg>
  );
};

export const TorqueDistalBucctal = (props: Omit<Props, "value">) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M31.5165 28.3352H29.066V35H27.4176V28.3352H25V27H31.5165V28.3352Z"
        fill="#9254DE"
      />
      <path
        d="M10 35V27H12.4615C13.1648 27 13.793 27.1593 14.3462 27.478C14.9029 27.793 15.337 28.2436 15.6483 28.8297C15.9597 29.4121 16.1154 30.0751 16.1154 30.8187V31.1868C16.1154 31.9304 15.9615 32.5916 15.6538 33.1703C15.3498 33.7491 14.9194 34.1978 14.3626 34.5165C13.8059 34.8352 13.1777 34.9963 12.478 35H10ZM11.6483 28.3352V33.6758H12.4451C13.0897 33.6758 13.5824 33.4652 13.9231 33.044C14.2637 32.6227 14.4377 32.0201 14.4451 31.2363V30.8132C14.4451 30 14.2766 29.3846 13.9396 28.967C13.6026 28.5458 13.1099 28.3352 12.4615 28.3352H11.6483Z"
        fill="#9254DE"
      />
      <path
        d="M18 35V27H20.8022C21.7729 27 22.5091 27.1868 23.011 27.5604C23.5128 27.9304 23.7637 28.4744 23.7637 29.1923C23.7637 29.5842 23.663 29.9304 23.4615 30.2308C23.2601 30.5275 22.9798 30.7454 22.6209 30.8846C23.0311 30.9872 23.3535 31.1941 23.5879 31.5055C23.826 31.8168 23.945 32.1978 23.945 32.6484C23.945 33.4176 23.6996 34 23.2088 34.3956C22.7179 34.7912 22.0183 34.9927 21.1099 35H18ZM19.6483 31.5165V33.6758H21.0604C21.4487 33.6758 21.7509 33.5842 21.967 33.4011C22.1868 33.2143 22.2967 32.9579 22.2967 32.6319C22.2967 31.8993 21.9176 31.5275 21.1593 31.5165H19.6483ZM19.6483 30.3516H20.8681C21.6996 30.337 22.1154 30.0055 22.1154 29.3571C22.1154 28.9945 22.0091 28.7344 21.7967 28.5769C21.5879 28.4158 21.2564 28.3352 20.8022 28.3352H19.6483V30.3516Z"
        fill="#9254DE"
      />

      {props.side === "left" ? (
        <path
          d="M17.8613 9.98791L14.3674 22.2166L26.5961 18.7227L23.9757 16.1023L29.2166 7.36737L20.4817 12.6083L17.8613 9.98791Z"
          fill="#9254DE"
        />
      ) : (
        <path
          d="M16.4156 18.3433L28.6443 21.8372L25.1504 9.60845L22.5299 12.2289L13.795 6.98799L19.036 15.7229L16.4156 18.3433Z"
          fill="#9254DE"
        />
      )}
    </svg>
  );
};

export const TorqueDistalLingual = (props: Omit<Props, "value">) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M10 35V27H12.4615C13.1648 27 13.793 27.1593 14.3462 27.478C14.9029 27.793 15.337 28.2436 15.6483 28.8297C15.9597 29.4121 16.1154 30.0751 16.1154 30.8187V31.1868C16.1154 31.9304 15.9615 32.5916 15.6538 33.1703C15.3498 33.7491 14.9194 34.1978 14.3626 34.5165C13.8059 34.8352 13.1777 34.9963 12.478 35H10ZM11.6483 28.3352V33.6758H12.4451C13.0897 33.6758 13.5824 33.4652 13.9231 33.044C14.2637 32.6227 14.4377 32.0201 14.4451 31.2363V30.8132C14.4451 30 14.2766 29.3846 13.9396 28.967C13.6026 28.5458 13.1099 28.3352 12.4615 28.3352H11.6483Z"
        fill="#9254DE"
      />
      <path
        d="M19 35V27H20.6914V33.6055H24.1211V35H19ZM23.9375 28.3945V27H30.5078V28.3945H28.0586V35H26.3867V28.3945H23.9375Z"
        fill="#9254DE"
      />

      {props.side === "left" ? (
        <path
          d="M23.5961 8.86133L11.3674 5.36737L14.8613 17.5961L17.4818 14.9757L26.2167 20.2166L20.9758 11.4817L23.5961 8.86133Z"
          fill="#9254DE"
        />
      ) : (
        <path
          d="M26.7227 17.5961L30.2167 5.36745L17.9879 8.86133L20.6084 11.4818L15.3674 20.2167L24.1023 14.9758L26.7227 17.5961Z"
          fill="#9254DE"
        />
      )}
    </svg>
  );
};

const Torque = ({ value, className, style, side }: Props) => {
  switch (value) {
    case TorqueType.MESIAL:
      return <TorqueMesial side={side} className={className} style={style} />;
    case TorqueType.DISTAL:
      return <TorqueDistal side={side} className={className} style={style} />;
    case TorqueType.PALATAL:
      return <TorquePalatal className={className} style={style} />;
    case TorqueType.LINGUAL:
      return <TorqueLingual className={className} style={style} />;
    case TorqueType.BUCCTAL:
      return <TorqueBucctal className={className} style={style} />;

    case TorqueType.MESIAL_PALATAL:
      return (
        <TorqueMesialPalatal side={side} className={className} style={style} />
      );
    case TorqueType.MESIAL_BUCCTAL:
      return (
        <TorqueMesialBucctal side={side} className={className} style={style} />
      );
    case TorqueType.MESIAL_LINGUAL:
      return (
        <TorqueMesialLingual side={side} className={className} style={style} />
      );

    case TorqueType.DISTAL_PALATAL:
      return (
        <TorqueDistalPalatal side={side} className={className} style={style} />
      );
    case TorqueType.DISTAL_BUCCTAL:
      return (
        <TorqueDistalBucctal side={side} className={className} style={style} />
      );
    case TorqueType.DISTAL_LINGUAL:
      return (
        <TorqueDistalLingual side={side} className={className} style={style} />
      );
  }
  return null;
};

export default Torque;
