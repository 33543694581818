import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";

export const CaseStepInfo = () => {
  const isLoading = useAppSelector(selectors.isLoading);
  const caseStep = useAppSelector(selectors.caseStepInfo);
  return (
    <Stack spacing={1} minWidth={180}>
      <Typography variant="body2" color={Colors.LABEL_GREY}>
        CASE STEP
      </Typography>
      <Typography
        variant="body1"
        fontSize={14}
        color={Colors.TEXT_GREY}
        fontWeight={500}
      >
        {isLoading ? (
          <Skeleton animation="wave" width={150} />
        ) : caseStep ? (
          `Wearing aligners ${caseStep.startSteps}-${caseStep.endSteps}`
        ) : (
          "N/A"
        )}
      </Typography>
    </Stack>
  );
};
