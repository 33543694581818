import { useNavigate } from "react-router-dom";
import { EventDashboardOperation } from "lib/enum";
import { useAppDispatch } from "hooks/redux";
import { actions } from "store/patientDetail";
import { Nullable } from "lib/types";

interface ButtonInfo {
  clickHandler: () => void;
  label: string;
}
export const useButtonActions = (
  eventType: EventDashboardOperation,
  targetId: number,
  caseId: Nullable<number>
): ButtonInfo => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  switch (eventType) {
    case EventDashboardOperation.INVOICING:
      return {
        label: "Pay overdue invoices",
        clickHandler: () => {
          if (targetId) {
            navigate(`/invoices/?officeId=${targetId}&tab=1`);
          } else {
            navigate(`/invoices/?tab=1`);
          }
        },
      };
    case EventDashboardOperation.START_WEARING:
      return {
        label: "Have you fit these aligners?",
        clickHandler: () => {
          navigate(`/cases/${caseId}`);
          dispatch(actions.setOpenScheduling(true));
        },
      };
    case EventDashboardOperation.SHEDULING:
      return {
        label: "Review now",
        clickHandler: () => {
          navigate(`/cases/${caseId}`);
        },
      };
    case EventDashboardOperation.TIME_FOR_NEXT_ORDER:
      return {
        label: "Order Next Aligners",
        clickHandler: () => {
          navigate(`/orders/continuation/${targetId}`);
        },
      };
    case EventDashboardOperation.TIME_FOR_RETAINER_ORDER:
      return {
        label: "Place a retainer order",
        clickHandler: () => {},
      };
    case EventDashboardOperation.CREATE_ALIGNER_ORDER:
      return {
        label: "Order Aligners",
        clickHandler: () => {
          navigate(`/cases/${targetId}`);
          dispatch(actions.setOpenVerification(true));
        },
      };
    default:
      return {
        label: "Some action",
        clickHandler: () => {},
      };
  }
};
