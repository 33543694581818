import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

import { Colors } from "consts/colors";
import Stack from "@mui/material/Stack";
import { generateUniqueID } from "web-vitals/dist/modules/lib/generateUniqueID";
import { isHttpUrl } from "utils";

const linkRegexp = /({([\w\s#_.:()]+)}\[([\d\w\s/:.\-_=&+?]+)])+/gm;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.PRIMARY};
`;
const StyledExternalLInk = styled("a")`
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${Colors.PRIMARY};
`;
type LinkType =
  | {
      type: "link";
      path: string;
      name: string;
    }
  | {
      type: "string";
      content: string;
    };
export const getMessagesLink = (
  message: string
): React.ReactElement[] | null => {
  if (!message) {
    return null;
  }
  const links: LinkType[] = [];
  let currentIndex = 0;
  message.replace(
    linkRegexp,
    (match, fullLink, linkName, link, offset, input) => {
      const prevStr = input.slice(currentIndex, offset);
      if (prevStr) {
        links.push({
          type: "string",
          content: prevStr,
        });
      }
      links.push({
        type: "link",
        path: link,
        name: linkName,
      });
      currentIndex = offset + fullLink.length + 1;
      return fullLink;
    }
  );
  if (currentIndex !== message.length - 1) {
    links.push({
      type: "string",
      content: message.slice(currentIndex),
    });
  }
  return links.map((link) => {
    if (link.type === "link") {
      return isHttpUrl(link.path)
        ? React.createElement(
            StyledExternalLInk,
            {
              key: generateUniqueID(),
              href: link.path,
              target: "_blank",
            },
            link.name
          )
        : React.createElement(
            StyledLink,
            {
              key: generateUniqueID(),
              to: link.path,
            },
            link.name
          );
    }
    return React.createElement(
      Typography,
      {
        key: generateUniqueID(),
        variant: "body2",
      },
      link.content
    );
  });
};

interface Props {
  children: string;
}
export const ItemText: React.VFC<Props> = React.memo(({ children }) => {
  const elements = getMessagesLink(children);
  if (!elements?.length) {
    return <Typography variant="body2">{children}</Typography>;
  }
  return (
    <Stack direction="row" spacing={0.5}>
      {elements}
    </Stack>
  );
});
