import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { ShippingCreateContainer } from "containers";
import { Button } from "components/ui";
import { Colors } from "consts/colors";
import printJS from "print-js";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions, selectors } from "store/caseCreate";

interface Props {
  open: boolean;
  onClose: () => void;
  withRedirect?: boolean;
}
export const CreateShippingModal: React.VFC<Props> = ({
  open,
  onClose,
  withRedirect,
}) => {
  const order = useAppSelector(selectors.getAllInfoSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const printHandler = () => {
    setLoading(true);
    dispatch(actions.createShippingForOrder())
      .unwrap()
      .then((shippingLabel) => {
        setLoading(false);
        printJS({
          printable: `data:image/jpeg;base64,${shippingLabel}`,
          type: "image",
        });
        onClose();
        if (withRedirect) {
          navigate(-1);
        }
      })
      .catch((error) => {
        showErrorNotice(error.message);
        setLoading(false);
      });
  };
  return (
    <Dialog fullWidth open={open} maxWidth="lg">
      <DialogTitle color={Colors.PRIMARY}>Create Shipping Label</DialogTitle>
      <DialogContent>
        <ShippingCreateContainer orderId={order.orderId!} />
      </DialogContent>
      <DialogActions sx={{ paddingInline: 5, paddingBottom: 3 }}>
        <Button fatButton variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={printHandler}
          loading={loading}
          fatButton
          variant="contained"
        >
          Create shipping
        </Button>
      </DialogActions>
    </Dialog>
  );
};
