import { IAuthData, IBaseResponse, IUserUpdate } from "lib/model";
import {
  IExtUserDtoForProfileView,
  IPatientDto,
  IUserDto,
  IIdentity,
} from "lib/dto";
import api from "./baseService";
import { LoginProviders } from "lib/enum";
import { deleteCookie, getCookie } from "utils/cookies";
import { LOGGED_IN_AS } from "./apiConsts";

class UserService {
  private baseUrl: string = "users";

  public async getUserIdentities(): Promise<IIdentity[]> {
    const { data } = await api.get<IBaseResponse<IIdentity[]>>(
      `${this.baseUrl}/identities`
    );
    return data.result;
  }
  public async getUserProfile(
    userId: number
  ): Promise<IExtUserDtoForProfileView> {
    const { data } = await api.get<IBaseResponse<IExtUserDtoForProfileView>>(
      `${this.baseUrl}/profile/${userId}`
    );
    return data.result;
  }
  public async getUserPatients(userId: number): Promise<IPatientDto[]> {
    const { data } = await api.get<IBaseResponse<IPatientDto[]>>(
      `${this.baseUrl}/${userId}/patients`
    );
    return data.result;
  }
  public async getUserPatientsSearch(
    userId: number,
    search: string
  ): Promise<IPatientDto[]> {
    const { data } = await api.get<IBaseResponse<IPatientDto[]>>(
      `${this.baseUrl}/${userId}/patients/search?search-for=${search}`
    );
    return data.result;
  }
  public async getAllDoctors(): Promise<IUserDto[]> {
    const { data } = await api.get<IBaseResponse<IUserDto[]>>(
      `${this.baseUrl}/doctors`
    );
    return data.result;
  }
  public async updateUser(user: IUserUpdate): Promise<void> {
    await api.put(this.baseUrl, user);
  }
  public async linkIdentity(code: string): Promise<IAuthData> {
    const { data } = await api.put<IBaseResponse<IAuthData>>(
      `${this.baseUrl}/link-identity?code=${code}&redirectUri=${window.location.origin}`
    );
    return data.result;
  }

  public async unlinkIdentity(
    userId: string,
    provider: LoginProviders
  ): Promise<void> {
    await api.put<IBaseResponse<IAuthData>>(`${this.baseUrl}/unlink-identity`, {
      userId,
      provider,
    });
  }

  public async getDoctorModeInfo() {
    const loggedInAsCookie = getCookie(LOGGED_IN_AS);
    if (!loggedInAsCookie) return false;
    const doctorInfo = await this.getUserProfile(Number(loggedInAsCookie));
    return {
      userId: doctorInfo.id,
      firstName: doctorInfo.firstName,
      lastName: doctorInfo.lastName,
    };
  };

  public async turnOnDoctorMode(doctorUserId: number) {
    const response = await api.post(`login-as-doctor/${doctorUserId}`);
    return response;
  }

  public async turnOffDoctorMode() {
    const res = await api.post('logout-as-doctor');
    deleteCookie(LOGGED_IN_AS);
    return res;
  }
}

export const userService = new UserService();
