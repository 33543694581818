import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="6" height="12" fill="none">
      <path
        d="M4.04185 8.34228V0.479248C4.04185 0.30667 3.90193 0.166748 3.72935 0.166748H2.27102C2.09845 0.166748 1.95852 0.30667 1.95852 0.479248V8.34228H0.759073C0.202251 8.34228 -0.0766029 9.01548 0.31712 9.40923L2.55824 11.6503C2.80232 11.8944 3.19805 11.8944 3.44212 11.6503L5.68323 9.40923C6.07695 9.01551 5.7981 8.34228 5.24128 8.34228H4.04185Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
