export const generateArrow = (cx: number, cy: number): string => {
	return `
	M${2.5 + cx} ${cy} 
	H${3.5 + cx} 
	L${3.5 + cx} ${9 + cy} 
	H${2.5 + cx} 
	L${2.5 + cx} ${cy}
	M${cx} ${9 + cy}
	L${3 + cx} ${16 + cy}
	L${6 + cx} ${9 + cy}
	H${cx}
	`;
};
