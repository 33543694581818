import React, { useMemo } from "react";

import { lowerArchTooth, upperArchTooth } from "./consts";
import ArchTooth from "./ArchTooth";
import { IMarkOutToothDto } from "lib/dto";

interface Props {
  type: "lower" | "upper";
  toothMap: Map<number, IMarkOutToothDto>;
}
const Arch: React.FC<Props> = ({ type, toothMap }) => {
  const incrementIndex: number = useMemo(() => {
    if (type === "lower") {
      return -31;
    }
    return 0;
  }, [type]);
  const archTooth = useMemo(() => {
    if (type === "lower") {
      return lowerArchTooth;
    }
    return upperArchTooth;
  }, [type]);
  return (
    <svg
      width="245"
      height="245"
      viewBox={type === "lower" ? "0 0 260 245" : "0 5 260 245"}
      fill="none"
    >
      {archTooth.map(({ toothPath, numberPath }, index: number) => (
        <ArchTooth
          key={toothPath}
          toothId={Math.abs(index + incrementIndex).toString()}
          toothPath={toothPath}
          numberPath={numberPath}
          angle={
            toothMap.get(Math.abs(index + incrementIndex) + 1)?.toothMovement
          }
        />
      ))}
    </svg>
  );
};
export default Arch;
