import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { orderService } from "services";
import { FileType, OrderRemakeType } from "lib/enum";

import { actions } from "../shipping";
import { actions as caseActions } from "../patientDetail";
import { URLStored } from "utils/URLStored";

export const submitRemakeOrder = createAsyncThunk<
  {
    shippingLabel: string;
  },
  void
>("remake/order/submit", async (_, thunkAPI) => {
  const { requestRemake, patientCard } = thunkAPI.getState() as RootState;
  let shippingLabel = "";
  if (requestRemake.shippingCreated) {
    shippingLabel = await thunkAPI
      .dispatch(actions.printShippingLabel(patientCard.currentOrder?.id!))
      .unwrap();
  }

  await orderService.remakeOrder(patientCard.currentOrder?.id!, {
    remakeType: requestRemake.remakeType as OrderRemakeType,
    notes: requestRemake.notes,
    partialReplacement: requestRemake.partialReplacement,
  });

  if (requestRemake.clinicalPhotos.length) {
    await thunkAPI.dispatch(
      caseActions.uploadCaseFiles({
        files: requestRemake.clinicalPhotos
          .filter(({ unDeletable }) => !unDeletable)
          .map(({ src, name }) => URLStored.getBlobByObjectURL(src)) as any,
        filesType: FileType.CLINICAL_PHOTO,
      })
    );
  }

  if (requestRemake.commonFiles.length) {
    await thunkAPI.dispatch(
      caseActions.uploadCaseFiles({
        files: requestRemake.commonFiles
          .filter(({ unDeletable }) => !unDeletable)
          .map(({ src, name }) => URLStored.getBlobByObjectURL(src)) as any,
        filesType: FileType.COMMON,
      })
    );
  }

  await thunkAPI.dispatch(caseActions.reloadOrder());

  return { shippingLabel };
});
