import React from 'react';

const Torque = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none">
			<circle cx="16" cy="16" r="16" fill="white" />
			<path
				d="M8 21V11H11.0606C11.9351 11 12.7162 11.1992 13.4039 11.5975C14.0962 11.9913 14.6359 12.5545 15.0231 13.2871C15.4102 14.0151 15.6038 14.8439 15.6038 15.7734V16.2335C15.6038 17.163 15.4125 17.9895 15.0299 18.7129C14.6519 19.4364 14.1167 19.9973 13.4244 20.3956C12.7321 20.794 11.951 20.9954 11.0811 21H8ZM10.0495 12.669V19.3448H11.0401C11.8417 19.3448 12.4543 19.0815 12.8779 18.5549C13.3015 18.0284 13.5178 17.2752 13.5269 16.2953V15.7665C13.5269 14.75 13.3174 13.9808 12.8984 13.4588C12.4794 12.9322 11.8668 12.669 11.0606 12.669H10.0495Z"
				fill="#9254DE"
			/>
			<path
				d="M24 12.669H20.953V21H18.9035V12.669H15.8975V11H24V12.669Z"
				fill="#9254DE"
			/>
		</svg>
	);
};
export default Torque;
