import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  FileType,
  OrderItemType,
  Questions,
  CaseExternalService,
  TxPlanComplexityEnum,
  CaseBudgetEnum,
} from "lib/enum";
import { PreferencesType } from "lib/types";
import { complications } from "lib/dictionaries";
import { IUploadedFile } from "lib/model";
import {
  IExtCaseDto,
  IExtOrderDto,
  IExtOrderWithItemsDto,
  IOfficeDto,
  IOrderItemDto,
  IPatientDto,
} from "lib/dto";
import {
  caseService,
  fileService,
  orderService,
  orgService,
  userService,
  userSettingsService,
} from "services";
import { URLStored } from "utils/URLStored";

import { OrderCreateState, Tag, OrderType, RequiredFields } from "./types";
import { RootState } from "../store";
import { actions } from "../shipping";
import { Colors } from "../../consts/colors";

const FILES_SAVE_ERROR =
  "Order was saved, but files could not be uploaded, pls try to upload them later or contact us";

export const setPatientFields =
  createAction<Partial<OrderCreateState["patient"]>>("case/set/patient");

export const toggleArch = createAction<"lowerArch" | "upperArch">(
  "case/set/arch"
);

export const setRemoveAttachment = createAction<boolean>(
  "case/removeAttachment/set"
);

export const setClinicalPhotos =
  createAction<Omit<IUploadedFile, "id">[]>("case/photos/set");
export const setOtherFiles = createAction<Omit<IUploadedFile, "id">[]>(
  "case/otherFiles/set"
);
export const setEditing = createAction<boolean>("case/edit/set");
export const setPreEval = createAction<boolean>("case/preEval/set");
export const deleteClinicalPhoto = createAction<string>("case/photos/delete");
export const deleteOtherFile = createAction<string>("case/otherFiles/delete");
export const setIncludeTags = createAction<Tag[]>("case/include/set");
export const setExcludeTags = createAction<Tag[]>("case/exclude/set");
export const setWearSchedule = createAction<string>("case/wearSchedule/set");
export const setStepsType = createAction<string>("case/stepsType/set");
export const setCustomSteps = createAction<string>("case/customSteps/set");
export const setNotes = createAction<string>("case/notes/set");
export const setDmEnabled = createAction<string>("case/dmEnabled/set");
export const setDmNumber = createAction<string>("case/dmNumber/set");
export const clearState = createAction("case/clear");
export const clearPatients = createAction("case/patients/clear");
export const setRequiredState = createAction<RequiredFields>(
  "case/requiredFields/set"
);
export const setIsRetainer = createAction<boolean>("case/isRetainer/set");

export const setSendScansViaMeditLink = createAction<boolean>(
  "case/isSendScansViaMeditLink/set"
);

export const setDoctorId = createAction<string>("case/doctorId/set");
export const setProductTypeId = createAction<string>("case/productTypeId/set");
export const setOfficeId = createAction<{
  officeId: string;
  showDM: boolean;
  dmEnabled: boolean;
}>("case/officeId/set");
export const setOrderItem =
  createAction<Partial<Record<OrderItemType, number>>>("case/orderItem/set");
export const setPreference = createAction<{
  name: "ipr" | "attachments";
  value: string;
}>("case/preferences/set");
export const setComplications = createAction<{
  toothNumber: number;
  value: string | null;
}>("case/complications/set");
export const setExtractions = createAction<{
  toothNumber: number;
  value: string | null;
}>("case/extractions/set");
export const setCaseBudget = createAction<{
  name: "txPlanComplexity" | "caseBudget" | "caseBudgetCost";
  value: TxPlanComplexityEnum | CaseBudgetEnum | string;
}>("case/caseBudget/set");

export const searchPatients = createAsyncThunk<IPatientDto[], string>(
  "case/patients/search",
  async (search, thunkAPI) => {
    const { currentUser, newCase } = thunkAPI.getState() as RootState;
    return await userService.getUserPatientsSearch(
      newCase.doctorId
        ? Number(newCase.doctorId)
        : currentUser.currentUser!.userId,
      search
    );
  }
);

export const getStaffOfficeByUserId = createAsyncThunk<IOfficeDto>(
  "case/staffOffice/fetch",
  async (_, thunkAPI) => {
    const { newCase } = thunkAPI.getState() as RootState;
    const officeStaff = await orgService.getOfficeStaffById(
      newCase.officeStaffId ?? ""
    );
    return officeStaff.office;
  }
);

export const fetchDoctorInfo = createAsyncThunk<{
  preferences: PreferencesType;
  showDM: boolean;
}>("case/preferences/fetch", async (_, thunkAPI) => {
  const { currentUser, newCase } = thunkAPI.getState() as RootState;
  let showDM: boolean;
  if (!newCase.officeStaffId) {
    const officeStaffs = await orgService.getOfficeStaffByUserId(
      currentUser.currentUser!.userId
    );
    const defOffice = officeStaffs.find(
      ({ isDefaultOffice }) => isDefaultOffice
    );
    showDM = defOffice ? defOffice.dmEnabled : officeStaffs[0].dmEnabled;
  } else {
    showDM = newCase.showDm;
  }
  const preferences = await userSettingsService.getPreferencesByUser(
    newCase.doctorId
      ? Number(newCase.doctorId)
      : currentUser.currentUser!.userId
  );
  return {
    preferences,
    showDM,
  };
});

export const downloadFile = createAsyncThunk<
  {
    src: string;
    fileId: string;
    fileType: "clinicalPhotos" | "otherFiles";
  },
  {
    fileId: string;
    controller: AbortController;
    contentType: string;
    fileType: "clinicalPhotos" | "otherFiles";
  }
>(
  "case/file/download",
  async ({ fileType, fileId, controller, contentType }) => {
    const src = await fileService.getFile(
      fileId,
      contentType,
      controller.signal
    );
    return {
      src,
      fileId,
      fileType,
    };
  }
);

export const fetchOnlyCaseInfo = createAsyncThunk<
  {
    caseInfo: IExtCaseDto;
    clinicalPhotos: IUploadedFile[];
    otherFiles: IUploadedFile[];
    selectedOffice: IOfficeDto | null;
  },
  number | string
>("case/case/fetch", async (caseId) => {
  const caseInfo = await caseService.getCaseById(caseId);
  const selectedOffice = caseInfo?.officeStaffId
    ? ((await orgService.getOfficeStaffById(caseInfo.officeStaffId)) as any)
    : null;
  const { files } = await caseService.getAllCaseAndOrderFiles(caseId);
  return {
    caseInfo: {
      ...caseInfo,
      needPreEvaluation: false,
    },
    selectedOffice,
    clinicalPhotos:
      (files.CLINICAL_PHOTO?.map(({ id, contentType, name }) => ({
        id: id.toString(),
        name,
        contentType,
        unDeletable: true,
        src: "",
      })) as IUploadedFile[]) || [],
    otherFiles:
      (files.COMMON?.map(({ id, contentType, name }) => ({
        id: id.toString(),
        name,
        contentType,
        unDeletable: true,
        src: "",
      })) as IUploadedFile[]) || [],
  };
});

export const fetchContinueOrderInfo = createAsyncThunk<
  {
    caseInfo: IExtCaseDto;
    orderInfo: IExtOrderWithItemsDto;
    clinicalPhotos: IUploadedFile[];
    otherFiles: IUploadedFile[];
    selectedOffice: IOfficeDto | null;
  },
  number | string
>("case/info/fetch", async (caseId) => {
  const orders = await caseService.getOrdersByCaseId(caseId);
  const lastOrder = orders?.pop();
  const orderInfo = await orderService.getOrderWithItemsById(lastOrder?.id!);
  const caseInfo = await caseService.getCaseById(orderInfo.caseId);
  const { files } = await caseService.getAllCaseAndOrderFiles(orderInfo.caseId);
  const selectedOffice = orderInfo?.officeStaffId
    ? ((await orgService.getOfficeStaffById(orderInfo.officeStaffId)) as any)
    : null;
  return {
    caseInfo,
    selectedOffice,
    orderInfo,
    clinicalPhotos:
      (files.CLINICAL_PHOTO?.map(({ id, contentType, name }) => ({
        id: id.toString(),
        name,
        contentType,
        unDeletable: true,
        src: "",
      })) as IUploadedFile[]) || [],
    otherFiles:
      (files.COMMON?.map(({ id, contentType, name }) => ({
        id: id.toString(),
        name,
        contentType,
        unDeletable: true,
        src: "",
      })) as IUploadedFile[]) || [],
  };
});

export const updateCaseOrder = createAsyncThunk<{
  caseInfo: IExtCaseDto;
  orderInfo: IExtOrderDto;
  filesSaveError?: string;
}>("case/update/send", async (_, thunkAPI) => {
  const { newCase } = thunkAPI.getState() as RootState;
  let newOrderId: number;
  const caseInfo = await caseService.getCaseById(newCase.caseId!);
  const complicationsTooth = newCase.complications.reduce(
    (previousValue, currentValue, currentIndex) => {
      previousValue[currentIndex.toString()] = {
        active: false,
        fill: currentValue,
        value: currentValue ? complications[currentValue] : "unselect",
      };

      return previousValue;
    },
    {} as any
  );
  await caseService.updateCase({
    ...caseInfo,
    lowerArch: newCase.lowerArch,
    upperArch: newCase.upperArch,
    needPreEvaluation: newCase.preEval,
    externalCases: {
      [CaseExternalService.DENTAL_MONITORING]: {
        ...caseInfo.externalCases?.DENTAL_MONITORING,
        caseId: caseInfo.id,
        extServId: CaseExternalService.DENTAL_MONITORING,
        enabled: newCase.dmEnabled === Questions.YES,
        externalId: newCase.dmNumber.trim(),
        activated: null,
      },
    },
    additionalInfo: {
      txPlanComplexity: newCase.txPlanComplexity ?? "",
      caseBudget: newCase.caseBudget ?? "",
      caseBudgetCost: newCase.caseBudgetCost ?? "",
      attachPrefer: newCase.attachments,
      include: JSON.stringify(newCase.includes),
      exclude: JSON.stringify(newCase.excludes),
      other: newCase.notes,
      extractionIPR: newCase.ipr,
      extractionNote: "",
      wearSchedule: newCase.wearSchedule,
      productionType: caseInfo.additionalInfo.productionType,
      extractionTooth: JSON.stringify(
        newCase.extractions.reduce(
          (previousValue, currentValue, currentIndex) => {
            if (currentValue) {
              switch (currentValue) {
                case Colors.DISABLED:
                  previousValue.push(currentIndex.toString());
                  break;
                case Colors.TOOTH_WHITE:
                  previousValue.push(`${currentIndex}_p`);
                  break;
              }
            }
            return previousValue;
          },
          [] as string[]
        )
      ),
      complicationsTooth: JSON.stringify(complicationsTooth),
    },
  });

  if (newCase.createOnlyOrder && !newCase.orderId) {
    const { id } = await orderService.createOrder({
      useMeditLink: newCase.useMeditLink,
      caseId: caseInfo.id,
      threeDVisualization: false,
      lowerArch: newCase.lowerArch,
      upperArch: newCase.upperArch,
      requestedRetainers: 0,
      note: "",
      specialInstruction: "",
      orderTypeId: OrderType.NEW_PATIENT,
      productionType: true,
      orderItems: Object.entries(newCase.orderItems).map(([key, value]) => ({
        itemType: key as OrderItemType,
        qty: value,
      })),
      alignersMaxQty: newCase.customStepsNum,
    });
    newOrderId = id;
  }
  const orderInfo = await orderService.getOrderById(
    newCase.orderId! || newOrderId!
  );
  if (newCase.orderId) {
    await orderService.updateOrder({
      ...orderInfo,
      alignersMaxQty: newCase.customStepsNum,
      lowerArch: newCase.lowerArch,
      upperArch: newCase.upperArch,
      orderTypeId: newCase.isRetainer
        ? OrderType.RETAINER
        : newCase.preEval
        ? OrderType.PRE_EVAL
        : OrderType.NEW_PATIENT,
      productionType: true,
      orderItems: Object.entries(newCase.orderItems).map(([key, value]) => ({
        itemType: key as OrderItemType,
        orderId: newCase.orderId!,
        qty: value,
      })) as IOrderItemDto[],
    });
  }

  try {
    if (newCase.clinicalPhotos.length > 0) {
      await caseService.uploadCaseFiles(
        newCase.caseId!,
        newCase.clinicalPhotos
          .filter(({ unDeletable }) => !unDeletable)
          .map(({ src, name }) => ({
            blob: URLStored.getBlobByObjectURL(src),
            name,
          })),
        FileType.CLINICAL_PHOTO
      );
    }
    if (newCase.otherFiles.length > 0) {
      await caseService.uploadCaseFiles(
        newCase.caseId!,
        newCase.otherFiles
          .filter(({ unDeletable }) => !unDeletable)
          .map(({ src, name }) => ({
            blob: URLStored.getBlobByObjectURL(src),
            name,
          })),
        FileType.COMMON
      );
    }
  } catch (error: any) {
    return {
      caseInfo,
      orderInfo,
      filesSaveError: FILES_SAVE_ERROR,
    };
  }
  return {
    caseInfo,
    orderInfo,
  };
});

export const createOrder = createAsyncThunk<{
  caseId: number;
  orderId: number;
  filesSaveError?: string;
}>("case/create/send", async (_, thunkAPI) => {
  const { newCase, currentUser } = thunkAPI.getState() as RootState;
  let officeStaffID: string | number;
  if (!newCase.officeStaffId) {
    const officeStaffs = await orgService.getOfficeStaffByUserId(
      currentUser.currentUser!.userId
    );
    const defOffice = officeStaffs.find(
      ({ isDefaultOffice }) => isDefaultOffice
    );
    officeStaffID = defOffice ? defOffice.id : officeStaffs[0].id;
  } else {
    officeStaffID = newCase.officeStaffId;
  }

  const complicationsTooth = newCase.complications.reduce(
    (previousValue, currentValue, currentIndex) => {
      previousValue[currentIndex.toString()] = {
        active: false,
        fill: currentValue,
        value: currentValue ? complications[currentValue] : "unselect",
      };

      return previousValue;
    },
    {} as any
  );
  const { caseId, orderId } = await caseService.createCaseAndOrder({
    extCase: {
      useMeditLink: newCase.useMeditLink,
      officeStaffId: Number(officeStaffID),
      needPreEvaluation: newCase.preEval,
      lowerArch: newCase.lowerArch,
      upperArch: newCase.upperArch,
      patient: {
        ...newCase.patient,
        gender:
          newCase.patient.gender === "" ? null : newCase.patient.gender === "1",
        birthDate: newCase.patient.birthDate
          ? new Date(newCase.patient.birthDate).toISOString()
          : null,
      },
      externalCases: {
        [CaseExternalService.DENTAL_MONITORING]: {
          extServId: CaseExternalService.DENTAL_MONITORING,
          enabled: newCase.dmEnabled === Questions.YES,
          externalId: newCase.dmNumber.trim(),
          activated: null,
        },
      },
      additionalInfo: {
        txPlanComplexity: newCase.txPlanComplexity ?? "",
        caseBudget: newCase.caseBudget ?? "",
        caseBudgetCost: newCase.caseBudgetCost ?? "",
        attachPrefer: newCase.attachments,
        include: JSON.stringify(newCase.includes),
        exclude: JSON.stringify(newCase.excludes),
        other: newCase.notes,
        extractionIPR: newCase.ipr,
        extractionNote: "",
        wearSchedule: newCase.wearSchedule,
        productionType: true,
        extractionTooth: JSON.stringify(
          newCase.extractions.reduce(
            (previousValue, currentValue, currentIndex) => {
              if (currentValue) {
                switch (currentValue) {
                  case Colors.DISABLED:
                    previousValue.push(currentIndex.toString());
                    break;
                  case Colors.TOOTH_WHITE:
                    previousValue.push(`${currentIndex}_p`);
                    break;
                }
              }
              return previousValue;
            },
            [] as string[]
          )
        ),
        complicationsTooth: JSON.stringify(complicationsTooth),
      },
      productTypeId: newCase.productTypeId,
    },
    order: {
      threeDVisualization: false,
      lowerArch: newCase.lowerArch,
      upperArch: newCase.upperArch,
      orderTypeId: newCase.isRetainer
        ? OrderType.RETAINER
        : newCase.preEval
        ? OrderType.PRE_EVAL
        : OrderType.NEW_PATIENT,
      productionType: true,
      orderItems: Object.entries(newCase.orderItems).map(([key, value]) => ({
        itemType: key as OrderItemType,
        qty: value,
      })),
      alignersMaxQty: newCase.customStepsNum,
      removeAttachment: newCase.removeAttachment,
    },
  });
  try {
    if (newCase.clinicalPhotos.length > 0) {
      await caseService.uploadCaseFiles(
        caseId,
        newCase.clinicalPhotos.map(({ src, name }) => ({
          blob: URLStored.getBlobByObjectURL(src),
          name,
        })),
        FileType.CLINICAL_PHOTO
      );
    }

    if (newCase.otherFiles.length > 0) {
      await caseService.uploadCaseFiles(
        caseId,
        newCase.otherFiles.map(({ src, name }) => ({
          blob: URLStored.getBlobByObjectURL(src),
          name,
        })),
        FileType.COMMON
      );
    }
  } catch (error: any) {
    return {
      caseId,
      orderId,
      filesSaveError: FILES_SAVE_ERROR,
    };
  }

  return {
    caseId,
    orderId,
  };
});

export const submitOrder = createAsyncThunk<{
  caseId: number;
  orderId: number;
  filesSaveError?: string;
}>("case/submit/send", async (_, thunkAPI) => {
  const { newCase } = thunkAPI.getState() as RootState;
  if (!newCase.caseId) {
    const { caseId, orderId, filesSaveError } = await thunkAPI
      .dispatch(createOrder())
      .unwrap();
    if (!filesSaveError) {
      await orderService.submitOrderToLab(orderId);
    }
    return { caseId, orderId, filesSaveError };
  }
  const {
    orderInfo: { id: orderId },
    filesSaveError,
  } = await thunkAPI.dispatch(updateCaseOrder()).unwrap();

  if (!filesSaveError) {
    await orderService.submitOrderToLab(orderId);
  }

  return {
    caseId: newCase.caseId!,
    orderId: newCase.orderId!,
    filesSaveError,
  };
});

export const createShippingForOrder = createAsyncThunk<string>(
  "case/shipping/create",
  async (_, thunkAPI) => {
    const { newCase } = thunkAPI.getState() as RootState;
    return await thunkAPI
      .dispatch(actions.printShippingLabel(newCase.orderId!))
      .unwrap();
  }
);
