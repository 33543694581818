export class URLStored extends URL {
  static blobs: Record<string, Blob> = {};

  static createObjectURL(blob: Blob): string {
    const blobUrl = super.createObjectURL(blob);
    this.blobs[blobUrl] = blob;
    return blobUrl;
  }

  static revokeObjectURL(objectUrl: string): void {
    super.revokeObjectURL(objectUrl);
    delete this.blobs[objectUrl];
  }

  static getBlobByObjectURL(objectUrl: string): Blob {
    return this.blobs[objectUrl];
  }
}
