import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { InvoiceInfoContainer } from "./Elements";
import { InfoWithLabel } from "components/common";
import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/createPayment";
import { toLocalCurrency } from "utils";

export const InvoiceInfo = () => {
  const dispatch = useAppDispatch();
  const invoiceInfo = useAppSelector(selectors.invoiceInfoSelectors);
  const selectedInvoices = useAppSelector(selectors.selectedInvoiceSelectors);
  const offices = useAppSelector(selectors.officesSelector);
  const office = useAppSelector(selectors.officeSelector);
  useEffect(() => {
    if (offices.length) {
      dispatch(actions.calculateInvoiceInfo());
    }
  }, [dispatch, selectedInvoices, offices.length]);
  return (
    <>
      <Typography variant="h2" color={Colors.TEXT_PRIMARY} ml={5} mb={3}>
        Unpaid invoices
      </Typography>
      <InvoiceInfoContainer direction="row" spacing={6}>
        <InfoWithLabel
          direction="column"
          label="OUTSTANDING BALANCE"
          text={toLocalCurrency(invoiceInfo.outstandingBalance)}
        />
        <InfoWithLabel
          direction="column"
          label="PROMO BALANCE"
          text={toLocalCurrency(office?.promo || 0)}
        />
        <InfoWithLabel
          direction="column"
          label="DEBIT BALANCE"
          text={toLocalCurrency(office?.debitBalance || 0)}
        />
        <InfoWithLabel
          direction="column"
          label="PAYMENT AMOUNT"
          text={toLocalCurrency(invoiceInfo.invoiceAmount)}
        />
      </InvoiceInfoContainer>
    </>
  );
};
