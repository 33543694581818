import React, { SyntheticEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MainContainer, ListTableContainer } from "containers";
import { Header, SearchInput } from "components/common";
import { paymentService } from "services";
import { getUrlSearchParams } from "utils/urlHelpers";
import { Button } from "components/ui";
import { dateToString } from "utils/dateHelpers";
import { paymentsMethodEnum, paymentsMethodType } from "lib/enum";
import { toLocalCurrency } from "utils/helpers";
import { TableFilterListOption } from "components/ui/listTable/types";
import { ListTableContainerColumnProps } from "containers/listTable/types";
import { IPaymentDto } from "lib/dto";
import { useStyledSnackbar } from "hooks/notification";

const fetch = async (filter: any) => {
  return await paymentService.fetch({
    ...filter,
  });
};
const filterField = [
  "paymentMethod",
  { name: "orderType", initValue: "DESC" },
  { name: "orderBy", initValue: "id" },
];
export const PaymentsPage = () => {
  const navigate = useNavigate();
  const { showErrorNotice } = useStyledSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentMethods, setPaymentMethods] = useState<
    { title: string; value: string }[] | undefined
  >();
  const initPaymentMethod = () => {
    const paymentMethod = searchParams.get(
      "paymentMethod"
    ) as unknown as paymentsMethodType;
    if (![null, "null"].includes(paymentMethod)) {
      return {
        title: paymentsMethodEnum[paymentMethod],
        value: paymentMethod,
      };
    }
    return undefined;
  };
  const columns: ListTableContainerColumnProps<IPaymentDto>[] = [
    {
      title: "ID",
      width: "150px",
      dataIndex: "id",
      sort: true,
    },
    {
      title: "Payment date",
      width: "170px",
      dataIndex: "paymentDate",
      sort: true,
      render: (date: string) => dateToString(date),
    },
    {
      title: "Note",
      width: "160px",
      dataIndex: "note",
      sort: true,
    },
    {
      title: "Payment",
      width: "160px",
      dataIndex: "paymentMethod",
      sort: true,
      render: (method: paymentsMethodType) => paymentsMethodEnum[method],
      filter: {
        type: "list",
        handleChange: (
          event: SyntheticEvent,
          option: TableFilterListOption | null
        ) => {
          event.stopPropagation();
          handleFilter("paymentMethod", String(option?.value ?? option));
        },
        defaultValue: initPaymentMethod(),
        data:
          paymentMethods ??
          Object.keys(paymentsMethodEnum).map((key) => ({
            title: paymentsMethodEnum[key as unknown as paymentsMethodType],
            value: key,
          })),
      },
    },
    {
      title: "Amount",
      width: "200px",
      dataIndex: "amount",
      sort: true,
      render: (amount: number) => (amount ? toLocalCurrency(amount) : null),
    },
    {
      title: "Created date",
      width: "160px",
      dataIndex: "created",
      sort: true,
      render: (date: string) => dateToString(date),
    },
  ];

  const handleFilter = (field: string, value: string) => {
    setSearchParams({
      ...getUrlSearchParams(searchParams),
      [field]: value,
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({
      ...getUrlSearchParams(searchParams),
      "search-for": e.target.value,
    });
  };

  useEffect(() => {
    paymentService
      .getUsingPaymentMethods()
      .then((paymentMethods) => {
        setPaymentMethods(
          paymentMethods.map(({ paymentMethod }) => ({
            title: paymentsMethodEnum[paymentMethod],
            value: paymentMethod,
          }))
        );
      })
      .catch((error) => {
        showErrorNotice(error.message);
      });
  }, [showErrorNotice]);
  return (
    <MainContainer>
      <Header
        title={"Payments"}
        subtitle={
          <SearchInput
            onChange={handleSearch}
            placeholder="Search payments..."
          />
        }
        right={
          <Button
            onClick={() => navigate("new")}
            sx={{ height: 48 }}
            variant="contained"
            color="primary"
          >
            Add New
          </Button>
        }
      />
      <ListTableContainer
        filterField={filterField}
        columns={columns}
        requests={{ fetch }}
        pagination={true}
      />
    </MainContainer>
  );
};
