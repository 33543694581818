import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { dateToString, timeToString } from "utils";
import { Colors } from "consts/colors";
import Tooltip from "@mui/material/Tooltip";
import "./styles.css";

interface Props {
  fileName?: string;
  createdDate?: string;
  children: React.ReactElement;
}
export const FileNameTooltip: React.FC<Props> = ({
  fileName,
  createdDate,
  children,
}) => {
  return (
    <Tooltip
      arrow
      classes={{
        tooltip: "tooltip",
        arrow: "tooltip__arrow",
      }}
      title={
        <Stack alignItems="center" justifyContent="space-between">
          {fileName && (
            <Typography color={Colors.DISABLED} variant="body1">
              {fileName}
            </Typography>
          )}
          {createdDate && (
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={0.5}
            >
              <Typography color={Colors.LIGHT_GREY} variant="body1">
                {dateToString(createdDate)}
              </Typography>
              <Typography
                color={Colors.LIGHT_GREY}
                variant="body2"
                sx={{ fontSize: "12px" }}
              >
                {timeToString(createdDate)}
              </Typography>
            </Stack>
          )}
        </Stack>
      }
      placement="top"
    >
      {children}
    </Tooltip>
  );
};
