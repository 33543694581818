import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { InfoContainer } from "./Elements";
import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { orderItems } from "lib/dictionaries";
import { selectors } from "store/patientDetail";

export const EncloseWithOrder = () => {
  const { currentOrder } = useAppSelector(selectors.currentOrder);
  if (!currentOrder?.orderItems.length) {
    return (
      <Stack direction="row" justifyContent="center">
        <Typography
          variant="body1"
          fontWeight="bold"
          color={Colors.TEXT_PRIMARY}
        >
          There's no enclosed with this order items.
        </Typography>
      </Stack>
    );
  }
  return (
    <InfoContainer>
      <Stack direction="row" spacing={2}>
        {currentOrder.orderItems.map((item) => (
          <Stack direction="row" key={item.id} spacing={1}>
            <Typography variant="body2" fontWeight="bold">
              {orderItems[item.itemType]}
            </Typography>
            <Typography variant="body2">{item.qty}</Typography>
          </Stack>
        ))}
      </Stack>
    </InfoContainer>
  );
};
