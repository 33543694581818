import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material/Select";

import { Colors } from "consts/colors";
import { InfoWithLabel } from "components/common";
import { Select } from "components/ui";
import { selectors, actions } from "store/createPayment";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toLocalCurrency } from "utils";

export const OrganizationsFieldsEdit = () => {
  const dispatch = useAppDispatch();
  const offices = useAppSelector(selectors.officesSelector);
  const orgs = useAppSelector(selectors.orgsSelector);
  const fields = useAppSelector(selectors.editableFields);
  const currentBalance = useAppSelector(selectors.currentBalanceSelector);

  const changeOrgHandler = (event: SelectChangeEvent) => {
    dispatch(actions.changeOrg(event.target.value));
  };
  const changeOfficeHandler = (event: SelectChangeEvent) => {
    dispatch(actions.changeOffice(event.target.value));
  };
  useEffect(() => {
    dispatch(actions.getOrgs());
  }, [dispatch]);
  useEffect(() => {
    if (fields.org) {
      dispatch(actions.getOffices(Number(fields.org)));
    }
  }, [fields.org, dispatch]);
  useEffect(() => {
    if (fields.office) {
      dispatch(actions.getInvoices(Number(fields.office)));
    }
  }, [fields.office, dispatch]);
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item md={4}>
          <Stack spacing={0.5}>
            <Typography variant="body2" color={Colors.TEXT_PRIMARY}>
              Organization
            </Typography>
            <Select
              disabled={!orgs.length || fields.isSaving}
              placeholder="Select organization"
              value={fields.org}
              name="org"
              onChange={changeOrgHandler}
              data={orgs.map(({ id, name }) => ({
                label: name,
                value: id.toString(),
              }))}
            />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack spacing={0.5}>
            <Typography variant="body2" color={Colors.TEXT_PRIMARY}>
              Office
            </Typography>
            <Select
              disabled={!offices.length || fields.isSaving}
              placeholder="Select office"
              value={fields.office}
              onChange={changeOfficeHandler}
              name="office"
              data={offices.map(({ id, name }) => ({
                label: name,
                value: id.toString(),
              }))}
            />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Box mt={3}>
            <InfoWithLabel
              label="CURRENT BALANCE:"
              text={toLocalCurrency(currentBalance)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
