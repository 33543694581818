import React from "react";
import { MainContainer } from "../main";
import { LoadingOverlay } from "components/common";
import {
  ContentContainer,
  Header,
  SelectProduct,
  OrderNotes,
  ImpressionsSelect,
  SelectArches,
  FilesUpload,
  Footer,
  VerificationWindow,
  CompleteArchWarning,
  CompleteCaseWarning,
  HeaderSubTitle,
  MeditLink,
} from "./components";
import Stack from "@mui/material/Stack";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/continuationOrderCreate";
import { Grid } from "@mui/material";

export const ContinuationOrderCreateContainer = () => {
  const dispatch = useAppDispatch();
  const { isSaving, showVerification, previousOrder, caseInfo } = useAppSelector(
    selectors.getAllInfoSelector
  );
  const needVKFiles = useAppSelector(selectors.needVKFilesSelector);
  const closeHandler = () => {
    dispatch(actions.setShowVerification(false));
  };


  return (
    <MainContainer>
      <ContentContainer>
        <LoadingOverlay
          text="Please, wait until data(files) saving will have finished"
          open={isSaving}
        />
        {previousOrder && (
          <VerificationWindow
            withFiles={needVKFiles}
            orderInfo={previousOrder}
            open={showVerification}
            closeHandler={closeHandler}
          />
        )}
        <CompleteCaseWarning />
        <CompleteArchWarning />
        <Header />
        <Grid container>
          <Grid item xs={7}>
            <HeaderSubTitle />
            <Stack mt={3} spacing={3}>
              <SelectProduct />
              {caseInfo?.office.useMeditLink && <MeditLink />}
              <OrderNotes />
              <SelectArches />
              <ImpressionsSelect />
            </Stack>
          </Grid>
          <Grid item xs={5} className="intercom-orders-continuation" />
        </Grid>
        <FilesUpload />
        <Footer />
      </ContentContainer>
    </MainContainer>
  );
};
