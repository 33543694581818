import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Radio, RadioGroup, styled } from "@mui/material";

import { Button } from "components/ui";
import { Colors } from "consts/colors";
import { InformBlock } from "components/common";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/currentUser";
import { selectors as caseSelector } from "store/caseCreate";
import { UserRoles } from "lib/enum/userRoles";
import { sendSegment } from "utils/analyticsMethod";

const StyledButton = styled(Button)`
  height: 48px;
  padding-inline: 20px;
`;
const StyledSpan = styled("span")`
  text-decoration: underline;
  cursor: pointer;
`;
export const OptionsContainer = () => {
  const questions = [
    {
      label: "New Patient Order",
      to: "/orders/new",
    },
    {
      label: "Continuation Order",
      to: "/cases/search",
    },
    {
      label: "I have a new patient who only wants Retainers",
      to: "/orders/new/retainer",
    },
  ];
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const navigate = useNavigate();
  const permissions = useAppSelector(selectors.permissionsSelector);
  const caseInfo = useAppSelector(caseSelector.getAllInfoSelector);
  const disableNextButton =
    selectedOption === null ||
    (permissions.includes(UserRoles.ASSISTANT_USER) && !caseInfo.officeStaffId);

  const selectOptionHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(Number(e.target.value));
    switch (Number(e.target.value)) {
      case 0:
        sendSegment("New patient option selected", {});
        break;
      case 1:
        sendSegment("Existing patient option selected", {});
        break;
      case 2:
        sendSegment("New patient option selected Retainer", {});
        break;
    }
  };

  const goCancelHandler = () => {
    navigate("/orders");
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const goForwardHandler = () => {
    navigate(questions[selectedOption!].to);
  };

  const handleShowIntercom = () => {
    //@ts-ignore
    window?.Intercom("show");
  };

  return (
    <>
      <Grid container spacing={3} mt={2}>
        <Grid item md={7}>
          <Stack alignItems="flex-start" spacing={4}>
            <Typography variant="h2" color={Colors.SECONDARY}>
              What kind of order would you like to place?
            </Typography>
            <Stack spacing={3} alignItems="flex-start">
              <RadioGroup value={selectedOption} onChange={selectOptionHandler}>
                {questions.map(({ label }, index) => {
                  return (
                    <FormControlLabel
                      key={`radio-${index}`}
                      value={index}
                      control={<Radio />}
                      label={label}
                    />
                  );
                })}
              </RadioGroup>
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={5}>
          <InformBlock
            title="Notice"
            text={
              <>
                Start a new case by clicking New Order. If this patient already
                exists in the Orthosnap Doctor Portal, please select
                Continuation Order.
                <br />
                <br />
                Need help submitting an order?{" "}
                <StyledSpan onClick={handleShowIntercom}>
                  Click here for help
                </StyledSpan>
              </>
            }
          />
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        flexGrow={1}
      >
        <Stack direction="row" spacing={3}>
          <StyledButton
            onClick={goCancelHandler}
            variant="text"
            sx={{ color: Colors.DISABLED }}
          >
            Cancel
          </StyledButton>
        </Stack>
        <Stack spacing={3} direction="row">
          <StyledButton
            onClick={goBackHandler}
            variant="outlined"
            color="secondary"
          >
            Back
          </StyledButton>
          <StyledButton
            disabled={disableNextButton}
            onClick={goForwardHandler}
            variant="contained"
            color="primary"
          >
            Next
          </StyledButton>
        </Stack>
      </Stack>
    </>
  );
};
