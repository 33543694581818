import React, { useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Collapse from "@mui/material/Collapse";

import { InfoWithLabel } from "components/common";
import { IExtOrderForCaseViewDto } from "lib/dto";
import { dateToString } from "utils";
import { orderStatuses } from "lib/dictionaries";
import { selectors } from "store/patientDetail";
import { useAppSelector } from "hooks/redux";

const Container = styled(Box)`
  padding: 22px 24px;
  border: 1px solid var(--container-border-grey-color);
  box-shadow: 0 0 7px rgba(56, 71, 109, 0.1);
  border-radius: 4px;
`;

const InfoContainer = styled(Box)`
  padding: 12px;
  background: #f0f0f0;
  border-radius: 5px;
`;

interface Props {
  order: IExtOrderForCaseViewDto;
}
export const OrdersItem: React.VFC<Props> = ({ order }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { caseEvents, requiredActions } = useAppSelector(
    selectors.caseNoticeSelector
  );
  const fontSize = caseEvents.length > 0 || requiredActions.length ? 14 : 16;
  const toggleOpen = () => setOpen((oldOpen) => !oldOpen);
  const productName = order.template?.isPreEvaluation
    ? "Pre-evaluation"
    : order.productionType
    ? "Aligner"
    : "Retainer";
  return (
    <Container>
      <div className="active-order__general-info" style={{ fontSize }}>
        <InfoWithLabel
          label="ORDER ID"
          labelStyleSx={{ fontSize: "0.75em" }}
          textStyleSx={{ fontSize: "1em" }}
          text={order.id.toString()}
          direction="column"
        />
        <InfoWithLabel
          label="LAST ACTIVITY DATE"
          labelStyleSx={{ fontSize: "0.75em" }}
          textStyleSx={{ fontSize: "1em" }}
          text={dateToString(order.modified)}
          direction="column"
        />
        <InfoWithLabel
          label="PRODUCT"
          labelStyleSx={{ fontSize: "0.75em" }}
          textStyleSx={{ fontSize: "1em" }}
          text={productName}
          direction="column"
        />
        <Box gridColumn="4/span 2">
          <InfoWithLabel
            label="ORDER STATUS"
            labelStyleSx={{ fontSize: "0.75em" }}
            textStyleSx={{ fontSize: "1em" }}
            text={orderStatuses[order.status]}
            direction="column"
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={toggleOpen}>
            {open ? <ArrowLeftIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </Box>
      </div>
      <Collapse unmountOnExit in={open}>
        <InfoContainer mt={2}>
          <InfoWithLabel
            label="Special Instruction"
            labelStyleSx={{ color: "black", fontWeight: "bold" }}
            textStyleSx={{ fontSize: 12 }}
            text={order.specialInstruction || ""}
            direction="column"
          />
        </InfoContainer>
      </Collapse>
    </Container>
  );
};
