import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SelectChangeEvent } from "@mui/material/Select";

import { Select } from "components/ui";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/currentUser";
import { Colors } from "consts/colors";

interface Props {
  onChangeOffice: (officeId: string) => void;
  office: string;
}
export const OfficeSelector: React.VFC<Props> = ({
  onChangeOffice,
  office,
}) => {
  const offices = useAppSelector(selectors.selectUserOffices);
  const changeHandler = (event: SelectChangeEvent<typeof office>) => {
    onChangeOffice(event.target.value);
  };
  const clearHandler = () => {
    onChangeOffice("");
  };
  if (offices.length === 0) {
    return null;
  }
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography fontWeight={500} variant="body2" color={Colors.TEXT_GREY}>
        Office:
      </Typography>
      <Stack direction="row" alignItems="center">
        <Select
          value={office}
          onChange={changeHandler}
          placeholder="Select one"
          sx={{ minWidth: 150, height: 32 }}
          data={offices.map((office) => ({
            label: office.name,
            value: office.id.toString(),
          }))}
        />
        {!!office && (
          <IconButton onClick={clearHandler}>
            <CloseIcon sx={{ fontSize: 18 }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};
