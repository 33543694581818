import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const PaperContainer = styled(Box)`
  padding-inline: 24px;
  padding-block: 32px;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0 7px rgba(56, 71, 109, 0.1);
  border-radius: 4px;
`;

export const Title = styled(Typography)`
  color: var(--primary-color);
  font-weight: 600;
  margin-block: 32px;
  margin-left: 40px;
`;

export const Wrapper = styled(Stack)`
  height: 100%;
`;
export const ContentContainer = styled(Box)`
  padding-inline: 16px;
`;
export const ButtonsContainer = styled(Stack)`
  padding: 40px;
`;
