export enum FileType {
  INVALID = "INVALID",
  COMMON = "COMMON",
  CLINICAL_PHOTO = "CLINICAL_PHOTO",
  ORIGIN_MODEL = "ORIGIN_MODEL",
  VERIFICATION_KEY = "VERIFICATION_KEY",
  PRE_EVALUATION = "PRE_EVALUATION",
  TREATMENT_PLAN = "TREATMENT_PLAN",
  TP_VIDEO = "TP_VIDEO",
}
