import { useEffect } from "react";

export const useBackdrop = (clickHandler: (event: MouseEvent) => void) => {
  useEffect(() => {
    const root = document.getElementById("root");
    root!.addEventListener("click", clickHandler, true);
    return () => {
      root!.removeEventListener("click", clickHandler, true);
    };
  }, [clickHandler]);
};
