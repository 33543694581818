export enum UserDefineFlag {
  INVALID = "INVALID",
  /**
   * Red.
   */
  RUSH_CASE = "RUSH_CASE",
  /**
   * Orange.
   */
  ORANGE = "ORANGE",
  /**
   * Yellow.
   */
  NOT_COMPLIANT = "NOT_COMPLIANT",
  /**
   * Green.
   */
  FINISHED = "FINISHED",
  /**
   * Blue.
   */
  NEW_IMPRESSIONS_NEEDED = "NEW_IMPRESSIONS_NEEDED",
  /**
   * Dark blue.
   */
  DARK_BLUE = "DARK_BLUE",
  /**
   * Purple.
   */
  INTERESTING_CASE = "INTERESTING_CASE",
}
