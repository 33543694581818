import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Divider from "@mui/material/Divider";
import clsx from "clsx";

import { selectors } from "store/treatmentPlan";
import { fbmCellsCreate } from "./helpers";
import { GridColumn, SubtitleColumn } from "./LineElements";
import "./styles.css";
import FbmArch from "../FbmArch";

import { useAppSelector } from "hooks/redux";

const GeneralFBMLine: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const treatmentPlan = useAppSelector(selectors.treatPlanSelector);
  const cellsCreateHandler = fbmCellsCreate(treatmentPlan?.markOutToothList);
  const toggleOpen = () => {
    setOpen((oldOpen) => !oldOpen);
  };
  return (
    <>
      {!open && <Divider sx={{ marginInline: "93px" }} />}
      <div
        className={clsx("generalFBM__row", open && "generalFBM__row_opened")}
      >
        <div className="generalFBM__item">
          <IconButton size="small" color="primary" onClick={toggleOpen}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography
            fontSize={10}
            fontWeight="bold"
            variant="body2"
            color="primary"
          >
            FBM
          </Typography>
        </div>
        <div className="flexGrow1">
          <div
            className={clsx(
              "generalFBM__subtitles",
              open && "generalFBM__subtitles_hidden"
            )}
          >
            <SubtitleColumn>
              <GridColumn>{cellsCreateHandler(0)}</GridColumn>
              <GridColumn>{cellsCreateHandler(8)}</GridColumn>
            </SubtitleColumn>
            <SubtitleColumn>
              <GridColumn>{cellsCreateHandler(-31)}</GridColumn>
              <GridColumn>{cellsCreateHandler(-23)}</GridColumn>
            </SubtitleColumn>
          </div>
          <Collapse in={open}>
            <FbmArch />
          </Collapse>
        </div>
      </div>
    </>
  );
};
export default GeneralFBMLine;
