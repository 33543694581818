import React from "react";
import usePagination from "@mui/material/usePagination";

import { PaginationProps } from "./types";
import { PaginationIconButton } from "./PaginationIconButton";
import { List, TotalCount, LiCount } from "./Elements";
import { PaginationItemsCount } from "./PaginationItemsCount";

export const Pagination = ({
  totalCount,
  onChange,
  itemsCountParams,
  page = 1,
}: PaginationProps): JSX.Element => {
  const pageSize = itemsCountParams?.itemsCount ?? 8;
  const count = Math.ceil(totalCount / pageSize);
  const { items } = usePagination({
    count,
    page,
    showFirstButton: true,
    showLastButton: true,
  });
  return (
    <List key="pagination-container">
      {itemsCountParams && <PaginationItemsCount {...itemsCountParams} />}
      {items.map(({ type, disabled }, index) => {
        return (
          <React.Fragment key={`rf-pagination-${index}`}>
            <PaginationIconButton
              type={type}
              page={page}
              count={count}
              disabled={disabled}
              onChange={onChange}
            />
            {type === "previous" && (
              <LiCount key={"pagination-count"}>
                <span key={"pagination-count-current"}>
                  {page - 1 ? (page - 1) * pageSize : 1} -{" "}
                  {page === count ? totalCount : page * pageSize}{" "}
                </span>
                <TotalCount key={"pagination-count-total"}>
                  of {totalCount}
                </TotalCount>
              </LiCount>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};
