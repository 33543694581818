import React, { useEffect } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";
import { TextField } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/caseCreate";
import { Questions } from "lib/enum";

export const RadioLabel = styled(FormControlLabel)`
  color: var(--text-secondary-color);
`;
export const DmInfo = () => {
  const caseInfo = useAppSelector(selectors.getAllInfoSelector);
  const dispatch = useAppDispatch();

  const changeDMHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setDmEnabled(event.target.value));
  };

  const changeDMNumberHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(actions.setDmNumber(event.target.value));
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="body2" color={Colors.SECONDARY} lineHeight={2}>
          Will Dental monitoring be applied to the Patient?
        </Typography>
        <RadioGroup
          data-intercom-target="Dental mentoring"
          row
          value={caseInfo.dmEnabled}
          onChange={changeDMHandler}
        >
          <RadioLabel
            componentsProps={{ typography: { fontSize: 12 } }}
            value={Questions.NO}
            control={<Radio />}
            label="No"
          />
          <RadioLabel
            componentsProps={{ typography: { fontSize: 12 } }}
            value={Questions.YES}
            control={<Radio />}
            label="Yes"
          />
        </RadioGroup>
      </Stack>
    </Stack>
  );
};
