import { FC, useState } from "react";
import { ExtractionType } from "./ExtractionType";
import { Stack, Typography } from "@mui/material";
import { ToothArches } from "components/common";
import { Colors } from "consts/colors";

interface Props {
  extractions: (string | null)[];
  onChangeHandler: (selectedTooth: number, value: string | null) => void;
  upperArch: boolean;
  lowerArch: boolean;
  toothClickHandler: (toothNumber: number, color: string) => void;
}
export const ExtractionsArchContainer: FC<Props> = ({
  extractions,
  onChangeHandler,
  upperArch,
  lowerArch,
  toothClickHandler,
}) => {
  const [anchorEl, setAnchorEl] = useState<SVGGElement | null>(null);
  const [selectedTooth, setSelectedTooth] = useState<number | null>(null);

  const toothClick = (
    event: React.MouseEvent<SVGGElement> | null,
    toothNumber: string
  ) => {
    const tooth = Number(toothNumber.split("-")[1]) - 1;
    if (!extractions[tooth]) {
      toothClickHandler(tooth, Colors.DISABLED);
    }
    setSelectedTooth(tooth);
    setAnchorEl(event?.currentTarget!);
  };

  const closeHandler = () => {
    setSelectedTooth(null);
    setAnchorEl(null);
  };

  const changeHandler = (value: string | null) => {
    onChangeHandler(selectedTooth!, value);
    closeHandler();
  };

  return (
    <>
      <ExtractionType
        value={extractions[selectedTooth!] || ""}
        anchorEl={anchorEl}
        onClose={closeHandler}
        onChange={changeHandler}
      />
      <Stack spacing={5} data-intercom-target="Extractions">
        <Typography variant="h2" color={Colors.SECONDARY}>
          Specify any planned extractions
        </Typography>
        <ToothArches
          teeth={extractions}
          onClick={toothClick}
          upperArch={upperArch}
          lowerArch={lowerArch}
        />
      </Stack>
    </>
  );
};
