import React from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { Select, TextField, FormLabel } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  requiredStepsNum,
  wearSchedule as wearScheduleDict,
} from "lib/dictionaries";
import { selectors, actions } from "store/caseCreate";

const StyledSelect = styled(Select)`
  max-width: 322px;
`;
export const StepsStartSelector = () => {
  const { stepsType, wearSchedule, customStepsNum } = useAppSelector(
    selectors.getStepsInfoSelector
  );
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectors.getAllInfoSelector);

  const wearScheduleChangeHandler = (
    event: SelectChangeEvent<string | number>
  ) => {
    dispatch(actions.setWearSchedule(event.target.value as string));
  };
  const stepsTypeChangeHandler = (
    event: SelectChangeEvent<string | number>
  ) => {
    dispatch(actions.setStepsType(event.target.value as string));
  };
  const customStepsChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(actions.setCustomSteps(event.target.value));
  };
  if (order.preEval) {
    return null;
  }

  return (
    <Stack spacing={3} mt={3}>
      <FormLabel
        required={!order.preEval}
        label="Specify patient wear schedule"
      >
        <StyledSelect
          error={order.requiredFields.includes("wearSchedule")}
          helperText={
            order.requiredFields.includes("wearSchedule")
              ? "Please fill this field"
              : undefined
          }
          value={wearSchedule || ""}
          placeholder="Select one"
          fullWidth
          onChange={wearScheduleChangeHandler}
          data={wearScheduleDict}
        />
      </FormLabel>

      <FormLabel
        required={!order.preEval}
        label="Specify optimal number of starting treatment steps"
      >
        <Stack spacing={3} direction="row">
          <StyledSelect
            placeholder="Select one"
            fullWidth
            value={stepsType || ""}
            onChange={stepsTypeChangeHandler}
            data={requiredStepsNum}
          />
          {stepsType === "custom" && (
            <TextField
              value={customStepsNum}
              onChange={customStepsChangeHandler}
              sx={{ maxWidth: 123 }}
            />
          )}
        </Stack>
      </FormLabel>
    </Stack>
  );
};
