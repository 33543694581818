type assetStructure = {
	files: {
		['main.js']: string;
		['runtime-main.js']: string;
	};
};
const getCurrentManifest = async (): Promise<assetStructure | null> => {
	try {
		const response = await fetch(
			process.env.REACT_APP_REDIRECT_URI
				? `${process.env.REACT_APP_REDIRECT_URI}asset-manifest.json`
				: 'asset-manifest.json'
		);
		return await response.json();
	} catch (e) {
		return null;
	}
};
const checkChunkActuality = async (chunkStructure: assetStructure) => {
	try {
		const result = await getCurrentManifest();
		if (!result) {
			return;
		}
		if (
			result.files['main.js'] !== chunkStructure.files['main.js'] ||
			result.files['runtime-main.js'] !==
				chunkStructure.files['runtime-main.js']
		) {
			document.location.reload();
		} else {
			setTimeout(() => checkChunkActuality(chunkStructure), 30000);
		}
	} catch (e) {
		console.error(e);
	}
};
let currentChunk: assetStructure;
export const autoRefreshPlugin = async () => {
	try {
		const result = await getCurrentManifest();
		if (!result) return;
		currentChunk = result;
		setTimeout(() => checkChunkActuality(currentChunk), 30000);
	} catch (e) {
		console.error(e);
	}
};
