import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { fileService } from "services";
import { fileDownload } from "utils/fileHelpers";
import { useStyledSnackbar } from "hooks/notification";
import { MainContainer } from "containers";

import { Container } from "./Elements";

export const FileDownloadPage = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { showErrorNotice } = useStyledSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const fileName = searchParams.get("fileName");
  const [source, setSource] = useState<string | undefined>();
  const pressHandler = () => {
    if (source) {
      fileDownload(fileName || `orthosnap_file_${fileId}`, source);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const init = async () => {
      try {
        const fileSource = await fileService.getFileBlob(
          fileId!,
          controller.signal
        );
        setSource(URL.createObjectURL(fileSource));
        fileDownload(
          fileName || `orthosnap_file_${fileId}`,
          URL.createObjectURL(fileSource)
        );
      } catch (e: any) {
        showErrorNotice(e.message);
      }
    };
    init().then();
    return () => {
      controller.abort();
    };
  }, [fileId, showErrorNotice, fileName]);
  return (
    <MainContainer>
      <Container alignItems="center" justifyContent="center">
        {!source && (
          <Stack direction="row" alignItems="baseline" spacing={2}>
            <Box>
              <CircularProgress size={16} />
            </Box>
            <Typography variant="h6">
              Please wait, the download will start automatically
            </Typography>
          </Stack>
        )}
        <Stack spacing={0.5} direction="row" mt={2} minHeight={45}>
          {!!source && (
            <>
              <Typography variant="body1">
                If the download has not started, please click on the
              </Typography>
              <Link
                sx={{ cursor: "pointer" }}
                onClick={pressHandler}
                variant="body1"
                ml={0.5}
              >
                link
              </Link>
            </>
          )}
        </Stack>
      </Container>
    </MainContainer>
  );
};
