import React from "react";
import {
  ListColumns,
  ListTableContainerColumnProps,
} from "containers/listTable/types";
import { IExtOrderInvoiceDtoForFilterView } from "lib/dto";
import { dateToString } from "utils/dateHelpers";
import { InvoiceStatuses, PaymentStatuses } from "lib/enum";
import { invoiceStatuses } from "lib/dictionaries";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import { toLocalCurrency } from "utils";
import { Colors } from "consts/colors";
import { Button } from "components/ui";
import Link from "@mui/material/Link";

const OverdueIcon = styled("span")`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--error-color);
  display: block;
`;

export const renderStatus = (
  value: InvoiceStatuses,
  { dueDate, status, payment, stripeUrl }: any
) => {
  if (stripeUrl && status === InvoiceStatuses.PAID) {
    return (
      <Link
        href={stripeUrl}
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
        target="_blank"
        rel="noreferrer"
      >
        {invoiceStatuses[value]}
      </Link>
    );
  }

  if (dueDate) {
    const currentDate = new Date();
    const dueDateDay = new Date(dueDate);
    if (dueDateDay < currentDate && status === InvoiceStatuses.OPEN) {
      return (
        <Stack spacing={1} direction="row" alignItems="center">
          <OverdueIcon />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            Overdue
          </Typography>
        </Stack>
      );
    }
  }
  if (
    status === InvoiceStatuses.OPEN &&
    payment?.status === PaymentStatuses.IN_PROCESS
  ) {
    return "Pending";
  }
  return invoiceStatuses[value];
};

export const renderPaymentLink = (
  link: string,
  rowData: IExtOrderInvoiceDtoForFilterView
) => {
  // if link not exist return null
  if (!link) return null;

  // if paid show "view receipt" button, else show "pay invoice" button
  return (
    <Link target="_blank" href={link}>
      <Button sx={{ paddingInline: 4 }} variant="contained">
        {rowData.status === InvoiceStatuses.PAID
          ? "View Receipt"
          : "Pay Invoice"}
      </Button>
    </Link>
  );
};

export const columns: ListColumns<IExtOrderInvoiceDtoForFilterView> = [
  {
    title: "Invoice ID",
    dataIndex: "id",
    sort: true,
    render: (id) => (
      <Typography
        variant="body2"
        sx={{ fontWeight: 500 }}
        color={Colors.PRIMARY}
      >
        {id}
      </Typography>
    ),
  },
  {
    title: "Patient",
    dataIndex: "patient",
    render: ({ firstName, lastName }) => (
      <Typography
        variant="body2"
        sx={{ fontWeight: 500 }}
        color={Colors.PRIMARY}
      >
        {" "}
        {`${firstName} ${lastName}`}
      </Typography>
    ),
    sort: true,
  },
  {
    title: "Invoice date",
    dataIndex: "openDate",
    render: (date) => dateToString(date),
    sort: true,
  },
  {
    title: "Invoice due",
    dataIndex: "dueDate",
    render: (date) => dateToString(date),
    sort: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    sort: true,
    render: renderStatus,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (amount: number) => (
      <Typography variant="body2" fontWeight="bold">
        {toLocalCurrency(amount)}
      </Typography>
    ),
  },
];

const payColumn: ListTableContainerColumnProps<IExtOrderInvoiceDtoForFilterView> =
  {
    title: "Payment Link",
    dataIndex: "stripeUrl",
    width: "210px",
    style: {
      rowCell: {
        paddingBlock: 20,
        boxSizing: "border-box",
      },
    },
    render: renderPaymentLink,
  };

export const columnsWithPayColumn = [...columns, payColumn];
