import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { FileUploadArea } from "components/common";
import { FormLabel, PreviewUploadFile } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { createFileForStore } from "utils/fileHelpers";
import { selectors, actions } from "store/requestRemake";

export const RemakeFilesUpload = () => {
  const dispatch = useAppDispatch();
  const { commonFiles, clinicalPhotos } = useAppSelector(
    selectors.getFilesSelector
  );
  const needToSendFiles = useAppSelector(
    (state) => state.requestRemake.needToSendFiles
  );

  const filesUploadHandler =
    (type: "clinicalPhotos" | "commonFiles") => (files: File[]) => {
      dispatch(
        actions.setFiles({ files: files.map(createFileForStore), type })
      );
    };
  const filesDeleteHandler =
    (type: "clinicalPhotos" | "commonFiles") => (name: string) => {
      dispatch(
        actions.deleteFile({
          fileName: name,
          type,
        })
      );
    };

  if (!needToSendFiles) {
    return null;
  }
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item md={5}>
          <FormLabel
            label="Upload your scans (STL format)"
            targetLabel="STL files"
          >
            <FileUploadArea
              sx={{ height: 70 }}
              onFilesUpload={filesUploadHandler("commonFiles")}
            />
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              {commonFiles.map((file) => (
                <PreviewUploadFile
                  key={file.id}
                  name={file.name}
                  contentType={file.contentType}
                  src={file.src}
                  onDelete={filesDeleteHandler("commonFiles")}
                />
              ))}
            </Stack>
          </FormLabel>
        </Grid>
        <Grid item md={5}>
          <FormLabel
            label="Upload your Clinical Photos"
            targetLabel="Clinical photos"
          >
            <FileUploadArea
              sx={{ height: 70 }}
              onFilesUpload={filesUploadHandler("clinicalPhotos")}
            />
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              {clinicalPhotos.map((file) => (
                <PreviewUploadFile
                  key={file.id}
                  name={file.name}
                  contentType={file.contentType}
                  src={file.src}
                  onDelete={filesDeleteHandler("clinicalPhotos")}
                />
              ))}
            </Stack>
          </FormLabel>
        </Grid>
      </Grid>
    </Box>
  );
};
