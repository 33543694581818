import { ShippingInfoStatus } from "../enum";

export const shippingInfoStatus: {
  [key in ShippingInfoStatus]: string;
} = {
  INVALID_STATUS: "Invalid status",
  CREATED: "Created",
  IN_TRANSIT: "In transit",
  DELIVERED: "Delivered",
  EXCEPTION: "Exception",
  PICK_UP: "Pick up",
  MANIFEST_PICK_UP: "Manifest pick up",
  OTHER: "Other",
};
