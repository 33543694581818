import { TreatmentPlanState } from "./types";
import { ITreatmentPlanStepInstructionDto } from "lib/dto";
import { NotesRecipient } from "lib/enum";

export const initObjInstruction: Omit<
  ITreatmentPlanStepInstructionDto,
  "id" | "created" | "modified" | "treatmentPlanStepId"
> = {
  toothNumber: 0,
  rotation: null,
  mesial: null,
  distal: null,
  torque: null,
  fullBodyMovement: null,
  attachment: null,
  intrusion: false,
  extrusion: false,
  pinNumber: null,
  reversed: false,
};

export const initState: TreatmentPlanState = {
  treatmentPlan: null,
  verificationKeys: {},
  treatPlanExtraInst: {
    upperSteps: {},
    lowerSteps: {},
  },
  tpNotes: {
    addNote: false,
    notes: [],
    currentTooth: [],
    selectedNote: null,
    noteCells: {},
    previewFilter: NotesRecipient.DOCTOR,
  },
  lowerImpOrderIds: [],
  upperImpOrderIds: [],
  treatPlanAutoGenInstr: [],
  treatmentPlanInstructions: [],
  isLoading: false,
  iprHoveredNum: null,
  showFBM: false,
  startSelectingTooth: undefined,
  selectingToothKey: {},
  selectingToothDto: [],
  showLegend: true,
  showNotes: false,
  showPin: false,
  case: null,
};
