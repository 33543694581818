import React, { useCallback, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material";

import { Colors } from "consts/colors";
import { invoiceService } from "services";
import { useStyledSnackbar } from "hooks/notification";
import { toLocalCurrency } from "utils";

import { PayAllButton } from "./PayAllButton";

interface Props {
  officeId?: number | string;
}
const OutstandingBalanceContainer = styled(Stack)`
  background-color: var(--tertiary-color);
  padding-inline: 16px 5px;
  padding-block: 5px;
  border-radius: 8px;
`;
export const OutstandingBalance: React.VFC<Props> = ({ officeId }) => {
  const { showErrorNotice } = useStyledSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const fetchOutstandingBalance = useCallback(
    (officeId?: number | string) => {
      setLoading(true);
      invoiceService
        .getOutstanding(officeId)
        .then(({ outstandingBalance }) => {
          setBalance(outstandingBalance);
        })
        .catch((error) => {
          showErrorNotice(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [showErrorNotice]
  );
  useEffect(() => {
    fetchOutstandingBalance(officeId);
  }, [fetchOutstandingBalance, officeId]);
  return (
    <OutstandingBalanceContainer
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <Typography
        fontWeight={600}
        variant="body1"
        fontSize={14}
        color={Colors.PRIMARY}
      >
        Total outstanding
      </Typography>
      <Typography variant="h2" fontSize={34} color={Colors.PRIMARY}>
        {loading ? (
          <Skeleton animation="wave" width={150} />
        ) : (
          toLocalCurrency(balance)
        )}
      </Typography>
      <PayAllButton officeId={officeId} />
    </OutstandingBalanceContainer>
  );
};
