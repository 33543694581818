import { Button } from "@mui/material";
import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { useMemo } from "react";
import { userService } from "services";
import { selectors } from "store/currentUser";

export const DoctorModeButton: React.FC = () => {
  const { currentUser } = useAppSelector(selectors.getAllUserInfo);

  const doctorModeButtonText = useMemo(() => {
    if (currentUser?.doctorMode) {
      return `Doctor Mode: ${currentUser.doctorMode.firstName} ${currentUser.doctorMode.lastName}`;
    }
    return "Doctor Mode";
  }, [currentUser?.doctorMode]);

  const handleClick = async () => {
    await userService.turnOffDoctorMode();
    window.location.reload();
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.backgroundColor = Colors.BADGE;
    e.currentTarget.textContent = "Turn off Doctor mode";
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.backgroundColor = "transparent";
    e.currentTarget.textContent = doctorModeButtonText;
  };

  return (
    <Button
      sx={{
        display: "block",
        color: "white",
        textTransform: "none",
        fontSize: "1rem",
        flexShrink: 0,
        overflow: "hidden",
        width: "15rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {doctorModeButtonText}
    </Button>
  );
};
