import React from "react";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";

import { ListTable } from "components/ui";
import { ListTableColumn } from "components/ui/listTable";
import { IExtOrderInvoiceDtoForFilterView } from "lib/dto";
import { toLocalCurrency, dateToString } from "utils";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { selectors, actions } from "store/createPayment";

import { InvoiceListContainer } from "./Elements";

export const UnpaidInvoicesList = () => {
  const dispatch = useAppDispatch();
  const invoices = useAppSelector(selectors.unpaidInvoicesSelector);
  const isLoading = useAppSelector(selectors.loadingSelector);
  const selectedInvoices = useAppSelector(selectors.selectedInvoiceSelectors);
  const invoiceInfo = useAppSelector(selectors.invoiceInfoSelectors);
  const columns: ListTableColumn<IExtOrderInvoiceDtoForFilterView>[] = [
    {
      title: "",
      dataIndex: "id",
      render: (id: number, { totalAmount }) => (
        <Checkbox
          disabled={
            (invoiceInfo.outstandingBalance - totalAmount < 1 &&
              !selectedInvoices.includes(id)) ||
            invoiceInfo.isSaving
          }
          onChange={() => dispatch(actions.selectInvoice(id))}
          checked={selectedInvoices.includes(id)}
          sx={{ padding: 0 }}
        />
      ),
    },
    {
      title: "Date",
      dataIndex: "openDate",
      render: (openDate: string) => dateToString(openDate || ""),
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      render: (doctor: IExtOrderInvoiceDtoForFilterView["doctor"]) =>
        `${doctor ? doctor.firstName : ""} ${doctor ? doctor.lastName : ""}`,
    },
    {
      title: "Patient",
      dataIndex: "patient",
      render: (patient: IExtOrderInvoiceDtoForFilterView["patient"]) =>
        `${patient ? patient.firstName : ""} ${
          patient ? patient.lastName : ""
        }`,
    },
    {
      title: "Amount, $",
      dataIndex: "totalAmount",
      render: (totalAmount: IExtOrderInvoiceDtoForFilterView["totalAmount"]) =>
        toLocalCurrency(totalAmount || ""),
    },
    {
      title: "Stripe url",
      dataIndex: "stripeUrl",
      render: (stripeUrl: any) =>
        stripeUrl ? (
          <Link href={stripeUrl} underline="none" rel="noreferrer">
            Pay Online
          </Link>
        ) : null,
    },
  ];
  return (
    <InvoiceListContainer>
      <ListTable
        maxHeight={377}
        columns={columns}
        data={invoices}
        loading={isLoading}
      />
    </InvoiceListContainer>
  );
};
