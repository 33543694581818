import {
  IExtOfficeDto,
  IOrganizationDto,
  IExtOrderInvoiceDtoForFilterView,
} from "lib/dto";

export enum CreatePaymentActions {
  FETCH_INVOICES = "payment/invoices/fetch",
  FETCH_OFFICES = "payment/offices/fetch",
  FETCH_ALL_INFO = "payment/fetch",
  FETCH_ORGS = "payment/orgs/fetch",
  PAYMENT_SAVING = "payment/create",
  CALCULATE_AMOUNTS = "payment/invoices/amount",
  CHANGE_ORG = "payment/org/change",
  SELECT_INVOICE = "payment/invoices/select",
  CHANGE_OFFICE = "payment/office/change",
  CHANGE_NOTE = "payment/note/change",
  CHANGE_PAYMENT = "payment/payment/change",
}

export interface CreatePaymentState {
  org: string;
  office: string;
  notes: string;
  outstandingBalance: number;
  invoiceAmount: number;
  orgs: IOrganizationDto[];
  offices: IExtOfficeDto[];
  invoices: IExtOrderInvoiceDtoForFilterView[];
  selectedInvoices: number[];
  isLoading: boolean;
  isSaving: boolean;
  paymentMethod: string;
  errors: string | null;
}
