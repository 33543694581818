import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { selectors } from "store/treatmentPlan";
import { useAppSelector } from "hooks/redux";

import { StepsAreaProps } from "./types";
import { StepsRow } from "./StepsRow";
import { CollapseBlock } from "./CollapseBlock";
import { EmptyRetainerRow } from "./EmptyReatinerRow";
import {
  AreaContainer,
  AreaNumberContainer,
  AreaNumber,
  ImpressionContainer,
  ImpressionLabel,
} from "./TableElements";
import { IExtTreatmentPlanStepDto } from "lib/dto";

export const StepsArea = (props: StepsAreaProps) => {
  const { plan, itemIndex, row } = props;
  const state = useAppSelector(selectors.getAllInfo);
  const orderUpperExtra: IExtTreatmentPlanStepDto | undefined = Object.values(
    state.treatPlanExtraInst?.upperSteps || {}
  ).find(({ orderId, number }: any) => !number && orderId === plan.id);
  const orderLowerExtra: IExtTreatmentPlanStepDto | undefined = Object.values(
    state.treatPlanExtraInst?.lowerSteps || {}
  ).find(({ orderId, number }: any) => !number && orderId === plan.id);

  if (!plan || !plan.upperSteps || !plan.lowerSteps) {
    return null;
  }
  const isAutogenerated =
    plan.lowerSteps[0]?.autogenerated || plan.upperSteps[0]?.autogenerated;
  const lowerIncrement =
    plan?.lowerSteps[0]?.number! > plan?.upperSteps[0]?.number! &&
    !isAutogenerated
      ? plan?.lowerSteps[0]?.number! - plan?.upperSteps[0]?.number!
      : 0;
  const upperIncrement =
    plan?.upperSteps[0]?.number! > plan?.lowerSteps[0]?.number! &&
    !isAutogenerated
      ? plan?.upperSteps[0]?.number! - plan?.lowerSteps[0]?.number!
      : 0;
  const orderNeedLower = state.lowerImpOrderIds.includes(plan.id);
  const orderNeedUpper = state.upperImpOrderIds.includes(plan.id);
  const getRow = (_: any, index: number, array: any[]) => {
    const upperStep =
      index - upperIncrement > -1
        ? plan.upperSteps[index - upperIncrement]
        : null;
    const lowerStep =
      index - lowerIncrement > -1
        ? plan.lowerSteps[index - lowerIncrement]
        : null;
    const needUpperImpressions = !index && orderNeedUpper;
    const needLowerImpressions = !index && orderNeedLower;
    return (
      <StepsRow
        key={
          `${plan.id}_${index}` ||
          `row_inkrement_${index - lowerIncrement}_${index - upperIncrement}`
        }
        plan={plan}
        itemIndex={itemIndex}
        lowerIndex={lowerStep?.autogenerated ? index : index - lowerIncrement}
        upperIndex={upperStep?.autogenerated ? index : index - upperIncrement}
        needUpperImpressions={needUpperImpressions}
        needLowerImpressions={needLowerImpressions}
        upper={upperStep}
        lower={lowerStep}
        rowNumber={Math.max(lowerStep?.number || 0, upperStep?.number || 0)}
      />
    );
  };
  return (
    <CollapseBlock collapse={props.collapse}>
      <AreaContainer>
        <AreaNumberContainer ml={1} mr={0.5}>
          <AreaNumber variant="body1">{plan.id}</AreaNumber>
        </AreaNumberContainer>
        <Box flexGrow={1}>
          {Boolean(orderLowerExtra || orderUpperExtra) && (
            <EmptyRetainerRow
              extraInstructions={{
                upperSteps: orderUpperExtra,
                lowerSteps: orderLowerExtra,
              }}
            />
          )}
          {row[itemIndex] && Array(row[itemIndex].count).fill(null).map(getRow)}
        </Box>
        <AreaNumberContainer ml={0.5} mr={1}>
          <AreaNumber variant="body1">{plan.id}</AreaNumber>
        </AreaNumberContainer>
      </AreaContainer>
      {plan?.nextOrderNeedImpr && (
        <ImpressionContainer>
          <Stack alignItems="center" direction="row" spacing={1}>
            <ImpressionLabel size="small" label="need impressions" />
          </Stack>
        </ImpressionContainer>
      )}
    </CollapseBlock>
  );
};
