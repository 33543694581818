import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { styled } from "@mui/material";

import {
  ListTableContainerColumnProps,
  ListTableContainerFilterField,
  ListTableContainerProps,
  ListTableContainerState,
} from "./types";
import { ListTable } from "components/ui";
import { useStyledSnackbar } from "hooks/notification";
import { getUrlSearchParams } from "utils";
import { ListTableColumn } from "components/ui/listTable";

const Wrapper = styled("div")`
  padding: 32px 40px 0;
`;

export const ListTableContainer = <D,>({
  requests,
  columns,
  filterField: filterFieldsProps,
  maxHeight = 511,
  onRowClick,
  emptyDataText,
  pagination = false,
  containerStyle,
}: ListTableContainerProps<D>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { showErrorNotice } = useStyledSnackbar();
  const [filterField, setFilterFields] = useState<
    ListTableContainerFilterField[]
  >(
    filterFieldsProps ?? [
      { name: "orderType", initValue: "DESC" },
      { name: "orderBy", initValue: "id" },
    ]
  );
  const [state, setState] = useState<ListTableContainerState<D>>({
    loading: false,
    data: [],
    totalCount: 0,
  });

  const handleState = useCallback(
    (key: keyof ListTableContainerState<D>, value: any) => {
      setState((oldState) => ({
        ...oldState,
        [key]: value,
      }));
    },
    []
  );

  const handleSearchParams = (field: string, value: string | number) => {
    setSearchParams({
      ...getUrlSearchParams(searchParams),
      [field]: String(value),
    });
  };
  const { fetch: propsFetch } = requests;
  const fetch = useCallback(
    async (filter: any) => {
      handleState("loading", true);
      if (
        [null, "null"].includes(filter["orderType"]) ||
        [null, "null"].includes(filter["orderBy"])
      ) {
        delete filter["orderBy"];
        delete filter["orderType"];
      }
      try {
        if (propsFetch) {
          const { data, totalCount } = await propsFetch({
            ...filter,
            pageNumber: Number(filter?.pageNumber ?? 1) - 1,
          });
          setState((oldState) => ({
            ...oldState,
            data,
            totalCount,
          }));
        }
      } catch (error) {
        let message = "Error";
        if (error instanceof Error) {
          message = error.message;
        }
        showErrorNotice(message);
      } finally {
        handleState("loading", false);
      }
    },
    [handleState, propsFetch, showErrorNotice]
  );

  const getFilter = useCallback(
    (initValue: boolean = false) => {
      const pageNumber = Number(searchParams.get("pageNumber") ?? 1);
      const itemsCount = Number(searchParams.get("itemsCount") ?? 10);
      let filter: any = {};
      if (filterField) {
        filterField.forEach((field: ListTableContainerFilterField) => {
          if (typeof field === "string") {
            if (searchParams.get(field)) {
              filter[field] = searchParams.get(field);
            }
          } else if (field?.name) {
            filter[field?.name] =
              searchParams.get(field?.name) ??
              (initValue ? field?.initValue : null);
          }
        });
      }
      return {
        pageNumber,
        itemsCount,
        ...filter,
      };
    },
    [filterField, searchParams]
  );

  const handleSort = (orderBy: string, orderType: string) => {
    setSearchParams({
      ...getUrlSearchParams(searchParams),
      orderBy,
      orderType,
    });
  };

  const prepareColumn = (
    columns: ListTableContainerProps<D>["columns"]
  ): ListTableColumn<D>[] => {
    return columns.map(
      (column: ListTableContainerColumnProps<D>): ListTableColumn<D> => {
        const sort =
          typeof column.sort === "boolean"
            ? (type: string | null) =>
                handleSort(column.dataIndex.toString(), String(type))
            : column.sort;
        return {
          ...column,
          sort,
        };
      }
    );
  };

  useEffect(() => {
    if (
      filterFieldsProps?.some((filter, index) => {
        if (typeof filter === "string") {
          return filter !== filterField[index];
        } else {
          return (
            filter.name !== (filterField[index] as any).name ||
            filter.initValue !== (filterField[index] as any).initValue
          );
        }
      })
    ) {
      setFilterFields(filterFieldsProps);
    }
  }, [filterFieldsProps, filterField]);
  useEffect(() => {
    setSearchParams(getFilter(true));
  }, [setSearchParams, getFilter]);

  useEffect(() => {
    if (searchParams.toString() !== "") {
      fetch(getFilter()).then();
    }
  }, [searchParams, fetch, getFilter]);

  return (
    <Wrapper style={containerStyle}>
      <ListTable
        emptyDataText={emptyDataText}
        columns={prepareColumn(columns)}
        data={state.data}
        loading={state.loading}
        maxHeight={maxHeight}
        onRowClick={onRowClick}
        pagination={
          pagination
            ? {
                page: Number(searchParams.get("pageNumber") ?? 1),
                totalCount: state.totalCount,
                onChange: (page: number) =>
                  handleSearchParams("pageNumber", page),
                itemsCountParams: {
                  onChange: (itemsCount: number) =>
                    handleSearchParams("itemsCount", itemsCount),
                  itemsCount: Number(searchParams.get("itemsCount") ?? 8),
                },
              }
            : undefined
        }
      />
    </Wrapper>
  );
};
