import {
  CaseStatuses,
  OrderFakeStatuses,
  OrderStatuses,
  OrderTaskStatuses,
  ShippingInfoStatus,
  ShippingTypes,
  TaskType,
} from "lib/enum";
import { PatientDetailsState } from "./types";
import { IExtShippingInfoDto } from "lib/dto";

export const getBaseStatuses = (
  estPlanTask: OrderTaskStatuses[],
  approveTreatPlanTask: OrderTaskStatuses[],
  shippingToDoctor: IExtShippingInfoDto | undefined,
  orderStatus: OrderStatuses | undefined,
  aligner: boolean
) => {
  const orderShipStatus =
    orderStatus === OrderStatuses.SUBMITTED_TO_DOCTOR ||
    orderStatus === OrderStatuses.RECEIVED_BY_DOCTOR;
  const finishedStatus = [
    OrderTaskStatuses.CANCELED,
    OrderTaskStatuses.DONE,
    OrderTaskStatuses.SKIPPED,
  ];
  const taskCompleteCondition = Boolean(
    (!estPlanTask?.length ||
      estPlanTask?.some((status) => finishedStatus.includes(status))) &&
      (!approveTreatPlanTask?.length ||
        approveTreatPlanTask?.some((status) => finishedStatus.includes(status)))
  );

  if (orderStatus === OrderStatuses.DRAFT) {
    return {
      status: null,
      completeStatuses: [],
    };
  }

  if (taskCompleteCondition && !shippingToDoctor && !orderShipStatus) {
    return {
      status: OrderFakeStatuses.MANUFACTURING,
      completeStatuses: [
        OrderFakeStatuses.PROCESSING,
        OrderFakeStatuses.TREAT_PLAN,
      ],
    };
  }
  if (
    taskCompleteCondition &&
    shippingToDoctor &&
    (orderStatus === OrderStatuses.READY_FOR_SHIPPING ||
      !orderShipStatus ||
      [
        ShippingInfoStatus.PICK_UP,
        ShippingInfoStatus.MANIFEST_PICK_UP,
      ].includes(shippingToDoctor.status))
  ) {
    return {
      status: OrderFakeStatuses.SHIPPING,
      completeStatuses: [
        OrderFakeStatuses.PROCESSING,
        OrderFakeStatuses.TREAT_PLAN,
        OrderFakeStatuses.MANUFACTURING,
      ],
    };
  }

  if (
    aligner &&
    shippingToDoctor &&
    ((ShippingInfoStatus.DELIVERED === shippingToDoctor.status &&
      shippingToDoctor.type === ShippingTypes.UPS) ||
      (shippingToDoctor.type !== ShippingTypes.UPS && orderShipStatus))
  ) {
    return {
      status: null,
      completeStatuses: [
        OrderFakeStatuses.PROCESSING,
        OrderFakeStatuses.TREAT_PLAN,
        OrderFakeStatuses.DELIVERED,
        OrderFakeStatuses.MANUFACTURING,
        OrderFakeStatuses.SHIPPING,
      ],
    };
  }

  if (
    (shippingToDoctor &&
      ShippingInfoStatus.DELIVERED === shippingToDoctor.status &&
      shippingToDoctor.type === ShippingTypes.UPS) ||
    (shippingToDoctor?.type !== ShippingTypes.UPS && orderShipStatus)
  ) {
    return {
      status: null,
      completeStatuses: [
        OrderFakeStatuses.PROCESSING,
        OrderFakeStatuses.TREAT_PLAN,
        OrderFakeStatuses.DELIVERED,
        OrderFakeStatuses.MANUFACTURING,
        OrderFakeStatuses.SHIPPING,
      ],
    };
  }

  return {
    status: null,
    completeStatuses: [],
  };
};

export const getStatusForAligner = (state: PatientDetailsState) => {
  const estTreatPlanTask = state.currentOrder?.orderTasks
    .filter(
      ({ task, status }) =>
        task.type === TaskType.ESTIMATE_TREAT_PLAN &&
        status !== OrderTaskStatuses.CANCELED
    )
    .map(({ status }) => status);
  const approveTreatPlanTask = state.currentOrder?.orderTasks
    .filter(
      ({ task, status }) =>
        task.type === TaskType.APPROVE_TREAT_PLAN &&
        status !== OrderTaskStatuses.CANCELED
    )
    .map(({ status }) => status);
  const shippingToDoctor = state.currentOrderShippings.find(
    ({ toLab }) => !toLab
  );

  const startStatuses = [
    OrderTaskStatuses.BLOCKED,
    OrderTaskStatuses.TO_DO,
    OrderTaskStatuses.CANCELED,
  ];

  if (state.currentOrder?.status === OrderStatuses.DRAFT) {
    return {
      status: null,
      completeStatuses: [],
    };
  }

  if (
    (!estTreatPlanTask?.length ||
      estTreatPlanTask?.some((status) => startStatuses.includes(status))) &&
    (!approveTreatPlanTask?.length ||
      approveTreatPlanTask?.some((status) => startStatuses.includes(status)))
  ) {
    return {
      status: OrderFakeStatuses.PROCESSING,
      completeStatuses: [],
    };
  }
  if (
    estTreatPlanTask?.includes(OrderTaskStatuses.IN_PROGRESS) ||
    approveTreatPlanTask?.includes(OrderTaskStatuses.IN_PROGRESS)
  ) {
    return {
      status: OrderFakeStatuses.TREAT_PLAN,
      completeStatuses: [OrderFakeStatuses.PROCESSING],
    };
  }

  return getBaseStatuses(
    estTreatPlanTask!,
    approveTreatPlanTask!,
    shippingToDoctor,
    state.currentOrder?.status,
    true
  );
};

export const getStatusForRetainer = (state: PatientDetailsState) => {
  const approveTreatPlanTask = state.currentOrder?.orderTasks
    .filter(
      ({ task, status }) =>
        task.type === TaskType.APPROVE_TREAT_PLAN &&
        status !== OrderTaskStatuses.CANCELED
    )
    .map(({ status }) => status);
  const estTreatPlanTask = state.currentOrder?.orderTasks
    .filter(
      ({ task, status }) =>
        task.type === TaskType.ESTIMATE_TREAT_PLAN &&
        status !== OrderTaskStatuses.CANCELED
    )
    .map(({ status }) => status);
  const shippingToDoctor = state.currentOrderShippings.find(
    ({ toLab }) => !toLab
  );

  if (state.currentOrder?.status === OrderStatuses.DRAFT) {
    return {
      status: null,
      completeStatuses: [],
    };
  }

  return getBaseStatuses(
    estTreatPlanTask!,
    approveTreatPlanTask!,
    shippingToDoctor,
    state.currentOrder?.status,
    false
  );
};

export const getCaseCurrentStatus = (state: PatientDetailsState) => {
  if (
    ([
      OrderStatuses.DRAFT,
      OrderStatuses.RECEIVED_BY_DOCTOR,
      OrderStatuses.SUBMITTED_TO_DOCTOR,
      state.currentOrder?.status! === OrderStatuses.CANCELED,
    ].includes(state.currentOrder?.status!) &&
      state.caseInfo?.status! === CaseStatuses.PROCESSING) ||
    state.caseInfo?.status! === CaseStatuses.DRAFT
  ) {
    return "At Office";
  }

  if (
    [
      OrderStatuses.PROCESSING_IN_LAB,
      OrderStatuses.SUBMITTED_TO_LAB,
      OrderStatuses.RECEIVED_BY_LAB,
      OrderStatuses.READY_FOR_SHIPPING,
      OrderStatuses.HOLD,
    ].includes(state.currentOrder?.status!)
  ) {
    return "At Lab";
  }

  if (
    [CaseStatuses.CLOSED, CaseStatuses.CANCELED].includes(
      state.caseInfo?.status!
    )
  ) {
    return "Archived";
  }
};
