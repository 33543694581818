import React, { useState } from "react";
import clsx from "clsx";

import {
  Wrapper,
  StyledTab,
  StyledTabs,
  StyledBadge,
  StyledListTableContainer,
} from "./Elements";
import { TabsContainerProps } from "./types";
import { ContainerSearchInput } from "./ContainerSearchInput";
import { ListTableContainer } from "../listTable";
import { ListTableContainerProps } from "../listTable/types";
import { useSearchParams } from "react-router-dom";

export const TabsContainer = <D,>({
  tabs,
  searchProps,
  children,
}: TabsContainerProps<D> & { children?: React.ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") ? Number(searchParams.get("tab")) : 0
  );
  const onChangeTab = (event: React.SyntheticEvent, value: number) => {
    setActiveTab(value);
    setSearchParams({});
  };

  return (
    <>
      <Wrapper>
        <div>
          <StyledTabs
            value={activeTab}
            onChange={onChangeTab}
            aria-label="tabs"
          >
            {tabs.map(({ label, badge }, index) => {
              let labelValue = label;
              if (badge) {
                labelValue = (
                  <StyledBadge
                    className={clsx({ active: activeTab === index })}
                    badgeContent={badge}
                  >
                    {label}
                  </StyledBadge>
                );
              }
              return (
                <StyledTab
                  data-intercom-target={`${label} grid tab`}
                  label={labelValue}
                  {...a11yProps(index)}
                />
              );
            })}
          </StyledTabs>
        </div>
        <ContainerSearchInput searchProps={searchProps} />
        {children}
      </Wrapper>
      <StyledListTableContainer>
        {tabs[activeTab].headChildren}
        <ListTableContainer
          {...(tabs[activeTab].list as ListTableContainerProps<D>)}
          containerStyle={{ paddingTop: 0 }}
          maxHeight={543}
        />
      </StyledListTableContainer>
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
    key: `tab-key-${index}`,
  };
}
