import { OrderCreateState } from "./types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { complications } from "lib/dictionaries";
import { getFilesType } from "utils/fileHelpers";

const selectSelf = (state: RootState) => state.newCase;

//info selectors
export const getPatientSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => state.patient
);

export const getStaffsOfficeSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => state.selectedOffice
)

export const getClinicalPhotosSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => state.clinicalPhotos
);
export const getOtherFilesSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => state.otherFiles
);

export const getAllInfoSelector = createSelector(selectSelf, (state) => state);

export const getStepsInfoSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => ({
    wearSchedule: state.wearSchedule,
    stepsType: state.stepsType,
    customStepsNum: state.customStepsNum,
  })
);

export const getNotesSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => state.notes
);

export const getTagsSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => ({
    includes: state.includes,
    excludes: state.excludes,
  })
);

export const getCaseBudget = createSelector(
  selectSelf,
  (state: OrderCreateState) => ({
    txPlanComplexity: state.txPlanComplexity,
    caseBudget: state.caseBudget,
    caseBudgetCost: state.caseBudgetCost,
  })
);

export const getPreferencesSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => ({
    ipr: state.ipr,
    attachments: state.attachments,
  })
);
export const getArchesSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => ({
    lower: state.lowerArch,
    upper: state.upperArch,
  })
);
export const getComplicationsSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => state.complications
);

export const getExtractionsSelector = createSelector(
  selectSelf,
  (state: OrderCreateState) => state.extractions
);

export const getComplicationFullInfo = createSelector(
  selectSelf,
  (state: OrderCreateState) => {
    let complicationToothInfo: any = {};
    state.complications.forEach((item, index) => {
      if (item) {
        if (complicationToothInfo[item]) {
          complicationToothInfo[item].teeth.push(index + 1);
        } else {
          complicationToothInfo[item] = {
            teeth: [index + 1],
            title: complications[item],
          };
        }
      }
    });
    return Object.values(complicationToothInfo)
      .filter((infoItem: any) => infoItem.teeth.length > 0)
      .map((infoItem: any) => {
        return `${infoItem.title} (${infoItem.teeth.join(", ").trim()})`;
      })
      .join(". ")
      .trim();
  }
);

export const submitWarning = createSelector(
  selectSelf,
  (state: OrderCreateState) => {
    return (
      !state.preEval &&
      !state.orderItems.IMPRESSION &&
      !state.clinicalPhotos.some((file) => getFilesType(file) === "model") &&
      !state.otherFiles.some((file) => getFilesType(file) === "model")
    );
  }
);

export * from "./requiredStepsSelectors";
