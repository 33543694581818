import { styled } from "@mui/material";
import { Button } from "components/ui";

export const ClearButton = styled(Button)`
  font-size: 10px;
  background-color: #e7e7e7;
  color: var(--text-secondary-color);
  height: 18px;
  padding: 4px;
  margin-left: 16px;
  &:hover {
    background-color: var(--disabled-color);
  }
`;
