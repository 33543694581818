import { Divider, Grid, Typography, styled } from "@mui/material";
import { Colors } from "consts/colors";

export const StyledDivider = styled(Divider)(() => {
  return {
    borderColor: Colors.CONTAINER_BORDER_GREY,
  };
});

export const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBlock: theme.spacing(1),
  };
});

export const StyledCenterGrid = styled(StyledGrid)(({ theme }) => {
  return {
    justifyContent: "center",
  };
});

export const StyledLeftGrid = styled(StyledGrid)(({ theme }) => {
  return {
    justifyContent: "left",
    paddingLeft: theme.spacing(2),
  };
});

export const StyledRightGrid = styled(StyledGrid)(({ theme }) => {
  return {
    justifyContent: "right",
    textAlign: "right",
    paddingRight: theme.spacing(2),
  };
});

export const StyledTitle = styled(Typography)(() => {
  return {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
    color: Colors.TEXT_SECONDARY,
  };
});
