import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { MainContainer, ListTableContainer } from "containers";
import { Header, SearchInput } from "components/common";
import { Button } from "components/ui";
import { getUrlSearchParams } from "utils/urlHelpers";
import { caseService } from "services";
import { dateToString, getDateDiffInYears } from "utils/dateHelpers";
import { ICaseTabDto, IPatientDto } from "lib/dto";
import { ListTableContainerColumnProps } from "containers/listTable/types";
import Typography from "@mui/material/Typography";
import { Colors } from "consts/colors";
import { StatusColumn } from "./components/StatusColumn";

const fetch = async (filter: any) => {
  return await caseService.getCasesSearch(filter);
};
const LabStatus = ["Active", "Manufacturing", "Shipping", "Delivered"];
export const PatientsSearchPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const columns: ListTableContainerColumnProps<ICaseTabDto>[] = [
    {
      title: "Patient",
      targetLabel: "Patient column",
      style: {
        headerCell: { justifyContent: "center" },
      },
      width: "200px",
      dataIndex: "patientFirstName",
      onClick: () => {},
      render: (_: string, { patientDto }) => {
        return (
          <Typography fontWeight={500} variant="body2" color={Colors.PRIMARY}>
            {`${patientDto.firstName} ${patientDto.lastName}`}
          </Typography>
        );
      },
    },
    {
      title: "Age, Sex",
      targetLabel: "Age, Sex column",
      style: {
        headerCell: { justifyContent: "center" },
      },
      width: "200px",
      dataIndex: "patientDto",
      onClick: () => {},
      render: (patient: IPatientDto) => {
        if (
          !patient.birthDate ||
          getDateDiffInYears(new Date(), new Date(patient.birthDate)) < 1
        ) {
          return (
            <Typography fontWeight={500} variant="body2" color={Colors.PRIMARY}>
              {patient.gender ? "M" : "F"}
            </Typography>
          );
        }
        return (
          <Typography fontWeight={500} variant="body2" color={Colors.PRIMARY}>
            {`${getDateDiffInYears(new Date(), new Date(patient.birthDate))}, ${
              patient.gender ? "M" : "F"
            }`}
          </Typography>
        );
      },
    },
    {
      title: "Case Status",
      targetLabel: "Case Status column",
      width: "200px",
      dataIndex: "labStatus",
      render: (status: number) => LabStatus[status],
    },
    {
      title: "Last activity",
      targetLabel: "Last activity column",
      dataIndex: "lastActivity",
    },
    {
      title: "Last order date",
      targetLabel: "Last order date column",
      dataIndex: "lastActivityDate",
      sort: true,
      render: (date: string) => (date ? dateToString(date) : "N/A"),
    },
    {
      title: "Status",
      targetLabel: "Status column",
      dataIndex: "caseTabAction",
      width: "250px",
      render: (_, info) => <StatusColumn caseInfo={info} />,
      style: {
        rowCell: {
          padding: "12px 24px",
        },
      },
      sort: true,
    },
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleEnter = (e: React.KeyboardEvent) => {
    if (e?.code === "Enter") {
      if (search) {
        setSearchParams({
          ...getUrlSearchParams(searchParams),
          "search-for": search,
        });
      } else {
        navigate(`/cases/`, { replace: true });
      }
    }
  };
  const resetSearchHandler = () => navigate(`/cases/`, { replace: true });
  const navigateHandler = () => {
    navigate("/orders");
  };
  function onRowClick({ id }: ICaseTabDto) {
    navigate(`/cases/${id}`);
  }
  useEffect(() => {
    if (searchParams?.get("search-for")) {
      setSearch(searchParams?.get("search-for")!);
    }
  }, [searchParams]);
  return (
    <MainContainer>
      <Header
        title={"Patients"}
        subtitle={
          <SearchInput
            targetLabel="Patient search field"
            onChange={handleChange}
            onKeyDown={handleEnter}
            value={search || ""}
            placeholder="Search patients..."
            endAdornment={
              search ? (
                <IconButton onClick={resetSearchHandler}>
                  <CloseIcon />
                </IconButton>
              ) : undefined
            }
          />
        }
        right={
          <Button
            onClick={navigateHandler}
            fatButton
            variant="contained"
            color="primary"
          >
            + Create order
          </Button>
        }
      />

      <ListTableContainer
        filterField={[
          "search-for",
          { name: "orderType", initValue: "DESC" },
          { name: "orderBy", initValue: "id" },
        ]}
        onRowClick={onRowClick}
        columns={columns}
        requests={{ fetch }}
        pagination
      />
    </MainContainer>
  );
};
