import { OrderItemType } from "lib/enum";
import { CreateOrderState } from "./types";

export const initState: CreateOrderState = {
  reason: "",
  isLoading: false,
  isSaving: false,
  previousOrder: null,
  caseInfo: null,
  stepsType: "5",
  customStepsNum: 5,
  lowerArch: false,
  upperArch: false,
  productionType: "0",
  clinicalPhotos: [],
  commonFiles: [],
  verificationKeys: [],
  requestedRetainers: 0,
  orderNote: "",
  showVerification: false,
  requiredFields: [],
  orderItems: {
    [OrderItemType.BITE]: 0,
    [OrderItemType.IMPRESSION]: 0,
    [OrderItemType.MODEL]: 0,
  },
  useMeditLink: false,
  removeAttachment: false,
};
