import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "components/ui";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { selectors, actions } from "store/patientDetail";

export const InnerFields = () => {
  const [date, setDate] = useState<Date | null>(null);
  const dispatch = useAppDispatch();
  const dmInfo = useAppSelector(selectors.dmInfo);
  const { caseInfo } = useAppSelector(selectors.caseInfoSelector);

  const dateChangeHandler = (value: Date | null) => {
    if (!isNaN(Number(value)) || value === null) {
      dispatch(actions.setDMBirth(value?.toISOString() || ""));
    }
    setDate(value);
  };
  const phoneChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setDMPhone(event.target.value));
  };
  const notifyChangeHandler = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    dispatch(actions.setDMNotify(checked));
  };
  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setDMEmail(event.target.value));
  };
  const showText =
    !Boolean(caseInfo?.patient?.email) ||
    !Boolean(caseInfo?.patient?.phone) ||
    !Boolean(caseInfo?.patient?.birthDate);
  return (
    <>
      {showText && (
        <Typography variant="body1" mt={2}>
          The following fields are required to complete this enrollment:
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box mt={4}>
          <Grid container spacing={2} alignItems="center">
            {!Boolean(caseInfo?.patient?.phone) && (
              <>
                <Grid item sm={6}>
                  <Typography fontWeight="bold" variant="body2">
                    Phone number
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={dmInfo.phoneNumber}
                    onChange={phoneChangeHandler}
                  />
                </Grid>
              </>
            )}
            {!Boolean(caseInfo?.patient?.email) && (
              <>
                <Grid item sm={6}>
                  <Typography fontWeight="bold" variant="body2">
                    Email
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    value={dmInfo.email}
                    onChange={emailChangeHandler}
                  />
                </Grid>
              </>
            )}
            {!Boolean(caseInfo?.patient?.birthDate) && (
              <>
                <Grid item sm={6}>
                  <Typography fontWeight="bold" variant="body2">
                    Birth date
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={date}
                    onChange={dateChangeHandler}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </>
            )}
            <Grid item sm={6}>
              <FormControlLabel
                checked={dmInfo.notify}
                onChange={notifyChangeHandler}
                control={<Checkbox color="primary" />}
                label="Notify patient"
              />
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    </>
  );
};
