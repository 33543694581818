import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import { AUTH_TOKEN_NAME } from "services/apiConsts";
import { createRedirectPath } from "../../utils";

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const tokenExists = localStorage.getItem(AUTH_TOKEN_NAME);
  const isAuthorized = useAppSelector(
    (state) => state.currentUser.isAuthorized
  );
  if (!isAuthorized && Boolean(tokenExists)) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
  if (!isAuthorized) {
    createRedirectPath();
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <>{children}</>;
};
