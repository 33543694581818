import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ISearchAllResponseModel } from "lib/model";
import { commonService } from "services";

import { RootState } from "../store";

export const setSearchValue = createAction<string>("search/value/set");
export const setSearchOpen = createAction<boolean>("search/open/set");
export const clearState = createAction("search/state/clear");

export const getSearchResults = createAsyncThunk<ISearchAllResponseModel>(
  "search/results/fetch",
  async (_, thunkAPI) => {
    const { search } = thunkAPI.getState() as RootState;
    return await commonService.getSearchResults(search.searchValue);
  }
);
