import { dateToString } from "utils/dateHelpers";
import { IShippingInfoForFilterViewDto } from "lib/dto";
import { ListTableContainerProps } from "containers/listTable/types";
import { Link } from "react-router-dom";
import { Colors } from "../../consts/colors";
import React from "react";

export const columns: ListTableContainerProps<IShippingInfoForFilterViewDto>["columns"] =
  [
    {
      title: "ID",
      width: "150px",
      dataIndex: "id",
      sort: true,
    },
    {
      title: "Date Shipping",
      width: "160px",
      dataIndex: "shippingDate",
      sort: true,
      render: (date: string) => dateToString(date),
    },
    {
      title: "Shipped Date",
      width: "160px",
      dataIndex: "shippedDate",
      render: (date: string) => dateToString(date),
    },
    {
      title: "Patient",
      width: "250px",
      dataIndex: "patientFirstName",
      sort: true,
      render: (
        _: string,
        { patientFirstName, patientLastName }: IShippingInfoForFilterViewDto
      ) => {
        return `${patientFirstName ? patientFirstName : ""} ${
          patientLastName ? patientLastName : ""
        }`;
      },
    },
    {
      title: "Order",
      width: "150px",
      dataIndex: "orderId",
      render: (orderId) => (
        <Link
          style={{ textDecoration: "none", color: Colors.PRIMARY }}
          to={`/orders/${orderId}`}
        >
          {orderId}
        </Link>
      ),
    },
    {
      title: "Tracking number",
      width: "250px",
      dataIndex: "shipmentId",
      render: (shipmentId: string) =>
        shipmentId ? (
          <a
            rel="noreferrer"
            href={`https://www.ups.com/track?tracknum=${shipmentId}`}
            target="_blank"
            className="link"
          >
            {shipmentId}
          </a>
        ) : null,
    },
    {
      title: "Type",
      dataIndex: "type",
      sort: true,
    },
  ];
