import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import { MainContainer } from "containers";
import { Header } from "components/common";
import { Button } from "components/ui";
import {
  OrganizationInfo,
  UnpaidInvoicesList,
  InvoiceInfo,
} from "./components";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/createPayment";
import { useStyledSnackbar } from "hooks/notification";

export const CreatePaymentPage = () => {
  const errors = useAppSelector(selectors.errorsSelector);
  const loading = useAppSelector((state) => state.createPayment.isSaving);
  const buttonDisabled = useAppSelector(selectors.disableSaveButton);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();

  const saveHandler = () => {
    dispatch(actions.createPayment())
      .unwrap()
      .then(() => navigate(-1));
  };
  useEffect(() => {
    if (errors) {
      showErrorNotice(errors);
    }
  }, [errors, showErrorNotice]);
  return (
    <MainContainer>
      <Header title="Create payment" />
      <OrganizationInfo />
      <InvoiceInfo />
      <UnpaidInvoicesList />
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        px={6}
        pb={6}
      >
        <Button onClick={() => navigate(-1)} fatButton variant="outlined">
          Close
        </Button>
        <Button
          disabled={buttonDisabled}
          fatButton
          variant="contained"
          onClick={saveHandler}
        >
          {loading ? (
            <CircularProgress
              sx={{ color: "white", marginLeft: 1 }}
              size={20}
            />
          ) : null}
          Create payment
        </Button>
      </Stack>
    </MainContainer>
  );
};
