import {
  IExtCaseWithCaseProgress,
  IExtFileDto,
  IExtOrderForCaseViewDto,
  IExtOrderMessageDto,
  IExtOrderWithItemsAndTasksDto,
  IExtShippingInfoDto,
  ITreatmentPlanProductionInstructions,
  IEventDashboardDto,
  IOrderInvoiceDto,
  IExtOfficeStaffDto,
  IExtOrderTaskDto,
  ICaseTabDto,
  IProductTypesDto,
  IPriceListDto,
} from "lib/dto";
import { Nullable } from "lib/types";
import { CaseFinishRate } from "lib/enum";
import { IBaseResponseList } from "lib/model";

export enum PatientDetailsAction {
  FETCH_INFO = "patient/info/fetch",
  DOWNLOAD_FILE = "patient/files/download",
  CREATE_MESSAGE = "patient/message/create",
  READ_MESSAGE = "patient/message/read",
  CLEAR_STATE = "patient/state/clear",
  SUBMIT_ORDER = "patient/order/submit",
  APPROVE_PLAN = "patient/plan/approve",
  REJECT_PLAN = "patient/plan/reject",
  CANCEL_CASE = "patient/case/cancel",
  CANCEL_LAST_ORDER = "patient/order/cancel",
  SET_CASE_INFO = "patient/caseInfo/set",
  SCHEDULING_SET = "patient/scheduling/set",
  VERIFICATION_SET = "patient/verification/set",
  SEND_START_WEARING = "patient/startWearing/set",
  RELOAD_ORDER = "patient/order/reload",
  UPLOAD_FILE_CASES = "patient/files/upload",
  CREATE_SHIPPING = "patient/shipping/create",
  DELETE_MESSAGE_FILE = "patient/message/file/delete",
  SET_RATING = "patient/rating/set",
  SET_RATING_COMMENT = "patient/ratingComment/set",
  SET_DM_PHONE = "patient/dm/phone",
  SET_DM_BIRTH = "patient/dm/birth",
  SET_DM_EMAIL = "patient/dm/email",
  SET_DM_NOTIFY = "patient/dm/notify",
  SUBMIT_DM_PATIENT = "patient/dm/submit",
  SUBMIT_START_STOP_MONITORING_DM = "patient/dm/submitStartStopMonitoring",
  SUBMIT_RATING = "patient/rating/submit",
  OPEN_DM = "patient/dm/open",
  DISMISS_DM = "patient/dm/dismiss",
  SHOW_START_STOP_MD = "patient/dm/showStartStopDM",
  HIDE_START_STOP_MD = "patient/dm/hideStartStopDM",
  UPDATE_PATIENT = "patient/update",
}

export interface PatientDetailsState {
  isLoading: boolean;
  isOrderUpdating: boolean;
  showRating: boolean;
  ratingCompleted: boolean;
  raiting?: CaseFinishRate | undefined;
  ratingComment: string;
  searchTabCaseId: IBaseResponseList<ICaseTabDto>;
  caseInfo: Nullable<IExtCaseWithCaseProgress>;
  productType: Nullable<IProductTypesDto>;
  priceList: Nullable<IPriceListDto>;
  currentOrder: Nullable<IExtOrderWithItemsAndTasksDto>;
  currentOrderShippings: IExtShippingInfoDto[];
  currentOrderProducedSteps: Nullable<ITreatmentPlanProductionInstructions>;
  orderMessages: IExtOrderMessageDto[];
  previousOrders: IExtOrderForCaseViewDto[];
  caseEvents: IEventDashboardDto[];
  invoices: IOrderInvoiceDto[];
  openScheduling: boolean;
  openVerification: boolean;
  showDM: boolean;
  showStartStopDM: boolean;
  officeStaff: Nullable<IExtOfficeStaffDto>;
  orderTasks: IExtOrderTaskDto[];
  dmFields: {
    birthDate: string;
    phoneNumber: string;
    email: string;
    notify: boolean;
  };
  files: (IExtFileDto & { src?: string })[];
  errors: string;
}
