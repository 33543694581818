import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { selectors, actions } from "store/patientDetail";
import { Colors } from "consts/colors";

import { ShippingCreateContainer } from "containers";
import printJS from "print-js";
import { OrderStatuses } from "lib/enum";

export const CreateShippingButton: React.VFC = () => {
  const { currentOrder } = useAppSelector(selectors.allInfoSelector);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();

  const openHandler = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };
  const printHandler = () => {
    setLoading(true);
    dispatch(actions.createShippingForCurrentOrder())
      .unwrap()
      .then(({ shippingLabel }) => {
        setLoading(false);
        printJS({
          printable: `data:image/jpeg;base64,${shippingLabel}`,
          type: "image",
        });
        closeHandler();
      })
      .catch((error) => {
        showErrorNotice(error.message);
        setLoading(false);
      });
  };

  if (
    [
      OrderStatuses.DRAFT,
      OrderStatuses.SUBMITTED_TO_DOCTOR,
      OrderStatuses.CANCELED,
      OrderStatuses.READY_FOR_SHIPPING,
      OrderStatuses.RECEIVED_BY_DOCTOR,
    ].includes(currentOrder?.status!)
  ) {
    return null;
  }
  return (
    <>
      <Button
        data-intercom-target="Create shipping button"
        variant="outlined"
        onClick={openHandler}
      >
        Create shipping
      </Button>
      <Dialog fullWidth open={open} maxWidth="lg">
        <DialogTitle color={Colors.PRIMARY}>Create Shipping Label</DialogTitle>
        <DialogContent>
          <ShippingCreateContainer orderId={currentOrder?.id!} />
        </DialogContent>
        <DialogActions sx={{ paddingInline: 5, paddingBottom: 3 }}>
          <Button fatButton variant="outlined" onClick={closeHandler}>
            Close
          </Button>
          <Button
            onClick={printHandler}
            loading={loading}
            fatButton
            variant="contained"
          >
            Create shipping
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
