import React from "react";
import { Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/caseCreate";
import { useStyledSnackbar } from "hooks/notification";

export const SaveButton = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectors.getAllInfoSelector);
  const { showErrorNotice } = useStyledSnackbar();
  const saveHandler = () => {
    if (order.caseId) {
      dispatch(actions.updateCaseOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          if (filesSaveError) {
            showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
          }
        })
        .catch((error) => {
          showErrorNotice(error.message);
        });
    } else {
      dispatch(actions.createOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          if (filesSaveError) {
            showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
          }
        })
        .catch((error) => {
          showErrorNotice(error.message);
        });
    }
  };
  return (
    <Button
      data-intercom-target="Save button"
      onClick={saveHandler}
      variant="outlined"
    >
      {Boolean(order.orderId) ? "Save" : "Save as draft"}
    </Button>
  );
};
