import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";

interface Props {
  label: string;
  required?: boolean;
  hasError?: boolean;
  targetLabel?: string;
}
export const FormLabel: React.FC<Props> = (props) => {
  return (
    <Stack data-intercom-target={props.targetLabel || props.label}>
      <Typography variant="body2" color={Colors.SECONDARY} lineHeight={2}>
        {props.label}
        {props.required ? "*" : ""}
      </Typography>
      <div>{props.children}</div>
    </Stack>
  );
};
