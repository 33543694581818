export enum InvoiceStatuses {
  DRAFT = "DRAFT",
  PRE_DRAFT = "PRE_DRAFT",
  OPEN = "OPEN",
  PAID = "PAID",
  VOID = "VOID",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  UNPAID = "UNPAID",
}

export const invoiceStatusesEnum = {
  DRAFT: "Draft",
  PRE_DRAFT: "Pre Draft",
  OPEN: "Open",
  PAID: "Paid",
  VOID: "Void",
  PENDING: "Pending",
  UNCOLLECTIBLE: "Uncollectible",
  UNPAID: "Unpaid",
};

export type invoiceStatusType =
  | "DRAFT"
  | "PRE_DRAFT"
  | "OPEN"
  | "PAID"
  | "VOID"
  | "UNCOLLECTIBLE"
  | "UNPAID";
