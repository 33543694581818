import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CreatePaymentState } from "./types";
import { PaymentsMethod } from "../../lib/enum";

const selectSelf = (state: RootState) => state.createPayment;

export const orgsSelector = createSelector(
  selectSelf,
  (state: CreatePaymentState) => state.orgs
);
export const officesSelector = createSelector(
  selectSelf,
  (state: CreatePaymentState) => state.offices
);

export const officeSelector = createSelector(
  selectSelf,
  (state: CreatePaymentState) => {
    return state.offices.find(({ id }) => id.toString() === state.office);
  }
);

export const disableSaveButton = createSelector(selectSelf, (state) => {
  const office = state.offices.find(({ id }) => id.toString() === state.office);
  if (
    state.paymentMethod === PaymentsMethod.PROMO &&
    Number(office?.promo) < state.invoiceAmount
  ) {
    return true;
  }

  if (
    state.paymentMethod === PaymentsMethod.BALANCE &&
    Number(office?.debitBalance) < state.invoiceAmount
  ) {
    return true;
  }

  return !state.office || !state.paymentMethod;
});
export const getInfoForSelectedOrg = createSelector(
  selectSelf,
  (state: CreatePaymentState) => {
    return {
      orgName: state.orgs[0]?.name || "",
      officeName: state.offices[0]?.name || "",
    };
  }
);

export const selectedInvoiceSelectors = createSelector(
  selectSelf,
  (state) => state.selectedInvoices
);

export const invoiceInfoSelectors = createSelector(selectSelf, (state) => ({
  invoiceAmount: state.invoiceAmount,
  outstandingBalance: state.outstandingBalance,
  isSaving: state.isSaving,
}));
export const editableFields = createSelector(
  selectSelf,
  (state: CreatePaymentState) => ({
    notes: state.notes,
    office: state.office,
    paymentMethod: state.paymentMethod,
    org: state.org,
    isSaving: state.isSaving,
  })
);

export const errorsSelector = createSelector(
  selectSelf,
  (state: CreatePaymentState) => state.errors
);

export const unpaidInvoicesSelector = createSelector(
  selectSelf,
  (state: CreatePaymentState) => state.invoices
);
export const loadingSelector = createSelector(
  selectSelf,
  (state: CreatePaymentState) => state.isLoading
);
export const currentBalanceSelector = createSelector(
  selectSelf,
  (state: CreatePaymentState) => {
    if (!state.office || !state.offices.length) {
      return 0;
    }
    const office = state.offices.find(({ id }) => id === Number(state.office));
    return office && office.balance ? office.balance : 0;
  }
);
