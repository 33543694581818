import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { SearchState } from "./types";
import { findMatches } from "./utils";

const selectSelf = (state: RootState) => state.search;

export const allInfoSelector = createSelector(
  selectSelf,
  (state: SearchState) => state
);

export const parsedObjectSelect = createSelector(
  selectSelf,
  (state: SearchState) => {
    return Object.entries(state.searchData)
      .filter(([_, value]) => value.totalCount > 0)
      .reduce((prevState: any, [key, value]) => {
        prevState[key] = {
          data: value.data.map((data: any) => ({
            entityId: key === "patients" ? data.caseId : data.id,
            entityName: key,
            entityData: findMatches(data),
          })),
          totalCount: value.totalCount,
        };
        return prevState;
      }, {});
  }
);

export const isEmptyResult = createSelector(
  selectSelf,
  (state: SearchState) => {
    return Object.values(state.searchData).every((value) => !value.totalCount);
  }
);
