import { Badge, styled } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Colors } from "consts/colors";

export const Wrapper = styled("div")`
  display: flex;
  align-items: flex-end;
  margin-inline: 40px;
  background-color: var(--tertiary-text-color);
  border-radius: 4px 4px 0 0;
  padding-inline: 4px;
`;

export const SearchTab = styled("div")`
  padding: 0 0 0 56px;
`;

export const StyledBadge = styled(Badge)`
  padding-right: 18px;
  font-weight: 600;
  & .MuiBadge-badge {
    font-weight: 600;
    top: 10px;
    font-size: 8px;
    border-radius: 4px;
    background: ${Colors.CONTAINER_BORDER_GREY};
    color: var(--text-dark-color);
  }
  &.active .MuiBadge-badge {
    font-weight: 600;
    color: var(--text-dark-color);
    background: var(--tertiary-text-color);
  }
`;

export const StyledTabs = styled(Tabs)`
  min-height: 38px;
  height: 32px;

  & .MuiTabs-indicator {
    display: none;
  }
`;

export const StyledListTableContainer = styled("div")`
  & th {
    background-color: white;
    color: var(--text-primary-color);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;
export const StyledTab = styled(Tab)`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 8px 8px;
  text-transform: none;
  min-height: 32px;
  height: 38px;
  min-width: 100px;
  margin-right: 4px;
  color: var(--text-dark-color);
  &.Mui-selected {
    background-color: white;
    border-radius: 4px 4px 0 0;
    color: var(--text-dark-color);
  }
`;
