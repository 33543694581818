import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { CreateStepperState } from "./types";

const selectSelf = (state: RootState) => state.createStepper;

export const getAllInfo = createSelector(
  selectSelf,
  (state: CreateStepperState) => state
);
