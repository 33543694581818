import React, { useState } from "react";

import { useStyledSnackbar } from "hooks/notification";
import { fileDownload } from "utils";

import { Button } from "./Button";
import { fileService } from "services";

interface Props {
  name: string;
  fileId: number;
  contentType?: string;
}
export const FileDownloadButton: React.FC<Props> = ({
  name,
  fileId,
  contentType,
}) => {
  const { showErrorNotice } = useStyledSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const downloadFileHandler = async () => {
    try {
      setLoading(true);
      const path = await fileService.getFile(fileId, contentType);
      fileDownload(name, path);
    } catch (e: any) {
      showErrorNotice(e.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button loading={loading} color="secondary" onClick={downloadFileHandler}>
      Download
    </Button>
  );
};
