import React, { CSSProperties } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  SearchContainer,
  SearchIconWrapper,
  StyledInputBase,
} from "./Elements";
import { InputBaseProps } from "@mui/material";

export type SearchInputProps = InputBaseProps & {
  placeholder?: string;
  value?: string;
  targetLabel?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: {
    container?: CSSProperties;
  };
};
export const SearchInput = ({
  style,
  targetLabel,
  ...props
}: SearchInputProps) => {
  return (
    <SearchContainer style={style?.container}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        inputProps={{
          "aria-label": "search",
          "data-intercom-target": targetLabel,
        }}
        {...props}
      />
    </SearchContainer>
  );
};
