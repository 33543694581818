import { ICreateMessage, IUser } from "lib/model";
import { IExtOrderMessageDto, IFileDto } from "lib/dto";

export const generateNewMessage = (
  message: ICreateMessage,
  messageId: number,
  user: IUser,
  files?: IFileDto[]
): IExtOrderMessageDto => {
  const currentData = new Date();
  return {
    ...message,
    id: messageId,
    created: currentData.toISOString(),
    modified: currentData.toISOString(),
    files: files ? files : [],
    messageTemplateId: null,
    ccRecipients: [],
    unreadMessageId: null,
    author: {
      id: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      created: "",
      modified: "",
      email: user.email,
      hubspotId: null,
      additionalRole: null,
      isEnable: true,
      labstarId: null,
      phone: user.phone,
      roles: null,
      labstarRole: "",
    },
  };
};
