import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { ShippingItem } from "./ShippingItem";
import Skeleton from "@mui/material/Skeleton";
import { CreateShippingButton } from "./CreateShippingButton";

export const ShippingLabel = () => {
  const shippings = useAppSelector(selectors.shippingsSelector);
  const isLoading = useAppSelector(selectors.isLoading);
  const { caseEvents, requiredActions } = useAppSelector(
    selectors.caseNoticeSelector
  );
  const fontSize = caseEvents.length > 0 || requiredActions.length ? 14 : 16;
  if (isLoading) {
    return <Skeleton animation="wave" />;
  }
  if (!shippings.length) {
    return (
      <Stack alignItems="center" justifyContent="center" spacing={2}>
        <Typography
          variant="body1"
          fontWeight="bold"
          color={Colors.TEXT_PRIMARY}
        >
          There's no shipping information on this order.
        </Typography>
        <CreateShippingButton />
      </Stack>
    );
  }

  return (
    <Stack spacing={1.5} fontSize={fontSize}>
      {shippings.map((shipping) => (
        <ShippingItem key={shipping.id} shipping={shipping} />
      ))}
    </Stack>
  );
};
