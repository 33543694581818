import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Link from "@mui/material/Link";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { IconButton, styled } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Colors } from "consts/colors";
import { selectors, actions } from "store/patientDetail";
import { Button } from "components/ui";

import "./styles.css";
import { ActionsButton } from "../ActionsButton";
import { SubmitDM } from "../SubmitDM";
import { StartStopDmMonitoring } from "../StartStopDmMonitoring";
import { FontAwesomeSvgIcon } from "components/common";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { PatientInfoForm } from "./PatientInfoForm";

const LightTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.TERTIARY,
    color: Colors.PRIMARY,
    borderRadius: 25,
  },
}));
export const Header = () => {
  const { caseInfo, isLoading } = useAppSelector(selectors.caseInfoSelector);
  const caseStatus = useAppSelector(selectors.getCaseBaseStatus);
  const tpApproved = useAppSelector(selectors.tpApproved);
  const dmDoctor = useAppSelector(
    (state) => state.patientCard.officeStaff?.dmDoctor
  );
  const dispatch = useAppDispatch();

  const [isPatientEditing, setIsPatientEditing] = useState(false);

  const openDmHandler = () => {
    dispatch(actions.openDM());
  };

  const openStartStopDmHandler = () => {
    dispatch(actions.showStartStopDM());
  };

  const finishPatientEditing = () => {
    setIsPatientEditing(false);
  }

  return (
    <>
      <SubmitDM />
      <StartStopDmMonitoring />
      <Stack
        className="header"
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack spacing={0.75}>
            <Stack direction="row" spacing={3}>
              <span className="header__statusBar">
                <Typography variant="body1" color={Colors.SUCCESS}>
                  {isLoading ? (
                    <Skeleton animation="wave" width={70} />
                  ) : (
                    caseStatus
                  )}
                </Typography>
              </span>
              <Typography
                variant="body1"
                fontSize={18}
                fontWeight="bold"
                color={Colors.SECONDARY}
              >
                {isLoading ? (
                  <Skeleton animation="wave" width={100} />
                ) : (
                  `Case: ${caseInfo?.id}`
                )}
              </Typography>
              {caseInfo?.dmProfileLink && caseInfo?.dmProfileId ? (
                <LightTooltip
                  title="Open patient in  dental monitoring portal"
                  followCursor
                  placement="right-start"
                >
                  <Stack direction="row" spacing={0.5}>
                    <Typography variant="body1">
                      Dental monitoring ID:
                    </Typography>
                    <Link
                      href={caseInfo?.dmProfileLink}
                      target="_blank"
                      rel="noreferrer"
                      underline="none"
                      variant="body1"
                    >
                      {caseInfo?.dmProfileId ?? "link"}
                    </Link>
                    <OpenInNewIcon color="primary" fontSize="small" />
                  </Stack>
                </LightTooltip>
              ) : null}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="h1"
                lineHeight="24px"
                fontSize={24}
                color={Colors.PRIMARY}
              >
                {isLoading ? (
                  <Skeleton animation="wave" width={380} />
                ) : (
                  `${caseInfo?.patient.firstName || ""} ${caseInfo?.patient.lastName || ""
                  }`
                )}
              </Typography>
              {!isLoading && (
                <IconButton
                  color="primary"
                  onClick={() => setIsPatientEditing(!isPatientEditing)}
                  data-intercom-target="Create/Edit message button"
                >
                  <FontAwesomeSvgIcon icon={faEdit} color="secondary" />
                </IconButton>
              )}
            </Stack>
            {isPatientEditing && (
              <PatientInfoForm onClose={finishPatientEditing} />
            )}
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          {tpApproved &&
            Boolean(!caseInfo?.externalCases?.DENTAL_MONITORING?.externalId) &&
            Boolean(dmDoctor) && (
              <Button
                data-intercom-target="Submit Patient to DM"
                fatButton
                className="header__action-button"
                variant="outlined"
                onClick={openDmHandler}
              >
                ADD DENTAL MONITORING
              </Button>
            )}
          {tpApproved &&
            Boolean(caseInfo?.externalCases?.DENTAL_MONITORING?.externalId) &&
            Boolean(dmDoctor) && (
              <Button
                data-intercom-target="Submit to StartStop Monitoring DM"
                fatButton
                className="header__action-button"
                variant="outlined"
                sx={{ width: 170 }}
                onClick={openStartStopDmHandler}
              >
                {caseInfo?.patient.onMonitoring ? "PAUSE" : "START"} MONITORING
              </Button>
            )}

          <ActionsButton />
        </Stack>
      </Stack>
    </>
  );
};
