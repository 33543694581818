import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FontAwesomeSvgIcon } from "../../common";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons/faSquareXmark";
import { styled } from "@mui/material";

import { ItemProps } from "./types";

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  padding-inline: 16px 8px;
  padding-block: 2px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  color: white;
  width: 15px;
  height: 15px;
  min-width: 15px;
  &:hover {
    background-color: transparent;
  }
`;

export const TagItem: React.VFC<ItemProps> = ({ item, onRemove }) => {
  const removeHandler = () => {
    onRemove(item);
  };
  return (
    <StyledBox>
      <Typography fontSize={12} fontWeight={500} color="white">
        {item.name}
      </Typography>
      <StyledButton onClick={removeHandler}>
        <FontAwesomeSvgIcon
          icon={faSquareXmark}
          sx={{ fontSize: 16, fontWeight: "bold" }}
        />
      </StyledButton>
    </StyledBox>
  );
};
