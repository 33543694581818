import React, { useRef } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";
import { Container, Content, ContentContainer, StyledButton } from "./Elements";
import { useNavigate } from "react-router-dom";

interface Props {
  stepLeftAction?: React.ReactNode;
  stepRightAction?: React.ReactNode;
  stepComplete: boolean;
  title?: string;
  onNext?: () => void;
  onPrevious?: () => void;
}

export const StepTabPanel: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const tabPanel = useRef<HTMLDivElement>(null);
  const nextHandler = () => {
    if (props.onNext) {
      props.onNext();
      tabPanel.current?.scrollTo(0, 0);
    }
  };
  const cancelHandler = () => {
    navigate("/orders");
  };
  return (
    <ContentContainer ref={tabPanel}>
      <Container>
        <Stack sx={{ flexGrow: 1 }}>
          {Boolean(props.title) && (
            <Typography variant="h2" mt={5} color={Colors.SECONDARY}>
              {props.title}
            </Typography>
          )}
          <Content>{props.children}</Content>
          <Stack direction="row" mt={10} pb={5} justifyContent="space-between">
            <Stack direction="row" spacing={3}>
              {props.stepLeftAction}
              <StyledButton
                data-intercom-target="Cancel button"
                onClick={cancelHandler}
                sx={{ color: Colors.DISABLED }}
              >
                Cancel
              </StyledButton>
            </Stack>
            <Stack direction="row" spacing={3}>
              {props.onPrevious && (
                <StyledButton
                  data-intercom-target="Back button"
                  variant="outlined"
                  onClick={props.onPrevious}
                >
                  Back
                </StyledButton>
              )}
              {props.onNext && (
                <StyledButton
                  data-intercom-target="Next button"
                  variant="contained"
                  onClick={nextHandler}
                  color="primary"
                >
                  Next
                </StyledButton>
              )}
              {props.stepRightAction}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </ContentContainer>
  );
};
