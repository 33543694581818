import React from "react";
import Grid from "@mui/material/Grid";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { Select, FormLabel } from "components/ui";
import { actions, selectors } from "store/caseCreate";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { iprPreference, attachPreference } from "lib/dictionaries";
import { SelectIprAttachmentContainer } from "containers/selectIprAttachment";

export const PreferenceSelectors = () => {
  const { attachments, ipr } = useAppSelector(selectors.getPreferencesSelector);
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectors.getAllInfoSelector);
  const changePreferenceHandler = (event: SelectChangeEvent<string>) => {
    dispatch(
      actions.setPreference({
        name: event.target.name as "ipr" | "attachments",
        value: event.target.value,
      })
    );
  };
  return (
    <SelectIprAttachmentContainer
      required={{
        ipr: !order.preEval,
        attachments: !order.preEval,
      }}
      value={{
        ipr: ipr,
        attachments: attachments,
      }}
      helperText={{
        ipr: order.requiredFields.includes("ipr")
          ? "Please fill this field"
          : undefined,
        attachments: order.requiredFields.includes("attachments")
          ? "Please fill this field"
          : undefined,
      }}
      error={{
        ipr: order.requiredFields.includes("ipr"),
        attachments: order.requiredFields.includes("attachments"),
      }}
      changePreferenceHandler={changePreferenceHandler}
    />
  );
};
