import { ChipProps, styled } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import Input from "@mui/material/Input";
import { PLAN_COLORS } from "consts/tpColors";
import Stack from "@mui/material/Stack";
import Box, { BoxProps } from "@mui/material/Box";
import { Button } from "components/ui";
import Chip from "@mui/material/Chip";

export const NoteListDot = styled("span")`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin-inline: 1px;
  background-color: ${PLAN_COLORS.grey.GREY_14};
`;

export const StyledNoteInput = styled(Input)`
  width: 100%;
  margin: 0;
  &::before {
    border-bottom: none;
  }
  &::after {
    border-bottom: none;
  }
  &:hover::before {
    border-bottom: none !important;
  }
  &:focus::before {
    border-bottom: none !important;
  }
`;

export const NotesToContainer = styled(Stack)`
  padding: 0 8px;
  background-color: ${PLAN_COLORS.grey.GREY_11};
  border: 1px solid ${PLAN_COLORS.grey.GREY_12};
  border-radius: 24px;
  margin-right: 8px;
`;

export const NotesHeadContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-content: space-between;
  align-items: center;
  padding-block: 12px;
  padding-inline: 93px;
  background-color: ${PLAN_COLORS.orange.ORANGE_1}14;
`;
export const StyledNotesButton = styled(Button)`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    border-radius: 4px;
    box-shadow: none;
    
    font-weight: unset;
    text-transform: unset;
    padding: 0 16px;
    margin-right: 8px;
    background-color: ${PLAN_COLORS.orange.ORANGE_1}; 
    &:hover {
      background-color: ${PLAN_COLORS.orange.light}
    },
`;

export const StyledCloseButton = styled(Button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 4px;
  box-shadow: none;

  border: 1px solid ${PLAN_COLORS.grey.GREY_7};
  width: 24px;
  min-width: 24px;
  font-size: 14px;
`;

export const NotesToSelector = styled(Button)``;

interface StyledNotesProps extends BoxProps {
  isEdit?: boolean;
  isSelected?: boolean;
}
export const StyledNotesItem = muiStyled(Box)<StyledNotesProps>(
  ({ isEdit, isSelected }) => ({
    padding: 16,
    cursor: "pointer",
    borderBottom: `1px solid ${PLAN_COLORS.grey.GREY_12}`,
    "&:last-of-type": {
      borderBottom: "none",
    },
    ...(isEdit && {
      "&:hover": {
        backgroundColor: `${PLAN_COLORS.orange.ORANGE_1}1a`,
      },
    }),
    ...(isSelected && {
      border: `1px solid ${PLAN_COLORS.orange.ORANGE_1}`,
      backgroundColor: `${PLAN_COLORS.orange.ORANGE_1}1a`,
      "&:last-of-type": {
        borderBottom: `1px solid ${PLAN_COLORS.orange.ORANGE_1}`,
      },
    }),
  })
);

interface StyledChipProps extends ChipProps {
  selected?: boolean;
}
export const NoteFilterChip = muiStyled(Chip)<StyledChipProps>(
  ({ selected }) => ({
    cursor: "pointer",
    height: 24,
    padding: "0 16px",
    color: PLAN_COLORS.grey.GREY_9,
    margin: "0 4px",
    "&:first-of-type": {
      marginLeft: 8,
    },
    ...(selected && {
      backgroundColor: PLAN_COLORS.orange.ORANGE_1,
      borderColor: PLAN_COLORS.orange.ORANGE_1,
      color: "white",
      "&:focus": {
        backgroundColor: `${PLAN_COLORS.orange.ORANGE_1}ad !important`,
      },
      "&:hover": {
        backgroundColor: `${PLAN_COLORS.orange.ORANGE_1}ad !important`,
      },
    }),
  })
);
