import api from "./baseService";

class OrderTaskService {
  private baseUrl: string = "order-tasks";
  public async moveTaskToDone(taskId: number | string): Promise<void> {
    await api.put(`${this.baseUrl}/done`, { orderTaskId: taskId });
  }
  public async moveTaskToCancel(
    taskId: number | string,
    description: string
  ): Promise<void> {
    await api.put(`${this.baseUrl}/cancel`, {
      orderTaskId: taskId,
      description,
    });
  }
}

export const orderTaskService = new OrderTaskService();
