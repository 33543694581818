import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { Button, FormLabel, Select } from "components/ui";
import { Colors } from "consts/colors";
import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
} from "./Elements";
import { fileTypes } from "lib/dictionaries";
import { FileType } from "lib/enum";
import { useAppDispatch } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions } from "store/patientDetail";

import { UploadArea } from "./UploadArea";

export const UploadFilesButton = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filesType, setFilesType] = useState<string>("");
  const [files, setFiles] = useState<File[]>([]);
  const fileTypesData = Object.entries(fileTypes)
    .filter(([key]) => key !== FileType.INVALID)
    .map(([key, value]) => ({
      label: value,
      value: key,
    }));
  const openHandler = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };
  const changeFileTypesHandler = (event: SelectChangeEvent<string>) => {
    setFilesType(event.target.value);
  };
  const uploadHandler = () => {
    setLoading(true);
    dispatch(
      actions.uploadCaseFiles({ files, filesType: filesType as FileType })
    )
      .unwrap()
      .then(() => {
        setFilesType("");
        setFiles([]);
        setOpen(false);
      })
      .catch((e) => showErrorNotice(e.message))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open}>
        <StyledDialogTitle>
          <IconButton size="small" onClick={closeHandler}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </StyledDialogTitle>
        <Typography mx={5} variant="h2" color={Colors.PRIMARY}>
          File Upload
        </Typography>
        <StyledDialogContent>
          <Stack spacing={2} mb={8} mt={12}>
            <UploadArea onChange={setFiles} files={files} />
            <FormLabel label="Select file type">
              <Select
                sx={{ maxWidth: 290 }}
                value={filesType}
                fullWidth
                data={fileTypesData}
                placeholder="Select one"
                onChange={changeFileTypesHandler}
              />
            </FormLabel>
          </Stack>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button
            disabled={!filesType || !files.length}
            variant="contained"
            fatButton
            loading={loading}
            onClick={uploadHandler}
          >
            Upload
          </Button>
        </StyledDialogActions>
      </Dialog>
      <Button
        data-intercom-target="Upload files button"
        onClick={openHandler}
        variant="contained"
        startIcon={<AddIcon />}
      >
        Add File
      </Button>
    </>
  );
};
