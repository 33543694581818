export const iprValues: any = {
  "1": "Allowed",
  "2": "Minimum",
  "0": "No IPR",
};
export const attachValue: any = {
  "1": "Allowed",
  "2": "Minimum",
  "0": "No Attachments",
};
export const wearScheduleValues: any = {
  "1": "14 days",
  "2": "10 days",
  "7": "7 days",
};
