import React from "react";
import { useSearchParams } from "react-router-dom";
import { SearchInput } from "components/common";
import { SearchTab } from "./Elements";
import { getUrlSearchParams } from "utils";
import { SearchProps } from "./types";

interface Props {
  searchProps?: SearchProps;
}
export const ContainerSearchInput: React.VFC<Props> = ({ searchProps }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isDisableOnSearch = () => {
    return (
      typeof searchProps !== "boolean" && searchProps?.disableOnSearch === true
    );
  };
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisableOnSearch()) {
      return;
    }
    setSearchParams({
      ...getUrlSearchParams(searchParams),
      "search-for": e.target.value,
    });
  };

  const handleEnter = (e: any) => {
    if (
      e?.code === "Enter" &&
      typeof searchProps !== "boolean" &&
      searchProps?.handleEnter
    ) {
      searchProps?.handleEnter(e.target.value);
    }
  };

  if (!searchParams) {
    return null;
  }
  return (
    <SearchTab sx={{ marginBottom: 0.5, marginTop: 1 }}>
      <SearchInput
        sx={{ maxHeight: 29 }}
        style={{ container: { backgroundColor: "white", borderRadius: 4 } }}
        placeholder={
          typeof searchProps !== "boolean"
            ? searchProps?.placeholder ?? "Search..."
            : "Search..."
        }
        targetLabel="Search field"
        value={
          isDisableOnSearch()
            ? undefined
            : searchParams?.get("search-for") ?? undefined
        }
        onChange={onSearch}
        onKeyDown={handleEnter}
      />
    </SearchTab>
  );
};
