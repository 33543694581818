import { Grid, Skeleton } from "@mui/material";
import { useAppSelector } from "hooks/redux";
import { toLocalCurrency } from "utils";
import {
  SubTotal,
  Total,
  TotalContainer,
  TotalLine,
  Wrapper,
} from "./Elements";

export const AmountsInvoice = () => {
  const invoice = useAppSelector((state) => state.invoicesCard.invoice);
  const loading = useAppSelector((state) => state.invoicesCard.isLoading);
  return (
    <Wrapper>
      <TotalContainer>
        <Grid container>
          <SubTotal item xs={3}>Subtotal</SubTotal>
          <SubTotal item xs={3}>
            {loading ? <Skeleton /> : invoice?.amount  ? toLocalCurrency(invoice.amount) : "-"}
          </SubTotal>
        </Grid>
        <TotalLine container>
          <Total item xs={3}>Discount</Total>
          <Total item xs={3}>
            {loading ? (
              <Skeleton />
            ) : invoice?.discountAmount ? (
              toLocalCurrency(invoice.discountAmount)
            ) : (
              "-"
            )}
          </Total>
        </TotalLine>
        <TotalLine container>
          <Total item xs={3}>Shipping and handling</Total>
          <Total item xs={3}>
            {loading ? (
              <Skeleton />
            ) : invoice?.shippingPrice ? (
              toLocalCurrency(invoice.shippingPrice)
            ) : (
              "-"
            )}
          </Total>
        </TotalLine>
        <TotalLine container>
          <Total item xs={3}>Invoice total</Total>
          <Total item xs={3}>
            {loading ? (
              <Skeleton />
            ) : invoice?.totalAmount ? (
              toLocalCurrency(invoice.totalAmount)
            ) : (
              "-"
            )}
          </Total>
        </TotalLine>
      </TotalContainer>
    </Wrapper>
  );
};
