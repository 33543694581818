import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { EventDashboardOperation } from "lib/enum";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/patientDetail";
import Link from "@mui/material/Link";

interface Props {
  text: string;
  type: EventDashboardOperation;
}
export const getEventHead = (type: EventDashboardOperation) => {
  switch (type) {
    case EventDashboardOperation.SEND_IMPRESSIONS:
      return (
        <Stack direction="row" spacing={1.5}>
          <Typography fontWeight={500} fontSize={14}>
            Send impressions
          </Typography>
        </Stack>
      );
    case EventDashboardOperation.VERIFICATION_KEY:
      return (
        <Stack direction="row" spacing={1.5}>
          <Typography fontWeight={500} fontSize={14}>
            Verification
          </Typography>
        </Stack>
      );
  }
};
export const EventItem: React.VFC<Props> = ({ text, type }) => {
  const { currentOrder } = useAppSelector(selectors.currentOrder);
  const dispatch = useAppDispatch();
  const clickHandler = () => {
    dispatch(actions.setOpenScheduling(true));
  };

  if (type === EventDashboardOperation.START_WEARING) {
    return (
      <Stack spacing={1.5}>
        <Typography fontWeight={500} fontSize={14}>
          {`${
            currentOrder?.productionType ? "Aligner" : "Retainer"
          } were delivered`}
        </Typography>
        <Link
          data-intercom-target="Start wearing link"
          sx={{ cursor: "pointer" }}
          fontWeight={400}
          underline="none"
          variant="body2"
          onClick={clickHandler}
        >
          Have you fit these aligners?
        </Link>
      </Stack>
    );
  }
  return (
    <Stack spacing={1.5}>
      {getEventHead(type)}
      <Typography variant="body2">{text}</Typography>
    </Stack>
  );
};
