import { RequestRemakeState } from "./types";

export const initState: RequestRemakeState = {
  stepNum: 0,
  open: false,
  notes: "",
  remakeType: "",
  partialReplacement: false,
  needToSendFiles: false,
  impressions: 0,
  clinicalPhotos: [],
  commonFiles: [],
  shippingCreated: false,
};
