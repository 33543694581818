import { IShippingInfoForFilterViewDto, IExtShippingInfoDto } from "lib/dto";
import {
  IBaseResponse,
  IBaseResponseList,
  IShippingsRatingResult,
  IPrintShippingParams,
  IPrintShippingResponse,
} from "lib/model";
import { getUrlSearchParamsString } from "utils/urlHelpers";
import api from "./baseService";

class ShippingService {
  private baseUrl: string = "shipping-orders";
  public async fetch(
    filter: any
  ): Promise<IBaseResponseList<IShippingInfoForFilterViewDto>> {
    const { data } = await api.get<
      IBaseResponse<IBaseResponseList<IShippingInfoForFilterViewDto>>
    >(`${this.baseUrl}/filter?${getUrlSearchParamsString(filter)}`);
    return data.result;
  }
  public async getShippingsRate(): Promise<IShippingsRatingResult> {
    const { data } = await api.get<IBaseResponse<IShippingsRatingResult>>(
      `${this.baseUrl}/rate`
    );
    return data.result;
  }
  public async getShippingLabel(
    params: IPrintShippingParams
  ): Promise<IPrintShippingResponse> {
    const { data } = await api.post<IBaseResponse<IPrintShippingResponse>>(
      this.baseUrl,
      params
    );
    return data.result;
  }
  public async getShippingLabelById(
    infoId: number
  ): Promise<IPrintShippingResponse> {
    const { data } = await api.get<IBaseResponse<IPrintShippingResponse>>(
      `${this.baseUrl}/${infoId}/shipping-label`
    );
    return data.result;
  }

  public async getShippingsByOrderId(
    orderId: number | string
  ): Promise<IExtShippingInfoDto[]> {
    const { data } = await api.get<IBaseResponse<IExtShippingInfoDto[]>>(
      `orders/${orderId}/${this.baseUrl}`
    );
    return data.result;
  }
}

export const shippingService = new ShippingService();
