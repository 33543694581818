import React from "react";
import { useNavigate } from "react-router-dom";
import { CaseTabAction } from "lib/enum";
import Typography from "@mui/material/Typography";

import { ICaseTabDto } from "lib/dto";
import { Colors } from "consts/colors";
import { Button } from "components/ui";
import { CalendlyFrame } from "components/common";
import { styled } from "@mui/material";

interface Props {
  caseInfo: ICaseTabDto;
}
const StyledButton = styled(Button)`
  position: static;
`;
export const StatusColumn: React.VFC<Props> = ({ caseInfo }) => {
  const navigate = useNavigate();
  const createAlignerHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/orders/aligners/new/${caseInfo.id}`);
  };
  const completeOrderHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/orders/case/continuation/${caseInfo.id}`);
  };
  const completeContinuationOrderHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/orders/continuation/complete/${caseInfo.id}`);
  };
  const createContinuationOrderHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/orders/continuation/${caseInfo.id}`);
  };
  switch (caseInfo.caseTabAction) {
    case CaseTabAction.IN_TREATMENT:
      return (
        <Typography
          fontWeight={500}
          textAlign="center"
          variant="body2"
          color={Colors.SECONDARY}
        >
          In Treatment
        </Typography>
      );
    case CaseTabAction.PLACE_A_CONTINUATION_ORDER:
      return (
        <StyledButton
          variant="contained"
          fullWidth
          onClick={createContinuationOrderHandler}
        >
          Order Next Aligners
        </StyledButton>
      );
    case CaseTabAction.SEND_IMPRESSIONS:
      return (
        <StyledButton variant="contained" fullWidth>
          Send impressions
        </StyledButton>
      );
    case CaseTabAction.NOTHING_REQUIRED:
      return (
        <Typography
          fontWeight={500}
          textAlign="center"
          variant="body2"
          color={Colors.SECONDARY}
        >
          Nothing required
        </Typography>
      );
    case CaseTabAction.APPROVE_TREATMENT_PLAN:
      return (
        <StyledButton variant="contained" fullWidth>
          Approve treatment plan
        </StyledButton>
      );
    case CaseTabAction.SUBMIT_PHOTO_EVALUATIONS:
      return (
        <StyledButton
          variant="contained"
          fullWidth
          onClick={createAlignerHandler}
        >
          Order aligners
        </StyledButton>
      );
    case CaseTabAction.SUBMIT_TO_LAB:
    case CaseTabAction.COMPLETE_ORDER:
      return (
        <StyledButton
          variant="contained"
          fullWidth
          onClick={completeOrderHandler}
        >
          Submit Draft
        </StyledButton>
      );
    case CaseTabAction.COMPLETE_A_CONTINUATION_ORDER:
      return (
        <StyledButton
          variant="contained"
          fullWidth
          onClick={completeContinuationOrderHandler}
        >
          Submit Draft
        </StyledButton>
      );
    case CaseTabAction.SCHEDULE_CONSULT:
      return (
        <CalendlyFrame
          patient={`${caseInfo.patientFirstName} ${caseInfo.patientLastName}`}
          discussTP
          caseId={caseInfo.id}
        >
          <StyledButton variant="contained" fullWidth>
            Schedule consult
          </StyledButton>
        </CalendlyFrame>
      );
    default:
      return null;
  }
};
