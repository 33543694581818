import { createReducer } from "@reduxjs/toolkit";
import { InvoicesCardState } from "./types";
import { initState } from "./state";
import { getInvoiceInfo } from "./actions";

export const reducer = createReducer<InvoicesCardState>(
  initState,
  (builder) => {
    builder
      .addCase(getInvoiceInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvoiceInfo.fulfilled, (state, action) => {
        return {
          ...state,
          invoice: action.payload.invoice,
          order: action.payload.order,
          payment: action.payload.payment,
          isLoading: false,
        };
      })
      .addCase(getInvoiceInfo.rejected, (state) => {
        state.isLoading = false;
      });
  }
);
