import React, { useMemo } from "react";
import { useMatch, useResolvedPath, Link } from "react-router-dom";
import { ImgContainer, StyledNavLinkButton } from "./Elements";

interface Props {
  to: string;
  icon: React.ReactElement;
  title: string;
  isBlank?: boolean;
}

export const NavLink: React.VFC<Props> = (props) => {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: props.to === "/" });
  const isExternal = useMemo(() => props.to.startsWith("http"), [props.to]);

  const Content = useMemo(() => {
    return (
      <StyledNavLinkButton
        variant={match ? "contained" : "text"}
        data-intercom-target={`${props.title} menu item`}
      >
        <ImgContainer>
          {React.cloneElement(props.icon, {
            sx: {
              fill: "none",
            },
            fontSize: "small",
          })}
        </ImgContainer>
        {props.title}
      </StyledNavLinkButton>
    )
  }, [match, props.icon, props.title]);

  return (
    <>
      {isExternal ? (
        <a href={props.to} target="_blank" rel="noopener noreferrer">
          {Content}
        </a>
      ) : (
        <Link
          to={props.to}
          target={props.isBlank ? "_blank" : undefined}
        >
          {Content}
        </Link>
      )}
    </>
  );
};
