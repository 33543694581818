import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";

import { useAppSelector } from "hooks/redux";
import { Footer } from "./components/Footer";
import { SecondStep } from "./components/SecondStep";
import { FirstStep } from "./components/FirstStep";
import { ShippingButton } from "./components/ShippingButton";

export const RequestRemakeContainer: React.FC = () => {
  const open = useAppSelector((state) => state.requestRemake.open);
  const stepNum = useAppSelector((state) => state.requestRemake.stepNum);

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogTitle color="primary">Request Remake</DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <Box px={3}>{stepNum ? <SecondStep /> : <FirstStep />}</Box>
        <ShippingButton />
      </DialogContent>
      <DialogActions>
        <Footer />
      </DialogActions>
    </Dialog>
  );
};
