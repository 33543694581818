import api from "./baseService";
import { IBaseResponse, ICreateMessage } from "lib/model";
import { IBaseDto, IFileDto, IGroupedUnreadMessageDto } from "lib/dto";
import { FileType } from "lib/enum";

class OrderMessageService {
  private baseUrl: string = "order-message";

  public async readMessage(unreadMessageIds: number[]): Promise<void> {
    await api.delete(`unread-message`, {
      data: {
        unreadMessageIds,
      },
    });
  }

  public async getUnreadMessagesCount(): Promise<IGroupedUnreadMessageDto[]> {
    const { data } = await api.get<IBaseResponse<IGroupedUnreadMessageDto[]>>(
      `unread-message/count`
    );
    return data.result;
  }

  public async sendMessage(params: ICreateMessage): Promise<IBaseDto> {
    const { data } = await api.post<IBaseResponse<IBaseDto>>(
      this.baseUrl,
      params
    );
    return data.result;
  }

  public async getFilesByMessageId(
    messageId: number | string
  ): Promise<IFileDto[]> {
    const { data } = await api.get<IBaseResponse<IFileDto[]>>(
      `${this.baseUrl}/${messageId}/files?fileType=${FileType.COMMON}`
    );
    return data.result;
  }

  public async uploadFilesMessage(
    messageId: number,
    files: File[]
  ): Promise<void> {
    const formData = new FormData();
    files.forEach((file: File) => {
      formData.append(file.name, file);
    });
    await api.post(
      `${this.baseUrl}/${messageId}/files?fileType=${FileType.COMMON}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  public async removeOrderMessageFile(fileIds: number[]): Promise<void> {
    await api.delete(`${this.baseUrl}/files`, {
      data: {
        fileIds,
      },
    });
  }
}

export const orderMessageService = new OrderMessageService();
