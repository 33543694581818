import { MainContainer } from "containers";
import { StlViewer } from "react-stl-viewer";
import React from "react";
import { useTheme } from "@mui/material";

interface Props {
  source: string;
}
export const ModelPreview: React.VFC<Props> = ({ source }) => {
  const theme = useTheme();
  return (
    <MainContainer>
      <StlViewer
        url={source}
        style={{
          width: "calc(100vw - 290px)",
          height: "calc(100vh - 100px)",
        }}
        modelProps={{
          color: theme.palette.primary.main,
          scale: 1.8,
          positionX: 18,
        }}
        orbitControls
      />
    </MainContainer>
  );
};
