import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAppSelector } from "hooks/redux";
import { Colors } from "consts/colors";
import { selectors } from "store/patientDetail";

import { OrdersItem } from "./OrdersItem";
import { PaperContainer } from "../Elements";

export const Orders = () => {
  const { prevOrders, isLoading } = useAppSelector(selectors.previousOrders);
  if (!prevOrders.length || isLoading) {
    return null;
  }
  return (
    <PaperContainer sx={{ padding: 2 }} data-intercom-target="Previous orders">
      <Stack spacing={2}>
        <Typography
          sx={{ fontWeight: 500 }}
          variant="body1"
          color={Colors.PRIMARY}
        >
          Previous orders
        </Typography>
        <Stack spacing={1.25}>
          {prevOrders.map((order) => (
            <OrdersItem key={order.id} order={order} />
          ))}
        </Stack>
      </Stack>
    </PaperContainer>
  );
};
