import { Stack, Box, Typography } from "@mui/material";
import { FatButton } from "components/ui";
import { Colors } from "consts/colors";
import { FC } from "react";
import { actions } from "store/currentUser";

interface Props {
  upper: boolean;
  lower: boolean;
  onClickUpper: () => void;
  onClickLower: () => void;
}
export const ArchesStepContainer: FC<Props> = ({
  upper,
  lower,
  onClickUpper,
  onClickLower,
}) => {
  return (
    <Stack spacing={3} direction="row" sx={{ mt: 3 }}>
      <FatButton
        data-intercom-target="Upper arch button"
        onClick={onClickUpper}
        variant={upper ? "contained" : "outlined"}
      >
        Upper Arches
      </FatButton>
      <FatButton
        data-intercom-target="Lower arch button"
        onClick={onClickLower}
        variant={lower ? "contained" : "outlined"}
      >
        Lower Arches
      </FatButton>
    </Stack>
  );
};
