import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material";

export const RadioLabel = styled(FormControlLabel)`
  color: var(--text-secondary-color);
`;

interface Props {
  options: {
    label: string;
    value: string;
  }[];
  value?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
}
export const RadioSelect: React.VFC<Props> = ({ options, onChange, value }) => {
  return (
    <RadioGroup value={value} row onChange={onChange}>
      {options.map(({ value, label }) => (
        <RadioLabel
          key={`${value}_${label}`}
          componentsProps={{ typography: { fontSize: 12 } }}
          value={value}
          control={<Radio />}
          label={label}
        />
      ))}
    </RadioGroup>
  );
};
