import api from "./baseService";
import { IOfficeSettingsDto, IOfficeSetting, IFileDto } from "lib/dto";
import { IBaseResponse } from "../lib/model";

class OfficeSettingsService {
  private baseUrl: string = "office-settings";

  public async getOfficeSettingsByOfficeId(
    officeId: string | number
  ): Promise<IOfficeSettingsDto> {
    const { data } = await api.get<IBaseResponse<IOfficeSettingsDto>>(
      `${this.baseUrl}/offices/${officeId}`
    );
    return data.result;
  }

  public async createAccountSetting(
    officeId: number | string,
    officeSettings: IOfficeSetting[]
  ): Promise<void> {
    await api.post(this.baseUrl, {
      officeId,
      officeSettings,
    });
  }

  public async addOfficeImages(officeId: number, file: File): Promise<void> {
    const formData = new FormData();
    formData.append(file.name, file);
    await api.post(`${this.baseUrl}/offices/${officeId}/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  public async getOfficeImageLogo(officeId: number): Promise<IFileDto[]> {
    const { data } = await api.get<IBaseResponse<IFileDto[]>>(
      `${this.baseUrl}/offices/${officeId}/files`
    );
    return data.result;
  }

  public async deleteOfficeImageLogo(
    officeId: number,
    fileIds: number[]
  ): Promise<void> {
    await api.delete(`${this.baseUrl}/offices/files`, { data: { fileIds } });
  }
}

export const officeSettingsService = new OfficeSettingsService();
