import axios, { Axios, AxiosRequestConfig, AxiosResponse } from "axios";
import { AUTH_TOKEN_NAME } from "./apiConsts";
import { responseInterceptor } from "./responseInterceptor";

const $api: Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
  withCredentials: true,
});

$api.interceptors.request.use(function (config) {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      AUTH_TOKEN_NAME
    )}`;
  }
  return config;
});
//TODO: добавить нормальную обработку ошибок

$api.interceptors.response.use(responseInterceptor);

const api = {
  get: async function <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    try {
      return await $api.get<T, R, D>(url, config);
    } catch (e: any) {
      if (e?.response?.data?.desc) {
        throw new Error(e?.response?.data?.desc);
      }
      throw new Error(e.message);
    }
  },
  request: $api.request,
  post: async function <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    try {
      return await $api.post<T, R, D>(url, data, config);
    } catch (e: any) {
      if (e?.response?.data?.desc) {
        throw new Error(e?.response?.data?.desc);
      }
      throw new Error(e.message);
    }
  },
  put: async function <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    try {
      return await $api.put<T, R, D>(url, data, config);
    } catch (e: any) {
      if (e?.response?.data?.desc) {
        throw new Error(e?.response?.data?.desc);
      }
      throw new Error(e.message);
    }
  },
  delete: async function <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    try {
      return await $api.delete<T, R, D>(url, config);
    } catch (e: any) {
      if (e?.response?.data?.desc) {
        throw new Error(e?.response?.data?.desc);
      }
      throw new Error(e.message);
    }
  },
};
export default api;
