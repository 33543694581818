import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { PLAN_COLORS } from "consts/tpColors";

export const InstructionsText = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  color: ${PLAN_COLORS.grey.GREY_8};
  background-color: ${PLAN_COLORS.grey.GREY_11};
  padding: 4px;
  font-size: 10px;
  min-height: 20px;
  border-radius: 4px;
  border: 1px solid ${PLAN_COLORS.grey.GREY_12};
  box-sizing: border-box;
`;

export const ExtraInstructionContainer = styled("span")`
  position: relative;
  cursor: pointer;

  & + .visible {
    display: none;
  }
  &:hover + .visible {
    display: flex;
  }
`;

export const ExtraInstructionOrder = styled(Box)`
  position: absolute;
  background-color: #dcedff;
  color: #266fbe;
  white-space: nowrap;
  font-size: 14px;
  padding-inline: 8px;
  padding-block: 4px;
  border-radius: 16px;
  top: calc((50% + 20px) * -1);
  flex-direction: column;
`;
