import React, { useEffect, useMemo, useRef, useState } from "react";
import { PLAN_COLORS } from "consts/tpColors";
import { Colors } from "consts/colors";
import { generateArrow } from "./helpers";

interface ToothProps {
  numberPath: string;
  toothPath: string;
  toothId: string;
  className?: string;
  angle?: number | null;
}
const ArchTooth: React.FC<ToothProps> = (props) => {
  const pathRef = useRef<SVGPathElement | null>(null);
  const [pathCenter, setPathCenter] = useState<{
    cx: number;
    cy: number;
  } | null>(null);
  const styleString = useMemo(() => {
    if (typeof props.angle === "number" && pathCenter) {
      const cx = pathCenter.cx;
      const cy = pathCenter.cy;
      return {
        transform: `
					translate(${cx}px, ${cy}px)
					rotateX(180deg)
					rotateY(180deg)
					rotate(${props.angle}deg)
					translate(-${cx + 3}px,  -${cy}px)
				`,
      };
    }
  }, [props.angle, pathCenter]);

  useEffect(() => {
    if (pathRef.current) {
      const { x, y, width, height } = pathRef.current.getBBox();
      setPathCenter({
        cx: x + width / 2,
        cy: y + height / 2,
      });
    }
  }, []);
  return (
    <g>
      <path
        ref={pathRef}
        data-id={Number(props.toothId) + 1}
        className={props.className}
        d={props.toothPath}
        fill={PLAN_COLORS.grey.GREY_12}
      />
      <text
        textAnchor="middle"
        style={{
          fontSize: 10,
          transform: `translate(${props.numberPath})`,
        }}
        fill={Colors.LIGHT_GREY}
      >
        {Number(props.toothId) + 1}
      </text>
      {!!pathCenter && typeof props.angle === "number" && (
        <path
          style={styleString}
          d={generateArrow(pathCenter.cx, pathCenter.cy)}
          fill={Colors.PRIMARY}
        />
      )}
    </g>
  );
};
export default ArchTooth;
