import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const StyledDialogTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px 0 24px;
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-inline: 180px;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding-inline: 180px;
  padding-block: 24px 34px;
`;

export const StyledUploadContainer = styled(Stack)`
  height: 190px;
  width: 190px;
`;

export const StyledTextSeparator = styled(Typography)`
  font-size: 10.5px;
  color: var(--text-primary-color);

  &::after,
  &::before {
    content: "";
    display: inline-block;
    height: 1px;
    width: 26px;
    background-color: var(--text-primary-color);
    margin-inline: 8px;
    vertical-align: middle;
  }
`;
