import React, { useEffect, useState } from "react";
import { FileCard } from "components/common";
import { IExtFileDto } from "lib/dto";
import { getFilesType } from "utils";
import { useAppDispatch } from "hooks/redux";
import { actions } from "store/patientDetail";
import { fileService } from "services";
import { fileDownload } from "utils/fileHelpers";

interface Props {
  file: IExtFileDto & { src?: Blob | string };
  messageId: number;
}
export const MessagesFileCard: React.VFC<Props> = ({ file, messageId }) => {
  const [filePath, setFilePath] = useState<string | undefined>();
  const fileType = getFilesType(file);
  const dispatch = useAppDispatch();
  const fileDeleteHandler = () => {
    dispatch(actions.removeMessageFile({ fileId: file.id, messageId }));
  };
  const downloadFile = () => {
    if (filePath) {
      fileDownload(file.name, filePath);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    if (!file?.src) {
      fileService
        .getFile(file.id, file.contentType, controller.signal)
        .then(setFilePath)
        .catch(() => {});
    }
    return () => {
      controller.abort();
    };
  }, [file.id, file?.src, file.contentType, fileType]);
  const path = {
    id: file.id,
    contentType: file.contentType,
    created: file.created,
    name: file.name,
    originalFileId: file.originalFileId,
    originalFileName: file.originalFileName,
    notDeletable: false,
    src: filePath,
  };
  return (
    <div onClick={downloadFile}>
      <FileCard
        onDelete={fileDeleteHandler}
        file={path}
        width={45}
        height={45}
      />
    </div>
  );
};
