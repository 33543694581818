import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { PatientDetailsState } from "./types";
import {
  getCaseCurrentStatus,
  getStatusForAligner,
  getStatusForRetainer,
} from "./statusGetters";
import {
  IEventDashboardDto,
  IExtOrderForCaseViewDto,
  IExtOrderWithItemsAndTasksDto,
} from "lib/dto";
import {
  EntityType,
  EventDashboardOperation,
  FileType,
  OrderRemakeType,
  OrderStatuses,
  OrderTaskStatuses,
  ShippingInfoStatus,
  TaskType,
} from "lib/enum";
import { getRequiredActions } from "./requiredActionGetter";
import { generateUniqueNumberID } from "utils";

const selectSelf = (state: RootState) => state.patientCard;

export const allInfoSelector = createSelector(
  selectSelf,
  (state: PatientDetailsState) => state
);
export const currentOrder = createSelector(
  selectSelf,
  (state: PatientDetailsState) => ({
    currentOrder: state.currentOrder,
    isLoading: state.isLoading,
  })
);

export const remakedOrder = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    return Boolean(
      state.currentOrder?.exceptions.find(
        ({ exceptionType: { name } }) =>
          name === OrderRemakeType.REMAKE_ORDER ||
          name === OrderRemakeType.LOST_PACKAGE
      )
    );
  }
);

export const dmInfo = createSelector(
  selectSelf,
  (state: PatientDetailsState) => state.dmFields
);

export const filesSelector = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    const originalFilesId = state.files
      .filter(({ originalFileId }) => !!originalFileId)
      .map(({ originalFileId }) => originalFileId);
    return {
      files: state.files.filter(({ id }) => !originalFilesId.includes(id)),
    };
  }
);

export const preEvaluationFiles = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    return state.files
      .filter(({ fileType }) => fileType === FileType.PRE_EVALUATION)
      .map((file) => ({
        ...file,
        notDeletable: true,
      }));
  }
);
export const unreadMessageCount = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    return state.orderMessages.reduce((previousValue, currentValue) => {
      if (currentValue.unreadMessageId) {
        previousValue++;
      }
      return previousValue;
    }, 0);
  }
);
export const tpVideoFiles = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    return state.files
      .filter(({ fileType }) => fileType === FileType.TP_VIDEO)
      .map((file) => ({
        ...file,
        notDeletable: true,
      }));
  }
);
export const previousOrders = createSelector(
  selectSelf,
  (state: PatientDetailsState) => ({
    prevOrders: state.previousOrders,
    isLoading: state.isLoading,
  })
);
export const isLoading = createSelector(
  selectSelf,
  (state: PatientDetailsState) => state.isLoading
);
export const errors = createSelector(
  selectSelf,
  (state: PatientDetailsState) => state.errors
);
export const shippingsSelector = createSelector(
  selectSelf,
  (state: PatientDetailsState) => state.currentOrderShippings
);
export const caseInfoSelector = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    return {
      caseInfo: state.caseInfo,
      productType: state.productType,
      priceList: state.priceList,
      isLoading: state.isLoading,
    };
  }
);

export const tpApproved = createSelector(selectSelf, (state) => {
  return !!state.caseInfo?.treatPlanApprovedDate;
});

export const tpApproveTask = createSelector(selectSelf, (state) => {
  return state.orderTasks.find(
    ({ task, status }) =>
      task.type === TaskType.APPROVE_TREAT_PLAN &&
      status === OrderTaskStatuses.IN_PROGRESS
  );
});

export const showEstShipDate = createSelector(selectSelf, (state) => {
  // if false then retainer
  if (state.currentOrder?.productionType === false) {
    return true;
  }

  const canShow = state.currentOrder?.orderTasks.find(
    ({ task, status }) =>
      (task.type === TaskType.ESTIMATE_TREAT_PLAN ||
        task.type === TaskType.APPROVE_TO_ALIGNER_PRODUCTION ||
        task.type === TaskType.APPROVE_TREAT_PLAN) &&
      (status === OrderTaskStatuses.DONE ||
        status === OrderTaskStatuses.SKIPPED)
  );
  return Boolean(canShow);
});

export const needDiscussTp = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    const orderTask = state.currentOrder?.orderTasks.find(
      ({ task }: any) => task.type === TaskType.ESTIMATE_TREAT_PLAN
    );
    let discussTP = false;
    if (orderTask) {
      const currentDate = new Date();
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      const nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 7);
      const doneDate = new Date(orderTask.doneDate || "");
      const deadLineDate = new Date(orderTask.deadlineDate || "");
      discussTP =
        (Number(doneDate) - Number(lastMonth) > -1 &&
          Number(currentDate) - Number(doneDate) > -1) ||
        (Number(nextWeek) - Number(deadLineDate) > -1 &&
          Number(deadLineDate) - Number(currentDate) > -1);
    }
    return discussTP;
  }
);

export const caseNoticeSelector = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    const caseEvents: IEventDashboardDto[] = [...state.caseEvents];
    if (
      process.env.REACT_APP_SHOW_START_WEARING &&
      !state.caseInfo?.needPreEvaluation &&
      (state.currentOrder?.status === OrderStatuses.SUBMITTED_TO_DOCTOR ||
        state.currentOrder?.status === OrderStatuses.RECEIVED_BY_DOCTOR) &&
      state.currentOrderShippings.some(
        ({ status }) => status === ShippingInfoStatus.DELIVERED
      ) &&
      !state.currentOrder.wearingStartDate
    ) {
      caseEvents.push({
        id: generateUniqueNumberID(),
        caseId: state.caseInfo?.id!,
        done: false,
        entity: EntityType.CASE,
        operation: EventDashboardOperation.START_WEARING,
        message: "",
        created: "",
        officeId: state.caseInfo?.office.id!,
        pinned: false,
        showFrom: "",
        targetId: state.caseInfo?.id!,
        modified: "",
      });
    }
    return {
      caseEvents,
      requiredActions: getRequiredActions(state),
    };
  }
);
export const orderMessagesSelector = createSelector(
  selectSelf,
  (state: PatientDetailsState) => state.orderMessages
);
const getMinMaxSteps = (
  order: IExtOrderWithItemsAndTasksDto | IExtOrderForCaseViewDto
) => {
  if (
    order?.lowerFirstProducedStep === null &&
    order?.lowerLastProducedStep === null &&
    order?.upperFirstProducedStep === null &&
    order?.upperLastProducedStep === null
  ) {
    return null;
  }

  /**
   * Next logic is used to determine start steps
   * if lower arch not produced  - should take upper arch
   * if upper arch not produced  - should take lower arch
   * if both arches - should take min value
   */
  // #region
  let startSteps = 0;
  if (order?.lowerFirstProducedStep === 0) {
    startSteps = order?.upperFirstProducedStep || 0;
  } else if (order?.upperFirstProducedStep === 0) {
    startSteps = order?.lowerFirstProducedStep || 0;
  } else {
    startSteps = Math.min(
      order?.lowerFirstProducedStep || 0,
      order?.upperFirstProducedStep || 0
    );
  }
  // #endregion

  const endSteps = Math.max(
    order?.lowerLastProducedStep || 0,
    order?.upperLastProducedStep || 0
  );

  return {
    startSteps,
    endSteps,
  };
};
export const caseStepInfo = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    const shippingToDoctor = state.currentOrderShippings.find(
      ({ toLab }) => !toLab
    );
    if (shippingToDoctor) {
      return getMinMaxSteps(state.currentOrder!);
    }
    if (state.previousOrders.length > 0) {
      return getMinMaxSteps(state.previousOrders[0]);
    }
    return null;
  }
);
export const getCurrentOrderSteps = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    const estSteps = Math.max(
      state.caseInfo?.lowerEstimatedSteps || 0,
      state.caseInfo?.upperEstimatedSteps || 0
    );
    const startSteps = Math.min(
      state.currentOrderProducedSteps?.lowerAssignedSteps.from || 0,
      state.currentOrderProducedSteps?.upperAssignedSteps.from || 0
    );
    const endSteps = Math.max(
      state.currentOrderProducedSteps?.lowerAssignedSteps.till || 0,
      state.currentOrderProducedSteps?.upperAssignedSteps.till || 0
    );
    return {
      estSteps,
      startSteps,
      endSteps,
      lower: state.currentOrderProducedSteps?.lowerAssignedSteps,
      upper: state.currentOrderProducedSteps?.upperAssignedSteps,
    };
  }
);

export const getProducedStepsSelector = createSelector(
  selectSelf,
  (state: PatientDetailsState) => {
    let lower = 0;
    let upper = 0;
    if (
      state.currentOrder?.lowerFirstProducedStep &&
      state.currentOrder?.lowerLastProducedStep
    ) {
      lower =
        state.currentOrder?.lowerLastProducedStep -
        state.currentOrder?.lowerFirstProducedStep +
        1;
    }

    if (
      state.currentOrder?.upperFirstProducedStep &&
      state.currentOrder?.upperLastProducedStep
    ) {
      upper =
        state.currentOrder?.upperLastProducedStep -
        state.currentOrder?.upperFirstProducedStep +
        1;
    }

    return {
      lower,
      upper,
    };
  }
);
export const getCaseBaseStatus = createSelector(
  selectSelf,
  getCaseCurrentStatus
);

export const getActiveOrderStatusForAligners = createSelector(
  selectSelf,
  getStatusForAligner
);
export const getActiveOrderStatusForRetainers = createSelector(
  selectSelf,
  getStatusForRetainer
);
