import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IExtOfficeDto,
  IExtOrderInvoiceDtoForFilterView,
  IOrganizationDto,
} from "lib/dto";
import { orgService, invoiceService, paymentService } from "services";

import { CreatePaymentActions } from "./types";
import { RootState } from "../store";
import { currentBalanceSelector } from "./selectors";
import { PaymentsMethod } from "lib/enum";

export const getOrgs = createAsyncThunk<IOrganizationDto[]>(
  CreatePaymentActions.FETCH_ORGS,
  async (_, thunkAPI) => {
    const { currentUser } = thunkAPI.getState() as RootState;
    const orgs = await orgService.getAllOrgs();
    if (currentUser.offices?.length) {
      const orgsId = currentUser.offices.map(({ orgId }) => orgId);
      return orgs.filter(({ id }) => orgsId.includes(id));
    }
    return orgs;
  }
);
export const getOffices = createAsyncThunk<IExtOfficeDto[], number>(
  CreatePaymentActions.FETCH_OFFICES,
  async (officeId, thunkAPI) => {
    const { currentUser } = thunkAPI.getState() as RootState;
    const offices = await orgService.getOfficeByOrgId(officeId);
    if (currentUser.offices?.length) {
      const officesId = currentUser.offices.map(({ id }) => id);
      return offices.filter(({ id }) => officesId.includes(id));
    }
    return offices;
  }
);
export const getInvoices = createAsyncThunk<
  IExtOrderInvoiceDtoForFilterView[],
  number | null
>(CreatePaymentActions.FETCH_INVOICES, async (officeId) => {
  if (!officeId) {
    return [];
  }
  return await invoiceService.getUnpaidInvoices(officeId);
});

export const getAllInfo = createAsyncThunk<
  {
    office: IExtOfficeDto;
    org: IOrganizationDto;
    invoices: IExtOrderInvoiceDtoForFilterView[];
  },
  {
    orgId: string;
    officeId: string;
  }
>(CreatePaymentActions.FETCH_ALL_INFO, async ({ orgId, officeId }) => {
  const org = await orgService.getOrgById(orgId);
  const office = await orgService.getExtOfficeById(officeId);
  const invoices = await invoiceService.getUnpaidInvoices(officeId);
  return {
    org,
    office,
    invoices,
  };
});

export const createPayment = createAsyncThunk<void>(
  CreatePaymentActions.PAYMENT_SAVING,
  async (_, thunkAPI) => {
    const { createPayment } = thunkAPI.getState() as RootState;
    await paymentService.createPayment({
      payment: {
        paymentMethod: createPayment.paymentMethod,
        note: createPayment.notes,
        officeId: Number(createPayment.office),
        amount: createPayment.invoiceAmount,
      },
      invoiceIds: createPayment.selectedInvoices,
    });
  }
);

export const calculateInvoiceInfo = createAsyncThunk<{
  invoiceAmount: number;
  outstandingBalance: number;
}>(CreatePaymentActions.CALCULATE_AMOUNTS, async (_, thunkAPI) => {
  const state: RootState = thunkAPI.getState() as RootState;
  const currentBalance = currentBalanceSelector(state);
  const selectedInvoices = state.createPayment.selectedInvoices;
  if (!selectedInvoices.length) {
    return {
      invoiceAmount: 0,
      outstandingBalance: currentBalance,
    };
  }
  const invoiceUnits: number[] = state.createPayment.invoices
    .filter(({ id }) => selectedInvoices.includes(id))
    .map(({ totalAmount }) => totalAmount);
  const { sum: outstandingSum } = await paymentService.calculateSum([
    -1 * currentBalance,
    ...invoiceUnits,
  ]);
  const { sum } = await paymentService.calculateSum(invoiceUnits);
  return {
    invoiceAmount: sum,
    outstandingBalance: -1 * outstandingSum,
  };
});

export const selectInvoice = createAction<number>(
  CreatePaymentActions.SELECT_INVOICE
);
export const changeOrg = createAction<string>(CreatePaymentActions.CHANGE_ORG);
export const changeOffice = createAction<string>(
  CreatePaymentActions.CHANGE_OFFICE
);
export const changeNote = createAction<string>(
  CreatePaymentActions.CHANGE_NOTE
);

export const changePayment = createAction<PaymentsMethod>(
  CreatePaymentActions.CHANGE_PAYMENT
);
