export const PLAN_COLORS = {
  red: {
    light: "#F87E7E",
    dark: "#D34343",
  },
  grey: {
    active: "#888888",
    GREY_2: "#F2F2F2",
    GREY_3: "#828282",
    GREY_4: "#B8B7B7",
    GREY_5: "#6f6c6c",
    GREY_6: "#C4C4C4",
    GREY_7: "#D0D0CE",
    GREY_8: "#7F8287",
    GREY_9: "#53565A",
    GREY_10: "#E7E7E7",
    GREY_11: "#F9F9F9",
    GREY_12: "#ECECEC",
    GREY_13: "#8F8B8B",
    GREY_14: "#DADADA",
    CONTAINER_GREY: "#F9F9FA",
    light: "#C7C7C7",
    dark: "#A2A2A2",
    progressGrey: "#E0E0E0",
  },
  green: {
    GREEN_1: "#00C853",
    GREEN_2: "#009688",
    GREEN_3: "#00C7B1",
    light: "#A5D893",
    dark: "#7DBB68",
    text: "#219653",
  },
  orange: {
    ORANGE_1: "#FF8F1C",
    ORANGE_2: "#F39221",
    light: "#FFAB5D",
    dark: "#CC7A2E",
  },
  pink: {
    light: "#f1bec7",
    dark: "#cc6b8d",
  },
  blue: {
    light: "#63B4FF",
    dark: "#137EE0",
    BLUE1: "#2D9CDB",
    BLUE2: "#2F80ED",
    BLUE3: "#3D7BF6",
    BLUE4: "#2196F3",
  },
};
