import React from "react";
import { CreateEvalCaseOrder } from "containers";
import { analyticsMethod } from "plugins/analytics";

export const NewPatientEvalOrderPage = () => {
  analyticsMethod(
    "page",
    "Orders",
    {
      title: "Create form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  return <CreateEvalCaseOrder />;
};
