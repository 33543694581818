import React, { useEffect } from "react";
import { CreateEvalCaseOrder } from "containers";
import { analyticsMethod } from "plugins/analytics";
import { useParams } from "react-router-dom";

import { useStyledSnackbar } from "hooks/notification";
import { useAppDispatch } from "hooks/redux";
import { actions } from "store/caseCreate";

export const ContinueCaseCreatingPage = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { showErrorNotice } = useStyledSnackbar();
  const dispatch = useAppDispatch();
  analyticsMethod(
    "page",
    "Case",
    {
      title: "Create form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  useEffect(() => {
    if (orderId) {
      dispatch(actions.fetchContinueOrderInfo(orderId))
        .unwrap()
        .catch((e) => {
          showErrorNotice(e.message);
        });
    }
  }, [orderId, dispatch, showErrorNotice]);
  return <CreateEvalCaseOrder title="Complete order creating" />;
};
