import React, { useState } from "react";
import Box from "@mui/material/Box";

import { Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { selectors, actions } from "store/caseCreate";

import { CreateShippingModal } from "../CreateShippingModal";

export const CreateShippingButton: React.VFC = () => {
  const order = useAppSelector(selectors.getAllInfoSelector);
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const [open, setOpen] = useState<boolean>(false);

  const openHandler = () => {
    if (!order.caseId) {
      dispatch(actions.createOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          setOpen(true);
          if (filesSaveError) {
            showErrorNotice(
              `Order was saved, but files could not be uploaded, pls try to upload them later or contact us`
            );
          }
        })
        .catch((error) => {
          showErrorNotice(error.message);
        });
    } else {
      dispatch(actions.updateCaseOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          setOpen(true);
          if (filesSaveError) {
            showErrorNotice(
              `Order was saved, but files could not be uploaded, pls try to upload them later or contact us`
            );
          }
        })
        .catch((error) => {
          showErrorNotice(error.message);
        });
    }
  };
  const closeHandler = () => {
    setOpen(false);
  };

  if (!Boolean(order.orderItems.IMPRESSION) || order.shippingLabel) {
    return null;
  }
  return (
    <>
      <CreateShippingModal open={open} onClose={closeHandler} />
      <Box mt={7}>
        <Button
          data-intercom-target="Create shipping button"
          onClick={openHandler}
          fatButton
          variant="outlined"
        >
          Create shipping label
        </Button>
      </Box>
    </>
  );
};
