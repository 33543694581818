import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import { PLAN_COLORS } from "consts/tpColors";

export const NumberContainer = styled("div")`
  display: grid;
  background-color: var(--container-grey-color);
  grid-template-columns: repeat(8, 1fr);
  border-radius: 8px 8px 0 0;
`;
export const HeadItem = styled("span")`
  height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GeneralParamsContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
`;

export const PlanTableContainer = styled(Box)`
  overflow: auto;
  flex-grow: 1;
`;

export const PlanTableScrolledContainer = styled(Box)`
  min-width: 1500px;
  padding-bottom: 80px;
`;

export const HeadContainer = styled(Stack)`
  padding-inline: 93px;
`;

export const EmptyColContainer = styled(Box)`
  display: grid;
  grid-template-columns: 24px 1fr 2px 1fr 24px;
  column-gap: 5px;
  flex-grow: 1;
  height: 40px;
  align-items: center;
`;

export const InstructionsText = styled("span")`
  display: flex;
  align-items: center;
  color: ${PLAN_COLORS.grey.GREY_8};
  background-color: ${PLAN_COLORS.grey.GREY_11};
  padding-block: 4px;
  font-size: 10px;
  min-height: 25px;
  border-radius: 4px;
  border: 1px solid ${PLAN_COLORS.grey.GREY_12};
  white-space: nowrap;
`;

export const LineItem = styled("div")`
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed ${PLAN_COLORS.blue.BLUE3};
`;

export const AreaContainer = styled("div")`
  display: flex;
  margin-top: 8px;
  &:nth-of-type(2n + 1) {
    background-color: ${PLAN_COLORS.grey.CONTAINER_GREY};
  }
`;

export const AreaNumber = styled(Typography)`
  text-orientation: mixed;
  writing-mode: vertical-rl;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: ${PLAN_COLORS.grey.GREY_8};
  transform: rotate(180deg);
`;

export const AreaNumberContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PLAN_COLORS.grey.CONTAINER_GREY};
  border-radius: 16px;
  width: 24px;
`;

export const ImpressionContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  margin: 24px 32px;
  &::before,
  &::after {
    content: "";
    margin: 0 4px;
    display: block;
    height: 1px;
    border-bottom: 1px dashed ${PLAN_COLORS.blue.BLUE3};
  }
`;

export const ImpressionLabel = styled(Chip)`
  font-size: 12px;
  height: 16px;
  margin: 0 4px;
  color: ${PLAN_COLORS.grey.GREY_8};
  background-color: ${PLAN_COLORS.grey.CONTAINER_GREY};
  border: 1px solid ${PLAN_COLORS.grey.GREY_12};
`;

export const GeneralAttachContainer = styled("span")`
  font-size: 10px;
  font-weight: 500;
  color: ${PLAN_COLORS.grey.GREY_8};
  text-align: center;
  display: flex;
  position: relative;
  white-space: nowrap;
  flex-grow: 1;
  height: auto;
  cursor: pointer;
  justify-content: center;
`;

export const GeneralIprContainer = styled("span")`
  color: ${PLAN_COLORS.grey.GREY_8};
  text-align: center;
  display: flex;
  position: relative;
  white-space: nowrap;
  width: min-content;
  min-height: 18px;
  flex-grow: 1;
  height: auto;
  cursor: pointer;
  justify-content: center;
  flex-wrap: wrap;
`;

export const GeneralGridColumns = styled(Box)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
`;

export const GeneralHeadItem = styled("span")`
  font-size: 10px;
  font-weight: 700;
  color: ${PLAN_COLORS.grey.GREY_8};
  text-align: center;
  display: flex;
  position: relative;
  white-space: nowrap;
  height: 18px;
`;

export const GeneralEmptyValue = styled("span")`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &:after {
	  content: '';
	  display: block;
	  width: 5px;
	  height: 5px;
	  margin-top: 6px;
	  margin-bottom: 6px;
	  border-radius: 5px;
	  background-color: ${PLAN_COLORS.grey.GREY_12};
  },
`;

export const GeneralSubTitles = styled(Box)`
  display: grid;
  column-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  flex-grow: 1;
  margin-right: 93px;
`;

export const GeneralSubtitlesColumns = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
`;
