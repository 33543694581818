import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { faInbox } from "@fortawesome/free-solid-svg-icons/faInbox";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faTruckFast } from "@fortawesome/free-solid-svg-icons/faTruckFast";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons/faFlagCheckered";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { OrderFakeStatuses } from "lib/enum";
import { Nullable } from "lib/types";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { FontAwesomeSvgIcon } from "components/common";
import { Colors } from "consts/colors";
import { ItemStatus, ProgressInfoItem } from "./ProgressInfoItem";

const getIconStatus = (
  pendingStatus: OrderFakeStatuses,
  params: {
    status: Nullable<OrderFakeStatuses>;
    completeStatuses: OrderFakeStatuses[];
  }
): ItemStatus => {
  if (params.completeStatuses.includes(pendingStatus)) {
    return "complete";
  }
  if (pendingStatus === params.status) {
    return "inProgress";
  }
  return "pending";
};
export const ProgressInfo = () => {
  const { caseInfo } = useAppSelector(selectors.caseInfoSelector);
  const { showRating } = useAppSelector(selectors.allInfoSelector);
  const { currentOrder, isLoading } = useAppSelector(selectors.currentOrder);
  const { caseEvents, requiredActions } = useAppSelector(
    selectors.caseNoticeSelector
  );
  const orderStatusAligners = useAppSelector(
    selectors.getActiveOrderStatusForAligners
  );
  const fontSize =
    caseEvents.length > 0 || requiredActions.length > 0 || showRating ? 12 : 16;
  const orderStatusRetainers = useAppSelector(
    selectors.getActiveOrderStatusForRetainers
  );

  if (caseInfo?.needPreEvaluation && !isLoading) {
    return (
      <Stack>
        <Typography variant="body2" color={Colors.DISABLED}>
          ORDER STATUS
        </Typography>
        <Stack spacing={1.5} mt={0.5} direction="row" sx={{ fontSize }}>
          <Typography variant="body1" color={Colors.TEXT_SECONDARY}>
            Processing
          </Typography>
        </Stack>
      </Stack>
    );
  }

  if (currentOrder?.productionType) {
    return (
      <Stack>
        <Typography variant="body2" color={Colors.DISABLED}>
          ORDER STATUS
        </Typography>
        <Stack
          spacing={1.5}
          direction="row"
          sx={{ fontSize }}
          data-intercom-target="Order progress info"
        >
          <ProgressInfoItem
            icon={<FontAwesomeSvgIcon icon={faInbox} />}
            label="Processing"
            status={getIconStatus(
              OrderFakeStatuses.PROCESSING,
              orderStatusAligners
            )}
            isLoading={isLoading}
          />
          <ProgressInfoItem
            icon={<FontAwesomeSvgIcon icon={faClipboard} />}
            label="Treatment plan"
            status={getIconStatus(
              OrderFakeStatuses.TREAT_PLAN,
              orderStatusAligners
            )}
            isLoading={isLoading}
          />
          <ProgressInfoItem
            icon={<SettingsOutlinedIcon />}
            label="Manufacturing"
            status={getIconStatus(
              OrderFakeStatuses.MANUFACTURING,
              orderStatusAligners
            )}
            isLoading={isLoading}
          />
          <ProgressInfoItem
            icon={<FontAwesomeSvgIcon icon={faTruckFast} />}
            label="Shipping"
            status={getIconStatus(
              OrderFakeStatuses.SHIPPING,
              orderStatusAligners
            )}
            isLoading={isLoading}
          />
          <ProgressInfoItem
            icon={<FontAwesomeSvgIcon icon={faFlagCheckered} />}
            label="Delivered"
            status={getIconStatus(
              OrderFakeStatuses.DELIVERED,
              orderStatusAligners
            )}
            isLoading={isLoading}
            lastPoint
          />
        </Stack>
      </Stack>
    );
  }

  if (currentOrder?.productionType === void 0) {
    return null;
  }
  return (
    <Stack>
      <Typography variant="body2" color={Colors.DISABLED}>
        ORDER STATUS
      </Typography>
      <Stack spacing={1.5} direction="row">
        <ProgressInfoItem
          icon={<SettingsOutlinedIcon />}
          label="Manufacturing"
          status={getIconStatus(
            OrderFakeStatuses.MANUFACTURING,
            orderStatusRetainers
          )}
          isLoading={isLoading}
        />
        <ProgressInfoItem
          icon={<FontAwesomeSvgIcon icon={faTruckFast} />}
          label="Shipping"
          status={getIconStatus(
            OrderFakeStatuses.SHIPPING,
            orderStatusRetainers
          )}
          isLoading={isLoading}
        />
        <ProgressInfoItem
          icon={<FontAwesomeSvgIcon icon={faFlagCheckered} />}
          label="Delivered"
          status={getIconStatus(
            OrderFakeStatuses.DELIVERED,
            orderStatusRetainers
          )}
          isLoading={isLoading}
          lastPoint
        />
      </Stack>
    </Stack>
  );
};
