import { styled } from "@mui/material";
import { Button } from "components/ui";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";

export const EmptyContainer = styled("div")`
  flex-grow: 1;
`;

export const StyledButton = styled(Button)`
  height: 48px;
`;
export const Container = styled("div")`
  flex-grow: 1;
  display: flex;
  box-sizing: border-box;
  height: 100%;
`;
export const Content = styled("div")`
  flex-grow: 1;
`;
export const ContentContainer = styled("div")`
  height: calc(100% - 86px);
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const StyledTab = styled(Tab)`
  text-transform: none;
  text-align: left;
  padding: 19px 24px;
  opacity: 1 !important;
  border: 1px solid ${Colors.CONTAINER_GREY};
  box-sizing: border-box;
  cursor: pointer;
`;
export const TabTitle = styled(Typography)`
  font-weight: 600;
`;
export const TabSubTitle = styled(Typography)`
  font-weight: 400;
`;
export const EmptyBlock = styled("span")`
  width: 24px;
  height: 24px;
`;
