import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { InfoWithLabel } from "components/common";
import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/continuationOrderCreate";
import { dateToString } from "utils";

import { Subtitle, NameText } from "./Elements";

export const HeaderSubTitle = () => {
  const order = useAppSelector(selectors.getAllInfoSelector);
  const stepsIsEmpty = Boolean(
    !order.previousOrder?.lowerFirstProducedStep &&
      !order.previousOrder?.lowerLastProducedStep &&
      !order.previousOrder?.upperFirstProducedStep &&
      !order.previousOrder?.upperLastProducedStep
  );
  return (
    <Box>
      <Subtitle>
        {order.isLoading ? (
          <Skeleton width={100} animation="wave" />
        ) : (
          `Case: ${order.caseInfo?.id || ""}`
        )}
      </Subtitle>
      <NameText>
        {order.isLoading ? (
          <Skeleton width={200} animation="wave" />
        ) : (
          `${order.caseInfo?.patient.firstName || ""} ${
            order.caseInfo?.patient.lastName || ""
          }`
        )}
      </NameText>
      {Boolean(order.previousOrder?.lastChangeStatusDate) && (
        <Box mt={0.5}>
          <InfoWithLabel
            isLoading={order.isLoading}
            direction="column"
            label="LAST ACTIVITY DATE"
            text={dateToString(order.previousOrder?.lastChangeStatusDate || "")}
            labelStyleSx={{ color: Colors.TEXT_SECONDARY, fontWeight: 500 }}
            textStyleSx={{ fontSize: 14 }}
          />
        </Box>
      )}
      {!stepsIsEmpty && (
        <Box mt={2}>
          <InfoWithLabel
            direction="column"
            isLoading={order.isLoading}
            label="PREVIOUS ALIGNERS THAT WERE PRODUCED"
            labelStyleSx={{ color: Colors.TEXT_SECONDARY, fontWeight: 500 }}
          >
            <Stack mt={0.5} spacing={0.5}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={Colors.TEXT_GREY}
              >
                Lower arch from{" "}
                {order.previousOrder?.lowerFirstProducedStep || 0} to{" "}
                {order.previousOrder?.lowerLastProducedStep || 0}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={Colors.TEXT_GREY}
              >
                Upper arch from{" "}
                {order.previousOrder?.upperFirstProducedStep || 0} to{" "}
                {order.previousOrder?.upperLastProducedStep || 0}
              </Typography>
            </Stack>
          </InfoWithLabel>
        </Box>
      )}
    </Box>
  );
};
