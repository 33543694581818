import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

export const ContentContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
`;

export const StyledButton = styled(Link)`
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
`;

export const OverlayContainer = styled(Box)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  justify-content: center;
  z-index: 100;
`;

export const PopoverContainer = styled(Box)`
  background: #ffffff;
  border: 0.6px solid #818181;
  box-sizing: border-box;
  box-shadow: 0 2.40574px 18.0431px rgba(56, 71, 109, 0.1);
  border-radius: 12px;
  padding: 4px;
  min-height: 258px;
  color: var(--text-grey-color);
`;
