//TODO: Разделить файл

export enum PaymentsMethod {
  INVALID = "INVALID",
  CASH = "CASH",
  CHECK = "CHECK",
  STRIPE = "STRIPE",
  CREDIT_CARD = "CREDIT_CARD",
  VISA = "VISA",
  MASTER_CARD = "MASTER_CARD",
  AMERICAN_EXPRESS = "AMERICAN_EXPRESS",
  BANK_TRANSFER = "BANK_TRANSFER",
  BALANCE = "BALANCE",
  DISCOVER = "DISCOVER",
  US_BANK_ACCOUNT = "US_BANK_ACCOUNT",
  OTHER = "OTHER",
  BAD_DEBT = "BAD_DEBT",
  PROMO = "PROMO",
}

export type paymentsMethodType =
  | "INVALID"
  | "CASH"
  | "CHECK"
  | "STRIPE"
  | "CREDIT_CARD"
  | "VISA"
  | "MASTER_CARD"
  | "BALANCE"
  | "AMERICAN_EXPRESS"
  | "US_BANK_ACCOUNT"
  | "BANK_TRANSFER"
  | "DISCOVER"
  | "PROMO"
  | "BAD_DEBT"
  | "OTHER";

export const paymentsMethodEnum: Record<PaymentsMethod, string> = {
  INVALID: "Invalid",
  CASH: "Cash",
  CHECK: "Check",
  STRIPE: "Stripe",
  CREDIT_CARD: "Credit card",
  BALANCE: "Balance",
  VISA: "VISA",
  MASTER_CARD: "Master Card",
  AMERICAN_EXPRESS: "American Express",
  BANK_TRANSFER: "Bank transfer",
  DISCOVER: "Discover",
  US_BANK_ACCOUNT: "ACH Debit",
  BAD_DEBT: "Bad debt",
  PROMO: "Promo",
  OTHER: "Other",
};
