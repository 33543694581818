import api from "./baseService";
import { AUTH_TOKEN_NAME, NEW_PORTAL_COOKIE } from "./apiConsts";
import { IAuthData, IBaseResponse } from "lib/model";
import axios from "axios";
import { responseInterceptor } from "./responseInterceptor";

const redirectLink =
  document.cookie.indexOf(`${NEW_PORTAL_COOKIE}`) !== -1 &&
  `${window.location.origin}/` === process.env.REACT_APP_REDIRECT_URI_OLD
    ? process.env.REACT_APP_REDIRECT_URI_OLD
    : process.env.REACT_APP_REDIRECT_URI;

class AuthService {
  public async getLoginLink(customRedirectUri?: string): Promise<string> {
    const redirectUrl = customRedirectUri
      ? `?redirectUri=${customRedirectUri}`
      : `?redirectUri=${redirectLink}auth`;
    const { data } = await axios.get<IBaseResponse<string>>(
      `${process.env.REACT_APP_API_URL}login-uri${redirectUrl}`
    );
    return data.result;
  }
  public async getLogoutLink(): Promise<string> {
    const redirectUrl = `?redirectUri=${redirectLink}`;
    const { data } = await axios.get<IBaseResponse<string>>(
      `${process.env.REACT_APP_API_URL}logout-uri${redirectUrl}`
    );
    return data.result;
  }
  public async loginUser(code: string): Promise<IAuthData> {
    const isLocalHost = process.env.REACT_APP_REDIRECT_URI
      ? `&isLocalHost=1&redirectUri=${redirectLink}auth`
      : "";
    const interceptor = axios.interceptors.response.use(responseInterceptor);
    try {
      const { data } = await axios.get<IBaseResponse<IAuthData>>(
        `${process.env.REACT_APP_API_URL}callback?code=${code}${isLocalHost}`,
        {
          withCredentials: true,
        }
      );

      if (data.result.accessToken) {
        localStorage.setItem(AUTH_TOKEN_NAME, data.result.accessToken);
      }

      return data.result;
    } catch (e: any) {
      if (e?.response?.data?.desc) {
        throw new Error(e?.response?.data?.desc);
      }
      throw new Error(e.message);
    } finally {
      axios.interceptors.response.eject(interceptor);
    }
  }
  public async getUserData(): Promise<IAuthData> {
    const { data } = await api.get<IBaseResponse<IAuthData>>("user-data");
    return data.result;
  }
}

export const authService = new AuthService();
