import { createReducer } from "@reduxjs/toolkit";
import { NotesRecipient } from "lib/enum";
import { initState } from "./initState";
import { TreatmentPlanState } from "./types";
import * as actions from "./actions";
import { generateCellsForNote, selectCellsInRange } from "./helpers";
export const reducer = createReducer<TreatmentPlanState>(
  initState,
  (builder) => {
    builder
      .addCase(actions.fetchCurrentVersion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.setIPRHover, (state, action) => {
        state.iprHoveredNum = action.payload;
      })
      .addCase(actions.openToShow, (state, action) => {
        state.tpNotes.currentTooth = action.payload?.currentTooth || [];
        state.tpNotes.selectedNote = null;
        state.tpNotes.notesForShow =
          action.payload.noteIds.length > 0
            ? state.tpNotes.notes.filter(({ noteDto: { id } }) =>
                action.payload.noteIds.includes(id)
              )
            : undefined;
        state.tpNotes.anchor = action.payload.anchor;
        state.tpNotes.addNote = Boolean(action.payload.addNote);
      })
      .addCase(actions.setTpNotesFilter, (state, action) => {
        state.tpNotes.previewFilter = action.payload;
        state.tpNotes.noteCells = generateCellsForNote(
          state.tpNotes.notes.filter(({ forDoctor }) => {
            switch (action.payload) {
              case NotesRecipient.DOCTOR:
                return forDoctor;
              case NotesRecipient.TECHNICIAN:
                return !forDoctor;
              default:
                return true;
            }
          })
        );
      })
      .addCase(actions.deleteNote.fulfilled, (state, action) => {
        const filteredNotes = state.tpNotes.notes.filter(
          ({ noteDto: { id } }) => id !== action.payload
        );
        state.tpNotes.notesForShow =
          state.tpNotes.notesForShow?.filter(
            ({ noteDto: { id } }) => id !== action.payload
          ) || [];
        state.tpNotes.notes = filteredNotes;
        state.tpNotes.noteCells = generateCellsForNote(filteredNotes);
      })
      .addCase(actions.updateNote.fulfilled, (state, action) => {
        const updatedNotes = state.tpNotes.notes.map((item) => {
          if (item.noteDto.id === action.payload.noteId) {
            return {
              ...item,
              noteDto: {
                ...item.noteDto,
                text: action.payload.note,
              },
            };
          }
          return item;
        });
        state.tpNotes.notesForShow =
          state.tpNotes.notesForShow?.map((item) => {
            if (item.noteDto.id === action.payload.noteId) {
              return {
                ...item,
                noteDto: {
                  ...item.noteDto,
                  text: action.payload.note,
                },
              };
            }
            return item;
          }) || [];
        state.tpNotes.notes = updatedNotes;
        state.tpNotes.noteCells = generateCellsForNote(updatedNotes);
      })
      .addCase(actions.fetchCurrentVersion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.treatmentPlan = action.payload.treatmentPlan;
        state.treatmentPlanInstructions =
          action.payload.treatmentPlanInstructions;
        state.verificationKeys = action.payload.verificationKeys;
        state.case = action.payload.caseInfo;
        state.tpNotes.notes = action.payload.tpNotes;
        state.tpNotes.noteCells = generateCellsForNote(action.payload.tpNotes);
        state.upperImpOrderIds = action.payload.needUpperIds;
        state.lowerImpOrderIds = action.payload.needLowerIds;
      })
      .addCase(actions.createNote.fulfilled, (state, action) => {
        const newNotes = [...state.tpNotes.notes, action.payload];
        state.tpNotes.currentTooth = [];
        state.tpNotes.selectedNote = null;
        state.tpNotes.notesForShow = undefined;
        state.tpNotes.anchor = undefined;
        state.tpNotes.addNote = false;
        state.tpNotes.notes = newNotes;
        state.tpNotes.noteCells = generateCellsForNote(newNotes);
        state.selectingToothKey = {};
        state.selectingToothDto = [];
      })
      .addCase(actions.fetchCurrentVersion.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(actions.approveTp.fulfilled, (state) => {
        if (state.case) {
          state.case!.needApproveTreatPlan = false;
        }
      })
      .addCase(actions.rejectTp.fulfilled, (state) => {
        if (state.case) {
          state.case!.needApproveTreatPlan = false;
        }
      })
      .addCase(actions.setSelectingStart, (state, action) => {
        state.startSelectingTooth = action.payload;
        const idx = `${action.payload.toothNumber.toString()}_${action.payload.stepNumber.toString()}`;
        if (!state.selectingToothKey[idx]) {
          state.selectingToothKey[idx] = true;
          state.selectingToothDto.push(action.payload);
        }
      })
      .addCase(actions.setSelectingEnd, (state) => {
        state.startSelectingTooth = undefined;
      })
      .addCase(actions.setSelectedCell, (state, action) => {
        if (state.startSelectingTooth) {
          const { selectedToothDto, selectedToothKey } = selectCellsInRange(
            Number(state.startSelectingTooth?.stepNumber),
            Number(state.startSelectingTooth?.stepNumber) -
              action.payload.stepNumber,
            Number(state.startSelectingTooth?.toothNumber),
            Number(state.startSelectingTooth?.toothNumber) -
              action.payload.toothNumber
          );
          state.selectingToothDto = selectedToothDto;
          state.selectingToothKey = selectedToothKey;
        }
      })
      .addCase(actions.setSelectedCells, (state, action) => {
        state.selectingToothKey = action.payload.selectingToothKey;
        state.selectingToothDto = action.payload.selectingToothDto;
      })
      .addCase(actions.toggleLegend, (state, action) => {
        state.showLegend = action.payload;
      })
      .addCase(actions.selectNote, (state, action) => {
        state.tpNotes.selectedNote = action.payload;
      })
      .addCase(actions.togglePin, (state, action) => {
        state.showPin = action.payload;
      })
      .addCase(actions.toggleNoteList, (state, action) => {
        state.tpNotes.anchor = action.payload;
        state.tpNotes.selectedNote = null;
        state.tpNotes.notesForShow = action.payload
          ? state.tpNotes.notes
          : undefined;
      })
      .addCase(actions.toggleFBM, (state, action) => {
        state.showFBM = action.payload;
      })
      .addCase(actions.toggleNotes, (state, action) => {
        state.showNotes = action.payload;
      });
  }
);
