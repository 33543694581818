import { ListTableColumn } from "components/ui/listTable";
import { IExtOrderMessageDto } from "lib/dto";
import { Colors } from "consts/colors";
import { dateToString } from "utils";

const headerCellStyle = {
  color: Colors.TEXT_SECONDARY,
  backgroundColor: "white",
  paddingBottom: 16,
  borderBottom: "none",
};
export const columns: ListTableColumn<IExtOrderMessageDto>[] = [
  {
    title: "ORDER ID",
    dataIndex: "orderId",
    width: "110px",
    render: (orderId, { unreadMessageId }) =>
      unreadMessageId ? (
        orderId
      ) : (
        <span style={{ color: Colors.DISABLED }}>{orderId}</span>
      ),
    style: {
      headerCell: headerCellStyle,
      rowCell: {
        color: Colors.PRIMARY,
        paddingBlock: 8,
      },
    },
  },
  {
    title: "SUBJECT",
    dataIndex: "subject",
    render: (subject, { unreadMessageId }) =>
      unreadMessageId ? (
        subject
      ) : (
        <span style={{ color: Colors.DISABLED }}>{subject}</span>
      ),
    style: {
      headerCell: headerCellStyle,
      rowCell: {
        color: Colors.PRIMARY,
        maxWidth: "250px",
        textOverflow: "ellipsis",
        paddingBlock: 8,
      },
    },
  },
  {
    title: "DATE",
    dataIndex: "created",
    width: "110px",
    render: (created, { unreadMessageId }) =>
      unreadMessageId ? (
        dateToString(created)
      ) : (
        <span style={{ color: Colors.DISABLED }}>{dateToString(created)}</span>
      ),
    style: {
      headerCell: headerCellStyle,
      rowCell: {
        paddingBlock: 8,
      },
    },
  },
];
