import {
  IExtCaseWithCaseProgress,
  IExtCaseListDto,
  IExtOrderForCaseViewDto,
  ICaseTabDto,
  IExtOrderMessageDto,
  IEventDashboardDto,
  IExtCaseDto,
  ICaseTabCountDto,
  ITreatmentConditionDto,
  IPatientDto,
  IInvoiceItemDto,
} from "lib/dto";
import { Nullable } from "lib/types";
import {
  ICaseAndOrdersFile,
  ICreateCaseAndOrder,
  IDMPatient,
  ITreatmentCondition,
} from "lib/model";
import { IBaseResponse, IBaseResponseList } from "lib/model/IBaseResponse";
import { getUrlSearchParamsString } from "utils/urlHelpers";
import api from "./baseService";
import { store } from "App";
import { CaseFinishRate, FileType } from "lib/enum";

class CaseService {
  private baseUrl: string = "cases";
  public async fetch(filter: any): Promise<IBaseResponseList<IExtCaseListDto>> {
    const { data } = await api.get<
      IBaseResponse<IBaseResponseList<IExtCaseListDto>>
    >(`${this.baseUrl}/filter?${getUrlSearchParamsString(filter)}`);
    return data.result;
  }

  public async getActiveCases(
    filter: any
  ): Promise<IBaseResponseList<ICaseTabDto>> {
    const {
      currentUser: { currentUser },
    } = store.getState();
    const { data } = await api.get<
      IBaseResponse<IBaseResponseList<ICaseTabDto>>
    >(
      `${this.baseUrl}/tabs/active?${getUrlSearchParamsString({
        ...filter,
        doctorFirstName: currentUser?.firstName || "",
        doctorLastName: currentUser?.lastName || "",
      })}`
    );
    return data.result;
  }

  public getCaseTreatmentCondition = async (): Promise<
    ITreatmentCondition[]
  > => {
    const { data } = await api.get<IBaseResponse<ITreatmentConditionDto[]>>(
      `${this.baseUrl}/treatment-condition`
    );
    return (
      data?.result?.map((treatmentCondition) => {
        return {
          ...treatmentCondition,
          secondary: treatmentCondition.secondary
            ? JSON.parse(treatmentCondition.secondary)
            : null,
        };
      }) ?? []
    );
  };

  public async getCaseTabsCount(): Promise<ICaseTabCountDto> {
    const {
      currentUser: { currentUser },
    } = store.getState();
    const { data } = await api.get<IBaseResponse<ICaseTabCountDto>>(
      `${this.baseUrl}/tabs/count?${getUrlSearchParamsString({
        doctorFirstName: currentUser?.firstName || "",
        doctorLastName: currentUser?.lastName || "",
      })}`
    );
    return data.result;
  }

  public async getCasesAtLab(
    filter: any
  ): Promise<IBaseResponseList<ICaseTabDto>> {
    const {
      currentUser: { currentUser },
    } = store.getState();
    const { data } = await api.get<
      IBaseResponse<IBaseResponseList<ICaseTabDto>>
    >(
      `${this.baseUrl}/tabs/at-lab-new?${getUrlSearchParamsString({
        ...filter,
        doctorFirstName: currentUser?.firstName || "",
        doctorLastName: currentUser?.lastName || "",
      })}`
    );
    return data.result;
  }

  public async getCasesArchived(
    filter: any
  ): Promise<IBaseResponseList<ICaseTabDto>> {
    const {
      currentUser: { currentUser },
    } = store.getState();
    const { data } = await api.get<
      IBaseResponse<IBaseResponseList<ICaseTabDto>>
    >(
      `${this.baseUrl}/tabs/archived?${getUrlSearchParamsString({
        ...filter,
        doctorFirstName: currentUser?.firstName || "",
        doctorLastName: currentUser?.lastName || "",
      })}`
    );
    return data.result;
  }

  public async getCasesSearch(
    filter: any
  ): Promise<IBaseResponseList<ICaseTabDto>> {
    const {
      currentUser: { currentUser },
    } = store.getState();
    const { data } = await api.get<
      IBaseResponse<IBaseResponseList<ICaseTabDto>>
    >(
      `${this.baseUrl}/tabs/search?${getUrlSearchParamsString({
        ...filter,
        doctorFirstName: currentUser?.firstName || "",
        doctorLastName: currentUser?.lastName || "",
      })}`
    );
    return data.result;
  }

  public async getCaseWithOfficeAndProgressById(
    caseId: string | number
  ): Promise<IExtCaseWithCaseProgress> {
    const { data } = await api.get<IBaseResponse<IExtCaseWithCaseProgress>>(
      `${this.baseUrl}/${caseId}/with-office-new`
    );
    return data.result;
  }

  public async getCaseById(caseId: string | number): Promise<IExtCaseDto> {
    const { data } = await api.get<IBaseResponse<IExtCaseDto>>(
      `${this.baseUrl}/${caseId}`
    );
    return data.result;
  }

  public async getCaseOrderInvoices(
    caseId: string | number
  ): Promise<IInvoiceItemDto[]> {
    const { data } = await api.get<IBaseResponse<IInvoiceItemDto[]>>(
      `${this.baseUrl}/${caseId}/order-invoices`
    );
    return data.result;
  }

  public async getOrdersByCaseId(
    caseId: string | number
  ): Promise<Nullable<IExtOrderForCaseViewDto[]>> {
    const { data } = await api.get<IBaseResponse<IExtOrderForCaseViewDto[]>>(
      `${this.baseUrl}/${caseId}/orders`
    );
    return data.result;
  }

  public async getCaseOrderMessagesById(
    caseId: string | number
  ): Promise<IExtOrderMessageDto[]> {
    const { data } = await api.get<IBaseResponse<IExtOrderMessageDto[]>>(
      `${this.baseUrl}/${caseId}/order-messages`
    );
    return data.result;
  }

  public async getAllCaseAndOrderFiles(
    caseId: number | string
  ): Promise<ICaseAndOrdersFile> {
    const { data } = await api.get<IBaseResponse<ICaseAndOrdersFile>>(
      `${this.baseUrl}/${caseId}/orders/files`
    );
    return data.result;
  }
  public async getCaseEvents(
    caseId: number | string
  ): Promise<IEventDashboardDto[]> {
    const { data } = await api.get<IBaseResponse<IEventDashboardDto[]>>(
      `${this.baseUrl}/${caseId}/dashboard-events`
    );
    return data.result;
  }

  public async getTreatmentPlanPdf(caseId: number | string): Promise<string> {
    const { data } = await api.get<BlobPart>(
      `${this.baseUrl}/${caseId}/treatment-plan/pdf`,
      {
        responseType: "blob",
      }
    );
    return URL.createObjectURL(
      new File([data], `treatment-plan-${caseId}`, {
        type: "application/pdf",
      })
    );
  }

  public async createCaseAndOrder(params: ICreateCaseAndOrder): Promise<{
    orderId: number;
    caseId: number;
  }> {
    const { data } = await api.post<
      IBaseResponse<{
        orderId: number;
        caseId: number;
      }>
    >(`case-with-order`, params);
    return data.result;
  }

  public async updateCase(caseInfo: IExtCaseDto): Promise<void> {
    await api.put(this.baseUrl, caseInfo);
  }

  public async finishRate(
    caseId: number,
    finishRate: CaseFinishRate,
    finishRateComment: string
  ): Promise<void> {
    await api.put(`${this.baseUrl}/finish-rate`, {
      caseId,
      finishRate,
      finishRateComment,
    });
  }

  public async uploadCaseFiles(
    caseId: number,
    files: { blob: Blob; name: string }[],
    fileType: FileType
  ): Promise<void> {
    const formData = new FormData();
    files.forEach((file: { blob: Blob; name: string }) => {
      formData.append(file.name, file.blob);
    });
    await api.post(
      `${this.baseUrl}/${caseId}/files?fileType=${fileType}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  public async submitPatientToDM(caseId: number, patientFields: IDMPatient) {
    await api.post(`${this.baseUrl}/${caseId}/dm-patient`, {
      ...patientFields,
      fromHub: false,
    });
  }

  public async cancelTheCase(
    caseId: number | string,
    description: string
  ): Promise<void> {
    await api.put(`${this.baseUrl}/cancel`, { caseId, description });
  }

  public async printCaseLabel(orderId: number): Promise<string> {
    const { data } = await api.get<IBaseResponse<string>>(
      `${this.baseUrl}/orders/${orderId}/case-label`
    );
    return data.result;
  }

  public async startDmMonitoring(caseId: number): Promise<any> {
    await api.post(`${this.baseUrl}/start-dm-monitoring`, {
      caseId,
      fromHub: false,
    });
  }

  public async stopDmMonitoring(caseId: number): Promise<any> {
    await api.post(`${this.baseUrl}/stop-dm-monitoring`, {
      caseId,
      fromHub: false,
    });
  }

  public async updatePatientInfo(patient: IPatientDto): Promise<any> {
    await api.put(`${this.baseUrl}/patients`, patient);
  }
}
export const caseService = new CaseService();
