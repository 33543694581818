import api from "./baseService";
import { IBaseResponse, IOrderCreate, IOrderUpdateModel } from "lib/model";
import {
  IExtOrderWithOrgAndOfficeDto,
  IExtOfficeDto,
  IExtOrderWithItemsAndTasksDto,
  IExtOrderTaskWithNotesDto,
  IExtOrderDto,
  IExtOrderWithItemsDto,
  IFileDto,
} from "lib/dto";
import { FileType } from "../lib/enum";
import { IOrderRemake } from "../lib/model/IOrderRemake";

class OrderService {
  private baseUrl: string = "orders";

  public async createOrder(newOrder: IOrderCreate): Promise<{ id: number }> {
    const { data } = await api.post<IBaseResponse<{ id: number }>>(
      this.baseUrl,
      newOrder
    );
    return data.result;
  }
  public async getOrderById(orderId: number | string): Promise<IExtOrderDto> {
    const { data } = await api.get<IBaseResponse<IExtOrderDto>>(
      `${this.baseUrl}/${orderId}`
    );
    return data.result;
  }

  public async getFilesByOrderId(
    orderId: number | string
  ): Promise<IFileDto[]> {
    const { data } = await api.get<IBaseResponse<IFileDto[]>>(
      `${this.baseUrl}/${orderId}/files`
    );
    return data.result;
  }

  public async getOrderWithOrgAndOffice(
    orderId: number
  ): Promise<IExtOrderWithOrgAndOfficeDto> {
    const { data } = await api.get<IBaseResponse<IExtOrderWithOrgAndOfficeDto>>(
      `${this.baseUrl}/${orderId}/with-org-and-office`
    );
    return data.result;
  }

  public async getOfficesByOrderId(orderId: number): Promise<IExtOfficeDto[]> {
    const { data } = await api.get<IBaseResponse<IExtOfficeDto[]>>(
      `${this.baseUrl}/${orderId}/offices`
    );
    return data.result;
  }

  public async getOrderWithItemsById(
    orderId: number | string
  ): Promise<IExtOrderWithItemsDto> {
    const { data } = await api.get<IBaseResponse<IExtOrderWithItemsDto>>(
      `${this.baseUrl}/${orderId}/with-items`
    );
    return data.result;
  }

  public async getOrderWithItemsAndTasksById(
    orderId: number | string
  ): Promise<IExtOrderWithItemsAndTasksDto> {
    const { data } = await api.get<
      IBaseResponse<IExtOrderWithItemsAndTasksDto>
    >(`${this.baseUrl}/${orderId}/with-items-and-tasks`);
    return data.result;
  }

  public async getOrderTaskByOrderId(
    orderId: number | string
  ): Promise<IExtOrderTaskWithNotesDto[]> {
    const { data } = await api.get<IBaseResponse<IExtOrderTaskWithNotesDto[]>>(
      `${this.baseUrl}/${orderId}/order-tasks`
    );
    return data.result;
  }

  public async updateOrder(order: IOrderUpdateModel): Promise<void> {
    await api.put(this.baseUrl, order);
  }

  public async submitOrderToLab(orderId: number | string): Promise<void> {
    await api.put(`${this.baseUrl}/submit-to-lab`, { orderId });
  }

  public async uploadOrderFiles(
    orderId: number,
    files: { blob: Blob; name: string }[],
    fileType: FileType
  ): Promise<void> {
    const formData = new FormData();
    files.forEach((file: { blob: Blob; name: string }) => {
      formData.append(file.name, file.blob);
    });
    await api.post(
      `${this.baseUrl}/${orderId}/files?fileType=${fileType}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  public async remakeOrder(
    orderId: number | string,
    remakeOrder: IOrderRemake
  ) {
    await api.put(`${this.baseUrl}/${orderId}/remake-order`, remakeOrder);
  }

  public async addNoteToOrder(
    orderId: number | string,
    note: string
  ): Promise<void> {
    await api.post(`${this.baseUrl}/notes`, {
      orderId,
      note,
    });
  }

  public async setStartWearingDate(
    orderId: number | string,
    wearingStartDate: string
  ): Promise<void> {
    await api.put(`${this.baseUrl}/wearing-start-date`, {
      orderId,
      wearingStartDate,
    });
  }

  public async cancelOrder(
    orderId: number | string,
    description: string,
    orderReasonStatusId?: string | number
  ): Promise<void> {
    await api.put(`${this.baseUrl}/cancel`, {
      orderId,
      description,
      orderReasonStatusId,
    });
  }
  public async unholdOrder(orderId: number | string): Promise<void> {
    await api.put(`${this.baseUrl}/unhold`, {
      orderId,
    });
  }
}

export const orderService = new OrderService();
