import React from "react";
import Stack from "@mui/material/Stack";
import { FontAwesomeSvgIcon } from "components/common";
import { faInbox } from "@fortawesome/free-solid-svg-icons/faInbox";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { faTruckFast } from "@fortawesome/free-solid-svg-icons/faTruckFast";

import { CaseProgressItem } from "./CaseProgressItem";

interface Props {
  currentState: number;
  isPreEval: boolean;
}

enum ProgressStatus {
  IN_PROGRESS = 1,
  TREAT_PLAN,
  MANUFACTURING,
  SHIPPING,
}
export const CaseProgressColumn: React.VFC<Props> = ({
  currentState,
  isPreEval,
}) => {
  if (isPreEval) {
    return (
      <Stack spacing={0.25} direction="row" alignItems="center">
        <CaseProgressItem
          isFirstPoint
          complete={currentState > ProgressStatus.MANUFACTURING}
          inProgress={currentState === ProgressStatus.MANUFACTURING}
          pending={currentState < ProgressStatus.MANUFACTURING}
          icon={<SettingsOutlinedIcon />}
        />
      </Stack>
    );
  }
  return (
    <Stack spacing={0.25} direction="row" alignItems="center">
      <CaseProgressItem
        isFirstPoint
        complete={currentState > ProgressStatus.IN_PROGRESS}
        inProgress={currentState === ProgressStatus.IN_PROGRESS}
        pending={currentState < ProgressStatus.IN_PROGRESS}
        icon={<FontAwesomeSvgIcon icon={faInbox} />}
      />
      <CaseProgressItem
        complete={currentState > ProgressStatus.TREAT_PLAN}
        inProgress={currentState === ProgressStatus.TREAT_PLAN}
        pending={currentState < ProgressStatus.TREAT_PLAN}
        icon={<FontAwesomeSvgIcon icon={faClipboard} />}
      />
      <CaseProgressItem
        complete={currentState > ProgressStatus.MANUFACTURING}
        inProgress={currentState === ProgressStatus.MANUFACTURING}
        pending={currentState < ProgressStatus.MANUFACTURING}
        icon={<SettingsOutlinedIcon />}
      />
      <CaseProgressItem
        complete={currentState > ProgressStatus.SHIPPING}
        inProgress={currentState === ProgressStatus.SHIPPING}
        pending={currentState < ProgressStatus.SHIPPING}
        icon={<FontAwesomeSvgIcon icon={faTruckFast} />}
      />
    </Stack>
  );
};
