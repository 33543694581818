import {
  IExtOrderInvoiceDtoForFilterView,
  IExtOrderInvoiceDtoWithItems,
  IOrderInvoiceCountDto,
} from "lib/dto";
import { IBaseResponse, IBaseResponseList } from "lib/model/IBaseResponse";
import { getUrlSearchParamsString } from "utils/urlHelpers";
import api from "./baseService";
import { IOutstandingBalanceModel } from "../lib/model/IOutstandingBalanceModel";
import { IStripeSession } from "../lib/model/IStripeSession";

class InvoiceService {
  private baseUrl: string = "order-invoices";
  public async fetch(
    filter: any
  ): Promise<IBaseResponseList<IExtOrderInvoiceDtoForFilterView>> {
    const { data } = await api.get<
      IBaseResponse<IBaseResponseList<IExtOrderInvoiceDtoForFilterView>>
    >(`${this.baseUrl}/filter?${getUrlSearchParamsString(filter)}`);
    return data.result;
  }
  public async get(id: number): Promise<IExtOrderInvoiceDtoWithItems> {
    const { data } = await api.get<IBaseResponse<IExtOrderInvoiceDtoWithItems>>(
      `${this.baseUrl}/${id}`
    );
    return data.result;
  }

  public async getTabsCount(): Promise<IOrderInvoiceCountDto> {
    const { data } = await api.get<IBaseResponse<IOrderInvoiceCountDto>>(
      `${this.baseUrl}/count`
    );
    return data.result;
  }

  public async getUnpaidInvoices(
    officeId?: number | string
  ): Promise<IExtOrderInvoiceDtoForFilterView[]> {
    const { data } = await api.get<
      IBaseResponse<IExtOrderInvoiceDtoForFilterView[]>
    >(`${this.baseUrl}/unpaid${officeId ? `?officeId=${officeId}` : ""}`);
    return data.result;
  }

  public async getInvoicesByOrderId(
    orderId?: number | string
  ): Promise<IExtOrderInvoiceDtoWithItems[]> {
    const { data } = await api.get<
      IBaseResponse<IExtOrderInvoiceDtoWithItems[]>
    >(`${this.baseUrl}/orders/${orderId}`);
    return data.result;
  }

  public async getOutstanding(
    officeId?: number | string
  ): Promise<IOutstandingBalanceModel> {
    const { data } = await api.get<IBaseResponse<IOutstandingBalanceModel>>(
      `${this.baseUrl}/outstanding${officeId ? `?officeId=${officeId}` : ""}`
    );
    return data.result;
  }

  public async getPaymentSession(): Promise<IStripeSession> {
    const { data } = await api.post<IBaseResponse<IStripeSession>>(
      `${this.baseUrl}/sessions`,
      {}
    );
    return data.result;
  }

  public async payAllInvoices(
    outstandingBalance: number,
    stripeSourceId: string,
    officeId: number
  ): Promise<void> {
    await api.post(`${this.baseUrl}/pay-all?officeId=${officeId}`, {
      outstandingBalance,
      stripeSourceId,
    });
  }
}

export const invoiceService = new InvoiceService();
