export enum TaskType {
  INVALID = "INVALID",
  ALIGNER_PRODUCTION = "ALIGNER_PRODUCTION",
  APPROVE_TREAT_PLAN = "APPROVE_TREAT_PLAN",
  MOLD_PREPARATION = "MOLD_PREPARATION",
  CASE_MARK_OUT = "CASE_MARK_OUT",
  BASE_PREP_ARTICULATE_PIN = "BASE_PREP_ARTICULATE_PIN",
  POLISH_ALIGNERS = "POLISH_ALIGNERS",
  PACKAGING = "PACKAGING",
  INVOICE = "INVOICE",
  ESTIMATE_TREAT_PLAN = "ESTIMATE_TREAT_PLAN",
  PHOTO_STL_EVALUATION = "PHOTO_STL_EVALUATION",
  THREED_SETUP = "THREED_SETUP",
  THREED_PRINTING = "THREED_PRINTING",
  APPROVE_TO_ALIGNER_PRODUCTION = "APPROVE_TO_ALIGNER_PRODUCTION",
  GENERAL = "GENERAL",
}
