export enum ShippingInfoStatus {
  INVALID_STATUS = "INVALID_STATUS",
  CREATED = "CREATED",
  IN_TRANSIT = "IN_TRANSIT",
  DELIVERED = "DELIVERED",
  EXCEPTION = "EXCEPTION",
  PICK_UP = "PICK_UP",
  MANIFEST_PICK_UP = "MANIFEST_PICK_UP",
  OTHER = "OTHER",
}
