import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { userSettingsService, shippingService } from "services";
import { Preferences } from "lib/enum";

import { UserPreferencesKey, PreferencesFetchModel } from "./types";
import { RootState } from "../store";

export const clearState = createAction("preference/state/clear");
export const setField = createAction<{
  fieldName: UserPreferencesKey;
  value: any;
}>("preference/field/set");

export const fetchInfo = createAsyncThunk<PreferencesFetchModel>(
  "preference/info/fetch",
  async (_, thunkApi) => {
    const {
      currentUser: { currentUser },
    } = thunkApi.getState() as RootState;
    const [prefs, shippingRate] = await Promise.all([
      userSettingsService.getPreferencesByUser(currentUser?.userId!),
      shippingService.getShippingsRate(),
    ]);
    const shippingLevelIsSame = shippingRate
      ? shippingRate.cheapestPackagingCode ===
          shippingRate.fastestPackagingCode &&
        shippingRate.cheapestServiceCode === shippingRate.fastestServiceCode
      : false;
    return {
      shippingRate,
      existsPreference: prefs,
      iprPreference:
        prefs[Preferences.IPR_PREFERENCES] !== null
          ? (prefs as any)[Preferences.IPR_PREFERENCES].value
          : "",
      attachPreference:
        prefs[Preferences.ATTACHMENTS_PREFERENCES] !== null
          ? (prefs as any)[Preferences.ATTACHMENTS_PREFERENCES].value
          : "",
      wearPreference:
        prefs[Preferences.PLANNED_WEAR_SCHEDULE] !== null
          ? (prefs as any)[Preferences.PLANNED_WEAR_SCHEDULE].value
          : "",
      additional:
        prefs[Preferences.ADDITIONAL_INFO] !== null
          ? (prefs as any)[Preferences.ADDITIONAL_INFO].value
          : "",
      customStep:
        prefs[Preferences.STEPS_NUMBER] !== null
          ? (prefs as any)[Preferences.STEPS_NUMBER].value
          : "",
      shippingLevel: prefs[Preferences.SHIPPING_LEVEL]
        ? (prefs as any)[Preferences.SHIPPING_LEVEL].value
        : shippingLevelIsSame
        ? "cheapest"
        : "",
    };
  }
);

export const saveHandler = createAsyncThunk(
  "preference/info/save",
  async (_, thunkAPI) => {
    const { userPreferences, currentUser } = thunkAPI.getState() as RootState;
    const userId = currentUser.currentUser!.userId;
    await userSettingsService.saveUserPreferences(
      {
        userId: !userPreferences.existsPreference ? userId : undefined,
        userSettings: [
          {
            userId,
            id:
              userPreferences.existsPreference &&
              userPreferences.existsPreference[Preferences.IPR_PREFERENCES]
                ? (userPreferences.existsPreference as any)[
                    Preferences.IPR_PREFERENCES
                  ].id
                : undefined,
            key: Preferences.IPR_PREFERENCES,
            value: userPreferences.iprPreference,
          },
          {
            userId,
            id:
              userPreferences.existsPreference &&
              userPreferences.existsPreference[
                Preferences.ATTACHMENTS_PREFERENCES
              ]
                ? (userPreferences.existsPreference as any)[
                    Preferences.ATTACHMENTS_PREFERENCES
                  ].id
                : undefined,
            key: Preferences.ATTACHMENTS_PREFERENCES,
            value: userPreferences.attachPreference,
          },
          {
            userId,
            id:
              userPreferences.existsPreference &&
              userPreferences.existsPreference[
                Preferences.PLANNED_WEAR_SCHEDULE
              ]
                ? (userPreferences.existsPreference as any)[
                    Preferences.PLANNED_WEAR_SCHEDULE
                  ].id
                : undefined,
            key: Preferences.PLANNED_WEAR_SCHEDULE,
            value: userPreferences.wearPreference,
          },
          {
            userId,
            id:
              userPreferences.existsPreference &&
              userPreferences.existsPreference[Preferences.ADDITIONAL_INFO]
                ? (userPreferences.existsPreference as any)[
                    Preferences.ADDITIONAL_INFO
                  ].id
                : undefined,
            key: Preferences.ADDITIONAL_INFO,
            value: userPreferences.additional,
          },
          {
            userId,
            id:
              userPreferences.existsPreference &&
              userPreferences.existsPreference[Preferences.STEPS_NUMBER]
                ? (userPreferences.existsPreference as any)[
                    Preferences.STEPS_NUMBER
                  ].id
                : undefined,
            key: Preferences.STEPS_NUMBER,
            value: userPreferences.customStep,
          },
          {
            userId,
            id:
              userPreferences.existsPreference &&
              userPreferences.existsPreference[Preferences.SHIPPING_LEVEL]
                ? (userPreferences.existsPreference as any)[
                    Preferences.SHIPPING_LEVEL
                  ].id
                : undefined,
            key: Preferences.SHIPPING_LEVEL,
            value: userPreferences.shippingLevel,
          },
        ],
      },
      !!userPreferences.existsPreference
    );
  }
);
