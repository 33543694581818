import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const WalletIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="16" viewBox="0 0 18 16" fill="none">
      <path
        d="M1 3.28571L1 13.5714C1 14.2026 1.51168 14.7143 2.14286 14.7143L15.8571 14.7143C16.4883 14.7143 17 14.2026 17 13.5714V4.42857C17 3.79739 16.4883 3.28571 15.8571 3.28571L3.85714 3.28571M1 3.28571V2.14286C1 1.51167 1.51168 1 2.14286 1H11.2857C11.9169 1 12.4286 1.51167 12.4286 2.14286V3.28571L3.85714 3.28571M1 3.28571L3.85714 3.28571M10.7143 10.1429H14.1429"
        stroke="currentColor"
        strokeWidth="1.4"
      />
    </SvgIcon>
  );
};
