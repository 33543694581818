import { PatientDetailsState } from "./types";
import {
  CaseStatuses,
  FileType,
  InvoiceStatuses,
  OrderActions,
  OrderStatuses,
  OrderTaskStatuses,
  TaskType,
} from "lib/enum";

export const getRequiredActions = (
  state: PatientDetailsState
): { type: OrderActions; targetId: number }[] => {
  const actions: { type: OrderActions; targetId: number }[] = [];
  const approveTreatPlanTask = state.orderTasks.find(
    ({ task, status }) =>
      task.type === TaskType.APPROVE_TREAT_PLAN &&
      status === OrderTaskStatuses.IN_PROGRESS
  );
  const tpApproved = state.orderTasks.find(
    ({ task, status }) =>
      task.type === TaskType.APPROVE_TREAT_PLAN &&
      (status === OrderTaskStatuses.DONE ||
        status === OrderTaskStatuses.SKIPPED)
  );
  const openInvoice = state.invoices.find(
    ({ status }) => status === InvoiceStatuses.OPEN
  );

  const requiredFieldsFilled =
    (state.caseInfo?.lowerArch || state.caseInfo?.upperArch) &&
    state.files.some(({ fileType }) => fileType === FileType.CLINICAL_PHOTO) &&
    ((state.caseInfo?.additionalInfo.extractionIPR &&
      state.caseInfo?.additionalInfo.include.length > 2 &&
      state.caseInfo?.additionalInfo.attachPrefer &&
      state.caseInfo?.additionalInfo.wearSchedule &&
      state.currentOrder?.alignersMaxQty) ||
      state.caseInfo?.needPreEvaluation);

  if (
    state.currentOrder?.status === OrderStatuses.DRAFT &&
    !requiredFieldsFilled &&
    state.caseInfo?.status === CaseStatuses.DRAFT
  ) {
    actions.push({
      type: OrderActions.CREATE_CONTINUATION,
      targetId: state.caseInfo?.id || 0,
    });
  }

  if (
    state.currentOrder?.status === OrderStatuses.DRAFT &&
    requiredFieldsFilled &&
    state.caseInfo?.status === CaseStatuses.DRAFT
  ) {
    actions.push({
      type: OrderActions.SUBMIT_TO_LAB,
      targetId: state.caseInfo?.id || 0,
    });
  }

  if (
    state.currentOrder?.status === OrderStatuses.DRAFT &&
    state.caseInfo?.status === CaseStatuses.PROCESSING
  ) {
    actions.push({
      type: OrderActions.COMPLETE_CONTINUE_ORDER,
      targetId: state.caseInfo?.id || 0,
    });
  }

  if (
    state.currentOrder?.status === OrderStatuses.HOLD &&
    !approveTreatPlanTask
  ) {
    actions.push({
      type: OrderActions.SCHEDULE_CONSULT,
      targetId: state.currentOrder?.id || 0,
    });
  }

  if (
    state.currentOrder?.status === OrderStatuses.RECEIVED_BY_DOCTOR &&
    state.caseInfo?.needPreEvaluation
  ) {
    actions.push({
      type: OrderActions.SUBMIT_PHOTO_EVALUATIONS,
      targetId: state.caseInfo?.id || 0,
    });
  }

  if (openInvoice) {
    actions.push({
      type: OrderActions.PAY_INVOICE,
      targetId: openInvoice.id,
    });
  }

  if (approveTreatPlanTask) {
    actions.push({
      type: OrderActions.APPROVE_TP,
      targetId: approveTreatPlanTask.id,
    });
  }

  if (
    Boolean(state.caseInfo?.externalCases?.DENTAL_MONITORING?.externalId) &&
    state.caseInfo?.patient.onMonitoring === null &&
    tpApproved
  ) {
    actions.push({
      type: OrderActions.DM_MONITORING,
      targetId: state.caseInfo?.id || 0,
    });
  }

  return actions;
};
