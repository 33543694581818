import {
  FontAwesomeSvgIcon,
  FontAwesomeSvgIconProps,
} from "components/common/FontAwesomeSvgIcon";
import { ImgContainer } from "./Elements";

export const NavMenuIcon = ({
  icon,
}: Pick<FontAwesomeSvgIconProps, "icon">) => {
  return (
    <ImgContainer>
      <FontAwesomeSvgIcon
        icon={icon}
        sx={{ color: "currentColor" }}
        fontSize="small"
      />
    </ImgContainer>
  );
};
