import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Colors } from "consts/colors";

export const EmptyBlock = () => {
  return (
    <Stack
      sx={{
        padding: 4.5,
        borderRadius: "4px",
        backgroundColor: Colors.PRIMARY,
        width: "max-content",
      }}
      spacing={1}
    >
      <Typography variant="body1" color="white">
        You’re all caught up!
      </Typography>
      <Typography variant="body2" color={Colors.TERTIARY}>
        When something else requires your attention we will let you know!
      </Typography>
    </Stack>
  );
};
