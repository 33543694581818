import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const UpperArch = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 4C21 2.89 20.11 2 19 2H5C3.89 2 3.01 2.89 3.01 4L3 7C3 8.11 3.89 9 5 9C5 10.11 5.89 11 7 11H17C18.11 11 19 10.11 19 9C20.11 9 21 8.11 21 7V4ZM17 13C18.11 13 19 13.89 19 15C20.11 15 21 15.89 21 17V20C21 21.11 20.11 22 19 22H5C3.89 22 3 21.11 3 20L3.01 17C3.01 15.89 3.89 15 5 15H5.01C5.01 13.89 5.89 13 7 13H17ZM7 16C7 15.5 7.45 15 8 15H16C16.55 15 17 15.5 17 16C17 16.5 17.5 17 18 17C18.5 17 19 17.45 19 18V19C19 19.55 18.55 20 18 20H6C5.45 20 5 19.55 5 19V18C5 17.45 5.5 17 6 17C6.5 17 7 16.5 7 16Z"
      />
    </SvgIcon>
  );
};
