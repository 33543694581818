import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import { Button } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions, selectors } from "store/patientDetail";

import { InnerFields } from "./InnerFields";

export const SubmitDM = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showErrorNotice, showSuccessNotice } = useStyledSnackbar();
  const open = useAppSelector((state) => state.patientCard.showDM);
  const tpApproveTask = useAppSelector(selectors.tpApproveTask);
  const dispatch = useAppDispatch();

  const dismissHandler = () => {
    dispatch(actions.dismissDM());
    if (tpApproveTask) {
      dispatch(actions.approveTp(tpApproveTask.id)).catch((error) => {
        showErrorNotice(error.message);
      });
    }
  };
  const submitDMHandler = () => {
    setLoading(true);
    dispatch(actions.submitPatientToDM())
      .unwrap()
      .then((approved) => {
        if (approved) {
          showSuccessNotice("Treatment plan successfully approved.");
        }
      })
      .catch((error) => {
        showErrorNotice(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Add Dental Monitoring</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please note, while this enrolls your patient into the Dental
            Monitoring system, you must also select ‘START MONITORING’ at the
            time they first receive their scan box.
          </Typography>
          <InnerFields />
        </DialogContent>
        <DialogActions>
          <Button onClick={dismissHandler} variant="outlined">
            Dismiss
          </Button>
          <Button
            onClick={submitDMHandler}
            loading={loading}
            variant="contained"
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
