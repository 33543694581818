import React from "react";
import Stack from "@mui/material/Stack";

import { FormLabel, RadioSelect } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/caseCreate";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Colors } from "consts/colors";

import { ArchesStepContainer } from "containers";

interface ArchesStepProps {
  isRetainer?: boolean;
}

const yesNoOptions = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const ArchesStep: React.FC<ArchesStepProps> = ({
  isRetainer = false,
}) => {
  const arches = useAppSelector(selectors.getArchesSelector);
  const order = useAppSelector(selectors.getAllInfoSelector);
  const dispatch = useAppDispatch();

  const handleClick = (name: "upperArch" | "lowerArch") => () => {
    dispatch(actions.toggleArch(name));
  };

  const removeAttachmentChangeHandler = (value: string) => {
    const booleanValue = value === "true";
    dispatch(actions.setRemoveAttachment(booleanValue));
  };

  return (
    <Stack spacing={1}>
      <ArchesStepContainer
        upper={arches.upper}
        lower={arches.lower}
        onClickLower={handleClick("lowerArch")}
        onClickUpper={handleClick("upperArch")}
      />
      {order.requiredFields.includes("arches") && (
        <Box>
          <Typography variant="body1" color={Colors.ERROR}>
            Please select arches
          </Typography>
        </Box>
      )}
      {isRetainer && (
        <FormLabel label="Do we need to remove the attachment?">
          <Box ml={1.5}>
            <RadioSelect
              value={order.removeAttachment ? "true" : "false"}
              options={yesNoOptions}
              onChange={(_, value) => removeAttachmentChangeHandler(value)}
            />
          </Box>
        </FormLabel>
      )}
    </Stack>
  );
};
