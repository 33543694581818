import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { Colors } from "consts/colors";
import { LowerArch, UpperArch } from "assets/vectorIcon";
import { NumberContainer, HeadItem, HeadContainer } from "./TableElements";
import Box from "@mui/material/Box";

const generateToothNumber = (offset: number) => {
  return Array(8)
    .fill(null)
    .map((_, index) => (
      <HeadItem key={`head_${Math.abs(index + offset)}`}>
        <Typography variant="body2" color={Colors.LABEL_GREY}>
          {Math.abs(index + offset)}
        </Typography>
      </HeadItem>
    ));
};
export const TableHeader = () => {
  return (
    <HeadContainer>
      <Box>
        <Grid container spacing={1.5} mb={1.5}>
          <Grid item sm={6}>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <UpperArch fontSize="small" color="disabled" />
              <Typography
                variant="body1"
                fontWeight={500}
                color={Colors.TEXT_PRIMARY}
              >
                Upper
              </Typography>
            </Stack>
          </Grid>
          <Grid item sm={6}>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <LowerArch fontSize="small" color="disabled" />
              <Typography
                variant="body1"
                fontWeight={500}
                color={Colors.TEXT_PRIMARY}
              >
                Lower
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={1.5}>
          <Grid item container sm={6} spacing={1}>
            <Grid item sm={6}>
              <NumberContainer>{generateToothNumber(1)}</NumberContainer>
            </Grid>
            <Grid item sm={6}>
              <NumberContainer>{generateToothNumber(9)}</NumberContainer>
            </Grid>
          </Grid>
          <Grid item container sm={6} spacing={1}>
            <Grid item sm={6}>
              <NumberContainer>{generateToothNumber(-32)}</NumberContainer>
            </Grid>
            <Grid item sm={6}>
              <NumberContainer>{generateToothNumber(-24)}</NumberContainer>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </HeadContainer>
  );
};
