import { styled } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";

export const StyledTab = styled(Tab)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 0 0 4px;
  min-height: 32px;
  margin-right: 32px;
  text-transform: none;
`;

export const StyledTabBar = styled(Tabs)`
  min-height: 32px;
  margin-inline: 10px;
  margin-top: 32px;
`;

export const ContentWrapper = styled("div")`
  margin-inline: 10px;
  padding-top: 24px;
  min-height: 96px;
  box-sizing: border-box;
`;

export const InfoContainer = styled("div")`
  padding-block: 6px;
  padding-inline: 12px;
  border-radius: 5px;
  min-height: 72px;
  box-sizing: border-box;
  width: 70%;
  margin-inline: auto;
  background-color: var(--container-border-grey-color);
`;

export const ShippingItemContainer = styled(Stack)`
  border: 1px solid var(--tertiary-text-color);
  box-sizing: border-box;
  border-radius: 4px;
  padding-inline: 16px;
  padding-block: 24px;
`;
