import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors } from "store/currentUser";
import { actions } from "store/caseCreate";
import { MainContainer } from "containers";
import { analyticsMethod } from "plugins/analytics";

import { OptionsContainer } from "./OptionsContainer";
import { UserRoles } from "lib/enum/userRoles";
import { DoctorSelector } from "./DoctorSelector";
import { someInclude } from "utils/helpers";
import { StaffSelector } from "./StaffSelector";

export const CreateOrderPage = () => {
  analyticsMethod(
    "page",
    "Orders",
    {
      title: "Create form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(selectors.permissionsSelector);
  const { currentUser } = useAppSelector(selectors.getAllUserInfo);

  useEffect(() => {
    dispatch(actions.setDoctorId(""));
    dispatch(
      actions.setOfficeId({
        officeId: "",
        showDM: false,
        dmEnabled: false,
      })
    );
  }, [dispatch]);
  return (
    <MainContainer>
      <Stack
        px={4}
        pt={8}
        pb={5}
        sx={{ height: "100%", boxSizing: "border-box" }}
      >
        <Typography variant="h1" color={Colors.PRIMARY}>
          Create an Order
        </Typography>
        {permissions.includes(UserRoles.ASSISTANT_USER) &&
        !currentUser?.doctorMode ? (
          <DoctorSelector />
        ) : someInclude(permissions, [
            UserRoles.DOCTOR_BASIC_USER,
            UserRoles.DOCTOR_USER,
          ]) ? (
          <StaffSelector />
        ) : null}
        <OptionsContainer />
      </Stack>
    </MainContainer>
  );
};
