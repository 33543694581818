import { IExtEventDashboardDto } from "lib/dto";
import { IBaseResponse } from "lib/model/IBaseResponse";
import api from "./baseService";

class DashboardService {
  private baseUrl: string = "dashboard";
  public async fetch(): Promise<IExtEventDashboardDto[]> {
    const { data } = await api.get<IBaseResponse<IExtEventDashboardDto[]>>(
      `${this.baseUrl}`
    );
    return data.result;
  }
  public async pinEvent(dashboardEventId: number): Promise<void> {
    await api.put(`${this.baseUrl}/pinned`, {
      dashboardEventId,
    });
  }
  public async doneEvent(dashboardEventId: number): Promise<void> {
    await api.put(`${this.baseUrl}/${dashboardEventId}/done`, {});
  }
  public async remindLaterEvent(
    dashboardEventId: number,
    daysCount: number
  ): Promise<void> {
    await api.put(`${this.baseUrl}/show-from`, {
      dashboardEventId,
      daysCount,
    });
  }
}

export const dashboardService = new DashboardService();
