import React from "react";
import Stack from "@mui/material/Stack";

import { TpInstructions } from "lib/enum";
import { ICellIcon } from "./types";
import { styled } from "@mui/styles";

const fieldNameType: Record<TpInstructions, string> = {
  rotation: "Rotation",
  mesial: "Ipr Mesial",
  distal: "Ipr Distal",
  torque: "Torque",
  fullBodyMovement: "Full body movement",
  attachment: "Attachment",
  intrusion: "Intrusion",
  extrusion: "Extrusion",
};

export const TooltipIcon = styled("div")({
  display: "flex",
  height: 40,
  width: 40,
  alignItems: "center",
  justifyContent: "center",
  background: "white",
  border: "1px solid #ECECEC",
  borderRadius: "4px",
});
export const TooltipText = styled("div")({
  color: "white",
  padding: "0px 8px",
  fontSize: "12px",
});
export const TooltipCell = ({ icons }: { icons: ICellIcon[] }) => {
  return icons.length ? (
    <>
      {icons.map(({ icon, field }, index) => {
        return (
          <Stack
            mb={1}
            key={`index_${index}_${field}`}
            alignItems="center"
            direction="row"
          >
            <TooltipIcon>{icon}</TooltipIcon>
            <TooltipText>{fieldNameType[field]}</TooltipText>
          </Stack>
        );
      })}
    </>
  ) : null;
};
