import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { Colors } from "consts/colors";

interface Props {
  label: string;
  text: string | null | undefined;
  isLoading: boolean;
}
export const InfoItem: React.VFC<Props> = (props) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant="body2" color={Colors.TEXT_SECONDARY}>
        {props.label.toUpperCase()}
      </Typography>
      <Typography
        variant="button"
        sx={{ textTransform: "none" }}
        color={Colors.TEXT_PRIMARY}
      >
        {props.isLoading ? <Skeleton animation="wave" /> : props.text || "-"}
      </Typography>
    </Stack>
  );
};
