import api from "./baseService";
import { IProductTypesDto } from "lib/dto";
import { IBaseResponse } from "../lib/model";

class ProductTypesService {
  private baseUrl = "price-lists/product-types";
  public async getAll(): Promise<IProductTypesDto[]> {
    const { data } = await api.get<IBaseResponse<IProductTypesDto[]>>(
      this.baseUrl
    );
    return data.result;
  }

  public async get(id: number): Promise<IProductTypesDto> {
    const { data } = await api.get<IBaseResponse<IProductTypesDto>>(
      `${this.baseUrl}/${id}`
    );
    return data.result;
  }
}

export const productTypesService = new ProductTypesService();
