import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "components/ui";
import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions, selectors } from "store/continuationOrderCreate";

import { CreateShippingModal } from "./CreateShippingModal";
import { useConfirm } from "hooks/confirm";
import React, { useState } from "react";

export const Footer = () => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectors.getAllInfoSelector);
  const { caseId } = useParams<{ caseId: string }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const { showErrorNotice } = useStyledSnackbar();
  const requiredFieldsFilled = useAppSelector(selectors.requiredFieldsFilled);
  const { orderId, orderItems, shippingLabel } = useAppSelector(
    selectors.getAllInfoSelector
  );

  const closeHandler = () => {
    setOpen(false);
  };
  const openHandler = () => {
    if (orderId) {
      dispatch(actions.updateOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          setOpen(true);
          if (filesSaveError) {
            showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
          }
        })
        .catch((error) => {
          showErrorNotice(error.message);
        });
    } else {
      dispatch(actions.createOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          setOpen(true);
          if (filesSaveError) {
            showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
          }
        })
        .catch((error) => {
          showErrorNotice(error.message);
        });
    }
  };
  const submitHandler = () => {
    if (!requiredFieldsFilled) {
      dispatch(actions.setRequired());
      return;
    }
    const submitOrderToLab = () => {
      dispatch(actions.submitOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          if (!filesSaveError) {
            navigate(`/cases/${caseId}`, { replace: true });
          } else {
            showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
          }
        })
        .catch((e) => showErrorNotice(e.message));
    };
    if (orderItems.IMPRESSION && !shippingLabel) {
      confirm({
        title: "Did you forget your shipping label?",
        titleProps: {
          sx: { color: Colors.PRIMARY, textAlign: "center" },
        },
        dialogProps: {
          maxWidth: "sm",
          disableEscapeKeyDown: false,
        },
        actionProps: {
          sx: {
            justifyContent: "space-between",
            padding: 3,
          },
        },
        confirmationText: "No, just submit my Order",
        cancellationText: "Creates shipping label",
        cancellationButtonProps: {
          variant: "contained",
          color: "primary",
          sx: { textTransform: "none" },
        },
        confirmationButtonProps: {
          variant: "contained",
          color: "primary",
          sx: { textTransform: "none" },
        },
        allowClose: false,
      })
        .then(submitOrderToLab)
        .catch(openHandler);
    } else {
      submitOrderToLab();
    }
  };
  const saveHandler = () => {
    if (!requiredFieldsFilled) {
      dispatch(actions.setRequired());
      return;
    }
    dispatch(!orderId ? actions.createOrder() : actions.updateOrder())
      .unwrap()
      .then(({ filesSaveError }) => {
        if (filesSaveError) {
          showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
        }
      })
      .catch((e) => showErrorNotice(e.message));
  };
  const goBackHandler = () => {
    navigate(`/cases/${caseId}`, { replace: true });
  };
  return (
    <>
      <CreateShippingModal open={open} onClose={closeHandler} />
      <Stack direction="row" justifyContent="space-between" mt={10}>
        <Stack direction="row" spacing={3}>
          <Button
            data-intercom-target="Save button"
            onClick={saveHandler}
            fatButton
            variant="outlined"
          >
            {Boolean(orderId) ? "Save" : "Save as draft"}
          </Button>
          <Button
            fatButton
            sx={{ color: Colors.DISABLED, textTransform: "none" }}
            onClick={goBackHandler}
          >
            Cancel
          </Button>
        </Stack>
        <Stack direction="row" spacing={3}>
          {Boolean(order.orderItems.IMPRESSION && !order.shippingLabel) && (
            <Button
              data-intercom-target="Create shipping label button"
              onClick={openHandler}
              fatButton
              variant="outlined"
            >
              Create shipping label
            </Button>
          )}
          <Button
            data-intercom-target="Submit order button"
            onClick={submitHandler}
            fatButton
            variant="contained"
          >
            Submit order
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
