import { IExtFileDto } from "lib/dto";
import { dateToString } from "utils";

export interface State {
  dateFilter: string;
  orderFilter: string;
  typeFilter: string;
  personFilter: string;
}

export const filterFiles = (
  files: (IExtFileDto & { src?: string })[],
  filters: State
): (IExtFileDto & { src?: string })[] => {
  if (!Object.values(filters).filter((value) => Boolean(value)).length) {
    return files;
  }
  return files.filter(({ orderId, created, fromType, fileType }) => {
    if (
      filters.orderFilter &&
      (!orderId || !~filters.orderFilter.indexOf(orderId?.toString()))
    ) {
      return false;
    }
    if (
      filters.dateFilter &&
      !~filters.dateFilter.indexOf(dateToString(created))
    ) {
      return false;
    }

    if (
      filters.typeFilter &&
      (!fileType || !~filters.typeFilter.indexOf(fileType))
    ) {
      return false;
    }

    return !filters.personFilter || ~filters.personFilter.indexOf(fromType);
  });
};

export const openPdfInNewTab = (src: string) => {
  window.open(src);
};
