import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "components/common";
import {
  CreateOrderPage,
  NewPatientOrderPage,
  NewPatientEvalOrderPage,
  NewPatientPreEvalOrderPage,
  NewPatientOrderRetainerPage,
  CreateContinuationOrderPage,
  ContinueCaseCreatingPage,
  OrderRedirectPage,
  OrderAlignersCreatePage,
  CompleteContinuationOrderPage,
} from "pages";

export const OrdersRoute = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <CreateOrderPage />
          </ProtectedRoute>
        }
      />
      <Route
        path=":orderId"
        element={
          <ProtectedRoute>
            <OrderRedirectPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="case/continuation/:orderId"
        element={
          <ProtectedRoute>
            <ContinueCaseCreatingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="new/eval"
        element={
          <ProtectedRoute>
            <NewPatientEvalOrderPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="new/pre-eval"
        element={
          <ProtectedRoute>
            <NewPatientPreEvalOrderPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="new"
        element={
          <ProtectedRoute>
            <NewPatientOrderPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="new/retainer"
        element={
          <ProtectedRoute>
            <NewPatientOrderRetainerPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="continuation/:caseId"
        element={
          <ProtectedRoute>
            <CreateContinuationOrderPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="continuation/complete/:caseId"
        element={
          <ProtectedRoute>
            <CompleteContinuationOrderPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="aligners/new/:caseId"
        element={
          <ProtectedRoute>
            <OrderAlignersCreatePage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
