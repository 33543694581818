import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const UpperComplete = ({ sx, ...props }: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 19" fill="none" {...props} sx={{ fill: "none" }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5.66665C14 4.92665 13.4067 4.33331 12.6667 4.33331H3.33333C2.59333 4.33331 2.00667 4.92665 2.00667 5.66665L2 7.66665C2 8.40665 2.59333 8.99998 3.33333 8.99998C3.33333 9.73998 3.92667 10.3333 4.66667 10.3333H11.3333C12.0733 10.3333 12.6667 9.73998 12.6667 8.99998C13.4067 8.99998 14 8.40665 14 7.66665V5.66665ZM11.3333 11.6666C12.0733 11.6666 12.6667 12.26 12.6667 13C13.4067 13 14 13.5933 14 14.3333V16.3333C14 17.0733 13.4067 17.6666 12.6667 17.6666H3.33333C2.59333 17.6666 2 17.0733 2 16.3333L2.00667 14.3333C2.00667 13.5933 2.59333 13 3.33333 13H3.34C3.34 12.26 3.92667 11.6666 4.66667 11.6666H11.3333ZM4.66667 13.6666C4.66667 13.3333 4.96667 13 5.33333 13H10.6667C11.0333 13 11.3333 13.3333 11.3333 13.6666C11.3333 14 11.6667 14.3333 12 14.3333C12.3333 14.3333 12.6667 14.6333 12.6667 15V15.6666C12.6667 16.0333 12.3667 16.3333 12 16.3333H4C3.63333 16.3333 3.33333 16.0333 3.33333 15.6666V15C3.33333 14.6333 3.66667 14.3333 4 14.3333C4.33333 14.3333 4.66667 14 4.66667 13.6666Z"
        fill="#888888"
      />
      <circle cx="13" cy="5" r="4.5" fill="#5CBD7B" stroke="white" />
      <path
        d="M16.6666 3L13 6.66667L11.3333 5"
        stroke="black"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6666 3L13 6.66667L11.3333 5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
