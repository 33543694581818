import React from "react";
import Box from "@mui/material/Box";

import { Tabs } from "components/ui";
import { ShippingLabel } from "./ShippingLabel";
import { EncloseWithOrder } from "./EncloseWithOrder";
import { SpecialInstructions } from "./SpecialInstructions";
import { Invoices } from "./Invoices";

export const AdditionalInfo = () => {
  return (
    <Box mt={4} pb={2.25}>
      <Tabs
        hideTab
        tabs={[
          {
            label: "Shipping label",
            tabId: "shipLabels",
            content: <ShippingLabel />,
          },
          {
            label: "Special Instructions",
            tabId: "specInstr",
            content: <SpecialInstructions />,
          },
          {
            label: "Enclosed with order",
            tabId: "enclosedItems",
            content: <EncloseWithOrder />,
          },
          {
            label: "Invoices",
            tabId: "invoices",
            content: <Invoices />,
          },
        ]}
      />
    </Box>
  );
};
