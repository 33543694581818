import Stack from "@mui/material/Stack";

import { useAppSelector } from "hooks/redux";
import { selectors } from "store/caseCreate";

import { PatientInfo } from "./PatientInfo";
import { DmInfo } from "./DmInfo";

export const PatientStep = () => {
  const caseInfo = useAppSelector(selectors.getAllInfoSelector);
  return (
    <Stack>
      {caseInfo.showDm && <DmInfo />}
      <PatientInfo />
    </Stack>
  );
};
