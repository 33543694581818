import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { Button } from "components/ui";
import { ShippingCreateContainer } from "containers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { selectors, actions } from "store/continuationOrderCreate";
import { Colors } from "consts/colors";

import printJS from "print-js";

interface Props {
  open: boolean;
  onClose: () => void;
}
export const CreateShippingModal: React.VFC<Props> = ({ open, onClose }) => {
  const order = useAppSelector(selectors.getAllInfoSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();

  const printHandler = () => {
    setLoading(true);
    dispatch(actions.createShippingForOrder())
      .unwrap()
      .then((shippingLabel) => {
        setLoading(false);
        printJS({
          printable: `data:image/jpeg;base64,${shippingLabel}`,
          type: "image",
        });
        onClose();
      })
      .catch((error) => {
        showErrorNotice(error.message);
        setLoading(false);
      });
  };

  if (!Boolean(order.orderItems.IMPRESSION) || order.shippingLabel) {
    return null;
  }
  return (
    <>
      <Dialog fullWidth open={open} maxWidth="lg">
        <DialogTitle color={Colors.PRIMARY}>Create Shipping Label</DialogTitle>
        <DialogContent>
          <ShippingCreateContainer orderId={order.orderId!} />
        </DialogContent>
        <DialogActions sx={{ paddingInline: 5, paddingBottom: 3 }}>
          <Button fatButton variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button
            onClick={printHandler}
            loading={loading}
            fatButton
            variant="contained"
          >
            Create shipping
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
