import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/createStepper";

import { StepTab, StepTabPanel } from "./components";
import { ContainerProps, StepContent } from "./types";
import { EmptyContainer } from "./components/Elements";

export const StepperContainer: React.VFC<ContainerProps> = (props) => {
  const { currentTab, subStep } = useAppSelector(selectors.getAllInfo);
  const dispatch = useAppDispatch();

  const currentStepInfo: StepContent =
    props.steps[currentTab].stepContent[subStep];

  const isSubStep = props.steps[currentTab].stepContent.length - 1 !== subStep;
  const isLastSubStep =
    props.steps[currentTab].stepContent.length - 1 >= subStep && subStep > 0;

  const setNext = () => {
    if (
      !currentStepInfo.nextStepCheckHandler ||
      currentStepInfo.nextStepCheckHandler!()
    ) {
      dispatch(actions.incrementTab());
    }
  };
  const selectTabHandler = (index: number) => () => {
    dispatch(actions.setTab(index));
  };
  const setPrevious = () => {
    dispatch(actions.decrementTab());
  };
  const setSubNext = () => {
    if (
      !currentStepInfo.nextStepCheckHandler ||
      currentStepInfo.nextStepCheckHandler!()
    ) {
      dispatch(actions.incrementSubStep());
    }
  };
  const setSubPrevious = () => {
    dispatch(actions.decrementSubStep());
  };

  useEffect(() => {
    return () => {
      dispatch(actions.clearState());
    };
  }, [dispatch]);
  if (!props.steps.length) {
    return null;
  }
  return (
    <Box mx={4} height="100%">
      <Tabs
        value={currentTab}
        TabIndicatorProps={{
          style: { backgroundColor: Colors.SUCCESS },
        }}
      >
        {props.steps.map((step, index) => (
          <StepTab
            disabled={index > currentTab && !step.stepDone}
            onClick={selectTabHandler(index)}
            key={step.id}
            label={step.label}
            requiredStepFields={step.requiredStepFields}
            requiredStepFieldsDone={step.requiredStepFieldsDone}
            stepDone={step.stepDone}
            isActive={currentTab === index}
            index={index}
          />
        ))}
        <EmptyContainer className="intercom-new-patient-tab" />
      </Tabs>
      <StepTabPanel
        onNext={
          currentTab !== props.steps.length - 1
            ? isSubStep
              ? setSubNext
              : setNext
            : undefined
        }
        onPrevious={
          currentTab !== 0
            ? isLastSubStep
              ? setSubPrevious
              : setPrevious
            : undefined
        }
        stepRightAction={props.steps[currentTab].stepRightAction}
        stepComplete={props.steps[currentTab].stepDone}
        title={currentStepInfo.title}
        stepLeftAction={props.steps[currentTab].stepLeftAction}
      >
        {currentStepInfo.component}
      </StepTabPanel>
    </Box>
  );
};
