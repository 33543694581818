import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MainContainer } from "containers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions, selectors } from "store/userPreferences";
import { analyticsMethod } from "plugins/analytics";

import {
  PreferencesTabs,
  Title,
  ContentContainer,
  ButtonsContainer,
  Wrapper,
} from "./components";
import { Button } from "components/ui";
import { Box } from "@mui/material";

const PreferencesPage = () => {
  analyticsMethod(
    "page",
    "Account settings",
    {
      title: "Edit form",
      url: window.location.href,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );
  const dispatch = useAppDispatch();
  const { isSaving } = useAppSelector(selectors.getAllSelector);
  const navigate = useNavigate();
  const { showErrorNotice } = useStyledSnackbar();
  const closeHandler = () => {
    navigate(-1);
  };
  const submitHandler = () => {
    dispatch(actions.saveHandler())
      .unwrap()
      .catch((e) => {
        showErrorNotice(e.message);
      });
  };
  useEffect(() => {
    dispatch(actions.fetchInfo())
      .unwrap()
      .catch((e) => {
        showErrorNotice(e.message);
      });
  }, [dispatch, showErrorNotice]);
  return (
    <MainContainer>
      <Wrapper>
        <Title variant="h1">Preferences</Title>
        <ContentContainer>
          <PreferencesTabs />
        </ContentContainer>
        <Box sx={{ p: 2 }}>
          <div className="intercom-preference" />
        </Box>

        <ButtonsContainer
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          flexGrow={1}
        >
          <Button onClick={closeHandler} fatButton variant="outlined">
            Close
          </Button>
          <Button
            onClick={submitHandler}
            loading={isSaving}
            fatButton
            variant="contained"
          >
            Save
          </Button>
        </ButtonsContainer>
      </Wrapper>
    </MainContainer>
  );
};

export default PreferencesPage;
