import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { TextField } from "../TextField";
import { ISelectOption, ISelect } from "./types";
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "./consts";

export function AutocompleteSelect<T extends number | string>({
  data,
  onOpen,
  disabled,
  onChange,
  placeholder,
  value,
  ...props
}: ISelect<T>) {
  const currentElem = data.find(
    ({ value: elemValue }) => elemValue.toString() === (value as any).toString()
  );
  const changeHandler = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption<T> | null
  ) => {
    if (onChange) {
      onChange(
        {
          ...event,
          //@ts-ignore
          target: {
            ...event.target,
            value: value?.value || "",
          },
        },
        null
      );
    }
  };

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      value={currentElem || null}
      options={data}
      onChange={changeHandler}
      disabled={disabled}
      style={{ maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP }}
      getOptionLabel={(option) => option.label}
      filterOptions={createFilterOptions<ISelectOption<T>>({
        matchFrom: "start",
        stringify: (option) => option.label,
      })}
      className={props.className}
      renderOption={({ key, ...props }: any, option) => {
        return (
          <Box component="li" key={option.value} {...props}>
            {option.label}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          helperText={props.helperText}
          style={{ width: "100%", margin: 0 }}
          margin={props.margin}
          error={props.error}
          placeholder={placeholder}
          required={props.required}
          {...params}
          label={props.label}
          sx={props.sx}
          variant={props.variant as any}
        />
      )}
    />
  );
}
