import { TxPlanComplexityEnum, TxPlanComplexity } from "lib/enum";

export const txPlanComplexitySelect = [
  {
    label: TxPlanComplexity[TxPlanComplexityEnum.SIMPLE],
    value: TxPlanComplexityEnum.SIMPLE,
  },
  {
    label: TxPlanComplexity[TxPlanComplexityEnum.MODERATE],
    value: TxPlanComplexityEnum.MODERATE,
  },
  {
    label: TxPlanComplexity[TxPlanComplexityEnum.COMPLEX],
    value: TxPlanComplexityEnum.COMPLEX,
  },
];
