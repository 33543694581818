export enum OrderActions {
  SUBMIT_TO_LAB = "SUBMIT_TO_LAB",
  COMPLETE_CONTINUE_ORDER = "COMPLETE_CONTINUE_ORDER",
  APPROVE_TP = "APPROVE_TP",
  SUBMIT_CONTINUATION = "SUBMIT_CONTINUATION",
  CREATE_CONTINUATION = "CREATE_CONTINUATION",
  SUBMIT_PHOTO_EVALUATIONS = "SUBMIT_PHOTO_EVALUATIONS",
  START_WEARING = "START_WEARING",
  SUBMIT_ALIGNER_ORDER = "SUBMIT_ALIGNER_ORDER",
  SCHEDULE_CONSULT = "SCHEDULE_CONSULT",
  PAY_INVOICE = "PAY_INVOICE",
  DM_MONITORING = "DM_MONITORING",
}
