import React from "react";
import { FileTypes } from "lib/types";
import VideocamIcon from "@mui/icons-material/Videocam";
import LayersIcon from "@mui/icons-material/Layers";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import Skeleton from "@mui/material/Skeleton";
interface Props {
  fileType: FileTypes;
  src?: string | Blob;
}
export const PreviewIcon: React.VFC<Props> = (props) => {
  const sx = { color: "white", fontSize: "48px", cursor: "pointer" };
  switch (props.fileType) {
    case "image":
      return (
        <div
          className="file-card__preview-img"
          style={{
            backgroundImage: `url(${props.src as string})`,
          }}
        />
      );
    case "video":
      return (
        <div className="file-card__preview">
          <VideocamIcon sx={sx} />
        </div>
      );
    case "model":
      return (
        <div className="file-card__preview">
          <LayersIcon sx={sx} />
        </div>
      );
    case "pdf":
      return (
        <div className="file-card__preview">
          <LaptopMacIcon sx={sx} />
        </div>
      );
    case "application":
      return (
        <div className="file-card__preview">
          <LaptopMacIcon sx={sx} />
        </div>
      );
    case "text":
      return (
        <div className="file-card__preview">
          <TextFieldsIcon sx={sx} />
        </div>
      );
  }
  return (
    <Skeleton
      sx={{ cursor: "pointer" }}
      variant="rectangular"
      animation={"wave"}
      width={57}
      height={46}
    />
  );
};
