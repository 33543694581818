import React from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/caseCreate";

import { ComplicationsArchContainer } from "containers/complicationsArch";

export const ComplicationsArch = () => {
  const dispatch = useAppDispatch();
  const arches = useAppSelector(selectors.getArchesSelector);
  const initComplications = useAppSelector(selectors.getComplicationsSelector);

  const buttonClickHandler = (toothNumber: number, value: string | null) => {
    dispatch(
      actions.setComplications({
        toothNumber,
        value,
      })
    );
  };
  return (
    <>
      <ComplicationsArchContainer
        initComplications={initComplications}
        onClick={buttonClickHandler}
        lowerArch={arches.lower}
        upperArch={arches.upper}
      />
    </>
  );
};
