import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { InfoWithLabel } from "components/common";
import { Colors } from "consts/colors";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/patientDetail";
import { dateToString } from "utils";
import { Tooltip } from "@mui/material";
import { QuestionIcon } from "assets/vectorIcon";

const PRE_EVAL_ORDER_TYPE = 1;
export const GeneralInfo = () => {
  const { isLoading, currentOrder } = useAppSelector(selectors.currentOrder);
  const { showRating } = useAppSelector(selectors.allInfoSelector);
  const canShowEstShipDate = useAppSelector(selectors.showEstShipDate);
  const steps = useAppSelector(selectors.getCurrentOrderSteps);
  const { caseEvents, requiredActions } = useAppSelector(
    selectors.caseNoticeSelector
  );
  const fontSize =
    caseEvents.length > 0 || requiredActions.length || showRating ? 14 : 16;
  const productName =
    currentOrder?.template?.isPreEvaluation ||
    currentOrder?.type?.id === PRE_EVAL_ORDER_TYPE
      ? "Pre-evaluation"
      : currentOrder?.productionType
      ? "Aligner"
      : "Retainer";

  return (
    <div className="active-order__general-info" style={{ fontSize }}>
      <InfoWithLabel
        label="ORDER ID"
        labelStyleSx={{ fontSize: "0.75em" }}
        textStyleSx={{ fontSize: "1em" }}
        text={currentOrder?.id.toString()}
        isLoading={isLoading}
        direction="column"
      />
      <InfoWithLabel
        label="OFFICE"
        labelStyleSx={{ fontSize: "0.75em" }}
        textStyleSx={{ fontSize: "1em" }}
        text={currentOrder?.office?.name ?? "-"}
        isLoading={isLoading}
        direction="column"
      />
      <InfoWithLabel
        label="START DATE"
        labelStyleSx={{ fontSize: "0.75em" }}
        textStyleSx={{ fontSize: "1em" }}
        text={dateToString(currentOrder?.created || "")}
        isLoading={isLoading}
        direction="column"
      />
      <InfoWithLabel
        label="TYPE"
        labelStyleSx={{ fontSize: "0.75em" }}
        textStyleSx={{ fontSize: "1em" }}
        text={productName}
        isLoading={isLoading}
        direction="column"
      />
      <InfoWithLabel
        label={
          canShowEstShipDate ? (
            "ESTIMATED SHIP DATE"
          ) : (
            <Tooltip
              arrow
              placement="top"
              title="The date will be available after approving treatment plan"
            >
              <span>
                ESTIMATED SHIP DATE{" "}
                <QuestionIcon sx={{ width: "16px", height: "16px" }} />
              </span>
            </Tooltip>
          )
        }
        labelStyleSx={{ fontSize: "0.75em" }}
        textStyleSx={{ fontSize: "1em" }}
        text={
          canShowEstShipDate
            ? dateToString(currentOrder?.dueDate || "")
            : "--/--/-- "
        }
        isLoading={isLoading}
        direction="column"
      />
      <InfoWithLabel
        label="UPPER/LOWER"
        labelStyleSx={{ fontSize: "0.75em" }}
        textStyleSx={{ fontSize: "1em" }}
        text={`${
          steps.upper ? `${steps.upper.from}-${steps.upper.till}` : "-"
        }/${steps.lower ? `${steps.lower.from}-${steps.lower.till}` : "-"}`}
        isLoading={isLoading}
        direction="column"
      />
      <InfoWithLabel
        label="STEPS INCLUDED"
        labelStyleSx={{ fontSize: "0.75em", mt: 1.5 }}
        textStyleSx={{ fontSize: "1em" }}
        isLoading={isLoading}
        direction="column"
      >
        {steps.estSteps ? (
          <Stack direction="row" spacing={0.5} alignItems="baseline">
            <Typography
              sx={{ fontWeight: 500, fontSize: "1em" }}
              variant="body1"
              color={Colors.TEXT_GREY}
            >
              {`${steps.startSteps}-${steps.endSteps}`}
            </Typography>
            <Typography
              sx={{ fontWeight: 500, fontSize: "1em" }}
              variant="body1"
              color={Colors.DISABLED}
            >
              of
            </Typography>
            <Typography
              sx={{ fontWeight: 500, fontSize: "1em" }}
              variant="body1"
              color={Colors.TEXT_GREY}
            >
              {steps.estSteps}
            </Typography>
          </Stack>
        ) : (
          <Typography
            sx={{ fontWeight: 500, fontSize: "1em" }}
            variant="body1"
            color={Colors.TEXT_GREY}
          >
            -
          </Typography>
        )}
      </InfoWithLabel>
    </div>
  );
};
