import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { MainContainer } from "containers";
import { actions } from "store/treatmentPlan";
import { useAppDispatch } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { Header, StepsTable, FullHeightContainer } from "./components";

export const TreatmentPlanPage = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const { plainId } = useParams<{ plainId: string }>();
  useEffect(() => {
    dispatch(actions.fetchCurrentVersion(Number(plainId)))
      .unwrap()
      .catch((error) => showErrorNotice(error.message));
  }, [plainId, showErrorNotice, dispatch]);
  return (
    <MainContainer>
      <FullHeightContainer>
        <Header />
        <StepsTable />
      </FullHeightContainer>
    </MainContainer>
  );
};
