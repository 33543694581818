import { Nullable } from "lib/types";
import {
  IExtOrderInvoiceDtoWithItems,
  IExtOrderWithOrgAndOfficeDto,
  IPaymentDto,
} from "lib/dto";

export interface InvoicesCardState {
  invoice: Nullable<IExtOrderInvoiceDtoWithItems>;
  order: Nullable<IExtOrderWithOrgAndOfficeDto>;
  payment: Nullable<IPaymentDto>;
  isLoading: boolean;
}

export enum InvoicesCardActions {
  INVOICE_CARD_GET = "invoiceCard/get",
}
