import { InvoiceStatuses } from "../enum";

export const invoiceStatuses: Record<InvoiceStatuses, string> = {
  DRAFT: "Draft",
  PRE_DRAFT: "Pre Draft",
  OPEN: "Open",
  PAID: "Paid",
  VOID: "Void",
  UNCOLLECTIBLE: "Uncollectible",
  UNPAID: "Unpaid",
};
