import React from "react";
import { PaperContainer } from "./Elements";
import Grid from "@mui/material/Grid";
import { FormLabel, Select } from "components/ui";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { shippingPreferences } from "lib/dictionaries";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/userPreferences";
import { InfoWithLabel } from "components/common";
import { Colors } from "consts/colors";
import Typography from "@mui/material/Typography";

export const ShippingPreferences = () => {
  const { isLoading, isSaving, shippingLevel } = useAppSelector(
    selectors.getAllSelector
  );
  const { packageType, upsService } = useAppSelector(
    selectors.getShippingPreferences
  );
  const shippingsIsSame = useAppSelector(selectors.shippingPrefISSame);
  const dispatch = useAppDispatch();
  const selectChangeHandler = (event: SelectChangeEvent<string>) => {
    dispatch(
      actions.setField({
        fieldName: event.target.name as any,
        value: event.target.value,
      })
    );
  };
  return (
    <PaperContainer>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <FormLabel label="Shipping level">
              {isLoading ? (
                <Skeleton height={40} animation="wave" />
              ) : (
                <Select
                  disabled={isSaving}
                  fullWidth
                  data={shippingPreferences}
                  placeholder="Select preference"
                  value={shippingLevel}
                  name="shippingLevel"
                  onChange={selectChangeHandler}
                />
              )}
            </FormLabel>
          </Grid>
        </Grid>
      </Box>
      <Stack direction="row" mt={1.5} spacing={3} alignItems="baseline">
        <InfoWithLabel
          isLoading={isLoading}
          labelStyleSx={{ fontSize: 10, color: Colors.TEXT_PRIMARY }}
          textStyleSx={{ fontSize: 10, color: Colors.TEXT_PRIMARY }}
          label="UPS SERVICE:"
          text={upsService}
        />
        <InfoWithLabel
          isLoading={isLoading}
          labelStyleSx={{ fontSize: 10, color: Colors.TEXT_PRIMARY }}
          textStyleSx={{ fontSize: 10, color: Colors.TEXT_PRIMARY }}
          label="PACKAGE TYPES:"
          text={packageType}
        />
        {shippingsIsSame && (
          <Typography variant="body2" fontSize={10} color={Colors.TEXT_PRIMARY}>
            For your address, the shipping parameters are the same for the
            "Shipping level" values: "Fastest" and "Cheapest"
          </Typography>
        )}
      </Stack>
    </PaperContainer>
  );
};
