import { useAppSelector } from "hooks/redux";
import { ListTable } from "components/ui";
import { ListTableColumn } from "components/ui/listTable/types";
import { toLocalCurrency } from "utils";
import { Wrapper } from "./Elements";
import { IInvoiceItemDto } from "lib/dto";
import { useProductTypes } from "hooks/productTypes";

export const ItemsTableInvoice = () => {
  const invoice = useAppSelector((state) => state.invoicesCard.invoice);
  const loading = useAppSelector((state) => state.invoicesCard.isLoading);
  const { dataMap: productTypesDataMap } = useProductTypes();
  const columns: ListTableColumn<IInvoiceItemDto>[] = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Item",
      dataIndex: "productTypeId",
      render: (productTypeId: number) => {
        return productTypesDataMap.get(productTypeId)?.name;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Unit price",
      dataIndex: "unitAmount",
      render: (amount: number) => (amount ? toLocalCurrency(amount) : null),
    },
    {
      title: "Qty",
      dataIndex: "qty",
    },
    {
      title: "Price",
      dataIndex: "amount",
      render: (amount: number) => (amount ? toLocalCurrency(amount) : null),
    },
  ];
  return (
    <Wrapper>
      <ListTable columns={columns} data={invoice?.items} loading={loading} />
    </Wrapper>
  );
};
