import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions } from "store/requestRemake";

export const FileSendQuestion = () => {
  const dispatch = useAppDispatch();
  const needToSend = useAppSelector((state) =>
    state.requestRemake.needToSendFiles ? "yes" : "no"
  );
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setFilesNeed(event.target.value === "yes"));
  };
  return (
    <Stack>
      <Typography variant="body1">
        Do you need to send files or physicals samples?
      </Typography>
      <RadioGroup row onChange={changeHandler} value={needToSend}>
        <FormControlLabel
          componentsProps={{
            typography: { variant: "body2" },
          }}
          value="yes"
          control={<Radio />}
          label="Yes"
        />
        <FormControlLabel
          componentsProps={{
            typography: { variant: "body2" },
          }}
          value="no"
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
    </Stack>
  );
};
