import React from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "consts/colors";

interface Props {
  anchorEl: SVGGElement | null;
  value: string;
  onChange: (value: string) => void;
  onClose: () => void;
}
export const ExtractionType: React.FC<Props> = ({
  anchorEl,
  value,
  onChange,
  onClose,
}) => {
  const changeHandler = (event: any) => {
    onChange(event.target.value === value ? null : event.target.value);
  };
  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="right-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Stack alignItems="flex-end" pl={2} pb={1}>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>

              <RadioGroup value={value}>
                <FormControlLabel
                  value={Colors.DISABLED}
                  control={
                    <Radio
                      onClick={changeHandler}
                      sx={{ padding: 0.5 }}
                      size="small"
                    />
                  }
                  label="Extracted"
                />
                <FormControlLabel
                  value={Colors.TOOTH_WHITE}
                  control={
                    <Radio
                      onClick={changeHandler}
                      sx={{ padding: 0.5 }}
                      size="small"
                    />
                  }
                  label="Planned"
                />
              </RadioGroup>
            </Stack>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
