import React from "react";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { ISelect } from "./types";
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "./consts";
import { Colors } from "consts/colors";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const DefaultSelect = <T extends number | string>({
  variant = "outlined",
  margin = "none",
  size = "small",
  data,
  placeholder,
  displayEmpty,
  sx,
  helperText,
  ...props
}: ISelect<T>) => {
  const MenuProps = {
    ...props.MenuProps,
    PaperProps: {
      ...props.MenuProps?.PaperProps,
      style: {
        ...props.MenuProps?.PaperProps?.style,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  return (
    <Stack>
      <MuiSelect
        displayEmpty={!!placeholder || displayEmpty}
        margin={margin}
        variant={variant}
        size={size}
        error={props.error}
        MenuProps={MenuProps}
        sx={{
          "& .MuiSelect-select": {
            color: props.value ? "rgba(0, 0, 0, 0.87)" : Colors.DISABLED,
          },
          "& fieldset": {
            borderColor: Colors.SECONDARY,
          },
          ...sx,
        }}
        {...props}
      >
        {placeholder && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}
        {data.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {Boolean(helperText) && (
        <Box px={1.75}>
          <Typography variant="body1" color={Colors.ERROR}>
            {helperText}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
