import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const TuneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        d="M8.43601 5.21976C8.38215 5.70622 7.96902 6.08492 7.46853 6.08492C6.96788 6.08492 6.55508 5.7059 6.50104 5.21976H0.546702C0.48695 5.21976 0.438515 5.17132 0.438515 5.11157C0.438515 5.05182 0.48695 5.00339 0.546702 5.00339H6.50104C6.55505 4.51692 6.96819 4.13822 7.46853 4.13822C7.96932 4.13822 8.38213 4.51725 8.43601 5.00339H9.19905C9.2588 5.00339 9.30724 5.05182 9.30724 5.11157C9.30724 5.17132 9.2588 5.21976 9.19905 5.21976H8.43601ZM7.46853 4.35444C7.05101 4.35444 6.71152 4.69378 6.71152 5.1116C6.71152 5.52912 7.05071 5.86877 7.46853 5.86877C7.88604 5.86877 8.22569 5.52942 8.22569 5.1116C8.22569 4.69408 7.88635 4.35444 7.46853 4.35444ZM3.24467 1.32617C3.19066 1.81263 2.77752 2.19133 2.27719 2.19133C1.77639 2.19133 1.36358 1.81231 1.3097 1.32617H0.546663C0.486911 1.32617 0.438477 1.27773 0.438477 1.21798C0.438477 1.15823 0.486911 1.10979 0.546663 1.10979H1.3097C1.36356 0.623327 1.7767 0.244629 2.27719 0.244629C2.77783 0.244629 3.19064 0.623656 3.24467 1.10979H9.19901C9.25876 1.10979 9.3072 1.15823 9.3072 1.21798C9.3072 1.27773 9.25876 1.32617 9.19901 1.32617H3.24467ZM2.27719 0.461002C1.85967 0.461002 1.52002 0.800192 1.52002 1.21801C1.52002 1.63553 1.85937 1.97518 2.27719 1.97518C2.69471 1.97518 3.0342 1.63583 3.0342 1.21801C3.0342 0.800493 2.69501 0.461002 2.27719 0.461002ZM5.19139 9.11343C5.13753 9.59989 4.72439 9.97859 4.22391 9.97859C3.72326 9.97859 3.31046 9.59957 3.25642 9.11343H0.546663C0.486911 9.11343 0.438477 9.06499 0.438477 9.00524C0.438477 8.94549 0.486911 8.89705 0.546663 8.89705H3.25642C3.31043 8.41059 3.72357 8.03189 4.22391 8.03189C4.7247 8.03189 5.13751 8.41092 5.19139 8.89705H9.19893C9.25869 8.89705 9.30712 8.94549 9.30712 9.00524C9.30712 9.06499 9.25869 9.11343 9.19893 9.11343H5.19139ZM4.22391 8.24811C3.80639 8.24811 3.46689 8.58745 3.46689 9.00527C3.46689 9.42279 3.80609 9.76228 4.22391 9.76228C4.64142 9.76228 4.98107 9.42309 4.98107 9.00527C4.98107 8.58775 4.64173 8.24811 4.22391 8.24811Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
