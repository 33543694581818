import React, { useState } from "react";
import printJS from "print-js";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions, selectors } from "store/shipping";
import { StyledButton } from "./Elements";

export const Footer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { orderId } = useParams<{ orderId: string }>();
  const disableButton = useAppSelector(selectors.disableButtonSelector);
  const navigate = useNavigate();
  const { showErrorNotice } = useStyledSnackbar();
  const dispatch = useAppDispatch();
  const goBackHandler = () => {
    navigate(-1);
  };
  const printHandler = () => {
    setLoading(true);
    dispatch(actions.printShippingLabel(Number(orderId)))
      .unwrap()
      .then((shippingLabel) => {
        setLoading(false);
        printJS({
          printable: `data:image/jpeg;base64,${shippingLabel}`,
          type: "image",
        });
        goBackHandler();
      })
      .catch((error) => {
        showErrorNotice(error.message);
        setLoading(false);
      });
  };
  return (
    <Stack
      sx={{
        marginInline: "40px 48px",
        mb: 5,
        flexGrow: 1,
      }}
      justifyContent="flex-end"
    >
      <Stack direction="row" justifyContent="space-between">
        <StyledButton onClick={goBackHandler} variant="outlined">
          Close
        </StyledButton>
        <StyledButton
          disabled={disableButton}
          variant="contained"
          onClick={printHandler}
          startIcon={
            loading ? (
              <CircularProgress sx={{ color: "white" }} size={20} />
            ) : undefined
          }
        >
          Create shipping
        </StyledButton>
      </Stack>
    </Stack>
  );
};
