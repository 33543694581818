declare global {
  interface Window {
    analytics: any;
    smartlook: any;
  }
}

export const analytics = () => {
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error("Segment snippet included twice.");
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    "trackSubmit",
    "trackClick",
    "trackLink",
    "trackForm",
    "pageview",
    "identify",
    "reset",
    "group",
    "track",
    "ready",
    "alias",
    "debug",
    "page",
    "once",
    "off",
    "on",
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method: string) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key: string, options: any) {
    // Create an async script element based on your key.
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";

    // Insert our script next to the first script element.
    const first: any = document.getElementsByTagName("script")[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = "4.1.0";

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(process.env.REACT_APP_SEGMENT_IO_KEY);
};

export const smartlookSetConsent = (confirmation: () => Promise<void>) => {
  if (window.smartlook) {
    const consentData = localStorage.getItem("smartlook.consent.data");
    if (consentData) {
      const consentObj: {
        ips: string;
        emails: string;
      } = JSON.parse(consentData);
      if (consentObj.ips && consentObj.emails) {
        window.smartlook("record", {
          forms: true,
          emails: true,
          ips: true,
          numbers: true,
          api: true,
        });
      } else {
        window.smartlook("record", {
          forms: false,
          emails: false,
          ips: false,
          numbers: false,
          api: false,
        });
      }
    } else {
      confirmation()
        .then(() => {
          localStorage.setItem(
            "smartlook.consent.data",
            JSON.stringify({ ips: true, emails: true })
          );
          window.smartlook("record", {
            forms: true,
            emails: true,
            ips: true,
            numbers: true,
            api: true,
          });
        })
        .catch(() => {
          localStorage.setItem(
            "smartlook.consent.data",
            JSON.stringify({ ips: false, emails: false })
          );
          window.smartlook("record", {
            forms: false,
            emails: false,
            ips: false,
            numbers: false,
            api: false,
          });
        });
    }
  }
};

export const analyticsMethod = (method: string, ...params: any[]) => {
  if (window.analytics) {
    window.analytics[method](...params);
  }
};
export const getAnalyticsUserId = () => {
  if (window.analytics && window.analytics.user) {
    return window.analytics.user().id();
  }
  return "";
};

export default analytics;
