import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { TextField } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/patientDetail";
import { Colors } from "consts/colors";
import { DialogStyledButton } from "./Elements";

export const WearSchedulingModal = () => {
  const { openScheduling, caseInfo } = useAppSelector(
    selectors.allInfoSelector
  );
  const { lower, upper } = useAppSelector(selectors.getProducedStepsSelector);
  const dispatch = useAppDispatch();
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [loading, setLoading] = React.useState<boolean>(false);

  const patientName = `${caseInfo?.patient.firstName} ${caseInfo?.patient.lastName}`;
  const validDate = !date || isNaN(date?.getTime()) ? new Date() : date;

  const submitHandler = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(actions.updateWearingStart(date?.toISOString() || ""))
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  };
  const setDateHandler = (data: Date | null) => {
    setDate(data);
  };
  const clearHandler = () => {
    setDate(null);
  };
  const closeHandler = () => {
    dispatch(actions.setOpenScheduling(false));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 3,
            border: `1px solid ${Colors.TEXT_GREY}`,
          },
        }}
        open={openScheduling && Boolean(caseInfo)}
        maxWidth="md"
      >
        <DialogTitle color={Colors.PRIMARY}>
          Have you fit these aligners?
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 4 }}>
          <Stack direction="row" spacing={3}>
            <Stack>
              <Stack spacing={2}>
                <Typography variant="body1">
                  {`When do you expect ${patientName} to begin wearing steps Upper
                  ${upper} - Lower ${lower}?`}
                </Typography>
                <Typography variant="body1">
                  We’ll remind you when it’s time to place the next order.
                </Typography>
                <DatePicker
                  mask="__/__/____"
                  disabled={loading}
                  value={date}
                  onChange={setDateHandler}
                  renderInput={({ InputProps, sx, ...params }) => (
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <IconButton size="small" onClick={clearHandler}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                      sx={{ ...sx, maxWidth: 180 }}
                      {...params}
                    />
                  )}
                />
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                alignItems="flex-end"
                flexGrow={1}
              >
                <DialogStyledButton
                  disabled={!date}
                  loading={loading}
                  onClick={submitHandler}
                  variant="contained"
                >
                  Submit
                </DialogStyledButton>
                <DialogStyledButton
                  disabled={loading}
                  sx={{ width: 80 }}
                  variant="outlined"
                  onClick={closeHandler}
                >
                  Later
                </DialogStyledButton>
              </Stack>
            </Stack>
            <Box
              sx={{
                borderRadius: 3,
                border: `1px solid ${Colors.TEXT_GREY}`,
              }}
            >
              <CalendarPicker
                disabled={loading}
                date={validDate}
                onChange={setDateHandler}
              />
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
};
