import React, { useState } from "react";
import { PopupModal } from "react-calendly";
import { useAppSelector } from "hooks/redux";
import { selectors as userSelector } from "store/currentUser";

interface Props {
  children: JSX.Element;
  patient: string;
  discussTP: boolean;
  caseId: number | string;
}
export const CalendlyFrame: React.VFC<Props> = ({
  patient,
  caseId,
  children,
  discussTP,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { currentUser: doctorInfo } = useAppSelector(
    userSelector.getAllUserInfo
  );
  const rootElement = document.getElementById("root");
  if (!rootElement) {
    return null;
  }
  const prefill = {
    name: `${doctorInfo?.firstName || ""} ${doctorInfo?.lastName || ""}`,
    email: doctorInfo?.email || "",
    customAnswers: {
      a1: patient,
      a2: discussTP ? "1" : "0",
      a3: `${process.env.REACT_APP_HOST!}cases/${caseId || ""}`,
    },
  };
  const openHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };
  return (
    <>
      {React.cloneElement(children, {
        onClick: openHandler,
      })}
      <PopupModal
        url={process.env.REACT_APP_CALENDLY_LINK || ""}
        prefill={prefill}
        onModalClose={closeHandler}
        open={open}
        rootElement={document.getElementById("root")!}
      />
    </>
  );
};
