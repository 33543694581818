import React from "react";
interface Props {
  className?: string;
  style?: any;
  value: boolean;
  type: "uppers" | "lowers";
}

const Extrusion = ({ value, className, style, type }: Props) => {
  return value ? (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
      style={style}
    >
      <path
        d="M14.8132 32.533H11.6484V34.6758H15.3626V36H10V28H15.3516V29.3352H11.6484V31.2418H14.8132V32.533Z"
        fill="#5F5CE6"
      />
      <path
        d="M19.1923 30.7582L20.6923 28H22.5879L20.2582 31.967L22.6484 36H20.7308L19.1923 33.1978L17.6538 36H15.7363L18.1264 31.967L15.7967 28H17.6923L19.1923 30.7582Z"
        fill="#5F5CE6"
      />
      <path
        d="M29.5055 29.3352H27.0549V36H25.4066V29.3352H22.989V28H29.5055V29.3352Z"
        fill="#5F5CE6"
      />
      {type === "uppers" ? (
        <path
          d="M26.7059 15.7647L20.3529 25L14 15.7647H17.8118L20.3529 0L22.8941 15.7647H26.7059Z"
          fill="#5F5CE6"
        />
      ) : (
        <path
          d="M26.7059 10.2353L20.3529 0L14 10.2353H17.8118L20.3529 25L22.8941 10.2353H26.7059Z"
          fill="#5F5CE6"
        />
      )}
    </svg>
  ) : null;
};
export default Extrusion;
