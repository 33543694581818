import { createReducer } from "@reduxjs/toolkit";
import { SearchState } from "./types";
import { initState } from "./state";
import * as actions from "./actions";

export const reducer = createReducer<SearchState>(initState, (builder) => {
  builder
    .addCase(actions.setSearchValue, (state, action) => {
      state.searchValue = action.payload;
      if (action.payload) {
        state.searchData = initState.searchData;
        state.open = false;
      }
    })
    .addCase(actions.setSearchOpen, (state, action) => {
      state.open = action.payload;
    })
    .addCase(actions.clearState, () => {
      return initState;
    })
    .addCase(actions.getSearchResults.fulfilled, (state, action) => {
      state.searchData = {
        patients:
          action.payload.patients.totalCount > 0
            ? action.payload.patients
            : {
                data: action.payload.cases.data.map(
                  ({
                    panNumber,
                    patientFirstName,
                    patientLastName,
                    lastOrderStatus,
                    id,
                    ...other
                  }) => ({
                    caseId: id,
                    id,
                    lastOrderStatus,
                    panNumber,
                    lastOrderId: 0,
                    lastName: patientLastName,
                    firstName: patientFirstName,
                    ...other,
                  })
                ),
                totalCount: action.payload.cases.totalCount,
              },
        invoices: action.payload.invoices,
      };
      state.open = true;
      state.isLoading = false;
    })
    .addCase(actions.getSearchResults.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(actions.getSearchResults.rejected, (state) => {
      state.isLoading = false;
    });
});
