import React, { SyntheticEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Select, TextField, FormLabel } from "components/ui";
import { selectors, actions } from "store/caseCreate";
import { IPatient } from "lib/model";
import { Questions } from "lib/enum";
import { throttle } from "lodash";

import { genderDict } from "../../dicts";
import { usePatientConfirmation } from "../../patientConfirmationHook";
import { IPatientDto } from "lib/dto";
import { sendSegment } from "utils/analyticsMethod";
import { PhoneField } from "components/ui/PhoneField";

export const PatientInfo = () => {
  const [open, setOpen] = useState<string | undefined>();
  const patient = useAppSelector(selectors.getPatientSelector);
  const order = useAppSelector(selectors.getAllInfoSelector);
  const navigate = useNavigate();
  const confirm = usePatientConfirmation();
  const dispatch = useAppDispatch();
  const fetchPatients = useCallback(
    throttle(async (value: string) => {
      dispatch(actions.searchPatients(value));
    }, 1000),
    [dispatch]
  );
  const changeFirstNameHandler = (event: SyntheticEvent, value: string) => {
    dispatch(
      actions.setPatientFields({
        firstName: value,
      })
    );
    if (value) {
      fetchPatients(value);
    }
    setOpen("firstName");
  };
  const changeSecondNameHandler = (event: SyntheticEvent, value: string) => {
    dispatch(
      actions.setPatientFields({
        lastName: value,
      })
    );
    if (value) {
      fetchPatients(patient.firstName + " " + value);
    }
    setOpen("lastName");
  };
  const changeOptionHandler = (
    event: SyntheticEvent,
    value: IPatientDto | null
  ) => {
    if (value) {
      const patient = {
        firstName: value.firstName || "",
        lastName: value.lastName || "",
        email: value.email || "",
        gender: value.gender ? "1" : "0",
        birthDate: value.birthDate,
        phone: value.phone || "",
      };
      sendSegment("Existing Patient Selected", patient);
      dispatch(actions.setPatientFields(patient));

      confirm()
        .then(() => {
          navigate(`/cases/search?search-for=${value!.firstName}`);
        })
        .catch(() => {
          setOpen(undefined);
        });
    }
  };
  const blurHandler = () => {
    setOpen(undefined);
    dispatch(actions.clearPatients);
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setPatientFields({
        [event.target.name as keyof IPatient]: event.target.value,
      })
    );
  };
  const changeSelectHandler = (event: SelectChangeEvent<string>) => {
    dispatch(
      actions.setPatientFields({
        gender: event.target.value,
      })
    );
  };
  const changeDateHandler = (date: Date | null) => {
    dispatch(
      actions.setPatientFields({
        birthDate: date?.toISOString(),
      })
    );
  };

  const changePhoneHandler = (phone: string) => {
    dispatch(actions.setPatientFields({ phone }));
  };
  return (
    <>
      <Grid container spacing={3} sx={{ mt: 0 }}>
        <Grid item md={4}>
          <FormLabel label="First Name" required>
            <Autocomplete
              open={Boolean(patient.firstName) && open === "firstName"}
              onBlur={blurHandler}
              options={order.patients}
              inputValue={patient.firstName}
              getOptionLabel={(option) => option.firstName!}
              fullWidth
              includeInputInList
              filterSelectedOptions
              clearOnBlur={false}
              filterOptions={(options) => options}
              onInputChange={changeFirstNameHandler}
              onChange={changeOptionHandler}
              renderInput={(params) => (
                <TextField
                  data-intercom-target="Patient first name"
                  error={order.requiredFields.includes("patient.firstName")}
                  helperText={
                    order.requiredFields.includes("patient.firstName")
                      ? "Please fill this field"
                      : undefined
                  }
                  {...params}
                  placeholder="First Name"
                />
              )}
              renderOption={({ key, ...props }: any, option) => {
                return (
                  <Box component="li" key={option.id} {...props}>
                    {`${option.firstName} ${option.lastName}`}
                  </Box>
                );
              }}
            />
          </FormLabel>
        </Grid>
        <Grid item md={4}>
          <FormLabel label="Last Name" required>
            <Autocomplete
              open={Boolean(patient.lastName) && open === "lastName"}
              onBlur={blurHandler}
              inputValue={patient.lastName}
              options={order.patients}
              getOptionLabel={(option) => option.lastName!}
              filterOptions={(options) => options}
              fullWidth
              includeInputInList
              filterSelectedOptions
              clearOnBlur={false}
              onInputChange={changeSecondNameHandler}
              onChange={changeOptionHandler}
              renderOption={({ key, ...props }: any, option) => {
                return (
                  <Box component="li" key={option.id} {...props}>
                    {`${option.firstName} ${option.lastName}`}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  data-intercom-target="Patient last name"
                  error={order.requiredFields.includes("patient.lastName")}
                  helperText={
                    order.requiredFields.includes("patient.lastName")
                      ? "Please fill this field"
                      : undefined
                  }
                  {...params}
                  placeholder="Last Name"
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item md={2}>
          <FormLabel label="Gender">
            <Select
              data-intercom-target="Patient gender"
              placeholder="Gender"
              name="gender"
              onChange={changeSelectHandler}
              value={patient.gender}
              fullWidth
              data={genderDict}
            />
          </FormLabel>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 0 }}>
        <Grid item md={2}>
          <FormLabel
            label="Birth Date"
            required={order.dmEnabled === Questions.YES && !order.dmNumber}
          >
            <MobileDatePicker
              inputFormat="MM/dd/yyyy"
              value={patient.birthDate}
              disableFuture
              onChange={changeDateHandler}
              renderInput={({
                placeholder,
                fullWidth,
                error,
                helperText,
                ...params
              }) => (
                <TextField
                  data-intercom-target="Patient birth date"
                  error={order.requiredFields.includes("patient.birthDate")}
                  helperText={
                    order.requiredFields.includes("patient.birthDate")
                      ? "Please fill this field"
                      : undefined
                  }
                  placeholder="Birth Date"
                  fullWidth
                  {...params}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item md={6}>
          <FormLabel
            label="Patient Email"
            required={order.dmEnabled === Questions.YES && !order.dmNumber}
          >
            <TextField
              data-intercom-target="Patient email"
              placeholder="Patient Email"
              name="email"
              value={patient.email}
              error={order.requiredFields.includes("patient.email")}
              helperText={
                order.requiredFields.includes("patient.email")
                  ? "Please fill this field"
                  : undefined
              }
              onChange={changeHandler}
              fullWidth
            />
          </FormLabel>
        </Grid>
        <Grid item md={2}>
          <FormLabel
            label="Phone number"
            required={order.dmEnabled === Questions.YES && !order.dmNumber}
          >
            <PhoneField
              data-intercom-target="Patient phone"
              onChange={changePhoneHandler}
              error={order.requiredFields.includes("patient.phone")}
              helperText={
                order.requiredFields.includes("patient.phone")
                  ? "Please fill this field"
                  : undefined
              }
              value={patient.phone}
              fullWidth
            />
          </FormLabel>
        </Grid>
      </Grid>
    </>
  );
};
