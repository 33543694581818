import React from "react";
import MuiButton, { ButtonProps } from "@mui/material/Button";

export const FatButton: React.FC<ButtonProps> = ({
  disableElevation = true,
  ...props
}) => {
  return (
    <MuiButton
      disableElevation={disableElevation}
      sx={{ ...props.sx, textTransform: "none", height: 48, px: 2.5, py: 1.5 }}
      {...props}
    />
  );
};
