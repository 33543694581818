import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
export const StyledLink = styled(Link)`
  font-size: 12px;
  text-decoration: none;
  font-weight: 500;
  line-height: 24px;
  color: var(--primary-color);
`;

export const FullHeightContainer = styled(Stack)`
  height: 100%;
`;
