import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions, selectors } from "store/shipping";
import { selectors as patientSelectors } from "store/patientDetail";
import { InfoEditFields, ShipInfo } from "../../shippingCreate/components";

export const ShippingInfo = () => {
  const { lab, office } = useAppSelector(selectors.shippingInfoSelector);
  const { currentOrder } = useAppSelector(patientSelectors.currentOrder);
  const isLoading = useAppSelector(selectors.loadingSelector);
  const { showErrorNotice } = useStyledSnackbar();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.fetchShippingInfo(Number(currentOrder?.id!)))
      .unwrap()
      .catch((e) => showErrorNotice(e.message));
  }, [dispatch, currentOrder?.id, showErrorNotice]);
  return (
    <>
      <Box sx={{ marginInline: -2, mb: -2, mt: 2, width: "calc(100% - 80px)" }}>
        <InfoEditFields />
      </Box>
      <Stack spacing={4} px={3}>
        <ShipInfo title="Shipper info" info={office} isLoading={isLoading} />
        <ShipInfo title="Recipient info" info={lab} isLoading={isLoading} />
      </Stack>
    </>
  );
};
