import { IAuthData } from "lib/model";
import { userService } from "services";

export const withDoctorModeProcessing = async <T extends (...args: any) => any>(
  authDataFetcher: T,
  ...args: Array<Parameters<T>>
): Promise<IAuthData> => {
  const authData = await authDataFetcher(...args)
  const doctorMode = await userService.getDoctorModeInfo()

  if (!doctorMode) return authData

  const returnData = {
    ...authData,
    currentUser: {
      ...authData.currentUser,
      userId: doctorMode.userId,
      doctorMode,
      isAdmin: false,
    },
  }
  return returnData
    

}