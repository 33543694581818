import { IProductTypesDto } from "lib/dto";
import { useEffect, useState } from "react";
import { productTypesService } from "services";

enum productTypesEnum {
  ALIGNER,
  RETAINER,
  VERIFICATION_KEY,
  MATRIX,
  SETUP_FEE,
  SHIPPING,
  CUSTOM,
  ORIGIN_MOLD,
  DENTAL_MONITORING,
}
export const useProductTypes = () => {
  const [data, setData] = useState<IProductTypesDto[]>([]);
  const [dataMap, setDataMap] = useState<Map<number, IProductTypesDto>>(
    new Map()
  );

  const fetch = async () => {
    const data = await productTypesService.getAll();
    setData(data);
    setDataMap(
      new Map(
        data.map((obj: IProductTypesDto) => {
          return [obj.id, obj];
        })
      )
    );
    return data;
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    data,
    dataMap,
    fetch,
    productTypesIsEnum: data?.filter(({ isAppEnum }) => isAppEnum),
    enum: productTypesEnum,
  };
};
