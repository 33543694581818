import { FileType } from "../enum";

export const fileTypes: Record<FileType, string> = {
  [FileType.CLINICAL_PHOTO]: "Clinical",
  [FileType.COMMON]: "Common",
  [FileType.ORIGIN_MODEL]: "Model",
  [FileType.PRE_EVALUATION]: "Pre-Evaluaton",
  [FileType.TP_VIDEO]: "Pre-evaluation video",
  [FileType.INVALID]: "Invalid",
  [FileType.TREATMENT_PLAN]: "Treatment Plan",
  [FileType.VERIFICATION_KEY]: "Verification keys",
};
