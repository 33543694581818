import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import { FontAwesomeSvgIcon } from "components/common";
import { Colors } from "consts/colors";

export type ItemStatus = "complete" | "pending" | "inProgress";
interface Props {
  icon: React.ReactElement<SvgIconProps>;
  label: string;
  status: ItemStatus;
  isLoading: boolean;
  lastPoint?: boolean;
}

const statusLabel = {
  complete: "Complete",
  pending: "Pending",
  inProgress: "In progress",
};
const ProgressIcon = (props: Pick<Props, "status" | "icon">) => {
  switch (props.status) {
    case "complete":
      return (
        <Stack
          sx={{
            width: "2em",
            height: "2em",
            borderRadius: "50%",
            backgroundColor: Colors.SUCCESS,
            color: "white",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeSvgIcon sx={{ fontSize: "1.5em" }} icon={faCheck} />
        </Stack>
      );
    case "inProgress":
      return React.cloneElement(props.icon, {
        sx: {
          fontSize: "2.1875em",
        },
        color: "inherit",
      });
    default:
      return React.cloneElement(props.icon, {
        sx: {
          fontSize: "1.25em",
        },
        color: "inherit",
      });
  }
};

export const ProgressInfoItem: React.VFC<Props> = (props) => {
  if (props.isLoading) {
    return (
      <Stack className={"progress-info__item"} spacing={0.5}>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <div className="progress-info__item-icon">
            <Skeleton
              variant="circular"
              width="2em"
              height="2em"
              animation="wave"
            />
          </div>
          {!props.lastPoint && <span className={"progress-info__item-line"} />}
        </Stack>
        <Typography variant="body1" className="progress-info__item-text">
          <Skeleton animation="wave" width={110} />
        </Typography>
        <Typography variant="body2" className="progress-info__item-status">
          <Skeleton animation="wave" width={57} />
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack
      className={clsx("progress-info__item", {
        "progress-info__item_active": props.status === "inProgress",
        "progress-info__item_complete": props.status === "complete",
      })}
      spacing={0.5}
    >
      <Stack direction="row" spacing={1.5} alignItems="center">
        <div className="progress-info__item-icon">
          <ProgressIcon icon={props.icon} status={props.status} />
        </div>
        {!props.lastPoint && (
          <span
            className={clsx("progress-info__item-line", {
              "progress-info__item-line_complete": props.status === "complete",
            })}
          />
        )}
      </Stack>
      <Typography variant="body1" className="progress-info__item-text">
        {props.label}
      </Typography>
      <Typography variant="body2" className="progress-info__item-status">
        {statusLabel[props.status]}
      </Typography>
    </Stack>
  );
};
