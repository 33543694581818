import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { MainContainer } from "containers";
import { orderService } from "services";
import { useStyledSnackbar } from "hooks/notification";

export const OrderRedirectPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();
  const { showErrorNotice } = useStyledSnackbar();
  const [caseId, setCaseId] = useState<number | undefined>();
  useEffect(() => {
    if (!orderId) {
      navigate("/");
    } else {
      orderService
        .getOrderById(orderId)
        .then(({ caseId }) => setCaseId(caseId))
        .catch((e) => {
          showErrorNotice(e.message);
        });
    }
  }, [orderId, showErrorNotice, navigate]);
  if (caseId) {
    return <Navigate to={`/cases/${caseId}`} replace />;
  }
  return (
    <MainContainer>
      <Stack
        pt={2}
        px={3}
        pb={2}
        spacing={1}
        sx={{ height: "100%", boxSizing: "border-box" }}
      >
        <Stack spacing={0.75}>
          <Typography variant="body1" fontSize={18}>
            <Skeleton animation="wave" width={100} />
          </Typography>
          <Typography variant="h1" lineHeight="24px" fontSize={24}>
            <Skeleton animation="wave" width={380} />
          </Typography>
        </Stack>
        <Stack spacing={2} flexGrow={1}>
          <Box flexGrow={1}>
            <Skeleton height="100%" />
          </Box>
          <Box flexGrow={1}>
            <Skeleton height="100%" />
          </Box>
        </Stack>
      </Stack>
    </MainContainer>
  );
};
