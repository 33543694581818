import React from "react";
import Typography from "@mui/material/Typography";

import { GridContainer } from "./Elements";
import { Colors } from "consts/colors";

interface Props {
  title: string;
  text: string;
}
export const MessageInfoItem: React.VFC<Props> = ({ title, text }) => {
  return (
    <div>
      <GridContainer>
        <Typography
          fontWeight="bold"
          variant="body2"
          color={Colors.TEXT_SECONDARY}
        >
          {title}
        </Typography>
        <Typography fontWeight="bold" variant="body2">
          {text}
        </Typography>
      </GridContainer>
    </div>
  );
};
