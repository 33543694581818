import { FC, useEffect, useState } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { InformBlock, LoadingOverlay } from "components/common";
import { selectors, actions } from "store/caseCreate";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  StyledCenterGrid,
  StyledDivider,
  StyledGrid,
  StyledLeftGrid,
  StyledRightGrid,
  StyledTitle,
} from "./styled";
import { priceListService } from "services";
import { IPriceListDto } from "lib/dto";
import { toLocalCurrency } from "utils";

export const SelectPrice: FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPriceListDto[]>([]);
  const caseInfo = useAppSelector(selectors.getAllInfoSelector);
  const dispatch = useAppDispatch();

  const handleChange = (e: any) => {
    dispatch(actions.setProductTypeId(e.target.value));
  };

  const fetch = async () => {
    setLoading(true);
    try {
      const data = await priceListService.getPriceListEnabled();
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <LoadingOverlay text={"Loading price list"} open={loading} />

      <Grid container spacing={10}>
        <Grid item xs={7}>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={1} />
            <StyledLeftGrid item xs={2}>
              <StyledTitle>Package</StyledTitle>
            </StyledLeftGrid>
            <StyledRightGrid xs={3}>
              <StyledTitle>Price</StyledTitle>
            </StyledRightGrid>
            <StyledRightGrid xs={3}>
              <StyledTitle>Setup Fee</StyledTitle>
            </StyledRightGrid>
            <StyledRightGrid xs={3}>
              <StyledTitle>Description</StyledTitle>
            </StyledRightGrid>
          </Grid>
          {data
            .filter((priceList) => {
              return (
                priceList?.productTypeDto?.priceBookId ===
                (caseInfo?.caseInfo?.typeOfPrice?.id ??
                  caseInfo.selectedOffice?.typeOfPriceId ??
                  (caseInfo.selectedOffice as any)?.office?.typeOfPriceId)
              );
            })
            .map((priceList) => {
              return (
                <>
                  <StyledDivider />

                  <Grid container>
                    <StyledCenterGrid item xs={1}>
                      <Radio
                        value={priceList.productTypeId}
                        onChange={handleChange}
                        checked={
                          Number(caseInfo.productTypeId) ===
                          Number(priceList.productTypeId)
                        }
                      />
                    </StyledCenterGrid>
                    <StyledLeftGrid container item xs={2}>
                      {priceList?.productTypeDto?.packageType?.name ?? "-"}
                    </StyledLeftGrid>
                    <StyledRightGrid container xs={3}>
                      {priceList.price ? toLocalCurrency(priceList.price) : "-"}
                    </StyledRightGrid>
                    <StyledRightGrid item xs={3}>
                      {priceList?.productTypeDto?.packageType?.setupFee
                        ? toLocalCurrency(
                            priceList?.productTypeDto?.packageType.setupFee
                          )
                        : "-"}
                    </StyledRightGrid>
                    <StyledLeftGrid item xs={3}>
                      {priceList?.description}
                    </StyledLeftGrid>
                  </Grid>
                </>
              );
            })}
        </Grid>

        <Grid item xs={5} />
      </Grid>
    </>
  );
};
