import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui";
import { useConfirm } from "hooks/confirm";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectors, actions } from "store/caseCreate";
import { useStyledSnackbar } from "hooks/notification";
import { Colors } from "consts/colors";
import { ImpressionsWarningWindow } from "./ImpressionsWarningWindow";
import { CreateShippingModal } from "../CreateShippingModal";
import { sendSegment } from "utils/analyticsMethod";

export const SubmitOrderButton = () => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const submitWarning = useAppSelector(selectors.submitWarning);
  const { showErrorNotice } = useStyledSnackbar();
  const order = useAppSelector(selectors.getAllInfoSelector);
  const [open, setOpen] = useState<boolean>(false);
  const [openShipping, setOpenShipping] = useState<boolean>(false);

  const submitOrderToLab = () => {
    dispatch(actions.submitOrder())
      .unwrap()
      .then(({ caseId, filesSaveError }) => {
        if (filesSaveError) {
          showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
        } else {
          navigate(`/cases/${caseId}`);
        }
      })
      .catch((error) => showErrorNotice(error.message));
  };
  const openHandler = () => {
    const resultPromise = order.caseId
      ? dispatch(actions.updateCaseOrder()).unwrap()
      : dispatch(actions.createOrder()).unwrap();

    resultPromise
      .then(({ filesSaveError }) => {
        setOpenShipping(true);
        if (filesSaveError) {
          showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
        }
      })
      .catch((error) => {
        showErrorNotice(error.message);
      });
  };
  const submitHandler = () => {
    sendSegment("Existing Patient Selected", order.patient);
    if (submitWarning) {
      setOpen(true);
    } else if (order.orderItems.IMPRESSION && !order.shippingLabel) {
      confirm({
        title: "Did you forget your shipping label?",
        titleProps: {
          sx: { color: Colors.PRIMARY, textAlign: "center" },
        },
        dialogProps: {
          maxWidth: "sm",
          disableEscapeKeyDown: true,
        },
        actionProps: {
          sx: {
            justifyContent: "space-between",
            padding: 3,
          },
        },
        confirmationText: "No, just submit my Order",
        cancellationText: "Creates shipping label",
        cancellationButtonProps: {
          variant: "contained",
          color: "primary",
          sx: { textTransform: "none" },
        },
        confirmationButtonProps: {
          variant: "contained",
          color: "primary",
          sx: { textTransform: "none" },
        },
      })
        .then(submitOrderToLab)
        .catch(openHandler);
    } else {
      submitOrderToLab();
    }
  };
  return (
    <>
      <CreateShippingModal
        open={openShipping}
        onClose={() => setOpenShipping(false)}
      />
      <ImpressionsWarningWindow open={open} onClose={() => setOpen(false)} />
      <Button
        data-intercom-target="Submit order button"
        variant="contained"
        onClick={submitHandler}
      >
        Submit order
      </Button>
    </>
  );
};
