import { createSelector } from "@reduxjs/toolkit";
import { OrderCreateState } from "./types";
import { RootState } from "../store";

const selectSelf = (state: RootState) => state.newCase;

export const firstStepsRequiredFieldsComplete = createSelector(
  selectSelf,
  (state: OrderCreateState) => {
    return Object.entries(state.patient).reduce((requiredCount, field) => {
      if ((field[0] === "firstName" || field[0] === "lastName") && field[1]) {
        requiredCount++;
      }
      return requiredCount;
    }, 0);
  }
);

export const secondStepsRequiredFieldsComplete = createSelector(
  selectSelf,
  (state: OrderCreateState) => {
    let requiredField = 0;

    if (state.lowerArch || state.upperArch) {
      requiredField++;
    }

    if (!state.useMeditLink && state.clinicalPhotos.length > 0) {
      requiredField++;
    }

    if (state.preEval) {
      return requiredField;
    }

    if (state.productTypeId) {
      requiredField++;
    }

    if (state.includes.length > 0) {
      requiredField++;
    }

    if (state.ipr) {
      requiredField++;
    }
    if (state.attachments) {
      requiredField++;
    }
    return requiredField;
  }
);
