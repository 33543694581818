import React from "react";
import Stack from "@mui/material/Stack";
import { Colors } from "consts/colors";
import Typography from "@mui/material/Typography";

export const ExceptionPreview = () => {
  return (
    <Stack
      direction="row"
      spacing={2}
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography sx={{ color: Colors.SECONDARY }} variant="h2">
        Cannot download file for preview.
      </Typography>
    </Stack>
  );
};
