import {
  IExtTreatmentPlanStepDto,
  IExtToothNoteDto,
  IToothDto,
  IExtTreatmentPlanDto,
} from "lib/dto";
import { StepInstructions, NoteCells, TpSteps } from "./types";
import { initObjInstruction } from "./initState";

export const getToothNumber = (
  archType: "uppers" | "lowers",
  index: number,
  increment?: number
): number => {
  const incrementCount = increment ?? 0;
  if (archType === "lowers") {
    return Math.abs(index + incrementCount - 32);
  }
  return index + incrementCount;
};

export const checkSteps = (steps: IExtTreatmentPlanStepDto[]) => {
  let localStep: any[] = [];
  if (steps) {
    localStep = steps.map((step: IExtTreatmentPlanStepDto) => {
      const instructions = step.instructions.length
        ? step.instructions.map((instruction, index): StepInstructions => {
            return instruction
              ? {
                  ...instruction,
                  toothNumber: instruction.toothNumber,
                }
              : {
                  ...initObjInstruction,
                  treatmentPlanStepId: step.id,
                  toothNumber: step.type === "LOWER" ? index + 17 : index + 1,
                };
          })
        : Array(16)
            .fill(null)
            .map((_, index: number) => ({
              ...initObjInstruction,
              treatmentPlanStepId: step.id,
              toothNumber: step.type === "LOWER" ? index + 17 : index + 1,
            }));
      return {
        ...step,
        instructions,
      };
    });
  }
  return localStep;
};

export const generateCellsForNote = (notes: IExtToothNoteDto[]): NoteCells => {
  const noteCells: NoteCells = {};
  notes
    .sort(({ noteDto: { id: idA } }, { noteDto: { id: idB } }) => idB - idA)
    .forEach(({ toothDtoList, noteDto }) => {
      const { stepNumber: minStep, toothNumber: minTooth } = toothDtoList[0];
      const { stepNumber: maxStep, toothNumber: maxTooth } =
        toothDtoList[toothDtoList.length - 1];
      toothDtoList.forEach(({ toothNumber, stepNumber }) => {
        const cell = {
          isFirst: toothNumber === minTooth && stepNumber === minStep,
          bTop: stepNumber === minStep,
          bBottom: stepNumber === maxStep,
          bLeft: toothNumber === minTooth,
          bRight: toothNumber === maxTooth,
          noteId: noteDto.id,
        };
        if (noteCells[stepNumber]) {
          if (noteCells[stepNumber][toothNumber]) {
            noteCells[stepNumber][toothNumber].push(cell);
          } else {
            noteCells[stepNumber][toothNumber] = [cell];
          }
        } else {
          noteCells[stepNumber] = {
            [toothNumber]: [cell],
          };
        }
      });
    });
  return noteCells;
};

export const calculateStepsHandler = (
  tpInfo: IExtTreatmentPlanDto
): TpSteps[] => {
  const { orders, unassignedUpperSteps, unassignedLowerSteps } = tpInfo;
  let firstUnassignedSteps: {
    autoGenerated: boolean;
    upperSteps: any[];
    lowerSteps: any[];
  }[] = [];
  const autoGeneratedStepsExist =
    unassignedUpperSteps.some(({ autogenerated }: any) => autogenerated) ||
    unassignedLowerSteps.some(({ autogenerated }: any) => autogenerated);
  if (autoGeneratedStepsExist) {
    firstUnassignedSteps = [
      {
        autoGenerated: true,
        upperSteps: checkSteps(
          unassignedUpperSteps.filter(({ autogenerated }: any) => autogenerated)
        ),
        lowerSteps: checkSteps(
          unassignedLowerSteps.filter(({ autogenerated }: any) => autogenerated)
        ),
      },
    ];
  }
  const upperNotAutogenerated = checkSteps(
    unassignedUpperSteps.filter(({ autogenerated }: any) => !autogenerated)
  );
  const lowerNotAutogenerated = checkSteps(
    unassignedLowerSteps.filter(({ autogenerated }: any) => !autogenerated)
  );

  const preparedOrders = orders
    .filter((order: any) =>
      Boolean(order.upperSteps?.length || order.lowerSteps?.length)
    )
    .map((order: any) => ({
      ...order,
      upperSteps: checkSteps(
        order.upperSteps.filter(({ aligner }: any) => aligner)
      ),
      lowerSteps: checkSteps(
        order.lowerSteps.filter(({ aligner }: any) => aligner)
      ),
    }));

  let stepsBefore: any[] = [];
  let ordersLastNum = 0;
  preparedOrders.forEach(({ upperSteps, lowerSteps }) => {
    const ordersMinStep = lowerSteps[0]?.number || upperSteps[0]?.number || 0;
    const lowerStepsBefore = lowerNotAutogenerated.filter(
      ({ number }) => number < ordersMinStep && number > ordersLastNum
    );
    const upperStepsBefore = upperNotAutogenerated.filter(
      ({ number }) => number < ordersMinStep && number > ordersLastNum
    );
    if (lowerStepsBefore.length || upperStepsBefore.length) {
      stepsBefore.push({
        upperSteps: upperStepsBefore,
        lowerSteps: lowerStepsBefore,
      });
      ordersLastNum = ordersMinStep;
    }
  });
  const calculatedSteps = [
    ...firstUnassignedSteps,
    ...stepsBefore,
    ...preparedOrders,
    {
      upperSteps: upperNotAutogenerated.filter(
        ({ number }) => number >= ordersLastNum
      ),
      lowerSteps: lowerNotAutogenerated.filter(
        ({ number }) => number >= ordersLastNum
      ),
    },
  ];
  calculatedSteps.sort((a, b) => {
    const minA = Math.min(
      a.lowerSteps[0]?.number || 100,
      a.upperSteps[0]?.number || 100
    );
    const minB = Math.min(
      b.lowerSteps[0]?.number || 100,
      b.upperSteps[0]?.number || 100
    );
    return minA - minB;
  });
  return calculatedSteps;
};

export const selectCellsInRange = (
  rowStart: number,
  rowDiff: number,
  cellStart: number,
  cellDiff: number
): {
  selectedToothKey: Record<string, boolean>;
  selectedToothDto: IToothDto[];
} => {
  const rowMark = rowDiff < 0 ? 1 : -1;
  const rowIndexes = Array(Math.abs(rowDiff) + 1)
    .fill(rowStart)
    .map((item, index) => item + index * rowMark);
  const cellMark = cellDiff < 0 ? 1 : -1;
  const cellIndexes = Array(Math.abs(cellDiff) + 1)
    .fill(cellStart)
    .map((item, index) => item + index * cellMark);
  const selectedToothKey: Record<string, boolean> = {};
  const selectedTeeth: IToothDto[] = cellIndexes.flatMap((cellItem: number) => {
    return rowIndexes.map((rowItem: number) => {
      selectedToothKey[`${cellItem}_${rowItem}`] = true;
      return {
        toothNumber: cellItem,
        stepNumber: rowItem,
      };
    });
  });

  return {
    selectedToothKey: selectedToothKey,
    selectedToothDto: selectedTeeth,
  };
};
