import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { TextField, FormLabel } from "components/ui";
import { InformBlock } from "components/common";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/caseCreate";

import { Impressions } from "./Impressions";
import { OtherFiles } from "./OtherFiles";
import { StepsStartSelector } from "./StepsStartSelector";

export const AdditionalInfoStep = () => {
  const dispatch = useAppDispatch();
  const notes = useAppSelector(selectors.getNotesSelector);
  const notesChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setNotes(event.target.value));
  };
  const text =
    "Submitting Impressions:\n" +
    "If you are sending physical samples, you must specify how many impressions will be physically shipped using the number toggles.\n\n" +
    "STL Scans:\n" +
    "Upload your STL scans on this page in .stl format. If you have Clinical Photos or any other files that are not STL scans, click “Back” to return to the RX information page to upload additional files.";
  return (
    <Grid container spacing={6}>
      <Grid item md={6}>
        <Impressions />
        <OtherFiles />
        <StepsStartSelector />
        <Box mt={3}>
          <FormLabel
            targetLabel="Additional info"
            label="If you have any additional treatment preferences, please provide them here:"
          >
            <TextField
              value={notes}
              onChange={notesChangeHandler}
              fullWidth
              placeholder="Type notes here..."
            />
          </FormLabel>
        </Box>
      </Grid>
      <Grid item md={6}>
        <InformBlock title="Note" text={text} />
      </Grid>
    </Grid>
  );
};
