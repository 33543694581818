import React, { useState, SyntheticEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { TextField } from "../TextField";
import { FieldProps, OptionItem } from "./types";
import { TagItem } from "./TagItem";

export const SelectTagFields: React.VFC<FieldProps> = ({
  options,
  placeholder,
  className,
  sx,
  value,
  onChange,
  error,
  helperText,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const changeInputHandler = (
    event: SyntheticEvent<Element, Event>,
    valueFromInput: string
  ) => {
    setInputValue(valueFromInput);
  };
  const optionAddHandler = (event: any, option: OptionItem | null) => {
    if (!option) return;
    const optionExists = value?.some(({ id }) => id === option.id);
    if (onChange) {
      if (Boolean(optionExists) && value) {
        onChange([...value.filter(({ id }) => id !== option.id), option]);
      } else {
        onChange([...(value || []), option]);
      }
      setInputValue("");
    }
  };
  const optionRemoveHandler = (option: OptionItem) => {
    const optionExists = value?.some(({ id }) => id === option.id);
    if (onChange) {
      if (Boolean(optionExists) && value) {
        onChange(value.filter(({ id }) => id !== option.id));
      } else {
        onChange([...(value || []), option]);
      }
    }
  };
  return (
    <Box sx={sx}>
      <Autocomplete
        value={null}
        inputValue={inputValue}
        options={options}
        getOptionLabel={(option) => option.name}
        fullWidth
        className={className}
        onChange={optionAddHandler}
        onInputChange={changeInputHandler}
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            placeholder={placeholder}
          />
        )}
      />
      {Boolean(value?.length) && (
        <Stack spacing={1} mt={1}>
          {value?.map((item) => (
            <TagItem
              key={`${item.id}_${item.name}`}
              item={item}
              onRemove={optionRemoveHandler}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};
