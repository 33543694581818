import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { IExtTreatmentPlanStepDto } from "lib/dto";
import { PLAN_COLORS } from "consts/tpColors";

import { EmptyColContainer, InstructionsText, LineItem } from "./TableElements";

interface Props {
  extraInstructions: {
    upperSteps?: IExtTreatmentPlanStepDto | null;
    lowerSteps?: IExtTreatmentPlanStepDto | null;
  };
}
const check = (value: any) => {
  return (
    value === null || value === false || value === undefined || value === 0
  );
};

export const EmptyRetainerRow = (props: Props) => {
  const stepInstructionsUpper = useMemo(() => {
    const instr: React.ReactElement[] = [];

    if (!check(props.extraInstructions.upperSteps?.origin)) {
      instr.push(
        <Box
          component="span"
          width="100%"
          textAlign="center"
          key={`oa_${props.extraInstructions.upperSteps!.id}_upper`}
        >
          OA
        </Box>
      );
    }
    if (!check(props.extraInstructions.upperSteps?.matrix)) {
      instr.push(
        <Box
          component="span"
          width="100%"
          textAlign="center"
          key={`mx_${props.extraInstructions.upperSteps!.id}_upper`}
        >
          MX
        </Box>
      );
    }
    if (props.extraInstructions.upperSteps?.retainerQty) {
      instr.push(
        <Box
          component="span"
          width="100%"
          textAlign="center"
          key={`rt_${props.extraInstructions.upperSteps!.id}_upper`}
        >
          RT {props.extraInstructions.upperSteps?.retainerQty}
        </Box>
      );
    }
    return instr;
  }, [props.extraInstructions.upperSteps]);
  const stepInstructionsLower = useMemo(() => {
    const instr: React.ReactElement[] = [];

    if (!check(props.extraInstructions.lowerSteps?.origin)) {
      instr.push(
        <Box
          component="span"
          width="100%"
          textAlign="center"
          key={`oa_${props.extraInstructions.lowerSteps!.id}_lower`}
        >
          OA
        </Box>
      );
    }
    if (!check(props.extraInstructions.lowerSteps?.matrix)) {
      instr.push(
        <Box
          component="span"
          width="100%"
          textAlign="center"
          key={`mx_${props.extraInstructions.lowerSteps!.id}_lower`}
        >
          MX
        </Box>
      );
    }
    if (props.extraInstructions.lowerSteps?.retainerQty) {
      instr.push(
        <Box
          component="span"
          width="100%"
          textAlign="center"
          key={`rt_${props.extraInstructions.lowerSteps.id}_lower`}
        >
          RT {props.extraInstructions.lowerSteps?.retainerQty}
        </Box>
      );
    }
    return instr;
  }, [props.extraInstructions.lowerSteps]);

  return (
    <>
      <Stack alignItems="center" direction="row">
        <EmptyColContainer>
          <div>
            {Boolean(stepInstructionsUpper.length > 0) && (
              <Box
                component="span"
                position="relative"
                color={PLAN_COLORS.grey.GREY_8}
                sx={{ cursor: "pointer" }}
              >
                <InstructionsText>{stepInstructionsUpper}</InstructionsText>
              </Box>
            )}
          </div>
          {stepInstructionsUpper.length > 0 ? LineItem : <div />}
          <div />
          {stepInstructionsLower.length > 0 ? LineItem : <div />}
          <div>
            {Boolean(stepInstructionsLower.length > 0) && (
              <Box
                component="span"
                position="relative"
                color={PLAN_COLORS.grey.GREY_8}
                sx={{ cursor: "pointer" }}
              >
                <InstructionsText>{stepInstructionsLower}</InstructionsText>
              </Box>
            )}
          </div>
        </EmptyColContainer>
      </Stack>
    </>
  );
};
