import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { InfoWithLabel } from "components/common";
import { selectors, actions } from "store/createPayment";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { toLocalCurrency } from "utils";
import { useSearchParams } from "react-router-dom";

export const OrganizationsFieldsRead = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const officeId = searchParams.get("office");
  const orgId = searchParams.get("org");
  const fields = useAppSelector(selectors.getInfoForSelectedOrg);
  const currentBalance = useAppSelector(selectors.currentBalanceSelector);
  const isLoading = useAppSelector(selectors.loadingSelector);
  useEffect(() => {
    if (orgId && officeId) {
      dispatch(
        actions.getAllInfo({
          orgId,
          officeId,
        })
      );
    }
  }, [orgId, officeId, dispatch]);
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item md={4}>
          <InfoWithLabel
            label="ORGANIZATION:"
            text={fields.orgName}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={3}>
          <InfoWithLabel
            label="OFFICE:"
            text={fields.officeName}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={3}>
          <InfoWithLabel
            label="CURRENT BALANCE:"
            text={toLocalCurrency(currentBalance)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};
