import React from "react";
import { ErrorBoundry } from "components/hoc";
import { NavMenu } from "./NavMenu";
import { Header } from "./Header";
import { Container, Content, ContentContainer } from "./Elements";
import { useSnackbarHelper } from "components/common/SnackbarProvider/useSnackbarHelper";

export const MainContainer: React.FC = (props) => {
  useSnackbarHelper();

  return (
    <Container>
      <Header />
      <ContentContainer direction="row">
        <NavMenu />
        <Content>
          <ErrorBoundry>{props.children}</ErrorBoundry>
        </Content>
      </ContentContainer>
    </Container>
  );
};
