import { Colors } from "consts/colors";
import { useConfirm } from "./confirm";
import { useCallback } from "react";
import { Typography } from "@mui/material";

export const useConsentWindow: () => () => Promise<void> = () => {
  const confirm = useConfirm();
  return useCallback(
    () =>
      confirm({
        title: "We respect your privacy",
        cancellationText: `I prefer that data I enter is not recorded`,
        confirmationText:
          "I allow this information to be recorded for customer service",
        allowClose: false,
        confirmationButtonProps: {
          variant: "contained",
          color: "primary",
          disableElevation: true,
          sx: {
            fontSize: 12,
            margin: 0,
            marginLeft: "0 !important",
            marginBottom: 3,
            width: "100%",
            textTransform: "none",
          },
        },
        contentProps: {
          sx: {
            color: Colors.TEXT_PRIMARY,
          },
        },
        actionProps: {
          children: (
            <Typography
              variant="body2"
              sx={{ fontSize: 13 }}
              mt={0.5}
              color={Colors.DISABLED}
            >
              Data entered will be hidden from recordings, but all information
              necessary for proper functionality is stored securely with
              Orthosnap
            </Typography>
          ),
        },
        dialogProps: {
          maxWidth: "xs",
          disableEscapeKeyDown: true,
          sx: {
            "& .MuiDialogActions-root": {
              flexDirection: "column-reverse",
              alignItems: "flex-start",
              padding: "8px 16px 32px",
            },
          },
        },
        cancellationButtonProps: {
          variant: "outlined",
          disableElevation: true,
          sx: {
            fontSize: 12,
            textTransform: "none",
            width: "100%",
          },
        },
        description:
          "To provide better customer support, we record what is displayed on screen as you work within this application, including any data that is entered.",
      }),
    [confirm]
  );
};
