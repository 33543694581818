import { OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { useCallback } from "react";

interface NoticeHandlers {
  showSuccessNotice: (message: string) => void;
  showErrorNotice: (message: string, options?: Partial<OptionsObject>) => void;
  showWarningNotice: (message: string) => void;
  showInfoNotice: (message: string) => void;
  closeSnackbar: (key?: SnackbarKey) => void;
}
export const useStyledSnackbar = (): NoticeHandlers => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const showSuccessNotice = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: "success",
      });
    },
    [enqueueSnackbar]
  );
  const showErrorNotice = useCallback(
    (message: string, options) => {
      enqueueSnackbar(message, {
        variant: "error",
        ...options,
      });
    },
    [enqueueSnackbar]
  );
  const showWarningNotice = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: "warning",
      });
    },
    [enqueueSnackbar]
  );
  const showInfoNotice = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: "info",
      });
    },
    [enqueueSnackbar]
  );
  return {
    showSuccessNotice,
    showErrorNotice,
    showWarningNotice,
    showInfoNotice,
    closeSnackbar,
  };
};
