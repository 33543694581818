import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { CurrentUserState } from "./types";

const selectSelf = (state: RootState) => state.currentUser;

export const permissionsSelector = createSelector(
  selectSelf,
  (state: CurrentUserState) => {
    if (state.currentUser?.permissions?.length) {
      return state.currentUser.permissions;
    }
    return [];
  }
);

export const getUnreadMessage = createSelector(
  selectSelf,
  (state: CurrentUserState) => state.unreadMessage
);

export const getAllUserInfo = createSelector(
  selectSelf,
  (state: CurrentUserState) => state
);

export const getUserBalances = createSelector(
  selectSelf,
  (state: CurrentUserState) => {
    if (state.offices?.length) {
      return state.offices.reduce(
        (balance, item) => {
          return {
            currentBalance: balance.currentBalance + Number(item.balance),
            debitBalance: balance.debitBalance + Number(item.debitBalance),
            promoBalance: balance.promoBalance + Number(item.promo),
          };
        },
        {
          currentBalance: 0,
          debitBalance: 0,
          promoBalance: 0,
        }
      );
    }
    return {
      currentBalance: 0,
      debitBalance: 0,
      promoBalance: 0,
    };
  }
);

export const selectUserOffices = createSelector(
  selectSelf,
  (state: CurrentUserState) => {
    if (state.offices?.length) {
      return state.offices;
    }
    return [];
  }
);
