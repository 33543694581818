import { createReducer } from "@reduxjs/toolkit";
import { AccountSettingsState } from "./types";
import { intiState } from "./state";
import * as actions from "./actions";
import { OfficeSettings } from "lib/enum";

export const reducer = createReducer<AccountSettingsState>(
  intiState,
  (builder) => {
    builder
      .addCase(actions.setPassword, (state, action) => {
        state.password = action.payload;
      })
      .addCase(actions.setUserFields, (state, action) => {
        state.userInfo![action.payload.name] = action.payload.value;
      })
      .addCase(actions.setOfficeSetting, (state, action) => {
        const officeIdx = state.officeSettings.findIndex(
          ({ officeId }) => officeId === action.payload.officeId
        );
        switch (action.payload.value) {
          case OfficeSettings.NOTIFY_PER_INVOICE:
            state.officeSettings[officeIdx] = {
              ...state.officeSettings[officeIdx],
              [OfficeSettings.NOTIFY_PER_INVOICE]: "true",
              [OfficeSettings.NOTIFY_OUTSTANDING_INVOICE]: "false",
            };
            break;
          case OfficeSettings.NOTIFY_OUTSTANDING_INVOICE:
            state.officeSettings[officeIdx] = {
              ...state.officeSettings[officeIdx],
              [OfficeSettings.NOTIFY_PER_INVOICE]: "false",
              [OfficeSettings.NOTIFY_OUTSTANDING_INVOICE]: "",
            };
        }
      })
      .addCase(actions.setOfficeTreshold, (state, action) => {
        const officeIdx = state.officeSettings.findIndex(
          ({ officeId }) => officeId === action.payload.officeId
        );
        state.officeSettings[officeIdx] = {
          ...state.officeSettings[officeIdx],
          [OfficeSettings.NOTIFY_PER_INVOICE]: "false",
          [OfficeSettings.NOTIFY_OUTSTANDING_INVOICE]: action.payload.value,
        };
      })
      .addCase(actions.fetchFiles.fulfilled, (state, action) => {
        state.officesLogo[action.payload.officeId] = action.payload.files.map(
          (file) => ({
            id: file?.id.toString(),
            src: "",
            contentType: file.contentType!,
            name: file.name,
            unDeletable: false,
          })
        );
      })
      .addCase(actions.downloadFile.fulfilled, (state, action) => {
        if (state.officesLogo[action.payload.officeId]) {
          const fileIndex = state.officesLogo[
            action.payload.officeId
          ].findIndex(({ id }) => id === action.payload.fileId);
          if (
            state.officesLogo[action.payload.officeId] &&
            state.officesLogo[action.payload.officeId][fileIndex]
          ) {
            // @ts-ignore
            state.officesLogo[action.payload.officeId][fileIndex].src =
              action.payload.src;
          }
        }
      })
      .addCase(actions.uploadFile.fulfilled, (state, action) => {
        state.officesLogo[action.payload.officeId]?.push({
          ...action.payload,
          unDeletable: false,
        });
      })
      .addCase(actions.deletedFile.fulfilled, (state, action) => {
        state.officesLogo[action.payload.officeId] = state.officesLogo[
          action.payload.officeId
        ].filter(
          ({ id, name }) =>
            (id && id !== action.payload.fileId) ||
            (!id && name !== action.payload.name)
        );
      })
      .addCase(actions.unlinkSocialMediaAccount.fulfilled, (state, action) => {
        state.identities = state.identities.filter(
          ({ user_id }) => user_id !== action.payload
        );
      })
      .addCase(actions.saveChanges.fulfilled, (state) => {
        state.isSaving = false;
      })
      .addCase(actions.saveChanges.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(actions.saveChanges.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(actions.fetchAccountSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.officeSettings = action.payload.officeSettings;
        state.loginDirectLink = action.payload.loginDirectLink;
        state.identities = action.payload.identities;
        state.userInfo = {
          lastName: action.payload.userInfo.lastName,
          firstName: action.payload.userInfo.firstName,
          userId: action.payload.userInfo.userId,
          role: action.payload.roles.map(({ name }) => name).join(", "),
          email: action.payload.userInfo.email,
          phone: action.payload.userInfo.phone,
        };
      })
      .addCase(actions.fetchAccountSettings.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(actions.fetchAccountSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.clearState, () => intiState);
  }
);
