import { IUploadedFile } from "lib/model";
import {
  IExtCaseWithCaseProgress,
  IExtOrderWithItemsAndTasksDto,
  IOfficeDto,
} from "lib/dto";
import { OrderItemType } from "lib/enum";

export type OrderFileType =
  | "clinicalPhotos"
  | "commonFiles"
  | "verificationKeys";

export enum ProductionType {
  ALIGNER = "0",
  RETAINER = "1",
  BOTH = "2",
}

export enum OrderType {
  CONTINUATION_NEW_IMPR = 3,
  CONTINUATION_EXIST_IMPR,
}
export type RequiredFields =
  | keyof Omit<CreateOrderState, "requiredFields">
  | "arch";
export interface CreateOrderState {
  isLoading: boolean;
  isSaving: boolean;
  shippingLabel?: string;
  orderId?: number;
  lowerArch: boolean;
  upperArch: boolean;
  stepsType: string;
  customStepsNum: number;
  productionType: string;
  orderNote: string;
  requestedRetainers: number;
  requiredFields: RequiredFields[];
  previousOrder: IExtOrderWithItemsAndTasksDto | null;
  showVerification: boolean;
  caseInfo: IExtCaseWithCaseProgress | null;
  orderItems: Partial<Record<OrderItemType, number>>;
  clinicalPhotos: IUploadedFile[];
  commonFiles: IUploadedFile[];
  verificationKeys: IUploadedFile[];
  reason: string;
  useMeditLink: boolean;
  removeAttachment: boolean;
}
