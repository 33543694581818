import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { PaginationIconButtonProps } from "./types";
import { NavigatePreviousIcon } from "./Elements";

export const PaginationIconButton = ({
  type,
  disabled,
  onChange,
  count,
  page = 1,
}: PaginationIconButtonProps): JSX.Element | null => {
  switch (type) {
    case "first":
      return (
        <li key={`pagination-icon-btn-${type}`}>
          <IconButton disabled={disabled} onClick={() => onChange(1)}>
            <FirstPageIcon />
          </IconButton>
        </li>
      );
    case "previous":
      return (
        <li key={`pagination-icon-btn-${type}`}>
          <IconButton disabled={disabled} onClick={() => onChange(page - 1)}>
            <NavigatePreviousIcon />
          </IconButton>
        </li>
      );
    case "next":
      return (
        <li key={`pagination-icon-btn-${type}`}>
          <IconButton disabled={disabled} onClick={() => onChange(page + 1)}>
            <NavigateNextIcon />
          </IconButton>
        </li>
      );
    case "last":
      return (
        <li key={`pagination-icon-btn-${type}`}>
          <IconButton disabled={disabled} onClick={() => onChange(count)}>
            <LastPageIcon />
          </IconButton>
        </li>
      );
  }

  return null;
};
