import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";

import { OrderRemakeType } from "lib/enum";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions } from "store/requestRemake";

export const ReportFields = () => {
  const dispatch = useAppDispatch();
  const remakeType = useAppSelector((state) => state.requestRemake.remakeType);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.changeRemakeType(event.target.value as OrderRemakeType));
  };
  return (
    <Box my={1}>
      <RadioGroup row onChange={changeHandler} value={remakeType}>
        <FormControlLabel
          componentsProps={{
            typography: { variant: "body2" },
          }}
          value={OrderRemakeType.LOST_PACKAGE}
          control={<Radio />}
          label="Report lost package"
        />
        <FormControlLabel
          componentsProps={{
            typography: { variant: "body2" },
          }}
          value={OrderRemakeType.REMAKE_ORDER}
          control={<Radio />}
          label="Report order issue"
        />
      </RadioGroup>
    </Box>
  );
};
