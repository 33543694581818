import React from 'react';

const Attachment = () => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none">
			<circle cx="16" cy="16" r="16" fill="white" />
			<path
				d="M8.57812 18.4785C8.57812 18.0911 8.44141 17.7949 8.16797 17.5898C7.89453 17.3802 7.40234 17.1615 6.69141 16.9336C5.98047 16.7012 5.41764 16.4733 5.00293 16.25C3.87272 15.6393 3.30762 14.8167 3.30762 13.7822C3.30762 13.2445 3.45801 12.766 3.75879 12.3467C4.06413 11.9229 4.49935 11.5924 5.06445 11.3555C5.63411 11.1185 6.27214 11 6.97852 11C7.68945 11 8.32292 11.1299 8.87891 11.3896C9.4349 11.6449 9.86556 12.0072 10.1709 12.4766C10.4808 12.946 10.6357 13.4792 10.6357 14.0762H8.58496C8.58496 13.6204 8.44141 13.2673 8.1543 13.0166C7.86719 12.7614 7.46387 12.6338 6.94434 12.6338C6.44303 12.6338 6.05339 12.7409 5.77539 12.9551C5.4974 13.1647 5.3584 13.4427 5.3584 13.7891C5.3584 14.1126 5.52018 14.3838 5.84375 14.6025C6.17188 14.8213 6.65267 15.0264 7.28613 15.2178C8.4528 15.5687 9.30273 16.0039 9.83594 16.5234C10.3691 17.043 10.6357 17.6901 10.6357 18.4648C10.6357 19.3262 10.3099 20.0029 9.6582 20.4951C9.00651 20.9827 8.12923 21.2266 7.02637 21.2266C6.26074 21.2266 5.56348 21.0876 4.93457 20.8096C4.30566 20.527 3.82487 20.1419 3.49219 19.6543C3.16406 19.1667 3 18.6016 3 17.959H5.05762C5.05762 19.0573 5.71387 19.6064 7.02637 19.6064C7.514 19.6064 7.89453 19.5085 8.16797 19.3125C8.44141 19.112 8.57812 18.834 8.57812 18.4785Z"
				fill="#2C2E30"
			/>
			<path
				d="M19.5225 12.7979H16.4736V21.0898H14.4229V12.7979H11.415V11.1367H19.5225V12.7979Z"
				fill="#2C2E30"
			/>
			<path
				d="M20.6982 21.0898V11.1367H23.7607C24.6357 11.1367 25.4173 11.335 26.1055 11.7314C26.7982 12.1234 27.3382 12.6839 27.7256 13.4131C28.113 14.1377 28.3066 14.9626 28.3066 15.8877V16.3457C28.3066 17.2708 28.1152 18.0934 27.7324 18.8135C27.3542 19.5335 26.8187 20.0918 26.126 20.4883C25.4333 20.8848 24.6517 21.0853 23.7812 21.0898H20.6982ZM22.749 12.7979V19.4424H23.7402C24.5423 19.4424 25.1553 19.1803 25.5791 18.6562C26.0029 18.1322 26.2194 17.3825 26.2285 16.4072V15.8809C26.2285 14.8691 26.0189 14.1035 25.5996 13.584C25.1803 13.0599 24.5674 12.7979 23.7607 12.7979H22.749Z"
				fill="#2C2E30"
			/>
		</svg>
	);
};
export default Attachment;
