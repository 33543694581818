import React from "react";
import { AttachmentType } from "lib/enum";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  value: string | null;
}

export const Attachment = ({ className, style, value }: Props) => {
  if (!value) {
    return null;
  }
  switch (value) {
    case AttachmentType.UE:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-12 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M6.52344 0.625H8.85938V8.14062C8.85938 9.01562 8.67188 9.7526 8.29688 10.3516C7.92708 10.9505 7.41406 11.401 6.75781 11.7031C6.10677 12.0052 5.35677 12.1562 4.50781 12.1562C3.65885 12.1562 2.90365 12.0052 2.24219 11.7031C1.58594 11.401 1.07031 10.9505 0.695312 10.3516C0.325521 9.7526 0.140625 9.01562 0.140625 8.14062V0.625H2.48438V8.14062C2.48438 8.65104 2.5651 9.06771 2.72656 9.39062C2.88802 9.71354 3.11979 9.95052 3.42188 10.1016C3.72917 10.2526 4.09115 10.3281 4.50781 10.3281C4.9349 10.3281 5.29688 10.2526 5.59375 10.1016C5.89583 9.95052 6.125 9.71354 6.28125 9.39062C6.44271 9.06771 6.52344 8.65104 6.52344 8.14062V0.625ZM18.4219 10.1719V12H12.3672V10.1719H18.4219ZM13.1328 0.625V12H10.7891V0.625H13.1328ZM17.6328 5.25781V7.03906H12.3672V5.25781H17.6328ZM18.4141 0.625V2.46094H12.3672V0.625H18.4141Z"
            fill="#2C2E30"
          />
        </svg>
      );
    case AttachmentType.UE_EMBED:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-12 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M6.52344 0.625H8.85938V8.14062C8.85938 9.01562 8.67188 9.7526 8.29688 10.3516C7.92708 10.9505 7.41406 11.401 6.75781 11.7031C6.10677 12.0052 5.35677 12.1562 4.50781 12.1562C3.65885 12.1562 2.90365 12.0052 2.24219 11.7031C1.58594 11.401 1.07031 10.9505 0.695312 10.3516C0.325521 9.7526 0.140625 9.01562 0.140625 8.14062V0.625H2.48438V8.14062C2.48438 8.65104 2.5651 9.06771 2.72656 9.39062C2.88802 9.71354 3.11979 9.95052 3.42188 10.1016C3.72917 10.2526 4.09115 10.3281 4.50781 10.3281C4.9349 10.3281 5.29688 10.2526 5.59375 10.1016C5.89583 9.95052 6.125 9.71354 6.28125 9.39062C6.44271 9.06771 6.52344 8.65104 6.52344 8.14062V0.625ZM18.4219 10.1719V12H12.3672V10.1719H18.4219ZM13.1328 0.625V12H10.7891V0.625H13.1328ZM17.6328 5.25781V7.03906H12.3672V5.25781H17.6328ZM18.4141 0.625V2.46094H12.3672V0.625H18.4141Z"
            fill="#2196F3"
          />
        </svg>
      );
    case AttachmentType.UE_PRODUCE:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-12 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M6.52344 0.625H8.85938V8.14062C8.85938 9.01562 8.67188 9.7526 8.29688 10.3516C7.92708 10.9505 7.41406 11.401 6.75781 11.7031C6.10677 12.0052 5.35677 12.1562 4.50781 12.1562C3.65885 12.1562 2.90365 12.0052 2.24219 11.7031C1.58594 11.401 1.07031 10.9505 0.695312 10.3516C0.325521 9.7526 0.140625 9.01562 0.140625 8.14062V0.625H2.48438V8.14062C2.48438 8.65104 2.5651 9.06771 2.72656 9.39062C2.88802 9.71354 3.11979 9.95052 3.42188 10.1016C3.72917 10.2526 4.09115 10.3281 4.50781 10.3281C4.9349 10.3281 5.29688 10.2526 5.59375 10.1016C5.89583 9.95052 6.125 9.71354 6.28125 9.39062C6.44271 9.06771 6.52344 8.65104 6.52344 8.14062V0.625ZM18.4219 10.1719V12H12.3672V10.1719H18.4219ZM13.1328 0.625V12H10.7891V0.625H13.1328ZM17.6328 5.25781V7.03906H12.3672V5.25781H17.6328ZM18.4141 0.625V2.46094H12.3672V0.625H18.4141Z"
            fill="#429488"
          />
        </svg>
      );
    case AttachmentType.APICAL:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-6 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M6.69922 8.94922H3.10352L2.41992 11H0.239258L3.94434 1.04688H5.84473L9.57031 11H7.38965L6.69922 8.94922ZM3.65723 7.28809H6.14551L4.89453 3.5625L3.65723 7.28809ZM12.5508 7.49316V11H10.5V1.04688H14.3828C15.1302 1.04688 15.7865 1.18359 16.3516 1.45703C16.9212 1.73047 17.3587 2.12012 17.6641 2.62598C17.9694 3.12728 18.1221 3.69922 18.1221 4.3418C18.1221 5.31706 17.7871 6.08724 17.1172 6.65234C16.4518 7.21289 15.529 7.49316 14.3486 7.49316H12.5508ZM12.5508 5.83203H14.3828C14.9251 5.83203 15.3376 5.70443 15.6201 5.44922C15.9072 5.19401 16.0508 4.82943 16.0508 4.35547C16.0508 3.86784 15.9072 3.47363 15.6201 3.17285C15.333 2.87207 14.9365 2.71712 14.4307 2.70801H12.5508V5.83203ZM27.3506 7.68457C27.2731 8.75553 26.8766 9.59863 26.1611 10.2139C25.4502 10.8291 24.5114 11.1367 23.3447 11.1367C22.0687 11.1367 21.0638 10.7083 20.3301 9.85156C19.6009 8.99023 19.2363 7.8099 19.2363 6.31055V5.70215C19.2363 4.74512 19.4049 3.90202 19.7422 3.17285C20.0794 2.44368 20.5602 1.88542 21.1846 1.49805C21.8135 1.10612 22.5426 0.910156 23.3721 0.910156C24.5205 0.910156 25.4456 1.21777 26.1475 1.83301C26.8493 2.44824 27.2549 3.31185 27.3643 4.42383H25.3135C25.2633 3.78125 25.0833 3.31641 24.7734 3.0293C24.4681 2.73763 24.001 2.5918 23.3721 2.5918C22.6885 2.5918 22.1758 2.83789 21.834 3.33008C21.4967 3.81771 21.3236 4.5765 21.3145 5.60645V6.3584C21.3145 7.43392 21.4762 8.22005 21.7998 8.7168C22.1279 9.21354 22.6429 9.46191 23.3447 9.46191C23.9782 9.46191 24.4499 9.31836 24.7598 9.03125C25.0742 8.73958 25.2542 8.29069 25.2998 7.68457H27.3506Z"
            fill="#2C2E30"
          />
        </svg>
      );
    case AttachmentType.APICAL_EMBED:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-6 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M6.69922 8.94922H3.10352L2.41992 11H0.239258L3.94434 1.04688H5.84473L9.57031 11H7.38965L6.69922 8.94922ZM3.65723 7.28809H6.14551L4.89453 3.5625L3.65723 7.28809ZM12.5508 7.49316V11H10.5V1.04688H14.3828C15.1302 1.04688 15.7865 1.18359 16.3516 1.45703C16.9212 1.73047 17.3587 2.12012 17.6641 2.62598C17.9694 3.12728 18.1221 3.69922 18.1221 4.3418C18.1221 5.31706 17.7871 6.08724 17.1172 6.65234C16.4518 7.21289 15.529 7.49316 14.3486 7.49316H12.5508ZM12.5508 5.83203H14.3828C14.9251 5.83203 15.3376 5.70443 15.6201 5.44922C15.9072 5.19401 16.0508 4.82943 16.0508 4.35547C16.0508 3.86784 15.9072 3.47363 15.6201 3.17285C15.333 2.87207 14.9365 2.71712 14.4307 2.70801H12.5508V5.83203ZM27.3506 7.68457C27.2731 8.75553 26.8766 9.59863 26.1611 10.2139C25.4502 10.8291 24.5114 11.1367 23.3447 11.1367C22.0687 11.1367 21.0638 10.7083 20.3301 9.85156C19.6009 8.99023 19.2363 7.8099 19.2363 6.31055V5.70215C19.2363 4.74512 19.4049 3.90202 19.7422 3.17285C20.0794 2.44368 20.5602 1.88542 21.1846 1.49805C21.8135 1.10612 22.5426 0.910156 23.3721 0.910156C24.5205 0.910156 25.4456 1.21777 26.1475 1.83301C26.8493 2.44824 27.2549 3.31185 27.3643 4.42383H25.3135C25.2633 3.78125 25.0833 3.31641 24.7734 3.0293C24.4681 2.73763 24.001 2.5918 23.3721 2.5918C22.6885 2.5918 22.1758 2.83789 21.834 3.33008C21.4967 3.81771 21.3236 4.5765 21.3145 5.60645V6.3584C21.3145 7.43392 21.4762 8.22005 21.7998 8.7168C22.1279 9.21354 22.6429 9.46191 23.3447 9.46191C23.9782 9.46191 24.4499 9.31836 24.7598 9.03125C25.0742 8.73958 25.2542 8.29069 25.2998 7.68457H27.3506Z"
            fill="#2196F3"
          />
        </svg>
      );
    case AttachmentType.APICAL_PRODUCE:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-6 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M6.69922 8.94922H3.10352L2.41992 11H0.239258L3.94434 1.04688H5.84473L9.57031 11H7.38965L6.69922 8.94922ZM3.65723 7.28809H6.14551L4.89453 3.5625L3.65723 7.28809ZM12.5508 7.49316V11H10.5V1.04688H14.3828C15.1302 1.04688 15.7865 1.18359 16.3516 1.45703C16.9212 1.73047 17.3587 2.12012 17.6641 2.62598C17.9694 3.12728 18.1221 3.69922 18.1221 4.3418C18.1221 5.31706 17.7871 6.08724 17.1172 6.65234C16.4518 7.21289 15.529 7.49316 14.3486 7.49316H12.5508ZM12.5508 5.83203H14.3828C14.9251 5.83203 15.3376 5.70443 15.6201 5.44922C15.9072 5.19401 16.0508 4.82943 16.0508 4.35547C16.0508 3.86784 15.9072 3.47363 15.6201 3.17285C15.333 2.87207 14.9365 2.71712 14.4307 2.70801H12.5508V5.83203ZM27.3506 7.68457C27.2731 8.75553 26.8766 9.59863 26.1611 10.2139C25.4502 10.8291 24.5114 11.1367 23.3447 11.1367C22.0687 11.1367 21.0638 10.7083 20.3301 9.85156C19.6009 8.99023 19.2363 7.8099 19.2363 6.31055V5.70215C19.2363 4.74512 19.4049 3.90202 19.7422 3.17285C20.0794 2.44368 20.5602 1.88542 21.1846 1.49805C21.8135 1.10612 22.5426 0.910156 23.3721 0.910156C24.5205 0.910156 25.4456 1.21777 26.1475 1.83301C26.8493 2.44824 27.2549 3.31185 27.3643 4.42383H25.3135C25.2633 3.78125 25.0833 3.31641 24.7734 3.0293C24.4681 2.73763 24.001 2.5918 23.3721 2.5918C22.6885 2.5918 22.1758 2.83789 21.834 3.33008C21.4967 3.81771 21.3236 4.5765 21.3145 5.60645V6.3584C21.3145 7.43392 21.4762 8.22005 21.7998 8.7168C22.1279 9.21354 22.6429 9.46191 23.3447 9.46191C23.9782 9.46191 24.4499 9.31836 24.7598 9.03125C25.0742 8.73958 25.2542 8.29069 25.2998 7.68457H27.3506Z"
            fill="#429488"
          />
        </svg>
      );
    case AttachmentType.STANDARD:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-6 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M5.85645 8.38867C5.85645 8.0013 5.71973 7.70508 5.44629 7.5C5.17285 7.29036 4.68066 7.07161 3.96973 6.84375C3.25879 6.61133 2.69596 6.38346 2.28125 6.16016C1.15104 5.54948 0.585938 4.72689 0.585938 3.69238C0.585938 3.15462 0.736328 2.67611 1.03711 2.25684C1.34245 1.83301 1.77767 1.5026 2.34277 1.26562C2.91243 1.02865 3.55046 0.910156 4.25684 0.910156C4.96777 0.910156 5.60124 1.04004 6.15723 1.2998C6.71322 1.55501 7.14388 1.91732 7.44922 2.38672C7.75911 2.85612 7.91406 3.38932 7.91406 3.98633H5.86328C5.86328 3.5306 5.71973 3.17741 5.43262 2.92676C5.14551 2.67155 4.74219 2.54395 4.22266 2.54395C3.72135 2.54395 3.33171 2.65104 3.05371 2.86523C2.77572 3.07487 2.63672 3.35286 2.63672 3.69922C2.63672 4.02279 2.7985 4.29395 3.12207 4.5127C3.4502 4.73145 3.93099 4.93652 4.56445 5.12793C5.73112 5.47884 6.58105 5.91406 7.11426 6.43359C7.64746 6.95312 7.91406 7.60026 7.91406 8.375C7.91406 9.23633 7.58822 9.91309 6.93652 10.4053C6.28483 10.8929 5.40755 11.1367 4.30469 11.1367C3.53906 11.1367 2.8418 10.9977 2.21289 10.7197C1.58398 10.4372 1.10319 10.0521 0.770508 9.56445C0.442383 9.07682 0.27832 8.51172 0.27832 7.86914H2.33594C2.33594 8.96745 2.99219 9.5166 4.30469 9.5166C4.79232 9.5166 5.17285 9.41862 5.44629 9.22266C5.71973 9.02214 5.85645 8.74414 5.85645 8.38867ZM16.8008 2.70801H13.752V11H11.7012V2.70801H8.69336V1.04688H16.8008V2.70801ZM17.9766 11V1.04688H21.0391C21.9141 1.04688 22.6956 1.24512 23.3838 1.6416C24.0765 2.03353 24.6165 2.59408 25.0039 3.32324C25.3913 4.04785 25.585 4.87272 25.585 5.79785V6.25586C25.585 7.18099 25.3936 8.00358 25.0107 8.72363C24.6325 9.44368 24.097 10.002 23.4043 10.3984C22.7116 10.7949 21.93 10.9954 21.0596 11H17.9766ZM20.0273 2.70801V9.35254H21.0186C21.8206 9.35254 22.4336 9.09049 22.8574 8.56641C23.2812 8.04232 23.4977 7.29264 23.5068 6.31738V5.79102C23.5068 4.7793 23.2972 4.01367 22.8779 3.49414C22.4587 2.97005 21.8457 2.70801 21.0391 2.70801H20.0273Z"
            fill="#2C2E30"
          />
        </svg>
      );
    case AttachmentType.STANDARD_EMBED:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-6 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M5.85645 8.38867C5.85645 8.0013 5.71973 7.70508 5.44629 7.5C5.17285 7.29036 4.68066 7.07161 3.96973 6.84375C3.25879 6.61133 2.69596 6.38346 2.28125 6.16016C1.15104 5.54948 0.585938 4.72689 0.585938 3.69238C0.585938 3.15462 0.736328 2.67611 1.03711 2.25684C1.34245 1.83301 1.77767 1.5026 2.34277 1.26562C2.91243 1.02865 3.55046 0.910156 4.25684 0.910156C4.96777 0.910156 5.60124 1.04004 6.15723 1.2998C6.71322 1.55501 7.14388 1.91732 7.44922 2.38672C7.75911 2.85612 7.91406 3.38932 7.91406 3.98633H5.86328C5.86328 3.5306 5.71973 3.17741 5.43262 2.92676C5.14551 2.67155 4.74219 2.54395 4.22266 2.54395C3.72135 2.54395 3.33171 2.65104 3.05371 2.86523C2.77572 3.07487 2.63672 3.35286 2.63672 3.69922C2.63672 4.02279 2.7985 4.29395 3.12207 4.5127C3.4502 4.73145 3.93099 4.93652 4.56445 5.12793C5.73112 5.47884 6.58105 5.91406 7.11426 6.43359C7.64746 6.95312 7.91406 7.60026 7.91406 8.375C7.91406 9.23633 7.58822 9.91309 6.93652 10.4053C6.28483 10.8929 5.40755 11.1367 4.30469 11.1367C3.53906 11.1367 2.8418 10.9977 2.21289 10.7197C1.58398 10.4372 1.10319 10.0521 0.770508 9.56445C0.442383 9.07682 0.27832 8.51172 0.27832 7.86914H2.33594C2.33594 8.96745 2.99219 9.5166 4.30469 9.5166C4.79232 9.5166 5.17285 9.41862 5.44629 9.22266C5.71973 9.02214 5.85645 8.74414 5.85645 8.38867ZM16.8008 2.70801H13.752V11H11.7012V2.70801H8.69336V1.04688H16.8008V2.70801ZM17.9766 11V1.04688H21.0391C21.9141 1.04688 22.6956 1.24512 23.3838 1.6416C24.0765 2.03353 24.6165 2.59408 25.0039 3.32324C25.3913 4.04785 25.585 4.87272 25.585 5.79785V6.25586C25.585 7.18099 25.3936 8.00358 25.0107 8.72363C24.6325 9.44368 24.097 10.002 23.4043 10.3984C22.7116 10.7949 21.93 10.9954 21.0596 11H17.9766ZM20.0273 2.70801V9.35254H21.0186C21.8206 9.35254 22.4336 9.09049 22.8574 8.56641C23.2812 8.04232 23.4977 7.29264 23.5068 6.31738V5.79102C23.5068 4.7793 23.2972 4.01367 22.8779 3.49414C22.4587 2.97005 21.8457 2.70801 21.0391 2.70801H20.0273Z"
            fill="#2196F3"
          />
        </svg>
      );
    case AttachmentType.STANDARD_PRODUCE:
      return (
        <svg
          width="40"
          height="40"
          viewBox="-6 -13 40 40"
          fill="none"
          className={className}
          style={style}
        >
          <path
            d="M5.85645 8.38867C5.85645 8.0013 5.71973 7.70508 5.44629 7.5C5.17285 7.29036 4.68066 7.07161 3.96973 6.84375C3.25879 6.61133 2.69596 6.38346 2.28125 6.16016C1.15104 5.54948 0.585938 4.72689 0.585938 3.69238C0.585938 3.15462 0.736328 2.67611 1.03711 2.25684C1.34245 1.83301 1.77767 1.5026 2.34277 1.26562C2.91243 1.02865 3.55046 0.910156 4.25684 0.910156C4.96777 0.910156 5.60124 1.04004 6.15723 1.2998C6.71322 1.55501 7.14388 1.91732 7.44922 2.38672C7.75911 2.85612 7.91406 3.38932 7.91406 3.98633H5.86328C5.86328 3.5306 5.71973 3.17741 5.43262 2.92676C5.14551 2.67155 4.74219 2.54395 4.22266 2.54395C3.72135 2.54395 3.33171 2.65104 3.05371 2.86523C2.77572 3.07487 2.63672 3.35286 2.63672 3.69922C2.63672 4.02279 2.7985 4.29395 3.12207 4.5127C3.4502 4.73145 3.93099 4.93652 4.56445 5.12793C5.73112 5.47884 6.58105 5.91406 7.11426 6.43359C7.64746 6.95312 7.91406 7.60026 7.91406 8.375C7.91406 9.23633 7.58822 9.91309 6.93652 10.4053C6.28483 10.8929 5.40755 11.1367 4.30469 11.1367C3.53906 11.1367 2.8418 10.9977 2.21289 10.7197C1.58398 10.4372 1.10319 10.0521 0.770508 9.56445C0.442383 9.07682 0.27832 8.51172 0.27832 7.86914H2.33594C2.33594 8.96745 2.99219 9.5166 4.30469 9.5166C4.79232 9.5166 5.17285 9.41862 5.44629 9.22266C5.71973 9.02214 5.85645 8.74414 5.85645 8.38867ZM16.8008 2.70801H13.752V11H11.7012V2.70801H8.69336V1.04688H16.8008V2.70801ZM17.9766 11V1.04688H21.0391C21.9141 1.04688 22.6956 1.24512 23.3838 1.6416C24.0765 2.03353 24.6165 2.59408 25.0039 3.32324C25.3913 4.04785 25.585 4.87272 25.585 5.79785V6.25586C25.585 7.18099 25.3936 8.00358 25.0107 8.72363C24.6325 9.44368 24.097 10.002 23.4043 10.3984C22.7116 10.7949 21.93 10.9954 21.0596 11H17.9766ZM20.0273 2.70801V9.35254H21.0186C21.8206 9.35254 22.4336 9.09049 22.8574 8.56641C23.2812 8.04232 23.4977 7.29264 23.5068 6.31738V5.79102C23.5068 4.7793 23.2972 4.01367 22.8779 3.49414C22.4587 2.97005 21.8457 2.70801 21.0391 2.70801H20.0273Z"
            fill="#429488"
          />
        </svg>
      );
  }
  return null;
};
export default Attachment;
