export enum CaseBudgetEnum {
  LOWER_PRICE = "0",
  BEST_RESULT = "1",
  UNKNOW = "2",
  NO_MORE = "3",
}

export const CaseBudget: Record<CaseBudgetEnum, string> = {
  [CaseBudgetEnum.LOWER_PRICE]: "Optimized for Lowest Price",
  [CaseBudgetEnum.BEST_RESULT]: "Optimized for Best Results",
  [CaseBudgetEnum.UNKNOW]: "I don’t know",
  [CaseBudgetEnum.NO_MORE]: "No more than",
};
