import React from 'react';
interface Props {
	className?: string;
	style?: any;
	value: boolean;
	type: 'uppers' | 'lowers';
}

const Intrusion = ({ value, className, style, type }: Props) => {
	return value ? (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			className={className}
			style={style}
		>
			<path d="M13.6484 36H12V28H13.6484V36Z" fill="#5F5CE6" />
			<path
				d="M21.6868 36H20.0385L16.8297 30.7363V36H15.1813V28H16.8297L20.044 33.2747V28H21.6868V36Z"
				fill="#5F5CE6"
			/>
			<path
				d="M28.9835 29.3352H26.533V36H24.8846V29.3352H22.467V28H28.9835V29.3352Z"
				fill="#5F5CE6"
			/>
			{type === 'lowers' ? (
				<path
					d="M26.7059 15.7647L20.3529 25L14 15.7647H17.8118L20.3529 0L22.8941 15.7647H26.7059Z"
					fill="#5F5CE6"
				/>
			) : (
				<path
					d="M26.7059 10.2353L20.3529 0L14 10.2353H17.8118L20.3529 25L22.8941 10.2353H26.7059Z"
					fill="#5F5CE6"
				/>
			)}
		</svg>
	) : null;
};
export default Intrusion;
