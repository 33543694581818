import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const LowerArch = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11C18.11 11 19 10.11 19 9C20.11 9 21 8.11 21 7V4C21 2.89 20.11 2 19 2H5C3.89 2 3 2.89 3 4L3.01 7C3.01 8.11 3.89 9 5 9H5.01C5.01 10.11 5.89 11 7 11H17ZM7 8C7 8.5 7.45 9 8 9H16C16.55 9 17 8.5 17 8C17 7.5 17.5 7 18 7C18.5 7 19 6.55 19 6V5C19 4.45 18.55 4 18 4H6C5.45 4 5 4.45 5 5V6C5 6.55 5.5 7 6 7C6.5 7 7 7.5 7 8ZM21 20C21 21.11 20.11 22 19 22H5C3.89 22 3.01 21.11 3.01 20L3 17C3 15.89 3.89 15 5 15C5 13.89 5.89 13 7 13H17C18.11 13 19 13.89 19 15C20.11 15 21 15.89 21 17V20Z"
      />
    </SvgIcon>
  );
};
