import React from "react";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export const Button: React.FC<
  ButtonProps & { fatButton?: boolean; loading?: boolean }
> = ({
  disableElevation = true,
  disabled,
  fatButton,
  loading,
  startIcon,
  ...props
}) => {
  const startIconLoading = loading ? (
    <CircularProgress color="inherit" size={14} />
  ) : (
    startIcon
  );
  return (
    <MuiButton
      disableElevation={disableElevation}
      startIcon={startIconLoading}
      disabled={loading || disabled}
      sx={{
        ...props.sx,
        textTransform: "none",
        height: fatButton ? 48 : undefined,
      }}
      {...props}
    />
  );
};
