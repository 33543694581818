import {
  IExtOfficeDto,
  IOfficeDto,
  IOrganizationDto,
  IOfficeStaffDto,
  IExtOfficeStaffDto,
} from "lib/dto";
import {
  IBaseResponse,
  IPaymentMethodModel,
  IOfficeStaffWithName,
} from "lib/model";
import api from "./baseService";

class OrgService {
  private baseUrl: string = "orgs";
  private officeUrl: string = "offices";
  private officeStaffUrl: string = "office-staff";

  public async getAllOrgs(): Promise<IOrganizationDto[]> {
    const { data } = await api.get<IBaseResponse<IOrganizationDto[]>>(
      this.baseUrl
    );
    return data.result;
  }

  public async getOrgById(orgId: number | string): Promise<IOrganizationDto> {
    const { data } = await api.get<IBaseResponse<IOrganizationDto>>(
      `${this.baseUrl}/${orgId}`
    );
    return data.result;
  }

  public async getOfficeByOrgId(
    orgId: number | string
  ): Promise<IExtOfficeDto[]> {
    const { data } = await api.get<IBaseResponse<IExtOfficeDto[]>>(
      `${this.baseUrl}/${orgId}/${this.officeUrl}`
    );
    return data.result;
  }

  public async getOfficeById(officeId: number | string): Promise<IOfficeDto> {
    const { data } = await api.get<IBaseResponse<IOfficeDto>>(
      `${this.officeUrl}/${officeId}`
    );
    return data.result;
  }

  public async getExtOfficeById(
    officeId: number | string
  ): Promise<IExtOfficeDto> {
    const { data } = await api.get<IBaseResponse<IExtOfficeDto>>(
      `ext-offices/${officeId}`
    );
    return data.result;
  }
  public async getOfficeStaffById(
    officeStaffId: number | string
  ): Promise<IExtOfficeStaffDto> {
    const { data } = await api.get<IBaseResponse<IExtOfficeStaffDto>>(
      `${this.officeStaffUrl}/${officeStaffId}`
    );
    return data.result;
  }

  public async getOfficeStaffByUserId(
    userId: number | string
  ): Promise<IOfficeStaffDto[]> {
    const { data } = await api.get<IBaseResponse<IOfficeStaffDto[]>>(
      `office-staff/users/${userId}`
    );
    return data.result;
  }

  public async getOfficesByUserId(
    userId: number | string
  ): Promise<{ orgStaff: IOfficeStaffDto[]; offices: IExtOfficeDto[] }> {
    const orgStaff = await this.getOfficeStaffByUserId(userId);
    const offices: IExtOfficeDto[] = [];

    if (orgStaff.length > 0) {
      for (const org of orgStaff) {
        const officesData = await this.getOfficeByOrgId(org.orgId);
        offices.push(...officesData);
      }
    }

    return { orgStaff, offices };
  }

  public async getStaffByUserId(
    userId: number | string
  ): Promise<IOfficeStaffWithName[]> {
    const { orgStaff, offices } = await this.getOfficesByUserId(userId);
    return orgStaff.map((item) => {
      const office = offices.find((office) => office.id === item.officeId);
      return {
        officeId: item.officeId,
        officeName: office!.name,
        officeStaffId: item.id,
        isDefaultOffice: item.isDefaultOffice,
        orgId: office!.orgId,
        dmEnabled: item.dmEnabled,
      };
    });
  }

  public async updateOffice(office: IOfficeDto): Promise<void> {
    await api.put<IBaseResponse<IOfficeDto>>(
      `${this.baseUrl}/${office.orgId}/offices`,
      office
    );
  }

  public async getPayments(
    officeID: number | string
  ): Promise<IPaymentMethodModel[]> {
    const { data } = await api.get<IBaseResponse<IPaymentMethodModel[]>>(
      `offices/payment-methods/?officeId=${officeID}`
    );
    return data.result;
  }
}

export const orgService = new OrgService();
