import React from "react";
import Cell from "../Cell";
import { selectors, helpers } from "store/treatmentPlan";
import { useAppSelector } from "hooks/redux";

interface Props {
  type: "uppers" | "lowers";
  indexPlus: 0 | 8;
  upperIndex: number;
  lowerIndex: number;
  rowNumber: number;
  itemIndex: number;
}
export const Cells: React.FC<Props> = ({
  type,
  lowerIndex,
  upperIndex,
  indexPlus,
  itemIndex,
  rowNumber,
}) => {
  const state = useAppSelector(selectors.getAllInfo);
  const extractionTooth = new Set(
    JSON.parse(state.case?.additionalInfo.extractionTooth ?? "[]")
  );
  return (
    <>
      {Array(8)
        .fill(null)
        .map((_, index) => {
          const orderColNum = helpers.getToothNumber(type, index, indexPlus);
          const toothIndexCalculated =
            type === "uppers" ? index + indexPlus : 15 - (index + indexPlus);
          const disabled = extractionTooth.has(orderColNum.toString());
          const planned = extractionTooth.has(`${orderColNum.toString()}_p`);
          const itemCells =
            state.treatmentPlanInstructions[itemIndex][
              type === "uppers" ? "upperSteps" : "lowerSteps"
            ][type === "uppers" ? upperIndex : lowerIndex]["instructions"][
              toothIndexCalculated
            ];
          const cellHovered =
            state.iprHoveredNum?.toothNumber === orderColNum &&
            (itemCells?.distal || itemCells?.mesial);
          return (
            state.treatmentPlanInstructions[itemIndex] && (
              <Cell
                key={
                  itemCells?.id ||
                  `${itemCells?.toothNumber}${itemIndex}${orderColNum}`
                }
                cellNum={orderColNum}
                rowNum={rowNumber}
                showPin={state.showPin}
                disabled={disabled}
                planned={planned}
                type={type}
                side={!indexPlus ? "left" : "right"}
                hovered={Boolean(cellHovered)}
                cells={itemCells!}
              />
            )
          );
        })}
    </>
  );
};
