import React, { useRef } from "react";
import { Theme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system/styleFunctionSx";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons/faCloudUploadAlt";

import { Colors } from "consts/colors";
import { FontAwesomeSvgIcon } from "../FontAwesomeSvgIcon";
import { UploadContainer, UploadLayout } from "./Elements";
import Box from "@mui/material/Box";

interface Props {
  accept?: string;
  error?: boolean;
  helperText?: string;
  sx?: SxProps<Theme>;
  onFilesUpload(files: File[]): void;
}

export const FileUploadArea: React.FC<Props> = (props) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const filesUploadStartHandler = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const filesChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      props.onFilesUpload(Array.from(event.target.files));
      event.target.value = "";
    }
  };
  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const targetInput: HTMLInputElement = event.target as HTMLInputElement;
    targetInput.classList.add("canDrop");
  };
  const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
    const targetInput: HTMLInputElement = event.target as HTMLInputElement;
    targetInput.classList.remove("canDrop");
  };
  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    const targetInput: HTMLInputElement = event.target as HTMLInputElement;
    targetInput.classList.remove("canDrop");
    const newFiles = event.dataTransfer.files;
    props.onFilesUpload(Array.from(newFiles));
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Stack spacing={0.5}>
      <UploadContainer
        sx={props.error ? { ...props.sx, borderColor: Colors.ERROR } : props.sx}
      >
        <input
          ref={fileInput}
          type="file"
          hidden
          accept={props.accept ?? "images/*"}
          onChange={filesChangeHandler}
        />
        {props.children ?? (
          <Stack px={3} spacing={1} alignItems="center" onDrop={dropHandler}>
            <FontAwesomeSvgIcon
              icon={faCloudUploadAlt}
              color="primary"
              fontSize="medium"
            />
            <Typography
              textAlign="center"
              variant="body2"
              color={Colors.PRIMARY}
            >
              Drag and drop files or click here to upload
            </Typography>
          </Stack>
        )}

        <UploadLayout
          onDragOver={dragOverHandler}
          onDragEnter={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={dropHandler}
          onClick={filesUploadStartHandler}
        />
      </UploadContainer>
      {Boolean(props.helperText) && (
        <Box px={1.75}>
          <Typography variant="body1" color={Colors.ERROR}>
            {props.helperText}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
