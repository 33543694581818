export const createRedirectPath = () => {
  const pathname = window.location.pathname;
  if (pathname && pathname !== "/") {
    localStorage.setItem("redirectPath", window.location.pathname);
  }
  if (window.location.search) {
    localStorage.setItem("searchPath", window.location.search);
  }
};

export const getUrlSearchParamsString = (params: any) => {
  const searchParams = new URLSearchParams();
  for (let key in params) {
    searchParams.append(key, params[key]);
  }
  return searchParams.toString();
};

export const getUrlSearchParams = (searchParams: URLSearchParams) => {
  let params: { [key: string]: string } = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });
  return params;
};

export const isHttpUrl = (path: string): boolean => {
  return path.startsWith("http");
};
