import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "components/ui";

export const PaperContainer = styled(Box)`
  padding-inline: 24px;
  padding-block: 32px;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0 7px rgba(56, 71, 109, 0.1);
  border-radius: 4px;
`;

export const CardsContainer = styled(Stack)`
  padding-inline: 16px;
`;
export const ButtonsContainer = styled(Stack)`
  padding: 40px;
`;

export const StyledFormLabel = styled(FormControlLabel)`
  color: var(--text-grey-dark-color);
`;

export const GoogleLinkAccount = styled(Button)`
  text-transform: none;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.084), 0 2px 3px rgba(0, 0, 0, 0.168);
  font-weight: 500;
  border-color: #fff;
  &:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.084), 0 2px 3px rgba(0, 0, 0, 0.168);
    border-color: #fff;
    background-color: #fff;
  }
`;
export const FacebookLinkAccount = styled(Button)`
  background-color: #1877f2;
  text-transform: none;
  font-family: "Helvetica", sans-serif;
  font-weight: 700;
  font-style: normal;
  &:hover {
    background-color: #1877f2;
  }
`;
