import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import Skeleton from "@mui/material/Skeleton";

import { LoginProviders } from "lib/enum";
import { GoogleIcon } from "assets/vectorIcon";
import { Colors } from "consts/colors";
import { InformBlock } from "components/common";
import { useStyledSnackbar } from "hooks/notification";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/accountSettings";

import { FacebookLinkAccount, GoogleLinkAccount } from "./Elements";
import { IIdentity } from "lib/dto";

export const LinkSMButtons = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotice } = useStyledSnackbar();
  const [loading, setLoading] = useState<LoginProviders | undefined>();
  const { loginDirectLink, identities, isLoading } = useAppSelector(
    selectors.getAllInfoSelector
  );
  const googleAccount: IIdentity | undefined = identities.find(
    ({ provider }) => provider === "google-oauth2"
  );
  const facebook: IIdentity | undefined = identities.find(
    ({ provider }) => provider === "facebook"
  );
  const smLoginHandler = (provider: LoginProviders, identity?: IIdentity) => {
    if (identity) {
      setLoading(identity.provider);
      dispatch(
        actions.unlinkSocialMediaAccount({
          accountUserId: identity.user_id,
          provider: identity.provider,
        })
      )
        .unwrap()
        .catch((e) => {
          showErrorNotice(e.message);
        })
        .finally(() => {
          setLoading(undefined);
        });
    } else {
      const loginLink = `${loginDirectLink}&connection=${provider}`;
      window.location.assign(loginLink);
    }
  };
  return (
    <Box mt={6}>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Box>
            <Stack spacing={2}>
              <Typography
                variant="body1"
                fontWeight={600}
                color={Colors.TEXT_PRIMARY}
              >
                Linked Social Media accounts
              </Typography>
              {isLoading ? (
                <Stack direction="row" spacing={2}>
                  <Skeleton animation="wave" width={215} height={40} />
                  <Skeleton animation="wave" width={215} height={40} />
                </Stack>
              ) : (
                <Stack direction="row" spacing={2}>
                  <GoogleLinkAccount
                    variant="outlined"
                    disableElevation
                    startIcon={<GoogleIcon />}
                    onClick={() =>
                      smLoginHandler(LoginProviders.GOOGLE, googleAccount)
                    }
                    loading={loading === LoginProviders.GOOGLE}
                  >
                    {Boolean(googleAccount)
                      ? "Unlink Google Account"
                      : "Link Google Account"}
                  </GoogleLinkAccount>
                  <FacebookLinkAccount
                    variant="contained"
                    disableElevation
                    startIcon={<FacebookRoundedIcon />}
                    onClick={() =>
                      smLoginHandler(LoginProviders.FACEBOOK, facebook)
                    }
                    loading={loading === LoginProviders.FACEBOOK}
                  >
                    {Boolean(facebook)
                      ? "Unlink Facebook Account"
                      : "Link Facebook Account"}
                  </FacebookLinkAccount>
                </Stack>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid item md={6}>
          <InformBlock
            title="Information"
            text="We will be able to use the account for sign in into system"
            sx={{ minHeight: 100, width: "auto" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
