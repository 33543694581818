import React from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import clsx from "clsx";

import { Colors } from "consts/colors";
import { Button } from "components/ui";
import { useAppSelector } from "hooks/redux";
import { eventSelector } from "store/dashboard/selectors";

import { EventsList } from "./EventsList";

const StyledButton = styled(Button)`
  padding-inline: 20px;
  padding-block: 12px;
  align-items: center;
`;
export const Content = () => {
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate("/orders");
  };
  const events = useAppSelector(eventSelector);
  return (
    <div className="dashboard">
      <div className="dashboard__content">
        <div
          className={clsx("dashboard__content-task-list", {
            "dashboard__content-task-list_full": !!events?.length,
          })}
        >
          <Stack spacing={3} sx={{ height: "100%" }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: Colors.TEXT_GREY }}
              >
                Task List
              </Typography>
              <StyledButton
                data-intercom-target="Order create button"
                variant="contained"
                onClick={navigateHandler}
              >
                <AddIcon sx={{ fontSize: 16, marginInlineEnd: 1 }} />
                Create order
              </StyledButton>
            </Stack>
            <EventsList />
          </Stack>
        </div>
      </div>
    </div>
  );
};
