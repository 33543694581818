import React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

import { NumberField } from "components/ui";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions } from "store/requestRemake";

export const ImpressionsField = () => {
  const dispatch = useAppDispatch();
  const impressions = useAppSelector(
    (state) => state.requestRemake.impressions
  );

  const changeHandler = (value: number) => {
    dispatch(actions.setImpressions(value));
  };

  return (
    <Stack spacing={1.5}>
      <Typography variant="body2">
        You must specify impressions if shipping physical samples
      </Typography>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2">Impression</Typography>
        <NumberField
          minValue={0}
          maxValue={2}
          value={impressions}
          onChange={changeHandler}
        />
      </Stack>
    </Stack>
  );
};
