import React from "react";
import ReactPlayer from "react-player";
interface Props {
  src: any;
}

export const VideoViewer = ({ src }: Props) => {
  if (!src) return null;
  return <ReactPlayer controls width="auto" height="100%" url={src} />;
};
