import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="14" fill="none">
      <path
        d="M12.2859 1.16663H1.71417C1.22841 1.16663 0.983292 1.75602 1.32748 2.10021L5.54165 6.31502V11.0104C5.54165 11.1888 5.62872 11.356 5.77492 11.4584L7.59783 12.734C7.95747 12.9857 8.45832 12.7306 8.45832 12.286V6.31502L12.6726 2.10021C13.0161 1.7567 12.7727 1.16663 12.2859 1.16663Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};