import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { PagePreloadContainer } from "containers";
import { ProtectedRoute } from "components/common";
import {
  HomePage,
  LoginPage,
  CodeInitPage,
  FileDownloadPage,
  FilesPage,
  TreatmentPlanPage,
  LinkSMAccountPage,
  AuthAsDoctor,
} from "pages";

import { PatientsRoute } from "./patients";
import { ShippingsRoute } from "./shippings";
import { OrdersRoute } from "./orders";

const InvoicesRoute = lazy(() => import("./invoices"));
const PaymentsRoute = lazy(() => import("./payments"));
const PreferencesPage = lazy(() => import("pages/preferences"));
const AccountSettingsPage = lazy(() => import("pages/accountSettings"));
const RootRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route path="/cases/*" element={<PatientsRoute />} />
      <Route path="/shippings/*" element={<ShippingsRoute />} />
      <Route
        path="/invoices/*"
        element={
          <Suspense fallback={<PagePreloadContainer />}>
            <InvoicesRoute />
          </Suspense>
        }
      />
      <Route path="/orders/*" element={<OrdersRoute />} />
      <Route
        path="/payments/*"
        element={
          <Suspense fallback={<PagePreloadContainer />}>
            <PaymentsRoute />
          </Suspense>
        }
      />

      <Route
        path="preferences"
        element={
          <ProtectedRoute>
            <Suspense fallback={<PagePreloadContainer />}>
              <PreferencesPage />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="download-file/:fileId"
        element={
          <ProtectedRoute>
            <FileDownloadPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="video/:fileId"
        element={
          <ProtectedRoute>
            <FilesPage type="video" />
          </ProtectedRoute>
        }
      />
      <Route
        path="model/:fileId"
        element={
          <ProtectedRoute>
            <FilesPage type="model" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/treatment-plan/:plainId"
        element={
          <ProtectedRoute>
            <TreatmentPlanPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings"
        element={
          <ProtectedRoute>
            <Suspense fallback={<PagePreloadContainer />}>
              <AccountSettingsPage />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route path="login" element={<LoginPage />} />
      <Route path="auth" element={<CodeInitPage />} />
      <Route path="link-account" element={<LinkSMAccountPage />} />
      <Route
        path="auth-as-doctor/:id"
        element={
          <ProtectedRoute>
            <AuthAsDoctor />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
export default RootRouter;
