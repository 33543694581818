import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { actions, selectors } from "store/patientDetail";
import { ICreateMessage } from "lib/model";
import { Colors } from "consts/colors";
import { FontAwesomeSvgIcon } from "components/common";

import { PaperContainer } from "../Elements";
import { MessagesTable } from "./MessagesTable";
import { MessageDialog } from "../MessageDialog";

export const Messages = () => {
  const dispatch = useAppDispatch();
  const { currentOrder } = useAppSelector(selectors.currentOrder);
  const unreadMessageCount = useAppSelector(selectors.unreadMessageCount);
  const [open, setOpen] = useState<boolean>(false);

  const openHandler = () => {
    setOpen(true);
  };
  const closeDialogHandler = () => {
    setOpen(false);
  };
  const submitHandler = async (params: ICreateMessage) => {
    await dispatch(actions.createMessage(params));
  };

  return (
    <>
      <MessageDialog
        orderId={currentOrder?.id}
        open={open}
        onClose={closeDialogHandler}
        onSubmit={submitHandler}
      />
      <PaperContainer data-intercom-target="Messages">
        <Box px={3} pt={3} pb={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body1"
                color={Colors.PRIMARY}
                fontWeight="bold"
              >
                Messages
              </Typography>
              <Badge
                sx={{ marginLeft: 2 }}
                badgeContent={unreadMessageCount}
                color="error"
              />
            </Stack>

            <IconButton
              disabled={!currentOrder?.id}
              color="primary"
              onClick={openHandler}
              data-intercom-target="Create/Edit message button"
            >
              <FontAwesomeSvgIcon icon={faEdit} color="secondary" />
            </IconButton>
          </Stack>
        </Box>
        <Box pb={3}>
          <MessagesTable />
        </Box>
      </PaperContainer>
    </>
  );
};
