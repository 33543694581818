import React from "react";
import Stack from "@mui/material/Stack";

import { lowerTeeth, upperTeeth } from "./const";
import { Arch } from "./Arch";
import { ITooth } from "lib/model";

interface Props {
  width?: number;
  height?: number;
  teeth?: (string | null)[];
  lowerArch?: boolean;
  upperArch?: boolean;
  onClick?: (
    event: React.MouseEvent<SVGGElement> | null,
    toothNumber: string
  ) => void;
  onHover?: (
    event: React.MouseEvent | null,
    toothNumber: string | null
  ) => void;
}
const mergeTeethWithProps = (
  originalTeeth: ITooth[],
  teethColors?: (string | null)[]
) => {
  if (!teethColors) {
    return originalTeeth;
  }
  return originalTeeth.map(({ toothColor, ...tooth }, index) => ({
    ...tooth,
    toothColor: teethColors[index] ?? toothColor,
  }));
};
export const ToothArches: React.VFC<Props> = ({
  lowerArch = true,
  upperArch = true,
  onHover,
  onClick,
  ...props
}) => {
  const height = props.height ? (props.height - 16) / 2 : 187;
  const width = props.width ?? 264;
  const initToothColor = props.teeth ?? [];
  return (
    <Stack spacing={2}>
      {upperArch && (
        <Arch
          teeth={mergeTeethWithProps(upperTeeth, initToothColor.slice(0, 16))}
          width={width}
          height={height}
          onHover={onHover}
          onClick={onClick}
          viewBox="0 0 266 190"
        />
      )}
      {lowerArch && (
        <Arch
          teeth={mergeTeethWithProps(
            lowerTeeth,
            initToothColor.slice(16).reverse()
          )}
          width={width}
          height={height}
          onHover={onHover}
          onClick={onClick}
          viewBox="0 0 251 162"
        />
      )}
    </Stack>
  );
};
