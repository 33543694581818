import { ListTableContainerProps } from "containers/listTable/types";
import { ICaseTabDto } from "lib/dto";
import { dateToString } from "utils";
import { caseService } from "services";
import Typography from "@mui/material/Typography";
import { Colors } from "consts/colors";

import { StatusColumn } from "../components/StatusColumn";
import { CaseProgressColumn } from "../components/CaseProgressColumn";
import React from "react";

async function fetch(filter: any) {
  return await caseService.getCasesArchived(filter);
}
const columns: ListTableContainerProps<ICaseTabDto>["columns"] = [
  {
    title: "Patient",
    targetLabel: "Patient",
    style: {
      headerCell: { justifyContent: "center" },
    },
    dataIndex: "patientFirstName",
    sort: true,
    onClick: () => {},
    render: (_: string, { patientFirstName, patientLastName }: ICaseTabDto) => {
      return (
        <Typography
          variant="body2"
          fontWeight={500}
          color={Colors.PRIMARY}
        >{`${patientFirstName} ${patientLastName}`}</Typography>
      );
    },
  },
  {
    title: "Lab status",
    targetLabel: "Lab status",
    dataIndex: "labStatus",
    render: (status: number, { needPreEvaluation }) => (
      <CaseProgressColumn isPreEval={needPreEvaluation} currentState={status} />
    ),
  },
  {
    title: "Order date",
    targetLabel: "Order date",
    dataIndex: "orderDate",
    width: "150px",
    render: (date: string) => (date ? dateToString(date) : "N/A"),
    sort: true,
  },
  {
    title: "Est. Ship Date",
    targetLabel: "Est. Ship Date",
    dataIndex: "deliveryEst",
    width: "150px",
    render: (date: string) => (date ? dateToString(date) : "N/A"),
    sort: true,
  },
  {
    title: "In Production",
    targetLabel: "In Production",
    dataIndex: "lastActivity",
    width: "150px",
  },
  {
    title: "Status",
    targetLabel: "Status",
    width: "250px",
    dataIndex: "caseTabAction",
    render: (_, info) => <StatusColumn caseInfo={info} />,
    style: {
      rowCell: {
        padding: "12px 24px",
      },
    },
    sort: true,
  },
];

export const arhivedListConf: ListTableContainerProps<ICaseTabDto> = {
  columns,
  emptyDataText: "No patient records found <Archived>",
  requests: { fetch },
  pagination: true,
};
