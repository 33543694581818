import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { TextField } from "components/ui";
import DialogActions from "@mui/material/DialogActions";

export const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 60px 1fr;
  column-gap: 24px;
`;

export const StyledDialogTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px 8px 24px;
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-inline: 85px 68px;
`;

export const StyledTextArea = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 5px;
    &::after,
    &::before {
      display: none;
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  padding-inline: 85px 68px;
  padding-block: 24px 34px;
`;
