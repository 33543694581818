import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";

import { FileType } from "lib/enum";
import { PreviewUploadFile, RadioSelect, Button } from "components/ui";
import { FileUploadArea } from "components/common";
import { IUploadedFile } from "lib/model";
import { useStyledSnackbar } from "hooks/notification";
import { createFileForStore } from "utils/fileHelpers";
import { generateUniqueID } from "utils/idHelper";
import { IExtOrderWithItemsAndTasksDto } from "lib/dto";
import { orderService } from "services";
import { URLStored } from "utils/URLStored";

interface Props {
  orderInfo: IExtOrderWithItemsAndTasksDto;
  withFiles?: boolean;
  open: boolean;
  closeHandler: () => void;
}
export const VerificationWindow = (props: Props) => {
  const { showErrorNotice } = useStyledSnackbar();
  const [verificationFiles, setVerificationFiles] = useState<IUploadedFile[]>(
    []
  );
  const [verification, setVerification] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const verificationOptions = [
    {
      label: "Yes, the keys fit well.",
      value: "passed",
    },
    {
      label: "I did not have an opportunity to try them.",
      value: "failed",
    },
  ];
  const filesUploadHandler = (files: File[]) => {
    const fileNames = verificationFiles.map(({ name }) => name);
    setVerificationFiles([
      ...verificationFiles,
      ...files
        .filter(({ name }) => !fileNames.includes(name))
        .map((file) => ({
          id: generateUniqueID(),
          ...createFileForStore(file),
        })),
    ]);
  };
  const filesDeleteHandler = (fileName: string) => {
    setVerificationFiles(
      verificationFiles.filter(({ name }) => name !== fileName)
    );
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerification(event.target.value);
  };
  const submitHandler = async () => {
    setLoading(true);
    try {
      if (verification === "passed") {
        await orderService.updateOrder({
          ...props.orderInfo,
          verified: true,
        });
      }
      if (verificationFiles.length > 0) {
        await orderService.uploadOrderFiles(
          props.orderInfo.id,
          verificationFiles.map(({ src, name }) => ({
            blob: URLStored.getBlobByObjectURL(src),
            name,
          })),
          FileType.VERIFICATION_KEY
        );
      }
      props.closeHandler();
    } catch (e: any) {
      showErrorNotice(e.message);
    } finally {
      setLoading(false);
    }
  };
  const disableButton =
    !verification ||
    (props.withFiles && verification === "passed" && !verificationFiles.length);
  return (
    <Dialog open={props.open}>
      <DialogTitle>Important info.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Did the verification key(s) we shipped with the previous order fit
          correctly? They are intended to assure that this case is progressing
          correctly, which can save you and your patient time.
        </DialogContentText>
        <RadioSelect options={verificationOptions} onChange={handleChange} />
        {props.withFiles && verification === "passed" && (
          <>
            <FileUploadArea onFilesUpload={filesUploadHandler} />
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              {verificationFiles.map(({ id, ...file }) => (
                <PreviewUploadFile
                  key={id}
                  {...file}
                  onDelete={filesDeleteHandler}
                />
              ))}
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button fatButton variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          fatButton
          loading={loading}
          disabled={disableButton}
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
