import React from "react";
import Stack from "@mui/material/Stack";

import { useAppSelector } from "hooks/redux";
import { selectors } from "store/shipping";
import { ShipInfo } from "./ShipInfo";
import { InfoContainer } from "./Elements";

export const ShippingMainInfo = () => {
  const { lab, office } = useAppSelector(selectors.shippingInfoSelector);
  const isLoading = useAppSelector(selectors.loadingSelector);

  return (
    <InfoContainer>
      <Stack spacing={4}>
        <ShipInfo title="Shipper info" info={office} isLoading={isLoading} />
        <ShipInfo title="Recipient info" info={lab} isLoading={isLoading} />
      </Stack>
    </InfoContainer>
  );
};
