export enum OrderStatuses {
  INVALID_ORDER_STATUS = "INVALID_ORDER_STATUS",
  DRAFT = "DRAFT",
  SUBMITTED_TO_LAB = "SUBMITTED_TO_LAB",
  CANCELED = "CANCELED",
  RECEIVED_BY_LAB = "RECEIVED_BY_LAB",
  PROCESSING_IN_LAB = "PROCESSING_IN_LAB",
  READY_FOR_SHIPPING = "READY_FOR_SHIPPING",
  SUBMITTED_TO_DOCTOR = "SUBMITTED_TO_DOCTOR",
  RECEIVED_BY_DOCTOR = "RECEIVED_BY_DOCTOR",
  HOLD = "HOLD",
}
