import { Nullable } from "lib/types";
import { IUser } from "lib/model";
import {
  IDoctorInfoDto,
  IExtOfficeDtoWithOrg,
  IExtUserDtoForProfileView,
  IGroupedUnreadMessageDto,
  IRoleDto,
} from "lib/dto";

export interface CurrentUserState {
  currentUser: Nullable<IUser>;
  accessToken: string;
  roles: IRoleDto[];
  doctorInfo: Nullable<IDoctorInfoDto>;
  offices: Nullable<IExtOfficeDtoWithOrg[]>;
  isLoading: boolean;
  invoices: number;
  isAuthorized: boolean;
  unreadMessage: IGroupedUnreadMessageDto[];
  profile: Nullable<IExtUserDtoForProfileView>;
}

export enum CurrentUserActions {
  CURRENT_USER_FETCHED = "currentUser/fetched/get",
  CURRENT_USER_LOG_IN = "currentUser/login/get",
  CURRENT_USER_LINK = "currentUser/linkedSM/get",
  CURRENT_USER_SET_MESSAGE_COUNT = "currentUser/message/set",
}
