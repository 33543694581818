import React from "react";
import { ITreatmentPlanStepInstructionDto } from "lib/dto";
import { TpInstructions } from "lib/enum";
import Rotation from "./Rotation";
import Torque from "./Torque";
import Intrusion from "./Intrusion";
import Extrusion from "./Extrusion";
import Attachment from "./Attachment";
import IPR from "./IPR";
import FBMIcon from "./FBMIcon";
import { ICellIcon } from "./types";
import { styled } from "@mui/material";

const isEmpty = (value: any) => {
  return value === null || value === false || value === undefined;
};

export const getIcons = (
  cells: ITreatmentPlanStepInstructionDto,
  type: "uppers" | "lowers",
  side: "left" | "right"
): ICellIcon[] => {
  return Object.keys(cells).reduce((icons, key: string) => {
    if (!isEmpty(cells[key as keyof ITreatmentPlanStepInstructionDto])) {
      switch (key) {
        case TpInstructions.attachment:
          icons.push({
            field: TpInstructions.attachment,
            icon: <Attachment value={cells.attachment} />,
            componentParams: {
              Component: styled(Attachment)`
                width: 100%;
                height: 100%;
              `,
              props: {
                value: cells.attachment,
              },
            },
          });
          break;
        case TpInstructions.mesial:
          icons.push({
            field: TpInstructions.mesial,
            icon: <IPR type="mesial" value={cells.mesial as number} />,
            componentParams: {
              Component: styled(IPR)`
                width: 100%;
                height: 100%;
              `,
              props: {
                type: "mesial",
                value: cells.mesial as number,
              },
            },
          });
          break;
        case TpInstructions.distal:
          icons.push({
            field: TpInstructions.distal,
            icon: <IPR type="distal" value={cells.distal as number} />,
            componentParams: {
              Component: styled(IPR)`
                width: 100%;
                height: 100%;
              `,
              props: {
                type: "distal",
                value: cells.distal as number,
              },
            },
          });
          break;
        case TpInstructions.rotation:
          icons.push({
            field: TpInstructions.rotation,
            icon: <Rotation type={type} side={side} value={cells.rotation} />,
            componentParams: {
              Component: styled(Rotation)`
                width: 100%;
                height: 100%;
              `,
              props: {
                type: type,
                side: side,
                value: cells.rotation,
              },
            },
          });
          break;
        case TpInstructions.torque:
          icons.push({
            field: TpInstructions.torque,
            icon: <Torque side={side} value={cells.torque} />,
            componentParams: {
              Component: styled(Torque)`
                width: 100%;
                height: 100%;
              `,
              props: {
                type: type,
                side: side,
                value: cells.torque,
              },
            },
          });
          break;
        case TpInstructions.fullBodyMovement:
          icons.push({
            field: TpInstructions.fullBodyMovement,
            icon: <FBMIcon value={cells.fullBodyMovement} />,
            componentParams: {
              Component: styled(FBMIcon)`
                width: 100%;
                height: 100%;
              `,
              props: {
                value: cells.fullBodyMovement,
                showDegrees: false,
              },
            },
          });
          break;
        case TpInstructions.intrusion:
          icons.push({
            field: TpInstructions.intrusion,
            icon: <Intrusion type={type} value={cells.intrusion!} />,
            componentParams: {
              Component: styled(Intrusion)`
                width: 100%;
                height: 100%;
              `,
              props: {
                value: cells.intrusion,
                type: type,
              },
            },
          });
          break;
        case TpInstructions.extrusion:
          icons.push({
            field: TpInstructions.extrusion,
            icon: <Extrusion type={type} value={cells.extrusion!} />,
            componentParams: {
              Component: styled(Extrusion)`
                width: 100%;
                height: 100%;
              `,
              props: {
                value: cells.extrusion,
                type: type,
              },
            },
          });
          break;
      }
    }
    return icons;
  }, [] as ICellIcon[]);
};
