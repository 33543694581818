import { ToothsComplication } from "lib/enum";

export const genderDict = [
  {
    label: "Male",
    value: "1",
  },
  {
    label: "Female",
    value: "0",
  },
];

export const selectorValue = [
  { label: "Allowed", value: "1" },
  { label: "Minimum", value: "2" },
  { label: "No IPR", value: "0" },
];
export const selectorAttachValue = [
  { label: "Allowed", value: "1" },
  { label: "Minimum", value: "2" },
  { label: "No Attachments", value: "0" },
];

export const complicationTags = [
  { id: 1, name: "Anterior Crowding" },
  {
    id: 2,
    name: "Anterior Spacing",
    secondary: ["Close", "Even space"],
  },
  { id: 3, name: "Midline", secondary: ["Correct", "Maintain"] },
  { id: 4, name: "Anterior Crossbite" },
  {
    id: 5,
    name: "Occlusion",
    secondary: ["Improve", "Class I"],
  },
  {
    id: 6,
    name: "Posterior Spacing",
    secondary: ["Close", "Even space"],
  },
  { id: 7, name: "Posterior Crowding" },
  { id: 8, name: "Posterior Crossbite" },
  {
    id: 9,
    name: "Missing Teeth",
    secondary: ["Maintain", "Increase", "Close"],
  },
];

export const complications = [
  {
    value: ToothsComplication.IMPLANTS,
    fill: "#F87E7E",
    text: "Implants",
  },
  {
    value: ToothsComplication.ANKYLOSED,
    fill: "#C7C7C7",
    text: "Ankylosed",
  },
  {
    value: ToothsComplication.IMPACTED,
    fill: "#A5D893",
    text: "Impacted",
  },
  {
    value: ToothsComplication.UNERRUPTED,
    fill: "#FFAB5D",
    text: "Unerrupted",
  },
  {
    value: ToothsComplication.DO_NOT_MOVE,
    fill: "#63B4FF",
    text: "DoNotMove",
  },
  {
    value: ToothsComplication.VENEERS,
    fill: "#f1bec7",
    text: "Crown / veneer / lamination",
  },
  {
    value: ToothsComplication.BRIDGE,
    fill: "#00C7B1",
    text: "Bridge",
  },
];
