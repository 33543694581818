import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "hooks/redux";
import { selectors } from "store/currentUser";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

const CountLabel = styled("span")`
  color: #fff;
  display: inline-flex;
  padding: 2px 16px;
  font-size: 14px;
  align-items: center;
  font-weight: bold;
  border-radius: 16px;
  justify-content: center;
  background-color: var(--primary-color);
  margin-left: 8px;
  min-width: 40px;
  box-sizing: border-box;
`;

export const UnreadMessagesList: React.VFC<Props> = ({ anchorEl, onClose }) => {
  const unreadMessages = useAppSelector(selectors.getUnreadMessage);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const clickHandler = (caseId: string | number) => () => {
    onClose();
    navigate(`/cases/${caseId}`);
  };
  if (!unreadMessages.length) {
    return null;
  }
  return (
    <Menu
      anchorEl={anchorEl}
      id="messages-list"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {unreadMessages.map(
        ({ patientFirstName, patientLastName, unreadCount, caseId }) => (
          <MenuItem
            onClick={clickHandler(caseId)}
            key={caseId}
            sx={{ justifyContent: "space-between" }}
          >
            {`${patientFirstName} ${patientLastName}`}

            <CountLabel>{unreadCount}</CountLabel>
          </MenuItem>
        )
      )}
    </Menu>
  );
};
