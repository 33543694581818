import React from "react";
import Stack from "@mui/material/Stack";

import { MainContainer } from "containers";
import { VideoViewer } from "components/ui";

interface Props {
  source: string;
}
export const VideoPreview: React.VFC<Props> = ({ source }) => {
  return (
    <MainContainer>
      <Stack
        sx={{ boxSizing: "border-box", height: "calc(100vh - 100px)" }}
        padding={3}
        alignItems="center"
      >
        <VideoViewer src={source} />
      </Stack>
    </MainContainer>
  );
};
