import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import { Colors } from "consts/colors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useStyledSnackbar } from "hooks/notification";
import { actions, selectors } from "store/caseCreate";
import { actions as stepsAction } from "store/createStepper";

import { CreateShippingModal } from "../CreateShippingModal";

interface Props {
  open: boolean;
  onClose: () => void;
}
export const ImpressionsWarningWindow: React.VFC<Props> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const order = useAppSelector(selectors.getAllInfoSelector);
  const [openShipping, setOpenShipping] = useState<boolean>(false);
  const { showErrorNotice } = useStyledSnackbar();

  const submitOrderToLab = () => {
    onClose();
    dispatch(actions.submitOrder())
      .unwrap()
      .then(({ caseId, filesSaveError }) => {
        if (filesSaveError) {
          showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
        } else {
          navigate(`/cases/${caseId}`);
        }
      })
      .catch((error) => showErrorNotice(error.message));
  };
  const openHandler = () => {
    onClose();
    if (!order.caseId) {
      dispatch(actions.createOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          setOpenShipping(true);
          if (filesSaveError) {
            showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
          }
        })
        .catch((error) => {
          showErrorNotice(error.message);
        });
    } else {
      dispatch(actions.updateCaseOrder())
        .unwrap()
        .then(({ filesSaveError }) => {
          setOpenShipping(true);
          if (filesSaveError) {
            showErrorNotice(filesSaveError, { autoHideDuration: 10000 });
          }
        })
        .catch((error) => {
          showErrorNotice(error.message);
        });
    }
  };
  const uploadFiles = () => {
    onClose();
    dispatch(actions.setRequiredState("otherFiles"));
    dispatch(stepsAction.setTabStep({ currentTab: 1, subStep: 1 }));
  };
  return (
    <>
      <CreateShippingModal
        withRedirect
        open={openShipping}
        onClose={() => setOpenShipping(false)}
      />
      <Dialog maxWidth="sm" fullWidth open={open}>
        <DialogTitle sx={{ color: Colors.PRIMARY, textAlign: "center" }}>
          Are you sure you want to submit an order for a new patient without
          uploading STL files or sending impressions?
        </DialogTitle>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: 3,
          }}
        >
          <Button
            size="large"
            color="primary"
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={uploadFiles}
          >
            Upload STL files
          </Button>
          <Button
            size="large"
            color="primary"
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={openHandler}
          >
            Creates shipping label
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={submitOrderToLab}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
