export function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export function setCookie(key: string,
  value: string,
  expires: number,
  domain: string
) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * expires);
  document.cookie = key + "=" + value + ";path=/;expires=" + d.toUTCString() + ";Domain=" + domain;
}

export function deleteCookie(key: string) {
  document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
