import React, { FC } from "react";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { Grid } from "@mui/material";
import { Select, FormLabel } from "components/ui";
import { iprPreference, attachPreference } from "lib/dictionaries";

interface Props {
  required: {
    ipr: boolean;
    attachments: boolean;
  };
  value: {
    ipr: string;
    attachments: string;
  };
  helperText: {
    ipr?: string;
    attachments?: string;
  };
  error: {
    ipr: boolean;
    attachments: boolean;
  };
  changePreferenceHandler: (event: SelectChangeEvent<string>) => void;
}
export const SelectIprAttachmentContainer: FC<Props> = ({
  helperText,
  required,
  value,
  error,
  changePreferenceHandler,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={4}>
        <FormLabel label="Select IPR Preference" required={required.ipr}>
          <Select
            value={value.ipr || ""}
            fullWidth
            error={error.ipr}
            helperText={helperText.ipr}
            data={iprPreference}
            placeholder="Select one"
            name="ipr"
            onChange={changePreferenceHandler}
          />
        </FormLabel>
      </Grid>
      <Grid item md={4}>
        <FormLabel
          label="Select Attachment Preference"
          required={required.attachments}
        >
          <Select
            value={value.attachments || ""}
            fullWidth
            error={error.attachments}
            helperText={helperText.attachments}
            data={attachPreference}
            placeholder="Select one"
            name="attachments"
            onChange={changePreferenceHandler}
          />
        </FormLabel>
      </Grid>
    </Grid>
  );
};
