import { Checkbox, FormControlLabel } from "@mui/material";

interface PlainCheckboxProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
}

export const PlainCheckbox: React.FC<PlainCheckboxProps> = ({
  value,
  onChange,
  label,
}) => {
  return (
    <FormControlLabel
      style={{ columnGap: '0.5rem' }}
      checked={value}
      onChange={(_, value) => onChange(value)}
      control={
        <Checkbox
          style={{ padding: 0 }}
        />
      }
      label={label}
    />
  )
}