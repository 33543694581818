import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useAppDispatch } from "hooks/redux";
import { actions } from "store/search";

import { getRowLabel } from "./utils";
import { Params } from "./types";

type RowProps = {
  entityId: string;
  entityInfo: Params;
};

export const Row: React.VFC<RowProps> = ({ entityInfo, entityId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pressHandler = () => {
    dispatch(actions.clearState());
    navigate(
      `/${entityInfo.entity === "patients" ? "cases" : "invoices"}/${entityId}`
    );
  };
  return (
    <Box sx={{ cursor: "pointer" }} px={1.5} py={0.5}>
      <Typography variant="subtitle1" onClick={pressHandler}>
        {getRowLabel(entityInfo)}
      </Typography>
    </Box>
  );
};
